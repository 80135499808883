import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  profileResponseData: null,
  profileDataList: null,
  nextInvoiceNumber: null,
  customerDataInfo: null,
  deleteCustomerData: null,
  staffProfileDataList: null,
  customerErrorMessage: null
};

const getters = {
  getProfileResponseData: state => state.profileResponseData,
  getProfileDataList: state => state.profileDataList,
  getNextInvoiceNumber: state => state.nextInvoiceNumber,
  getCustomerDataInfo: state => state.customerDataInfo,
  getDeleteCustomerData: state => state.deleteCustomerData,
  getStaffProfileDataList: state => state.staffProfileDataList,
  getCustomerErrorMessage: state => state.customerErrorMessage
};

const actions = {
  async doSaveProfile({ commit }, data) {
    await axios
      .post(
        data.number
          ? IP + "profiles/update/" + data.number
          : IP + "profiles/save",
        data
      )
      .then(response => {
        commit("setProfileResponseData", response);
      })
      .catch(error => {
        commit("setProfileErrorMessage", error);
      });
  },
  async fetchProfiles({ commit }) {
    await axios
        .get(
            IP + "profiles/all"
        )
        .then(response => {
          commit("setProfileDataList", response);
        })
        .catch(error => {
          commit("setProfileErrorMessage", error);
        });
  },
  async fetchStaffProfiles({ commit }) {
    await axios
        .get(
            IP + "auth/users"
        )
        .then(response => {
          commit("setStaffProfileDataList", response);
        })
        .catch(error => {
          commit("setProfileErrorMessage", error);
        });
  },
  async doGetProfileDataList({ commit }, data) {
    let search = data?data.search ? data.search: "":""
    let profileType = data?data.profileType ? data.profileType: "":""
    await axios
      .get(
        IP +
          "profiles/all-paginated" +
          "?page=" + data.page +
          "&per_page=" +  data.per_page+
          "&search=" +  search+
          "&profile_type=" +  profileType
      )
      .then(response => {
        commit("setProfileDataList", response);
      })
      .catch(error => {
        commit("setProfileErrorMessage", error);
      });
  },
  async getGeneratedInvoiceNumber({ commit }) {
    commit("setNextInvoiceNumber", { data: null });

    await axios
      .get(IP + "invoice/next")
      .then(response => {
        commit("setNextInvoiceNumber", response);
      })
      .catch(error => {
        commit("setProfileErrorMessage", error);
      });
  },
  async doGetCustomerDataInfo({ commit }) {
    await axios
      .get(IP + "customer/all")
      .then(response => {
        commit("setCustomerDataInfo", response);
      })
      .catch(error => {
        commit("setProfileErrorMessage", error);
      });
  },
  async doDeleteCustomer({ commit }, data) {
    await axios
      .delete(IP + "profiles/delete/" + data)
      .then(response => {
        commit("setDeleteCustomer", response);
      })
      .catch(error => {
        commit("setProfileErrorMessage", error);
      });
  }
};

const mutations = {
  setProfileResponseData: (state, response) => {
    state.profileResponseData = response.data;
  },
  setProfileDataList: (state, response) => {
    state.profileDataList = response.data;
  },
  setStaffProfileDataList: (state, response) => {
    state.staffProfileDataList = response.data;
  },
  setCustomerDataInfo: (state, response) => {
    state.customerDataInfo = response.data;
  },
  setDeleteCustomer: (state, response) => {
    state.deleteCustomerData = response.data;
  },
  setNextInvoiceNumber: (state, response) => {
    state.nextInvoiceNumber = response.data;
  },
  setProfileErrorMessage: (state, error) => {
    state.customerErrorMessage = error.response
      ? error.response.data
      : "Error: Network Error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
