import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  uploadData: null,
  uploadError: null,
  templateDownloadData: null,
  exportFileData: null
};

const getters = {
  getUploadData: state => state.uploadData,
  getTemplateDownloadData: state => state.templateDownloadData,
  getExportFileData: state => state.exportFileData,
  getUploadError: state => state.uploadError
};

const actions = {
  async doUploadFile({ commit }, data) {
    await axios
      .post(IP + "company/files/import", data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        commit("setUploadFileData", response);
      })
      .catch(error => {
        commit("setUploadError", error);
      });
  },
  async downloadTemplateFile({ commit }, data) {
    await axios
      .get(IP + "template?type=" + data)
      .then(response => {
        commit("setTemplateDownloadData", response);
      })
      .catch(error => {
        commit("setUploadError", error);
      });
  },
  async exportFile({ commit }, data) {
    await axios
      .get(IP + "export?type=" + data)
      .then(response => {
        commit("setExportFile", response);
      })
      .catch(error => {
        commit("setUploadError", error);
      });
  }
};

const mutations = {
  setUploadFileData: (state, response) => {
    state.uploadData = response.data;
  },
  setTemplateDownloadData: (state, response) => {
    state.templateDownloadData = response.data;
  },
  setExportFile: (state, response) => {
    state.exportFileData = response.data;
  },
  setUploadError: (state, error) => {
    state.uploadError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
