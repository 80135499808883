<template>
  <v-card flat style="width: 70%; margin-top: 13%;" class="mx-auto">


    <div v-if="showSuccessPage" class="text-center " style="font-size: 16px; color: #757575;">
      <p style="color: green; font-size: 38px; font-weight: bold;" class="mb-5">Success</p>
      <p>You have successfully set password,<br />
        Please login with your email and password to continue. </p>

      <v-divider class="mt-10 pt-4"></v-divider>

      <v-row class="mx-auto">

        <v-spacer></v-spacer>
        <v-btn
            small
            :to="{ name: 'login' }"
            class="ma-2"
            outlined
            color="#1d2b36"
        >
          Login
        </v-btn>
      </v-row>

    </div>

    <div v-else>

      <v-card-title class="justify-center"><b>BASE</b></v-card-title>

    <v-card-subtitle class="text-center">
      You can now enter your new password below
    </v-card-subtitle>


    <v-form ref="form" @submit.prevent="doSetPassword" lazy-validation>
      <v-card-subtitle>
        <v-alert class="mb-6" :type="responseType" v-if="formErrorMessage">
          {{ formErrorMessage }}
        </v-alert>

        <v-text-field
          v-if="!passwordCode"
          outlined
          v-model="form.token"
          label="Reset Code"
          required
          prepend-inner-icon="mdi-lock-outline"
          dense
        ></v-text-field>

        <v-text-field
          outlined
          v-model="form.password"
          :rules="[rules.password_required]"
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          label="Password"
          required
          @click:append="show1 = !show1"
          dense
          prepend-inner-icon="mdi-lock-outline"
        >
        </v-text-field>

        <v-text-field
          outlined
          v-model="form.password_confirmation"
          :rules="[rules.password_required, password_match]"
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          label="Confirm Password"
          required
          @click:append="show1 = !show1"
          dense
          prepend-inner-icon="mdi-lock-outline"
        >
        </v-text-field>

        <v-btn
          class="mx-auto requestPasswordButton"
          block
          large
          :loading="setSpinner"
          color="#0000ff"
          dark
          type="submit"
        >
          Continue
        </v-btn>

        <v-row class="mx-auto">
          <p>
            <router-link :to="{ name: 'login' }" class="text-decoration-none">
              <b>Login</b>
            </router-link>
          </p>

          <v-spacer> </v-spacer>

          <p>
            Do not have an account?
            <router-link
              :to="{ name: 'register' }"
              class="text-decoration-none"
            >
              Get an account
            </router-link>
          </p>
        </v-row>
      </v-card-subtitle>
    </v-form>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "../../../global/router";

export default {
  name: "SetPassword",
  data() {
    return {
      show1: false,
      formErrorMessage: null,
      responseType: null,
      passwordCode: this.$route.params.id,
      form: {
        password: null,
        password_confirmation: null,
        token: this.$route.params.id ? this.$route.params.id : null
      },
      setSpinner: false,
      rules: {
        password_required: value => !!value || "Password is Required."
      },
      showSuccessPage:false
    };
  },
  methods: {
    ...mapActions(
        [
          "setPassword",
          "getUserProfile",
          "doGetCompanyProfile"
        ]
    ),
    doSetPassword() {
      this.formErrorMessage = "";

      if (!this.form.password) {
        this.formErrorMessage = "Password is Required";
        this.responseType = "error";
        return false;
      }

      if (!this.form.password_confirmation) {
        this.formErrorMessage = "Confirm Password is Required";
        this.responseType = "error";
        return false;
      }

      if (this.form.password !== this.form.password_confirmation) {
        this.formErrorMessage = "Password Mismatch";
        this.responseType = "error";
        return false;
      }

      this.setSpinner = true;

      this.setPassword(this.form);
    }
  },
  computed: {
    ...mapGetters([
      "getUserProfileData",
      "getSetPassword",
      "getCompanyData",
      "getLoginMessage"
    ]),
    password_match() {
      return () =>
        this.form.password === this.form.password_confirmation ||
        "Password Mismatch.";
    }
  },
  watch: {
    getLoginMessage(error) {
      this.formErrorMessage = error.message;
      this.responseType = "error";
      this.setSpinner = false;
    },

    getCompanyData(company) {
      if (company != null) {
        router.push("/dashboard");
        location.reload();
      }
    },
    getSetPassword(requestPassData) {
      this.formErrorMessage = requestPassData.message;
      this.responseType = "success";
      this.setSpinner = false;
      this.showSuccessPage = true
      this.form = {
            password: null,
            password_confirmation: null,
            token: this.$route.params.id ? this.$route.params.id : null
      }
    }
  }
};
</script>

<style scoped>
.requestPasswordButton {
  margin-top: 5%;
  margin-bottom: 7%;
  height: 10px;
  font-size: 16px;
  font-weight: bold;
}
</style>
