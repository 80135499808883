import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  supplierPayoutsData: null,
  supplierPayoutsError: null
};

const getters = {
  getSupplierPayoutsData: state => state.supplierPayoutsData,
  getSupplierPayoutsError: state => state.supplierPayoutsError
};

const actions = {
  async doGetSupplierPayouts({ commit }, data) {
    let search  = data ? data.search ? data.search : "" : ""
    await axios
      .get(
        data.supplier_number
          ? IP +
              "payouts/to-supplier/" +
              data.supplier_number +
              "?page=" +
              data.page +
              "&per_page=" +
              data.per_page+
              "&search="+search
          : IP + "payouts/all?page=" + data.page + "&per_page=" + data.per_page+"&search="+search
      )
      .then(response => {
        commit("setSupplierPayoutsData", response);
      })
      .catch(error => {
        commit("setSupplierPayoutsError", error);
      });
  }
};

const mutations = {
  setSupplierPayoutsData: (state, response) => {
    state.supplierPayoutsData = response.data;
  },
  setSupplierPayoutsError: (state, error) => {
    state.supplierPayoutsError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
