<template>
  <v-row>
    <v-col class="mx-auto">
      <v-form ref="form" @submit.prevent="doRegister" lazy-validation>
        <v-card-text>
          <v-alert type="error" v-if="formErrorMessage">
            {{ formErrorMessage }}
          </v-alert>
          <v-row>
            <v-col cols="6">
              <v-text-field
                outlined
                :rules="[rules.first_name_required]"
                v-model="form.first_name"
                label="First name"
                required
                prepend-inner-icon="mdi-account"
                type="text"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                outlined
                :rules="[rules.second_name_required]"
                v-model="form.second_name"
                label="Second name"
                required
                prepend-inner-icon="mdi-account"
                type="text"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-select
            outlined
            v-model="form.gender"
            :items="genderItems"
            :rules="[rules.gender_required]"
            color="pink"
            label="Gender"
            required
            dense
          ></v-select>

          <v-text-field
            outlined
            v-model="form.phone"
            :rules="[rules.email_required]"
            label="Phone"
            required
            prepend-inner-icon="mdi-phone"
            type="tel"
            dense
          ></v-text-field>

          <v-text-field
            outlined
            v-model="form.email"
            :rules="[rules.email_required, rules.email_pattern]"
            label="Email"
            required
            prepend-inner-icon="mdi-email-outline"
            type="email"
            dense
          ></v-text-field>

          <v-autocomplete
            v-model="form.roles"
            :items="rolesDataList"
            item-value="id"
            label="Role"
            dense
            outlined
            hide-selected
            clearable
            multiple
            type="text"
          ></v-autocomplete>

          <v-row>
            <v-col cols="6">
              <v-text-field
                outlined
                v-model="form.password"
                :rules="[rules.password_required]"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                label="Password"
                required
                @click:append="show1 = !show1"
                dense
                prepend-inner-icon="mdi-lock-outline"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                outlined
                v-model="form.confirm_password"
                :rules="[rules.password_required, password_match]"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                label="Confirm Password"
                required
                @click:append="show1 = !show1"
                dense
                prepend-inner-icon="mdi-lock-outline"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-btn
            class="mx-auto RegisterButton"
            :loading="registerSpinner"
            block
            large
            color="#0000ff"
            dark
            type="submit"
          >
            Register User
          </v-btn>
        </v-card-text>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewUser",
  data() {
    return {
      show1: false,
      formErrorMessage: null,
      registerSpinner: false,
      form: {
        first_name: null,
        second_name: null,
        gender: null,
        email: null,
        phone: null,
        password: null,
        confirm_password: null,
        roles: []
      },
      genderItems: [
        { text: "Select Gender", value: null },
        { text: "MALE", value: "MALE" },
        { text: "FEMALE", value: "FEMALE" }
      ],
      rules: {
        first_name_required: value => !!value || "First name is Required.",
        second_name_required: value => !!value || "Second name is Required.",
        password_required: value => !!value || "Password is Required.",
        email_required: value => !!value || "Email is Required.",
        gender_required: value => !!value || "Gender is Required.",
        email_pattern: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Please enter valid email.";
        }
      },
      rolesDataList: []
    };
  },
  mounted() {
    this.getUserRolesList();
  },
  methods: {
    ...mapActions(
        [
          "registerUser",
          "getUserRolesList"
        ]
    ),
    doRegister() {
      if(this.form.confirm_password !== this.form.password){
        this.formErrorMessage = "Password miss match!";
        return
      }

      this.formErrorMessage = "";
      this.registerSpinner = true;
      this.registerUser(this.form);
    },
    filterRolesList(dataList) {
      let dataObj = [{ text: "Select Role", id: null, disabled: true }];
      let dataJson = {};
      for (let i = 0; i < dataList.length; i++) {
        dataJson = {
          text: dataList[i].name,
          id: dataList[i].number,
          disabled: false
        };
        dataObj.push(dataJson);
      }

      return dataObj;
    }
  },
  computed: {
    ...mapGetters([
      "getRegisterUserProfileData",
      "getRegisterErrorMessage",
      "getRolesAndPermissionListData"
    ]),
    password_match() {
      return () =>
        this.form.password === this.form.confirm_password ||
        "Password Mismatch.";
    }
  },
  watch: {
    getRegisterUserProfileData(userData) {
      if (userData) {
        this.form = {
          first_name: null,
          second_name: null,
          gender: null,
          email: null,
          phone: null,
          password: null,
          confirm_password: null
        };
        this.successToast("Success", userData.message);
        this.registerSpinner = false;
      }
    },
    getRegisterErrorMessage(data) {
      this.formErrorMessage = data.data.message;
      this.registerSpinner = false;
      this.errorToast("Error", data.data.message);
    },
    getRolesAndPermissionListData(rolesData) {
      if (rolesData) {
        this.rolesDataList = this.filterRolesList(rolesData);
      }
    }
  }
};
</script>

<style scoped></style>
