<template>
  <span>
    <form ref="form" @submit.prevent="savePaymentMethods">
      <v-row class="mx-auto">
        <v-col>
          <v-text-field
            v-model="form.name"
            label="Name"
            outlined
            dense
            required
          ></v-text-field>

          <v-textarea
            v-model="form.notes"
            label="Memo"
            outlined
            rows="2"
            dense
            required
          ></v-textarea>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="companyButton"
          :loading="addPaymentMethodLoader"
          color="#0000ff"
          dark
          type="submit"
        >
          Save Payment Method
        </v-btn>
      </v-card-actions>
    </form>
  </span>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PaymentMethods",
  data() {
    return {
      form: {
        name: null,
        notes: null
      },
      addPaymentMethodLoader: false
    };
  },
  methods: {
    ...mapActions(
        [
            "saveNewPaymentMethod"
        ]
    ),
    savePaymentMethods() {
      this.addPaymentMethodLoader = true;
      this.saveNewPaymentMethod(this.form);
    }
  }
};
</script>

<style scoped></style>
