<template>
  <div>
    <v-alert type="error" v-if="formCustomerErrorMessage">
      {{ formCustomerErrorMessage }}
    </v-alert>
    <form ref="form" @submit.prevent="saveProfile">
      <v-row>
        <v-col cols="6">
          <p>Profile</p>
          <v-text-field
              v-model="form.name"
              label="Profile Name"
              outlined
              dense
              hide-details
              required
          ></v-text-field>

        <v-text-field
            class="mt-4"
            v-model="form.trading_name"
            label="Trading Name"
            outlined
            dense
            hide-details
        ></v-text-field>


      <v-text-field
          class="mt-4"
          v-model="form.address"
          label="Address"
          outlined
          hide-details
          dense
      ></v-text-field>

      <v-text-field
          class="mt-4"
          v-model="form.tin"
          label="TIN"
          outlined
          prepend-inner-icon="mdi-numeric-9-plus-box-outline"
          dense
          hide-details
      ></v-text-field>

      <v-row class="mx-auto">
        <v-checkbox
            v-model="showAddContacts"
            class="shrink mt-2 mb-5 float-left"
            label="Add contacts"
            id="sub_account_id"
            hide-details
        ></v-checkbox>
      </v-row>

      <v-divider v-if="showAddContacts"></v-divider>

      <v-simple-table dense light v-if="showAddContacts">
        <template v-slot:default>
          <tbody>
            <tr v-for="(phoneData, index) in form.phoneContact" :key="index">
              <td>
                <v-text-field
                    flat
                    solo
                    :placeholder="'+25670000000'+index"
                    v-model="phoneData.contact"
                    prepend-inner-icon="mdi-phone"
                    type="tel"
                    dense
                    maxlength="12"
                    hide-details
                    :append-outer-icon="index === 0 ? '' : 'mdi-close'"
                    @click:append-outer="removePhone(index)"
                ></v-text-field>
              </td>
            </tr>

            <tr>
              <td colspan="6" @click="addPhone" style="cursor: pointer;">
                <span class="text--disabled">Add another Phone</span>
              </td>
            </tr>

            <tr v-for="(emailData, index) in form.emailContact" :key="index">
              <td>
                <v-text-field
                    flat
                    solo
                    :placeholder="'email'+(index+1)+'@address.com'"
                    v-model="emailData.contact"
                    prepend-inner-icon="mdi-email-outline"
                    type="email"
                    dense
                    :append-outer-icon="index === 0 ? '' : 'mdi-close'"
                    @click:append-outer="removeEmail(index)"
                    hide-details
                ></v-text-field>
              </td>
            </tr>

            <tr>
              <td colspan="6" @click="addEmail" style="cursor: pointer;">
                <span class="text--disabled">Add another Email</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-divider v-if="showAddContacts"></v-divider>



        </v-col>
        <v-col cols="6">

          <v-row class="mx-auto">
             <p class="mt-3" style="font-size: 20px;">Contact Person</p>
                <v-spacer></v-spacer>
                 <v-btn
                     class="ma-2 text-decoration-none"
                     outlined
                     small
                     color="#242f7e"
                     @click="addContactProfile"
                 >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add Contact Person
              </v-btn>
          </v-row>


           <div class="mb-12" v-for="(contactP, idx) in form.contactPersons" :key="idx">

           <v-text-field
               v-model="contactP.name"
               :label="'Contact person '+ (idx + 1)"
               outlined
               dense
               hide-details
               class="mb-2"
               :append-outer-icon="idx > 0 ? 'mdi-close' : ''"
               @click:append-outer="removeProfile(idx)"
           ></v-text-field>


      <v-divider ></v-divider>

      <v-simple-table dense light>
        <template v-slot:default>
          <tbody>
            <tr v-for="(phoneData, index1) in contactP.phone_contacts" :key="'A'+index1">
              <td>
                <v-text-field
                    flat
                    solo
                    :placeholder="'+25670000000'+index1"
                    v-model="phoneData.phone"
                    prepend-inner-icon="mdi-phone"
                    type="tel"
                    dense
                    maxlength="12"
                    hide-details
                    :append-outer-icon="index1 === 0 ? '' : 'mdi-close'"
                    @click:append-outer="removeContactPersonPhone(idx, index1)"
                ></v-text-field>
              </td>
            </tr>

            <tr>
              <td colspan="6" @click="addContactPersonPhone(idx)" style="cursor: pointer;">
                <span class="text--disabled">Add another Phone</span>
              </td>
            </tr>

            <tr v-for="(emailData, index2) in contactP.email_contacts" :key="'B'+index2">
              <td>
                <v-text-field
                    flat
                    solo
                    :placeholder="'email'+(index2+1)+'@address.com'"
                    v-model="emailData.email"
                    prepend-inner-icon="mdi-email-outline"
                    type="email"
                    dense
                    :append-outer-icon="index2 === 0 ? '' : 'mdi-close'"
                    @click:append-outer="removeContactPersonEmail(idx, index2)"
                    hide-details
                ></v-text-field>
              </td>
            </tr>

            <tr>
              <td colspan="6" @click="addContactPersonEmail(idx)" style="cursor: pointer;">
                <span class="text--disabled">Add another Email</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-divider></v-divider>
           </div>



        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6"></v-col>
        <v-col cols="6">
           <div class="text-right">
        <v-btn
            class="companyButton mt-4 pl-10 pr-10"
            :loading="customerSpinner"
            color="#0000ff"
            dark
            type="submit"
        >
          {{ editProfilesObj ? "Edit" : "Submit" }}
        </v-btn>
      </div>
        </v-col>
      </v-row>


    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewProfile",
  props: {
    editProfilesObj: {}
  },
  data() {
    return {
      formCustomerErrorMessage: null,
      customerSpinner: false,
      showAddContacts: false,
      showAddContactPersonContacts: false,
      tin: false,
      form: {
        name: null,
        trading_name: null,
        address: null,
        number: null,
        tin: null,
        phoneContact: [
          {
            contact: null,
            id: null,
            type: "phone"
          }
        ],
        emailContact: [
          {
            contact: null,
            id: null,
            type: "email"
          }
        ],
        contactPersons: [
            {
              name: null,
          phone_contacts: [
            {
              phone: null
            }
          ],
          email_contacts: [
            {
              email: null
            }
          ],
            }
            ]
      }
    };
  },
  mounted() {
    this.checkProfile()
  },
  methods: {
    ...mapActions(
        [
            "doSaveProfile",
          "customerProfile"
        ]
    ),
    saveProfile() {
      this.customerSpinner = true;
      this.formCustomerErrorMessage = ""
      let formData = this.form
      if(
          formData.contactPersons.length > 0 &&
          !formData.contactPersons[0].name
      ){
        formData.contactPersons = null
      }

      this.doSaveProfile(formData);
    },
    addPhone() {
      this.form.phoneContact.push({
        contact: null,
        id: null,
        type: "phone"
      });
    },
    addContactPersonPhone(idx) {
      this.form.contactPersons[idx].phone_contacts.push({
        phone: null
      });
    },
    removePhone(index) {
      this.form.phoneContact.splice(index, 1);
    },
    removeContactPersonPhone(idx, index) {
      this.form.contactPersons[idx].phone_contacts.splice(index, 1);
    },
    removeProfile(idx){
      this.form.contactPersons.splice(idx, 1);
    },
    addEmail() {
      this.form.emailContact.push({
        contact: null,
        id: null,
        type: "email"
      });
    },
    removeEmail(index) {
      this.form.emailContact.splice(index, 1);
    },
    addContactPersonEmail(idx) {
      this.form.contactPersons[idx].email_contacts.push({
        email: null
      });
    },
    removeContactPersonEmail(idx, index) {
      this.form.contactPersons[idx].email_contacts.splice(index, 1);
    },
    checkProfile(){
      if (this.editProfilesObj) {
        this.customerProfile(this.editProfilesObj.number);
      }
    },
    addContactProfile(){

    this.form.contactPersons.push(
        {
          name: null,
          phone_contacts: [
            {
              phone: null
            }
          ],
          email_contacts: [
            {
              email: null
            }
          ],
        })


    }
  },
  computed: {
    ...mapGetters(
        [
            "getCustomerErrorMessage",
             "getCustomerProfileData"
        ]
    )
  },
  watch: {
    getCustomerErrorMessage(customerErrorMessage) {
      this.formCustomerErrorMessage = customerErrorMessage.message;
      this.customerSpinner = false;
      this.errorToast("Error ", customerErrorMessage.message);
    },
    editProfilesObj() {
      this.checkProfile()
    },
    getCustomerProfileData(userProfileData) {
      if (userProfileData) {
        this.form.name = userProfileData.name;
        this.form.address = userProfileData.address;
        this.form.tin = userProfileData.tin;
        this.form.trading_name = userProfileData.trading_name;
        this.form.number = userProfileData.number;

        this.showAddContacts = userProfileData.profile_contacts.length !== 0;
        let contactInfo = [];

        contactInfo = userProfileData.profile_contacts;



        if(contactInfo.length > 0) {
          this.form.phoneContact = [];
          this.form.emailContact = [];
          contactInfo.forEach(lst => {
            if (lst.type === "phone") {
              this.form.phoneContact.push({
                contact: lst.contact,
                id: lst.id,
                type: lst.type
              });
            } else if (lst.type === "email") {
              this.form.emailContact.push({
                contact: lst.contact,
                id: lst.id,
                type: lst.type
              });
            }

          })
        }


        contactInfo = userProfileData.contact_persons;
        if(contactInfo.length > 0){
          this.form.contactPersons = []
          contactInfo.forEach(lst => {

            this.form.contactPersons.push(
                {
                  name: lst.name,
                  phone_contacts: lst.phone_contacts,
                  email_contacts: lst.email_contacts,
                })

          } )
        }




      }
    }
  }
};
</script>

<style scoped></style>
<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
