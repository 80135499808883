<template>
<div>

   <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>


    <form ref="form" @submit.prevent="editStock">
       <v-row>
            <v-col cols="6">

              <v-autocomplete
                  class="mb-3"
                  outlined
                  @click:append-outer="addItemData"
                  append-outer-icon="mdi-plus"
                  v-model="form.item_number"
                  :items="ItemDataObj"
                  item-value="id"
                  color="pink"
                  label="Item"
                  required
                  dense
                  clearable
                  hide-details
              ></v-autocomplete>



              <v-row>
                <v-col cols="6">

                  <v-autocomplete
                      label="Unit"
                      hide-details
                      outlined
                      v-model="form.unit_number"
                      :items="unitDataObj"
                      item-value="id"
                      append-outer-icon="mdi-plus"
                      @click:append-outer="addUnitData"
                      required
                      clearable
                      dense
                  ></v-autocomplete>

                </v-col>
                <v-col cols="6">
                  <v-text-field
                      @keypress="onlyNumberValidator($event)"
                      v-model="form.quantity"
                      label="Quantity"
                      maxlength="17"
                      outlined
                      dense
                      hide-details
                      v-on:blur="
                            computeValues(
                              form.quantity,
                              'quantity'
                            )
                          "
                  ></v-text-field>
                </v-col>
              </v-row>

                <v-row>
                <v-col cols="6">
                   <v-text-field
                       @keypress="onlyNumberValidator($event)"
                       v-model="form.buying_price"
                       label="Buying Price"
                       maxlength="17"
                       outlined
                       dense
                       hide-details
                       v-on:blur="
                            computeValues(
                              form.buying_price,
                              'buying_price'
                            )
                          "
                   ></v-text-field>
                </v-col>
                <v-col cols="6">
                     <v-text-field
                         @keypress="onlyNumberValidator($event)"
                         v-model="form.selling_price"
                         label="Selling Price"
                         maxlength="17"
                         outlined
                         dense
                         hide-details
                         v-on:blur="
                            computeValues(
                              form.selling_price,
                              'selling_price'
                            )
                          "
                     ></v-text-field>

                </v-col>
              </v-row>


              <v-text-field
                  v-model="form.date"
                  label="Date [ dd / mm / yyy ]"
                  outlined
                  dense
                  type="date"
                  required
                  hide-details
              ></v-text-field>
              <small style="color: red;" v-if="yearLengthValidator(form.date)">
                Invalid Date
              </small>


            </v-col>

             <v-col cols="6">


               <v-autocomplete
                   outlined
                   v-model="form.tax_number"
                   :items="taxItemDataObj"
                   item-value="id"
                   color="pink"
                   label=""
                   hide-selected
                   dense
                   clearable
                   append-outer-icon="mdi-plus"
                   @click:append-outer="addTaxModel"
               ></v-autocomplete>



          <v-textarea
              v-model="form.notes"
              label="Memo"
              outlined
              rows="4"
              dense
          ></v-textarea>
        </v-col>

          </v-row>

      <v-row>

        <v-col class="text-right">


            <v-btn
                class="companyButton"
                :loading="btnSpinner"
                color="#0000ff"
                dark
                type="submit"
            >
              Edit
            </v-btn>

        </v-col>
      </v-row>
    </form>


  <!--tax dialog-->
  <v-dialog v-model="addTaxDialog" width="35%">
    <v-card>
      <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
        <v-row>
          <v-card-title>Add Tax</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="addTaxDialog = false">
            <v-icon color="red">
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
        <new-tax ref="new_tax_component"></new-tax>
      </div>
    </v-card>
  </v-dialog>


  <!--    adds unit-->
  <v-dialog v-model="add_unit_dialog" width="30%">
    <v-card>
      <div style="width: 95%; padding-bottom: 10px;" class="mx-auto">
        <v-row>
          <v-card-title> Add Unit</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="add_unit_dialog = false">
            <v-icon color="red">
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
        <add-unit ref="unit_components"></add-unit>
      </div>
    </v-card>
  </v-dialog>


  <!--      adds items-->
  <v-dialog v-model="add_item_dialog" width="60%">
    <v-card>
      <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
        <v-row>
          <v-card-title>Add Item</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="add_item_dialog = false">
            <v-icon color="red">
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
        <add-item :filterItems="['IVP','IVA']" ref="items_components"></add-item>
      </div>
    </v-card>
  </v-dialog>


</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewTax from "@/modules/dashboard/Settings/tax/NewTax";
import AddUnit from "@/modules/dashboard/accounting/commons/AddUnit";
import AddItem from "@/modules/dashboard/accounting/commons/AddItem";

export default {
  name: "EditStock",
  components: {AddItem, AddUnit, NewTax},
  data(){
    return{
      errorMessage:null,
      form:{
        stock_item_number:null,
        buying_price:null,
        selling_price:null,
        reference:null,
        item_number:"",
        tax_number:"",
        quantity:null,
        notes:null,
        unit_number:null,
        date:null
      },
      unitDataObj: [],
      ItemDataObj: [],
      taxItemDataObj: [],
      btnSpinner:false,
      supplierDataObj: [],
      addTaxDialog: false,
      add_unit_dialog: false,
      add_item_dialog: false,
    }
  },
  mounted() {
    this.doGetStockableItems();
    this.fetchTaxData();
    this.doGetUnitDataList();
  },
  methods:{
    ...mapActions(
        [
            "doGetStockableItems",
            "fetchTaxData",
            "saveEditStock",
            "doGetUnitDataList",
        ]
    ),
    editStock(){
        this.errorMessage = ""
        this.btnSpinner = true
        let formData = {...this.form}
        formData.buying_price = this.form.buying_price ? this.form.buying_price.toString().replace(/,/g, "") : 0
        formData.selling_price = this.form.selling_price ? this.form.selling_price.toString().replace(/,/g, "") : 0
        formData.quantity = this.form.quantity ? this.form.quantity.toString().replace(/,/g, "") : 0
        this.saveEditStock(formData)
    },
    addItemData() {
      this.add_item_dialog = true;
    },
    addUnitData() {
      this.add_unit_dialog = true;
    },
    addTaxModel(){
      this.addTaxDialog = true;
    },
    newStockFilter(dataList) {
      let dataObj = [{ text: "Select item", id: "", disabled: true }];
      let dataJson = {};
      dataList.forEach(list => {
        dataJson = {
          text: list.name ? list.name : "",
          id: list.number ? list.number : "",
          disabled: false,
          buying_price:list.selling_price ? list.selling_price : "",
          selling_price:list.selling_price ? list.selling_price : "",
          tax: list.tax.length > 0 ? list.tax : [],
        };
        dataObj.push(dataJson);
      })

      return dataObj;
    },
    taxItemFilter(dataList) {
      let arr = [
        { text: "Select tax", id: "", disabled:true}
      ];
      let obj = {};
      dataList.forEach( list => {
            obj = {
              text: list.name+" ("+list.rate+" %)",
              id: list.number,
            };
            arr.push(obj);
          }
      )
      return arr;
    },
    filterUnits(unitDataList) {
      let unitJsn = {};
      let unitObj = [
        {
          text: "Select unit",
          id: null,
          disabled: true
        }
      ];
      for (let i = 0; i < unitDataList.length; i++) {
        unitJsn = {
          text:
              unitDataList[i].name + " ( " + unitDataList[i].abbreviation + " )",
          id: unitDataList[i].number,
          disabled: false
        };
        unitObj.push(unitJsn);
      }

      this.unitDataObj = unitObj;
    },
    computeValues(value, vModal) {
      if (value) {
        ///this removes any value that starts with a zero
        value = value.toString().replace(/,/g, "");
        if (!isNaN(value)) {
          value = value * 1;
        }
        if (vModal === "quantity") {
          this.form.quantity =
              eval(value) >= 0 ? eval(value).toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
        } else if (vModal === "buying_price") {
          this.form.buying_price =
              eval(value) >= 0 ? eval(value).toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
        } else if (vModal === "selling_price") {
          this.form.selling_price =
              eval(value) >= 0 ? eval(value).toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
        }
      }
    },
  },
  computed:{
    ...mapGetters(
        [
            "getNewStockDataInfo",
            "getStockableItems",
            "getTaxDataAll",
            "getUnitDataList",
            "getEditStockErrorMessage",
            "getNewTaxData",
            "getUnitData",
            "getInventoryPartItem",
            "getInventoryAssemblyItem"
        ]
    )
  },
  watch:{
    getInventoryPartItem(itemData) {
      if (itemData) {
        // this.$refs["items_components"].addItemLoader = false;
        this.add_item_dialog = false;
        this.form.item_number = itemData.data.number
        this.doGetStockableItems();
      }
    },
    getInventoryAssemblyItem(itemData) {
      if (itemData) {
        // this.$refs["items_components"].addItemLoader = false;
        this.add_item_dialog = false;
        this.form.item_number = itemData.data.number
        this.doGetStockableItems();
      }
    },
    getNewStockDataInfo(data){
      if(data){
        this.form.stock_item_number = data.number
       this.form.item_number = data.item_number
       this.form.code = data.code
       this.form.buying_price = data.buying_price ? data.buying_price.toFixed(2)
           .toString()
           .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
       this.form.selling_price = data.selling_price ? data.selling_price.toFixed(2)
           .toString()
           .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
       this.form.quantity = data.quantity ? data.quantity.toFixed(2)
           .toString()
           .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
       this.form.notes = data.notes ? data.notes : ""
       this.form.tax_number = data.taxes.length > 0 ?  data.taxes[0].number : ""
       this.form.unit_number = data.unit.number
       this.form.date = data.stock.date_of_purchase
      }
    },
    getUnitData(unitData) {
      if (unitData) {
        this.$refs["unit_components"].addUnitLoader = false;
        this.$refs["unit_components"].form = {
          name: null,
          abbreviation: null,
          number: null
        };
        this.add_unit_dialog = false;
        this.form.unit_number = unitData.data.number
        this.successToast("Success", unitData.message)
        this.doGetUnitDataList();
      }
    },
    getStockableItems(itemDataList) {
      if(itemDataList){
        this.unfilteredItems = itemDataList
        this.ItemDataObj = this.newStockFilter(itemDataList);
      }

    },
    getTaxDataAll(taxData) {
      if (taxData) {
        this.taxItemDataObj = this.taxItemFilter(taxData);
        this.taxListUnfiltered = taxData
      }

    },
    getUnitDataList(unitDataList) {
      this.filterUnits(unitDataList);
    },
    getEditStockErrorMessage(data){
      if(data){
        this.btnSpinner = false
        this.errorToast("Error", data.message)
      }
    },
    getNewTaxData(data){
      if(data){
        this.form.tax_number = data.data.number
        this.$refs["new_tax_component"].form = {
          name:null,
          rate:null,
          profile_number:null,
          is_compound:false,
          is_absolute:false
        };
        this.$refs["new_tax_component"].taxSpinner = false;
        this.addTaxDialog = false;
        this.fetchTaxData();
        this.successToast("Success", data.message);
      }
    },
  }
}
</script>

<style scoped>

</style>