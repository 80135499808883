<template>
  <span>
    <v-card flat>
      <v-toolbar dense flat>
<!--        <v-app-bar-nav-icon></v-app-bar-nav-icon>-->
        <v-toolbar-title>Users</v-toolbar-title>
      </v-toolbar>

      <v-tabs>
        <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
        <v-tab>
          <v-icon left>mdi-account</v-icon>
          Users
        </v-tab>

        <v-tab v-if="checkLicenseStatus()">
          <v-icon left>mdi-lock</v-icon>
          New User
        </v-tab>

        <v-tab-item>
          <v-divider></v-divider>
          <v-card flat>
            <v-row class="mx-auto mt-8">
              <v-col cols="8"> </v-col>

              <v-col cols="4">
                <v-text-field
                  class="mr-3"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="innerUsersTableData"
              :search="search"
              :loading="tableInnerUsersLoader"
              loading-text="Loading... please wait"
              :page="page"
              :items-per-page="items_per_page"
              :server-items-length="server_items_length"
              :options.sync="paginationInnerUsers"
            >
              <template v-slot:item.roles="{ item }">
                <span v-for="(roles, index) in item.roles" :key="index">
                  <v-row>
                    <span>
                      {{ roles.name }}

                      <v-tooltip bottom v-if="roles.pivot.active === 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="roles.name"
                            v-on="on"
                            v-bind="attrs"
                            small
                            class="mr-2"
                            color="red"
                            @click="revertRoll(roles, item.number)"
                          >
                            mdi-block-helper
                          </v-icon>
                        </template>
                        <span>Revert Role</span>
                      </v-tooltip>

                      <v-chip
                        class="ma-2"
                        color="indigo darken-3"
                        outlined
                        small
                      >
                        {{ roles.pivot.active === 1 ? "Active" : "Inactive" }}
                      </v-chip>
                    </span>
                  </v-row>
                </span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editDiscount(item)">
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-divider></v-divider>

          <v-card flat>
            <v-row>
              <v-col cols="8" class="mx-auto mt-10">
                <new-user ref="users_components"></new-user>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </span>
</template>

<script>
import NewUser from "@/modules/dashboard/Settings/Users/NewUser";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Users",
  components: { NewUser },
  data() {
    return {
      innerUsersTableData: [],
      search: "",
      tableInnerUsersLoader: false,
      paginationInnerUsers: {},
      page: 1,
      items_per_page: 10,
      server_items_length: 0,
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Name", value: "name" },
        { text: "Gender", value: "gender" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Role", value: "roles", width: "25%" },
        { text: "Action", value: "actions", sortable: false, width: "5%" }
      ]
    };
  },
  methods: {
    ...mapActions(["fetchInnerUsers", "doRevertRole"]),
    filterInnerUserList(raw_data) {
      let innerUsersDataList = raw_data.data;
      this.server_items_length = raw_data.total;
      this.tableInnerUsersLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let innerUsersJsn = {};
      let innerUsersObj = [];

      for (let i = 0; i < innerUsersDataList.length; i++) {
        let userData = innerUsersDataList[i];
        innerUsersJsn = {
          key: (current_page - 1) * per_page + i + 1,
          name: userData.first_name + " " + userData.second_name,
          gender: userData.gender,
          email: userData.email,
          phone: userData.phone,
          roles: userData.roles,
          number: userData.number
        };
        innerUsersObj.push(innerUsersJsn);
      }
      this.innerUsersTableData = innerUsersObj;
    },
    checkRoles() {
      let options = {
        page: this.page,
        per_page: this.items_per_page
      };
      this.fetchInnerUsers(options);
    },
    revertRoll(roles, user_number) {
      let con = confirm("Are you sure? you want to revert this role.");
      if (!con) {
        return;
      }

      let data = {
        role_number: roles.number,
        user_number: user_number
      };
      this.doRevertRole(data);
    }
  },
  computed: {
    ...mapGetters([
      "getInnerUsersList",
      "getRegisterUserProfileData",
      "getRevertRolesAndPermissionList",
      "getRolesAndPermissionError"
    ])
  },
  watch: {
    paginationInnerUsers(obj) {
      this.tableInnerUsersLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage
      };
      this.fetchInnerUsers(options);
    },
    getInnerUsersList(innerUserData) {
      if (innerUserData) {
        this.filterInnerUserList(innerUserData);
      }
    },
    getRegisterUserProfileData(userProfileData) {
      if (userProfileData) {
        this.$refs["users_components"].registerSpinner = false;
        this.$refs["users_components"].form = {
          first_name: null,
          second_name: null,
          gender: null,
          email: null,
          phone: null,
          password: null,
          confirm_password: null,
          roles: []
        };
        this.successToast("Success", userProfileData.message);
        this.checkRoles();
      }
    },
    getRevertRolesAndPermissionList(revoke) {
      if (revoke) {
        this.successToast("Success", revoke.message);
        this.checkRoles();
      }
    },
    getRolesAndPermissionError(errorData) {
      if (errorData) {
        this.errorToast("Error", errorData.message);
      }
    }
  }
};
</script>

<style scoped></style>
