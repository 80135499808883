import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  storageAccountList: null,
  deleteStorageAccount: null,
  allStorageAccount: null,
  storageAccountListError: null
};

const getters = {
  getStorageAccountList: state => state.storageAccountList,
  getDeleteStorageAccount: state => state.deleteStorageAccount,
  getAllStorageAccount: state => state.allStorageAccount,
  getStorageAccountListError: state => state.storageAccountListError
};

const actions = {
  async getStorageAccountData({ commit }, data) {
    let search          = data ? data.search ? data.search : "" : ""
    await axios
      .get(
        IP +
          "bank-accounts/all" +
          "?page=" +
          data.page +
          "&per_page=" +
          data.per_page+
          "&search="+search
      )
      .then(response => {
        commit("setStorageAccountListData", response.data);
      })
      .catch(error => {
        commit("setStorageAccountError", error);
      });
  },
  async doDeleteStorageAccount({ commit }, data) {
    await axios
      .post(IP + "bank-accounts/delete/" + data)
      .then(response => {
        commit("setDeleteStorageAccountData", response);
      })
      .catch(error => {
        commit("setStorageAccountError", error);
      });
  },
  async fetchAllStorage({ commit }) {
    await axios
      .get(IP + "bank-accounts/all/")
      .then(response => {
        commit("setAllStorageAccounts", response);
      })
      .catch(error => {
        commit("setStorageAccountError", error);
      });
  }
};

const mutations = {
  setStorageAccountListData: (state, response) => {
    state.storageAccountList = response;
  },
  setDeleteStorageAccountData: (state, response) => {
    state.deleteStorageAccount = response;
  },
  setAllStorageAccounts: (state, response) => {
    state.allStorageAccount = response;
  },
  setStorageAccountError: (state, error) => {
    state.storageAccountListError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
