<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-toolbar dense flat>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
          <v-toolbar-title> Tax Payments </v-toolbar-title>

          <v-spacer></v-spacer>
          <span>

              <v-btn
                  class="ma-2"
                  outlined
                  color="#242f7e"
                  small
                  @click="fileExport"
                  :loading="exportSpinner"
              >
                <v-icon left>
                  mdi-download
                </v-icon>
                Export
              </v-btn>
            </span>
        </v-toolbar>

        <v-tabs v-model="activeTab">
          <v-tab href="#invoice">
            <v-icon left>mdi-account</v-icon>
            Tax Payments
          </v-tab>

          <v-tab v-if="checkLicenseStatus()" href="#new-invoice">
            <v-icon left>mdi-lock</v-icon>
           Pay Tax
          </v-tab>

          <v-tab-item id="invoice">
            <v-divider></v-divider>
           <tax-payment-table></tax-payment-table>
          </v-tab-item>

          <v-tab-item id="new-invoice">
            <v-divider></v-divider>
            <v-card flat>
              <v-row class="mx-auto mt-4">
                <v-col cols="5">
                  <tax-payment-form></tax-payment-form>
                </v-col>
              </v-row>

            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>




  </v-container>
</template>

<script>
import TaxPaymentForm from "@/modules/dashboard/accounting/taxPayments/TaxPaymentForm";
import TaxPaymentTable from "@/modules/dashboard/accounting/taxPayments/TaxPaymentTable";
export default {
  name: "TaxPayments",
  components: {TaxPaymentTable, TaxPaymentForm},
  data(){
    return{
      activeTab:null,
      exportSpinner:false
    }
  },
  methods:{
    fileExport(){

    }
  }
}
</script>

<style scoped>

</style>