<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Stock </v-toolbar-title>
            <v-spacer></v-spacer>
            <span>
              <v-btn
                class="ma-2 text-decoration-none"
                outlined
                small
                color="#1b5e20"
                :loading="downloadSpinner"
                @click="downloadTemplate"
              >
                <v-icon left>
                  mdi-import
                </v-icon>
                Template
              </v-btn>

              <v-btn
                class="ma-2"
                outlined
                color="#242f7e"
                small
                @click="fileUploader"
              >
                <v-icon left>
                  mdi-upload
                </v-icon>
                Import
              </v-btn>

              <v-btn
                class="ma-2"
                outlined
                color="#242f7e"
                small
                @click="fileExport"
                :loading="exportSpinner"
              >
                <v-icon left>
                  mdi-download
                </v-icon>
                Export
              </v-btn>
            </span>
          </v-toolbar>

          <v-tabs v-model="activeTab">
            <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->

            <v-tab v-if="checkLicenseStatus()" href="#new-stock">
              <v-icon left>mdi-lock</v-icon>
              New Stock
            </v-tab>

            <v-tab href="#stock">
              <v-icon left>mdi-account</v-icon>
              All Stock
            </v-tab>



              <v-tab href="#stock-movement">
                  <v-icon left>mdi-account</v-icon>
                  Stock Movement
              </v-tab>

            <v-tab href="#stock-adjustment">
                  <v-icon left>mdi-account</v-icon>
                  Stock Adjustment
              </v-tab>

            <v-tab-item id="new-stock">
              <v-divider></v-divider>

              <v-card flat>
                <div
                    style="width: 98%; padding-bottom: 15px;"
                    class="mt-8 mx-auto"
                >
                  <stock-options></stock-options>

                </div>
              </v-card>
            </v-tab-item>

            <v-tab-item id="stock">
              <v-divider></v-divider>
              <v-card flat>
                <v-row class="mx-auto mt-8">
                  <v-col cols="8">

                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      class="mr-3"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      dense
                      outlined
                      hide-details
                      v-on:keyup.enter="searchStock"
                    ></v-text-field>
                  </v-col>
                </v-row>


                <v-data-table
                  :headers="headers"
                  :items="stockTableData"
                  :search="search"
                  :loading="tableStockLoader"
                  loading-text="Loading... please wait"
                  item-key="name"
                  class="elevation-1"
                  :page="page"
                  :items-per-page="items_per_page"
                  :server-items-length="server_items_length"
                  :options.sync="paginationStock"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editStock(item)">
                      mdi-pencil
                    </v-icon>
<!--                    <v-icon small @click="deletePayment(item)">
                      mdi-delete
                    </v-icon>-->
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>



              <v-tab-item id="stock-movement">
                  <v-divider></v-divider>
                  <stock-movement-table></stock-movement-table>
              </v-tab-item>

            <v-tab-item id="stock-adjustment">
              <v-divider></v-divider>
              <stock-adjustment-info></stock-adjustment-info>
            </v-tab-item>



          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showEditStockDialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Stock</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showEditStockDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <edit-stock
              ref="edit_stock_component"
          ></edit-stock>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Import Stock</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="uploadDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <upload-file
            ref="upload_component"
            fileType="stock-items"
          ></upload-file>
        </div>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import configs from "@/global/configs.json";
import UploadFile from "@/modules/dashboard/accounting/UploadFile/UploadFile";
import router from "@/global/router";
import StockOptions from "@/modules/dashboard/accounting/Stock/StockOptions";
import EditStock from "@/modules/dashboard/accounting/Stock/EditStock";
import StockMovementTable from "@/modules/dashboard/accounting/Sales/StockMovementTable.vue";
import StockAdjustmentInfo from "@/modules/dashboard/accounting/Stock/StockAdjustmentInfo";
export default {
  name: "Stock",
  components: {StockAdjustmentInfo, EditStock, StockOptions, UploadFile ,StockMovementTable},
  data() {
    return {
      activeTab: "new-stock",
      stockTableData: [],
      showEditStockDialog: false,
      search: "",
      tableStockLoader: false,
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Date", value: "date_of_purchase" },
        { text: "Item", value: "item_name" },
        { text: "Reference", value: "reference" },
        { text: "Tax", value: "tax" },
        { text: "Quantity", value: "quantity" },
        { text: "Buying Price", value: "buying_price" },
        { text: "Selling Price", value: "selling_price" },
        { text: "Supplier", value: "supplier_name" },
        { text: "notes", value: "notes" },
        { text: "Action", value: "actions", sortable: false, width: "7%" }
      ],
      json_fields: {},
      page: 1,
      items_per_page: 10,
      server_items_length: 0,
      paginationStock: {},
      downloadLink: configs.SERVER_IP + "template?type=stock-items&file_token=",
      exportLink: configs.SERVER_IP + "export?type=stock-items&file_token=",
      uploadDialog: false,
      downloadSpinner: false,
      exportSpinner: false,
      additionalExpenseData:{},
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  methods: {
    ...mapActions([
      "doGetStockItemsList",
      "downloadTemplateFile",
      "exportFile",
      "fetchStockEditInfo"
    ]),
    filterStock(stockDataObj) {
      let stockData = stockDataObj.data;

      let current_page = stockDataObj.current_page;
      this.page = current_page;
      let per_page = stockDataObj.per_page;
      this.items_per_page = per_page * 1;
      this.server_items_length = stockDataObj.total;

      this.tableStockLoader = false;
      let stockJsn = {};
      let stockObj = [];

      // let date_of_purchase = "";

      stockData.forEach((list, i) => {
     /*   let _date_of_purchase = "";
        let stockData = list.stock ? list.stock : ""
        if (date_of_purchase !== stockData.date_of_purchase) {
          date_of_purchase = stockData ? stockData.date_of_purchase : "";
          _date_of_purchase = date_of_purchase;
        } else {
          _date_of_purchase = "";
        }*/

        stockJsn = {
          key: (current_page - 1) * per_page + i + 1,
          date_of_purchase: list.stock ? list.stock.date_of_purchase : "",
          item_name: list.item_name ? list.item_name : "",
          reference: list.stock ? list.stock.reference : "",
          number: list.number ? list.number : "",
          description: list.description ? list.description : "",
          quantity: list.quantity ? list.quantity.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
          buying_price: list.buying_price ? list.buying_price
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
          selling_price: list.selling_price ? list.selling_price
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
          supplier_name: list.stock ? list.stock.supplier_name : "",
          notes: list.notes ? list.notes : "",
          tax: list.tax.length > 0 ? list.tax[0].name +" ("+list.tax[0].rate+"%)" : ""
        };
        stockObj.push(stockJsn);
      })


      this.stockTableData = stockObj;
    },
    editStock(item) {
      if(item){
        this.fetchStockEditInfo(item.number)
        this.showEditStockDialog = true
      }
    },
    deleteExpense(item) {
      alert(JSON.stringify(item));
    },
    downloadTemplate() {
      this.downloadSpinner = true;
      this.downloadTemplateFile("stock-items");
    },
    fileExport() {
      this.exportSpinner = true;
      this.exportFile("stock-items");
    },
    fileUploader() {
      this.uploadDialog = true;
    },
    searchStock() {
      this.tableStockLoader = true;
      let options = {
        search: this.search,
        page: 1,
        per_page: this.items_per_page
      };

      this.doGetStockItemsList(options);

    }
  },
  computed: {
    ...mapGetters([
      "getStock",
      // "getNewStockDataList",
      "getTemplateDownloadData",
      "getUploadData",
      "getExportFileData",
       "getStockError",
        "getEditStockData"
    ])
  },
  watch: {

    getStock(stockData) {
      this.filterStock(stockData);
    },
    paginationStock(obj) {
      this.tableStockLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        search: this.search,
      };

      this.doGetStockItemsList(options);
    },
    getTemplateDownloadData(templateData) {
      if (templateData) {
        this.downloadSpinner = false;

        let fileUrl = this.downloadLink + templateData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },
    getExportFileData(exportData) {
      if (exportData) {
        this.exportSpinner = false;
        let fileUrl = this.exportLink + exportData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },

    getUploadData(uploadChartOfAccount) {
      if (uploadChartOfAccount) {
        this.uploadDialog = false;
        this.$refs["upload_component"].spinner = false;
        this.$refs["upload_component"].form = {
          attachment: null
        };
        this.successToast("Success", uploadChartOfAccount.message);
      }
    },
    activeTab(data) {
      router.push({ name: "stock", query: { type: data } });
    },
    getStockError(error){
        if(error){
            this.errorToast("Error", error.message)
        }
    },
    getEditStockData(data){
      if(data){
        this.$refs["edit_stock_component"].btnSpinner = false
        this.showEditStockDialog = false
        this.searchStock()
        this.successToast("Success", data.message)
      }
    }
  }
};
</script>

<style scoped></style>
