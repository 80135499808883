import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  unitData: null,
  unitErrorResponse: null,
  unitDataList: null,
  unitDataInfo: null,
  deleteUnit: null
};

const getters = {
  getUnitData: state => state.unitData,
  getUnitDataList: state => state.unitDataList,
  unitDataInfo: state => state.unitDataInfo,
  getDeleteUnit: state => state.deleteUnit,
  getUnitErrorResponse: state => state.unitErrorResponse
};

const actions = {
  async doSaveUnit({ commit }, data) {
    await axios
      .post(
        data.number ? IP + "units/update/" + data.number : IP + "units/new",
        data
      )
      .then(response => {
        commit("setNewUnitData", response);
      })
      .catch(error => {
        commit("setUnitErrorMessage", error);
      });
  },
  async doGetUnitDataList({ commit }) {
    await axios
      .get(IP + "units/all")
      .then(response => {
        commit("setUnitDataList", response);
      })
      .catch(error => {
        commit("setUnitErrorMessage", error);
      });
  },
  async doGetUnitList({ commit }, data) {
    await axios
      .get(
        IP + "units/all" + "?page=" + data.page + "&per_page=" + data.per_page
      )
      .then(response => {
        commit("setUnitData", response);
      })
      .catch(error => {
        commit("setUnitErrorMessage", error);
      });
  },
  async doDeleteUnit({ commit }, data) {
    await axios
      .delete(IP + "units/delete/" + data)
      .then(response => {
        commit("setDeleteUnit", response);
      })
      .catch(error => {
        commit("setUnitErrorMessage", error);
      });
  }
};

const mutations = {
  setNewUnitData: (state, response) => {
    state.unitData = response.data;
  },
  setUnitDataList: (state, response) => {
    state.unitDataList = response.data;
  },
  setUnitData: (state, response) => {
    state.unitDataInfo = response.data;
  },
  setDeleteUnit: (state, response) => {
    state.deleteUnit = response.data;
  },
  setUnitErrorMessage: (state, error) => {
    state.unitErrorResponse = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
