<template>
<span>
     <v-card flat>
                <v-row class="mx-auto mt-8">
                  <v-col cols="8">
<!--                    <download-excel
                        style="color:#777777; width: 30%; margin-left: 2%; cursor: pointer;"
                        class="btn btn-default mt-3"
                        :data="tableData"
                        :fields="json_fields"
                        worksheet="Expenses"
                        name="stock.xls"
                    >Download Excel
                      <v-icon color="#777777">
                        mdi-cloud-download
                      </v-icon>
                    </download-excel>-->
                  </v-col>

                  <v-col cols="4">
                     <v-text-field
                         v-model="search"
                         append-icon="mdi-magnify"
                         label="Search"
                         dense
                         outlined
                         persistent-hint
                         hint="Press enter to search"
                         v-on:keyup.enter="filterDepreciationTransactionInfo"
                     ></v-text-field>
                  </v-col>
                </v-row>

                <v-data-table
                    :headers="headers"
                    :items="tableData"
                    :search="search"
                    :loading="tableLoader"
                    loading-text="Loading... please wait"
                    :page="page"
                    :items-per-page="items_per_page"
                    :server-items-length="server_items_length"
                    :options.sync="pagination"
                    no-data-text="No Transaction Item found."
                    :footer-props="{
                      'itemsPerPageOptions': items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text':'Transaction per page'
                    }">
                   <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                        :pagination="pagination"
                        :options="options"
                        @update:options="updateOptions"
                        items-per-page-text="Transaction per page"
                        :items-per-page-options="items_per_page_options"
                        :showFirstLastPage="true"
                        firstIcon="mdi-arrow-collapse-left"
                        lastIcon="mdi-arrow-collapse-right"
                        prevIcon="mdi-chevron-left"
                        nextIcon="mdi-chevron-right"
                    />
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editDepreciationTransaction(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteDepreciationTransaction(item)">
                      mdi-delete
                    </v-icon>
                      <v-progress-circular
                          v-if="activeIndex === item.number"
                          width="1"
                          size="16"
                          color="#242f7e"
                          indeterminate
                      ></v-progress-circular>
                  </template>
                </v-data-table>
              </v-card>



  <v-dialog v-model="depreciationTransactionDialog" width="40%">
    <v-card>
      <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
        <v-row>
          <v-card-title>Edit Depreciation Transaction</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="depreciationTransactionDialog = false">
            <v-icon color="red">
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>


         <depreciation-transactions :itemsTransDataList="itemsTransDataList" ref="depreciation_transactions_content"></depreciation-transactions>


      </div>
    </v-card>
  </v-dialog>



 </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DepreciationTransactions from "@/modules/dashboard/accounting/Depreciation/DepreciationTransactions";

export default {
  name: "DepreciationTransactionTable",
  components: {DepreciationTransactions},
  data(){
    return{
      tableData: [],
      search: "",
      tableLoader: false,
      pagination: {},
      page: 1,
      items_per_page_options: [20, 50, 100, 500],
      items_per_page: 20,
      server_items_length: 0,
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Account Name", value: "account_name" },
        { text: "Date", value: "date" },
        { text: "Amount", value: "amount" },
        { text: "Action", value: "actions", sortable: false, width: "10%" }
      ],
      json_fields: {
        Name: "account_name",
        Rate: "date",
        MaxNumberOfYears: "amount"
      },
      activeIndex: undefined,
      depreciationTransactionDialog:false,
      depreciationTransactionsDialog:false,
      itemsTransDataList:{},
    }
  },
  methods:{
  ...mapActions(
      [
          "fetchTransactionDepreciationData",
          "deleteDepreciationTransactionData"
      ]
  ),
    filterDepreciationTransactionList(raw_data) {
      let discountData = raw_data.data;
      this.server_items_length = raw_data.total;
      this.tableLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let discountJsn = {};
      let discountObj = [];

      for (let i = 0; i < discountData.length; i++) {
        discountJsn = {
          key: (current_page - 1) * per_page + i + 1,
          amount: discountData[i].amount,
          date: discountData[i].date,
          number: discountData[i].number,
          account_name: discountData[i].account_name
        };
        discountObj.push(discountJsn);
      }

      this.tableData = discountObj;
    },
    filterDepreciationTransactionInfo(){
      this.tableLoader = true
      let options = {
        page: this.page,
        per_page: this.items_per_page,
        search: this.search,
      }
      this.fetchTransactionDepreciationData(options);
    },
    deleteDepreciationTransaction(items){

      let confirm_delete = confirm(
          "Are you sure you want to delete this item."
      );
      if (!confirm_delete) {
        return false;
      }

      this.activeIndex = items.number

      this.deleteDepreciationTransactionData(items.number)

    },
    editDepreciationTransaction(item){
      this.itemsTransDataList = item;
      this.depreciationTransactionDialog = true
    },
    inactivateLoader(){
      this.$refs["depreciation_transactions_content"].buttonLoader = false
    }
  },
  computed:{
    ...mapGetters(
        [
            "getDepreciationTransactionData"
        ]
    )
  },
  watch:{
    pagination(obj) {
      this.tableLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        search: this.search,
      };
      this.fetchTransactionDepreciationData(options);
    },
    getDepreciationTransactionData(data){
      if(data){
        this.filterDepreciationTransactionList(data);
      }
    },

  }
}
</script>

<style scoped>

</style>