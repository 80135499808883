<template>
  <v-container fluid>
    <v-card tile class="mb-3">
      <v-row class="mx-auto">
        <v-col cols="2">
          <v-menu
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mr-1"
                v-model="start_date"
                dense
                readonly
                v-bind="attrs"
                v-on="on"
                hint="Start Date [ YYY-MM-DD ]"
                persistent-hint
                clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="start_date" no-title></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2">
          <v-menu
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mr-1"
                v-model="end_date"
                dense
                readonly
                hint="End Date [ YYY-MM-DD ]"
                persistent-hint
                v-bind="attrs"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="end_date" no-title></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2">
          <v-menu
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            origin="center center"
            transition="fab-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="border: 1px solid #9e9e9e; height: 40px; color: #777777;"
                light
                v-bind="attrs"
                v-on="on"
                tile
              >
                <v-icon class="mr-4">
                  mdi mdi-filter
                </v-icon>
                Filter
                <v-icon>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list nav dense>
                <v-list-item-group color="primary">
                  <v-span v-for="(item, i) in filterList" :key="i">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="(i + 1) % 3 === 0"></v-divider>
                  </v-span>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>

    <v-card tile>
      <v-row class="mx-auto">
        <v-col>

          <v-row class="mx-auto">
            <v-card-title>Balance Sheet</v-card-title>
            <v-spacer></v-spacer>

              <span>
              <v-btn
                  class="ma-2"
                  outlined
                  color="#242f7e"
                  small
                  @click="fileExport"
              >
                <v-icon left>
                  mdi-file-pdf-box
                </v-icon>
                Export PDF
              </v-btn>
            </span>

          </v-row>


          <v-card-text>
           Company {{ company_name }} <br />
<!--            For the month ended 28 February 2021-->
          </v-card-text>

          <v-row class="mx-auto">
            <v-spacer></v-spacer>
            <p>
<!--              <v-card-text class="text&#45;&#45;disabled">Feb 2021</v-card-text>-->
            </p>
          </v-row>

          <v-divider></v-divider>

          <v-card-title>Assets</v-card-title>

          <v-divider></v-divider>

          <p class="mt-5 ml-10"><b>Bank</b></p>
          <v-divider></v-divider>
          <v-simple-table class="ml-8" dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(bank, index) in balance_sheet_info.banks"
                  :key="index"
                >
                  <td>
                    <span class="ml-3">{{ bank.name }}</span>
                  </td>
                  <td style="text-align: right">
                    <a
                        @click="showAdditionalDetails(bank)"
                        class="text-decoration-none linkStyle"
                    >
                    {{
                      transformNumbersToAccountingStandard(bank.amount)
                    }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="ml-3"><b>Total Bank</b></span>
                  </td>
                  <td style="text-align: right">
                    <b>{{
                        transformNumbersToAccountingStandard(balance_sheet_info.total_bank_amount)
                    }}</b>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <p class="mt-5 ml-10"><b>Current Assets</b></p>
          <v-divider></v-divider>
          <v-simple-table class="ml-8" dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(current, index) in balance_sheet_info.current_assets"
                  :key="index"
                >
                  <td>
                    <span class="ml-3">{{ current.name }}</span>
                  </td>
                  <td style="text-align: right">
                    <a
                        @click="showAdditionalDetails(current)"
                        class="text-decoration-none linkStyle"
                    >
                    {{
                      transformNumbersToAccountingStandard(current.total_worth)
                    }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="ml-3"><b>Total Current Assets</b></span>
                  </td>
                  <td style="text-align: right">
                    <b>{{
                        transformNumbersToAccountingStandard(balance_sheet_info.total_current_assets)
                    }}</b>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <p class="mt-5 ml-10"><b>Fixed Assets</b></p>
          <v-divider></v-divider>
          <v-simple-table class="ml-8" dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(fixed, index) in balance_sheet_info.fixed_assets"
                  :key="index"
                >
                  <td>
                    <span class="ml-3">{{ fixed.name }}</span>
                  </td>
                  <td style="text-align: right">
                    <a
                        @click="showAdditionalDetails(fixed)"
                        class="text-decoration-none linkStyle"
                    >
                    {{
                      transformNumbersToAccountingStandard(fixed.total_worth)
                    }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="ml-3"><b>Total Fixed Assets</b></span>
                  </td>
                  <td style="text-align: right">
                    <b>{{
                        transformNumbersToAccountingStandard(balance_sheet_info.total_fixed_assets)
                    }}</b>
                  </td>
                </tr>
                <tr>
                  <td><b>Total Assets</b></td>
                  <td style="text-align: right">
                    <b>{{
                        transformNumbersToAccountingStandard(balance_sheet_info.total_assets)
                    }}</b>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-card-title class="mt-7">Liabilities</v-card-title>

          <v-divider></v-divider>


          <p class="mt-5 ml-10"><b>Current Liabilities</b></p>
          <v-divider></v-divider>
          <v-simple-table class="ml-8" dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(current,
                  index) in balance_sheet_info.current_liabilities"
                  :key="index"
                >
                  <td>
                    <span class="ml-3">{{ current.name }}</span>
                  </td>
                  <td style="text-align: right">
                    <a
                        @click="showAdditionalDetails(current)"
                        class="text-decoration-none linkStyle"
                    >
                    {{
                      transformNumbersToAccountingStandard(current.total_worth)
                    }}
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="ml-3"><b>Total Current Liabilities</b></span>
                  </td>
                  <td style="text-align: right">
                    <b>{{
                        transformNumbersToAccountingStandard(balance_sheet_info.total_current_liabilities)
                    }}</b>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <p class="mt-5 ml-10"><b>Long Term Liabilities</b></p>
          <v-divider></v-divider>
          <v-simple-table class="ml-8" dense>
            <template v-slot:default>
              <tbody>
              <tr
                  v-for="(long,index) in balance_sheet_info.long_term_liabilities"
                  :key="index"
              >
                <td>
                  <span class="ml-3">{{ long.name }}</span>
                </td>
                <td style="text-align: right">
                  <a
                      @click="showAdditionalDetails(long)"
                      class="text-decoration-none linkStyle"
                  >
                  {{
                    transformNumbersToAccountingStandard(long.total_worth)
                  }}
                  </a>
                </td>
              </tr>

              <tr>
                <td>
                  <span class="ml-3"><b>Total Long Term Liabilities</b></span>
                </td>
                <td style="text-align: right">
                  <b>{{
                      transformNumbersToAccountingStandard(balance_sheet_info.total_long_term_liabilities)
                    }}</b>
                </td>
              </tr>
              <tr>
                <td><b>Total Liabilities</b></td>
                <td style="text-align: right">
                  <b>{{
                      transformNumbersToAccountingStandard(balance_sheet_info.total_liabilities)
                    }}</b>
                </td>
              </tr>
              <tr>
                <td><b>Net Assets</b></td>
                <td style="text-align: right; font-size: 17px; color: #073983;">
                  <b>{{
                      transformNumbersToAccountingStandard(balance_sheet_info.net_assets)
                    }}</b>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>


          <p class="mt-5 ml-10"><b>Equity</b></p>
          <v-divider></v-divider>
          <v-simple-table class="ml-8" dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(equi, index) in balance_sheet_info.equity"
                  :key="index"
                >
                  <td>
                    <span class="ml-3">{{ equi.name }}</span>
                  </td>
                  <td style="text-align: right">
                    <a
                        @click="showAdditionalDetails(equi)"
                        class="text-decoration-none linkStyle"
                    >
                    {{
                      transformNumbersToAccountingStandard(equi.total_worth)
                    }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="ml-3"><b>Total General Equity</b></span>
                  </td>
                  <td style="text-align: right">
                    <b>{{
                        transformNumbersToAccountingStandard(balance_sheet_info.total_equity)
                    }}</b>
                  </td>
                </tr>

                <tr>
                    <td>
                        <span class="ml-1"><b>Net Equity</b></span>
                    </td>
                    <td style="text-align: right; font-size: 17px; color: #073983;">
                        <b>{{
                            transformNumbersToAccountingStandard(balance_sheet_info.net_equity)
                            }}</b>
                    </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="showTransactionsDialog" width="60%">
      <v-card>
        <div style="width: 100%; padding-bottom: 20px;" class="mx-auto">
          <v-row class="mx-auto">
            <v-card-title>Transactions</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showTransactionsDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <balance-sheet-transactions></balance-sheet-transactions>
        </div>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import configs from "@/global/configs.json";
import BalanceSheetTransactions from "@/modules/dashboard/accounting/BalanceSheet/BalanceSheetTransactions";
export default {
  name: "BalanceSheet",
  components: {BalanceSheetTransactions},
  data() {
    return {
      company_profile: this.$company,
      company_name: null,
      showTransactionsDialog:false,
      balance_sheet_info: {
        net_equity: {},
        total_current_liabilities: {},
        total_fixed_assets: {},
        total_liabilities: {},
        net_assets: {},
        total_bank_amount: {},
        total_assets: {},
        total_current_assets: {}
      },
      filterList: [
        { text: "This Month", icon: "mdi-folder" },
        { text: "This Quarter", icon: "mdi-account-multiple" },
        { text: "This Financial Year", icon: "mdi-star" },
        { text: "Last Month", icon: "mdi-history" },
        { text: "Last Quarter", icon: "mdi-check-circle" },
        { text: "Last Financial Year", icon: "mdi-upload" },
        { text: "Year to Date", icon: "mdi-cloud-upload" }
      ],
      start_date: "",
      end_date: "",
      downloadLink:
          configs.SERVER_IP + "reports/export/balance-sheet?token=",
    };
  },
  mounted() {
    this.company_name = this.company_profile.company_name.toUpperCase();
    let data = {
      start_date: this.start_date,
      end_date: this.end_date
    };
    this.doGetBalanceSheet(data);
  },
  methods: {
    ...mapActions(
        [
            "fetchBalanceSheetTransactions",
            "doGetBalanceSheet"
        ]
    ),
    filterData() {
      let data = {
        start_date: this.start_date,
        end_date: this.end_date
      };
      this.doGetBalanceSheet(data);
    },
    fileExport() {
      this.downloadSpinner = false;
      let templateData  = localStorage.getItem("token");
      let companyNumber  = JSON.parse(localStorage.getItem("company")).number;

      let fileUrl = this.downloadLink + templateData+"&company-number="+companyNumber;

      const pdfObj = document.createElement("a");
      pdfObj.href = fileUrl;
      pdfObj.click();
    },
    showAdditionalDetails(draft){
      if(draft){
        this.showTransactionsDialog = true
        this.fetchBalanceSheetTransactions(draft.number)
      }
    }
  },
  computed: {
    ...mapGetters(["getBalanceSheetData"])
  },
  watch: {
    getBalanceSheetData(balanceData) {
      this.balance_sheet_info = balanceData;
    },
    start_date() {
      this.filterData();
    },
    end_date() {
      this.filterData();
    }
  }
};
</script>

<style scoped>
.linkStyle {
  text-decoration: none;
  color: #232323;
  /*font-size: 15px;*/
}

.linkStyle:hover {
  /*font-size: 15px;*/
  color: green;
  text-decoration: underline;
}
</style>
