import axios from "axios";
import configs from "../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  userProfileDataInfo: null,
  loginToken: null,
  loginStatusResponse: false,
  loginMessage: null,
  requestPassword: null,
  setPassword: null,
  activateAccount: null,
  token: localStorage.getItem("userProfile") || ""
};

const getters = {
  getUserProfileData: state => state.userProfileDataInfo,
  getLoginToken: state => state.loginToken,
  getLoginStatus: state => state.loginStatusResponse,
  getLoginMessage: state => state.loginMessage,
  getRequestPassword: state => state.requestPassword,
  getSetPassword: state => state.setPassword,
  getActivateAccount: state => state.activateAccount,
  isAuthenticated: state => !!state.token
};

const actions = {
  async loginUser({ commit }, data) {
    delete axios.defaults.headers.common['Authorization']
    await axios
      .post(IP + "auth/login", data)
      .then(response => {
        commit("setLoginToken", response);
      })
      .catch(error => {
        localStorage.removeItem("token");
        commit("setLoginErrorMessage", error);
      });
  },
  async getUserProfile({ commit }, token) {
    let url = "auth/profile?token=" + token;

    if (token == null) {
      url = "auth/profile";
    }

    await axios
      .get(IP + url, {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      .then(response => {
        commit("setUserProfileDataInfo", response);
      })
      .catch(error => {
        localStorage.removeItem("userProfile");
        commit("setLoginErrorMessage", error);
      });
  },
  async userLogout() {
    localStorage.clear();

    let r = this.$router.resolve({
      name: "login"
    });
    window.location.assign(r.href);
  },
  async requestResetPassword({ commit }, data) {
    await axios
      .post(IP + "auth/request-reset-password", data)
      .then(response => {
        commit("setRequestPassword", response);
      })
      .catch(error => {
        commit("setLoginErrorMessage", error);
      });
  },
  async setPassword({ commit }, data) {
    await axios
      .post(IP + "auth/reset-password", data)
      .then(response => {
        commit("setPassword", response);
      })
      .catch(error => {
        localStorage.removeItem("token");
        commit("setLoginErrorMessage", error);
      });
  },
  async doActivateAccount({ commit }, data) {
    await axios
      .post(IP + "auth/activate", data)
      .then(response => {
        commit("setActivateAccount", response);
      })
      .catch(error => {
        localStorage.removeItem("token");
        commit("setLoginErrorMessage", error);
      });
  }
};

const mutations = {
  setUserProfileDataInfo: (state, response) => {
    let user = response.data;
    localStorage.setItem("userProfile", JSON.stringify(user));
    let abilities = user.permissions;
    localStorage.setItem("abilities", JSON.stringify(abilities));

    state.loginStatusResponse = true;
    state.userProfileDataInfo = response.data;
  },

  setLoginToken: (state, response) => {
    state.loginToken = response.data;
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("expires_in", response.data.expires_in);
  },

  setRequestPassword: (state, response) => {
    state.requestPassword = response.data;
  },
  setPassword: (state, response) => {
    state.setPassword = response.data;
  },
  setActivateAccount: (state, response) => {
    state.activateAccount = response.data;
  },
  setLoginErrorMessage: (state, error) => {
    state.loginStatusResponse = false;
    state.loginMessage = error.response
      ? error.response.data
      : "Error: Network Error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
