<template>
  <span>
    <form ref="form" @submit.prevent="payBill()">
      <v-row class="mx-auto">
        <v-col>
          <v-alert type="error" v-if="supplierPaymentError">
            {{ supplierPaymentError }}
          </v-alert>

          <v-autocomplete
            outlined
            v-model="form.profile_number"
            :items="supplierDataList"
            item-value="id"
            color="#1976d2"
            label="Customer / supplier"
            dense
            clearable
            append-outer-icon="mdi-plus"
            @click:append-outer="addSupplierModel"
            @change="filterSupplierBills"
          ></v-autocomplete>

          <v-autocomplete
            outlined
            v-model="billNumber"
            :items="billDataList"
            item-value="id"
            color="#1976d2"
            label="Bills"
            dense
            clearable
            hide-details
            :loading="billLoader"
            @change="getBillsInfo"
          ></v-autocomplete>
          <small class="ml-2" style="color: red;" v-if="billErrorResp">{{
            billErrorResp
          }}</small>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          style="background-color: #0000ff; color: #ffffff"
          :disabled="disabledButton"
          block
          type="submit"
        >
          Pay Bill
        </v-btn>
      </v-card-actions>
    </form>

    <!--supplier dialog-->
    <v-dialog v-model="addSupplierDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Supplier</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addSupplierDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-supplier ref="add_supplier_info"></add-supplier>
        </div>
      </v-card>
    </v-dialog>

    <!--      adds bill-->
    <v-dialog v-model="payBillDialog" width="40%">
      <v-card>
        <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Pay Bill</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payBillDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <pay-invoice-bill
            ref="paymentComponents"
            :invoiceInfo="billDataDetails"
            receiptType="Bill"
          ></pay-invoice-bill>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import AddSupplier from "@/modules/dashboard/accounting/commons/AddSupplier";
import { mapActions, mapGetters } from "vuex";
import PayInvoiceBill from "@/modules/dashboard/accounting/commons/PayInvoiceBill";
export default {
  name: "NewSupplierPayouts",
  components: { PayInvoiceBill, AddSupplier },
  data() {
    return {
      supplierPaymentError: null,
      addSupplierDialog: false,
      supplierDataList: [],
      billDataList: [],
      billLoader: false,
      form: {
        profile_number: null
      },
      payBillDialog: false,
      billErrorResp: null,
      billNumber: null,
      fullBillDataList: {},
      billDataDetails: {},
      disablePayButton: true,
      disabledButton: true
    };
  },
  mounted() {
    this.fetchProfiles();
  },
  methods: {
    ...mapActions([
      "fetchProfiles",
      "fetchCustomerSupplierBillInvoices",
       "getInvoiceBillBalance"
    ]),

    addSupplierModel() {
      this.addSupplierDialog = true;
    },
    filterSupplierList(supplierList) {
      let dataObj = [{ text: "Select Supplier", id: null, disabled: true }];
      let dataJson = {};
      supplierList.forEach(lst => {
        dataJson = {
          text: lst.name,
          id: lst.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })

      this.supplierDataList = dataObj;
    },
    filterSupplierBills() {
      this.billLoader = true;
      this.billErrorResp = null;
      let data = {
        path: "bills/due/" + this.form.profile_number
      };
      this.fetchCustomerSupplierBillInvoices(data);
    },
    doFilterBillList(billDataInfo) {
      this.billLoader = false;
      this.fullBillDataList = billDataInfo;
      if (billDataInfo.length === 0) {
        this.disabledButton = true;
        this.billErrorResp = "No Bills";
      }

      let dataObj = [{ text: "Select Bill", id: null, disabled: true }];
      let dataJson = {};
      for (let i = 0; i < billDataInfo.length; i++) {
        dataJson = {
          text:
            billDataInfo[i].counter +
            " [ " +
            billDataInfo[i].total_amount.due
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " ]",
          id: billDataInfo[i].number,
          disabled: false
        };
        dataObj.push(dataJson);
      }
      this.billDataList = dataObj;
    },
    payBill() {
      this.payBillDialog = true;
      this.getInvoiceBillBalance(this.billDataDetails.number);
    },
    getBillsInfo() {
      let number = this.billNumber;

      let billObj = this.fullBillDataList;

      let obj = {};
      for (let i = 0; i < billObj.length; i++) {
        if (billObj[i].number === number) {
          obj = {
            number: billObj[i].number,
            counter: billObj[i].counter
          };
        }
      }

      this.disabledButton = false;

      this.billDataDetails = obj;
    }
  },
  computed: {
    ...mapGetters([
      "getProfileDataList",
      "getSupplierData",
      "getBillInvoiceDataInfoData",
      "getBillError",
      "getInvoicePaymentData"
    ])
  },
  watch: {
    getProfileDataList(supplierDataList) {
      this.filterSupplierList(supplierDataList);
    },
    getSupplierData(supplierData) {
      if (supplierData) {
        this.$refs["add_supplier_info"].addSupplierLoader = false;
        this.$refs["add_supplier_info"].form = {
          name: null,
          address: null,
          tin: null,
          phoneContact: [
            {
              contact: null
            }
          ],
          emailContact: [
            {
              contact: null
            }
          ]
        };
        this.addSupplierDialog = false;
        this.successToast("Success", supplierData.message);
        this.form.profile_number = supplierData.data.profile_number;
        this.fetchProfiles();
      }
    },
    getBillInvoiceDataInfoData(billDataInfo) {
      if (billDataInfo) {
        this.doFilterBillList(billDataInfo);
      }
    },
    getBillError(data) {
      if(data) {
        this.errorToast("Error", data.data.message)
      }
    },
    getInvoicePaymentData(billData) {
      if (billData) {
        this.$refs["paymentComponents"].form = {
          invoice_number: this.billDataDetails.number,
          amount: null,
          payment_method_number: null,
          storage_account_number: null,
          payment_date: new Date().toISOString().substr(0, 10),
          notes: null
        };
        this.$refs["paymentComponents"].invoiceLoader = false;
        this.payBillDialog = false;
        this.successToast("Success", billData.message);

        //  here we need to clear the form form for you to select again
        this.form.profile_number = null;
        this.billDataList = [];
      }
    }
  }
};
</script>

<style scoped></style>
