<template>
<span>
   <form ref="form" @submit.prevent="saveItem">

  <v-row>
    <v-col cols="4">

      <v-text-field
          v-model="form.name"
          label="Name"
          outlined
          dense
          required
      ></v-text-field>

  <v-text-field
      v-model="form.code"
      label="Code"
      outlined
      dense
      hide-details
  ></v-text-field>
    </v-col>


     <v-col cols="4">
     <v-autocomplete
         outlined
         v-model="form.unit_number"
         :items="unitDataList"
         item-value="id"
         label="Unit of Measure"
         append-outer-icon="mdi-plus"
         @click:append-outer="addItemUnitData"
         dense
         clearable
     ></v-autocomplete>


 <v-autocomplete
     outlined
     v-model="form.current_assets_account_number"
     :items="currentAssetAccountDataList"
     item-value="id"
     color="pink"
     label="Current Assets Account *"
     append-outer-icon="mdi-plus"
     @click:append-outer="addAccountModel"
     required
     clearable
     dense
     hide-details
 ></v-autocomplete>

     </v-col>

     <v-col cols="4">
          <v-autocomplete
              outlined
              v-model="form.income_account_number"
              :items="incomeAccountDataList"
              item-value="id"
              color="pink"
              label="Income Account"
              append-outer-icon="mdi-plus"
              @click:append-outer="addIncomeAccountModel"
              required
              dense
              clearable
          ></v-autocomplete>

       <v-autocomplete
           outlined
           v-model="form.cogs_account_number"
           :items="costOfGoodsAccountDataList"
           item-value="id"
           color="pink"
           label="Cost of Goods Account *"
           append-outer-icon="mdi-plus"
           @click:append-outer="addCostOfGoodsAccountModel"
           required
           dense
           clearable
           hide-details
       ></v-autocomplete>


    </v-col>
  </v-row>

<v-row>
  <v-col cols="4">

              <v-checkbox
                  dense
                  v-model="showTaxItems"
                  class="shrink mr-3 mt-0 float-left"
                  label="is item taxable?"
                  id="sub_account_id"
                  hide-details
              ></v-checkbox>

              <v-autocomplete
                  v-if="showTaxItems"
                  outlined
                  v-model="form.tax_item_numbers"
                  :items="taxItemDataObj"
                  item-value="id"
                  color="pink"
                  label="Tax Item"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="addTaxAccountModel"
                  multiple
                  hide-selected
                  hide-details
                  clearable
                  dense
              ></v-autocomplete>


  </v-col>

  <v-col cols="4">
     <v-text-field
         v-model="form.selling_price"
         label="Selling Price"
         outlined
         v-on:blur="computeNumber(form.selling_price, 'selling_price')"
         @keypress="onlyNumberValidator($event)"
         dense
         hide-details
     ></v-text-field>
    <small style="color: red;" v-html="SellingPxError"></small>

  </v-col>


  <v-col cols="4">
      <v-textarea
          v-model="form.notes"
          label="Item Description"
          outlined
          rows="2"
          dense
          hide-details
      ></v-textarea>
  </v-col>



</v-row>

<v-row>
    <v-col cols="12">

       <p class="mt-5">Bill of Items</p>

            <v-simple-table  class="elevation-1"  dense >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th style="border-right: 1px solid #e0e0e0; width: 25%;" class="text-left">Item</th>
                     <th style="border-right: 1px solid #e0e0e0; width: 15%;" class="text-left">Unit</th>
                    <th style="border-right: 1px solid #e0e0e0; width: 20%;" class="text-left">Description</th>
                    <th style="border-right: 1px solid #e0e0e0; width: 15%;" class="text-left">Quantity</th>
                     <th style="border-right: 1px solid #e0e0e0; width: 15%;" class="text-left">Unit Cost</th>
                    <th style="border-right: 1px solid #e0e0e0; width: 20%;" class="text-left" colspan="2">Total</th>
                  </tr>
                </thead>
                <tbody>

                  <tr
                      v-for="(billOfItemsData, index) in bill_of_items"
                      :key="index"
                  >
                    <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                      <v-select
                          flat
                          solo
                          v-model="billOfItemsData.part_item_number"
                          :items="billOfItemsData.items"
                          item-value="id"
                          color="pink"
                          label=""
                          title="Click here to add a new User Type"
                          hide-details
                          clearable
                          dense
                          required
                          v-on:change="prefillData(billOfItemsData.part_item_number, index)"
                      ></v-select>
                    </td>
                            <td  class="pa-0" style="border-right: 1px solid #e0e0e0;">
                         <v-autocomplete
                             solo
                             flat
                             readonly
                             dense
                             v-model="billOfItemsData.unit_number"
                             :items="unitDataList"
                             item-value="id"
                             label="Unit of Measure"
                             hide-details
                         ></v-autocomplete>
                     </td>
                    <td  class="pa-0" style="border-right: 1px solid #e0e0e0;">
                        <v-textarea
                            v-model="billOfItemsData.notes"
                            flat
                            readonly
                            solo
                            rows="1"
                            dense
                            hide-details
                        ></v-textarea>
                    </td>

                    <td  class="pa-0" style="border-right: 1px solid #e0e0e0;">
                      <v-text-field
                          v-model="billOfItemsData.quantity"
                          label=""
                          @keypress="onlyNumberValidator($event)"
                          flat
                          placeholder="0.0000000"
                          solo
                          dense
                          @input="computeQuantity(billOfItemsData.quantity, index)"
                          hide-details
                          required
                          v-on:blur="
                          computeNumber(
                            billOfItemsData.quantity,
                            'quantity',
                            index
                          )
                        "
                      ></v-text-field>
                    </td>

                        <td  class="pa-0" style="border-right: 1px solid #e0e0e0;">
                          <v-text-field
                              v-model="billOfItemsData.cost"
                              flat
                              solo
                              readonly
                              @keypress="onlyNumberValidator($event)"
                              dense
                              hide-details
                          ></v-text-field>
                    </td>
                     <td  class="pa-0">

                         <v-text-field
                             v-model="billOfItemsData.total"
                             flat
                             readonly
                             solo
                             @keypress="onlyNumberValidator($event)"
                             dense
                             hide-details
                         ></v-text-field>

                     </td>
                    <td width="1%">
                      <v-icon
                          @click="removeInventoryPartItem(index)"
                          v-if="index !== 0"
                          style="font-size: 20px;"
                      >mdi-close</v-icon
                      >
                    </td>
                  </tr>
                  <tr style="color: #03036b;">
                    <td><b class="ml-4"></b></td>
                    <td><b class="ml-4"></b></td>
                    <td><b class="ml-4"></b></td>
                    <td><b class="ml-4"></b></td>
                    <td style="border-left: 1px solid #e0e0e0;"><b class="ml-4">Total</b></td>
                    <td colspan="2"><b class="ml-4"> {{computeTotalValue}}</b></td>
                  </tr>


                <tr>
                      <td
                          class="pa-0"
                          colspan="6"
                          style="cursor: pointer;"
                      >
                         <v-btn

                             @click="addInventoryPartItem"
                             x-small
                             dark
                             color="indigo"
                         >
                            Add another item
                        </v-btn>
                      </td>
                    </tr>

                </tbody>
              </template>
            </v-simple-table>




    </v-col>
  </v-row>

      <v-row class="mt-5">
        <v-spacer></v-spacer>
        <v-btn
            class="companyButton"
            :loading="addItemLoader"
            color="#0000ff"
            dark
            type="submit"
        >
          {{ inventoryAssemblyDataInfo.number ? "Edit Item" : "Save Item" }}
        </v-btn>
      </v-row>
   </form>


     <v-dialog v-model="add_item_unit_dialog" width="30%">
      <v-card>
        <div style="width: 95%; padding-bottom: 10px;" class="mx-auto">
          <v-row>
            <v-card-title> Add Unit</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_item_unit_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-unit ref="unit_components"></add-unit>
        </div>
      </v-card>
    </v-dialog>


  <!--    dialog to add current assets-->
    <v-dialog v-model="current_asset_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="current_asset_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              account_type="CAST"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

  <!--    dialog to add income account-->
    <v-dialog v-model="income_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="income_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              account_type="INC"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

  <!--    dialog to add cost of goods account-->
    <v-dialog v-model="cost_of_goods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="cost_of_goods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <add-account
              account_type="COS"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>




  <!--tax item dialog-->
    <v-dialog v-model="addTaxDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Tax</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addTaxDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-tax ref="new_tax_component"></new-tax>
        </div>
      </v-card>
    </v-dialog>


  <!--    add item suppliers dialog-->
    <v-dialog v-model="add_item_supplier_dialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Supplier</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_item_supplier_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-profile ref="profile_component"></new-profile>
        </div>
      </v-card>
    </v-dialog>

</span>
</template>

<script>
import AddAccount from "@/modules/dashboard/accounting/commons/AddAccount";
import AddUnit from "@/modules/dashboard/accounting/commons/AddUnit";
import {mapActions, mapGetters} from "vuex";
import NewTax from "@/modules/dashboard/Settings/tax/NewTax";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";

export default {
  name: "InventoryAssemblyItem",
  components: {NewProfile, NewTax, AddAccount, AddUnit},
  props:{
    itemType:String,
    inventoryAssemblyDataInfo:{}
  },
  data(){
    return{
      addItemLoader:false,
      showOpeningStock:false,
      super_item:null,
      item_number:null,
      bill_of_items_info:[],
      form:{
        name:null,
        code:null,
        unit_number:"",
        current_assets_account_number:"",
        income_account_number:"",
        cogs_account_number:"",
        notes:null,
        selling_price:null,
        super_item:null,
        tax_item_numbers:[],
        profile_number:null,
        openingStock:null,
      },
      openingStock:{
        quantity:null,
        buying_price:null,
        selling_price:null,
        date_of_purchase:new Date().toISOString().substr(0, 10),
        reference:null,
      },
      taxItemDataObj: [],
      supplierDataObj: [],
      showSubItemCategory: false,
      showTaxItems: false,
      unitDataList:[],
      currentAssetAccountDataList:[],
      incomeAccountDataList:[],
      costOfGoodsAccountDataList:[],
      add_item_unit_dialog: false,
      current_asset_dialog: false,
      income_dialog: false,
      cost_of_goods_dialog: false,
      subItemDataList: [],
      addSuperItemDialog: false,
      addTaxDialog: false,
      add_item_supplier_dialog: false,
      bill_of_items: [],
      inventoryPartDataObj: [],
      inventoryPartDataObjUnfiltered: [],
      SellingPxError: "",
    }
  },
  mounted() {
    this.doGetUnitDataList();
    this.fetchAccountData("CAST");
    this.fetchAccountData("INC");
    this.fetchAccountData("COS");
    this.filterSubItemList()
    this.fetchTaxData();
    this.fetchProfiles();
    this.fetchItemsSetup();
    this.checkAssemblyInfo()
  },
  methods:{
    ...mapActions(
        [
          "saveInventoryAssemblyItem",
          "doGetUnitDataList",
          "doFetchAccountData",
          "doGetSubItemData",
          "fetchTaxData",
          "fetchProfiles",
          "fetchItemsSetup",
          "editServiceItem",
          "fetchStockedAssemblyItems"
        ]
    ),
    saveItem(){
      this.$emit("itemsErrorRemoval", "data");

      if(this.form.selling_price < 0){
        this.errorMessage = "Please provide selling price!"
        this.errorToast("Error", "Please provide selling price!")
        return
      }

      if(parseFloat(this.form.selling_price.toString().replace(/,/g, "")) < parseFloat(this.computeTotalValue.toString().replace(/,/g, ""))){
        let confirm_loss = confirm(
            "Are you sure you want to sell this item at a loss?"
        );
        if (!confirm_loss) {
          return false;
        }
      }



      let formData = this.form
      formData.itemType = this.itemType;
      formData.bill_of_items = this.bill_of_items;
      formData.selling_price = (this.form.selling_price.toString().replace(/,/g, "") * 1);

      if (!this.form.unit_number) {
        let confirm_unit = confirm(
            "Are you sure you want to continue without a unit of measure"
        );
        if (!confirm_unit) {
          return
        }
      }

       this.addItemLoader = true
      if (this.item_number) {
        formData.number = this.item_number;
        this.editServiceItem(formData)
      } else {
        this.saveInventoryAssemblyItem(formData)
      }

    },
    addItemUnitData() {
      this.add_item_unit_dialog = true;
    },
    addAccountModel() {
      this.current_asset_dialog = true;
    },
    addIncomeAccountModel() {
      this.income_dialog = true;
    },
    addCostOfGoodsAccountModel() {
      this.cost_of_goods_dialog = true;
    },

    computeQuantity(value, index){

      let quantity =  value.toString().replace(/,/g, "")

      if (!isNaN(value)) {
        quantity = quantity * 1;
      }

      quantity =  eval(value)


      this.bill_of_items[index].total =  ((this.bill_of_items[index].cost.toString().replace(/,/g, "") * 1) * quantity).toLocaleString('en-US')
    },
    computeNumber(value, vModal, index = null) {
      ///this removes any value that starts with a zero

      value = (value.toString().replace(/,/g, ""))

      if (!isNaN(value)) {
        value = value * 1;
      }

      if (vModal === "buying_price") {
        this.form.buying_price =
            eval(value) > 0 ? eval(value) : 0;
      } else if (vModal === "selling_price") {
        this.form.selling_price =
            eval(value) > 0 ? eval(value).toLocaleString('en-US') : 0;
      } else if (vModal === "quantity") {
        let qty = eval(value)
        this.bill_of_items[index].quantity = this.countDecimalPlaces(qty) < 8 ? qty : qty.toFixed(7)
      } else if (vModal === "item_quantity") {
        this.form.quantity = eval(value) >= 0 ? eval(value).toLocaleString('en-US') : 0;
      } else if (vModal === "rate") {
        let result = eval(value);

        if (result > 100) {
          result = 100;
        } else if (result < 1) {
          result = null;
        }

        this.form.rate = result;
      }
    },
    fetchAccountData(accountType) {
      this.doFetchAccountData(accountType);
    },
    filterSubItemData(subItemData) {
      let subItemObj = [];
      let subItemJson = {};
      for (let i = 0; i < subItemData.length; i++) {
        subItemJson = {
          text: subItemData[i].full_name,
          id: subItemData[i].number
        };
        subItemObj.push(subItemJson);
      }

      this.subItemDataList = subItemObj;

      if(subItemObj.length > 0) {
        const checkItem = obj => obj.id === this.super_item
        this.showSubItemCategory = subItemObj.some(checkItem)
      }

    },
    addSuperItem() {
      this.addSuperItemDialog = true;
    },
    filterSubItemList(){
      if(this.itemType) {
        this.doGetSubItemData(this.itemType);
      }
    },
    addTaxAccountModel() {
      this.addTaxDialog = true;
    },
    filterTaxData(taxData) {
      if(taxData){
        let arr = [{ text: "Select tax", id: "", disabled: true }];
        let obj = {};
        taxData.forEach(list => {
          obj = {
            text: list.name,
            id: list.number,
          };
          arr.push(obj);
        })
        this.taxItemDataObj = arr;
      }


    },
    addItemSupplierData() {
      this.add_item_supplier_dialog = true;
    },
    filterProfileList(customerList) {
      let dataObj = [{text: "Select profile", id: null, disabled: true}];
      let dataJson = {};
      for (let i = 0; i < customerList.length; i++) {
        dataJson = {
          text: customerList[i].name,
          id: customerList[i].number,
          disabled: false
        };
        dataObj.push(dataJson);
      }

      this.supplierDataObj = dataObj;
    },
    removeInventoryPartItem(index) {
      this.bill_of_items.splice(index, 1);
    },
    addInventoryPartItem() {
      let items = [];

      this.inventoryPartDataObj.forEach(obj => {
        let item_val = obj.id;

        let selected_items = this.bill_of_items.filter(
            t => t.part_item_number === item_val
        );

        if (selected_items.length === 0) {
          items.push(obj);
        }
      });

      if(items.length > 0) {
        this.bill_of_items.push({
          quantity: null,
          unit_number: null,
          total: null,
          notes: null,
          cost: null,
          part_item_number: items[0].id,
          items: items
        });
      }else {
       this.errorToast("Error", "No item found!!")
      }
    },
    checkAssemblyInfo(){
      if (this.inventoryAssemblyDataInfo) {
        let dataInfo = this.inventoryAssemblyDataInfo ?  this.inventoryAssemblyDataInfo : ""
        this.form.name = dataInfo.name ? dataInfo.name : ""
        this.form.code = dataInfo.code ? dataInfo.code : ""
        this.form.unit_number = dataInfo.unit ? dataInfo.unit.number : ""
        this.form.current_assets_account_number = dataInfo.current_assets_account_number ? dataInfo.current_assets_account_number : ""
        this.form.income_account_number = dataInfo.income_account_number ? dataInfo.income_account_number : ""
        this.form.cogs_account_number = dataInfo.cogs_account_number ? dataInfo.cogs_account_number : ""
        // this.form.selling_price = dataInfo.selling_price ? dataInfo.selling_price : ""
        this.form.notes = dataInfo.notes ? dataInfo.notes : ""
        this.super_item = dataInfo.super_item

        //check if super item has been selected
        if(this.subItemDataList.length > 0) {
          const checkItem = obj => obj.id === dataInfo.super_item
          this.showSubItemCategory = this.subItemDataList.some(checkItem)
        }

        this.form.super_item = dataInfo.super_item ? dataInfo.super_item : ""
        this.form.selling_price = dataInfo.selling_price ? dataInfo.selling_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
        this.item_number = dataInfo.number ? dataInfo.number : ""



        if(dataInfo.tax && dataInfo.tax.length > 0){
          this.showTaxItems = true
          let arr = []
          dataInfo.tax.forEach(list =>{
            arr.push(list.number)
          })
          this.form.tax_item_numbers = arr
        }

      //  fetch inventory assembly
        this.fetchStockedAssemblyItems(dataInfo.number)


      /*  if(dataInfo.bill_of_items) {
          this.bill_of_items_info = dataInfo.bill_of_items.length > 0 ? dataInfo.bill_of_items : []
        }*/

      }
    },
    prefillData(itemId, index){

      let obj = {}
      this.inventoryPartDataObjUnfiltered.forEach(list => {
        if(list.number === itemId){
          obj = list
        }
      })


      this.bill_of_items[index].notes = obj.notes ? obj.notes : ""
      this.bill_of_items[index].cost = (obj.buying_price).toLocaleString('en-US')
      this.bill_of_items[index].quantity = 1
      this.bill_of_items[index].unit_number = obj.unit.number ? obj.unit.number : ""
      this.bill_of_items[index].total = (obj.buying_price * 1).toLocaleString('en-US')

    },
    filterBillOfItemsInfo(){
      if(this.form){
        let SellingPxErr = ""
        if(
            this.form.selling_price &&
            (parseFloat(this.form.selling_price.toString().replace(/,/g, "")) < parseFloat(this.computeTotalValue.toString().replace(/,/g, "")))
        ){
          SellingPxErr = "Selling price is less than or equal to acutal unit cost of: <b>"+this.computeTotalValue+"</b>"
        }
        this.SellingPxError = SellingPxErr
      }

    }

  },
  computed:{
    ...mapGetters(
        [
          "getInventoryAssemblyItem",
          "getUnitDataList",
          "getUnitData",
          "getCostOfGoodsAccountDataSet",
          "getIncomeAccountDataSet",
          "getCurrentAssetsAccountDataSet",
          "getChartOfAccountsData",
          "getSubItemData",
          "getTaxDataAll",
          "getNewTaxData",
          "getProfileResponseData",
          "getProfileDataList",
          "getUpdateErrorMessage",
          "getUpdateItemData",
          "getItemsSetupDataSet",
          "getSelectedAssemblyItemsData"
        ]
    ),
    computeTotalValue(){
      if(this.bill_of_items.length > 0){
        let totalAmountCount = 0
        this.bill_of_items.forEach(list => {
          let total = list.total ? (list.total.toString().replace(/,/g, "") * 1) : list.total
          totalAmountCount = totalAmountCount + (total * 1)
        })

       return  totalAmountCount.toLocaleString('en-US')
      }else {
        return  0
      }
    },
  },
  watch:{
    form:{
      handler(data){
        if(data) {
          this.filterBillOfItemsInfo()
        }
      },
      deep:true
    },
    bill_of_items:{
      handler(data){
        if(data) {
          this.filterBillOfItemsInfo()
        }
      },
      deep:true
    },
    itemType() {
      this.filterSubItemList()
    },
    getInventoryAssemblyItem(data){
      if(data){
        this.form = {
          name:null,
          code:null,
          unit_number:"",
          current_assets_account_number:"",
          income_account_number:"",
          cogs_account_number:"",
          notes:null,
          selling_price:null,
          super_item:null,
          tax_item_numbers:[],
          profile_number:null,
          openingStock:null
        }

        this.addItemLoader = false
        this.showOpeningStock = false

        this.successToast("Success", data.message)
        this.filterSubItemList()
        this.$root.$emit("items_error_removal", "delete");

        this.bill_of_items = [
          {
            quantity: null,
            unit_number: "",
            total: "",
            notes: "",
            cost: "",
            part_item_number: "",
            items: this.inventoryPartDataObj
          }
        ]

      }
    },
    getUnitDataList(unitDataList) {
      if(unitDataList) {
        this.unitDataList =  this.filterUnits(unitDataList);
      }
    },
    getUnitData(unitData) {
      if (unitData) {
        this.form.unit_number = unitData.data.number;
        this.$refs["unit_components"].addUnitLoader = false;
        this.$refs["unit_components"].form = {
          name: null,
          abbreviation: null
        };
        this.add_item_unit_dialog = false;
        this.doGetUnitDataList();
        this.successToast("Success", unitData.message);
      }
    },
    getCurrentAssetsAccountDataSet(accountData) {
      this.currentAssetAccountDataList = this.accountFilter(accountData);
    },
    getIncomeAccountDataSet(accountData) {
      this.incomeAccountDataList = this.accountFilter(accountData);
    },
    getCostOfGoodsAccountDataSet(accountData) {
      this.costOfGoodsAccountDataList = this.accountFilter(accountData);
    },
    getChartOfAccountsData(chartData) {
      if (chartData) {
        this.$refs["chart_of_account_components"].chartSpinner = false;
        this.$refs["chart_of_account_components"].form = {
          name: null,
          code: null,
          description: null,
          sub_account: null,
          number: null
        };
        this.current_asset_dialog = false;
        this.income_dialog = false;
        this.cost_of_goods_dialog = false;
        this.successToast("Success", chartData.message);

        // alert(chartData.data.ref_code)
        if (chartData.data.ref_code === "CAST") {
          this.form.current_assets_account_number = chartData.data.number;
        } else if (chartData.data.ref_code === "INC") {
          this.form.income_account_number = chartData.data.number;
        } else if (chartData.data.ref_code === "COS") {
          this.form.cogs_account_number = chartData.data.number;
        }

        this.fetchAccountData("CAST");
        this.fetchAccountData("INC");
        this.fetchAccountData("COS");
      }
    },
    getSubItemData(subItemData) {
      this.filterSubItemData(subItemData);
    },
    getTaxDataAll(data) {
      if (data) {
        this.filterTaxData(data)
      }
    },
    getNewTaxData(data) {
      if (data) {
        this.form.tax_item_numbers = []
        this.form.tax_item_numbers = [data.data.number]
        this.$refs["new_tax_component"].taxSpinner = false
        this.$refs["new_tax_component"].form = {
          name:null,
          rate:null,
          profile_number:null,
          is_compound:false,
          is_absolute:false
        }
        this.addTaxDialog = false
        this.successToast("Success", data.message)
        this.fetchTaxData();
      }
    },
    getProfileResponseData(data) {
      if (data) {
        this.add_item_supplier_dialog = false
        this.$refs["profile_component"].customerSpinner = false;
        this.openingStock.profile_number = data.data.number;
        this.$refs["profile_component"].form = {
          name: null,
          address: null,
          number: null,
          tin: null,
          phoneContact: [
            {
              contact: null,
              id: null,
              type: null
            }
          ],
          emailContact: [
            {
              contact: null,
              id: null,
              type: null
            }
          ]
        };
        this.successToast("Success", data.message);
        this.fetchProfiles()
      }
    },
    getProfileDataList(data) {
      if(data) {
        this.filterProfileList(data);
      }
    },

    showOpeningStock(){
      if(this.showOpeningStock){
        this.form.openingStock = this.openingStock
      }else{
        this.form.openingStock = null
      }
    },
    inventoryAssemblyDataInfo(data){
      if(data){
        this.checkAssemblyInfo()
      }
    },
    getUpdateErrorMessage(data){
      if (data) {
        this.addItemLoader = false
      }
    },
    getUpdateItemData(data) {
      if (data) {
        this.addItemLoader = false
      }
    },
    showTaxItems(data){
      if(!data){
        this.form.tax_item_numbers = ""
      }
    },
    getItemsSetupDataSet(itemsData){
      if(itemsData){
        this.bill_of_items = []
        this.inventoryPartDataObjUnfiltered = itemsData
        let items = this.stockItemsFilter(itemsData);
        this.inventoryPartDataObj = items

        if(this.bill_of_items_info.length > 0){

          let arr = []
          this.bill_of_items_info.forEach(list =>{
            let obj = list
            obj.items = items
            arr.push(obj)
          })

          this.bill_of_items = arr

        } else {

          this.bill_of_items = [
            {
              quantity: null,
              unit_number: null,
              notes: null,
              total: null,
              cost: null,
              part_item_number: "",
              items: items
            }
          ]
        }
      }
    },
    getSelectedAssemblyItemsData(data){
        if (data && (data.length > 0)) {
          let obj = {}
          let arr = []
          let items = this.inventoryPartDataObj


          data.forEach(lst => {
            let qty = lst.quantity ? lst.quantity : 0
            let cost = lst.part_item.buying_price ? lst.part_item.buying_price : 0
            obj = {
              quantity: qty,
              unit_number: lst.unit_number ? lst.unit_number : "",
              total: (cost * qty),
              notes: lst.part_item.notes ? lst.part_item.notes : "",
              cost: cost.toLocaleString('en-US'),
              part_item_number: lst.part_item_number ? lst.part_item_number : "",
              items: items
            }
            arr.push(obj)
          })

          this.bill_of_items = arr
        }

    }
  }
}
</script>

<style scoped>

</style>