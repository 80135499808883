<template>
<div>
    <form ref="form" @submit.prevent="payLiability">

      <v-alert type="error" v-if="payInvoiceError">
        {{ payInvoiceError }}
      </v-alert>

      <v-row class="mx-auto">
        <v-spacer></v-spacer>

        <v-chip
            class="mr-3 mb-3"
            color="indigo darken-3"
            outlined
        >
      {{ amountDue }}
    </v-chip>




      </v-row>

      <v-row class="mx-auto">
        <v-col>
          <v-text-field
              class="mb-2"
              v-model="form.amount"
              label="Amount *"
              outlined
              dense
              persistent-hint
              :hint="amountDue"
              required
              @keypress="onlyNumberValidator($event)"
              v-on:blur="compute(form.amount)"
          ></v-text-field>

          <v-text-field
              v-model="form.payment_date"
              label="Payment Date"
              outlined
              dense
              type="date"
              required
              hide-details
          ></v-text-field>
          <small style="color: red;" v-if="yearLengthValidator(form.payment_date)">
            Invalid Date
          </small>
           <v-select
               class="mt-3"
               outlined
               v-model="form.account_number"
               :items="accountDataList"
               item-value="id"
               color="pink"
               label="Expense Account *"
               append-outer-icon="mdi-plus"
               @click:append-outer="accountModel"
               dense
           ></v-select>

          <v-select
              outlined
              v-model="form.storage_account_number"
              :items="storageAccountDataList"
              item-value="id"
              color="pink"
              label="Bank/Cash Account *"
              append-outer-icon="mdi-plus"
              @click:append-outer="storageAccountModel"
              dense
          ></v-select>

          <v-select
              outlined
              v-model="form.payment_method_number"
              :items="paymentMethodDataList"
              item-value="id"
              color="pink"
              label="Payment Method"
              append-outer-icon="mdi-plus"
              @click:append-outer="paymentMethodModel"
              dense
          ></v-select>


            <v-autocomplete
                outlined
                v-model="form.sale_user_number"
                :items="staffDataObj"
                item-value="id"
                color="pink"
                label="Received by"
                append-outer-icon="mdi-plus"
                @click:append-outer="addNewStaffProfileData"
                dense
                clearable
            ></v-autocomplete>


          <v-textarea
              v-model="form.notes"
              label="Notes"
              outlined
              rows="2"
              dense
          ></v-textarea>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="companyButton"
            :loading="invoiceLoader"
            color="#0000ff"
            dark
            type="submit"
        >
           {{form.number ? "Edit Payment" : "Pay"}}
        </v-btn>
      </v-card-actions>
    </form>

  <!--                    payment methods-->
    <v-dialog v-model="payment_methods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Payment Methods</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payment_methods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <payment-methods ref="paymentMethodsComponents"></payment-methods>
        </div>
      </v-card>
    </v-dialog>

  <!--    adds Storage accounts -->

    <v-dialog v-model="storage_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="storage_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <storage-account ref="storageAccountComponent"></storage-account>
        </div>
      </v-card>
    </v-dialog>


  <!--    adding account dialog-->

    <v-dialog v-model="account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Expense Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              account_type="EXP"
              ref="expense_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>


      <v-dialog v-model="new_staff_dialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Staff</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="new_staff_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-user ref="users_components"></new-user>
        </div>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import StorageAccount from "@/modules/dashboard/accounting/commons/StorageAccount";
import PaymentMethods from "@/modules/dashboard/accounting/commons/PaymentMethods";
import {mapActions, mapGetters} from "vuex";
import AddAccount from "@/modules/dashboard/accounting/commons/AddAccount";
import NewUser from "@/modules/dashboard/Settings/Users/NewUser";

export default {
  name: "PayLiability",
  components: {NewUser, AddAccount, StorageAccount, PaymentMethods },
  props: {
    invoiceInfo: {},
    receiptType: String
  },
  data() {
    return {
      invoiceLoader: false,
      payment_methods_dialog: false,
      new_staff_dialog: false,
      storage_account_dialog: false,
      paymentMethodDataList: [],
      storageAccountDataList: [],
      accountDataList: [],
      payInvoiceError: null,
      amountDue:null,
      form: {
        number: null,
        liability_number: null,
        amount: null,
        account_number: null,
        payment_method_number: null,
        storage_account_number: null,
        payment_date: new Date().toISOString().substr(0, 10),
        notes: null,
        sale_user_number: null
      },
      invoice_counter: null,
      account_dialog:false,
      staffDataObj: []
    };
  },
  mounted() {
    this.doGetAccountData("EXP");
    this.doGetPaymentMethodData();
    this.doGetStorageAccountData();
    this.fetchStaffProfiles();
    if (this.invoiceInfo) {
      this.filterPaymentInfo();
    }
  },
  methods: {
    ...mapActions([
      "doGetPaymentMethodData",
      "doGetStorageAccountData",
      "doPayLiability",
      "getInvoiceBillBalance",
      "doGetAccountData",
      "fetchStaffProfiles",
      "doEditPayLiability"
    ]),
    payLiability() {
      this.invoiceLoader = true;
      this.payInvoiceError = null;
      let formData = this.form;
      let moneyData =  formData.amount
      formData.amount = moneyData.toString().includes(',') ? (moneyData.toString().replace(/,/g, "") * 1)  : moneyData
     if(this.form.number){
       this.doEditPayLiability(formData);
     }else {
       this.doPayLiability(formData);
     }

    },
    paymentMethodModel() {
      this.payment_methods_dialog = true;
    },
    addNewStaffProfileData() {
      this.new_staff_dialog = true;
    },
    accountModel() {
      this.account_dialog = true
    },
    storageAccountModel() {
      this.storage_account_dialog = true;
    },
    compute(value) {
      value = value + "";
      value = value.toString().replace(/,/g, "");

      if (!isNaN(value)) {
        value = value - 0;
      }
      this.form.amount =
          eval(value) > 0
              ? eval(value)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : null;
    },
    filterPaymentInfo() {
      this.payInvoiceError = "";
      this.form.liability_number = this.invoiceInfo.liability_number;
      // this.invoice_counter = this.invoiceInfo.counter;
      // this.getInvoiceBillBalance(this.invoiceInfo.number);
    }
  },
  computed: {
    ...mapGetters([
      "getUnitData",
      "getNewPaymentMethods",
      "getPaymentMethodDataSet",
      "getNewStorageAccountData",
      "getStorageAccountDataSet",
      "getInvoiceError",
      "getLiabilityBalanceData",
      "getAccountDataSet",
      "getStaffProfileDataList",
       "getChartOfAccountsData",
       "getRegisterUserProfileData",
        "getLiabilityPaymentDataInfo"
    ])
  },
  watch: {
    getNewPaymentMethods(paymentData) {
      if (paymentData) {
        this.$refs["paymentMethodsComponents"].form = {};
        this.$refs["paymentMethodsComponents"].addPaymentMethodLoader = false;
        this.payment_methods_dialog = false;
        this.form.payment_method_number = paymentData.data.number;
        this.successToast("Success", paymentData.message);
        this.doGetPaymentMethodData();
      }
    },
    getPaymentMethodDataSet(paymentData) {
      this.paymentMethodDataList = this.nameNumberFilter(paymentData);
    },
    getNewStorageAccountData(storageAccountData) {
      if (storageAccountData) {
        this.$refs["storageAccountComponent"].form = { name: null,
                                                  notes: null,
                                                  supper_account: null,
                                                  number: null};
        this.$refs["storageAccountComponent"].addStorageLoader = false;
        this.storage_account_dialog = false;
        this.form.storage_account_number = storageAccountData.data.number;
        this.successToast("Success", storageAccountData.message);
        this.doGetStorageAccountData(this.$company.number);
      }
    },
    getStorageAccountDataSet(storageAccountData) {
      this.storageAccountDataList = this.nameNumberFilter(storageAccountData);
    },
    getInvoiceError(invoiceError) {
      if (invoiceError) {
        this.payInvoiceError = invoiceError;
        this.invoiceLoader = false;
        this.errorToast("Error", invoiceError)
      }
    },
    getLiabilityBalanceData(balanceData) {
      if (balanceData) {

        this.form.amount = balanceData.oustanding.toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        this.amountDue = "Amount due: "+balanceData.oustanding
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    invoiceInfo() {
      this.filterPaymentInfo();
    },
    getAccountDataSet(accountData) {
      this.accountDataList = this.accountFilter(accountData);
    },
    getStaffProfileDataList(staffDataList) {
      this.staffDataObj = this.filterStaffProfilesList(staffDataList);
    },
    getChartOfAccountsData(data){
      if(data){
        this.account_dialog = false
        this.successToast("Success", data.message)
        this.$refs.expense_components.chartSpinner = false
        this.$refs.expense_components.form = {
                                            name: null,
                                            code: null,
                                            description: null,
                                            sub_account: null,
                                            number: null
                                          }
        this.form.account_number = data.data.number
        this.doGetAccountData("EXP");
      }
    },
    getRegisterUserProfileData(data){
      if(data){
        this.successToast("Success", data.message)
        this.new_staff_dialog = false
        this.$refs.users_components.registerSpinner = false
        this.$refs.users_components.form = {
                                          first_name: null,
                                          second_name: null,
                                          gender: null,
                                          email: null,
                                          phone: null,
                                          password: null,
                                          confirm_password: null,
                                          roles: []
                                        }
        this.form.sale_user_number = data.data.number
        this.fetchStaffProfiles();
      }
    },
    getLiabilityPaymentDataInfo(data){
      if(data){
            this.form.number = data.number ? data.number : null
            this.form.liability_number = data.liability_number ? data.liability_number : null
            this.form.amount  = data.amount ? data.amount.toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null
            this.form.account_number  =  data.transaction ? data.transaction.account_number : null
            this.form.payment_method_number  = data.transaction ? data.transaction.payment_method_number : null
            this.form.storage_account_number  = data.storage_account_number ? data.storage_account_number : null
            this.form.payment_date  = data.date ? data.date : null
            this.form.notes  = data.name ? data.name : null
            this.form.sale_user_number  =  data.transaction ? data.transaction.sale_user_number : null
            this.amountDue = data.amount ? "Amount due: "+data.amount
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

      }
    }
  }
}
</script>

<style scoped>

</style>