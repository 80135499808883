import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  transactionData: null,
  statusExpenseResponse: "error",
  transactionErrorMessage: null,
  accountDataSet: null,
  incomeAccountDataInfo: null,
  eXPCOSAccountDataInfo: null,
  newLiabilityData: null,
  updateLiabilityData: null,
  storageAccountDataSet: null,
  newStorageAccount: null,
  newPaymentMethods: null,
  paymentMethodDataSet: null,
  currentAssetsAccountDataSet: null,
  transactionInfoData: null,
  liabilityInfoData: null,
  liabilityBalanceData: null,
  liabilityDeleteData: null,
  incomeAccountDataSet: null,
  costOfGoodsAccountDataSet: null,
  currentLiabilityAccountDataSet: null,
  equityAccountDataSet: null,
  fixedAssetAccountDataSet: null,
  longTermLiabilityAccountDataSet: null
};

const getters = {
  getTransactionData: state => state.transactionData,
  getStatusExpenseResponse: state => state.statusExpenseResponse,
  getTransactionErrorMessage: state => state.transactionErrorMessage,
  getAccountDataSet: state => state.accountDataSet,
  getStorageAccountDataSet: state => state.storageAccountDataSet,
  getNewStorageAccountData: state => state.newStorageAccount,
  getNewPaymentMethods: state => state.newPaymentMethods,
  getPaymentMethodDataSet: state => state.paymentMethodDataSet,
  getCurrentAssetsAccountDataSet: state => state.currentAssetsAccountDataSet,
  getIncomeAccountDataSet: state => state.incomeAccountDataSet,
  getTransactionInfoData: state => state.transactionInfoData,
  getCostOfGoodsAccountDataSet: state => state.costOfGoodsAccountDataSet,
  getNewLiabilityData: state => state.newLiabilityData,
  getUpdateLiabilityData: state => state.updateLiabilityData,
  getLiabilityInfoData: state => state.liabilityInfoData,
  getLiabilityBalanceData: state => state.liabilityBalanceData,
  getIncomeAccountDataInfo: state => state.incomeAccountDataInfo,
  getLiabilityDeleteData: state => state.liabilityDeleteData,
  getCurrentLiabilityAccountDataSet: state => state.currentLiabilityAccountDataSet,
  getEquityAccountDataSet: state => state.equityAccountDataSet,
  getFixedAssetAccountDataSet: state => state.fixedAssetAccountDataSet,
  getLongTermLiabilityAccountDataSet: state => state.longTermLiabilityAccountDataSet,
  getEXPCOSAccountDataInfo: state => state.eXPCOSAccountDataInfo
};

const actions = {
  async doSaveTransaction({ commit }, data) {
    await axios
      .post(
        data.number
          ? IP + "transactions/update?number=" + data.number
          : IP + "company/save-transaction",
        data
      )
      .then(response => {
        commit("setTransactionData", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async doSaveLiability({ commit }, data) {
    await axios
      .post(IP + "liabilities/new", data)
      .then(response => {
        commit("setNewLiabilityData", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async doUpdateLiability({ commit }, data) {
    await axios
      .post(IP + "liabilities/update", data)
      .then(response => {
        commit("setUpdateLiabilityData", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },

  async doGetAccountData({ commit }, data) {
    await axios
      .get(
        IP +
          "company/get-account-data?type=" +
          data
      )
      .then(response => {
        commit("setAccountDataSet", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async doGetIncomeAccountData({ commit }, data) {
    await axios
      .get(
        IP +
          "company/get-account-data?type=" +
          data
      )
      .then(response => {
        commit("setIncomeAccountDataInfo", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async fetchEXPCOSAccountData({ commit }, data) {
    await axios
      .get(
        IP +
          "company/get-account-data?type=" +
          data
      )
      .then(response => {
        commit("setEXPCOSAccountDataInfo", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async doFetchAccountData({ commit }, type) {
    await axios
      .get(
        IP +
          "company/get-account-data?type=" +
           type
      )
      .then(response => {
        if ( type === "INC") {
          commit("setIncomeAccountDataSet", response);
        } else if ( type === "CAST") {
          commit("setCurrentAssetAccountDataSet", response);
        } else if ( type === "COS") {
          commit("setCostOfGoodAccountDataSet", response);
        }else if ( type === "CLBT") {
          commit("setCurrentLiabilityAccountDataSet", response);
        }else if ( type === "EQT") {
          commit("setEquityAccountDataSet", response);
        }else if ( type === "FAST") {
          commit("setFixedAssetAccountDataSet", response);
        }else if ( type === "LTLB") {
          commit("setLongTermLiabilityAccountDataSet", response);
        }
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async doGetStorageAccountData({ commit }) {
    await axios
      .get(IP + "company/storage-accounts")
      .then(response => {
        commit("setStorageAccountDataSet", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async saveNewStorageAccount({ commit }, data = null) {
    await axios
      .post(
        data.number
          ? IP + "bank-accounts/update/" + data.number
          : IP + "company/new-storage-account",

        data
      )
      .then(response => {
        commit("setNewStorageAccount", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async saveNewPaymentMethod({ commit }, data) {
    await axios
      .post(IP + "company/new-payment-methods", data)
      .then(response => {
        commit("setNewPaymentMethods", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async doGetPaymentMethodData({ commit }) {
    await axios
      .get(IP + "company/payment-methods" )
      .then(response => {
        commit("setPaymentMethodsDataSet", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async doFetchTransactionInfo({ commit }, data) {
    commit("setTransactionInfoData", { data: null });
    await axios
      .get(IP + "transactions/details/" + data)
      .then(response => {
        commit("setTransactionInfoData", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async doFetchLiabilityInfo({ commit }, data) {
    commit("setTransactionInfoData", { data: null });
    await axios
      .get(IP + "liabilities/details/" + data)
      .then(response => {
        commit("setLiabilityInfoData", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async fetchLiabilityBalance({ commit }, data) {
    await axios
      .get(IP + "liabilities/outstanding/" + data)
      .then(response => {
        commit("setLiabilityBalanceData", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async doDeleteLiabilityInfo({ commit }, data) {
    await axios
      .post(IP + "liabilities/delete/" + data)
      .then(response => {
        commit("setLiabilityDeleteData", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  }
};

const mutations = {
  setTransactionData: (state, response) => {
    state.statusExpenseResponse = "success";
    state.transactionData = response.data;
  },

  setAccountDataSet: (state, response) => {
    state.accountDataSet = response.data;
  },
  setIncomeAccountDataInfo: (state, response) => {
    state.incomeAccountDataInfo = response.data;
  },
  setEXPCOSAccountDataInfo: (state, response) => {
    state.eXPCOSAccountDataInfo = response.data;
  },
  setNewLiabilityData: (state, response) => {
    state.newLiabilityData = response.data;
  },
  setUpdateLiabilityData: (state, response) => {
    state.updateLiabilityData = response.data;
  },
  setIncomeAccountDataSet: (state, response) => {
    state.incomeAccountDataSet = response.data;
  },
  setCurrentAssetAccountDataSet: (state, response) => {
    state.currentAssetsAccountDataSet = response.data;
  },
  setCostOfGoodAccountDataSet: (state, response) => {
    state.costOfGoodsAccountDataSet = response.data;
  },
  setCurrentLiabilityAccountDataSet: (state, response) => {
    state.currentLiabilityAccountDataSet = response.data;
  },
  setEquityAccountDataSet: (state, response) => {
    state.equityAccountDataSet = response.data;
  },
  setFixedAssetAccountDataSet: (state, response) => {
    state.fixedAssetAccountDataSet = response.data;
  },
  setLongTermLiabilityAccountDataSet: (state, response) => {
    state.longTermLiabilityAccountDataSet = response.data;
  },
  setStorageAccountDataSet: (state, response) => {
    state.statusExpenseResponse = "success";
    state.storageAccountDataSet = response.data;
  },
  setNewStorageAccount: (state, response) => {
    state.newStorageAccount = response.data;
  },
  setPaymentMethodsDataSet: (state, response) => {
    state.paymentMethodDataSet = response.data;
  },
  setNewPaymentMethods: (state, response) => {
    state.newPaymentMethods = response.data;
  },
  setTransactionInfoData: (state, response) => {
    state.transactionInfoData = response.data;
  },
  setLiabilityInfoData: (state, response) => {
    state.liabilityInfoData = response.data;
  },
  setLiabilityBalanceData: (state, response) => {
    state.liabilityBalanceData = response.data;
  },
  setLiabilityDeleteData: (state, response) => {
    state.liabilityDeleteData = response.data;
  },
  setTransactionErrorMessage: (state, error) => {
    state.statusExpenseResponse = "error";
    state.transactionErrorMessage = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
