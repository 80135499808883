<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card>
                    <v-toolbar dense flat>
                        <v-app-bar-nav-icon></v-app-bar-nav-icon>
                        <v-toolbar-title>Sales</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <span>
              <v-btn
                      class="ma-2 text-decoration-none"
                      outlined
                      small
                      color="#1b5e20"
              >
                <v-icon left>
                  mdi-import
                </v-icon>
                Template
              </v-btn>

              <v-btn
                      class="ma-2"
                      outlined
                      color="#242f7e"
                      small

              >
                <v-icon left>
                  mdi-upload
                </v-icon>
                Import
              </v-btn>
            </span>
                    </v-toolbar>

                    <v-tabs v-model="activeTab">



                        <v-tab v-if="checkLicenseStatus()" href="#new-sales">
                            <v-icon left>mdi-lock</v-icon>
                            New Sale
                        </v-tab>

                      <v-tab href="#sales">
                        <v-icon left>mdi-account</v-icon>
                        Sales
                      </v-tab>

                      <v-tab-item id="new-sales">
                        <v-divider></v-divider>

                        <v-card flat>
                          <div
                              style="width: 98%; padding-bottom: 15px;"
                              class="mt-8 mx-auto"
                          >
                            <sales-option></sales-option>
                          </div>
                        </v-card>
                      </v-tab-item>

                        <v-tab-item id="sales">
                            <v-divider></v-divider>
                            <v-card flat>
                                <v-row class="mx-auto mt-8">
                                    <v-col cols="8">
                                        <v-row>
                                            <v-col cols="3">
                                                <v-select
                                                        class="ml-4"
                                                        v-model="filters.sale_type"
                                                        :items="invoice_types"
                                                        item-value="value"
                                                        color="pink"
                                                        dense
                                                        hint="Status"
                                                        persistent-hint
                                                        v-on:change="fetchSales"
                                                ></v-select>
                                            </v-col>

                                            <v-col cols="3">
                                                <v-select
                                                        class="ml-4"
                                                        v-model="filters.profile_number"
                                                        :items="customerDataList"
                                                        item-value="id"
                                                        color="pink"
                                                        dense
                                                        hint="Customer"
                                                        persistent-hint
                                                        clearable
                                                        v-on:change="fetchSales"
                                                ></v-select>
                                            </v-col>

                                            <v-col cols="2">
                                                <v-menu
                                                        :close-on-content-click="true"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                                class="mr-1"
                                                                v-model="filters.start_date"
                                                                dense
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                hint="Start Date [ YYY-MM-DD ]"
                                                                persistent-hint
                                                                clearable
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="filters.start_date" no-title></v-date-picker>
                                                </v-menu>
                                            </v-col>

                                            <v-col cols="2">
                                                <v-menu
                                                        :close-on-content-click="true"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                                class="mr-1"
                                                                v-model="filters.end_date"
                                                                dense
                                                                readonly
                                                                hint="End Date [ YYY-MM-DD ]"
                                                                persistent-hint
                                                                v-bind="attrs"
                                                                clearable
                                                                v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="filters.end_date" no-title></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="4">
                                        <v-text-field
                                                class="mr-3"
                                                v-model="search"
                                                append-icon="mdi-magnify"
                                                label="Search"
                                                dense
                                                outlined
                                                hide-details
                                                v-on:keyup.enter="filterSales"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-data-table
                                        :headers="headers"
                                        :items="salesItemDataObj"
                                        :search="search"
                                        :loading="tableSalesLoader"
                                        loading-text="Loading... please wait"
                                        item-key="name"
                                        class="elevation-1"
                                        :page="page"
                                        :items-per-page="items_per_page"
                                        :server-items-length="server_items_length"
                                        :options.sync="paginationSales"
                                >
                                    <!--                  <template v-slot:item.actions="{ item }">
                                                        <v-icon small class="mr-2" @click="editExpense(item)">
                                                          mdi-pencil
                                                        </v-icon>
                                                        <v-icon small @click="deleteExpense(item)">
                                                          mdi-delete
                                                        </v-icon>
                                                      </template>-->
                                </v-data-table>
                            </v-card>
                        </v-tab-item>




                    </v-tabs>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import router from "@/global/router";
import SalesOption from "@/modules/dashboard/accounting/Sales/SalesOption";

export default {
    name: "Sales",
    components: {SalesOption},
    data() {
        return {
            activeTab: "new-sales",
            stockTableData: [],
            search: "",
            tableSalesLoader: false,
            salesItemDataObj: [],
            headers: [
                {
                    text: "#",
                    align: "start",
                    width: "5%",
                    value: "key"
                },
                {text: "Date", value: "date_of_sale"},
                {text: "Item", value: "item_name"},
                {text: "Memo", value: "notes"},
                {text: "Unit", value: "unit_name"},
                {text: "Type", value: "sale_type"},
                {text: "Quantity", value: "quantity"},
                {text: "Price", value: "unit_price"},
                {text: "Amount", value: "total_amount"},
                // { text: "Action", value: "actions", sortable: false, width: "7%" }
            ],
            json_fields: {},
            page: 1,
            items_per_page: 10,
            server_items_length: 0,
            paginationSales: {},
            filters: {
                page: 1,
                per_page: this.items_per_page,
                sale_type: null,
                start_date: null,
                end_date: null,
                profile_number: null
            },
            invoice_types: [
                {text: "Cash", value: "cash"},
                {text: "Credit", value: "credit"},

            ],
            customerDataList :[]
        };
    },
    mounted() {
        this.fetchProfiles();
        if (this.$route.query.type) {
            this.activeTab = this.$route.query.type;
        }
    },
    methods: {
        ...mapActions(["doGetSalesItemsList", "fetchProfiles"]),
        editExpense(item) {
            alert(JSON.stringify(item));
        },
        deleteExpense(item) {
            alert(JSON.stringify(item));
        },
        filterSales(salesDataObj) {
            let salesData = salesDataObj.data;

            let current_page = salesDataObj.current_page;
            this.page = current_page;
            let per_page = salesDataObj.per_page;
            this.items_per_page = per_page * 1;
            this.server_items_length = salesDataObj.total;

            this.tableSalesLoader = false;

            let salesJsn = {};
            let salesObj = [];

            salesData.forEach((list, i) => {

                salesJsn = {
                    key: (current_page - 1) * per_page + i + 1,
                    date_of_sale: list.date ? list.date : "",
                    item_name: list.item_name ? list.item_name : "",
                    unit_name: list.unit_name ? list.unit_name : "",
                    notes: list.stock_item ? list.stock_item.notes : "",
                    sale_type: "cash",
                    quantity: list.quantity ? list.quantity
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
                    unit_price: list.unit_price ? list.unit_price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
                    total_amount: list.total_amount ? list.total_amount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                };
                salesObj.push(salesJsn);

            })


            this.salesItemDataObj = salesObj;
        },

        fetchSales() {
            this.tableSalesLoader = true;
            let options = {
                page: this.filters.page,
            };

            if (this.items_per_page){
                options.per_page = this.items_per_page
            }
            if (this.profile_number) {
                options.profile_number = this.filters.profile_number
            }
            if (this.start_date) {
                options.start_date = this.filters.start_date
            }

            if (this.end_date) {
                options.end_date = this.filters.end_date
            }

            if (this.sale_type) {
                options.sale_type = this.filters.sale_type
            }


            /* "pos/sales" +q
             "?page=" + data.page +
             "&per_page=" + data.per_page +
             "&search="+search*/


            this.doGetSalesItemsList(this.objectToQueryParams(options));
        },
        filterCustomerList(customerList) {
            let dataObj = [
                { text: "Add Profile", id: "new-profile"},
                {text: "Select profiles", id: null, disabled: true}
            ];
            let dataJson = {};
            customerList.forEach(list =>{
                dataJson = {
                    text: list.name,
                    id: list.number,
                    disabled: false
                };
                dataObj.push(dataJson);
            })


            this.customerDataList = dataObj;
        },
    },
    computed: {
        ...mapGetters(
            [
                "getSales",
                "getProfileDataList"
            ]
        )
    },
    watch: {
        paginationSales(obj) {
            this.tableSalesLoader = true;

                this.filters.page = obj.page
                this.items_per_page = obj.itemsPerPage


            this.fetchSales()
        },
        getSales(salesData) {
            this.filterSales(salesData);
        },
        activeTab(data) {
          if(data) {
            router.push({name: "sales", query: {type: data}});
          }
        },
        getProfileDataList(customerInfo) {
            this.filterCustomerList(customerInfo);
        },
    }
};
</script>

<style scoped></style>
