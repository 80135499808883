import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  discountData: null,
  customerProfileData: null,
  invoiceListData: null,
  cancelInvoiceBill: null,
  customerPaymentsData: null,
  customerDetailsErrorMessage: null
};

const getters = {
  getDiscountDataList: state => state.discountData,
  getCustomerProfileData: state => state.customerProfileData,
  getInvoiceListData: state => state.invoiceListData,
  getCustomerPaymentsData: state => state.customerPaymentsData,
  getCancelInvoiceBill: state => state.cancelInvoiceBill,
  getCustomerDetailsErrorMessage: state => state.customerDetailsErrorMessage
};

const actions = {
  async customerProfile({ commit }, data) {
    await axios
      .get(IP + "profiles/details/" + data)
      .then(response => {
        commit("setCustomerProfileData", response);
      })
      .catch(error => {
        commit("setCustomerDetailsErrorMessage", error);
      });
  },
  async fetchDiscountList({ commit }) {
    await axios
      .get(IP + "discount/all")
      .then(response => {
        commit("setDiscountDataList", response);
      })
      .catch(error => {
        commit("setCustomerDetailsErrorMessage", error);
      });
  },
  async doCancelInvoiceBill({ commit }, data) {
    await axios
      .post(IP + "invoice/cancel/"+data)
      .then(response => {
        commit("setCancelInvoiceBill", response);
      })
      .catch(error => {
        commit("setCustomerDetailsErrorMessage", error);
      });
  },

  async doGetInvoiceListData({ commit}, data) {
    let profile_number  = data ? data.profile_number ? data.profile_number : "" : ""
    let start_date      = data ? data.start_date ? data.start_date : "" : ""
    let end_date        = data ? data.end_date ? data.end_date : "" : ""
    let search          = data ? data.search ? data.search : "" : ""
    let status          = data ? data.status ? data.status : "" : ""
    let page          = data ? data.page ? data.page : "1" : "1"
    let per_page          = data ? data.per_page ? data.per_page : "10" : "10"
    await axios
      .get( IP +
              "invoice/all" +
              "?page="+page +
              "&per_page="+per_page+
              "&profile_number="+profile_number+
              "&start_date="+start_date+
              "&end_date="+end_date+
              "&search="+search+
              "&status="+status
      )
      .then(response => {
        commit("setInvoiceListData", response);

      })
      .catch(error => {
        commit("setCustomerDetailsErrorMessage", error);
      });
  },
  async doGetCustomerPayments({ commit }, data) {
    let search  = data ? data.search ? data.search : "" : ""
    await axios
      .get(
        data.customer_number
          ? IP +
              "payments/by-customer/" +
              data.customer_number +
              "?page=" +
              data.page +
              "&per_page=" +
              data.per_page+
              "&search="+search
          : IP + "payments/all?page=" + data.page + "&per_page=" + data.per_page+"&search="+search
      )
      .then(response => {
        commit("setCustomerPaymentsData", response);
      })
      .catch(error => {
        commit("setCustomerDetailsErrorMessage", error);
      });
  }
};

const mutations = {
  setDiscountDataList: (state, response) => {
    state.discountData = response.data;
  },
  setCustomerProfileData: (state, response) => {
    state.customerProfileData = response.data;
  },
  setCancelInvoiceBill: (state, response) => {
    state.cancelInvoiceBill = response.data;
  },

  setInvoiceListData: (state, response) => {
    state.invoiceListData = response.data;
  },
  setCustomerPaymentsData: (state, response) => {
    state.customerPaymentsData = response.data;
  },
  setCustomerDetailsErrorMessage: (state, error) => {
    state.customerDetailsErrorMessage = error.response
      ? error.response.data
      : "Error: Network Error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
