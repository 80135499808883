<template>

   <div>
    <form ref="form" @submit.prevent="adjustStockInfo">
      <v-alert type="error" v-if="messageError">
        {{ messageError }}
      </v-alert>


      <v-row>
        <v-col cols="4">
          <v-autocomplete
              label="Account"
              v-model="account_number"
              :items="accountDataItem"
              item-value="id"
              color="pink"
              append-outer-icon="mdi-plus"
              @click:append-outer="accountModel"
              dense
              outlined
              hide-details
          ></v-autocomplete>
        </v-col>

        <v-col cols="4">
          <v-text-field
              v-model="date"
              label="Date [ dd / mm / yyy ]"
              outlined
              dense
              type="date"
              required
              hide-details
          ></v-text-field>
          <small style="color: red;" v-if="yearLengthValidator(date)">
            Invalid Date
          </small>
        </v-col>

      </v-row>

      <v-row>
        <v-col>


          <v-simple-table dense light style="border: 1px solid #e0e0e0;">
            <template v-slot:default>
              <tbody>
              <tr>
                <th>#</th>
                <th style="width: 30%;">Name</th>
                <th>Remaining Stock</th>
                <th style="width: 20%;">Physical Quantity</th>
                <th style="width: 20%;">Difference</th>
              </tr>
              <tr v-for="(item, index) in itemsListDataInfo" :key="index">
                <td>
                 {{item.key}}
                </td>
                <td>
                 {{item.name}}
                </td>
                <td>
                 {{item.remaining_stock}}
                </td>
                <td class="pa-0">
                  <v-text-field
                      class="pa-0"
                      style="border: 1px solid #e0e0e0;"
                      @keypress="onlyNumberValidator($event)"
                      v-model="item.quantity"
                      label=""
                      flat
                      solo
                      dense
                      hide-details
                      required
                      v-on:blur="computeValues(
                              item.quantity,
                              index
                            )
                          "
                  ></v-text-field>
                </td>
                <td>
                  {{(parseFloat(item.quantity) - parseFloat(item.remaining_stock))}}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>


        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="companyButton"
            :loading="buttonLoader"
            color="#0000ff"
            dark
            type="submit"
        >
       Submit
        </v-btn>
      </v-card-actions>
    </form>



     <!--    adding account dialog-->
     <v-dialog v-model="account_dialog" width="35%">
       <v-card>
         <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
           <v-row>
             <v-card-title>Add Account</v-card-title>
             <v-spacer></v-spacer>
             <v-btn icon dark @click="account_dialog = false">
               <v-icon color="red">
                 mdi-close
               </v-icon>
             </v-btn>
           </v-row>
           <add-account
               :account_type_list="['COS']"
               ref="chart_of_account_components"
           ></add-account>
         </div>
       </v-card>
     </v-dialog>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddAccount from "@/modules/dashboard/accounting/commons/AddAccount";

export default {
  name: "AdjustStockData",
  components: {
    AddAccount,
  },
  data() {
    return {
      buttonLoader: false,
      // storage_account_dialog: false,
      storageAccountDataList: [],
      messageError: null,
      amountDue:null,
      account_dialog: false,
      itemsListDataInfo:null,
      account_number: null,
      date: new Date().toISOString().substr(0, 10),
      accountDataItem: [],
      // payment_methods_dialog: false,
      paymentMethodDataList: [],
      adjustType: [
          { text: "Select type", id: null },
          { text: "Increase Stock", id: "Increase" },
          { text: "Decrease Stock", id: "Decrease" }
      ],
      ItemDataObj: [],
      // add_item_dialog: false,
    };
  },
  mounted() {
    this.doGetStockableItems();
    this.fetchAccountsData()
    if (this.invoiceInfo) {
      this.filterPaymentInfo();
    }
  },
  methods: {
    ...mapActions([
      "doGetStockableItems",
      "doAdjustStock",
      "doEditInvoicePaymentDetails",
       "doGetAccountData"
    ]),
    fetchAccountsData(){
      this.doGetAccountData("COS");
    },
    adjustStockInfo() {
      this.buttonLoader = true;
      this.messageError = null;

      let arr = []
      let obj = {}
      this.itemsListDataInfo.forEach(list => {
        obj = {
          item_number:list.number,
          quantity:list.quantity ? list.quantity.toString().replace(/,/g, "") : 0
        }
        arr.push(obj)
      })

      let formData = {}
      formData.account_number = this.account_number
      formData.date = this.date
      formData.items = arr
        this.doAdjustStock(formData);



    },


    filterPaymentInfo() {
      this.messageError = "";
      this.form.invoice_number = this.invoiceInfo.number;
      this.invoice_counter = this.invoiceInfo.counter;
    },
    newStockFilter(dataList) {
      let dataObj = [{ text: "Select item", id: "", disabled: true }];
      let dataJson = {};
      dataList.forEach(list => {
        dataJson = {
          text: list.name ? list.name : "",
          id: list.number ? list.number : "",
          disabled: false,
          buying_price:list.selling_price ? list.selling_price : "",
          selling_price:list.selling_price ? list.selling_price : "",
          tax: list.tax.length > 0 ? list.tax : [],
        };
        dataObj.push(dataJson);
      })

      return dataObj;
    },
    filterItemsList(itemDataList){
      if(itemDataList){
        let arr = []
        let obj = {}


        itemDataList.forEach((list,index) => {
          obj = {
            key:(index+1),
            number:list.number,
            name:list.name,
            remaining_stock:list.remaining_stock.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            quantity:list.remaining_stock.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          }
          arr.push(obj)
        })
        this.itemsListDataInfo = arr
      }
    },
    computeValues(value,index){
        value = value.toString().replace(/,/g, "");
        if (!isNaN(value)) {
          value = value - 0;
        }

        this.itemsListDataInfo[index].quantity = value ? eval(value).toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
    },
    accountModel() {
      this.account_dialog = true;
    },
  },
  computed: {
    ...mapGetters([
      "getUnitData",
      "getNewPaymentMethods",
      "getNewStorageAccountData",
      "getAdjustmentError",
      "getInvoiceBillBalanceInfo",
      "getInvoicePaymentDetails",
       "getStockableItems",
       "getChartOfAccountsData",
        "getAccountDataSet"
    ])
  },
  watch: {
    getAccountDataSet(accountData) {
      this.accountDataItem = this.accountFilter(accountData);
    },
    getChartOfAccountsData(chartData) {
      if (chartData) {
        this.account_dialog = false;
        this.account_number = chartData.data.number;

        this.$refs["chart_of_account_components"].chartSpinner = false;
        this.$refs["chart_of_account_components"].form = {
          name: null,
          code: null,
          description: null,
          super_account: null,
          number: null
        };

        this.successToast("Success", chartData.message);


        this.fetchAccountsData()
      }
    },
    getStockableItems(itemDataList) {
      this.filterItemsList(itemDataList)
    },
    getNewPaymentMethods(paymentData) {
      if (paymentData) {
        this.$refs["paymentMethodsComponents"].form = {
          name: null,
          notes: null
        };
        this.$refs["paymentMethodsComponents"].addPaymentMethodLoader = false;
        this.payment_methods_dialog = false;
        this.form.payment_method_number = paymentData.data.number;
        this.successToast("Success", paymentData.message);
        this.doGetPaymentMethodData();
      }
    },
    getNewStorageAccountData(storageAccountData) {
      if (storageAccountData) {
        this.$refs["storageAccountComponent"].form = {};
        this.$refs["storageAccountComponent"].addStorageLoader = false;
        this.storage_account_dialog = false;
        this.form.storage_account_number = storageAccountData.data.number;

        this.successToast("Success", storageAccountData.message);
        this.doGetStorageAccountData(this.$company.number);
      }
    },
    getAdjustmentError(invoiceError) {
      if (invoiceError) {
        this.messageError = invoiceError.data.message;
        this.buttonLoader = false;
        this.errorToast("Error", invoiceError.data.message)
      }
    },
    getInvoiceBillBalanceInfo(balanceData) {
      if (balanceData) {

        this.form.number= null
        this.form.transaction_number= null
        this.form.payment_method_number= null
        this.form.storage_account_number= null
        this.form.payment_date= new Date().toISOString().substr(0, 10)
        this.form.notes= null


        this.form.amount = balanceData
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        this.amountDue = "Amount due: "+balanceData
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    invoiceInfo() {
      this.filterPaymentInfo();
    },
    getInvoicePaymentDetails(data){
      if(data){
        this.invoice_counter = data.invoice_counter ? data.invoice_counter : null
        this.form.number = data.number ? data.number : null
        this.form.transaction_number = data.transaction_number ? data.transaction_number : null
        this.form.invoice_number = data.invoice_number ? data.invoice_number : null
        this.form.payment_date = data.payment_date ? data.payment_date : null
        this.form.payment_method_number = data.payment_method_number ? data.payment_method_number : null
        this.form.storage_account_number = data.storage_account_number ? data.storage_account_number : null
        this.form.notes = data.notes ? data.notes : null
        this.form.amount = data.amount ?
            data.amount.
            toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null
      }
    }

  }
}
</script>

<style scoped>

</style>