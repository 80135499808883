<template>
 <div>
    <v-row>
      <v-col cols="6">
        <v-alert type="error" v-if="errorMessage">
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>

    <form ref="form" @submit.prevent="saveInventory">
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            outlined
              v-model="form.item_number"
              :items="invoiceItemDataObj"
              item-value="id"
              color="pink"
              label="Item"
              append-outer-icon="mdi-plus"
              @click:append-outer="addItemData"
              required
              hide-details
              dense
              :loading="itemsLoader"
              @change="fetchItemsList(form.item_number)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
           <v-autocomplete
               outlined
               v-model="form.unit_number"
               :items="unitDataList"
               item-value="id"
               label="Unit of Measure"
               append-outer-icon="mdi-plus"
               @click:append-outer="addItemUnitData"
               dense
               clearable
               hide-details
           ></v-autocomplete>
        </v-col>
        <v-col cols="2">
       <v-text-field
           v-model="form.quantity"
           label="Quantity"
           maxlength="17"
           outlined
           dense
           hide-details
           v-on:blur="validateQuantity(form.quantity)"
       ></v-text-field>
        </v-col>
        <v-col cols="2">
       <v-text-field
           readonly
           v-model="form.unit_cost"
           label="Unit cost"
           maxlength="17"
           outlined
           dense
           hide-details
       ></v-text-field>
         <small>Actual Unit Cost: {{actualCost}}</small>
        </v-col>
        <v-col cols="2">
      <v-text-field
          v-model="form.selling_price"
          label="Selling Price"
          outlined
          v-on:blur="computeNumber(form.selling_price, 'selling_price')"
          @keypress="onlyNumberValidator($event)"
          dense
          hide-details
      ></v-text-field>
          <small v-if="sellingPriceWarning" style="color: red;">{{sellingPriceWarning}}</small>
        </v-col>
        <v-col cols="4">
             <v-textarea
                 v-model="form.notes"
                 label="Assembly Description"
                 outlined
                 rows="1"
                 dense
                 hide-details
             ></v-textarea>
        </v-col>
          <v-col cols="2">
                   <v-text-field
                       v-model="form.date"
                       label="Date [ dd / mm / yyy ]"
                       outlined
                       dense
                       type="date"
                       required
                       hide-details
                   ></v-text-field>
            <small style="color: red;" v-if="yearLengthValidator(form.date)">
              Invalid Date
            </small>
                </v-col>
            <v-col cols="2">
                   <v-text-field
                       v-model="form.expiry_date"
                       label="Expiry Date [ dd / mm / yyy ]"
                       outlined
                       dense
                       type="date"
                       hide-details
                   ></v-text-field>
                  <small style="color: red;" v-if="yearLengthValidator(form.expiry_date)">
                    Invalid Date
                  </small>
                </v-col>
      </v-row>

           <p class="ml-2 mt-8"> Bill of Materials</p>
           <v-simple-table  class="elevation-1"  dense >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th style="border-right: 1px solid #e0e0e0; width: 20%;" class="text-left">Item</th>
                    <th style="border-right: 1px solid #e0e0e0; width: 15%;" class="text-left">Description</th>
                     <th style="border-right: 1px solid #e0e0e0; width: 15%;" class="text-left">Current in Stock</th>
                    <th style="border-right: 1px solid #e0e0e0; width: 10%;" class="text-left">Unit Quantity</th>
                    <th style="border-right: 1px solid #e0e0e0; width: 10%;" class="text-left">Required Quantity</th>
                     <th style="border-right: 1px solid #e0e0e0; width: 15%;" class="text-left">Unit Cost</th>
                     <th style="border-right: 1px solid #e0e0e0; width: 15%;" class="text-left">Total Cost</th>
                  </tr>
                </thead>
                <tbody>

                  <tr
                      v-for="(billOfItemsData, index) in form.bill_of_items"
                      :key="index"
                  >
                    <td style="border-right: 1px solid #e0e0e0;">
                         <v-text-field
                             v-model="billOfItemsData.name"
                             flat
                             readonly
                             solo
                             dense
                             hide-details
                         ></v-text-field>
                    </td>
                        <td style="border-right: 1px solid #e0e0e0;">
                         <v-text-field
                             v-model="billOfItemsData.description"
                             flat
                             readonly
                             solo
                             dense
                             hide-details
                         ></v-text-field>
                    </td>

                    <td style="border-right: 1px solid #e0e0e0; font-size: 16px;">
                      <div style="color: #065fdb;" class="ml-2">
                         {{billOfItemsData.remaining_stock}}
                      </div>


                    </td>
                      <td style="border-right: 1px solid #e0e0e0;">
                         <v-text-field
                             v-model="billOfItemsData.quantity"
                             flat
                             readonly
                             solo
                             dense
                             hide-details
                         ></v-text-field>
                    </td>
                    <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                         <v-text-field
                             color="red"
                             v-model="billOfItemsData.actualQuantity"
                             flat
                             readonly
                             solo
                             dense
                             hide-details
                         ></v-text-field>
                      <small style="color: red;" v-if="billOfItemsData.itemWarning">
                        Insufficient stock
                      </small>
                    </td>
                    <td style="border-right: 1px solid #e0e0e0;">
                         <v-text-field
                             v-model="billOfItemsData.buying_price"
                             flat
                             readonly
                             solo
                             dense
                             hide-details
                         ></v-text-field>
                    </td>
                    <td style="border-right: 1px solid #e0e0e0;">
                         <v-text-field
                             v-model="billOfItemsData.total"
                             flat
                             readonly
                             solo
                             dense
                             hide-details
                         ></v-text-field>
                    </td>
                  </tr>
                <tr>
                  <td colspan="5"></td>
                  <td class="text-right"><b>Total Item(s) Cost</b></td>
                  <td>
                    <b> {{totalItemsCost}}</b>
                  </td>
                </tr>

                </tbody>
              </template>
            </v-simple-table>


      <v-row class="mx-auto flex justify-end mt-3">
        &nbsp;
        <v-btn
            @click="showOtherExpenses"
            x-small
            class="float-right"
            outlined
            color="indigo"
        >
                             {{ !showAdditionalItems ? "Show" : "Hide" }}   Other Expenses
                        </v-btn>
      </v-row>



       <p v-if="showAdditionalItems" class="ml-5 mt-8">Other Expenses</p>

         <v-simple-table v-if="showAdditionalItems" class="table elevation-1" dense style="width: 100%;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Category</th>
                      <th class="text-left">Bank/Cash Account</th>
                      <th class="text-left">Notes</th>
                      <th class="text-left" colspan="2">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="(expenseItemData, key) in form.expense_items"
                        v-bind:key="key"
                    >
                      <td style="border-right: 1px solid #e0e0e0; width: 24%;">
                        <v-text-field
                            v-model="expenseItemData.name"
                            flat
                            solo
                            dense
                            hide-details
                        ></v-text-field>
                      </td>

                      <td style="border-right: 1px solid #e0e0e0; width: 20%;">
                        <v-autocomplete
                            v-model="expenseItemData.account_number"
                            :items="accountDataItem"
                            item-value="id"
                            color="pink"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="accountModel"
                            dense
                            flat
                            solo
                            hide-details
                        ></v-autocomplete>
                      </td>

                      <td style="border-right: 1px solid #e0e0e0; width: 20%;">
                        <v-autocomplete
                            v-model="expenseItemData.storage_account_number"
                            :items="storageAccountDataList"
                            item-value="id"
                            color="pink"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="accountModel"
                            dense
                            flat
                            solo
                            hide-details
                        ></v-autocomplete>
                      </td>


                      <td style="border-right: 1px solid #e0e0e0; width: 20%;">
                        <v-text-field
                            v-model="expenseItemData.notes"
                            flat
                            solo
                            rows="1"
                            dense
                            no-resize
                            hide-details
                        ></v-text-field>
                      </td>

                      <td style="width: 20%;">
                        <v-text-field
                            v-model="expenseItemData.amount"
                            flat
                            solo
                            dense
                            hide-details
                            @keypress="onlyNumberValidator($event)"
                            v-on:blur="
                            computeExpense(expenseItemData.amount, key)
                          "
                        ></v-text-field>
                      </td>

                      <td width="1%">
                        <v-icon
                            @click="removesExpenseItem(key)"
                            style="font-size: 20px;"
                        >mdi-close</v-icon
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td class="text-right"><b>Total Additional Expenses</b></td>
                      <td colspan="2"><b>{{totalAdditionalExpenses}}</b></td>
                    </tr>
                    <tr>
                      <td
                          colspan="6"
                          @click="addExpenseItems"
                          style="cursor: pointer;"
                      >
                        <span class="text--disabled">Add another item</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>


      <p v-if="form.item_number">Cost sheet</p>
      <v-simple-table class="table elevation-1" dense v-if="form.item_number" >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Item</th>
            <!--                       <th class="text-left">Unit</th>-->
            <th class="text-left">Quantity</th>
            <th class="text-left">
              Actual Cost
              <v-tooltip color="#000000" right>
                <template v-slot:activator="{ on, attrs }">

                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ma-2"
                      outlined
                      x-small
                      fab
                      color="indigo"
                  >
                    <v-icon size="23">mdi-information-variant</v-icon>
                  </v-btn>
                </template>
                <span>
                            Formula:  (((BuyingPrice / TotalBuyingPrice) * TotalExpense) + (BuyingPrice))
                          </span>
              </v-tooltip>


            </th>
<!--            <th class="text-left" colspan="2">Selling Price</th>-->
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(billOfItemsInfo, indx) in form.bill_of_items"
              :key="indx+'k'"
          >
            <td style="border-right: 1px solid #e0e0e0;">
              {{billOfItemsInfo.name}}
            </td>
            <td style="border-right: 1px solid #e0e0e0;">
              {{billOfItemsInfo.actualQuantity ? billOfItemsInfo.actualQuantity.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}}
            </td>
            <td style="border-right: 1px solid #e0e0e0;">
              {{computeActualCostPrice(billOfItemsInfo.total)}}
            </td>
<!--            <td>
&lt;!&ndash;              {{form.selling_price}}&ndash;&gt;
            </td>-->
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td class="text-right"><b>Total Cost</b></td>
            <td><b>{{totalActualCost}}</b></td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>



      <v-row class="mt-5">
        <v-spacer></v-spacer>
        <v-btn
            style="color: #ffffff"
            :disabled="!form.item_number"
            class="companyButton mr-3"
            :loading="stockLoader"
            color="#0000ff"
            type="submit"
        >
          Save
        </v-btn>
      </v-row>


    </form>

     <v-dialog v-model="add_item_unit_dialog" width="30%">
      <v-card>
        <div style="width: 95%; padding-bottom: 10px;" class="mx-auto">
          <v-row>
            <v-card-title> Add Unit</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_item_unit_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-unit ref="unit_components"></add-unit>
        </div>
      </v-card>
    </v-dialog>

   <!--    adding account dialog-->

    <v-dialog v-model="account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <add-account
              :account_type_list="['EXP','COS']"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>


   <!--      adds items-->
   <v-dialog v-model="add_item_dialog" width="65%">
     <v-card>
       <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
         <v-row>
           <v-card-title>Add Item</v-card-title>
           <v-spacer></v-spacer>
           <v-btn icon dark @click="add_item_dialog = false">
             <v-icon color="red">
               mdi-close
             </v-icon>
           </v-btn>
         </v-row>
         <add-item ref="items_components"></add-item>
       </div>
     </v-card>
   </v-dialog>


 </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddUnit from "@/modules/dashboard/accounting/commons/AddUnit";
import AddAccount from "@/modules/dashboard/accounting/commons/AddAccount";
import AddItem from "@/modules/dashboard/accounting/commons/AddItem";


export default {
  name: "Assembly",
  components: {AddItem, AddAccount, AddUnit},
  data(){
    return{
      errorMessage:null,
      invoiceItemDataObj: [],
      add_item_dialog: false,
      itemsLoader:false,
      account_dialog: false,
      unitDataList:[],
      stockLoader:false,
      totalActualCost:0,
      sellingPriceWarning:null,
      storageAccountDataList: [],
      form:{
        item_number:null,
        unit_cost:null,
        unit_number:null,
        quantity:0,
        selling_price:0,
        notes:null,
        expiry_date:null,
        date: new Date().toISOString().substr(0, 10),
        bill_of_items: [
          {
            name:null,
            item_number:null,
            quantity:null,
            actualQuantity:null,
            itemWarning:false,
            description:null,
            unit_number:null,
            remaining_stock:null,
            buying_price:null,
            total:null,
            ref_code:null,
          }
        ],
        expense_items: []
      },
      add_item_unit_dialog: false,
      showAdditionalItems:false,
      accountDataItem: [],
      totalAdditionalExpenses:0,
      totalItemsCost:0,
      actualCost:0
    }
  },
  mounted(){
    this.fetchAssemblyItemsList()
    this.doGetUnitDataList();
    this.itemsLoader = true
    this.fetchAccountsAdditionalCosts()
    this.doGetStorageAccountData();
  },
  methods:{
    ...mapActions(
        [
            "fetchAssemblyItemsList",
            "fetchStockedAssemblyItems",
            "doGetUnitDataList",
            "saveStockedAssemblyItemData",
            "doGetAccountData",
            "doGetStorageAccountData"
        ]
    ),
    accountModel() {
      this.account_dialog = true;
    },
    fetchAccountsAdditionalCosts(){
      this.doGetAccountData("EXP,COS");
    },
    filterStockItemsList(itemsList) {
      let itemJsn = {};
      let itemObj = [{ text: "Select Item", id: null }];
      itemsList.forEach(list =>{
        let unitAbb = list.unit ? list.unit.abbreviation : ''
        itemJsn = {
          text: list.name+" ( "+unitAbb+" )",
          id: list.number,
          unit_number: list.unit ? list.unit.number : '',
          selling_price: list.selling_price ? list.selling_price : '',
          buying_price: list.buying_price ? list.buying_price : ''
        };
        itemObj.push(itemJsn);
      })

      this.invoiceItemDataObj = itemObj;
      this.itemsLoader = false
    },
    addItemData(){
      this.add_item_dialog = true;
    },
    fetchItemsList(item_number){

      this.sellingPriceWarning = ""
      this.actualCost = ""
      this.totalItemsCost = ""
      this.totalActualCost = ""
      this.invoiceItemDataObj.forEach(list => {
        if(item_number === list.id){
          this.form.unit_number = list.unit_number
          this.form.selling_price = list.selling_price.toLocaleString('en-US')
          // this.form.unit_cost = list.buying_price.toLocaleString('en-US')
          this.form.quantity = 1
        }
      })

      this.form.bill_of_items = []

      this.fetchStockedAssemblyItems({number:item_number, quantity:1})
    },
    filterPartItemsList(data) {
      if(data && (data.length > 0)) {
        this.form.bill_of_items = []
        let obj = {}
        let arr = []

        data.forEach(list => {
          let actualQty = this.form.quantity ? (list.quantity * this.form.quantity) : 0
          obj = {
            name: list.part_item.name + " (" + list.part_item.unit.abbreviation + ")",
            item_number: list.part_item ? list.part_item.number : null,
            description: list.part_item ? list.part_item.notes : null,
            ref_code: list.part_item ? list.part_item.ref_code : null,
            quantity: list.quantity ? list.quantity.toLocaleString('en-US') : null,
            remaining_stock: list.part_item.remaining_stock ? list.part_item.remaining_stock.toLocaleString('en-US') : 0,
            total: (actualQty * list.part_item.buying_price).toLocaleString('en-US'),
            unit_number: list.unit_number ? list.unit_number : null,
            buying_price: list.unit_cost ? list.unit_cost.toLocaleString('en-US') : 0,
            actualQuantity: actualQty ? actualQty : '-',
            itemWarning: false,
          }
          arr.push(obj)
        });

        this.form.bill_of_items = arr
      }
    },
    saveInventory(){
      this.stockLoader = true
      this.errorMessage = ""
      let formData = {...this.form}
          formData.selling_price = this.form.selling_price ? parseFloat(this.form.selling_price.toString().replace(/,/g, "")) : 0
          formData.quantity = this.form.quantity ? parseFloat(this.form.quantity.toString().replace(/,/g, "")) : 0
          if(formData.expense_items && (formData.expense_items.length > 0)){
            formData.expense_items.forEach(lst => {
              lst.amount = (lst.amount.toString().replace(/,/g, "") * 1)
            })
          }
      this.saveStockedAssemblyItemData(formData)
    },
    computeNumber(value, vModal) {
      ///this removes any value that starts with a zero
      value = value.toString().replace(/,/g, "")
      value = parseFloat(value)

     if (vModal === "selling_price") {
        this.form.selling_price =
            value ? eval(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      }

    },
    addItemUnitData() {
      this.add_item_unit_dialog = true;
    },
    validateQuantity(data){
      if(data){
        data = data.toString().replace(/,/g, "")
        data = parseFloat(data)
        this.fetchStockedAssemblyItems({number:this.form.item_number, quantity:data})
        this.form.quantity = data ? eval(data).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      }
    },
    showOtherExpenses(){
      this.showAdditionalItems =  !this.showAdditionalItems

      if(this.showAdditionalItems){
        this.form.expense_items = [
          {
            number: null,
            name: null,
            account_number: null,
            storage_account_number:null,
            amount: null,
            notes: null,
          }
        ]
      }else {
        this.form.expense_items = []
      }
    },
    computeExpense(value, index) {
      if (value) {
        value = value.toString().replace(/,/g, "");

        if (!isNaN(value)) {
          value = value * 1;
        }

        this.form.expense_items[index].amount = eval(value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    addExpenseItems() {
      this.form.expense_items.push({
        number: null,
        name: null,
        account_number: null,
        storage_account_number:null,
        amount: null,
        notes: null,
      });
    },
    removesExpenseItem(key) {
      this.form.expense_items.splice(key, 1);
    },
   /* returnItemName(itemNumber){
      if(itemNumber){
        const item = this.invoiceItemDataObj.find(item => item.id === itemNumber);
        return item ? item.text : "";
      }
    },*/
    computeActualCostPrice(totalCost){
      if(totalCost){

        let sellingPrice  = this.form.selling_price ? this.form.selling_price.toString().replace(/,/g, "") : 0
        let quantity = this.form.quantity ? this.form.quantity : 0

        //items cost
        let itemsList = this.form.bill_of_items[0].item_number ? this.form.bill_of_items : []
        let totalUnitCost = 0
        if(itemsList.length > 0){
          itemsList.forEach(lst => {
            totalUnitCost += parseFloat(lst.total.toString().replace(/,/g, ""))
          })
        }

        let expenseItems = this.form.expense_items.length ? this.form.expense_items : []
        let totalExpense = 0
        if(expenseItems.length > 0){
          expenseItems.forEach(list =>{
            if(list.amount) {
              totalExpense += (list.amount.toString().replace(/,/g, "") * 1)
            }
          })
        }

        //actual cost per item
        let itemsList_ = this.form.bill_of_items[0].item_number ? this.form.bill_of_items : []
        let totalUnitCost_ = 0
        if(itemsList_.length > 0){
          itemsList_.forEach(lst => {
            let actualCost_ =  ((lst.total / totalUnitCost) * totalExpense) + lst.total
            totalUnitCost_ += actualCost_
          })
        }

        // console.log(totalUnitCost_)
        this.totalActualCost = this.transformNumbersToAccountingStandard(totalUnitCost_)
        let actualUnitCost = (totalUnitCost_ > 0) ? (totalUnitCost_ / quantity) : 0
        this.actualCost = this.transformNumbersToAccountingStandard(actualUnitCost)
        this.sellingPriceWarning =  (sellingPrice <= actualUnitCost) ? "Selling price is less than or equal to actual unit Cost of : "+this.transformNumbersToAccountingStandard(actualUnitCost):null


        let actualCost =  ((totalCost / totalUnitCost) * totalExpense) + totalCost
        this.totalAdditionalExpenses = this.transformNumbersToAccountingStandard(totalExpense)
        this.totalItemsCost = this.transformNumbersToAccountingStandard(totalUnitCost)

        this.form.unit_cost = (totalUnitCost > 0) ? this.transformNumbersToAccountingStandard(totalUnitCost / this.form.quantity) : 0

        return this.transformNumbersToAccountingStandard(actualCost)
      }

    },
    filterAssemblyItemsData(data){
      if(data){
        let dataInfo = this.form.bill_of_items
        if(dataInfo.length > 0){
          dataInfo.forEach(list => {
            let quantity = this.form.quantity ? parseFloat(this.form.quantity) : 0
            let actual = quantity ? (quantity * (list.quantity * 1)) : 0
            let remaining_stock = list.remaining_stock ? (list.remaining_stock * 1) : 0
            let totalAmount = actual > 0 ? (actual * list.buying_price.toString().replace(/,/g, "")) : 0
            list.actualQuantity = actual > 0 ? actual : 0
            list.itemWarning = (actual > remaining_stock) && (list.ref_code !== "SRV")
            list.total = totalAmount > 0 ? totalAmount : 0
          })
        }
      }
    }
  },
  computed:{
    ...mapGetters(
        [
            "getStockAssemblyItemsData",
            "getSelectedAssemblyItemsData",
            "getUnitDataList",
            "getUnitData",
            "getNewStockAssembly",
            "getStockError",
            "getAccountDataSet",
            "getChartOfAccountsData",
            "getItemData",
            "getStorageAccountDataSet"
        ]
    )
  },
  watch:{
    getStorageAccountDataSet(storageAccountData) {
      this.storageAccountDataList = this.nameNumberFilter(storageAccountData);
    },
    getItemData(itemsData) {
      if (itemsData) {
        this.add_item_dialog = false;
        // this.$refs["items_components"].form = {};
        this.fetchItemsList();
        this.successToast("Success", itemsData.message);
      }
    },
    getChartOfAccountsData(chartData) {
      if (chartData) {
        this.form.account_number = chartData.data.number;
        this.$refs["chart_of_account_components"].chartSpinner = false;
        this.$refs["chart_of_account_components"].form = {};
        this.account_dialog = false;

        this.successToast("Success", chartData.message);

        this.fetchAccountsAdditionalCosts()
      }
    },
    getAccountDataSet(accountData) {
      this.accountDataItem = this.accountFilter(accountData);
    },
    getStockAssemblyItemsData(data){
      if(data){
        this.filterStockItemsList(data)
      }
    },
    getSelectedAssemblyItemsData(data){
      if(data){
        this.filterPartItemsList(data)
      }
    },
    getUnitDataList(unitDataList) {
      if(unitDataList) {
        this.unitDataList =  this.filterUnits(unitDataList);
      }
    },
    getUnitData(unitData) {
      if (unitData) {
        this.form.unit_number = unitData.data.number;
        this.$refs["unit_components"].addUnitLoader = false;
        this.$refs["unit_components"].form = {
          name: null,
          abbreviation: null
        };
        this.add_item_unit_dialog = false;
        this.doGetUnitDataList();
        this.successToast("Success", unitData.message);
      }
    },
    getNewStockAssembly(data){
      if(data){
        this.stockLoader = false
        this.successToast("Success", data.message)
        this.form = {
                      item_number:null,
                      unit_number:null,
                      quantity:null,
                      selling_price:null,
                      notes:null,
                      expiry_date:null,
                      bill_of_items: [
                    {
                      name:null,
                      item_number:null,
                      quantity:null,
                      description:null,
                      unit_number:null,
                      remaining_stock:null,
                    }
                  ],
                }

      }
    },
    getStockError(data){
      if(data){
        this.errorToast("Error", data.message)
        this.stockLoader = false
        this.errorMessage = data.message
      }
    },
    form:{
      handler(data){
        if(data) {
          this.filterAssemblyItemsData(data)
        }
      },
      deep:true
    },
  }
}
</script>

<style scoped>

</style>