import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  newDiscountData: null,
  discountErrorResponse: null
};

const getters = {
  getDiscountData: state => state.newDiscountData,
  getDiscountErrorResponse: state => state.discountErrorResponse
};

const actions = {
  async doSaveDiscount({ commit }, data) {
    await axios
      .post(
        data.discount_number
          ? IP + "discount/update/" + data.discount_number
          : IP + "discount/create",
        data
      )
      .then(response => {
        commit("setAddDiscountData", response);
      })
      .catch(error => {
        commit("setDiscountErrorResponse", error);
      });
  }
};

const mutations = {
  setAddDiscountData: (state, response) => {
    state.newDiscountData = response.data;
  },
  setDiscountErrorResponse: (state, error) => {
    state.discountErrorResponse = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
