import axios from "axios";
import configs from "@/global/configs.json";
const IP = configs.SERVER_IP;
const state = {
  assets: [],
  message: {},
  transactionDataSet: null,
  liabilityDataSet: null,
  liabilityPaymentsDataSet: null,
  liabilityPaymentDataInfo: null,
  liabilityPaymentDeleteInfo: null,
  deleteTransactionInfo: null,
  transactionError: null,
  assetsDataInfo: null
};

const getters = {
  getAssets: state => state.assets,
  getAssetsError: state => state.message,
  getTransactionsDataSet: state => state.transactionDataSet,
  getLiabilityDataSet: state => state.liabilityDataSet,
  getLiabilityPaymentsDataSet: state => state.liabilityPaymentsDataSet,
  getLiabilityPaymentDataInfo: state => state.liabilityPaymentDataInfo,
  getLiabilityPaymentDeleteInfo: state => state.liabilityPaymentDeleteInfo,
  getDeleteTransactionInfo: state => state.deleteTransactionInfo,
  getAssetsDataInfo: state => state.assetsDataInfo,
  getTransactionError: state => state.transactionError
};

const actions = {
  async addAsset({ commit }, data) {
    await axios
      .post(IP + "", data)
      .then(response => {
        commit("setAssetsData", response.data);
      })
      .catch(error => {
        // console.log(error);
        commit("setAssetsErrorMessage", error.response);
      });
  },
  async fetchTransactions({ commit }, data) {
    let search = data.search ? data.search : "";
    await axios
      .get(
        IP +
          "company/transactions?" +
          "page="+data.page+
          "&per_page="+data.per_page+
          "&type="+data.type+
          "&search="+search
      )
      .then(response => {
        commit("getTransactionDataSet", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async fetchLiabilities({ commit }, data) {
    let search  = data ? data.search ? data.search : "" : ""
    await axios
      .get(
        IP +
          "liabilities/all?page="+data.page+
          "&per_page="+data.per_page+
          "&type="+data.type+
          "&search="+search
      )
      .then(response => {
        commit("getLiabilityDataSet", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async fetchLiabilitiesPayments({ commit }, data) {
    let search  = data ? data.search ? data.search : "" : ""
    await axios
      .get(
        IP +
          "liability-payments/all?page="+data.page+
          "&per_page="+data.per_page+
          "&type="+data.type+
          "&search="+search
      )
      .then(response => {
        commit("getLiabilityPaymentsDataSet", response);
      })
      .catch(error => {
        commit("setTransactionErrorMessage", error);
      });
  },
  async doFetchLiabilityPaymentInfo({ commit }, data) {
    await axios
        .get(IP + "liability-payments/details/"+data)
        .then(response => {
          commit("setLiabilityPaymentDataInfo", response.data);
        })
        .catch(error => {
          commit("setTransactionErrorMessage", error.response);
        });
  },
  async doDeleteLiabilityPayment({ commit }, data) {
    await axios
        .post(IP + "liability-payments/delete/"+data)
        .then(response => {
          commit("setLiabilityPaymentDeleteInfo", response.data);
        })
        .catch(error => {
          commit("setTransactionErrorMessage", error.response);
        });
  },
  async deleteTransactionItems({ commit }, data) {
    await axios
        .post(IP + "transactions/delete/"+data)
        .then(response => {
          commit("setDeleteTransactionInfo", response.data);
        })
        .catch(error => {
          commit("setTransactionError", error.response);
        });
  },
};

const mutations = {
  setAssetsData: (state, response) => {
    state.assets = response.data;
  },
  setAssetsDataInfo: (state, response) => {
    state.assetsDataInfo = response;
  },
  getTransactionDataSet: (state, response) => {
    state.statusExpenseResponse = "success";
    state.transactionDataSet = response.data;
  },
  getLiabilityDataSet: (state, response) => {
    state.liabilityDataSet = response.data;
  },
  getLiabilityPaymentsDataSet: (state, response) => {
    state.liabilityPaymentsDataSet = response.data;
  },
  setLiabilityPaymentDataInfo: (state, response) => {
    state.liabilityPaymentDataInfo = response;
  },
  setLiabilityPaymentDeleteInfo: (state, response) => {
    state.liabilityPaymentDeleteInfo = response;
  },
  setDeleteTransactionInfo: (state, response) => {
    state.deleteTransactionInfo = response;
  },
  setTransactionError: (state, error) => {
    state.transactionError = error.data
  },
  setAssetErrorMessage: (state, error) => {
    state.message = error.response
      ? error.response.data
      : "Error: Network Error";
  }
};

export default {
  getters,
  state,
  actions,
  mutations
};
