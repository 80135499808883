import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  stockItemsDataList: null,
  newSaleData: null,
  stockItemsErrorResponse: null
};

const getters = {
  getStockItemsDataList: state => state.stockItemsDataList,
  getNewSaleData: state => state.newSaleData,
  getStockItemsErrorResponse: state => state.stockItemsErrorResponse
};

const actions = {
  async fetchItemsList({ commit }, data) {
    await axios
      .get(
        data === "customer" ? IP + "items/for-invoice" : IP + "items/for-bill"
      )
      .then(response => {
        commit("setStockItemsDataList", response);
      })
      .catch(error => {
        commit("setStockItemErrorMessage", error);
      });
  },
  async doMakeSale({ commit }, data) {

    if(data.bill_of_items.length > 0) {
      data.bill_of_items.forEach((list, i) => {
        data.bill_of_items[i].quantity = (list.quantity.toString().replace(/,/g, "") * 1);
        data.bill_of_items[i].unit_price = (list.unit_price.toString().replace(/,/g, "") * 1);
        data.bill_of_items[i].total_amount = (list.total_amount.toString().replace(/,/g, "") * 1);
      })
    }

    //removes commas on expense
    if(data.expense_items.length > 0) {
      data.expense_items.forEach((list, i) => {
        data.expense_items[i].amount = list.amount
            ? (list.amount.toString().replace(/,/g, "") * 1)
            : "";
      })
    }

    await axios
      .post(IP + "pos/make-sale", data)
      .then(response => {
        commit("setNewSaleData", response);
      })
      .catch(error => {
        commit("setStockItemErrorMessage", error);
      });
  }
};

const mutations = {
  setStockItemsDataList: (state, response) => {
    state.stockItemsDataList = response.data;
  },
  setNewSaleData: (state, response) => {
    state.newSaleData = response.data;
  },
  setStockItemErrorMessage: (state, error) => {
    state.stockItemsErrorResponse = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
