<template>
  <span>
<!--    <v-row>-->
<!--      <v-col>-->
<!--        <v-card>-->
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Activity Logs</v-toolbar-title>
          </v-toolbar>

<!--          <v-card flat>-->
            <v-row class="mt-8">
              <v-col cols="8">

              </v-col>

              <v-col cols="4">
                <v-text-field
                  class="mr-3"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              :headers="storage_account_headers"
              :items="activityLogTableData"
              :search="search"
              :loading="activityLogsLoader"
              loading-text="Loading... please wait"
              item-key="name"
              class="elevation-1"
              :page="page"
              :items-per-page="items_per_page"
              :server-items-length="server_items_length"
              :options.sync="paginationActivityLogs"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="ViewDetails(item)">
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
<!--          </v-card>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--    </v-row>-->

    <v-dialog v-model="ActivityLogDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Activity Log Details</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="ActivityLogDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <activity-logs-info :logInfo="logInfo"></activity-logs-info>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ActivityLogsInfo from "@/modules/dashboard/accounting/Settings/ActivityLogs/ActivityLogsInfo";

export default {
  name: "ActivityLogs",
  components: { ActivityLogsInfo },
  data() {
    return {
      search: null,
      storage_account_headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Date", value: "date" },
        { text: "Log Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Causer", value: "causer" },
        { text: "Action", value: "actions", sortable: false, width: "7%" }
      ],
      page: 1,
      items_per_page: 10,
      server_items_length: 0,
      paginationActivityLogs: {},
      activityLogsLoader: false,
      activityLogTableData: [],
      json_fields: {},
      editStorageAccountObj: {},
      ActivityLogDialog: false,
      logInfo: {}
    };
  },
  methods: {
    ...mapActions(["fetchActivityLogs"]),
    filterActivityLogs(raw_data) {
      let activityLogsDataList = raw_data.data;
      this.server_items_length = raw_data.total;
      this.activityLogsLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let activityLogsJsn = {};
      let activityLogsObj = [];

      for (let i = 0; i < activityLogsDataList.length; i++) {
        let attributes = activityLogsDataList[i].properties.attributes;
        activityLogsJsn = {
          key: (current_page - 1) * per_page + i + 1,
          date: activityLogsDataList[i].created_at,
          name: activityLogsDataList[i].log_name,
          description: activityLogsDataList[i].description,
          causer: activityLogsDataList[i].causer_type,
          attributes: attributes
        };
        activityLogsObj.push(activityLogsJsn);
      }
      this.activityLogTableData = activityLogsObj;
    },
    ViewDetails(item) {
      this.ActivityLogDialog = true;
      this.logInfo = item;
    }
  },
  computed: {
    ...mapGetters(["getActivityLogsData"])
  },
  watch: {
    paginationActivityLogs(obj) {
      this.activityLogsLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage
      };

      this.fetchActivityLogs(options);
    },
    getActivityLogsData(activityData) {
      if (activityData) {
        this.filterActivityLogs(activityData);
      }
    }
  }
};
</script>

<style scoped></style>
