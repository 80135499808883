import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
    sales: null,
    stockMovement: null,
    salesError: null
};

const getters = {
    getSales: state => state.sales,
    getStockMovement: state => state.stockMovement,
    getSalesError: state => state.salesError
};

const actions = {
    async doGetSalesItemsList({commit}, queryString) {
        //let search = data.search ? data.search : ""
        await axios
            .get(IP +"pos/sales?"+ queryString)
            .then(response => {
                commit("setSalesData", response.data);
            })
            .catch(error => {
                commit("setSalesError", error);
            });
    },
    async doGetStockMovementList({commit}, data) {
        let search = data?data.search ? data.search: "":""
        await axios
            .get(
                IP + "pos/sales?page=" +
                data.page +
                "&per_page=" +
                data.per_page +
                "&type=assembled" +
                "&search=" + search
            )
            .then(response => {
                commit("setStockMovementData", response.data);
            })
            .catch(error => {
                commit("setSalesError", error);
            });
    },
};

const mutations = {
    setSalesData: (state, response) => {
        state.sales = response;
    },
    setStockMovementData: (state, response) => {
        state.stockMovement = response;
    },
    setSalesError: (state, error) => {
        state.salesError = error.response
            ? error.response.data
            : "Error: Network Error"; //error.response.message;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
