<template>
  <span>
    <form ref="form" @submit.prevent="saveUnit">
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.name"
            label="Name"
            outlined
            dense
            required
          ></v-text-field>

          <v-text-field
            v-model="form.abbreviation"
            label="Abbreviation"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="companyButton"
          :loading="addUnitLoader"
          color="#0000ff"
          dark
          type="submit"
        >
          {{ editUnitObj ? "Edit Unit" : "Save Unit" }}
        </v-btn>
      </v-card-actions>
    </form>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AddUnit",
  props: {
    editUnitObj: {}
  },
  data() {
    return {
      addUnitLoader: false,
      form: {
        name: null,
        abbreviation: null,
        number: null
      }
    };
  },
  mounted() {
    if (this.editUnitObj) {
      this.editUnitEditData();
    }
  },
  methods: {
    ...mapActions(["doSaveUnit"]),
    saveUnit(evt) {
      evt.preventDefault();
      this.addUnitLoader = true;
      this.doSaveUnit(this.form);
    },
    editUnitEditData() {
      this.form.name = this.editUnitObj.name;
      this.form.abbreviation = this.editUnitObj.abbreviation;
      this.form.number = this.editUnitObj.number;
    }
  },
  computed: {
    ...mapGetters(["getUnitData"])
  },
  watch: {
    editUnitObj() {
      this.editUnitEditData();
    }
  }
};
</script>

<style scoped></style>
