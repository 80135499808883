import axios from "axios";
import configs from "@/global/configs.json";
const IP = configs.SERVER_IP;
const state = {
  fixedTransactionDataSet: null,
  fixedTransactionErrorMessage: null,
  fixedTransactionDataResponse: null,
  fixedEditTransactionDataResponse: null
};

const getters = {
  getFixedTransactionDataSet: state => state.fixedTransactionDataSet,
  getFixedTransactionDataResponse: state => state.fixedTransactionDataResponse,
  getFixedTransactionErrorMessage: state => state.fixedTransactionErrorMessage,
  getFixedEditTransactionDataResponse: state => state.fixedEditTransactionDataResponse
};

const actions = {
  async addAsset({ commit }, data) {
    await axios
      .post(IP + "", data)
      .then(response => {
        commit("setAssetsData", response.data);
      })
      .catch(error => {
        commit("setAssetsErrorMessage", error.response);
      });
  },
  async fetchFixedAssetInfo({ commit }, data) {
    await axios
      .get(IP + "assets/details/"+data)
      .then(response => {
        commit("setAssetsDataInfo", response.data);
      })
      .catch(error => {
        commit("setAssetsErrorMessage", error.response);
      });
  },
  async fetchFixedAssetTransactions({ commit }, data) {
    let search = data.search ? data.search : "";
    await axios
        .get(
            IP +
            "assets?" +
            "page="+data.page+
            "&per_page="+data.per_page+
            "&type="+data.type+
            "&search="+search
        )
        .then(response => {
          commit("getFixedTransactionDataSet", response);
        })
        .catch(error => {
          commit("setFixedTransactionErrorMessage", error);
        });
  },
  async doSaveFixedTransaction({ commit }, data) {
    await axios
        .post(IP + "assets/new", data)
        .then(response => {
          commit("setFixedTransactionDataResponse", response);
        })
        .catch(error => {
          commit("setFixedTransactionErrorMessage", error);
        });
  },
  async doEditFixedTransaction({ commit }, data) {
    await axios
        .post( IP + "assets/update/" + data.number, data)
        .then(response => {
          commit("setFixedEditTransactionDataResponse", response);
        })
        .catch(error => {
          commit("setFixedTransactionErrorMessage", error);
        });
  },
};

const mutations = {
  getFixedTransactionDataSet: (state, response) => {
    state.fixedTransactionDataSet = response.data;
  },
  setFixedTransactionDataResponse: (state, response) => {
    state.fixedTransactionDataResponse = response.data;
  },
  setFixedEditTransactionDataResponse: (state, response) => {
    state.fixedEditTransactionDataResponse = response.data;
  },
  setFixedTransactionErrorMessage: (state, error) => {
    state.fixedTransactionErrorMessage = error.response
        ? error.response.data
        : "Error: Network Error";
  }
};

export default {
  getters,
  state,
  actions,
  mutations
};
