<template>
  <span>
    <v-toolbar dense flat>
      <v-toolbar-title>Customer Invoice</v-toolbar-title>
    </v-toolbar>

    <v-tabs>
      <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        <v-icon left>mdi-apps</v-icon>
        Invoices
      </v-tab>

      <v-tab>
        <v-icon left>mdi-pencil</v-icon>
        New Invoice
      </v-tab>

      <v-tab-item>
        <v-divider></v-divider>
        <invoice-table></invoice-table>
      </v-tab-item>

      <v-tab-item>
        <v-divider></v-divider>

        <v-card flat>
          <div class="mt-4 mx-auto" style="width: 98%; padding-bottom: 15px;">
            <new-invoice ref="invoice_items"></new-invoice>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </span>
</template>

<script>
import NewInvoice from "@/modules/dashboard/accounting/commons/NewInvoice";
import InvoiceTable from "@/modules/dashboard/accounting/profiles/InvoiceTable";
export default {
  name: "CustomerInvoice",
  components: { InvoiceTable, NewInvoice }
};
</script>

<style scoped></style>
