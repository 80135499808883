<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Supplier </v-toolbar-title>
          </v-toolbar>

          <v-tabs v-model="activeTab">
            <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
            <v-tab href="#supplier">
              <v-icon left>mdi-account</v-icon>
              Supplier List
            </v-tab>

            <v-tab href="#new-supplier">
              <v-icon left>mdi-lock</v-icon>
              New Supplier
            </v-tab>

            <v-tab-item id="supplier">
              <v-divider></v-divider>
              <v-card flat>
                <v-row class="mx-auto mt-8">
                  <v-col cols="8">
<!--                    <download-excel
                      style="color:#777777; width: 30%; margin-left: 2%; cursor: pointer;"
                      class="btn btn-default mt-3"
                      :data="supplierTableData"
                      :fields="json_fields"
                      worksheet="Expenses"
                      name="stock.xls"
                      >Download Excel
                      <v-icon color="#777777">
                        mdi-cloud-download
                      </v-icon>
                    </download-excel>-->
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      class="mr-3"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      dense
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-data-table
                  :headers="headers"
                  :items="supplierTableData"
                  :search="search"
                  :loading="tableSupplierLoader"
                  loading-text="Loading... please wait"
                  :page="page"
                  :items-per-page="items_per_page"
                  :server-items-length="server_items_length"
                  :options.sync="paginationSupplier"
                >
                  <!--                  add a link on the customer name-->
                  <template #item.name="{ item }">
                    <router-link
                      class="supplierName"
                      :to="{
                        name: 'supplier-details',
                        params: { id: item.name_url },
                        query: { sup: item.number }
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </template>

                  <template #item.contacts="{ item }">
                    <span v-if="item.contacts.length > 0"> Email: </span>
                    <a
                      v-for="(contact, index) in item.contacts"
                      v-bind:key="index"
                      class="supplierName"
                      :href="'mailto:' + contact.contact"
                      ><span v-if="contact.type === 'email'"
                        >{{ contact.contact }},
                      </span></a
                    ><br />
                    <span v-if="item.contacts.length > 0"> Phone: </span>
                    <a
                      v-for="(contact, index) in item.contacts"
                      v-bind:key="'A' + index"
                      class="supplierName"
                      :href="'tel:' + contact.contact"
                      ><span v-if="contact.type === 'phone'"
                        >{{ contact.contact }},
                      </span></a
                    >
                  </template>

                  <!--                  slot for table actions-->
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editSupplier(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteSupplier(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item id="new-supplier">
              <v-divider></v-divider>

              <v-card flat>
                <v-row>
                  <v-col cols="5" class="mt-15 ml-6">
                    <p style="font-size: 20px;">Add Supplier</p>
                    <add-supplier ref="supplierComponents"></add-supplier>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <!--  success Snack bar-->

    <v-snackbar
      v-model="supplierSnackbar"
      :timeout="timeout"
      outlined
      color="success"
      bottom
      left
      absolute
      shaped
    >
      {{ supplierErrorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="supplierSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="editSupplierDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Customer Info</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editSupplierDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-supplier
            ref="supplierComponents"
            :editSupplierObj="editSupplierObj"
          ></add-supplier>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AddSupplier from "../commons/AddSupplier";
import { mapActions, mapGetters } from "vuex";
import router from "@/global/router";
export default {
  name: "Supplier",
  components: { AddSupplier },
  data() {
    return {
      activeTab: "supplier",
      search: null,
      timeout: 3000,
      supplierSnackbar: false,
      supplierErrorMessage: null,
      paginationSupplier: {},
      tableSupplierLoader: false,
      supplierTableData: [],
      page: 1,
      items_per_page: 10,
      server_items_length: 0,
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Name", value: "name" },
        { text: "Contacts", value: "contacts", width: "30%" },
        { text: "Address", value: "address" },
        { text: "Tin", value: "tin" },
        { text: "Paid Out Amount", value: "paid_out_amount" },
        { text: "Bills Due", value: "bills" },
        { text: "Action", value: "actions", sortable: false }
      ],
      json_fields: {
        Name: "name",
        Contacts: "contacts",
        Address: "address",
        Tin: "tin"
      },
      editSupplierDialog: false,
      editSupplierObj: {}
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  methods: {
    ...mapActions(["doGetSuppliers", "doDeleteSupplier"]),
    filterSupplier(raw_data) {
      let supplierListData = raw_data.data;
      this.server_items_length = raw_data.total;
      this.tableSupplierLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let supplierListJsn = {};
      let supplierListObj = [];

      for (let i = 0; i < supplierListData.length; i++) {
        supplierListJsn = {
          key: (current_page - 1) * per_page + i + 1,
          name: supplierListData[i].name,
          name_url: (supplierListData[i].name).replace(" ", "-"),
          contacts: supplierListData[i].profile_contacts,
          number: supplierListData[i].number,
          address: supplierListData[i].address,
          tin: supplierListData[i].tin,
          paid_out_amount: supplierListData[i].paid_out_amount,
          bills: supplierListData[i].bills,
        };
        supplierListObj.push(supplierListJsn);
      }

      this.supplierTableData = supplierListObj;
    },
    editSupplier(item) {
      this.editSupplierObj = item;
      this.editSupplierDialog = true;
    },
    deleteSupplier(item) {
      let confirm_dele = confirm(
        "Are you sure you want to delete this Supplier?"
      );
      if (!confirm_dele) {
        return false;
      }
      this.doDeleteSupplier(item.number);
    }
  },
  computed: {
    ...mapGetters([
      "getSupplierData",
      "getSupplierDataInfo",
      "getDeleteSupplierData"
    ])
  },
  watch: {
    getSupplierData(supplierData) {
      if (supplierData) {
        this.$refs["supplierComponents"].addSupplierLoader = false;
        this.$refs["supplierComponents"].form = {
          name: null,
          address: null,
          tin: null,
          phoneContact: [
            {
              contact: null
            }
          ],
          emailContact: [
            {
              contact: null
            }
          ]
        };
        this.successToast("Success", supplierData.message);
        this.supplierSnackbar = true;
        this.supplierErrorMessage = supplierData.message;

        this.editSupplierDialog = false;

        // this.tableSupplierLoader = true;
        let options = {
          page: this.page,
          per_page: this.items_per_page
        };
        this.doGetSuppliers(options);
      }
    },
    paginationSupplier(obj) {
      this.tableSupplierLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage
      };
      this.doGetSuppliers(options);
    },
    getSupplierDataInfo(supplierInfo) {
      this.filterSupplier(supplierInfo);
    },
    getDeleteSupplierData(deleteSupplierInfo) {
      if (deleteSupplierInfo) {
        this.successToast("Success", deleteSupplierInfo.message);

        let options = {
          page: this.page,
          per_page: this.items_per_page
        };
        this.doGetSuppliers(options);
      }
    },
    activeTab(data) {
      router.push({ name: "supplier", query: { type: data } });
    }
  }
};
</script>

<style scoped>
.supplierName {
  text-decoration: none;
}

.supplierName:hover {
  color: green;
  text-decoration: underline;
}
</style>
