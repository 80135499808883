import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  discount: null,
  deleteDiscount: null,
  discountError: null
};

const getters = {
  getDiscount: state => state.discount,
  getDeleteDiscount: state => state.deleteDiscount,
  getDiscountError: state => state.discountError
};

const actions = {
  async doGetDiscountList({ commit }, data) {
    await axios
      .get(
        IP +
          "discount/all-paginated" +
          "?page=" +
          data.page +
          "&per_page=" +
          data.per_page
      )
      .then(response => {
        commit("setDiscountData", response.data);
      })
      .catch(error => {
        commit("setDiscountError", error);
      });
  },
  async doDeleteDiscount({ commit }, data) {
    await axios
      .post(IP + "discount/delete/" + data)
      .then(response => {
        commit("setDeleteDiscountData", response);
      })
      .catch(error => {
        commit("setDiscountError", error);
      });
  }
};

const mutations = {
  setDiscountData: (state, response) => {
    state.discount = response;
  },
  setDeleteDiscountData: (state, response) => {
    state.deleteDiscount = response;
  },
  setDiscountError: (state, error) => {
    state.discountError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
