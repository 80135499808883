import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  newLicense: null,
  licenseListData: null,
  deactivateLicense: null,
  activateLicense: null,
  allCompany: null,
  licenseError: null,
};

const getters = {
  getNewLicense: state => state.newLicense,
  getLicenseListData: state => state.licenseListData,
  getLicenseError: state => state.licenseError,
  getDeactivateLicense: state => state.deactivateLicense,
  getAllCompany: state => state.allCompany,
  getActivateLicense: state => state.activateLicense,
};

const actions = {
  async doLicenseData({ commit }, data) {
    let search  = data ? data.search ? data.search : "" : ""
    await axios
        .get(
            IP + "license/all?page=" + data.page +
            "&per_page=" + data.per_page+
            "&search="+search
        )
        .then(response => {
          commit("setLicenseListData", response);
        })
        .catch(error => {
          commit("setLicenseError", error);
        });
  },
  async saveNewLicense({ commit }, data) {
    await axios
      .put(IP + "license/create", data)
      .then(response => {
        commit("setNewLicense", response);
      })
      .catch(error => {
        commit("setLicenseError", error);
      });
  },
  async doDeactivateLicense({ commit }, data) {
    await axios
      .post(IP + "license/deactivate", data)
      .then(response => {
        commit("setDeactivateLicense", response);
      })
      .catch(error => {
        commit("setLicenseError", error);
      });
  },
  async doActivateLicense({ commit }, data) {
    await axios
      .put(IP + "license/activate", data)
      .then(response => {
        commit("setActivateLicense", response);
      })
      .catch(error => {
        commit("setLicenseError", error);
      });
  },
  async fetchCompanyList({ commit }, data) {
    await axios
      .get(IP + "company/all", data)
      .then(response => {
        commit("setAllCompany", response);
      })
      .catch(error => {
        commit("setLicenseError", error);
      });
  },

};

const mutations = {
  setLicenseListData: (state, response) => {
    state.licenseListData = response.data;
  },
  setNewLicense: (state, response) => {
    state.newLicense = response.data;
  },
  setDeactivateLicense: (state, response) => {
    state.deactivateLicense = response.data;
  },
  setActivateLicense: (state, response) => {
    state.activateLicense = response.data;
  },
  setAllCompany: (state, response) => {
    state.allCompany = response.data;
  },
  setLicenseError: (state, error) => {
    state.licenseError = error.response
        ? error.response.data
        : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
