var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-auto mt-8"},[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSearch($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.generalTableData,"search":_vm.search,"loading":_vm.tableBoxLoader,"loading-text":"Loading... please wait","item-key":"name","page":_vm.page,"items-per-page":_vm.items_per_page,"footer-props":{
                      'itemsPerPageOptions': _vm.items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text':'Items per page'
                    },"server-items-length":_vm.server_items_length,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
                    var pagination = ref.pagination;
                    var options = ref.options;
                    var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"Items per page","items-per-page-options":_vm.items_per_page_options,"showFirstLastPage":true,"firstIcon":"mdi-arrow-collapse-left","lastIcon":"mdi-arrow-collapse-right","prevIcon":"mdi-chevron-left","nextIcon":"mdi-chevron-right"},on:{"update:options":updateOptions}})]}},{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [(!item.locked)?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editExpense(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteExpense(item)}}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}])}),_c('v-dialog',{attrs:{"width":"70%"},model:{value:(_vm.editTransactionsDialog),callback:function ($$v) {_vm.editTransactionsDialog=$$v},expression:"editTransactionsDialog"}},[_c('v-card',[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"95%","padding-bottom":"20px"}},[_c('v-row',[_c('v-card-title',[_vm._v("Edit Asset Transaction")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.editTransactionsDialog = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('new-fixed-asset',{ref:"addExpenseItems",attrs:{"account_type":_vm.account_type}})],1)])],1),_c('ConfirmDialog',{ref:"deleteInvoicePaymentTransaction"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }