import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  newTaxData: null,
  taxDataAll: null,
  taxDetails: null,
  updateTaxData: null,
  deleteTaxData: null,
  currencyDataAll: null,
  // notificationChangeError: null
};

const getters = {
  getNewTaxData: state => state.newTaxData,
  getTaxDataAll: state => state.taxDataAll,
  getDeleteTaxData: state => state.deleteTaxData,
  getTaxDetails: state => state.taxDetails,
  getUpdateTaxData: state => state.updateTaxData,
  getCurrencyDataAll: state => state.currencyDataAll,
  // getNotificationChangeError: state => state.notificationChangeError
};

const actions = {
  async fetchTaxData({ commit }) {
    await axios
      .get(IP + "taxes/all")
      .then(response => {
        commit("setTaxDataAll", response);
      })
      .catch(error => {
        commit("setNotificationsError", error);
      });
  },
  async fetchCurrencies({ commit }) {
    await axios
      .get(IP + "settings/currencies")
      .then(response => {
        commit("setCurrencyDataAll", response);
      })
      .catch(error => {
        commit("setNotificationsError", error);
      });
  },
  async fetchTaxInfo({ commit }, data) {
    await axios
        .get(IP + "taxes/details/"+data)
        .then(response => {
          commit("setTaxDetails", response);
        })
        .catch(error => {
          commit("setNotificationsError", error);
        });
  },
  async updateTaxInfo({ commit }, data) {
    await axios
        .post(IP + "taxes/update", data)
        .then(response => {
          commit("setUpdateTaxData", response);
        })
        .catch(error => {
          commit("setNewTaxError", error);
        });
  },
  async deleteTaxInfo({ commit }, data) {
    await axios
        .post(IP + "taxes/delete/"+data)
        .then(response => {
          commit("setDeleteTaxData", response);
        })
        .catch(error => {
          commit("setNotificationsError", error);
        });
  },
  async saveNewTax({ commit }, data) {
    await axios
        .post(IP + "taxes/new", data)
        .then(response => {
          commit("setNewTaxData", response);
        })
        .catch(error => {
          commit("setNewTaxError", error);
        });
  },

};

const mutations = {
  setNewTaxData: (state, response) => {
    state.newTaxData = response.data;
  },
  setTaxDataAll: (state, response) => {
    state.taxDataAll = response.data;
  },
  setCurrencyDataAll: (state, response) => {
    state.currencyDataAll = response.data;
  },
    setTaxDetails: (state, response) => {
    state.taxDetails = response.data;
  },
    setUpdateTaxData: (state, response) => {
    state.updateTaxData = response.data;
  },
    setDeleteTaxData: (state, response) => {
    state.deleteTaxData = response.data;
  },
/*  setNotificationsError: (state, error) => {
    state.notificationError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  },*/

};

export default {
  state,
  getters,
  actions,
  mutations
};
