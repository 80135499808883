<template>
<span>
  <v-card flat>
                <v-row class="mx-auto mt-8">
                  <v-col cols="8"> </v-col>

                  <v-col cols="4">
                    <v-text-field
                            class="mr-3"
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            dense
                            outlined
                            hide-details
                            v-on:keyup.enter="doSearch"
                    ></v-text-field>
                  </v-col>
                </v-row>


                <v-data-table
                        :headers="customerPaymentHeaders"
                        :items="customerPaymentsList"
                        :search="search"
                        :loading="tableCustomerPaymentLoader"
                        loading-text="Loading... please wait"
                        item-key="name"
                        class="elevation-1"
                        :page="page"
                        :items-per-page="items_per_page"
                        :server-items-length="server_items_length"
                        :options.sync="customerPaymentPagination"
                >

                  <template #item.invoice_counter="{ item }">
                    <router-link
                            class="refNumber"
                            :to="{
                        name: 'invoice-details',
                        params: { type: item.invoice_counter },
                        query: { id: item.invoice_number }
                      }"
                    >
                      {{ item.invoice_counter }}
                    </router-link>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editPayment(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="confirmDeletePayment(item.transaction_number)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>


              </v-card>

     <v-dialog v-model="editInvoicePaymentDialog" width="40%">
            <v-card>
                <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
                    <v-row>
                        <v-card-title>Edit Payment Details</v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="editInvoicePaymentDialog = false">
                            <v-icon color="red">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-row>
                    <pay-invoice-bill
                            ref="editPaymentComponents"
                            receiptType="Invoice"
                    ></pay-invoice-bill>
                </div>
            </v-card>
        </v-dialog>

<ConfirmDialog ref="deleteInvoicePaymentTransaction"></ConfirmDialog>
</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PayInvoiceBill from "@/modules/dashboard/accounting/commons/PayInvoiceBill";
import ConfirmDialog from "@/modules/commons/ConfirmDialog.vue";

export default {
    name: "CustomerPayoutTable",
    components: {PayInvoiceBill, ConfirmDialog},
    data() {
        return {
            customerPaymentHeaders: [
                {
                    width: "5%",
                    text: "#",
                    align: "start",
                    value: "key"
                },
                {text: "Invoice Number", value: "invoice_counter"},
                {text: "Amount", value: "amount"},
                {text: "Notes", value: "notes"},
                {text: "Payment Method", value: "payment_method_name"},
                {text: "Bank/Cash Account", value: "bank"},
                {text: "Payment Date", value: "payment_date"},
                {text: "Action", value: "actions"}
            ],
            search: null,
            tableCustomerPaymentLoader: false,
            items_per_page: 10,
            page: 1,
            server_items_length: 0,
            customerPaymentPagination: {},
            customerPaymentsList: [],
            editInvoicePaymentDialog: false,
        };
    },
    methods: {
        ...mapActions(
            [
                "doGetCustomerPayments",
                "fetchInvoicePaymentDetails",
                "deleteInvoicePayment"
            ]
        ),
        filterCustomerPaymentListData(raw_data) {
            let paymentDataList = raw_data.data;
            this.server_items_length = raw_data.total;
            this.tableCustomerPaymentLoader = false;

            let current_page = raw_data.current_page;
            this.page = current_page;
            let per_page = raw_data.per_page;
            this.items_per_page = per_page * 1;

            let paymentJsn = {};
            let paymentObj = [];

            paymentDataList.forEach((lst, i) => {
                paymentJsn = {
                    key: (current_page - 1) * per_page + i + 1,
                    invoice_counter: lst.invoice_counter,
                    amount: lst.amount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    notes: lst.notes,
                    payment_method_name: lst.payment_method_name,
                    payment_date: lst.payment_date,
                    invoice_number: lst.invoice_number,
                    number: lst.number,
                    bank: lst.bank ? lst.bank : null,
                    transaction_number: lst.transaction_number
                };
                paymentObj.push(paymentJsn);
            })

            this.customerPaymentsList = paymentObj;
        },
        editPayment(item) {
            if (item) {
                this.editInvoicePaymentDialog = true
                this.fetchInvoicePaymentDetails(item.number)
            }
        },
        doSearch() {
            this.tableCustomerPaymentLoader = true;
            let options = {
                page: this.page,
                per_page: this.items_per_page,
                search: this.search,
                customer_number: this.$route.query.prof,
            };
            this.doGetCustomerPayments(options);
        },
        async confirmDeletePayment(transaction_number) {

            let title = `Are you sure you want to delete this payment ?  Doing this is not reversible`

            const agreed = await this.$refs.deleteInvoicePaymentTransaction.confirm(
                title,
                {
                    color: "red",
                    width: "480",
                }
            );

            if (!agreed) {
                return
            }
            await this.deleteInvoicePayment({transaction_number});

        },
    },
    computed: {
        ...mapGetters(
            [
                "getCustomerPaymentsData",
                "getInvoicePaymentDetailsEditDetails",
                "getInvoicePaymentDelete",
                "getInvoicePaymentDeleteError"
            ]
        )
    },
    watch: {
        getCustomerPaymentsData(customerPaymentsData) {
            this.filterCustomerPaymentListData(customerPaymentsData);
        },
        customerPaymentPagination(obj) {
            this.tableCustomerPaymentLoader = true;
            let page = obj.page;

            let options = {
                page: page,
                per_page: obj.itemsPerPage,
                customer_number: this.$route.query.prof
            };
            this.doGetCustomerPayments(options);
        },
        getInvoicePaymentDetailsEditDetails(data) {
            if (data) {
                this.$refs.editPaymentComponents.invoiceLoader = false
                this.editInvoicePaymentDialog = false
                this.successToast("Success", data.message)
                this.doSearch()
            }
        },
        getInvoicePaymentDelete(data) {
            this.successToast("Success", data.message)
            this.doSearch()

        },
        getInvoicePaymentDeleteError(error){
            this.errorToast("Error", error.message)
        }
    }
}
</script>

<style scoped>

</style>