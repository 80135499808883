var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mx-auto mt-8"},[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSearch($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.itemHeaders,"items":_vm.itemListDataTable,"search":_vm.search,"loading":_vm.tableItemLoader,"loading-text":"Loading... please wait","item-key":"name","page":_vm.page,"items-per-page":_vm.items_per_page,"server-items-length":_vm.server_items_length,"options":_vm.pagination,"footer-props":{
                    itemsPerPageOptions: _vm.items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Items per page'
                  }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
                  var pagination = ref.pagination;
                  var options = ref.options;
                  var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"Items per page","items-per-page-options":_vm.items_per_page_options,"showFirstLastPage":true,"firstIcon":"mdi-arrow-collapse-left","lastIcon":"mdi-arrow-collapse-right","prevIcon":"mdi-chevron-left","nextIcon":"mdi-chevron-right"},on:{"update:options":updateOptions}})]}},{key:"item.item_type",fn:function(ref){
                  var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.item_type))])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1),_c('v-dialog',{attrs:{"width":"100%"},model:{value:(_vm.itemsDialog),callback:function ($$v) {_vm.itemsDialog=$$v},expression:"itemsDialog"}},[_c('v-card',[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"97%","padding-bottom":"20px"}},[_c('v-row',[_c('v-card-title',[_vm._v("Edit Item")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.itemsDialog = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('add-item',{ref:"edit_item_components",attrs:{"itemsDataList":_vm.itemsDataList}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }