<template>
  <span>
    <form ref="form" @submit.prevent="saveDiscount">
      <v-row class="mx-auto">
        <v-col>
          <v-alert type="error" v-if="discountErrorMessage">
            {{ discountErrorMessage }}
          </v-alert>

          <v-text-field
            v-model="form.name"
            label="Name"
            outlined
            dense
            required
          ></v-text-field>

          <v-text-field
            v-model="form.rate"
            label="Rate (%)"
            outlined
            dense
            maxlength="7"
            required
            @keypress="onlyNumberTaxValidator($event)"
            v-on:blur="computeValues(form.rate)"
            persistent-hint
            hint="between 0 and 100"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="addDiscountLoader"
          color="#0000ff"
          block
          dark
          type="submit"
        >
          {{ editDiscountObj ? "Edit Discount" : "Save Discount" }}
        </v-btn>
      </v-card-actions>
    </form>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddDiscount",
  props: {
    editDiscountObj: {}
  },
  data() {
    return {
      addDiscountLoader: false,
      discountErrorMessage: null,
      form: {
        name: null,
        rate: null,
        discount_number: null
      }
    };
  },
  mounted() {
    if (this.editDiscountObj) {
      this.filterEditDiscountData();
    }
  },
  methods: {
    ...mapActions(["doSaveDiscount"]),
    saveDiscount() {
      this.discountErrorMessage = "";

      let rate = this.form.rate.replace(/%/g, "")

      if (rate < 0 || rate > 100) {
        this.discountErrorMessage = "Discount should be between 1 to 100";
        return false;
      }

      let formData = this.form
      formData.rate = rate

      this.discountErrorMessage = null;
      this.addDiscountLoader = true;
      this.doSaveDiscount(formData);
    },
    computeValues(value) {
      if (value) {
        value = value.replace(/%/g, "");
        if (!isNaN(value)) {
          value = value * 1;
        }

        let result = eval(value);

        if (result > 100) {
          result = 100;
        } else if (result < 0) {
          result = null;
        }

        this.form.rate = result + '%';
      }
    },
    filterEditDiscountData() {
      this.form.name = this.editDiscountObj.name;
      this.form.rate = this.editDiscountObj.rate+ '%';
      this.form.discount_number = this.editDiscountObj.number;
    }
  },
  computed: {
    ...mapGetters(["getDiscountErrorResponse"])
  },
  watch: {
    editDiscountObj() {
      this.filterEditDiscountData();
    },
    getDiscountErrorResponse(discountError) {
      if (discountError) {
        this.addDiscountLoader = false;
        this.discountErrorMessage = discountError.message;
        this.errorToast("Error", discountError.message);
      }
    }
  }
};
</script>

<style scoped></style>
