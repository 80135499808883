<template>
<span>
   <v-alert type="error" v-if="error">
      {{ error }}
    </v-alert>

    <form ref="form" @submit.prevent="saveTransfer">
      <v-row>
        <v-col cols="6">

          <v-text-field
                  v-model="form.name"
                  label="Name"
                  outlined
                  dense
                  required
          ></v-text-field>

          <v-text-field
                  v-model="form.amount"
                  label="Amount to transfer"
                  outlined
                  dense
                  required
                  @keypress="onlyNumberValidator($event)"
                  v-on:blur="compute(form.amount)"
          ></v-text-field>

          <v-textarea
                  v-model="form.notes"
                  label="Memo"
                  outlined
                  rows="2"
                  dense
          ></v-textarea>

          <v-text-field
                  v-model="form.date"
                  label="Date [ dd / mm / yyy ]"
                  outlined
                  dense
                  type="date"
                  required
                  hide-details
          ></v-text-field>
           <small style="color: red;" v-if="yearLengthValidator(form.date)">
                Invalid Date
              </small>
        </v-col>

        <v-col cols="6">


          <v-autocomplete
                  outlined
                  v-model="form.source_account_number"
                  :items="storageAccountDataList"
                  item-value="number"
                  item-text="name"
                  color="pink"
                  label="From "
                  append-outer-icon="mdi-plus"
                  @click:append-outer="showBankModal"
                  dense
          ></v-autocomplete>

             <v-autocomplete
                     outlined
                     v-model="form.destination_account_number"
                     :items="storageAccountDataList"
                     item-value="number"
                     item-text="name"
                     color="pink"
                     label="Destination Account *"
                     append-outer-icon="mdi-plus"
                     @click:append-outer="showBankModal"
                     dense
             ></v-autocomplete>

          <v-autocomplete
                  outlined
                  v-model="form.payment_method_number"
                  :items="paymentMethodDataList"
                  item-value="number"
                  item-text="name"
                  color="pink"
                  label="Payment Method"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="showPaymentModal"
                  dense
          ></v-autocomplete>


              <v-autocomplete
                      outlined
                      v-model="form.sale_user_number"
                      :items="staffList"
                      item-value="id"
                      color="pink"
                      label="Transferred by"
                      append-outer-icon="mdi-plus"
                      @click:append-outer="addNewStaffProfileData"
                      dense
                      clearable
              ></v-autocomplete>

             <v-text-field
                     v-model="form.reference"
                     label="Reference"
                     outlined
                     dense
             ></v-text-field>
        </v-col>
      </v-row>

      <div class="text-right">
        <v-btn
                @click="saveTransfer"
                class="companyButton"
                :loading="transferLoader"
                color="#0000ff"
                dark
                type="submit"
        >
          Transfer
        </v-btn>
      </div>
    </form>

    <!--    adds Storage accounts -->

    <v-dialog v-model="storage_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="storage_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <storage-account ref="storageAccountComponent"></storage-account>
        </div>
      </v-card>
    </v-dialog>

    <!--                    payment methods-->

    <v-dialog v-model="payment_methods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Payment Methods</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payment_methods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <payment-methods ref="paymentMethodsComponents"></payment-methods>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog v-model="new_staff_dialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Staff</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="new_staff_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-user ref="users_components"></new-user>
        </div>
      </v-card>
    </v-dialog>

</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import StorageAccount from "@/modules/dashboard/accounting/commons/StorageAccount";
import PaymentMethods from "@/modules/dashboard/accounting/commons/PaymentMethods";
import NewUser from "@/modules/dashboard/Settings/Users/NewUser";
export default {
    name: "NewTransfer",
    components:{
        StorageAccount,
        PaymentMethods,
        NewUser
    },
    data() {
        return {
            error: null,
            transferLoader: false,
            form: {
                source_account_number:null,
                destination_account_number:null,
                sale_user_number: null,
                name:"",
                amount:null,
                date:null,
                payment_method_number:null

            },
            storageAccountDataList:[],
            paymentMethodDataList:[],
            storage_account_dialog: false,
            payment_methods_dialog: false,
            showPaymentModal : false,
            showBankModal : false,
            new_staff_dialog: false,
            staffList: []
        }
    },

    mounted() {
        this.doGetStorageAccountData();
        this.doGetPaymentMethodData();
        this.fetchStaffProfiles();

    },

    methods: {
        ...mapActions(["doTransfer","doGetStorageAccountData","doGetPaymentMethodData","fetchStaffProfiles"]),
        saveTransfer() {
            this.transferLoader = true
            this.doTransfer(this.form)
        },
        compute(value) {
            this.form.amount = eval(value);
        },
        addNewStaffProfileData() {
            this.new_staff_dialog = true;
        },
    },
    computed :{
        ...mapGetters([
            "getPaymentMethodDataSet",
            "getStorageAccountDataSet",
            "getStaffProfileDataList",
            "getTransferSuccess",
            "getTransferError"

        ])
    },
    watch:{
        getPaymentMethodDataSet(data){
            this.paymentMethodDataList = data
        } ,
        getStorageAccountDataSet(data){
            this.storageAccountDataList = data

        },
        getStaffProfileDataList(staffDataList) {
            this.staffList = this.filterStaffProfilesList(staffDataList);
        },

        getTransferSuccess(){
            this.transferLoader = false
            this.successToast()
        },

        getTransferError(){
            this.transferLoader = false
            this.errorToast()
        }
    }

}
</script>

<style scoped>

</style>