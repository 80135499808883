<template>
  <v-container fluid>
    <v-card tile class="mb-3">
      <v-row class="mx-auto">
        <v-col cols="2">
          <v-menu
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mr-1"
                v-model="start_date"
                dense
                readonly
                v-bind="attrs"
                v-on="on"
                hint="Start Date [ YYY-MM-DD ]"
                persistent-hint
                clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="start_date" no-title></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2">
          <v-menu
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mr-1"
                v-model="end_date"
                dense
                readonly
                hint="End Date [ YYY-MM-DD ]"
                persistent-hint
                v-bind="attrs"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="end_date" no-title></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2">
          <v-menu
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            origin="center center"
            transition="fab-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="border: 1px solid #9e9e9e; height: 40px; color: #777777;"
                light
                v-bind="attrs"
                v-on="on"
                tile
              >
                <v-icon class="mr-4">
                  mdi mdi-filter
                </v-icon>
                Filter
                <v-icon>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list nav dense>
                <v-list-item-group color="primary">
                  <v-span v-for="(item, i) in filterList" :key="i">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="(i + 1) % 3 === 0"></v-divider>
                  </v-span>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>

    <v-card tile>
      <v-row class="mx-auto">
        <v-col>

          <v-row class="mx-auto">
            <v-card-title>Statement Of Cash FLows</v-card-title>
            <v-spacer></v-spacer>

            <span>
              <v-btn
                  class="pa-2"
                  outlined
                  color="#242f7e"
                  small
                  @click="fileExport"
              >
                <v-icon left>
                  mdi-file-pdf-box
                </v-icon>
                Export PDF
              </v-btn>
            </span>

          </v-row>



          <v-card-text>
           Company: {{ company_name }} <br />
<!--            For the month ended 28 February 2021-->
          </v-card-text>

          <v-row class="mx-auto">
            <v-spacer></v-spacer>
            <p>
<!--              <v-card-text class="text&#45;&#45;disabled">Feb 2021</v-card-text>-->
            </p>
          </v-row>

          <v-divider></v-divider>

          <p class="mt-5"><b>Operating Activities</b></p>
          <v-divider></v-divider>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
              <tr v-for="(income, index) in p_and_l_info.sales" :key="index">
                <td>
                  <span class="ml-3">{{ income.name }}</span>
                </td>
                <td style="text-align: right">
                  {{
                    transformNumbersToAccountingStandard(income.total_worth)
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  <span class="ml-3"><b>Net cash Flows and from Operating Activities</b></span>
                </td>
                <td style="text-align: right">
                  <b>{{
                      transformNumbersToAccountingStandard(p_and_l_info.total_trading_income)
                    }}</b>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>



          <p class="mt-5"><b>Investing Activities</b></p>
          <v-divider></v-divider>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
              <tr v-for="(income, index) in p_and_l_info.sales" :key="index">
                <td>
                  <span class="ml-3">{{ income.name }}</span>
                </td>
                <td style="text-align: right">
                  {{
                    transformNumbersToAccountingStandard(income.total_worth)
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  <span class="ml-3"><b>Net cash Flows and from Investment Activities</b></span>
                </td>
                <td style="text-align: right">
                  <b>{{
                      transformNumbersToAccountingStandard(p_and_l_info.total_trading_income)
                    }}</b>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>


          <p class="mt-5"><b>Financial Activities</b></p>
          <v-divider></v-divider>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr v-for="(income, index) in p_and_l_info.sales" :key="index">
                  <td>
                    <span class="ml-3">{{ income.name }}</span>
                  </td>
                  <td style="text-align: right">
                    {{
                      transformNumbersToAccountingStandard(income.total_worth)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="ml-3"><b>Net cash Flows and from Financial Activities</b></span>
                  </td>
                  <td style="text-align: right">
                    <b>{{
                        transformNumbersToAccountingStandard(p_and_l_info.total_trading_income)
                    }}</b>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>


          <p class="mt-5"><b>Cash and Cash Equivalents</b></p>
          <v-divider></v-divider>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
              <tr v-for="(income, index) in p_and_l_info.sales" :key="index">
                <td>
                  <span class="ml-3">{{ income.name }}</span>
                </td>
                <td style="text-align: right">
                  {{
                    transformNumbersToAccountingStandard(income.total_worth)
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  <span class="ml-3"><b>Cash and Cash Equivalents at the of Period</b></span>
                </td>
                <td style="text-align: right">
                  <b>{{
                      transformNumbersToAccountingStandard(p_and_l_info.total_trading_income)
                    }}</b>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>





        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import configs from "@/global/configs.json";
export default {
  name: "StatementOfCashFLows",
  data() {
    return {
      company_profile: this.$company,
      company_name: null,
      p_and_l_info: {
        total_income: {},
        total_cog: {},
        gross_profit: {},
        total_expenses: {},
        net_profit: {}
      },
      filterList: [
        { text: "This Month", icon: "mdi-folder" },
        { text: "This Quarter", icon: "mdi-account-multiple" },
        { text: "This Financial Year", icon: "mdi-star" },
        { text: "Last Month", icon: "mdi-history" },
        { text: "Last Quarter", icon: "mdi-check-circle" },
        { text: "Last Financial Year", icon: "mdi-upload" },
        { text: "Year to Date", icon: "mdi-cloud-upload" }
      ],
      start_date: "",
      end_date: "",
      downloadLink:
          configs.SERVER_IP + "reports/export/statement-of-cash-flows?token=",
      // start_date: new Date().toISOString().substr(0, 10),
      // end_date: new Date().toISOString().substr(0, 10),
    };
  },
  mounted() {
    this.company_name = this.company_profile.company_name.toUpperCase();
    let data = {
      start_date: this.start_date,
      end_date: this.end_date
    };
    this.doGetProfitAndLoss(data);
  },
  methods: {
    ...mapActions(["doGetProfitAndLoss"]),
    filterData() {
      // alert(this.start_date + "<=>" + this.end_date);
      let data = {
        start_date: this.start_date,
        end_date: this.end_date
      };
      this.doGetProfitAndLoss(data);
    },
    fileExport() {
      this.downloadSpinner = false;
      let templateData  = localStorage.getItem("token");
      let companyNumber  = JSON.parse(localStorage.getItem("company")).number;

      let fileUrl = this.downloadLink + templateData+"&company-number="+companyNumber;

      const pdfObj = document.createElement("a");
      pdfObj.href = fileUrl;
      pdfObj.click();
    },
  },
  computed: {
    ...mapGetters(["getProfitAndLossData"])
  },
  watch: {
    getProfitAndLossData(pAndLData) {
      this.p_and_l_info = pAndLData;
    },
    start_date() {
      this.filterData();
    },
    end_date() {
      this.filterData();
    }
  }
};
</script>

<style scoped></style>
