import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  depreciationTransactionData: null,
  allDepreciationData: null,
  depreciationTransactionError: null,
  newTransactionDepreciationData: null,
  detailsTransactionDepreciationData: null,
  updateTransactionDepreciationData: null,
  deleteTransactionDepreciationData: null,
};

const getters = {
  getDepreciationTransactionData: state => state.depreciationTransactionData,
  getAllDepreciationData: state => state.allDepreciationData,
  getNewTransactionDepreciationData: state => state.newTransactionDepreciationData,
  getDeleteTransactionDepreciationData: state => state.deleteTransactionDepreciationData,
  getDetailsTransactionDepreciationData: state => state.detailsTransactionDepreciationData,
  getUpdateTransactionDepreciationData: state => state.updateTransactionDepreciationData,
  getDepreciationTransactionError: state => state.depreciationTransactionError
};

const actions = {
  async fetchTransactionDepreciationData({ commit }, data) {
    await axios
      .get(
        IP +
          "depreciation/transactions/all" +
          "?page=" +
          data.page +
          "&per_page=" +
          data.per_page
      )
      .then(response => {
        commit("setDepreciationTransactionData", response.data);
      })
      .catch(error => {
        commit("setDepreciationTransactionError", error);
      });
  },
  async doGetDeprecatedItemsData({ commit }, data) {
    await axios
        .get(IP + "depreciation/assets/all", data)
        .then(response => {
          commit("setAllDepreciationData", response);
        })
        .catch(error => {
          commit("setDepreciationTransactionError", error);
        });
  },
  async addDepreciationTransactionData({ commit }, data) {
    await axios
        .post(IP + "depreciation/transactions/new", data)
        .then(response => {
          commit("setNewTransactionDepreciationData", response);
        })
        .catch(error => {
          commit("setDepreciationTransactionError", error);
        });
  },
  async deleteDepreciationTransactionData({ commit }, data) {
    await axios
        .post(IP + "depreciation/transactions/delete/"+data)
        .then(response => {
          commit("setDeleteTransactionDepreciationData", response);
        })
        .catch(error => {
          commit("setDepreciationTransactionError", error);
        });
  },
  async fetchTransactionsUpdateInfo({ commit }, data) {
    await axios
        .get(IP + "depreciation/transactions/details/"+data)
        .then(response => {
          commit("setDetailsTransactionDepreciationData", response);
        })
        .catch(error => {
          commit("setDepreciationTransactionError", error);
        });
  },
  async doUpdateTransactionsInfo({ commit }, data) {
    await axios
        .post(IP + "depreciation/transactions/update/"+data.number, data.data)
        .then(response => {
          commit("setUpdateTransactionDepreciationData", response);
        })
        .catch(error => {
          commit("setDepreciationTransactionError", error);
        });
  },


};

const mutations = {
  setDepreciationTransactionData: (state, response) => {
    state.depreciationTransactionData = response;
  },
  setAllDepreciationData: (state, response) => {
    state.allDepreciationData = response.data;
  },
  setNewTransactionDepreciationData: (state, response) => {
    state.newTransactionDepreciationData = response.data;
  },
  setDeleteTransactionDepreciationData: (state, response) => {
    state.deleteTransactionDepreciationData = response.data;
  },
  setDetailsTransactionDepreciationData: (state, response) => {
    state.detailsTransactionDepreciationData = response.data;
  },
  setUpdateTransactionDepreciationData: (state, response) => {
    state.updateTransactionDepreciationData = response.data;
  },
  setDepreciationTransactionError: (state, error) => {
    state.depreciationTransactionError = error.response
      ? error.response.data
      : "Error: Network Error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
