<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Items </v-toolbar-title>
            <v-spacer></v-spacer>
            <span>
              <v-btn
                class="ma-2 text-decoration-none"
                outlined
                small
                color="#1b5e20"
                :loading="downloadSpinner"
                @click="downloadTemplate"
              >
                <v-icon left>
                  mdi-import
                </v-icon>
                Template
              </v-btn>

              <v-btn
                class="ma-2"
                outlined
                color="#242f7e"
                small
                @click="fileUploader"
              >
                <v-icon left>
                  mdi-upload
                </v-icon>
                Import
              </v-btn>

              <v-btn
                class="ma-2"
                outlined
                color="#242f7e"
                small
                @click="fileExport"
                :loading="exportSpinner"
              >
                <v-icon left>
                  mdi-download
                </v-icon>
                Export
              </v-btn>
            </span>
          </v-toolbar>

          <v-tabs v-model="activeTab">
            <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
            <v-tab href="#inventory-item">
              <v-icon left>mdi-account</v-icon>
              Inventory Items
            </v-tab>

            <v-tab href="#service-item">
              <v-icon left>mdi-account</v-icon>
              Service Items
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-item">
              <v-icon left>mdi-lock</v-icon>
              New Item
            </v-tab>


            <v-tab-item id="inventory-item">
              <v-divider></v-divider>
              <v-card flat>
                <inventory-item-list></inventory-item-list>
              </v-card>
            </v-tab-item>

            <v-tab-item id="service-item">
            <service-item-list></service-item-list>
            </v-tab-item>

            <v-tab-item  id="new-item">
              <v-divider></v-divider>
              <v-card flat>
                <v-row>
                  <v-col cols="11" class="mt-15 mx-auto">
                    <p style="font-size: 20px;">Add Item</p>
                    <add-item ref="item_components"></add-item>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="itemsDialog" width="100%">
      <v-card>
        <div style="width: 97%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Item</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="itemsDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-item
            ref="edit_item_components"
            :itemsDataList="itemsDataList"
          ></add-item>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Import Chart of Accounts</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="uploadDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <upload-file ref="upload_component" fileType="items"></upload-file>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddItem from "../commons/AddItem";
import configs from "@/global/configs.json";
import UploadFile from "@/modules/dashboard/accounting/UploadFile/UploadFile";
import router from "@/global/router";
import ServiceItemList from "@/modules/dashboard/accounting/Item/ServiceItemList";
import InventoryItemList from "@/modules/dashboard/accounting/Item/InventoryItemList";
export default {
  name: "Item",
  components: {InventoryItemList, ServiceItemList, UploadFile, AddItem },
  data() {
    return {
      activeTab: "item",
      itemListDataTable: [],
      search: null,
      tableItemLoader: false,
      items_per_page: 20,
      page: 1,
      server_items_length: 0,
      itemHeaders: [
        {
          width: "5%",
          text: "#",
          align: "start",
          value: "key"
        },
        { text: "Item Type", value: "item_type" },
        { text: "Name", value: "name" },
        { text: "Code", value: "code" },
        { text: "notes", width: "20%", value: "notes" },
        { text: "Current in stock", width: "20%", value: "remaining_stock" },
        { text: "Stock Value", width: "20%", value: "stock_value" },
        { text: "Selling price", width: "20%", value: "selling_price" },
        { text: "Action", value: "actions", width: "10%", }
      ],
      pagination: {},
      items_per_page_options: [20, 50, 100, 150],
      itemsDialog: false,
      editedIndex: -1,
      editedItems: {
        item_type: null,
        name: null,
        code: null,
        notes: null
      },
      json_fields: {
        ItemType: "item_type",
        Name: "name",
        Code: "code",
        notes: "notes"
      },
      itemsDataList: {},
      uploadDialog: false,
      downloadLink: configs.SERVER_IP + "template?type=items&file_token=",
      exportLink: configs.SERVER_IP + "export?type=items&file_token=",
      downloadSpinner: false,
      exportSpinner: false,
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  methods: {
    ...mapActions([
      "doGetItemsListData",
      "downloadTemplateFile",
      "exportFile",
      "searchItem",
      "deleteItemInfo"
    ]),
    filterItemListData(raw_data) {
      //data

      let itemListData = raw_data.data;
      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;
      this.server_items_length = raw_data.total;
      this.tableItemLoader = false;

      let itemListJsn = {};
      let itemListObj = [];

      let type = "";

      itemListData.forEach((list, i) => {
        let _type = "";

        if (type !== list.type) {
          type = list.type;
          _type = type;
        } else {
          _type = "";
        }

        itemListJsn = {
          key: (current_page - 1) * per_page + i + 1,
          item_type: _type,
          name: list.full_name ? list.full_name : "",
          code: list.code ? list.code : "",
          notes: list.notes ? list.notes : "",
          number: list.number ? list.number : "",
          selling_price: list.selling_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          stock_value: list.stock_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          remaining_stock: list.remaining_stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        };
        itemListObj.push(itemListJsn);
      })

      this.itemListDataTable = itemListObj;
    },
    editItem(item) {
      this.itemsDataList = item;
      this.itemsDialog = true;
    },
    deleteItem(item) {
      let confirm_delete = confirm(
        "Are you sure you want to delete this item."
      );
      if (!confirm_delete) {
        return false;
      }
      this.deleteItemInfo(item.number)
      // alert(JSON.stringify(item.number));
    },
    editItemData() {
      alert(JSON.stringify(this.editedItems));
    },
    fileUploader() {
      this.uploadDialog = true;
    },
    downloadTemplate() {
      this.downloadSpinner = true;
      this.downloadTemplateFile("items");
    },
    fileExport() {
      this.exportSpinner = true;
      this.exportFile("items");
    },

  },
  computed: {
    ...mapGetters([
      "getItemListData",
      "getItemData",
      "getUploadData",
      "getTemplateDownloadData",
      "getExportFileData",
       "getDeleteItemsData",
       "getDeleteItemsDataError"
    ]),

    params() {
      return {
        ...this.pagination,
        query: this.search
      };
    }
  },
  watch: {
    getItemListData(itemListData) {
      this.filterItemListData(itemListData);
    },
    getItemData(itemData) {
      if (itemData) {
        this.$refs["item_components"].addItemLoader = false;
        this.successToast("Success", itemData.message);
        let options = {
          company_number: this.$company.number,
          page: this.page,
          per_page: this.items_per_page
        };

        this.doGetItemsListData(options);
      }
    },
    pagination(obj) {
      this.tableItemLoader = true;
      let page = obj.page;

      let options = {
        company_number: this.$company.number,
        page: page,
        per_page: obj.itemsPerPage
      };
      this.doGetItemsListData(options);
    },
    getUploadData(uploadChartOfAccount) {
      if (uploadChartOfAccount) {
        this.uploadDialog = false;
        this.$refs["upload_component"].spinner = false;
        this.$refs["upload_component"].form = {
          attachment: null
        };
        this.successToast("Success", uploadChartOfAccount.message);
      }
    },
    getTemplateDownloadData(templateData) {
      if (templateData) {
        this.downloadSpinner = false;

        let fileUrl = this.downloadLink + templateData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },
    getExportFileData(exportData) {
      if (exportData) {
        this.exportSpinner = false;
        let fileUrl = this.exportLink + exportData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },
    activeTab(data) {
      router.push({ name: "item-list", query: { type: data } });
    },
   /* getUpdateItemData(data){
      if(data){
        this.itemsDialog  = false
        this.successToast("Success", data.message)
        this.doSearch()
      }
    },*/
    getDeleteItemsData(data){
      if(data){
        this.successToast("Success", data.message)
        this.doSearch()
      }
    },
    getDeleteItemsDataError(data){
      if(data){
        this.errorToast("Error", data.message)
      }
    }
  }
};
</script>

<style scoped></style>
