import axios from "axios";
import configs from "../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
    analyticsData: null,
    analyticsDataError: null,
};

const getters = {
    getAnalyticsData: state => state.analyticsData,
    getAnalyticsDataError: state => state.analyticsDataError,
};

const actions = {

    async fetchStatisticalData({ commit }, data) {
        await axios
            .get(IP + "reports/home-analytics", data)
            .then(response => {
                commit("setAnalyticsData", response);
            })
            .catch(error => {
                commit("setAnalyticsError", error);
            });
    },
};

const mutations = {

    setAnalyticsData: (state, response) => {
        state.analyticsData = response.data;
    },
    setAnalyticsError: (state, error) => {
        state.analyticsDataError = error.response
            ? error.response.data
            : "Error: Network Error"; //error.response.data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
