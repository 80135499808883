<template>
    <v-container fluid>

        <v-card tile>
          <v-row class="mx-auto">
            <v-col>

              <v-row class="mx-auto">
                <div>
                <v-card-title>{{ cashFlow ? cashFlow : "Bank Statement" }}</v-card-title>
                <v-card-text>Company: {{ company_name }}</v-card-text>
                </div>
                <v-spacer></v-spacer>

                <span>
              <v-btn
                  class="ma-2"
                  outlined
                  color="#242f7e"
                  small
                  @click="fileExport"
              >
                <v-icon left>
                 mdi-file-pdf-box
                </v-icon>
                Export PDF
              </v-btn>
            </span>

              </v-row>

            </v-col>
          </v-row>




            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="2">
                    <v-menu
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    class="mr-1"
                                    v-model="start_date"
                                    dense
                                    readonly
                                    v-bind="attrs"
                                    :placeholder="new Date().toISOString().substr(0, 10)"
                                    v-on="on"
                                    hint="Start Date [ YYY-MM-DD ]"
                                    persistent-hint
                                    clearable
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="start_date" no-title></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="2">
                    <v-menu
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    class="mr-1"
                                    v-model="end_date"
                                    dense
                                    readonly
                                    hint="End Date [ YYY-MM-DD ]"
                                    persistent-hint
                                    v-bind="attrs"
                                    clearable
                                    v-on="on"
                                    :placeholder="new Date().toISOString().substr(0, 10)"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="end_date" no-title></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="2">
                    <v-menu
                            :close-on-content-click="false"
                            :nudge-width="200"
                            offset-y
                            origin="center center"
                            transition="fab-transition"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    style="border: 1px solid #9e9e9e; height: 40px; color: #777777;"
                                    light
                                    v-bind="attrs"
                                    v-on="on"
                                    tile
                            >
                                <v-icon class="mr-4">
                                    mdi mdi-filter
                                </v-icon>
                                Filter
                                <v-icon>
                                    mdi-menu-down
                                </v-icon>
                            </v-btn>
                        </template>

                        <v-card>
                            <v-list nav dense>
                                <v-list-item-group color="primary">
                                    <v-span v-for="(item, i) in filterList" :key="i">
                                        <v-list-item @click="filters(item.id)">
                                            <v-list-item-content>
                                                <v-list-item-title
                                                        v-text="item.text"
                                                ></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider v-if="(i + 1) % 3 === 0"></v-divider>
                                    </v-span>
                                </v-list-item-group>
                            </v-list>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>

            <v-data-table
                    :headers="headers"
                    :items="bankStatementInfo"
                    :search="search"
                    :loading="tableBankStatementLoader"
                    loading-text="Loading... please wait"
                    item-key="name"
                    class="elevation-1"
                    :page="page"
                    :items-per-page="items_per_page"
                    :server-items-length="server_items_length"
                    :options.sync="paginationBankStatement"
                    :footer-props="{
                      'itemsPerPageOptions': items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text':'Entries Per Page'
                    }"
            >
                <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            items-per-page-text="Entries Per Page"
                            :items-per-page-options="items_per_page_options"
                            :showFirstLastPage="true"
                            firstIcon="mdi-arrow-collapse-left"
                            lastIcon="mdi-arrow-collapse-right"
                            prevIcon="mdi-chevron-left"
                            nextIcon="mdi-chevron-right"
                    />
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import configs from "@/global/configs.json";

export default {
    name: "BankStatement",
    props: {
        cashFlow: String
    },
    data() {
        return {
            account_number: this.$route.query.id,
            company_profile: this.$company,
            company_name: null,
            bankStatementInfo: [],
            search: null,
            storageInfo: {},
            items_per_page_options: [20, 50, 100, 500],
            filterList: [
                {text: "This Month", id: "this-month", icon: "mdi-folder"},
                {text: "This Quarter", id: "this-quarter", icon: "mdi-account-multiple"},
                {text: "This Financial Year", id: "this-financial-year", icon: "mdi-star"},
                {text: "Last Month", id: "last-month", icon: "mdi-history"},
                {text: "Last Quarter", id: "last-quarter", icon: "mdi-check-circle"},
                {text: "Last Financial Year", id: "last-financial-year", icon: "mdi-upload"},
                {text: "Year to Date", id: "year-to-date", icon: "mdi-cloud-upload"}
            ],
            start_date: "",
            end_date: "",
            headers: [
                {
                    text: "#",
                    align: "start",
                    width: "5%",
                    value: "key"
                },
                {text: "Date", value: "date"},
                {text: "Name", value: "name"},
                {text: "Payment Mode", value: "payment_method_name"},
                {text: "Bank", value: "account"},
                {text: "Amount", value: "amount"},
                {text: "Debit", value: "debt"},
                {text: "Credit", value: "credit"},
                {text: "Balance", value: "balance"},

            ],
            page: 1,
            items_per_page: 20,
            server_items_length: 0,
            paginationBankStatement: {},
            tableBankStatementLoader: false,
            downloadLink:
              configs.SERVER_IP + "reports/export/cash-flow?token=",
        };
    },
    mounted() {
        this.account_number = this.$route.query.id;
        this.company_name = this.company_profile.company_name.toUpperCase();

        if (this.account_number) {
            this.doGetStorageAccount(this.account_number);
        }

    },
    methods: {
        ...mapActions(["doGeBankStatement", "doGetStorageAccount"]),
        filterBankStatement() {
            alert(JSON.stringify(this.form));
        },
        editBankStatement(item) {
            alert(JSON.stringify(item));
        },
        deleteBankStatement(item) {
            alert(JSON.stringify(item));
        },
        tableFilterBankStatement(raw_data) {
            let bankStatementDataList = raw_data.data;
            this.server_items_length = raw_data.total;
            this.tableBankStatementLoader = false;
            let current_page = raw_data.current_page;
            this.page = current_page;
            let per_page = raw_data.per_page;
            this.items_per_page = per_page * 1;
            let bankStatementJsn = {};
            let bankStatementObj = [];
            for (let i = 0; i < bankStatementDataList.length; i++) {
                bankStatementJsn = {
                    key: (current_page - 1) * per_page + i + 1,
                    name: bankStatementDataList[i].name,
                    amount: this.transformNumbersToAccountingStandard(bankStatementDataList[i].amount),
                    debt: isNaN(bankStatementDataList[i].debit) ? "-" : this.transformNumbersToAccountingStandard(bankStatementDataList[i].debit),
                    credit: isNaN(bankStatementDataList[i].credit) ? "-" : this.transformNumbersToAccountingStandard(bankStatementDataList[i].credit),
                    balance: isNaN(bankStatementDataList[i].balance) ? "-" : this.transformNumbersToAccountingStandard(bankStatementDataList[i].balance),
                    date: bankStatementDataList[i].date ? bankStatementDataList[i].date : "",
                    payment_method_name: bankStatementDataList[i].payment_method,
                    account: bankStatementDataList[i].bank
                };
                bankStatementObj.push(bankStatementJsn);
            }
            this.bankStatementInfo = bankStatementObj;
        },
        filters(id) {
            this.filterData(id)
        },
        filterData(dataFilter) {
            this.tableBankStatementLoader = true;
            let options = {
                storage_number: this.account_number,
                page: this.page,
                per_page: this.items_per_page,
                start_date: this.start_date,
                end_date: this.end_date,
                data_filter: dataFilter,
            };
            this.doGeBankStatement(options);
        },
          fileExport() {
            this.downloadSpinner = false;
            let templateData  = localStorage.getItem("token");
            let companyNumber  = JSON.parse(localStorage.getItem("company")).number;

            let fileUrl = this.downloadLink + templateData+"&company-number="+companyNumber;

            const pdfObj = document.createElement("a");
            pdfObj.href = fileUrl;
            pdfObj.click();
          },
    },
    computed: {
        ...mapGetters(["getBankStatementData", "getStorageDetails"])
    },
    watch: {
        getBankStatementData(bankData) {
            this.tableFilterBankStatement(bankData);
        },
        paginationBankStatement(obj) {
            this.tableBankStatementLoader = true;
            let options = {
                storage_number: this.account_number,
                page: obj.page,
                per_page: obj.itemsPerPage,
                start_date: this.start_date,
                end_date: this.end_date,
            };
            this.doGeBankStatement(options);
        },
        getStorageDetails(storageInfo) {
            this.storageInfo = storageInfo;
        },
        start_date() {
            this.filterData();
        },
        end_date() {
            this.filterData();
        }
    }
};
</script>

<style scoped></style>
