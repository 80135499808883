<template>
  <v-row>

    <v-col cols="10" class="mx-auto">
      <v-card flat class="mx-auto">
        <v-card-text>
          <v-card-title class="justify-center"><b>BASE</b></v-card-title>

          <v-card-subtitle class="text-center">
            Please login to your account.
          </v-card-subtitle>

          <v-form ref="form" @submit.prevent="doLogin" lazy-validation>
            <v-alert type="error" v-if="formLoginErrorMessage">
              {{ formLoginErrorMessage }}
            </v-alert>
            <v-card-text>
              <v-text-field
                outlined
                v-model="form.email"
                :rules="[rules.email_required, rules.email_pattern]"
                label="Email"
                required
                prepend-inner-icon="mdi-email-outline"
                type="email"
                dense
              ></v-text-field>

              <v-text-field
                outlined
                v-model="form.password"
                :rules="[rules.password_required]"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                label="Password"
                required
                @click:append="show1 = !show1"
                dense
                prepend-inner-icon="mdi-lock-outline"
              >
              </v-text-field>

              <v-row class="mx-auto">
                <v-checkbox
                  v-model="form.rememberMe"
                  class="shrink mr-0 mt-0 float-left"
                  hide-details
                  label="Remember Me?"
                  required
                  small
                ></v-checkbox>

                <v-spacer></v-spacer>

                <router-link
                  :to="{ name: 'request-reset-password' }"
                  class="float-right text-decoration-none"
                >Forgot Password?</router-link
                >
              </v-row>

              <v-btn
                class="mx-auto loginButton"
                block
                large
                :loading="loginSpinner"
                color="#0000ff"
                dark
                type="submit"
              >
                Login
              </v-btn>

              <div style="text-align: center;">
                Do not have an account? &nbsp; &nbsp;
                <router-link
                  class="text-decoration-none"
                  :to="{ name: 'register' }"
                >
                  Get an account
                </router-link>
              </div>
            </v-card-text>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      show1: false,
      formLoginErrorMessage: null,
      form: {
        email: null,
        password: null,
        rememberMe: true
      },
      loginSpinner: false,
      rules: {
        password_required: value => !!value || "Password is Required.",
        email_required: value => !!value || "Email is Required.",
        email_pattern: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Please enter valid email.";
        }
      }
    };
  },
  mounted() {
    /*if(localStorage.getItem("token")){
      localStorage.clear();
    }*/

  },
  methods: {
    ...mapActions(
        [
            "loginUser",
            "getUserProfile",
            "doGetCompanyProfile"
          ]),
    doLogin() {
      this.loginSpinner = true;
      this.formLoginErrorMessage = "";
      if (this.form.email == null || this.form.email === "") {
        this.formLoginErrorMessage = "Email is Required";
        return false;
      }
      if (this.form.password == null || this.form.password === "") {
        this.formLoginErrorMessage = "Password is Required";
        return false;
      }
      this.loginUser(this.form);
    }
  },
  computed: {
    ...mapGetters([
      "getUserProfileData",
      "getLoginMessage",
      "getLoginToken",
      "getCompanyData"
    ])
  },
  watch: {
    getUserProfileData(loginData) {
      if (loginData !== "" || loginData !== null) {
        let company_number = loginData.company_number;
        if (company_number == null || company_number === "") {
          let r = this.$router.resolve({
            name: "onboard_company"
          });
          window.location.assign(r.href);
        } else {
          this.doGetCompanyProfile(loginData.company_number);
        }
      }
    },
    getLoginMessage(error) {
      //alert(error)
      this.formLoginErrorMessage = error.message;
      this.loginSpinner = false;
    },
    getLoginToken(tokenResponse) {
      if (tokenResponse) {
        this.getUserProfile(tokenResponse.access_token);
      }
    },
    getCompanyData(company) {
      if (company != null) {
        let r = this.$router.resolve({
          name: "chart-of-accounts"
        });
        window.location.assign(r.href);
      }
    }
  }
};
</script>

<style scoped>
.loginButton {
  margin-top: 4%;
  margin-bottom: 4%;
  height: 10px;
  font-size: 16px;
  font-weight: bold;
  background: #065FDB;
}
</style>
