<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Payouts </v-toolbar-title>
          </v-toolbar>

          <v-tabs v-model="activeTab">
            <v-tab href="#Payouts">
              <v-icon left>mdi-account</v-icon>
              All Payouts
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-payments">
              <v-icon left>mdi-lock</v-icon>
              New Payment
            </v-tab>

            <v-tab-item id="Payouts">
              <v-divider></v-divider>

              <payout-table></payout-table>

            </v-tab-item>

            <v-tab-item id="new-payments">
              <v-divider></v-divider>
              <v-card flat>
                <v-row>
                  <v-col cols="5" class="mt-15 ml-6">
                    <new-supplier-payouts></new-supplier-payouts>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from "../../../../global/router";
import NewSupplierPayouts from "@/modules/dashboard/accounting/SupplierPayouts/NewSupplierPayouts";
import PayoutTable from "@/modules/dashboard/accounting/SupplierPayouts/PayoutTable";

export default {
  name: "SupplierPayouts",
  components: {PayoutTable, NewSupplierPayouts },
  data() {
    return {
      activeTab: "Payouts",
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },

  watch: {
    activeTab(data) {
      router.push({ name: "supplier-payouts", query: { type: data } });
    }
  }
};
</script>

<style scoped>
.refNumber {
  text-decoration: none;
}

.refNumber:hover {
  color: green;
  text-decoration: underline;
}
</style>
