<template>
  <span>
    <v-card flat>



      <v-row class="mt-10">

         <v-col cols="3">
            <v-select
                class="ml-4"
                v-model="status"
                :items="statusItems"
                item-value="id"
                color="pink"
                dense
                hint="Status"
                persistent-hint
                v-on:change="filterData"
            ></v-select>
         </v-col>

        <v-col cols="3">
            <v-select
                class="ml-4"
                v-model="profile_number"
                :items="supplierDataList"
                item-value="id"
                color="pink"
                clearable
                dense
                hint="Supplier"
                persistent-hint
                v-on:change="filterData"
            ></v-select>
         </v-col>


         <v-col cols="2">
          <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  class="mr-1"
                  v-model="start_date"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hint="Start Date [ YYY-MM-DD ]"
                  persistent-hint
                  clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="start_date" no-title></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2">
          <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  class="mr-1"
                  v-model="end_date"
                  dense
                  readonly
                  hint="End Date [ YYY-MM-DD ]"
                  persistent-hint
                  v-bind="attrs"
                  clearable
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="end_date" no-title></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>

        <v-col cols="9"></v-col>
        <v-col cols="3" class="ml-auto">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              dense
              outlined
              hide-details
              v-on:keyup.enter="filterData"
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>

      <v-data-table
        :headers="billHeaders"
        :items="billListData"
        :search="search"
        :loading="tableBillLoader"
        loading-text="Loading... please wait"
        :page="page"
        :items-per-page="items_per_page"
        :server-items-length="server_items_length"
        :options.sync="paginationBill"
      >
        <!--                  add a link on the an bill ref code-->
        <template #item.counter="{ item }">
          <router-link
            class="refNumber"
            :to="{
              name: 'bill-details',
              params: { type: item.counter },
              query: { id: item.number }
            }"
          >
            {{ item.counter }}
          </router-link>
        </template>

        <!--                status-->

        <template #item.status="{ item }">
          <v-chip class="ma-2" :color="getColor(item.status)" small outlined>
            {{ item.status }}<br />
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="item.status !== 'Fully paid' && item.status !== 'cancelled'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="makePayment(item)"
              >
                mdi-cash
              </v-icon>
            </template>
            <span>Pay Bill</span>
          </v-tooltip>

          <!--                  <v-icon small class="mr-2" @click="editBill(item)">
          mdi-pencil
        </v-icon>-->

            <v-tooltip v-if="item.status !== 'cancelled'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="17" v-bind="attrs" v-on="on" @click="editBill(item)">
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit Invoice</span>
          </v-tooltip>


          <v-tooltip v-if="item.status !== 'cancelled'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click="cancelBill(item)">
                mdi-close
              </v-icon>
            </template>
            <span>Cancel Bill</span>
          </v-tooltip>


           <v-progress-circular
               class="ml-2"
               v-if="activeIndex === item.number"
               width="1"
               size="16"
               color="#242f7e"
               indeterminate
           ></v-progress-circular>


        </template>
      </v-data-table>
    </v-card>

    <!--      adds bill-->
    <v-dialog v-model="payBillDialog" width="40%">
      <v-card>
        <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Pay Bill</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payBillDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <pay-invoice-bill
            ref="paymentComponents"
            :invoiceInfo="billDataInfo"
            receiptType="Bill"
          ></pay-invoice-bill>
        </div>
      </v-card>
    </v-dialog>


    <!--edit invoice dialog-->
    <v-dialog v-model="editBillDialog" width="90%">
      <v-card>
        <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Bill</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editBillDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <edit-bill :editInfo="'editBill'" ref="edit_bill_items"></edit-bill>
        </div>
      </v-card>
    </v-dialog>


  </span>
</template>

<script>
import PayInvoiceBill from "@/modules/dashboard/accounting/commons/PayInvoiceBill";
import { mapActions, mapGetters } from "vuex";
import router from "@/global/router";
import EditBill from "@/modules/dashboard/accounting/Bills/EditBill";
export default {
  name: "BillTable",
  components: {EditBill, PayInvoiceBill },
  data() {
    return {
      activeTab: "bill",
      search: "",
      billListData: [],
      tableBillLoader: false,
      items_per_page: 10,
      page: 1,
      server_items_length: 0,
      paginationBill: {},
      profile_number: null,
      bill_number: null,
      supplierDataList: [],
      billHeaders: [
        {
          width: "5%",
          text: "#",
          align: "start",
          value: "key"
        },
        { text: "No", value: "counter" },
        { text: "Reference", value: "reference" },
        { text: "Supplier Name", value: "supplier_name" },
        { text: "Bill Date", value: "invoice_date" },
        { text: "Due Date", value: "due_date" },
        { text: "Amount", value: "amount", align: "right" },
        { text: "Due Amount", value: "due_amount", align: "right" },
        { text: "Status", value: "status", align: "right" },
        { text: "Action", value: "actions" }
      ],
      editBillNumber: null,
      // editBillDialog: false,
      showBill: true,
      showNewBill: false,
      payBillDialog: false,
      editBillDialog: false,
      billDataInfo: {},
      start_date: "",
      end_date: "",
      status:"",
      statusItems:this.getInvoiceBillStatuses(),
      activeIndex:undefined,
    };
  },
  mounted() {
    this.profile_number = this.$route.query.prof ? this.$route.query.prof : null;
    this.fetchProfiles();
  },
  methods: {
    ...mapActions(
        [
          "doGetBillListData",
          "getGeneratedBillNumber",
          "fetchProfiles",
          "doCancelInvoiceBill",
          "doGetBillInfo",
          "getInvoiceBillBalance"
        ]),

    filterBillList(raw_data) {
      let billListData = raw_data.data;

      this.server_items_length = raw_data.total;
      this.tableBillLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let billListJsn = {};
      let billListObj = [];

      billListData.forEach((list, i) => {
        billListJsn = {
          key: (current_page - 1) * per_page + i + 1,
          supplier_name: list.customer ? list.customer.name : "",
          invoice_date: list.invoice_date ? list.invoice_date : "",
          due_date: list.due_date ? list.due_date : "",
          amount: list.total_amount ? list.total_amount.afterDiscount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
          due_amount: list.total_amount ? list.total_amount.due
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
          number: list.number ? list.number : "",
          status: list.status ? list.status : "",
          counter: list.counter ? list.counter : "",
          reference: list.ref_no ? list.ref_no : ""
        };
        billListObj.push(billListJsn);
      })


      this.billListData = billListObj;
    },
    makePayment(item) {
      this.billDataInfo = item;
      this.payBillDialog = true;
      this.getInvoiceBillBalance(item.number);
    },
    editBill(item){
      if(item){
        this.editBillDialog = true
        this.doGetBillInfo(item.number);
      }
    },
    cancelBill(item) {
      let confirm_dele = confirm("Are you sure you want to cancel this Bill?");
      if (!confirm_dele) {
        return false;
      }
      // alert(JSON.stringify(item));
      this.activeIndex = item.number
      this.doCancelInvoiceBill(item.number);
    },
    getColor(statusColor) {
      //changes color status
      return this.getStatusColor(statusColor);
    },
    filterData() {
      this.tableBillLoader = true

      let options = {
        page: 1,
        per_page: this.items_per_page,
        profile_number: this.profile_number,
        start_date: this.start_date,
        end_date: this.end_date,
        search: this.search,
        status: this.status
      };


      this.doGetBillListData(options);

    },
    filterSupplierList(customerList) {
      let dataObj = [{text: "Select profiles", id: null, disabled: true}];
      let dataJson = {};
      customerList.forEach(list =>{
        dataJson = {
          text: list.name,
          id: list.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })


      this.supplierDataList = dataObj;
    },
  },
  computed: {
    ...mapGetters([
      "getBillListData",
      "getNewBillData",
      "getInvoicePaymentData",
      "getProfileDataList",
      "getCancelInvoiceBill",
      "getCustomerDetailsErrorMessage",
      "getUpdateBillData"
    ])
  },
  watch: {
    getBillListData(billList) {
      this.filterBillList(billList);
    },

    paginationBill(obj) {
      this.tableBillLoader = true;

      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        profile_number: this.profile_number,
        start_date: this.start_date,
        end_date: this.end_date,
        search: this.search,
        status: this.status
      };

      this.doGetBillListData(options);
    },

    getNewBillData(billData) {
      if (billData) {
        this.$refs["bill_components"].btnBillSpinner = false;
        this.$refs["bill_components"].form = {
          ref_no: null,
          invoice_date: new Date().toISOString().substr(0, 10),
          due_date: new Date().toISOString().substr(0, 10),
          notes: null,
          profile_number: null,
          discounts: null,
          absolute_discounts: null,
          invoice_type: "supplier",
          items: [
            {
              item_number: null,
              quantity: null,
              item_discounts: null,
              unit_price: null
            }
          ],
          expense: {
            date: null,
            reference: null,
            storage_account_number: null,
            payment_method: null
          },
          expense_items: [
            {
              name: null,
              amount: null,
              notes: null,
              account_number: null
            }
          ]
        };
        this.successToast("Success", billData.message);

        this.filterData()

        //generate the next bill number
        this.getGeneratedBillNumber();
      }
    },
    activeTab(data) {
      router.push({ name: "bill", query: { type: data } });
    },
    getInvoicePaymentData(invoiceData) {
      if (invoiceData.message) {
        this.$refs["paymentComponents"].form = {
          invoice_number: this.billDataInfo.number,
          amount: null,
          payment_method_number: null,
          storage_account_number: null,
          payment_date: new Date().toISOString().substr(0, 10),
          notes: null
        };
        this.$refs["paymentComponents"].invoiceLoader = false;
        this.payBillDialog = false;
        this.successToast("Success", invoiceData.message);

        let options = {
          profile_number: this.profile_number,
          page: this.page,
          per_page: this.items_per_page
        };

        this.doGetBillListData(options);
      }
    },
    start_date() {
      this.filterData();
    },
    end_date() {
      this.filterData();
    },
    getProfileDataList(supplierDataList) {
      this.filterSupplierList(supplierDataList);
    },
    getCancelInvoiceBill(cancelInvoice){
      if(cancelInvoice){
        this.activeIndex = undefined
        this.successToast("Success", cancelInvoice.message)
        this.filterData()
      }
    },
    getCustomerDetailsErrorMessage(error){
      if(error){
        this.activeIndex = undefined
        this.errorToast("Error", error.message)
      }
    },
    getUpdateBillData(data){
      if(data){
        this.editBillDialog = false
        this.successToast("Success", data.message)
        this.$refs.edit_bill_items.btnBillSpinner = false
        this.filterData()
      }
    }
  }
};
</script>

<style scoped>
.refNumber {
  text-decoration: none;
}

.refNumber:hover {
  color: green;
  text-decoration: underline;
}
</style>
