<template>
  <div>
    <form ref="form" @submit.prevent="payInvoice">
      <v-alert type="error" v-if="payInvoiceError">
        {{ payInvoiceError }}
      </v-alert>
      <v-row class="mx-auto">
        <v-spacer></v-spacer>

<!--        <v-chip
            v-if="!form.number"
            class="mr-3 mb-3"
            color="indigo darken-3"
            outlined
        >
          {{ amountDue }}
        </v-chip>-->


      </v-row>

      <v-row class="mx-auto">
        <v-col>

          <v-text-field
              class="mb-2"
              v-model="form.amount"
              label="Amount *"
              outlined
              dense
              required
              @keypress="onlyNumberValidator($event)"
              v-on:blur="compute(form.amount)"
          ></v-text-field>


          <v-autocomplete
              outlined
              clearable
              v-model="form.profile_number"
              :items="supplierDataObj"
              item-value="id"
              color="pink"
              label="Tax Authority"
              append-outer-icon="mdi-plus"
              @click:append-outer="addSupplierData"
              required
              dense
          ></v-autocomplete>


          <v-text-field
              v-model="form.payment_date"
              label="Payment Date"
              outlined
              dense
              type="date"
              required
              hide-details
          ></v-text-field>
          <small style="color: red;" v-if="yearLengthValidator(form.payment_date)">
            Invalid Date
          </small>
          <v-select
              class="mt-3"
              outlined
              v-model="form.storage_account_number"
              :items="storageAccountDataList"
              item-value="id"
              color="pink"
              label="Bank/Cash Account *"
              append-outer-icon="mdi-plus"
              @click:append-outer="storageAccountModel"
              dense
          ></v-select>

          <v-select
              outlined
              v-model="form.payment_method_number"
              :items="paymentMethodDataList"
              item-value="id"
              color="pink"
              label="Payment Method"
              append-outer-icon="mdi-plus"
              @click:append-outer="paymentMethodModel"
              dense
          ></v-select>


          <v-textarea
              v-model="form.notes"
              label="Notes"
              outlined
              rows="2"
              dense
          ></v-textarea>


        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="companyButton"
            :loading="buttonLoader"
            color="#0000ff"
            dark
            type="submit"
        >
          {{form.number ? "Edit Invoice" : "Pay Tax"}}
        </v-btn>
      </v-card-actions>
    </form>

    <!--                    payment methods-->

    <v-dialog v-model="payment_methods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Payment Methods</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payment_methods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <payment-methods ref="paymentMethodsComponents"></payment-methods>
        </div>
      </v-card>
    </v-dialog>

    <!--    adds Storage accounts -->

    <v-dialog v-model="storage_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="storage_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <storage-account ref="storageAccountComponent"></storage-account>
        </div>
      </v-card>
    </v-dialog>


    <!--    Vendor dialog-->

    <v-dialog v-model="add_supplier_dialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Supplier</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_supplier_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-profile ref="add_customer_info"></new-profile>
        </div>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import StorageAccount from "@/modules/dashboard/accounting/commons/StorageAccount";
import PaymentMethods from "@/modules/dashboard/accounting/commons/PaymentMethods";
import {mapActions, mapGetters} from "vuex";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";

export default {
  name: "TaxPaymentForm",
  components: {NewProfile, StorageAccount, PaymentMethods },
  props: {
    invoiceInfo: {},
    receiptType: String
  },
  data() {
    return {
      buttonLoader: false,
      payment_methods_dialog: false,
      storage_account_dialog: false,
      add_supplier_dialog: false,
      paymentMethodDataList: [],
      storageAccountDataList: [],
      payInvoiceError: null,
      // amountDue:null,
      number: null,
      form: {
        profile_number: null,
        amount: null,
        payment_method_number: null,
        storage_account_number: null,
        payment_date: new Date().toISOString().substr(0, 10),
        notes: null
      },
      invoice_counter: null,
      supplierDataObj: [],
    };
  },
  mounted() {
    this.doGetPaymentMethodData();
    this.doGetStorageAccountData();
    this.fetchProfiles();
    if (this.invoiceInfo) {
      this.filterPaymentInfo();
    }
  },
  methods: {
    ...mapActions([
      "doGetPaymentMethodData",
      "doGetStorageAccountData",
      "doPayTaxes",
      "doEditInvoicePaymentDetails",
      "fetchProfiles"
    ]),
    payInvoice() {
      this.buttonLoader = true;
      this.payInvoiceError = null;
      let formData = this.form;
      let moneyData =  formData.amount
      formData.amount = moneyData.toString().includes(',') ? (moneyData.toString().replace(/,/g, "") * 1)  : moneyData
      if(this.number){
        this.doEditInvoicePaymentDetails(formData);
      }else {
        this.doPayTaxes(formData);
      }


    },
    paymentMethodModel() {
      this.payment_methods_dialog = true;
    },
    storageAccountModel() {
      this.storage_account_dialog = true;
    },
    addSupplierData() {
      this.add_supplier_dialog = true;
    },
    compute(value) {
      value = value + "";
      value = value.toString().replace(/,/g, "");

      if (!isNaN(value)) {
        value = value - 0;
      }
      this.form.amount =
          eval(value) > 0
              ? eval(value)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : null;
    },
    filterPaymentInfo() {
      this.payInvoiceError = "";
      this.form.invoice_number = this.invoiceInfo.number;
      this.invoice_counter = this.invoiceInfo.counter;

      // this.getInvoiceBillBalance(this.invoiceInfo.number);
    },
    filterCustomerList(customerList) {
      let dataObj = [
        // { text: "Add Profile", id: "new-profile"},
        {text: "Select profiles", id: null, disabled: true}
      ];
      let dataJson = {};
      customerList.forEach(list =>{
        let tradeName = list.trading_name ? " ["+list.trading_name+"]" : ""
            dataJson = {
          text: list.name + tradeName,
          id: list.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })


      this.supplierDataObj = dataObj;
    },
  },
  computed: {
    ...mapGetters([
      "getUnitData",
      "getNewPaymentMethods",
      "getPaymentMethodDataSet",
      "getNewStorageAccountData",
      "getStorageAccountDataSet",
      "getInvoiceError",
      "getInvoiceBillBalanceInfo",
      "getInvoicePaymentDetails",
      "getTaxPaymentData",
      "getProfileDataList",
      "getProfileResponseData"
    ])
  },
  watch: {
    getTaxPaymentData(data){
      if(data){
       this.buttonLoader = false

        this.form.profile_number = null
        this.form.amount = null
        this.form.payment_method_number = null
        this.form.storage_account_number = null
        this.form.payment_date = new Date().toISOString().substr(0, 10)
        this.form.notes =  null
        this.successToast("Success", data.message)
      }
    },
    getProfileDataList(supplierDataList) {
      this.filterCustomerList(supplierDataList)
    },
    getNewPaymentMethods(paymentData) {
      if (paymentData) {
        this.$refs["paymentMethodsComponents"].form = {
          name: null,
          notes: null
        };
        this.$refs["paymentMethodsComponents"].addPaymentMethodLoader = false;
        this.payment_methods_dialog = false;
        this.form.payment_method_number = paymentData.data.number;
        this.successToast("Success", paymentData.message);
        this.doGetPaymentMethodData();
      }
    },
    getPaymentMethodDataSet(paymentData) {
      this.paymentMethodDataList = this.nameNumberFilter(paymentData);
    },
    getNewStorageAccountData(storageAccountData) {
      if (storageAccountData) {
        this.$refs["storageAccountComponent"].form = {};
        this.$refs["storageAccountComponent"].addStorageLoader = false;
        this.storage_account_dialog = false;
        this.form.storage_account_number = storageAccountData.data.number;

        this.successToast("Success", storageAccountData.message);
        this.doGetStorageAccountData(this.$company.number);
      }
    },
    getStorageAccountDataSet(storageAccountData) {
      this.storageAccountDataList = this.nameNumberFilter(storageAccountData);
    },
    getInvoiceError(invoiceError) {
      if (invoiceError) {
        this.payInvoiceError = invoiceError;
        this.buttonLoader = false;
        this.errorToast("Error", invoiceError)
      }
    },
    getInvoiceBillBalanceInfo(balanceData) {
      if (balanceData) {

        this.form.number= null
        this.form.transaction_number= null
        this.form.payment_method_number= null
        this.form.storage_account_number= null
        this.form.payment_date= new Date().toISOString().substr(0, 10)
        this.form.notes= null


        this.form.amount = balanceData
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        this.amountDue = "Amount due: "+balanceData
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    invoiceInfo() {
      this.filterPaymentInfo();
    },
    getInvoicePaymentDetails(data){
      if(data){
        this.invoice_counter = data.invoice_counter ? data.invoice_counter : null
        this.form.number = data.number ? data.number : null
        this.form.transaction_number = data.transaction_number ? data.transaction_number : null
        this.form.invoice_number = data.invoice_number ? data.invoice_number : null
        this.form.payment_date = data.payment_date ? data.payment_date : null
        this.form.payment_method_number = data.payment_method_number ? data.payment_method_number : null
        this.form.storage_account_number = data.storage_account_number ? data.storage_account_number : null
        this.form.notes = data.notes ? data.notes : null
        this.form.amount = data.amount ?
            data.amount.
            toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null
      }
    },
    getProfileResponseData(customerData) {
      if (customerData) {
        this.$refs["add_customer_info"].customerSpinner = false;
        this.$refs["add_customer_info"].form = {
          name: null,
          trading_name: null,
          address: null,
          number: null,
          tin: null,
          phoneContact: [
            {
              contact: null,
              id: null,
              type: "phone"
            }
          ],
          emailContact: [
            {
              contact: null,
              id: null,
              type: "email"
            }
          ],
          contactPersons: [
            {
              name: null,
              phone_contacts: [
                {
                  phone: null
                }
              ],
              email_contacts: [
                {
                  email: null
                }
              ],
            }
          ]
        };
        this.add_supplier_dialog = false;
        this.successToast("Success", customerData.message);
        this.form.profile_number = customerData.data.number;
        this.fetchProfiles();
      }
    },

  }
}
</script>

<style scoped>

</style>