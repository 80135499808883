<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Chart Of Accounts</v-toolbar-title>
            <v-spacer></v-spacer>
            <span>
              <v-btn
                  class="ma-2 text-decoration-none"
                  outlined
                  small
                  color="#1b5e20"
                  :loading="downloadSpinner"
                  @click="downloadTemplate"
              >
                <v-icon left>
                  mdi-import
                </v-icon>
                Template
              </v-btn>

              <v-btn
                  class="ma-2"
                  outlined
                  color="#242f7e"
                  small
                  @click="fileUploader"
              >
                <v-icon left>
                  mdi-upload
                </v-icon>
                Import
              </v-btn>

              <v-btn
                  class="ma-2"
                  outlined
                  color="#242f7e"
                  small
                  @click="fileExport"
                  :loading="exportSpinner"
              >
                <v-icon left>
                 mdi-file-excel
                </v-icon>
                Export EXCEL
              </v-btn>

                <v-btn
                    class="ma-2"
                    outlined
                    color="#242f7e"
                    small
                    @click="fileExportPdf"
                    :loading="exportSpinner"
                >
                <v-icon left>
                 mdi-file-pdf-box
                </v-icon>
                Export PDF
              </v-btn>
            </span>
          </v-toolbar>

          <v-tabs v-model="activeTab">
            <!--                        <v-tabs-slider color="yellow"></v-tabs-slider>-->
            <v-tab href="#account">
              <v-icon left>mdi-account</v-icon>
              Account
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-account">
              <v-icon left>mdi-lock</v-icon>
              New Account
            </v-tab>

            <v-tab-item id="account">
              <v-divider></v-divider>
              <v-card flat>
                <v-row>
                  <v-col cols="4" class="ml-auto">
                    <v-card-title>
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          dense
                          outlined
                          hide-details
                          v-on:keyup.enter="clickedEnter"
                      ></v-text-field>
                    </v-card-title>
                  </v-col>
                </v-row>

                <v-data-table
                    :headers="chartHeaders"
                    :items="accountItemData"
                    :search="search"
                    :loading="tableChartLoader"
                    loading-text="Loading... please wait"
                    :page="page"
                    :items-per-page="items_per_page"
                    :server-items-length="server_items_length"
                    :options.sync="paginationAccount"
                    :footer-props="{
                    itemsPerPageOptions: items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Accounts per page'
                  }"
                >
                  <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                        :pagination="pagination"
                        :options="options"
                        @update:options="updateOptions"
                        items-per-page-text="Accounts per page"
                        :items-per-page-options="items_per_page_options"
                        :showFirstLastPage="true"
                        firstIcon="mdi-arrow-collapse-left"
                        lastIcon="mdi-arrow-collapse-right"
                        prevIcon="mdi-chevron-left"
                        nextIcon="mdi-chevron-right"
                    />
                  </template>
                  <template  v-slot:item.actions="{ item }">
                    <v-icon v-if="!item.locked" small class="mr-2" @click="editAccount(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon v-if="!item.locked" small @click="deleteAccount(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item id="new-account">
              <v-divider></v-divider>
              <v-card flat>
                <v-row class="mx-auto">
                  <v-col col lg="6" md="6" sm="12" class="mt-15">
                    <div style="width: 80%">
                      <p style="font-size: 20px;">Add Account</p>
                      <add-account ref="addAccountForm"></add-account>
                    </div>
                  </v-col>
                  <v-col col lg="6" md="6" sm="12">
                    <chart-of-account-info></chart-of-account-info>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="editChartOfAccountDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Chart Of Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editChartOfAccountDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              ref="addAccountForm"
              :editedChartOfAccountInfo="editedChartOfAccountInfo"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Import Chart of Accounts</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="uploadDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <upload-file
              ref="upload_component"
              fileType="chart-of-accounts"
          ></upload-file>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddAccount from "./commons/AddAccount";
import ChartOfAccountInfo from "./commons/ChartOfAccountInfo";
import UploadFile from "@/modules/dashboard/accounting/UploadFile/UploadFile";
import configs from "@/global/configs.json";
import router from "@/global/router";

export default {
  name: "Accounting",
  components: {UploadFile, ChartOfAccountInfo, AddAccount},
  data() {
    return {
      activeTab: "account",
      search: "",
      getUserNumber: null,
      accountItemData: [],
      items_per_page_options: [20, 50, 100, 150],
      tableChartLoader: false,
      page: 1,
      items_per_page: 10,
      server_items_length: 0,
      paginationAccount: {},
      form: {},
      chartHeaders: [
        {
          width: "5%",
          text: "#",
          align: "start",
          value: "key"
        },
        {text: "Account Type", value: "account_type"},
        {text: "Account Name", value: "name"},
        {text: "Account Code", value: "code"},
        {text: "Description", width: "30%", value: "description"},
        {text: "Amount", value: "amount"},
        {text: "Action", value: "actions"}
      ],
      chartOfAccountsData: [
        {
          name: "Donation Total Expense : USh 50,000.00",
          date: "Income from grants, gifts and charity given to us",
          action: "Edit"
        }
      ],
      editChartOfAccountDialog: false,
      editedChartOfAccountInfo: {},
      uploadDialog: false,
      downloadLink:
          configs.SERVER_IP + "template?type=chart-of-accounts&file_token=",
      exportLink:
          configs.SERVER_IP + "export?type=chart-of-accounts&file_token=",
      downloadSpinner: false,
      exportSpinner: false,
      downloadLinkPdf:
          configs.SERVER_IP + "reports/export/charts-of-accounts?token=",
    };
  },
  mounted() {
    /* this.tableChartLoader = true;
    this.doGetAccount(this.$company.number);*/
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  methods: {
    ...mapActions([
      "doGetAccount",
      "getCharOfAccountsEditData",
      "doDeleteChartOfAccount",
      "downloadTemplateFile",
      "exportFile",
      "searchAccount"
    ]),
    getAccountItemData(rowData) {
      let accountData = rowData.data;
      this.server_items_length = rowData.total;

      let current_page = rowData.current_page;
      this.page = current_page;
      let per_page = rowData.per_page;
      this.items_per_page = per_page * 1;

      this.tableChartLoader = false;
      let accountJsn = {};
      let accountObj = [];

      let account_type = "";
      accountData.map((accountData,i) => {
            let _account_type = "";

            if (account_type !== accountData.account_type) {
              account_type = accountData.account_type;
              _account_type = account_type;
            } else {
              _account_type = "";
            }

            accountJsn = {
              key: (current_page - 1) * per_page + i + 1,
              account_type: _account_type,
              name: accountData.full_name ? accountData.full_name : "",
              code: accountData.code ? accountData.code : "",
              description: accountData.description ? accountData.description : "",
              amount: accountData.total_worth
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              number: accountData.number ? accountData.number : "",
              locked: accountData.locked ? accountData.locked : ""
            };
            accountObj.push(accountJsn);
          }
      )


      this.accountItemData = accountObj;
    },
    editAccount(item) {
      this.editedChartOfAccountInfo = item;
      this.editChartOfAccountDialog = true;
    },
    deleteAccount(item) {
      let confirm_delete = confirm(
          "Are you sure you want to delete this Account?"
      );
      if (!confirm_delete) {
        return false;
      }
      this.doDeleteChartOfAccount(item.number);
    },
    fileUploader() {
      this.uploadDialog = true;
    },
    downloadTemplate() {
      this.downloadSpinner = true;
      this.downloadTemplateFile("chart-of-accounts");
    },
    fileExportPdf(){
      this.downloadSpinner = false;
      let templateData  = localStorage.getItem("token");
      let companyNumber  = JSON.parse(localStorage.getItem("company")).number;

      let fileUrl = this.downloadLinkPdf + templateData+"&company-number="+companyNumber;

      const pdfObj = document.createElement("a");
      pdfObj.href = fileUrl;
      pdfObj.click();
    },
    fileExport() {
      this.exportSpinner = true;
      this.exportFile("chart-of-accounts");
    },
    clickedEnter() {
      this.tableChartLoader = true;
      let options = {
        account: this.search,
        page: this.page,
        per_page: this.items_per_page
      };
      this.searchAccount(options);
    }
  },
  computed: {
    ...mapGetters([
      "getChartStatusResponse",
      "getChartOfAccountsErrorMessage",
      "getAccountData",
      "getChartOfAccountsData",
      "getDeleteChartOfAccountData",
      "getUploadData",
      "getTemplateDownloadData",
      "getExportFileData"
    ])
  },
  watch: {
    getAccountData(accountData) {
      this.getAccountItemData(accountData);
    },
    getChartOfAccountsData(accountData) {
      if (accountData) {
        let options = {
          company_number: this.$company.number,
          page: this.page,
          per_page: this.items_per_page
        };
        this.editChartOfAccountDialog = false;
        this.doGetAccount(options);
        this.$refs["addAccountForm"].chartSpinner = false;
        this.$refs["addAccountForm"].form = {
          name: null,
          code: null,
          description: null,
          sub_account: null,
          number: null
        };
        this.successToast("Success", accountData.message);
      }
    },
    paginationAccount(obj) {
      this.tableChartLoader = true;
      let options = {
        company_number: this.$company.number,
        page: obj.page,
        per_page: obj.itemsPerPage
      };
      this.doGetAccount(options);
    },
    getEditedChartOfAccountData(editedData) {
      if (editedData) {
        let options = {
          company_number: this.$company.number,
          page: this.page,
          per_page: this.items_per_page
        };
        this.doGetAccount(options);
        this.$refs["editChartOfAccountRef"].chartSpinner = false;
        this.editCharOfAccountDialog = false;
        this.successToast();
      }
    },
    getDeleteChartOfAccountData(deletedChartOfAccounts) {
      if (deletedChartOfAccounts) {
        let options = {
          company_number: this.$company.number,
          page: this.page,
          per_page: this.items_per_page
        };
        this.doGetAccount(options);

        this.successToast("Success", deletedChartOfAccounts.message);
      }
    },
    getUploadData(uploadChartOfAccount) {
      if (uploadChartOfAccount) {
        this.uploadDialog = false;
        this.$refs["upload_component"].spinner = false;
        this.$refs["upload_component"].form = {
          attachment: null
        };
        this.successToast("Success", uploadChartOfAccount.message);
      }
    },
    getTemplateDownloadData(templateData) {
      if (templateData) {
        this.downloadSpinner = false;

        let fileUrl = this.downloadLink + templateData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },
    getExportFileData(exportData) {
      if (exportData) {
        this.exportSpinner = false;
        let fileUrl = this.exportLink + exportData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },
    activeTab(data) {
      router.push({name: "chart-of-accounts", query: {type: data}});
    },
    getChartOfAccountsErrorMessage(data){
      if(data){
        this.errorToast("Error",  data.message)
      }
    }
  }
};
</script>

<style scoped></style>
