<template>
  <span>
       <v-toolbar dense flat>
        <v-toolbar-title>Company Details</v-toolbar-title>
      </v-toolbar>
    <v-divider></v-divider>
  <v-card flat style="width: 70%;" class="mx-auto">
    <v-card-text>






      <br />

      <v-alert type="error" v-if="responseMessage">
        {{ responseMessage }}
      </v-alert>

      <form ref="form" @submit.prevent="saveCompanyData">
        <v-row class="mb-2">
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-text-field
                v-model="form.company_name"
                label="Trading Name *"
                outlined
                dense
                required
                hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-text-field
                v-model="form.legal_name"
                label="Legal Name *"
                outlined
                dense
                required
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-autocomplete
            v-model="form.company_type_id"
            :items="companyTypeData"
            item-value="id"
            item-text="name"
            label="Company Type *"
            outlined
            required
            dense
        ></v-autocomplete>

        <v-textarea
            v-model="form.company_address"
            label="Company Address *"
            outlined
            rows="1"
            dense
            hide-details
            required
        ></v-textarea>

        <v-row>
          <v-col cols="6">
              <v-text-field
                  v-model="form.company_phone"
                  label="Company Phone *"
                  outlined
                  dense
                  hide-details
                  required
              ></v-text-field>
          </v-col>
          <v-col cols="6">
  <v-text-field
      v-model="form.company_email"
      label="Company Email"
      outlined
      dense
      hide-details
  ></v-text-field>
          </v-col>
        </v-row>





        <v-text-field
            v-model="form.company_website"
            label="Company Website"
            outlined
            dense
        ></v-text-field>


            <v-row>
        <v-col cols="6">
           <v-autocomplete
               class="mb-3"
               outlined
               v-model="form.currency_number"
               :items="ItemDataObj"
               item-value="id"
               color="pink"
               label="Select Default Currency"
               required
               dense
               clearable
               hide-details
           ></v-autocomplete>
        </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="form.company_tin"
                label="Company Tin"
                outlined
                dense
                hide-details
            ></v-text-field>
          </v-col>
      </v-row>








<v-row>
    <v-col cols="5">
      <b>Your financial year starts on ?</b>
      <v-select
          v-model="form.financial_start_day"
          :items="daysList"
          item-value="id"
          item-text="name"
          label="Day"
          outlined
          required
          dense
          hide-details
      ></v-select>
    </v-col>

    <v-col cols="7">
     <br />
      <v-select
          v-model="form.financial_start_month"
          :items="monthList"
          item-value="id"
          item-text="text"
          label="Month"
          outlined
          required
          dense
          hide-details
          v-on:change="fetchMonthDays(form.financial_start_month)"
      ></v-select>
    </v-col>

  </v-row>


        <div class="text-right">
          <v-btn
              class="companyButton"
              color="#0000ff"
              :loading="companySpinner"
              dark
              type="submit"
          >
            Save Changes
          </v-btn>
        </div>
      </form>
    </v-card-text>
  </v-card>
  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CompanyProfile",
  data(){
    return{
      responseMessage:null,
      companySpinner: false,
      companyTypeData: [],
      ItemDataObj: [],
      form: {
        company_name: null,
        currency_number: null,
        legal_name: null,
        company_address: null,
        company_phone: null,
        company_email: null,
        company_website: null,
        company_tin: null,
        company_type_id: null,
        financial_start_day:null,
        financial_start_month:null,
        financial_end_day:null,
        financial_end_month:null,
      },
      company_number:null,
      daysList:[],
      monthList:[]
    }
  },
  mounted() {
    this.fetchCurrencies()
    this.doGetCompanyTypes();
    this.prefillCompanyData()

    for (let i = 1; i < 32; i++) {
      this.daysList.push(i)
    }

      this.monthList = [
        {id:1, text:"January"},
        {id:2, text:"February"},
        {id:3, text:"March"},
        {id:4, text:"April"},
        {id:5, text:"May"},
        {id:6, text:"June"},
        {id:7, text:"July"},
        {id:8, text:"August"},
        {id:9, text:"September"},
        {id:10, text:"October"},
        {id:11, text:"November"},
        {id:12, text:"December"},
      ]


  },
  methods: {
    ...mapActions(
        [
            "doGetCompanyTypes",
            "updateCompanyData",
            "fetchCurrencies"
        ]
    ),
    prefillCompanyData(){
      let companyData =  this.$company
      this.form.company_name = companyData.company_name
      this.form.legal_name = companyData.legal_name
      this.form.company_type_id = companyData.company_type_id
      this.form.district_id = companyData.district_id
      this.form.company_address = companyData.company_address
      this.form.company_phone = companyData.company_phone
      this.form.company_email = companyData.company_email
      this.form.company_website = companyData.company_website
      this.form.currency_number = companyData.currency_number
      this.form.company_tin = companyData.company_tin
      this.form.financial_start_day = companyData.financial_start_day
      this.form.financial_start_month = companyData.financial_start_month
      this.form.financial_end_day = companyData.financial_end_day
      this.form.financial_end_month = companyData.financial_end_month
      this.company_number = companyData.number
    },
    saveCompanyData(){
      this.companySpinner = true
      let data = {
        formData : this.form,
        company_number  : this.company_number
      }
     this.updateCompanyData(data)
    },
    fetchMonthDays(month){

      //alert(this.getDaysInMonth(month))

      this.daysList = []
      for (let i = 1; i <= this.getDaysInMonth(month); i++) {
        this.daysList.push(i)
      }

    },
    getDaysInMonth(month) {
      const date = new Date();
      const year = date.getFullYear();
      return new Date(year, month, 0).getDate();
    },
    filterCurrencies(data){
      if(data){

        let arr = [
          { text: "Select default currency", id: "", disabled: true},
        ];
        let dataJson = {};
        data.forEach(list =>{
          dataJson = {
            text: list.name+" ["+list.code+"]",
            id: list.number,
            disabled: false
          };
          arr.push(dataJson);
        })


        this.ItemDataObj = arr
      }
    }
  },
  computed: {
    ...mapGetters(
        [
          "getCompanyTypes",
          "getCompanyUpdate",
          "getCompanyErrorMessage",
          "getCurrencyDataAll"
        ]
    )
  },
  watch:{
    getCompanyUpdate(companyData){
      if(companyData){
        this.companySpinner = false
        localStorage.setItem("company", JSON.stringify(companyData.data));
        this.$root.$emit("update_company_name", companyData.data.company_name);
        this.successToast("Success", companyData.message)
      }
    },
    getCompanyErrorMessage(error) {
      if (error) {
        this.companySpinner = false;
        this.responseMessage = error.message;
        this.errorToast(error.message);
      }
    },
    getCompanyTypes(data){
      if(data){
        this.companyTypeData = data
      }
    },
    getCurrencyDataAll(data){
      if(data){
        this.filterCurrencies(data)
      }
    }
  }
}
</script>

<style scoped>

</style>