var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}}),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"3"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.generalTableData,"search":_vm.search,"loading":_vm.tableUserLoader,"loading-text":"Loading... please wait","item-key":"name","page":_vm.page,"items-per-page":_vm.items_per_page,"footer-props":{
                      'itemsPerPageOptions': _vm.items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text':'Payments per page'
                    },"server-items-length":_vm.server_items_length,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
                    var pagination = ref.pagination;
                    var options = ref.options;
                    var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"Payments per page","items-per-page-options":_vm.items_per_page_options,"showFirstLastPage":true,"firstIcon":"mdi-arrow-collapse-left","lastIcon":"mdi-arrow-collapse-right","prevIcon":"mdi-chevron-left","nextIcon":"mdi-chevron-right"},on:{"update:options":updateOptions}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }