<template>
<span>
    <v-row>
      <v-col cols="6">
        <v-alert type="error" v-if="formInvoiceErrorMessage">
          {{ formInvoiceErrorMessage }}
        </v-alert>
      </v-col>
    </v-row>

    <form ref="form" @submit.prevent="editInvoice">
      <v-row>
        <v-col cols="3">

              <v-text-field
                  v-model="invoice_number"
                  label="Invoice Number"
                  outlined
                  dense
                  readonly
                  required
              ></v-text-field>

              <v-text-field
                  maxlength="17"
                  v-model="form.ref_no"
                  label="Reference No"
                  outlined
                  dense
              ></v-text-field>
        </v-col>
      <v-col cols="4">
          <v-autocomplete
              class="mb-3"
              outlined
              v-model="form.profile_number"
              :items="customerDataList"
              item-value="id"
              color="pink"
              label="Customer"
              dense
              clearable
              hide-details
              append-outer-icon="mdi-plus"
              @click:append-outer="addCustomerModel"
          ></v-autocomplete>

          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="form.invoice_date"
                  label="Invoice Date [ dd / mm / yyy ]"
                  outlined
                  dense
                  type="date"
                  required
              ></v-text-field>
               <small style="color: red;" v-if="yearLengthValidator(form.invoice_date)">
                Invalid Date
              </small>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="form.due_date"
                  label="Invoice Due Date [ dd / mm / yyy ]"
                  outlined
                  dense
                  type="date"
                  required
                  hide-details
              ></v-text-field>
               <small style="color: red;" v-if="yearLengthValidator(form.due_date)">
                Invalid Date
              </small>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="5">
          <v-textarea
              v-model="form.notes"
              label="Notes"
              outlined
              rows="3"
              dense
              hide-details
              class="mb-3"
          ></v-textarea>

          <!--          <v-row>
                      <v-col cols="4">
                        <v-checkbox
                            v-model="absoluteDiscount"
                            class="shrink mr-0 mt-0 float-left"
                            hide-details
                            label="is discount absolute?"
                            id="sub_account_id"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="8">
                         <v-text-field
                             v-if="absoluteDiscount"
                             v-model="form.absolute_discount"
                             label="Discount Amount"
                             outlined
                             dense
                         ></v-text-field>

                          <v-autocomplete
                              outlined
                              v-if="!absoluteDiscount"
                              v-model="form.discount"
                              :items="discountDataObj"
                              item-value="id"
                              color="pink"
                              label="Discount (s)"
                              append-outer-icon="mdi-plus"
                              @click:append-outer="addDiscountsModel"
                              clearable
                              hide-selected
                              dense
                          ></v-autocomplete>

                      </v-col>
                    </v-row>-->





        </v-col>


         <p class="ml-5">Invoice Items</p>


        <v-simple-table dense style="width: 100%; border-top: 1px solid #eeebeb; border-bottom: 1px solid #eeebeb;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="width: 20%;" class="text-left">Item</th>
                      <th style="width: 20%;" class="text-left">Discount (s)</th>
                      <th style="width: 20%;" class="text-left">Tax</th>
                      <th style="width: 10%;" class="text-left">Quantity</th>
                      <th style="width: 10%;" class="text-left">Price</th>
                      <th style="width: 15%;" class="text-left">Amount</th>
                      <th style="width: 5%;" class="text-left">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="(invoiceItemData, index) in form.bill_of_items"
                        v-bind:key="index"
                    >
                      <td style="border-right: 1px solid #e0e0e0;">
                        <v-autocomplete
                            flat
                            solo
                            v-model="invoiceItemData.item_number"
                            :items="invoiceItemDataObj"
                            item-value="id"
                            color="pink"
                            label="Item"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="addItemData"
                            required
                            hide-details
                            @change="
                            addInvoiceAutoCompute(
                              invoiceItemData.item_number,
                              index
                            )
                          "
                            dense
                        ></v-autocomplete>
                      </td>
                      <td style="border-right: 1px solid #e0e0e0;">
                        <v-autocomplete
                            flat
                            solo
                            v-model="invoiceItemData.discount"
                            :items="discountDataObj"
                            item-value="id"
                            color="pink"
                            label=""
                            hide-selected
                            dense
                            hide-details
                            clearable
                            append-outer-icon="mdi-plus"
                            @click:append-outer="addDiscountsModel"
                        ></v-autocomplete>
                      </td>

                       <td style="border-right: 1px solid #e0e0e0;">
                         <v-autocomplete
                             flat
                             solo
                             v-model="invoiceItemData.tax_number"
                             :items="taxItemDataObj"
                             item-value="id"
                             color="pink"
                             label=""
                             hide-selected
                             dense
                             hide-details
                             append-outer-icon="mdi-plus"
                             @click:append-outer="addTaxModel"
                         ></v-autocomplete>
                       </td>
                      <td style="border-right: 1px solid #e0e0e0;">
                        <v-text-field
                            @keypress="onlyNumberValidator($event)"
                            v-model="invoiceItemData.quantity"
                            label=""
                            flat
                            solo
                            dense
                            hide-details
                            required
                            v-on:blur="
                            computeValues(
                              invoiceItemData.quantity,
                              index,
                              'quantity'
                            )
                          "
                        ></v-text-field>
                      </td>
                      <td style="border-right: 1px solid #e0e0e0;">
                        <v-text-field
                            @keypress="onlyNumberValidator($event)"
                            v-model="invoiceItemData.unit_price"
                            label=""
                            flat
                            solo
                            dense
                            hide-details
                            required
                            v-on:blur="
                            computeValues(
                              invoiceItemData.unit_price,
                              index,
                              'unit_price'
                            )
                          "
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                            style="width: 100%;"
                            @keypress="onlyNumberValidator($event)"
                            v-model="invoiceItemData.amount"
                            label=""
                            flat
                            solo
                            dense
                            hide-details
                            required
                            v-on:blur="
                            computeValues(
                              invoiceItemData.amount,
                              index,
                              'amount'
                            )
                          "
                        ></v-text-field>
                      </td>
                      <td style="width: 5px;" class="text-right">
                        <v-icon
                            @click="removesInvoiceItem(index)"
                            style="font-size: 20px;"
                        >mdi-close</v-icon
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                          colspan="5"
                          @click="addInvoiceItems"
                          style="cursor: pointer;"
                      >
                        <span class="text--disabled">Add another item</span>
                      </td>
                      <td class="text-right" colspan="2">

                           <v-btn
                               @click="showOtherBillExpenses"
                               x-small
                               class="ma-2"
                               outlined
                               color="indigo"
                           >
                             {{ !showAdditionalItems ? "Show" : "Hide" }}  Other Charges
                        </v-btn>


                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

         <p v-if="showAdditionalItems" class="ml-5 mt-5">Other Charges</p>

           <v-simple-table v-if="showAdditionalItems" class="table" dense
                           style="width: 100%; border-top: 1px solid #eeebeb; border-bottom: 1px solid #eeebeb;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Notes</th>
                      <th class="text-left" colspan="2">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="(expenseItemData, key) in form.expense_items"
                        v-bind:key="key"
                    >
                      <td style="border-right: 1px solid #e0e0e0;">
                        <v-text-field
                            v-model="expenseItemData.name"
                            flat
                            solo
                            dense
                            hide-details
                        ></v-text-field>
                      </td>
                      <td style="border-right: 1px solid #e0e0e0;">
                        <v-text-field
                            v-model="expenseItemData.notes"
                            flat
                            solo
                            rows="1"
                            dense
                            no-resize
                            hide-details
                        ></v-text-field>
                      </td>

                      <td>
                        <v-text-field
                            v-model="expenseItemData.amount"
                            flat
                            solo
                            dense
                            hide-details
                            @keypress="onlyNumberValidator($event)"
                            v-on:blur="
                            computeExpense(expenseItemData.amount, key)
                          "
                        ></v-text-field>
                      </td>

                      <td>
                        <v-icon
                            @click="removesExpenseItem(key)"
                            style="font-size: 20px;"
                        >mdi-close</v-icon
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                          colspan="6"
                          @click="addExpenseItems"
                          style="cursor: pointer;"
                      >
                        <span class="text--disabled">Add another item</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>


        <v-col cols="12">


              <v-row>
                <v-col cols="8"></v-col>
                <v-col cols="4">
                  <v-card-text>
                    <v-row class="mx-auto">
                      <p style="color: red;" v-if="doSumTotal.discountError">
                        {{ doSumTotal.discountError }}
                      </p>
                    </v-row>


                    <v-row>
                    <v-col cols="6">
                        <v-select
                            v-model="form.tax_type"
                            :items="taxTypeItems"
                            item-value="id"
                            color="pink"
                            dense
                            hint="Tax Type"
                            persistent-hint
                            v-on:change="filterTax"
                        ></v-select>
                     </v-col>
                      <v-spacer></v-spacer>
                      <!--                      tax-->
                    </v-row>

                     <v-divider></v-divider>

                    <v-row class="mx-auto mt-3">
                      <p>Subtotal</p>
                      <v-spacer></v-spacer>
                      <p>{{ doSumTotal.subTotal }}</p>
                    </v-row>

                    <v-divider></v-divider>

                     <v-row class="mx-auto mt-3">
                      <p>Total Expense</p>
                      <v-spacer></v-spacer>
                      <p>{{ totalExpenseAmount }}</p>
                    </v-row>

                    <v-divider></v-divider>

                    <v-row class="mx-auto mt-3">
                      <p>Discount ( {{ doSumTotal.percentageDiscount }} % )</p>
                      <v-spacer></v-spacer>
                      <p>{{ doSumTotal.totalDiscount }}</p>
                    </v-row>

                     <v-row class="mx-auto mt-3">
                      <p>Tax ( {{ doSumTotal.percentageTax }} % )</p>
                      <v-spacer></v-spacer>
                      <p>{{ doSumTotal.totalTax }}</p>
                    </v-row>

                    <v-divider></v-divider>

                     <v-row class="mx-auto mt-4" style="font-size: 17px;">
                      <p><b>Total</b></p>
                      <v-spacer></v-spacer>
                      <p>
                        <b>{{ doSumTotal.totalAmountAfterTax }}</b>
                      </p>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
        </v-col>

        <v-col cols="12">
          <v-btn
              class="companyButton mt-4 float-right"
              :loading="btnInvoiceSpinner"
              color="#0000ff"
              dark
              type="submit"
          >
          Edit
          </v-btn>
        </v-col>
      </v-row>
    </form>
  <!--      adds items-->
    <v-dialog v-model="add_item_dialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Item</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_item_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-item ref="items_components"></add-item>
        </div>
      </v-card>
    </v-dialog>

  <!--discounts dialog-->
    <v-dialog v-model="addDiscountDialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Discount</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addDiscountDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-discount ref="add_discount_items"></add-discount>
        </div>
      </v-card>
    </v-dialog>

  <!--customer dialog-->
    <v-dialog v-model="addCustomerDialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Customer</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addCustomerDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-profile ref="add_customer_info"></new-profile>
        </div>
      </v-card>
    </v-dialog>

  <!--    adds Storage accounts -->

    <v-dialog v-model="storage_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="storage_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <storage-account ref="storageAccountComponent"></storage-account>
        </div>
      </v-card>
    </v-dialog>

  <!--                    payment methods-->

    <v-dialog v-model="payment_methods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Payment Methods</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payment_methods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <payment-methods ref="paymentMethodsComponents"></payment-methods>
        </div>
      </v-card>
    </v-dialog>

  <!--    adding account dialog-->

    <v-dialog v-model="account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <add-account
              account_type="EXP"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

  <!--tax dialog-->
    <v-dialog v-model="addTaxDialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Tax</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addTaxDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-tax ref="new_tax_component"></new-tax>
        </div>
      </v-card>
    </v-dialog>

  </span>
</template>

<script>
import NewTax from "@/modules/dashboard/Settings/tax/NewTax";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";
import AddAccount from "@/modules/dashboard/accounting/commons/AddAccount";
import PaymentMethods from "@/modules/dashboard/accounting/commons/PaymentMethods";
import StorageAccount from "@/modules/dashboard/accounting/commons/StorageAccount";
import AddDiscount from "@/modules/dashboard/accounting/commons/AddDiscount";
import AddItem from "@/modules/dashboard/accounting/commons/AddItem";
import Constants from "@/global/configs.json";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EditInvoice",
  props: {
    editInfo: String
  },
  components: {
    NewTax,
    NewProfile,
    AddAccount,
    PaymentMethods,
    StorageAccount,
    AddDiscount,
    AddItem
  },
  data() {
    return {
      invoiceItemDataObj: [],
      add_item_dialog: false,
      btnInvoiceSpinner: false,
      addDiscountDialog: false,
      addCustomerDialog: false,
      discountDataObj: [],
      taxItemDataObj: [],
      customerDataList: [],
      formInvoiceErrorMessage: null,
      invoice_number: null,
      absoluteDiscount: false,
      discountListUnfiltered: [],
      type: Constants.CUSTOMER,
      taxListUnfiltered: [],
      incomeAccountDataList: [],
      form: {
        tax_type: "Inclusive",
        number: null,
        ref_no: null,
        invoice_date: new Date().toISOString().substr(0, 10),
        due_date: new Date().toISOString().substr(0, 10),
        notes: null,
        profile_number: null,
        discount: null,
        absolute_discount: null,
        invoice_type: Constants.CUSTOMER,
        bill_of_items: [
          {
            tax_number: "",
            item_number: "",
            quantity: null,
            discount: null,
            absolute_discount: null,
            unit_price: null,
            amount: null,
            account_number: null
          }
        ],
        expense_items: []
      },
      addTaxDialog: false,
      storage_account_dialog: false,
      payment_methods_dialog: false,
      account_dialog: false,
      accountDataItem: [],
      unfilteredInvoiceList: [],
      taxTypeItems: [
        {text: "Inclusive", id: "Inclusive"},
        {text: "Exclusive", id: "Exclusive"},
        {text: "Exempt", id: "Exempt"},
      ],
      showAdditionalItems: false
    };
  },
  mounted() {
    this.fetchItemsList(this.form.invoice_type);
    this.fetchProfiles();
    this.fetchDiscountList();
    if (!this.editInfo) {
      this.getGeneratedInvoiceNumber();
    }

    //  items for expense

    this.doGetAccountData("EXP");

    this.doGetPaymentMethodData();

    this.fetchTaxData();

    this.fetchAccountDataList()

    if (this.$route.query.prof) {
      this.form.profile_number = this.$route.query.prof
    }

  },

  methods: {
    ...mapActions([
      "fetchItemsList",
      "fetchDiscountList",
      "doEditInvoice",
      "fetchProfiles",
      "getGeneratedInvoiceNumber",
      "doGetAccountData",
      "doGetPaymentMethodData",
      "doGetInvoiceInfo",
      "fetchTaxData",
      "fetchAccountDataList"
    ]),
    editInvoice() {
      this.btnInvoiceSpinner = true;
      this.formInvoiceErrorMessage = "";

      this.doEditInvoice(this.form);
    },
    filterStockItemsList(itemsList) {
      let itemJsn = {};
      let itemObj = [{text: "Select Item", id: "", disabled: true}];

      itemsList.forEach(list => {
        let price = list.selling_price.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        itemJsn = {
          text:
              list.item_name + " [" + price + "]",
          id: list.number
        };
        itemObj.push(itemJsn);
      })


      this.invoiceItemDataObj = itemObj;
      this.unfilteredInvoiceList = itemsList;
    },
    addItemData() {
      this.add_item_dialog = true;
    },
    addInvoiceItems() {
      let accountNo = ""
      this.incomeAccountDataList.forEach(accntItm => {
        if (accntItm.text === "Sales Revenue") {
          accountNo = accntItm.id
        }
      })

      this.form.bill_of_items.push({
        tax_number: "",
        item_number: null,
        quantity: null,
        discount: null,
        absolute_discount: null,
        unit_price: null,
        amount: null,
        account_number: accountNo
      });
    },
    addExpenseItems() {
      this.form.expense_items.push({
        name: null,
        amount: null,
        notes: null,
      });
    },
    removesInvoiceItem(index) {
      this.form.bill_of_items.splice(index, 1);
    },
    removesExpenseItem(key) {
      this.form.expense_items.splice(key, 1);
    },
    addDiscountsModel() {
      this.addDiscountDialog = true;
    },
    addCustomerModel() {
      this.addCustomerDialog = true;
    },
    filterDiscountsData(dataList) {
      let dataObj = [{text: "Select discount", id: null, disabled: true}];

      dataList.forEach(item => {
        dataObj.push(
            {
              text: item.name + " ( " + item.rate + "% )",
              id: item.number
            }
        );
      })

      return dataObj;
    },
    filterCustomerList(customerList) {
      let dataObj = [{text: "Select profiles", id: null, disabled: true}];
      let dataJson = {};
      customerList.forEach(list => {
        dataJson = {
          text: list.name,
          id: list.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })


      this.customerDataList = dataObj;
    },
    computeValues(value, index, vModal) {
      if (value) {
        value = value + "";
        value = value.toString().replace(/,/g, "");

        ///this removes any value that starts with a zero
        if (!isNaN(value)) {
          value = value - 0;
        }

        let unitPx = this.form.bill_of_items[index].unit_price
            ? (this.form.bill_of_items[index].unit_price.toString().replace(/,/g, "") * 1)
            : this.form.bill_of_items[index].unit_price;
        let amount = this.form.bill_of_items[index].amount
            ? (this.form.bill_of_items[index].amount.toString().replace(/,/g, "") * 1)
            : this.form.bill_of_items[index].amount;
        let quantity = this.form.bill_of_items[index].quantity
            ? (this.form.bill_of_items[index].quantity.toString().replace(/,/g, "") * 1)
            : this.form.bill_of_items[index].quantity;

        if (vModal === "quantity") {
          this.form.bill_of_items[index].quantity =
              eval(value) >= 0
                  ? eval(value)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : null;

          if (unitPx > 0) {
            this.form.bill_of_items[index].amount = (
                (this.form.bill_of_items[index].quantity.toString().replace(/,/g, "") * 1) *
                (this.form.bill_of_items[index].unit_price.toString().replace(/,/g, "") * 1)
            )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          if (unitPx < 1 && amount > 0) {
            this.form.bill_of_items[index].unit_price = (
                (this.form.bill_of_items[index].amount.toString().replace(/,/g, "") * 1) /
                (this.form.bill_of_items[index].quantity.toString().replace(/,/g, "") * 1)
            )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        } else if (vModal === "unit_price") {
          this.form.bill_of_items[index].unit_price =
              eval(value) >= 0
                  ? eval(value)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : null;

          if (quantity > 0) {
            this.form.bill_of_items[index].amount = (
                (this.form.bill_of_items[index].quantity.toString().replace(/,/g, "") * 1) *
                (this.form.bill_of_items[index].unit_price.toString().replace(/,/g, "") * 1)
            )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          if (quantity < 1 && amount > 0) {
            this.form.bill_of_items[index].quantity = (
                (this.form.bill_of_items[index].amount.toString().replace(/,/g, "") * 1) /
                (this.form.bill_of_items[index].unit_price.toString().replace(/,/g, "") * 1)
            )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        } else if (vModal === "amount") {
          this.form.bill_of_items[index].amount =
              eval(value) >= 0
                  ? eval(value)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : null;

          if (quantity > 0 && unitPx < 1) {
            this.form.bill_of_items[index].unit_price = (
                (this.form.bill_of_items[index].amount.toString().replace(/,/g, "") * 1) /
                (this.form.bill_of_items[index].quantity.toString().replace(/,/g, "") * 1)
            )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          if (unitPx > 0 && quantity < 1) {
            this.form.bill_of_items[index].quantity = (
                (this.form.bill_of_items[index].amount.toString().replace(/,/g, "") * 1) /
                (this.form.bill_of_items[index].unit_price.toString().replace(/,/g, "") * 1)
            )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          if (quantity > 0 && unitPx > 0) {
            this.form.bill_of_items[index].unit_price = (
                (this.form.bill_of_items[index].amount.toString().replace(/,/g, "") * 1) /
                (this.form.bill_of_items[index].quantity.toString().replace(/,/g, "") * 1)
            )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        }
      }
    },
    computeExpense(value, index) {
      if (value) {
        value = value + "";
        value = value.toString().replace(/,/g, "");

        if (!isNaN(value)) {
          value = value - 0;
        }

        this.form.expense_items[index].amount = eval(value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    storageAccountModel() {
      this.storage_account_dialog = true;
    },
    paymentMethodModel() {
      this.payment_methods_dialog = true;
    },
    accountModel() {
      this.account_dialog = true;
    },
    /* invoiceEditDetails() {
      this.doGetInvoiceInfo(this.editInvoiceNumber);
    },*/
    addInvoiceAutoCompute(value, index) {
      let invoiceListInfo = this.unfilteredInvoiceList;
      let quantity = 1;
      let price = 0;
      let tax_no = null;

      if (
          this.form.bill_of_items[index].quantity ||
          this.form.bill_of_items[index].unit_price ||
          this.form.bill_of_items[index].amount
      ) {
        return;
      }

      this.form.bill_of_items[index].unit_price = null;
      this.form.bill_of_items[index].amount = null;
      this.form.bill_of_items[index].quantity = null;
      this.form.bill_of_items[index].tax_number = "";

      for (let i = 0; i < invoiceListInfo.length; i++) {
        if (value === invoiceListInfo[i].number) {
          price = invoiceListInfo[i].selling_price;
          tax_no = invoiceListInfo[i].tax ? invoiceListInfo[i].tax.tax_number : tax_no;
        }
      }
      this.form.bill_of_items[index].tax_number = tax_no

      this.form.bill_of_items[index].quantity = quantity
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.form.bill_of_items[index].unit_price = price
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.form.bill_of_items[index].amount = (price * quantity)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    taxItemFilter(dataList) {
      let arr = [{text: "Select tax", id: "", disabled: true}];
      let obj = {};
      dataList.forEach(list => {
            obj = {
              text: list.name + " (" + list.rate + " %)",
              id: list.number,
            };
            arr.push(obj);
          }
      )
      return arr;
    },
    filterAccountDataLists: function (accountData) {
      let account = accountData;
      let accObj = [{text: "Select item", id: null, disabled: true}];
      let accountJson = {};


      let account_type = "";
      account.forEach((accountItem, index) => {

        let _account_type = "";

        if (account_type !== accountItem.account_type) {
          account_type = accountItem.account_type;
          _account_type = account_type;
        } else {
          _account_type = "";
        }

        if (_account_type) {
          accountJson = {text: _account_type, id: index, disabled: true}
          accObj.push(accountJson);
          accountJson = {divider: !!_account_type}
          accObj.push(accountJson);
        }


        accountJson = {
          text: accountItem.full_name,
          id: accountItem.number,
          disabled: false
        }
        accObj.push(accountJson);
        if (accountItem.full_name === "Sales Revenue") {
          this.form.bill_of_items[0].account_number = accountItem.number
        }

      })


      return accObj;
    },
    showOtherBillExpenses() {
      this.showAdditionalItems = !this.showAdditionalItems
      if (this.showAdditionalItems) {
        this.form.expense_items = [
          {
            number: null,
            name: null,
            amount: null,
            notes: null,
          }
        ]
      } else {
        this.form.expense_items = []
      }
    },
    addTaxModel() {
      this.addTaxDialog = true;
    },
    filterInvoiceData(data) {
      if (data) {
        // alert(JSON.stringify(data))
        this.form.expense_items = ""
        this.form.bill_of_items = ""
        this.invoice_number = data.counter
        this.form.number = data.number ? data.number : null
        this.form.ref_no = data.ref_no ? data.ref_no : null
        this.form.profile_number = data.profile_number ? data.profile_number : null
        this.form.invoice_date = data.invoice_date ? data.invoice_date : null
        this.form.due_date = data.due_date ? data.due_date : null
        this.form.notes = data.notes ? data.notes : null

        let arr = []
        let obj = {}
        if (data.bill_of_items.length > 0) {
          data.bill_of_items.forEach(lst => {
            obj = {
              tax_number: lst.taxes.length > 0 ? lst.taxes[0].number : "",
              item_number: lst.stock_item_number ? lst.stock_item_number : null,
              quantity: lst.quantity ? lst.quantity.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
              discount: lst.discount_ids.length > 0 ? lst.discount_ids[0] : null,
              absolute_discount: lst.absolute_discount ? lst.absolute_discount : null,
              unit_price: lst.unit_price ? lst.unit_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
              amount: lst.total_amount ? lst.total_amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
              // account_number: lst.account_number ? lst.account_number : null,
            }
            arr.push(obj)
          })
          if (arr.length > 0) {
            this.form.bill_of_items = arr
          }
        }


        let arry = []
        let obj2 = {}
        if(data.invoice_expenses.length > 0){
          this.showAdditionalItems = true
          data.invoice_expenses.forEach(list => {
            obj2 =   {
                      number: list.number ? list.number : null,
                      name: list.name ? list.name : null,
                      amount: list.amount ? list.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
                      notes: list.notes ? list.notes : null,
                    }
            arry.push(obj2)
          })
          this.form.expense_items = arry
        }

      }
    }
  },
  computed: {
    ...mapGetters([
      "getStockItemsDataList",
      "getItemData",
      "getDiscountData",
      "getDiscountDataList",
      "getNextInvoiceNumber",
      "getProfileResponseData",
      "getInvoiceError",
      "getCustomerDataInfo",
      "getAccountDataSet",
      "getPaymentMethodDataSet",
      "getNewPaymentMethods",
      "getChartOfAccountsData",
      "getTaxDataAll",
      "getNewInvoiceData",
      "getAccountDataLists",
      "getProfileDataList",
      "getNewServiceItemData",
      "getInventoryPartItem",
      "getInventoryAssemblyItem",
      "getNewTaxData",
      "getInvoiceDataInfo"
    ]),
    doSumTotal() {
      /*------------------------------------------------------------*/
      //this function will calculate the total amount
      let arr = this.form.bill_of_items;
      let sum = 0;
      for (let i = 0; i < arr.length; i++) {
        let quantity = arr[i].quantity
            ? (arr[i].quantity.toString().replace(/,/g, "") * 1)
            : arr[i].quantity;
        let unit_price = arr[i].unit_price
            ? (arr[i].unit_price.toString().replace(/,/g, "") * 1)
            : arr[i].unit_price;
        sum = quantity * unit_price + sum;
      }

      let subTotal = sum
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");


      //----------Taxation-- and  discount calculation-------------------/
      let totalTax = 0
      let percentageTax = 0

      // let taxUnfiltered = this.taxListUnfiltered

      let taxSum = 0

      /*    taxUnfiltered.forEach(itemUnfiltered => {
            let taxUnfiltd = itemUnfiltered.number
            let taxRate = itemUnfiltered.tax_item.rate

            arr.forEach(taxFormItem => {
                  let taxSelectd = taxFormItem.tax_number
                  if (taxUnfiltd === taxSelectd) {
                    // alert(taxUnfiltd +"<==>"+taxSelectd+"<==>"+taxRate)
                    taxSum = taxRate + taxSum
                  }
                }
            )


          })*/

      percentageTax = taxSum
      totalTax = (taxSum / 100) * sum
      // alert(taxSum)


      /*-------------Calculating discounts----------------------------*/


      let discountSum = 0;

      let formDiscounts = this.form.discount;
      let absoluteDiscount = this.form.absolute_discount;

      if (formDiscounts) {
        let discountList = this.discountListUnfiltered;

        for (let a = 0; a < discountList.length; a++) {
          let array_number = discountList[a].number;

          for (let s = 0; s < formDiscounts.length; s++) {
            if (array_number === formDiscounts[s]) {
              //this returns summation of discounts
              discountSum = discountList[a].rate + discountSum;
            }
          }
        }
      } else if (absoluteDiscount) {
        discountSum = (absoluteDiscount / sum) * 100;
      }

      let discountError = null;
      if (discountSum > 100) {
        discountError = "Danger!! discount has exceeded 100%";
      } else {
        discountError = "";
      }

      let computeDiscount = (discountSum / 100) * (sum + totalTax);
      // let computeDiscount = (discountSum / 100) * sum;

      let totalDiscount =
          "- " +
          computeDiscount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let totalAmount = (sum - computeDiscount)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let totalAmountAfterTax = ((sum + totalTax) - computeDiscount)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let percentageDiscount = discountSum.toFixed(2);

      return {
        subTotal: subTotal,
        totalAmount: totalAmount,
        totalDiscount: totalDiscount,
        percentageDiscount: percentageDiscount,
        discountError: discountError,
        totalAmountAfterTax: totalAmountAfterTax,
        totalTax: totalTax,
        percentageTax: percentageTax,
      };
    },
    totalExpenseAmount() {
      let arr = this.form.expense_items;

      //summation of expense
      let sum = 0;
      for (let i = 0; i < arr.length; i++) {
        let amount = arr[i].amount
            ? (arr[i].amount.toString().replace(/,/g, "") * 1)
            : arr[i].amount;
        sum = amount - 0 + sum;
      }

      return sum
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  watch: {
    getStockItemsDataList(itemsList) {
      this.filterStockItemsList(itemsList);
    },
    getItemData(itemsData) {
      if (itemsData) {
        this.add_item_dialog = false;
        this.$refs["items_components"].form = {};
        this.fetchItemsList(this.form.invoice_type);
        this.successToast("Success", itemsData.message);
      }
    },
    getDiscountData(discountData) {
      if (discountData) {
        this.$refs["add_discount_items"].form = {
          name: null,
          rate: null,
          discount_number: null
        };
        this.$refs["add_discount_items"].addDiscountLoader = false;
        this.addDiscountDialog = false;
        this.fetchDiscountList();
        this.successToast("Success", discountData.message);
      }
    },
    getDiscountDataList(discountData) {
      this.discountDataObj = this.filterDiscountsData(discountData);
      this.discountListUnfiltered = discountData;
    },
    getNextInvoiceNumber(numberInfo) {
      this.invoice_number = numberInfo;
    },
    getProfileResponseData(customerData) {
      if (customerData) {
        this.$refs["add_customer_info"].customerSpinner = false;
        this.$refs["add_customer_info"].form = {
          name: null,
          trading_name: null,
          address: null,
          number: null,
          tin: null,
          phoneContact: [
            {
              contact: null,
              id: null,
              type: "phone"
            }
          ],
          emailContact: [
            {
              contact: null,
              id: null,
              type: "email"
            }
          ],
          contactPersons: [
            {
              name: null,
              phone_contacts: [
                {
                  phone: null
                }
              ],
              email_contacts: [
                {
                  email: null
                }
              ],
            }
          ]
        };
        this.addCustomerDialog = false;
        this.successToast("Success", customerData.message);
        this.form.profile_number = customerData.data.number;
        this.fetchProfiles();
      }
    },
    getInvoiceError(errorMessage) {

      if (errorMessage !== null) {

        this.formInvoiceErrorMessage = errorMessage;
        this.btnInvoiceSpinner = false;
        this.errorToast("Error", errorMessage)
        // this.$store.state.invoiceError = "noe"
      }

    },
    getProfileDataList(customerInfo) {
      this.filterCustomerList(customerInfo);
    },
    absoluteDiscount() {
      this.form.absolute_discount = null;
      this.form.discount = null;
    },
    getAccountDataSet(accountData) {
      this.accountDataItem = this.accountFilter(accountData);
    },

    getNewPaymentMethods(paymentData) {
      if (paymentData) {
        this.$refs["paymentMethodsComponents"].form = {};
        this.$refs["paymentMethodsComponents"].addPaymentMethodLoader = false;
        this.payment_methods_dialog = false;
        this.form.payment_method = paymentData.data.number;
        this.successToast("Success", paymentData.message);
        this.doGetPaymentMethodData();
      }
    },
    getChartOfAccountsData(chartData) {
      if (chartData) {
        this.form.account_number = chartData.data.number;

        this.$refs["chart_of_account_components"].chartSpinner = false;
        this.$refs["chart_of_account_components"].form = {};
        this.account_dialog = false;

        this.successToast("Success", chartData.message);

        this.doGetAccountData("EXP");
      }
    },
    getTaxDataAll(taxData) {
      if (taxData) {
        this.taxItemDataObj = this.taxItemFilter(taxData);
        this.taxListUnfiltered = taxData
      }

    },
    getNewInvoiceData(invoiceData) {
      if (invoiceData) {
        this.btnInvoiceSpinner = false
        this.form = {
          number: null,
          ref_no: null,
          invoice_date: new Date().toISOString().substr(0, 10),
          due_date: new Date().toISOString().substr(0, 10),
          notes: null,
          profile_number: null,
          discount: null,
          absolute_discount: null,
          invoice_type: Constants.CUSTOMER,
          bill_of_items: [
            {
              tax_number: "",
              item_number: null,
              quantity: null,
              discount: null,
              absolute_discount: null,
              unit_price: null,
              amount: null,
              account_number: null
            }
          ],
          expense_items: [
            {
              number: null,
              name: null,
              amount: null,
              notes: null,
            }
          ]
        }
        this.successToast("Success", invoiceData.message)
      }
    },
    getAccountDataLists(accountData) {
      this.incomeAccountDataList = this.filterAccountDataLists(accountData);
    },
    getNewServiceItemData(data) {
      if (data) {
        this.add_item_dialog = false
        this.fetchItemsList(this.form.invoice_type);
      }
    },
    getInventoryPartItem(data) {
      if (data) {
        this.add_item_dialog = false
        this.fetchItemsList(this.form.invoice_type);
      }
    },
    getInventoryAssemblyItem(data) {
      if (data) {
        this.add_item_dialog = false
        this.fetchItemsList(this.form.invoice_type);
      }
    },
    getNewTaxData(data) {
      if (data) {
        this.$refs["new_tax_component"].form = {
          name:null,
          rate:null,
          profile_number:null,
          is_compound:false,
          is_absolute:false
        };
        this.$refs["new_tax_component"].taxSpinner = false;
        this.addTaxDialog = false;
        this.fetchTaxData();
        this.successToast("Success", data.message);
      }
    },
    getInvoiceDataInfo(data) {
      if (data) {
        this.filterInvoiceData(data)
      }
    }
  }
}
</script>

<style scoped>

</style>