<template>
<span>

  <v-card flat>
                <v-row class="mx-auto mt-8">
                  <v-col cols="8">

                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                        class="mr-3"
                        v-model="search"
                        v-on:keyup.enter="doSearch"
                        append-icon="mdi-magnify"
                        label="Search"
                        dense
                        outlined
                        hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-data-table
                    :headers="headers"
                    :items="salesItemDataObj"
                    :search="search"
                    :loading="tableLoader"
                    loading-text="Loading... please wait"
                    item-key="name"
                    class="elevation-1"
                    :page="page"
                    :items-per-page="items_per_page"
                    :server-items-length="server_items_length"
                    :options.sync="paginationMovement"
                >
<!--                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editExpense(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteExpense(item)">
                      mdi-delete
                    </v-icon>
                  </template>-->
                </v-data-table>
              </v-card>

</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "StockMovementTable",
  data(){
    return{


      stockTableData: [],
      search: "",
      tableLoader: false,
      salesItemDataObj: [],
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Date", value: "date_of_sale" },
        { text: "Item", value: "item_name" },
        { text: "Unit", value: "unit_name" },
        { text: "Quantity", value: "quantity" },
        // { text: "Action", value: "actions", sortable: false, width: "7%" }
      ],
      json_fields: {},
      page: 1,
      items_per_page: 20,
      server_items_length: 0,
      paginationMovement: {}


    }
  },
  methods:{
    ...mapActions(
        [
            "doGetStockMovementList"
        ]
    ),
    filterStockMovements(salesDataObj) {
      let stockData = salesDataObj.data;

      let current_page = salesDataObj.current_page;
      this.page = current_page;
      let per_page = salesDataObj.per_page;
      this.items_per_page = per_page * 1;
      this.server_items_length = salesDataObj.total;

      this.tableLoader = false;

      let salesJsn = {};
      let salesObj = [];


      stockData.forEach((stockItm, i) =>{
        salesJsn = {
          key: (current_page - 1) * per_page + i + 1,
          date_of_sale: stockItm.date,
          item_name: stockItm.item_name,
          unit_name: stockItm.unit_name,
          quantity: stockItm.quantity
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),


        };
        salesObj.push(salesJsn);

      })

      this.salesItemDataObj = salesObj;
    },
    doSearch() {
      this.tableLoader = true;
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search ? this.search : ""
      };
      this.doGetStockMovementList(options);
    },
  },
  computed:{
    ...mapGetters(
        [
            "getStockMovement"
        ]
    )
  },
  watch:{
    paginationMovement(obj) {
      this.tableLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        search: this.search ? this.search : ""
      };

      this.doGetStockMovementList(options);
    },
    getStockMovement(stockMovData){
      if(stockMovData){
        this.filterStockMovements(stockMovData);
      }
    }

  }
}
</script>

<style scoped>

</style>