<template>
  <span>
    <v-alert type="error" v-if="expenseError">
      {{ expenseError }}
    </v-alert>

    <form ref="form" @submit.prevent="saveTransaction">
      <v-row>
        <v-col cols="6">
          <v-autocomplete
                  v-if="account_type === 'CAST' || account_type === 'FAST'"
                  outlined
                  v-model="form.profile_number"
                  :items="supplierDataList"
                  item-value="id"
                  color="#1976d2"
                  label="Supplier"
                  dense
                  clearable
                  append-outer-icon="mdi-plus"
                  @click:append-outer="addSupplierModel"
          ></v-autocomplete>

          <v-text-field
                  v-model="form.name"
                  label="Name"
                  outlined
                  dense
                  required
          ></v-text-field>

          <v-text-field
                  v-model="form.amount"
                  label="Amount *"
                  outlined
                  dense
                  required
                  @keypress="onlyNumberValidator($event)"
                  v-on:blur="compute(form.amount)"
          ></v-text-field>

           <v-text-field
               v-model="form.date"
               label="Date [ dd / mm / yyy ]"
               outlined
               dense
               type="date"
               required
               hide-details
           ></v-text-field>
          <small style="color: red;" v-if="yearLengthValidator(form.date)">
                Invalid Date
              </small>
          <v-textarea
              class="mt-3"
                  v-model="form.notes"
                  label="Memo"
                  outlined
                  rows="3"
                  dense
          ></v-textarea>


        </v-col>

        <v-col cols="6">
          <v-autocomplete
                  outlined
                  v-model="form.account_number"
                  :items="accountDataItem"
                  item-value="id"
                  color="pink"
                  label="Category *"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="accountModel"
                  dense
          ></v-autocomplete>

          <v-autocomplete
                  outlined
                  v-model="form.storage_account_number"
                  :items="storageAccountDataList"
                  item-value="id"
                  color="pink"
                  label="Bank/Cash Account *"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="storageAccountModel"
                  dense
          ></v-autocomplete>

          <v-autocomplete
                  outlined
                  v-model="form.payment_method_number"
                  :items="paymentMethodDataList"
                  item-value="id"
                  color="pink"
                  label="Payment Method"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="paymentMethodModel"
                  dense
                  hide-details
          ></v-autocomplete>

          <v-row>
            <v-col cols="6">
                <v-autocomplete
                    outlined
                    v-model="form.sale_user_number"
                    :items="staffDataObj"
                    item-value="id"
                    color="pink"
                    :label="staffLabel"
                    append-outer-icon="mdi-plus"
                    @click:append-outer="addNewStaffProfileData"
                    dense
                    clearable
                    hide-details
                ></v-autocomplete>
            </v-col>
            <v-col cols="6">
               <v-autocomplete
                   outlined
                   clearable
                   v-model="form.profile_number"
                   :items="supplierDataList"
                   item-value="id"
                   color="pink"
                   :label="profileLabel"
                   append-outer-icon="mdi-plus"
                   @click:append-outer="addSupplierModel"
                   dense
                   hide-details
               ></v-autocomplete>
            </v-col>
          </v-row>


          <v-text-field
                  v-model="form.reference"
                  label="Reference"
                  outlined
                  dense
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="text-right">
        <v-btn
                class="companyButton"
                :loading="transactionLoader"
                color="#0000ff"
                dark
                type="submit"
        >
          {{ transactionEditData ? "Edit" : "save" }}
        </v-btn>
      </div>
    </form>

      <!--    adds Storage accounts -->

    <v-dialog v-model="storage_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="storage_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <storage-account ref="storageAccountComponent"></storage-account>
        </div>
      </v-card>
    </v-dialog>

      <!--                    payment methods-->

    <v-dialog v-model="payment_methods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Payment Methods</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payment_methods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <payment-methods ref="paymentMethodsComponents"></payment-methods>
        </div>
      </v-card>
    </v-dialog>

      <!--    adding account dialog-->

    <v-dialog v-model="account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
                  :account_type="account_type"
                  ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

      <!--supplier dialog-->
    <v-dialog v-model="addSupplierDialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Profile</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addSupplierDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
           <new-profile  ref="add_customer_info"></new-profile>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="new_staff_dialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Staff</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="new_staff_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-user ref="users_components"></new-user>
        </div>
      </v-card>
    </v-dialog>

      <!--                    end dialog-->
  </span>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import AddAccount from "./AddAccount";
import PaymentMethods from "./PaymentMethods";
import StorageAccount from "./StorageAccount";
import NewUser from "@/modules/dashboard/Settings/Users/NewUser";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";

export default {
    name: "AddTransaction",
    components: {NewProfile, NewUser, StorageAccount, PaymentMethods, AddAccount},
    props: {
        account_type: String,
        transactionEditData: {}
    },
    data() {
        return {
            expenseError: false,
            storage_account_dialog: false,
            payment_methods_dialog: false,
            new_staff_dialog: false,
            transactionLoader: false,
            accountDataItem: [],
            storageAccountDataList: [],
            paymentMethodDataList: [],
            addStorageLoader: false,
            addPaymentMethodLoader: false,
            account_dialog: false,
            supplierDataObj: [],
            staffLabel: null,
          profileLabel: null,
            form: {
                name: null,
                amount: null,
                notes: null,
                date: new Date().toISOString().substr(0, 10),
                storage_account_number: null,
                account_number: null,
                payment_method_number: null,
                profile_number: null,
                reference: null,
                sale_user_number: null
            },
            payment_methods_form: {
                name: null,
                notes: null
            },
            payment_method_items: [
                {text: "Select Payment Method", value: null},
                {text: "Cash", value: "cash"},
                {text: "Mobile Money", value: "mobile_money"},
                {text: "Cheque", value: "cheque"},
                {text: "Credit Cards", value: "credit_cards"},
                {text: "Electronic bank transfer", value: "electronic_bank_transfer"}
            ],
            addSupplierDialog: false,
            supplierDataList: [],
            staffDataObj: []
        };
    },
    mounted() {

        this.doGetAccountData(this.account_type);
        this.doGetStorageAccountData();
        this.doGetPaymentMethodData();

        if (this.transactionEditData) {
            this.transactionLoader = false;
            this.getTransactionInfo();
        }


        this.staffLabel = this.account_type === "INC" ? "Received by" : this.account_type === "EXP" ? "Spent by" : this.account_type === "CLBT" ? "Procured by" : ""
        this.profileLabel = this.account_type === "INC" ? "Received From" : this.account_type === "EXP" ? "Taken by" : this.account_type === "CLBT" ? "Procured by" : ""

        this.fetchProfiles();
        this.fetchStaffProfiles();
    },
    methods: {
        ...mapActions([
            "doGetAccountData",
            "doGetStorageAccountData",
            "doSaveTransaction",
            "saveNewPaymentMethod",
            "doGetPaymentMethodData",
            "doFetchTransactionInfo",
            "fetchProfiles",
            "fetchStaffProfiles"
        ]),
        saveTransaction() {
            this.expenseError = null;
            this.transactionLoader = true;
            let formData = this.form;
            formData.amount = this.form.amount.toString().replace(/,/g, "")
            formData.ref_code = this.account_type;
            if (this.transactionEditData) {
                formData.number = this.transactionEditData.number;
            }
            this.doSaveTransaction(formData);
        },
        storageAccountModel() {
            this.storage_account_dialog = true;
        },
        paymentMethodModel() {
            this.payment_methods_dialog = true;
        },
        addNewStaffProfileData() {
            this.new_staff_dialog = true;
        },
        accountModel() {
            this.account_dialog = true;
        },
        compute(value) {
          value = value.toString().replace(/,/g, "")
          if (!isNaN(value)) {
            value = value - 0;
          }
          this.form.amount = eval(value) >= 0 ? eval(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
        },
        getTransactionInfo() {
            this.doFetchTransactionInfo(this.transactionEditData.number);
        },
        addSupplierModel() {
            this.addSupplierDialog = true;
        },
        filterProfilesList(profileList) {
            let dataObj = [{text: "Select profile", id: null, disabled: true}];
            let dataJson = {};
            profileList.forEach(list => {
                dataJson = {
                    text: list.name,
                    id: list.number,
                    disabled: false
                };
                dataObj.push(dataJson);
            })
            this.supplierDataList = dataObj;
        }

    },
    computed: {
        ...mapGetters([
            "getAccountDataSet",
            "getStorageAccountDataSet",
            "getNewStorageAccountData",
            "getNewPaymentMethods",
            "getPaymentMethodDataSet",
            "getChartOfAccountsData",
            "getTransactionErrorMessage",
            "getTransactionInfoData",
            "getProfileDataList",
            "getProfileResponseData",
            "getStaffProfileDataList"
        ])
    },
    watch: {
        getProfileDataList(profileDataList) {
            this.filterProfilesList(profileDataList);
        },
        getStaffProfileDataList(staffDataList) {
            this.staffDataObj = this.filterStaffProfilesList(staffDataList);
        },
        getAccountDataSet(accountData) {
            this.accountDataItem = this.accountFilter(accountData);
        },
        getStorageAccountDataSet(storageAccountData) {
            this.storageAccountDataList = this.nameNumberFilter(storageAccountData);
        },
        getPaymentMethodDataSet(paymentData) {
            this.paymentMethodDataList = this.nameNumberFilter(paymentData);
        },
        getNewStorageAccountData(storageAccountData) {
            if (storageAccountData) {
                this.$refs["storageAccountComponent"].form = {
                                                          name: null,
                                                          notes: null,
                                                          supper_account: null,
                                                          number: null
                                                        };
                this.$refs["storageAccountComponent"].addStorageLoader = false;
                this.storage_account_dialog = false;
                this.form.storage_account_number = storageAccountData.data.number;

                this.successToast("Success", storageAccountData.message);
                this.doGetStorageAccountData(this.$company.number);
            }
        },
        getNewPaymentMethods(paymentData) {
            if (paymentData) {
                this.$refs["paymentMethodsComponents"].form = {
                    name: null,
                    notes: null
                };
                this.$refs["paymentMethodsComponents"].addPaymentMethodLoader = false;
                this.payment_methods_dialog = false;
                this.form.payment_method_number = paymentData.data.number;
                this.successToast("Success", paymentData.message);
                this.doGetPaymentMethodData();
            }
        },
        getChartOfAccountsData(chartData) {
            if (chartData) {
                this.form.account_number = chartData.data.number;

                this.$refs["chart_of_account_components"].chartSpinner = false;
                this.$refs["chart_of_account_components"].form = {};
                this.account_dialog = false;

                this.successToast("Success", chartData.message);

                this.doGetAccountData(this.account_type);
            }
        },
        getTransactionErrorMessage(transactionError) {
            if (transactionError) {
                this.transactionLoader = false;
                this.expenseError = transactionError.message;
                this.$refs.paymentMethodsComponents.addPaymentMethodLoader = false
            }
        },
        transactionEditData() {
            this.getTransactionInfo();
        },
        getTransactionInfoData(transactionData) {
            if (transactionData) {
                this.form.name = transactionData.name ? transactionData.name : "";
                this.form.amount = transactionData.amount ?
                    transactionData.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
                this.form.notes = transactionData.notes ? transactionData.notes : "";
                this.form.date = transactionData.date ? transactionData.date : "";
                this.form.account_number = transactionData.account_number ? transactionData.account_number : "";
                this.form.storage_account_number = transactionData.storage_account_number ? transactionData.storage_account_number : "";
                this.form.payment_method_number = transactionData.payment_method_number ? transactionData.payment_method_number : "";
                this.form.reference = transactionData.reference ? transactionData.reference : "";
                this.form.profile_number = transactionData.profile_number ? transactionData.profile_number : "";
                this.form.sale_user_number = transactionData.sale_user_number ? transactionData.sale_user_number : "";
            }
        },
        getProfileResponseData(supplierData) {
            if (supplierData) {
              this.$refs["add_customer_info"].customerSpinner = false;
              this.$refs["add_customer_info"].form = {
                name: null,
                trading_name: null,
                address: null,
                number: null,
                tin: null,
                phoneContact: [
                  {
                    contact: null,
                    id: null,
                    type: "phone"
                  }
                ],
                emailContact: [
                  {
                    contact: null,
                    id: null,
                    type: "email"
                  }
                ],
                contactPersons: [
                  {
                    name: null,
                    phone_contacts: [
                      {
                        phone: null
                      }
                    ],
                    email_contacts: [
                      {
                        email: null
                      }
                    ],
                  }
                ]
              };
              this.addSupplierDialog = false;
              this.successToast("Success", supplierData.message);
              this.form.profile_number = supplierData.data.number;
              this.fetchProfiles();


            }
        }
    }
};
</script>

<style scoped></style>
