<template>
<span>

  <v-row>
     <v-col cols="2">
          <v-select
              outlined
              v-model="sale_type"
              :items="paymentTypeObj"
              item-value="id"
              color="pink"
              label="Sell by"
              required
              hide-details
              dense
          ></v-select>
        </v-col>
  </v-row>

<!--sale is made only on cash and gift-->
 <make-sale v-if="sale_type !== 'Credit'" :sale_type="sale_type" ref="salesComponent"></make-sale>

<!--  and invoice is given out when sale is made on credit-->
  <new-invoice v-if="sale_type === 'Credit'" ref="invoice_items"></new-invoice>


</span>
</template>

<script>
import MakeSale from "@/modules/dashboard/accounting/commons/MakeSale";
import {mapGetters} from "vuex";
import NewInvoice from "@/modules/dashboard/accounting/commons/NewInvoice";
export default {
  name: "SalesOption",
  components: {NewInvoice, MakeSale},
  data(){
    return{
      sale_type:"Cash",
      paymentTypeObj: [
        {
          text: "Cash",
          id: "Cash"
        },
        {
          text: "Credit",
          id: "Credit"
        },
        {
          text: "Gift",
          id: "Gift"
        }
      ]
    }
  },
  computed: {
    ...mapGetters(
        [
          "getSales",
          "getNewSaleData"
        ]
    )
  },
  watch:{
    getNewSaleData(salesDataRes) {
      if (salesDataRes) {
        this.successToast("Success", salesDataRes.message);
        this.$refs["salesComponent"].salesSpinner = false;
        this.$refs["salesComponent"].form = {
          tax_type:"Inclusive",
          ref_no: null,
          due_date: new Date().toISOString().substr(0, 10),
          profile_number: null,
          sale_type: null,
          notes: null,
          discount: null,
          absolute_discount: null,
          invoice_date: new Date().toISOString().substr(0, 10),
          bill_of_items: [
            {
              tax_number: null,
              discount: null,
              absolute_discount: null,
              total_amount: null,
              unit_price: null,
              item_number: "",
              quantity: null,
              taxAmount: 0,
              amount: null,
            }
          ],
          expense_items: [],
          payment_method_number: null,
          storage_account_number: null
                        };


      }
    },
  }
}
</script>

<style scoped>

</style>