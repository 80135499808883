var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-app-bar-nav-icon'),_c('v-toolbar-title',[_vm._v("Bank Accounts ")])],1),_c('v-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{attrs:{"href":"#storage"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v(" All Bank Accounts ")],1),(_vm.checkLicenseStatus())?_c('v-tab',{attrs:{"href":"#new-storage"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-lock")]),_vm._v(" New Account ")],1):_vm._e(),_c('v-tab-item',{attrs:{"id":"storage"}},[_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mx-auto mt-8"},[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.storage_account_headers,"items":_vm.storageAccountTableData,"search":_vm.search,"loading":_vm.storageAccountStockLoader,"loading-text":"Loading... please wait","item-key":"name","page":_vm.page,"items-per-page":_vm.items_per_page,"server-items-length":_vm.server_items_length,"options":_vm.paginationStorageAccounts},on:{"update:options":function($event){_vm.paginationStorageAccounts=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"storageAccountName",attrs:{"to":{
                        name: 'bank-statement',
                        params: { id: item.name },
                        query: { id: item.number }
                      }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editStorageAccount(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteStorageAccount(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-tab-item',{attrs:{"id":"new-storage"}},[_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-15 ml-6",attrs:{"cols":"5"}},[_c('p',{staticStyle:{"font-size":"20px"}},[_vm._v("Add Bank/Cash Account")]),_c('storage-account',{ref:"storage_accounts_components"})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"40%"},model:{value:(_vm.editStorageAccountDialog),callback:function ($$v) {_vm.editStorageAccountDialog=$$v},expression:"editStorageAccountDialog"}},[_c('v-card',[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"95%","padding-bottom":"20px"}},[_c('v-row',[_c('v-card-title',[_vm._v("Edit Bank/Cash Account")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.editStorageAccountDialog = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('storage-account',{ref:"storage_accounts_components",attrs:{"editStorageAccountObj":_vm.editStorageAccountObj}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }