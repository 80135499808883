import axios from "axios";
import configs from "../../../../global/configs.json";
import abilities from "../../../../global/casl/abilities";

const IP = configs.SERVER_IP;

const state = {
  companyData: null,
  companyTypes: null,
  statusResponse: "error",
  companyErrorMessage: null,
  companyUpdate: null
};

const getters = {
  getCompanyData: state => state.companyData,
  getCompanyTypes: state => state.companyTypes,
  getStatusResponse: state => state.statusResponse,
  getCompanyUpdate: state => state.companyUpdate,
  getCompanyErrorMessage: state => state.companyErrorMessage
};

const actions = {
  async doSaveCompanyData({ commit }, data) {
    await axios
      .post(IP + "company/register", data)
      .then(response => {
        commit("setCompanyData", response.data);
      })
      .catch(error => {
        commit("setCompanyErrorMessage", error);
      });
  },
  async doGetCompanyTypes({ commit }, data) {
    await axios
      .get(IP + "company/company-types", data)
      .then(response => {
        commit("setCompanyTypes", response);
      })
      .catch(error => {
        commit("setCompanyErrorMessage", error);
      });
  },
  async updateCompanyData({ commit }, data) {
    await axios
      .post(IP + "company/update/"+data.company_number, data.formData)
      .then(response => {
        commit("setCompanyUpdate", response);
      })
      .catch(error => {
        commit("setCompanyErrorMessage", error);
      });
  },
  async doGetCompanyProfile({ commit }, company_number) {
    await axios
      .get(IP + "company/details/" + company_number, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
      .then(response => {
        commit("setCompanyData", response);
      })
      .catch(error => {
        commit("setCompanyErrorMessage", error);
      });
  }
};

const mutations = {
  setCompanyData: (state, response) => {
    state.statusResponse = "success";
    state.companyData = response.data;
    localStorage.setItem("company", JSON.stringify(response.data));
    localStorage.setItem(
      "abilities",
      JSON.stringify([{ action: "manage", subject: "all" }])
    );
    abilities.update([{ action: "manage", subject: "all" }]);
  },

  setCompanyUpdate: (state, response) => {
    state.companyUpdate = response.data;
  },
  setCompanyErrorMessage: (state, error) => {
    state.statusResponse = "error";
    state.companyErrorMessage = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  },
  setCompanyTypes: (state, response) => {
    state.statusResponse = "success";
    state.companyTypes = response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
