<template>
  <span>

          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Tax</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-tabs>
            <v-tab href="#stock">
<!--              <v-icon left>mdi-account</v-icon>-->
              Tax Details
            </v-tab>

            <v-tab  v-if="checkLicenseStatus()" href="#new-stock" @click="newTaxClear">
<!--              <v-icon left>mdi-lock</v-icon>-->
              New Tax
            </v-tab>


            <v-tab-item id="stock">
              <v-divider></v-divider>
              <v-card flat>
                <v-row class="mx-auto mt-8">
                  <v-col cols="8">
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        class="mr-3"
                        dense
                        hide-details
                        label="Search"
                        outlined
                    ></v-text-field>
                  </v-col>
                </v-row>


               <v-data-table
                   :headers="headers"
                   :items="taxTableData"
                   :loading="tableLoader"
                   :search="search"
                   class="elevation-1"
                   item-key="name"
                   loading-text="Loading... please wait"
               >
      <template v-slot:item.compound="{ item }">
         <v-chip
             small
             class="ma-2"
             :color="item.compound === 'Yes' ? 'success' : 'purple darken-3'"
             outlined
         >
      {{item.compound}}
    </v-chip>

      </template>

                  <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" small @click="editTax(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteTax(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item id="new-stock">
              <v-divider></v-divider>

              <v-card flat>
                <div
                    class="mt-8 mx-auto"
                    style="width: 98%; padding-bottom: 15px;"
                >

                   <v-row class="mx-auto">

                   <v-col class="mx-auto" cols="6">

                     <new-tax ref="new_tax_component"></new-tax>

                   </v-col>
                   </v-row>

                </div>
              </v-card>
            </v-tab-item>

          </v-tabs>



      <v-dialog v-model="editTaxDialog" width="40%">
      <v-card>
        <div class="mx-auto" style="width: 95%; padding-bottom: 20px;">
          <v-row>
            <v-card-title>Edit Tax</v-card-title>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="editTaxDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-tax
              ref="edit_tax_component"
              :taxNumber="taxNumber"
          ></new-tax>
        </div>
      </v-card>
    </v-dialog>


  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewTax from "@/modules/dashboard/Settings/tax/NewTax";

export default {
  name: "Tax",
  components: {NewTax},
  data() {
    return {
      taxTableData: [],
      search: "",
      tableLoader: false,
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        {text: "Name", value: "name"},
        {text: "Rate", value: "rate"},
        {text: "Vendor", value: "vendor"},
        {text: "Compound", value: "compound"},
        {text: "Action", value: "actions", sortable: false, width: "10%"}
      ],
      editTaxDialog: false,
      taxNumber: null,
    };
  },
  mounted() {
    this.tableLoader = true
    this.fetchTaxData();
  },
  methods: {
    ...mapActions(
        [
          "fetchTaxData",
          "deleteTaxInfo"
        ]
    ),
    filterTaxData(taxData) {
      this.tableTransactionLoader = false;

      this.tableLoader = false;
      let Jsn = {};
      let Obj = [];

      taxData.forEach((list, i) => {
        Jsn = {
          key: i + 1,
          name: list.name ? list.name : null,
          rate: (list.rate && !list.is_absolute) ? list.rate+"%" : list.rate.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          number: list.number ? list.number : null,
          compound: list.is_compound ? 'Yes' : 'No',
          vendor: list.profile ? list.profile.name : null,
        };
        Obj.push(Jsn);
      })

      this.taxTableData = Obj;

    },
    editTax(item) {
      this.taxNumber = item.number
      this.editTaxDialog = true
    },
    deleteTax(item) {
      let confirm_delete = confirm(
          "Are you sure you want to delete this tax item."
      );
      if (!confirm_delete) {
        return false;
      }
      this.deleteTaxInfo(item.number)
    },
    newTaxClear(){
      this.$refs["new_tax_component"].taxNo = ""
      this.$refs["new_tax_component"].form = {
        name:null,
        rate:null,
        profile_number:null,
        is_compound:false,
        is_absolute:false
      }
    }
  },
  computed: {
    ...mapGetters(
        [
          "getNewTaxData",
          "getTaxDataAll",
          "getDeleteTaxData",
            "getUpdateTaxData"
        ]
    )
  },
  watch: {
    getNewTaxData(data) {
      if (data) {
        this.$refs["new_tax_component"].taxSpinner = false
        this.$refs["new_tax_component"].form = {
          name:null,
          rate:null,
          profile_number:null,
          is_compound:false,
          is_absolute:false
        }
        this.successToast("Success", data.message)
        this.tableLoader = true
        this.fetchTaxData();
      }
    },
    getTaxDataAll(data) {
      if (data) {
        this.filterTaxData(data)
      }
    },
    getDeleteTaxData(data) {
      if (data) {
        this.fetchTaxData();
        this.successToast("Success", data.message)
      }
    },
    getUpdateTaxData(data){
      if(data){
        this.$refs["edit_tax_component"].taxSpinner = false
        this.editTaxDialog = false
        this.fetchTaxData();
        this.successToast("Success", data.message)
      }
    }
  }
}
</script>

<style scoped>

</style>