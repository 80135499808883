<template>
  <span style="background-color: #1976d2;">

    <v-row class="mx-auto">
      <v-spacer></v-spacer>

    </v-row>
  <v-card flat style="width: 90%;" class="mx-auto">
    <v-card-text>
      <v-toolbar dense flat>
        <v-toolbar-title>Register Company</v-toolbar-title>
        <v-spacer></v-spacer>
        <div>Step {{ step }}/3</div>
      </v-toolbar>

  <v-divider></v-divider>

      <v-alert type="error" class="mt-4" dense v-if="responseMessage">
        {{ responseMessage }}
      </v-alert>

      <form ref="form">


        <v-card flat class="mx-auto">

<div style="width: 94%" class="mx-auto">

<!-- step one-->
  <div v-if="step === 1">

<div class="text-center mt-4 mb-7" style="font-size: 15px;">Company Info</div>

      <v-text-field
        v-model="form.company_name"
        label="Business Name *"
        outlined
        dense
        required
      ></v-text-field>

      <v-text-field
        v-model="form.legal_name"
        label="Legal Name *"
        outlined
        dense
        required
      ></v-text-field>


  <v-select
    v-model="form.company_type_id"
    :items="getCompanyTypes"
    item-value="id"
    item-text="name"
    label="Business Type *"
    outlined
    required
    dense
  ></v-select>


    <!--     <v-divider></v-divider>-->

        <v-row class="mx-auto mt-5">
          <v-spacer></v-spacer>
          <v-btn
            type="button"
            class="companyButton"
            color="#0000ff"
            :loading="companySpinner"
            dark
            @click="nextTab(2, 'step1')"
          >
            Next Step
          </v-btn>
        </v-row>


  </div>


  <!--  step two-->

  <div v-if="step === 2">

    <div class="text-center mt-4 mb-7" style="font-size: 15px;">Address</div>

     <v-textarea
       v-model="form.company_address"
       label="Location *"
       outlined
       rows="1"
       dense
       required
     ></v-textarea>


            <v-text-field
              v-model="form.company_phone"
              label="Business Phone *"
              outlined
              type="tel"
              maxlength="12"
              @keypress="onlyNumberValidator($event)"
              dense
              required
            ></v-text-field>

            <v-text-field
              v-model="form.company_email"
              label="Business Email *"
              outlined
              dense
            ></v-text-field>


  <v-text-field
    v-model="form.company_website"
    label="Business Website"
    outlined

    dense
  ></v-text-field>


    <!--     <v-divider></v-divider>-->

        <v-row class="mx-auto mt-5">
            <v-btn @click="nextTab(1,'back')" type="button" color="#0000ff" outlined>
         Back
        </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="companyButton"
            color="#0000ff"
            :loading="companySpinner"
            dark
            type="button"
            @click="nextTab(3, 'step2')"
          >
            Next Step
          </v-btn>
        </v-row>


  </div>


  <!--  step 3-->
  <div v-if="step === 3">

    <div class="text-center mt-4 mb-7" style="font-size: 15px;">Company Financial period</div>

      <v-text-field
        v-model="form.company_tin"
        label="Company Tin"
        outlined
        dense
        hide-details
      ></v-text-field>



  <v-row class="mt-4">
    <v-col cols="12" sm="12" md="12" lg="12">
      <b>Your financial year starts on ?</b>
      <v-select
        v-model="form.financial_start_day"
        :items="daysList"
        item-value="id"
        item-text="name"
        label="Day"
        outlined
        required
        dense
        hide-details
      ></v-select>
    </v-col>

    <v-col cols="12" sm="12" md="12" lg="12">
      <v-select
        v-model="form.financial_start_month"
        :items="monthList"
        item-value="id"
        item-text="text"
        label="Month"
        outlined
        required
        dense
        hide-details
        v-on:change="fetchMonthDays(form.financial_start_month)"
      ></v-select>
    </v-col>

  </v-row>


    <!--     <v-divider class="mt-4"></v-divider>-->

        <v-row class="mx-auto mt-7">
             <v-btn @click="nextTab(2, 'back')" type="button" color="#0000ff" outlined>
         Back
        </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="companyButton"
            color="#0000ff"
            :loading="companySpinner"
            dark
            type="button"
            @click="saveCompanyData"
          >
            Finish
          </v-btn>
        </v-row>


  </div>






</div>


        </v-card>


      </form>
    </v-card-text>
  </v-card>

     <div class="mx-auto mt-3" style="width: 80%;">
            <p class="float-right" style="cursor: pointer; color: #0000ff;" @click="doLogout">Logout</p>
          </div>

  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Company",
  data() {
    return {
      companySpinner: false,
      step: 1,
      form: {
        company_name: null,
        legal_name: null,
        company_address: null,
        company_phone: null,
        company_email: null,
        company_website: null,
        company_tin: null,
        company_type_id: null,
        financial_start_day: 1,
        financial_start_month: 1
      },
      financial_end_day: null,
      financial_end_month: null,
      responseMessage: null,
      userProfile: null,
      user: this.$userProfile,
      daysList: [],
      monthList: []
    };
  },
  mounted() {
    this.doGetCompanyTypes();


    this.monthList = [
      { id: 1, text: "January" },
      { id: 2, text: "February" },
      { id: 3, text: "March" },
      { id: 4, text: "April" },
      { id: 5, text: "May" },
      { id: 6, text: "June" },
      { id: 7, text: "July" },
      { id: 8, text: "August" },
      { id: 9, text: "September" },
      { id: 10, text: "October" },
      { id: 11, text: "November" },
      { id: 12, text: "December" }
    ];

    this.fetchMonthDays(1);

  },
  methods: {
    ...mapActions([
      "doSaveCompanyData",
      "doGetCompanyTypes",
      "doGetCompanyProfile"
    ]),
    nextTab(data, step) {

      if (!this.validateStep1(step) && step === "step1") {
        this.step = data;
      }

      if (!this.validateStep2(step) && step === "step2") {
        this.step = data;
      }
      if (step === "back") {
        this.step = data;
      }

    },
    validateStep1(step) {
      if (step && step === "step1") {
        if (!this.form.company_name) {
          this.errorToast("Error", "Company name is required!!");
          return "error";
        }
        if (!this.form.legal_name) {
          this.errorToast("Error", "Company legal name is required!!");
          return "error";
        }
        if (!this.form.company_type_id) {
          this.errorToast("Error", "Select Company type!!");
          return "error";
        }
        return null;
      }

    },

    validateStep2(step) {
      if (step && step === "step2") {
        if (!this.form.company_address) {
          this.errorToast("Error", "Company address is required!!");
          return "error";
        }
        if (!this.form.company_phone) {
          this.errorToast("Error", "Company phone is required!!");
          return "error";
        }
        if (!this.form.company_email) {
          this.errorToast("Error", "Company email is required!!");
          return "error";
        }
        /*if(!this.form.company_website){
          this.errorToast("Error", "Company website is required!!")
          return  "error"
          }*/
        return null;
      }


    },
    saveCompanyData() {
      this.companySpinner = true;
      this.form.user_number = this.$userProfile.number;

      this.doSaveCompanyData(this.form);
    },
    fetchMonthDays(month) {

      //alert(this.getDaysInMonth(month))

      this.daysList = [];
      for (let i = 1; i <= this.getDaysInMonth(month); i++) {
        this.daysList.push(i);
      }

    },
    getDaysInMonth(month) {
      const date = new Date();
      const year = date.getFullYear();
      return new Date(year, month, 0).getDate();
    }
  },
  computed: {
    ...mapGetters([
      "getCompanyData",
      "getStatusResponse",
      "getCompanyErrorMessage",
      "getCompanyTypes"
    ])
  },
  watch: {
    /* form:({
         handler(data){
             if (data.financial_start_month){
                 if (data.financial_start_month == 12){
                     this.financial_end_month = 1
                 } else {
                     this.financial_end_month += this.financial_end_month
                 }

                 //this.financial_end_day =
             }
         },

         deep:true

}),*/
    getCompanyData(data) {
      if (data != null) {
        let r = this.$router.resolve({
          name: "chart-of-accounts"
        });
        window.location.assign(r.href);
      }
    },
    getCompanyErrorMessage(error) {
      if (error != null) {
        this.companySpinner = false;
        this.responseMessage = error.message;
        this.errorToast(error.message);
      }
    }
  }
};
</script>


<style scoped>
.logoutDesign:hover {
  text-decoration: underline;
}

.logoutDesign {
  font-size: 20px;
  color: #0000ff;
  cursor: pointer;
}
</style>
