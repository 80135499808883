<template>
<div>

  <v-row class="mx-auto">
    <v-col>


      <v-row v-if="showSuccessPage">
        <v-col cols="9" class="mx-auto">
          <v-card flat class="mx-auto">
      <div v-if="showSuccessPage" class="text-center mt-10" style="font-size: 16px; color: #757575;"><br />
        <p style="color: green; font-size: 38px; font-weight: bold;" class="mb-9">Congratulations</p>
        <p>You have successfully activated your account with BASE,<br />
          Click on the button below to login </p>

        <v-divider class="mt-10 pt-3"></v-divider>

        <v-row class="mx-auto">

          <v-spacer></v-spacer>
          <v-btn
              small
              :to="{ name: 'login' }"
              class="ma-2 mb-5"
              outlined
              color="#1d2b36"
          >
            Login
          </v-btn>
        </v-row>
      </div>
          </v-card>
        </v-col>
      </v-row>


<v-row v-if="errorMessage">
  <v-col cols="9" class="mx-auto">
    <v-card flat class="mx-auto pa-5">
    <v-alert type="error" v-if="errorMessage" dense>
      {{ errorMessage }}
    </v-alert>

    <div class="text-center" v-if="errorMessage">

      <v-btn
          class="ma-2"
          outlined
          color="#1d2b36"
          small
          type="button"
         :to="{name:'login'}"
      >
      Back to  Login
      </v-btn>

    </div>
    </v-card>

  </v-col>
</v-row>





      <v-row v-if="loader">
        <v-col cols="9" class="mx-auto">
      <v-card flat class="mx-auto pa-10">
      <div v-if="loader" class="text-center" style="font-size: 20px;">Please wait as we activate your account ...</div>
      <v-progress-linear
          v-if="loader"
          class="mx-auto"
          style="width: 40%;"
          color="#242f7e"
          indeterminate
          rounded
          height="3"
      ></v-progress-linear>
      </v-card>
        </v-col>
      </v-row>


    </v-col>
  </v-row>




</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: "ActivateAccount",
  data(){
    return{
      loader:false,
      errorMessage:null,
      activationId:null,
      showSuccessPage:false
    }
  },
  mounted() {
    this.errorMessage = null
    this.loader = true
    this.activationId = this.$route.params.id
    this.doActivateAccount({activation_code:this.$route.params.id})
  },
  methods:{
    ...mapActions(
        [
            "doActivateAccount"
        ]
    )
  },
  computed:{
    ...mapGetters(
        [
            "getActivateAccount",
            "getLoginMessage"
        ]
    )
  },
  watch:{
    getActivateAccount(data){
      if(data){
       this.showSuccessPage = true
        this.loader = false
      }
    },
    getLoginMessage(data){
      if(data){
        this.loader = false
        this.errorMessage = data.message
        this.errorToast("Error", data.message)
      }
    }
  }
}
</script>

<style scoped>

</style>