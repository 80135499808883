import axios from "axios";
import configs from "../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  chartOfAccountsData: null,
  statusResponse: "error",
  chartOfAccountsErrorMessage: null,
  accountItems: null,
  accountData: null,
  subAccountData: null,
  editChartOfAccountData: null,
  deleteChartOfAccountData: null,
  accountDataLists: null
  // editedChartOfAccountData: null
};

const getters = {
  getChartOfAccountsData: state => state.chartOfAccountsData,
  getChartStatusResponse: state => state.statusResponse,
  getChartOfAccountsErrorMessage: state => state.chartOfAccountsErrorMessage,
  getAccountItems: state => state.accountItems,
  getAccountData: state => state.accountData,
  getSubAccount: state => state.subAccountData,
  getEditChartOfAccountData: state => state.editChartOfAccountData,
  getDeleteChartOfAccountData: state => state.deleteChartOfAccountData,
  getAccountDataLists: state => state.accountDataLists
  // getEditedChartOfAccountData: state => state.editedChartOfAccountData
};

const actions = {
  async saveChartOfAccounts({ commit }, data) {
    await axios
      .post(
        data.number
          ? IP + "charts-of-accounts/update"
          : IP + "charts-of-accounts/save_chart",
        data
      )
      .then(response => {
        commit("setChartOfAccountsData", response);
      })
      .catch(error => {
        commit("setChartOfAccountsErrorMessage", error);
      });
  },
  async doGetAccountItems({ commit }) {
    await axios
      .get(IP + "charts-of-accounts/account-items")
      .then(response => {
        commit("setAccountItems", response);
      })
      .catch(error => {
        commit("setChartOfAccountsErrorMessage", error);
      });
  },
  async doGetAccount({ commit }, data) {
    await axios
      .get(
        IP +
          "charts-of-accounts" +
          "?page=" +
          data.page +
          "&per_page=" +
          data.per_page
      )
      .then(response => {
        commit("setAccountData", response);
      })
      .catch(error => {
        commit("setChartOfAccountsErrorMessage", error);
      });
  },
  async fetchAccountDataList({ commit }) {
    await axios
        .get(IP + "charts-of-accounts"
        )
        .then(response => {
          commit("setAccountDataLists", response);
        })
        .catch(error => {
          commit("setChartOfAccountsErrorMessage", error);
        });
  },
  async searchAccount({ commit }, data) {
    await axios
      .get(
        IP +
          "charts-of-accounts/search?account=" +
          data.account +
          "&page=" +
          data.page +
          "&per_page=" +
          data.per_page
      )
      .then(response => {
        commit("setAccountData", response);
      })
      .catch(error => {
        commit("setChartOfAccountsErrorMessage", error);
      });
  },
  async fetchSubAccountData({ commit }, data) {
    await axios
      .get(IP + "charts-of-accounts/child-accounts/" + data.type+"?super_account="+data.number)
      .then(response => {
        commit("setSubAccountData", response);
      })
      .catch(error => {
        commit("setChartOfAccountsErrorMessage", error);
      });
  },
  async getCharOfAccountsEditData({ commit }, data) {
    await axios
      .get(IP + "charts-of-accounts/details/" + data)
      .then(response => {
        commit("setChartOfAccountEditDAta", response);
      })
      .catch(error => {
        commit("setChartOfAccountsErrorMessage", error);
      });
  },
  async doDeleteChartOfAccount({ commit }, data) {
    await axios
      .delete(IP + "charts-of-accounts/delete/" + data)
      .then(response => {
        commit("setDeleteChartOfAccounts", response);
      })
      .catch(error => {
        commit("setChartOfAccountsErrorMessage", error);
      });
  }
};

const mutations = {
  setChartOfAccountsData: (state, response) => {
    state.statusResponse = "success";
    state.chartOfAccountsData = response.data;
  },
  setAccountItems: (state, response) => {
    state.statusResponse = "success";
    state.accountItems = response.data;
  },
  setAccountData: (state, response) => {
    state.statusResponse = "success";
    state.accountData = response.data;
  },
  setSubAccountData: (state, response) => {
    state.statusResponse = "success";
    state.subAccountData = response.data;
  },
  setChartOfAccountEditDAta: (state, response) => {
    state.editChartOfAccountData = response.data;
  },
  /*setEditedChartOfAccountsData: (state, response) => {
    state.editedChartOfAccountData = response.data;
  },*/
  setDeleteChartOfAccounts: (state, response) => {
    state.deleteChartOfAccountData = response.data;
  },
  setAccountDataLists: (state, response) => {
    state.accountDataLists = response.data;
  },
  setChartOfAccountsErrorMessage: (state, error) => {
    state.statusResponse = "error";
    state.chartOfAccountsErrorMessage = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
