import Vue from "vue";
import App from "./App.vue";
import store from "./global/store";
import router from "./global/router";
import vuetify from "./plugins/vuetify";
import Notifications from "vue-notification";
import axios from "axios";
import JsonExcel from "vue-json-excel";
import {abilitiesPlugin} from "@casl/vue";
import abilities from "./global/casl/abilities";
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import VueCountryCode from "vue-country-code-select";

Vue.use(Croppa)
Vue.use(VueCountryCode);

Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false;
Vue.prototype.$company = {};
Vue.prototype.$userProfile = {};

// localStorage.clear();

const user = localStorage.getItem("userProfile");
let company = localStorage.getItem("company");
if (user != null) {
  let usr = JSON.parse(localStorage.getItem("userProfile"));
  let token = localStorage.getItem("token");


  axios.create({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
  })
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };


  if (company != null) {
    let cmpny = JSON.parse(company);
    Vue.prototype.$company = cmpny;
    axios.defaults.headers.common["company-number"] = cmpny.number;
  }
  Vue.prototype.$userProfile = usr;

  axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
       /* if (error.response.status === 401 || error.response.status === 403) {
          localStorage.clear();
          router.push("/login");
        }*/

        if (!error.response) {
          alert("Please check your internet connection.");
          localStorage.clear();
          router.push("/login");
        }
        return Promise.reject(error);
      }
  );
}
Vue.use(Notifications);

Vue.use(abilitiesPlugin, abilities, {
  useGlobalProperties: true
});

Vue.mixin({
  methods: {
    successToast: function(
        title = "Success",
        text = "Request was processed successfully"
    ) {
      this.$notify({
        group: "foo",
        type: "success",
        title: title,
        text: text
      });
    },
    errorToast: function(
        title = "Error",
        text = "Something went wrong try again later"
    ) {
      this.$notify({
        group: "foo",
        type: "error",
        title: title,
        text: text
      });
    },
    warningToast: function(
        title = "Warning",
        text = "It seems something is not right"
    ) {
      this.$notify({
        group: "foo",
        type: "warn",
        title: title,
        text: text
      });
    },
    accountFilter: function(accountData) {
      let account = accountData;
      let accObj = [{ text: "Select item", id: "", disabled: true }];
      let accountJson = {};

      account.forEach(list => {
        accountJson = {
          text: list.full_name,
          id: list.number
        };
        accObj.push(accountJson);
      })

      return accObj;
    },
    nameNumberFilter(dataList) {
      let dataObj = [{ text: "Select item", id: "", disabled: true }];
      let dataJson = {};
      for (let i = 0; i < dataList.length; i++) {
        dataJson = {
          text: dataList[i].name,
          id: dataList[i].number,
          disabled: false
        };
        dataObj.push(dataJson);
      }

      return dataObj;
    },
    stockItemsFilter(dataList) {
      let dataObj = [{ text: "Select item", id: "", disabled: true }];
      let dataJson = {};
      for (let i = 0; i < dataList.length; i++) {
        dataJson = {
          text: dataList[i].item_name,
          id: dataList[i].number
        };
        dataObj.push(dataJson);
      }

      return dataObj;
    },
    getStatusColor(status) {
      let color = "";
      if (status === "Partially paid") {
        color = "blue darken-4";
      } else if (status === "Unpaid") {
        color = "red darken-4";
      } else if (status === "Fully paid") {
        color = "green darken-4";
      }else if (status === "cancelled") {
        color = "#e37400";
      }
      return color;
    },
    onlyNumberValidator(event) {
      if (!/[+-/*()\d]/.test(event.key)) return event.preventDefault();
    },
    onlyNumberTaxValidator(event) {
      if (!/[+-/%/*()\d]/.test(event.key)) return event.preventDefault();
    },
    onlyAlphabetValidator(event) {
      if (!/^[a-zA-Z]+$/.test(event.key)) return event.preventDefault();
    },
    getInvoiceBillStatuses(){
      return [
        {text: "All", id: ""},
        {text: "Fully paid", id: "Fully paid"},
        {text: "Unpaid", id: "Unpaid"},
        {text: "Partially paid", id: "Partially paid"},
        {text: "Cancelled", id: "Cancelled"},
      ]
    },
    filterStaffProfilesList(profileList) {
      let dataObj = [{ text: "Select staff", id: null, disabled: true }];
      let dataJson = {};
      profileList.forEach(list => {
        dataJson = {
          text: (list.first_name ? list.first_name : "")+" "+(list.second_name ? list.second_name : ""),
          id: list.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })
      return  dataObj;
    }
    ,
    transformNumbersToAccountingStandard(value,currencyCode="UGX",currencyLocale="Uganda"){
      if(!isNaN(value)) {
       let compInfo = JSON.parse(company)
        let code = ""
        let decimals = null
        if(compInfo && compInfo.number){
          code = compInfo.currency.code
          decimals = compInfo.currency.decimals
        }
        let absValue = Math.abs(value);
        let returnString = Number(absValue).toLocaleString(currencyLocale, {
          // style: 'currency',
          currency: code ? code : currencyCode,
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        });

        // if(code === 'USD' || code === 'EUR'){
          returnString = this.formatCurrency(returnString, code, decimals)
        // }
        // alert(returnString+"<=>"+code)

        return value < 0 ? '(' + returnString + ')' : returnString;
      }else {
        return "-"
      }
    },
    formatCurrency(amount, currency, decimals) {
  // Define the separators based on the currency
      amount =  (amount.toString().replace(/,/g, "") * 1)

  let decimalSeparator, thousandsSeparator;
  switch (currency) {
    case 'USD':
      decimalSeparator = '.';
      thousandsSeparator = ',';
      break;
    case 'EUR':
      decimalSeparator = ',';
      thousandsSeparator = '.';
      break;
    default:
      decimalSeparator = '.';
      thousandsSeparator = ',';
      break;
  }

  // Split the amount into integer and decimal parts
  let parts = amount.toFixed(decimals).toString().split('.');

  let integerPart = parts[0];
  let decimalPart = parts[1];

  // Add thousands separator to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

  // Combine integer and decimal parts with the decimal separator
  return integerPart + decimalSeparator + decimalPart;
      // Add the currency symbol based on the currency
      /*  switch (currency) {
          case 'USD':
            formattedAmount =  '$' + formattedAmount;
            break;
          case 'EUR':
            formattedAmount = '€' + formattedAmount;
            break;
          default:
            formattedAmount = currency + ' ' + formattedAmount;
            break;
        }*/
},
async doLogout() {
      localStorage.clear();
      let r = this.$router.resolve({
        name: "login",
      });
      window.location.assign(r.href);

    },
    filterUnits(unitDataList) {
      let unitJsn = {};
      let unitObj = [
        {
          text: "Select unit",
          id: "",
          disabled:true
        }
      ];
      for (let i = 0; i < unitDataList.length; i++) {
        unitJsn = {
          text:
              unitDataList[i].name + " ( " + unitDataList[i].abbreviation + " )",
          id: unitDataList[i].number
        };
        unitObj.push(unitJsn);
      }

      return unitObj;
    },
    objectToQueryParams(objectToParse) {
      return Object.keys(objectToParse).map(key => key + '=' + objectToParse[key]).join('&');
    },
    convertBase64ToMultipart(base64Image) {
      const contentType = base64Image.split(';')[0].split(':')[1];
      const data = base64Image.split(',')[1];
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    },
    countDecimalPlaces(num) {
        // Convert the number to a string and find the index of the decimal point
        const decimalIndex = num.toString().indexOf('.');

        // If there is no decimal point, the number has 0 decimal places
        if (decimalIndex === -1) {
          return 0;
        }

        // Otherwise, count the number of digits after the decimal point
        const numDigits = num.toString().length;
        const numDecimalPlaces = numDigits - decimalIndex - 1;

        return numDecimalPlaces;
      },
    abbreviateNumber(value) {
        value = (value.toString().replace(/,/g, "") * 1);
        let newValue = value
        if (value >= 1000) {
          const suffixes = ["", "k", "m", "b", "t"];
          const suffixNum = Math.floor(("" + value).length / 3);
          let shortValue = '';
          for (let precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
            if (dotLessShortValue.length <= 2) { break; }
          }
          if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(1);
          newValue = shortValue+suffixes[suffixNum];
        }
        return newValue;
      },
      yearLengthValidator(date){
      if(date){
        const dateInfo = (new Date(date).getFullYear());
        return isNaN(dateInfo)
      }
    },
    handleDateFocus(event) {
      event.target.blur();
    },
    checkLicenseStatus(){
     return  this.$company.license_status
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
