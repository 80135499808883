<template>
  <!-- App.vue -->
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <span>
    <v-navigation-drawer
      v-model="drawer"
      :expand-on-hover="expandOnHover"
      :mini-variant="miniVariant"
      :right="right"
      color="white"
      app
      dark>
      <v-list shaped class="py-0" dense style="color:#065FDB">
        <v-list-item dense :class="miniVariant && 'px-0'" two-line>
<!--          <v-list-item-avatar>-->
            <img alt="Logo" src="../../assets/logo.png" />
<!--          </v-list-item-avatar>-->
          <v-list-item-content class="ml-3">
            <v-list-item-title style="color: #065FDB;">BASE</v-list-item-title>
            <v-list-item-subtitle style="color: #065FDB;"><b>Good and Simple</b></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider style="background: #065FDB;"></v-divider>
        <v-list expand dense>
          <template v-for="item in items">
            <v-list-group
              v-if="item.children"
              :key="item.text"
              v-model="item.active"
              :append-icon="item.icon ? item['icon-alt'] : item.icon"
              :value="true">
              <template v-slot:activator>
                 <v-list-item-action v-if="item.left_icon">
                  <v-icon small  style="color: #065FDB;">{{ item.left_icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <small style="font-size: 12px; color: #065FDB;">{{ item.text }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                :to="{ name: child.routeName }"
                exact
                dense
                v-if="canViewMenuItem(child)">
                <v-list-item-action class="ml-6" v-if="child.icon">
                  <v-icon small  style="color: #065FDB;">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <small style="font-size: 12px; color: #065FDB;">{{ child.text }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else-if="canViewMenuItem(item)"
              :key="item.text"
              :to="{ name: item.routeName }"
              link
              color="primary"
              dense
              exact>
              <v-list-item-action>
                <v-icon small  style="color: #065FDB;">{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <small style="font-size: 12px; color: #065FDB;">{{ item.text }}</small>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="darken-2" dark dense style="background: #065FDB;">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{company_name || 'Basic Accounting For Simplified Enterprise'}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
           :close-on-content-click="false"
           :nudge-width="220"
           offset-x
           offset-y
           origin="center center"
           transition="fab-transition">
        <template v-slot:activator="{ on, attrs }">
           <v-card-subtitle v-bind="attrs" v-on="on" style="font-size: 16px; cursor: pointer;">
              <v-icon size="20">mdi-plus</v-icon>
             <small>Transact</small>
          </v-card-subtitle>
        </template>
        <v-card>
          <v-list dense>
            <v-subheader>Add Transactions</v-subheader>
            <v-divider></v-divider>
            <v-list-item-group color="primary">
              <v-list-item
                  v-for="(item, i) in transactionsLinks"
                  :key="i"
                  :to="{ name: item.routeName }"
                  v-if="canViewMenuItem(item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
      <v-menu
        :close-on-content-click="false"
        :nudge-width="220"
        offset-x
        offset-y
        origin="center center"
        transition="fab-transition">
        <template v-slot:activator="{ on, attrs }">
            <v-card-subtitle v-bind="attrs" v-on="on" style="font-size: 16px; cursor: pointer;">
              <v-icon size="20">mdi-calendar-text</v-icon>
              <small>Reports</small>
          </v-card-subtitle>
        </template>
        <v-card>
          <v-list dense>
            <v-subheader>Reports</v-subheader>
             <v-divider></v-divider>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in quickLinks"
                :key="i"
                :to="{ name: item.routeName }"
                v-if="canViewMenuItem(item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
      <v-menu
        :nudge-width="120"
        offset-x
        offset-y
        open-on-hover
        origin="center center"
        transition="fab-transition">
        <template v-slot:activator="{ on, attrs }">
          <span>
            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title
                ><small>{{ user_names }}</small></v-list-item-title
              >
              <v-list-item-subtitle
                ><b
                  ><small>{{ user_type }}</small></b
                ></v-list-item-subtitle
              >
            </v-list-item-content>
          </span>

          <v-btn v-bind="attrs" v-on="on" icon>
            <v-avatar color="" size="35">
              <v-icon dark>mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card width="300px">
          <v-list-item dense>
            <v-avatar class="mr-3">
              <v-icon large style="color: #1976d2">mdi-account-circle</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="mb-3">{{
                user_names
              }}</v-list-item-title>
              <v-list-item-title>Role: {{ user_type }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list dense>
            <v-list-item>
              <v-list-item-title
                ><b class="mr-3">Company:</b> {{ company_name }}
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-for="(item, i) in menu_items"
              :key="i"
              :to="{ name: item.routeName }">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>

              <!--              <v-list-item-title>{{ menu_items.title }} </v-list-item-title>-->
            </v-list-item>

            <v-list-item @click="doLogout">
              <v-list-item-icon>
                <v-icon>mdi-location-exit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->
    <v-main style="background: #e8e8e8; height: 100%; overflow-x: auto;">

       <v-alert
           tile
           type="error"
           dense
           class="pa-2"
           v-if="!checkLicenseStatus()"
       >
         <div style="font-size: 14px;">
         <b>Action needed!</b><br />
         To keep using BASE without interruption, Please provide your personalized license to activate software.

              <v-btn
                  class="ma-2 text-decoration-none"
                  outlined
                  small
                  color="#ffffff"
                  :loading="btnSpinner"
                  @click="addLicenseDialog"
              >
              <div class="mx-4">Activate</div>
            </v-btn>
           </div>

       </v-alert>

      <v-breadcrumbs
        style="padding-top: 15px; padding-bottom: 0;"
        :items="crumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <!-- If using vue-router -->
      <router-view></router-view>
    </v-main>

<!--    License dialog-->
    <v-dialog v-model="licenseDialog" width="35%">
    <v-card>
      <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
        <v-row>
          <v-card-title>Activate License</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="licenseDialog = false">
            <v-icon color="red">
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
        <activate-license ref="license_component"></activate-license>
      </div>
    </v-card>
  </v-dialog>



  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ActivateLicense from "@/modules/dashboard/Settings/license/ActivateLicense";
// import router from "../../global/router";

export default {
  name: "Dashboard",
  components: {ActivateLicense},

  data() {
    return {
      drawer: true,
      miniVariant: null,
      right: false,
      expandOnHover: false,
      btnSpinner:false,
      licenseDialog:false,
      menu_items: [
        { title: "Profile", icon: "mdi-account", routeName: "user-profile" }
      ],
      mini: true,
      items: [
        {
          text: "Chart of Accounts",
          routeName: "chart-of-accounts",
          icon: "mdi-chart-line",
          action: "view",
          resource: "chart"
        },
     /*   {
          left_icon: "mdi-dna",
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Transactions",
          active: false,
          children: [
            {
              text: "Journal",
              routeName: "journal",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "expenses"
            },
            {
              text: "Expense",
              routeName: "expenses",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "expenses"
            },
            {
              text: "Income",
              routeName: "income",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "income"
            },
            {
              text: "Current Assets",
              routeName: "current_assets",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "assets"
            },
            {
              text: "Fixed Assets",
              routeName: "fixed_assets",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "assets"
            },
            {
              text: "Current Liability",
              routeName: "current_liability",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "liabilities"
            },
            {
              text: "Long Term Liability",
              routeName: "long_term_liability",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "liabilities"
            },
            {
              text: "Equity",
              routeName: "equity",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "equity"
            },
            /!*{
              text: "Bills",
              routeName: "bill",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "bills"
            },*!/
            {
              text: "Sales",
              routeName: "sales",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "sales"
            },
            {
              text: "Customer Payments",
              routeName: "customer-payments",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "payments"
            },{
              text: "Transfer funds",
              routeName: "transfer",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "payments"
            },
            {
              text: "Supplier Payouts",
              routeName: "supplier-payouts",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "payments"
            },
            {
              text: "Depreciation",
              routeName: "depreciation",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "payments"
            },
            {
              text: "Depreciation Transaction",
              routeName: "depreciation-transaction",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "payments"
            }
          ]
        },*/
        {
          text: "Items List",
          routeName: "item-list",
          icon: "mdi-format-list-bulleted",
          action: "view",
          resource: "items"
        },
       /* {
          text: "Unit of Measure",
          routeName: "unit-of-measure",
          icon: "mdi-basket",
          action: "view",
          resource: "settings"
        },*/
        {
          text: "Customer / Suppliers",
          routeName: "profiles",
          icon: "mdi-account-multiple-plus",
          action: "view",
          resource: "customers"
        },
       /* {
          text: "Suppliers",
          routeName: "supplier",
          icon: "mdi-account-multiple",
          action: "view",
          resource: "suppliers"
        },*/
        {
          text: "Invoices",
          routeName: "invoice",
          icon: "mdi-script",
          action: "view",
          resource: "invoices"
        },
        {
          text: "Bills",
          routeName: "bill",
          icon: "mdi-cash-multiple",
          action: "view",
          resource: "bills"
        },
      /*  {
          text: "Discounts",
          routeName: "discounts",
          icon: "mdi-percent",
          action: "view",
          resource: "discounts"
        },*/

       /* {
          text: "Profit and Loss",
          routeName: "profit-and-loss",
          icon: "mdi-elevation-rise",
          action: "view",
          resource: "profitAndLoss"
        },
        {
          text: "Balance Sheet",
          routeName: "balance-sheet",
          icon: "mdi-scale",
          action: "view",
          resource: "balanceSheet"
        },*/
        {
          text: "Bank Accounts",
          routeName: "storage-accounts",
          icon: "mdi-bank",
          action: "view",
          resource: "bankAccounts"
        },
     /*   {
          text: "Cash Flow",
          routeName: "cash-flow",
          icon: "mdi-cash-100",
          action: "view",
          resource: "bankAccounts"
        },*/
     /*   {
          text: "Activity Logs",
          routeName: "activity-logs",
          icon: "mdi-timelapse",
          action: "view",
          resource: "settings"
        },*/
        {
          left_icon: "mdi-file",
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Reports",
          active: false,
          children:[
            {
              text: "Summary",
              routeName: "home",
              icon: "mdi-checkbox-blank-circle-outline",
              resource: "chart",
              action: "view"
            },
            {
              text: "Profit and Loss",
              routeName: "profit-and-loss",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "profitAndLoss"
            },
            {
              text: "Balance Sheet",
              routeName: "balance-sheet",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "balanceSheet"
            },
            {
              text: "Cash Flow",
              routeName: "cash-flow",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "bankAccounts"
            },
            {
              text: "Statement of Cash Flow",
              routeName: "statement-of-cash-flows",
              icon: "mdi-checkbox-blank-circle-outline",
              action: "view",
              resource: "bankAccounts"
            },
          ]
        },
        {
          icon: "mdi-cog",
          text: "Settings",
          routeName: "settings",
          action: "view",
          resource: "settings"
        }
      ],
      user: this.$userProfile,
      user_names: null,
      company_profile: this.$company,
      company_name: null,
      quickLinks: [
        {
          text: "Summary",
          routeName: "home",
          icon: "mdi-chart-bar",
          resource: "chart",
          action: "view"
        },
        {
          text: "Chart Of Accounts",
          icon: "mdi-chart-line",
          routeName: "chart-of-accounts",
          action: "view",
          resource: "chart"
        },
        {
          text: "Items List",
          icon: "mdi-format-list-bulleted",
          routeName: "item-list",
          action: "view",
          resource: "items"
        },
        {
          text: "Invoice",
          icon: "mdi-script",
          routeName: "invoice",
          action: "view",
          resource: "invoices"
        },
        {
          text: "Bill",
          icon: "mdi-receipt",
          routeName: "bill",
          action: "view",
          resource: "bills"
        },
       /* {
          text: "Profiles",
          icon: "mdi-account-multiple-plus",
          routeName: "profiles",
          action: "view",
          resource: "profiles"
        },*/
        {
          text: "Profit and Loss",
          icon: "mdi-elevation-rise",
          routeName: "profit-and-loss",
          action: "view",
          resource: "profitAndLoss"
        },
        {
          text: "Balance Sheet",
          icon: "mdi-scale",
          routeName: "balance-sheet",
          action: "view",
          resource: "balanceSheet"
        },
        {
          text: "Cash Flow",
          routeName: "cash-flow",
          icon: "mdi-cash-100",
          action: "view",
          resource: "bankAccounts"
        },
        {
          text: "Bank Accounts",
          icon: "mdi-bank",
          routeName: "storage-accounts",
          action: "view",
          resource: "bankAccounts"
        },
      /*  {
          text: "Journal",
          icon: "mdi-clipboard-text",
          routeName: "journal",
          action: "view",
          resource: "bankAccounts"
        }*/
      ],
      transactionsLinks:[
        {
          text: "Journal",
          routeName: "journal",
          icon: "mdi-clipboard-text",
          action: "view",
          resource: "expenses"
        },
        {
          text: "Expense",
          routeName: "expenses",
          icon: "mdi-playlist-check",
          action: "view",
          resource: "expenses"
        },
        {
          text: "Income",
          routeName: "income",
          icon: "mdi-cash-100",
          action: "view",
          resource: "income"
        },
        {
          text: "Current Assets",
          routeName: "current_assets",
          icon: "mdi-priority-low",
          action: "view",
          resource: "assets"
        },
        {
          text: "Fixed Assets",
          routeName: "fixed_assets",
          icon: "mdi-priority-high",
          action: "view",
          resource: "assets"
        },
        {
          text: "Current Liability",
          routeName: "current_liability",
          icon: "mdi-folder-download",
          action: "view",
          resource: "liabilities"
        },
        {
          text: "Long Term Liability",
          routeName: "long_term_liability",
          icon: "mdi-folder-upload",
          action: "view",
          resource: "liabilities"
        },
        {
          text: "Cost of Goods",
          routeName: "cost-of-goods",
          icon: "mdi-shape-rectangle-plus",
          action: "view",
          resource: "liabilities"
        },
        {
          text: "Equity",
          routeName: "equity",
          icon: "mdi-timer-sand",
          action: "view",
          resource: "equity"
        },
        /*{
          text: "Bills",
          routeName: "bill",
          icon: "mdi-checkbox-blank-circle-outline",
          action: "view",
          resource: "bills"
        },*/
        {
          text: "Sales",
          routeName: "sales",
          icon: "mdi-cash-multiple",
          action: "view",
          resource: "sales"
        },
        {
          text: "Transfer funds",
          routeName: "transfer",
          icon: "mdi-transit-transfer",
          action: "view",
          resource: "payments"
        },
        {
          text: "Customer Payments",
          routeName: "customer-payments",
          icon: "mdi-basket-fill",
          action: "view",
          resource: "payments"
        },
        {
          text: "Supplier Payouts",
          routeName: "supplier-payouts",
          icon: "mdi-basket-unfill",
          action: "view",
          resource: "payments"
        },
        {
          text: "Depreciation",
          routeName: "depreciation",
          icon: "mdi-playlist-plus",
          action: "view",
          resource: "payments"
        },
        {
          text: "Depreciation Transaction",
          routeName: "depreciation-transaction",
          icon: "mdi-playlist-minus",
          action: "view",
          resource: "payments"
        },
        {
          text: "Tax Payments",
          routeName: "tax-payments",
          icon: "mdi-cash-multiple",
          action: "view",
          resource: "payments"
        },
        {
          text: "All Transactions",
          routeName: "all-transactions",
          icon: "mdi-cash-multiple",
          action: "view",
          resource: "payments"
        }
      ],
      user_type: null,
    };
  },
  methods: {
    ...mapActions(
        [
            "userLogout",
            "doGetCompanyProfile"
        ]
    ),

    canViewMenuItem(item) {
      return this.$can(item.action, item.resource);
    },
    addLicenseDialog(){
      this.licenseDialog = true
    }
  },
  beforeMount() {
    if (
      this.$route.name === "expenses" ||
      this.$route.name === "fixed_assets" ||
      this.$route.name === "current_assets" ||
      this.$route.name === "long_term_liability" ||
      this.$route.name === "current_liability" ||
      this.$route.name === "income" ||
      this.$route.name === "equity" ||
      this.$route.name === "bill-details" ||
      this.$route.name === "transfer" ||
      this.$route.name === "sales" ||
      this.$route.name === "sales" ||
      this.$route.name === "customer-payments" ||
      this.$route.name === "supplier-payouts" ||
      this.$route.name === "depreciation" ||
      this.$route.name === "journal" ||
      this.$route.name === "depreciation-transaction"
    ) {
      this.items[2].active = true;
    }
    if (
        this.$route.name === "profit-and-loss" ||
        this.$route.name === "balance-sheet" ||
        this.$route.name === "cash-flow" ||
        this.$route.name === "home" ||
        this.$route.name === "statement-of-cash-flows"
    ) {
      this.items[6].active = true;
    }
  },
  mounted() {
    this.doGetCompanyProfile(this.company_profile.number);
    let user = this.user;
    let second_name = user.second_name;
    this.user_names =
      user.first_name.toUpperCase() +
      " " +
      second_name.charAt(0).toUpperCase() +
      second_name.slice(1);
    this.company_name = this.company_profile.company_name.toUpperCase();
    this.user_type = user.type;
    this.dummy_name = this.company_profile.company_name
  },
  created() {
    this.$root.$on("update_company_name", (companyNumber)=>{
     this.company_name = companyNumber.toUpperCase()
    })
  },
  computed: {
    ...mapGetters(
        [
            "getCompanyData",
            "getActivateLicense"
        ]
    ),
    crumbs: function() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      let itemsCount = pathArray.length;

      return pathArray.reduce((breadcrumbArray, path, idx) => {
        let count = idx + 2;
        breadcrumbArray.push({
          // to: {path:idx>1 ? "/" + breadcrumbArray[idx - 1].path + "/" : "/"+path },
          path: path,
          disabled: count > itemsCount,
          href: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text:
            (this.$route.matched[idx] &&
              this.$route.matched[idx].meta.breadCrumb) ||
            path
        });
        return breadcrumbArray;
      }, []);
    }
  },
  watch: {
    getCompanyData(newCompanyData) {
      if (newCompanyData != null) {
        let company = this.$company;

        if (company.number !== newCompanyData.number) {
          location.reload();
        }


          if (company.company_type.ref_code !== "COMPANY_PROFFESSIONAL"){
              if(this.items.find(item => item.routeName === "stock") === undefined){
                  this.items.push(
                      {
                          text: "Stock",
                          routeName: "stock",
                          icon: "mdi-cart-outline",
                          action: "view",
                          resource: "stock"
                      },
                  )
              }
              /*{
                  this.items.push(
                      {
                          text: "Stock",
                          routeName: "stock",
                          icon: "mdi-cart-outline",
                          action: "view",
                          resource: "stock"
                      },
                  )
              }*/

          }

      }
    },
    getActivateLicense(data){
      if(data){
        location.reload()
        this.licenseDialog = false
        this.$refs.license_component.btnSpinner = false
        this.$refs.license_component.form = {
                                              code:null,
                                            }
        this.successToast("Success", data.message)
      }
    }
  }
};
</script>

<style scoped></style>
