<template>
  <span>
     <v-app-bar app color="#ffffff" style="border-bottom: #1a1ada solid 3px;">
       <v-row>
        <v-col cols="2" sm="2" md="4"  lg="8">
          <v-img style="width: 38px; cursor: pointer;" src="@/assets/logo.png" @click="goHome"></v-img>
        </v-col>
        <v-col cols="6" sm="6" md="4" lg="3">
          <div  class="flex mt-2">
            <router-link class="menu-item" :to="{}">Solution</router-link>
            <router-link class="menu-item" :to="{}">Features</router-link>
            <router-link class="menu-item" :to="{}">Resources</router-link>
          </div>
        </v-col>
        <v-col cols="4" sm="4" md="4" lg="1">
<!--          <span class="free-trial float-right mt-1">
            <router-link :to="{name:'billing'}" style="color:white">
              Buy License
            </router-link>
          </span>-->
        </v-col>
       </v-row>
    </v-app-bar>
  </span>

</template>

<script>
import * as path from "path";
import router from "@/global/router";

export default {
  name: "HomeHeader",
  data() {
    return {
      logoImageStyle: {
        backgroundImage: "url(" + require("@/assets/logo.png") + ")",
      },
      logoPath: path.join(__dirname, 'src', 'assets', 'logo.png'),
      form:{
        language:null
      }
    };
  },
  methods:{
    goHome() {
      router.push("/")
    }
  }
};
</script>

<style scoped>
.topBar{
  background: white;
  border-bottom: 3px solid #1a1ada;
}
.logo-icon{
  height:38px;
}

.menu-item{
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  color: #03036b;
  text-decoration: none;
}

.menu-item:hover{
  color: #065FDB;
}

.free-trial{
  padding: 5px 10px;
  background: #065FDB;
  color:white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 3px;
}

.free-trial:hover{
  background: #1a1ada;
}
</style>