<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Transfers </v-toolbar-title>
            <v-spacer></v-spacer>
            <span>

              <v-btn
                class="ma-2"
                outlined
                color="#242f7e"
                small
                :loading="exportSpinner"
              >
                <v-icon left>
                  mdi-download
                </v-icon>
                Export
              </v-btn>
            </span>
          </v-toolbar>

          <v-tabs v-model="activeTab">
            <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
            <v-tab href="#transfer">
              <v-icon left>mdi-account</v-icon>
              All transfers
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-transfer">
              <v-icon left>mdi-lock</v-icon>
              New transfer
            </v-tab>

            <v-tab-item id="transfer">
              <v-divider></v-divider>
              <v-card flat>
               <AllTransfers/>
              </v-card>
            </v-tab-item>

            <v-tab-item id="new-transfer">
              <v-divider></v-divider>

              <v-card flat>
                <v-row>
                  <v-col cols="11" class="mt-8 mx-auto">
                   <new-transfer/>

                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="uploadDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Transfer funds</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="uploadDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <upload-file
            ref="upload_component"
            fileType="transactions"
          ></upload-file>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewTransfer   from "@/modules/dashboard/accounting/transfer/components/NewTransfer";
import AllTransfers from "@/modules/dashboard/accounting/transfer/components/AllTransfers";
import configs from "@/global/configs.json";
import router from "@/global/router";
export default {
  name: "Accounting",
  components: {  NewTransfer, AllTransfers },
  data() {
    return {
      activeTab: "transfer",
      uploadDialog: false,
      downloadLink:
        configs.SERVER_IP + "template?type=transactions&file_token=",
      exportLink: configs.SERVER_IP + "export?type=transactions&file_token=",
      downloadSpinner: false,
      exportSpinner: false
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  methods: {
    ...mapActions(["fetchTransactions", "downloadTemplateFile", "exportFile"]),

  },
  computed: {
    ...mapGetters([
      "getTransferList",

    ])
  },
  watch: {
    getTransferList(transactionData) {
      if (transactionData) {
        this.successToast("Success", transactionData.message);

        this.$refs["addExpenseItems"].transactionLoader = false;
        this.$refs["addExpenseItems"].form = {
          name: null,
          amount: null,
          notes: null,
          date: new Date().toISOString().substr(0, 10),
          storage_account_number: null,
          account_number: null,
          payment_method: null,
          reference: null
        };

        let data = {
          company_number: this.$company.number,
          type: "EXP"
        };
        this.fetchTransactions(data);
      }
    },

    activeTab(data) {
      router.push({ name: "transfer", query: { type: data } });
    }
  }
};
</script>

<style scoped></style>
