<template>
  <span>
    <v-card flat :loading="pageNotificationLoader">
      <v-toolbar dense flat>
        <!--        <v-app-bar-nav-icon></v-app-bar-nav-icon>-->
        <v-toolbar-title>Notifications</v-toolbar-title>
      </v-toolbar>

      <!--{{switch1}}-->

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th width="5%" class="text-left">
                <!--            Name-->
              </th>
              <th class="text-left">
                <!--            Calories-->
              </th>
              <th class="text-left">
                <!--            Calories-->
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in notificationData" :key="index">
              <td></td>
              <td>
                <v-switch
                  v-model="switch1"
                  :label="item.text"
                  :loading="itemIndex === index"
                  :value="item.number"
                  @change="notificationSettings(item.number, index)"
                ></v-switch>
              </td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      @click="notificationDialogAction(item)"
                    >
                      mdi-cog
                    </v-icon>
                  </template>
                  <span>Settings</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-dialog v-model="notificationDialog" width="45%">
        <v-card>
          <div style="width: 93%; padding-bottom: 10px;" class="mx-auto">
            <v-row>
              <v-card-title> Notification Settings</v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="notificationDialog = false">
                <v-icon color="red">
                  mdi-close
                </v-icon>
              </v-btn>
            </v-row>

            <new-notification-setting
              :notificationObj="notificationObj"
              ref="not_component"
            ></new-notification-setting>
          </div>
        </v-card>
      </v-dialog>
    </v-card>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewNotificationSetting from "@/modules/dashboard/Settings/Notifications/NewNotificationSetting";

export default {
  name: "Notifications",
  components: { NewNotificationSetting },
  data() {
    return {
      notificationData: [],
      switchLoader: false,
      switch1: [],
      selectedItem: null,
      itemIndex: undefined,
      notificationDialog: false,
      notificationObj: {},
      pageNotificationLoader: false
    };
  },
  mounted() {
    this.pageNotificationLoader = true;
    this.fetchNotificationSettings();
  },
  methods: {
    ...mapActions(["fetchNotificationSettings", "toggleNotification"]),
    notificationSettings(item, index) {
      this.selectedItem = item;
      this.itemIndex = index;
    },
    notificationDialogAction(item) {
      this.notificationDialog = true;
      this.notificationObj = item;
    }
  },
  computed: {
    ...mapGetters([
      "getNotificationSettings",
      "getNotificationChange",
      "getNotificationError",
      "getNotificationChangeError",
      "getNotificationConfig"
    ])
  },
  watch: {
    getNotificationSettings(notificationsData) {
      if (notificationsData) {
        this.pageNotificationLoader = false;
        this.notificationData = notificationsData;
        for (let i = 0; i < notificationsData.length; i++) {
          if (notificationsData[i].value === "On") {
            this.switch1.push(notificationsData[i].number);
          }
        }
      }
    },
    switch1() {
      if (this.selectedItem) {
        let data = {
          notification_uid: this.selectedItem,
          value: this.switch1.includes(this.selectedItem) ? "On" : "Off"
        };

        this.selectedItem = null;

        this.toggleNotification(data);
      }
    },
    getNotificationChange(changeInfo) {
      if (changeInfo) {
        this.itemIndex = undefined;
        this.successToast("Success", changeInfo.message);
      }
    },
    getNotificationError(errorData) {
      if (errorData) {
        this.errorToast("Error", errorData.message);
      }
    },
    getNotificationChangeError(changeError) {
      if (changeError) {
        this.itemIndex = undefined;
        this.errorToast("Error", changeError.message);
        this.fetchNotificationSettings();
      }
    },
    getNotificationConfig(notificationConfigs) {
      if (notificationConfigs) {
        this.notificationDialog = false;
        this.fetchNotificationSettings();
        this.$refs["not_component"].notificationLoader = false;
        this.successToast("Success", notificationConfigs.message);
      }
    }
  }
};
</script>

<style scoped></style>
