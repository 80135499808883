<template>
  <v-row>
    <v-col cols="1"></v-col>
    <v-col cols="10">
      <v-card flat class="mx-auto">
        <v-card-text>
          <div v-if="showSuccessPage" class="text-center mt-10" style="font-size: 16px; color: #757575;">
            <p style="color: green; font-size: 38px; font-weight: bold;" class="mb-9">Success</p>
            <p>You have successfully created a BASE account,<br />
              We have sent an emil on : <span class="text-decoration-none" style="color: green; font-weight: bold;">{{form.email}}</span> with activation instructions. </p>

            <v-divider class="mt-10 pt-4"></v-divider>

            <v-row class="mx-auto">

              <v-spacer></v-spacer>
              <v-btn
                small
                :to="{ name: 'login' }"
                class="ma-2"
                outlined
                color="#1d2b36"
              >
                Login
              </v-btn>
            </v-row>

          </div>

          <div v-else>

            <v-card-title class="justify-center"><b>BASE</b></v-card-title>

            <v-card-subtitle class="text-center">
              Create a New Account
            </v-card-subtitle>

            <v-alert
              type="error"
              v-if="formErrorMessage"
            >
              {{ formErrorMessage }}
            </v-alert>

            <v-form ref="form" @submit.prevent="doRegister" lazy-validation>
              <v-card-text>

                    <v-text-field
                      outlined
                      :rules="[rules.first_name_required]"
                      v-model="form.first_name"
                      label="First name"
                      required
                      prepend-inner-icon="mdi-account"
                      type="text"
                      dense
                    ></v-text-field>

                    <v-text-field
                      outlined
                      :rules="[rules.second_name_required]"
                      v-model="form.second_name"
                      label="Second name"
                      required
                      prepend-inner-icon="mdi-account"
                      type="text"
                      dense
                    ></v-text-field>

                <v-select
                  outlined
                  v-model="form.gender"
                  :items="genderItems"
                  :rules="[rules.gender_required]"
                  color="pink"
                  label="Gender"
                  required
                  dense
                ></v-select>

                <v-text-field
                  outlined
                  v-model="form.phone"
                  :rules="[rules.email_required]"
                  label="Phone"
                  maxlength="12"
                  @keypress="onlyNumberValidator($event)"
                  required
                  prepend-inner-icon="mdi-phone"
                  type="tel"
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="form.email"
                  :rules="[rules.email_required, rules.email_pattern]"
                  label="Email"
                  required
                  prepend-inner-icon="mdi-email-outline"
                  type="email"
                  dense
                ></v-text-field>


                    <v-text-field
                      outlined
                      v-model="form.password"
                      :rules="[rules.password_required]"
                      :type="show1 ? 'text' : 'password'"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      label="Password"
                      required
                      @click:append="show1 = !show1"
                      dense
                      prepend-inner-icon="mdi-lock-outline"
                    >
                    </v-text-field>

                    <v-text-field
                      outlined
                      v-model="form.confirm_password"
                      :rules="[rules.password_required, password_match]"
                      :type="show1 ? 'text' : 'password'"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      label="Confirm Password"
                      required
                      @click:append="show1 = !show1"
                      dense
                      prepend-inner-icon="mdi-lock-outline"
                    >
                    </v-text-field>


                <v-btn
                  class="mx-auto RegisterButton"
                  :loading="registerSpinner"
                  block
                  large
                  color="#0000ff"
                  dark
                  type="submit"
                >
                  Create Account
                </v-btn>

                <p class="text-center">
                  Already have an account? &nbsp; &nbsp;
                  <router-link :to="{ name: 'login' }">
                    Login
                  </router-link>
                </p>
              </v-card-text>
            </v-form>
          </div>

        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="1"></v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Register",
  data() {
    return {
      show1: false,
      formErrorMessage: null,
      registerSpinner: false,
      showSuccessPage:false,
      form: {
        first_name: null,
        second_name: null,
        gender: null,
        email: null,
        phone: null,
        password: null,
        confirm_password: null
      },
      genderItems: [
        { text: "Select Gender", value: null },
        { text: "MALE", value: "MALE" },
        { text: "FEMALE", value: "FEMALE" }
      ],
      rules: {
        first_name_required: value => !!value || "First name is Required.",
        second_name_required: value => !!value || "Second name is Required.",
        password_required: value => !!value || "Password is Required.",
        email_required: value => !!value || "Email is Required.",
        gender_required: value => !!value || "Gender is Required.",
        email_pattern: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Please enter valid email.";
        }
      }
    };
  },
  methods: {
    ...mapActions(["registerUser"]),
    doRegister() {
      this.formErrorMessage = "";

      if (this.form.first_name == null || this.form.first_name === "") {
        this.formErrorMessage = "First Name is Required";
        return false;
      }

      if (this.form.second_name == null || this.form.second_name === "") {
        this.formErrorMessage = "Second Name is Required";
        return false;
      }

      if (this.form.gender == null || this.form.gender === "") {
        this.formErrorMessage = "Gender is Required";
        return false;
      }

      if (this.form.phone == null || this.form.phone === "") {
        this.formErrorMessage = "Phone is Required";
        return false;
      }

      if (this.form.email == null || this.form.email === "") {
        this.formErrorMessage = "Email is Required";
        return false;
      }

      if (this.form.password == null || this.form.password === "") {
        this.formErrorMessage = "Password is Required";
        return false;
      }

      if (
        this.form.confirm_password == null ||
        this.form.confirm_password === ""
      ) {
        this.formErrorMessage = "Confirm Password is Required";
        return false;
      }

      if (this.form.password !== this.form.confirm_password) {
        this.formErrorMessage = "Password Mismatch";
        return false;
      }
      this.registerSpinner = true;

      this.registerUser(this.form);
    }
  },
  computed: {
    ...mapGetters([
      "getRegisterUserProfileData",
      "getRegisterErrorMessage",
      "getRegisterStatusResponse"
    ]),
    password_match() {
      return () =>
        this.form.password === this.form.confirm_password ||
        "Password Mismatch.";
    }
  },
  watch: {
    getRegisterUserProfileData(userData) {
      if (userData) {
        this.showSuccessPage = true
        this.successToast("Success", userData.message)
        this.registerSpinner = false
      }
    },
    getRegisterErrorMessage(error) {
      this.formErrorMessage = error.data.message;
      this.registerSpinner = false;
      this.errorToast("Error", error.data.message);
    }
  }
};
</script>

<style scoped>
.RegisterButton {
  margin-top: 4%;
  margin-bottom: 4%;
  height: 10px;
  font-size: 16px;
  font-weight: bold;
}
</style>
