<template>
  <div>
    <v-simple-table dense>
            <template v-slot:default>
              <tbody>
              <tr>
                <td>
                  <span class="ml-3">#</span>
                </td>
                <td>
                  Date
                </td>
                <td>
                    Name
                </td>
                <td>
                  Memo
                </td>
                <td>
                  Reference
                </td>
                <td>
                  Amount
                </td>
              </tr>
              <tr
                  v-for="(trans, index) in transactionInfo"
                  :key="index"
              >
                <td>
                  <span class="ml-3">{{ (index + 1) }}</span>
                </td>
                <td>
                  {{trans.date ? trans.date : null}}
                </td>
                <td>
                  {{trans.name ? trans.name : null}}
                </td>
                <td>
                  {{trans.notes ? trans.notes : null}}
                </td>
                <td>
                  {{trans.reference ? trans.reference : null}}
                </td>
                <td>
                  {{trans.amount ? trans.amount.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}}
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "BalanceSheetTransactions",
  data() {
    return {
      transactionInfo:[]
    };
  },
  mounted() {

  },
  methods: {
    ...mapActions([""]),
  },
  computed: {
    ...mapGetters(["getBSTransactionsInfo"])
  },
  watch: {
    getBSTransactionsInfo(data){
      if(data){
        this.transactionInfo = data
      }
    }
  }
};
</script>

<style scoped>
.linkStyle {
  text-decoration: none;
  color: #232323;
  /*font-size: 15px;*/
}

.linkStyle:hover {
  /*font-size: 15px;*/
  color: green;
  text-decoration: underline;
}
</style>
