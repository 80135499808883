<template>
<span>
    <form ref="form" @submit.prevent="saveDepreciation">
      <v-row class="mx-auto">
        <v-col>
          <v-alert type="error" v-if="errorMessage">
            {{ errorMessage }}
          </v-alert>


           <v-autocomplete
               v-model="form.account_number"
               :items="fixedAssetsList"
               item-value="id"
               label="Fixed Assets"
               outlined
               dense
           ></v-autocomplete>

           <v-text-field
               v-model="form.max_number_of_years"
               label="Number of Years"
               outlined
               dense
               maxlength="3"
               required
               @keypress="onlyNumberValidator($event)"
           ></v-text-field>

          <v-text-field
              v-model="form.rate"
              label="Rate (%)"
              outlined
              dense
              maxlength="3"
              required
              @keypress="onlyNumberValidator($event)"
              v-on:blur="computeValues(form.rate)"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :loading="buttonLoader"
            color="#0000ff"
            block
            dark
            type="submit"
        >
      {{itemsDataList ? 'Edit':'Save'}}
        </v-btn>
      </v-card-actions>
    </form>
  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewDepreciation",
  props:{
    itemsDataList:{}
  },
  data(){
    return{
      errorMessage:null,
      buttonLoader:false,
      fixedAssetsList:[],
      depreciationNumber:null,
      form:{
        account_number:null,
        max_number_of_years:null,
        rate:null,
      }
    }
  },
  mounted() {
    let data = {
      type: "FAST"
    };
      this.fetchAllFixedAssets(data)

    if(this.itemsDataList){
      this.checkDepreciation()
    }

    },
  methods:{
    ...mapActions(
        [
            "fetchAllFixedAssets",
            "saveDepreciationData",
            "getSingeDepreciation",
            "editDepreciationData"
        ]
    ),
    saveDepreciation(){
      this.buttonLoader = true
      let formData = this.form
      if(this.depreciationNumber){
        let data = {number:this.depreciationNumber, data:formData}
        this.editDepreciationData(data)
      }else {
        this.saveDepreciationData(formData)
      }

    },
    filterFixedAssetsList(dataList) {
      let dataObj = [{text: "Choose Fixed Assets", id: ""}];
      let dataJson = {};
      dataList.data.forEach(list => {
        dataJson = {
          text: list.name,
          id: list.number
        };
        dataObj.push(dataJson);
      })
      return dataObj;
    },
    computeValues(value) {
      if (!isNaN(value)) {
        value = value - 0;
      }

      let result = eval(value);

      if (result > 100) {
        result = 100;
      } else if (result < 1) {
        result = null;
      }

      this.form.rate = result;
    },
    checkDepreciation(){
      this.getSingeDepreciation(this.itemsDataList.number)
    }
  },
  computed:{
    ...mapGetters(
        [
            "getFixedAssetsDataList",
            "getSingleDepreciationData"
        ]
    )
  },
  watch:{
    getFixedAssetsDataList(assets){
        if(assets){
          this.fixedAssetsList = this.filterFixedAssetsList(assets)
        }
    },
    itemsDataList(){
      this.checkDepreciation()
    },
    getSingleDepreciationData(details){
      if(details){
        details = details.data
         this.form.account_number = details.account_number
         this.form.rate = details.rate
         this.form.max_number_of_years = details.max_number_of_years
        this.depreciationNumber = details.number
      }
    }
  }
}
</script>

<style scoped>

</style>