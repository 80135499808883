<template>
 <div>


   <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>

         <form ref="form" @submit.prevent="saveTax">


      <v-text-field
          v-model="form.name"
          label="Name"
          outlined
          dense
          required
          hide-details
      ></v-text-field>

           <v-row class="mx-auto pa-0">
             <v-checkbox
                 style="padding: 0;"
                 v-model="form.is_absolute"
                 class="shrink mt-2 mb-5 mr-3 float-left p-0"
                 label="Is tax absolute?"
                 id="sub_account_id"
                 @click="clearRate"
                 hide-details
             ></v-checkbox>

             <v-checkbox
                 style="padding: 0;"
                 v-model="form.is_compound"
                 class="shrink mt-2 mb-5 mr-3 float-left p-0"
                 label="Compound"
                 id="sub_account_id"
                 hide-details
             ></v-checkbox>

           </v-row>


               <v-text-field
                   v-model="form.rate"
                   label="Rate"
                   outlined
                   min="0"
                   max="100"
                   maxlength="18"
                   dense
                   @keypress="onlyNumberTaxValidator($event)"
                   v-on:blur="!form.is_absolute ? computeValues(form.rate) : computeAbsoluteValues(form.rate)"
                   persistent-hint
                   :hint="form.is_absolute ? '' : 'between 0 and 100'"
               ></v-text-field>


             <v-autocomplete
                 class="mt-2"
                 outlined
                 clearable
                 v-model="form.profile_number"
                 :items="supplierDataObj"
                 item-value="id"
                 color="pink"
                 label="Tax Authority"
                 append-outer-icon="mdi-plus"
                 @click:append-outer="addSupplierData"
                 @change="newSupplierData(form.profile_number)"
                 required
                 dense
             ></v-autocomplete>



      <div class="text-right">
        <v-btn
            block
            class="companyButton"
            :loading="taxSpinner"
            color="#0000ff"
            dark
            type="submit"
        >
        {{taxNo ? "Edit" : "Save"}}
        </v-btn>

      </div>
    </form>


   <!--    Vendor dialog-->

   <v-dialog v-model="add_supplier_dialog" width="60%">
     <v-card>
       <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
         <v-row>
           <v-card-title>Add Supplier</v-card-title>
           <v-spacer></v-spacer>
           <v-btn icon dark @click="add_supplier_dialog = false">
             <v-icon color="red">
               mdi-close
             </v-icon>
           </v-btn>
         </v-row>
         <new-profile ref="add_customer_info"></new-profile>
       </div>
     </v-card>
   </v-dialog>


  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";

export default {
  name: "NewTax",
  components: {NewProfile},
  props:{
    taxNumber:String
  },
  data(){
    return{
      errorMessage:"",
      taxNo:null,
      form:{
        name:null,
        rate:null,
        profile_number:null,
        is_compound:false,
        is_absolute:false
      },
      add_supplier_dialog: false,
      supplierDataObj: [],
      taxSpinner:false
    }
  },
  mounted() {
    this.checkTaxNumber()
    this.fetchProfiles();
  },
  methods:{
    ...mapActions(
        [
            "saveNewTax",
            "fetchTaxInfo",
            "updateTaxInfo",
            "fetchProfiles"
        ]
    ),
    clearRate(){
      this.form.rate = ""
    },
    saveTax(){

      this.errorMessage = ""
      let rate = this.form.rate.replace(/%/g, "")
          rate = rate.replace(/,/g, "");

      if ((rate < 0 || rate > 100) && !this.form.is_absolute) {
        this.errorMessage = "Tax rate should be between 1 to 100";
        return false;
      }
      this.taxSpinner = true
      let formData = this.form
      formData.rate = rate
      if(this.taxNo){
        formData.tax_number = this.taxNo
        this.updateTaxInfo(formData)
      }else {
        this.saveNewTax(formData)
      }

    },
    checkTaxNumber(){
      if(this.taxNumber){
        this.fetchTaxInfo(this.taxNumber)
      }
    },
    computeAbsoluteValues(value) {
      if (value) {
        value = value.replace(/,/g, "");
        if (!isNaN(value)) {
          value = value * 1;
        }

        this.form.rate = eval(value)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    computeValues(value) {
      if (value) {
        value = value.replace(/%/g, "");
        if (!isNaN(value)) {
          value = value * 1;
        }

        let result = eval(value);

       if (result < 0) {
          result = 0;
        }

        this.form.rate = result + '%';
      }
    },
    filterCustomerList(customerList) {
      let dataObj = [
        { text: "New Tax Authority", id: "new-profile"},
        {text: "Select Tax Authority", id: null, disabled: true}
      ];
      let dataJson = {};
      customerList.forEach(list =>{
        dataJson = {
          text: list.name,
          id: list.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })


      this.supplierDataObj = dataObj;
    },
    addSupplierData() {
      this.add_supplier_dialog = true;
    },
    newSupplierData(data) {
      if(data ==="new-profile"){
        this.form.profile_number = ""
        this.add_supplier_dialog = true;
      }
    },
  },
  computed:{
    ...mapGetters(
        [
            "getTaxDetails",
            "getNewTaxError",
            "getProfileDataList",
            "getProfileResponseData"
        ]
    )
  },
  watch:{
    taxNumber:{
      handler(data){
        if(data) {
          this.checkTaxNumber()
        }
      },
      deep:true
    },
    getProfileDataList(supplierDataList) {
      this.filterCustomerList(supplierDataList)
    },
    getTaxDetails(data){
      if(data){
        this.taxNo = data.number ? data.number : null
        this.form.name = data.name ? data.name : null
        this.form.rate = (data.rate && !data.is_absolute) ? data.rate+'%' : data.rate.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        this.form.is_compound = data.is_compound ? data.is_compound : false
        this.form.is_absolute = data.is_absolute ? data.is_absolute : false
        this.form.profile_number = data.profile_number ? data.profile_number : null
      }
    },
    getNewTaxError(data){
      if(data){
        this.errorMessage = data.message
        this.taxSpinner = false
      }
    },
    getProfileResponseData(customerData) {
      if (customerData) {
        this.$refs["add_customer_info"].customerSpinner = false;
        this.$refs["add_customer_info"].form = {
          name: null,
          trading_name: null,
          address: null,
          number: null,
          tin: null,
          phoneContact: [
            {
              contact: null,
              id: null,
              type: "phone"
            }
          ],
          emailContact: [
            {
              contact: null,
              id: null,
              type: "email"
            }
          ],
          contactPersons: [
            {
              name: null,
              phone_contacts: [
                {
                  phone: null
                }
              ],
              email_contacts: [
                {
                  email: null
                }
              ],
            }
          ]
        };
        this.add_supplier_dialog = false;
        this.successToast("Success", customerData.message);
        this.form.profile_number = customerData.data.number;
        this.fetchProfiles();
      }
    },
  }

}
</script>

<style scoped>

</style>