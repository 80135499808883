<template>
 <span>
    <v-row>
      <v-col cols="6">
        <v-alert type="error" v-if="formBillErrorMessage">
          {{ formBillErrorMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <form ref="form" @submit.prevent="editBill">
      <v-row>
        <v-col cols="3">

              <v-text-field
                  v-model="bill_number"
                  label="Bill Number"
                  readonly
                  outlined
                  dense
                  required
              ></v-text-field>

              <v-text-field
                  v-model="form.ref_no"
                  label="Reference No"
                  maxlength="17"
                  outlined
                  dense
              ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-autocomplete
              outlined
              v-model="form.profile_number"
              :items="supplierDataList"
              item-value="id"
              color="pink"
              label="Supplier"
              dense
              class="mb-3"
              clearable
              hide-details
              append-outer-icon="mdi-plus"
              @click:append-outer="addSupplierModel"
          ></v-autocomplete>

          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="form.invoice_date"
                  label="Bill Date [ dd / mm / yyy ]"
                  outlined
                  dense
                  type="date"
                  required
                  hide-details
              ></v-text-field>
               <small style="color: red;" v-if="yearLengthValidator(form.invoice_date)">
                Invalid Date
              </small>
            </v-col>

            <v-col cols="6">
              <v-text-field
                  v-model="form.due_date"
                  label="Bill Due Date [ dd / mm / yyy ]"
                  outlined
                  dense
                  type="date"
                  required
                  hide-details
              ></v-text-field>
               <small style="color: red;" v-if="yearLengthValidator(form.due_date)">
                Invalid Date
              </small>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="5">
          <v-textarea
              v-model="form.notes"
              label="Notes"
              outlined
              rows="3"
              dense
              hide-details
              class="mb-3"
          ></v-textarea>

          <!--          <v-row>
                      <v-col cols="4">
                        <v-checkbox
                            v-model="absoluteDiscount"
                            class="shrink mr-0 mt-0 float-left"
                            hide-details
                            label="is discount absolute?"
                            id="sub_account_id"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="8">
                         <v-text-field
                             v-if="absoluteDiscount"
                             v-model="form.absolute_discount"
                             label="Discount Amount"
                             outlined
                             dense
                         ></v-text-field>

                    <v-autocomplete
                        v-if="!absoluteDiscount"
                        outlined
                        v-model="form.discounts"
                        :items="discountDataObj"
                        item-value="id"
                        color="pink"
                        label="Discount (s)"
                        append-outer-icon="mdi-plus"
                        @click:append-outer="addDiscountsModel"
                        clearable
                        hide-selected
                        dense
                    ></v-autocomplete>
                      </v-col>
                    </v-row>-->




        </v-col>


         <p class="ml-5">Bill Items</p>

         <v-simple-table dense style="width: 100%; border-top: 1px solid #eeebeb; border-bottom: 1px solid #eeebeb;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="width: 20%;" class="text-left">Item</th>
                      <th style="width: 20%;" class="text-left">Memo</th>
<!--                      <th style="width: 15%;" class="text-left">Account</th>-->
                      <th style="width: 20%;" class="text-left">Tax</th>
                      <th style="width: 15%;" class="text-left">Quantity</th>
                      <th style="width: 15%;" class="text-left">Buying Price</th>
                      <th style="width: 10%;" class="text-left" colspan="2">Selling price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="(invoiceItemData, index) in form.bill_of_items"
                        v-bind:key="index"
                    >
                      <td class="pa-0" style="border-right: 1px solid #e0e0e0;">

                        <v-autocomplete
                            flat
                            solo
                            v-model="invoiceItemData.item_number"
                            :items="invoiceItemData.items"
                            item-value="id"
                            color="pink"
                            label="Item"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="addItemData"
                            required
                            hide-details
                            @change="
                            addBillAutoCompute(
                              invoiceItemData.item_number,
                              index
                            )
                          "
                            dense
                        ></v-autocomplete>
                      </td>
                      <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                        <v-textarea
                            flat
                            solo
                            v-model="invoiceItemData.notes"
                            rows="1"
                            hide-details
                        ></v-textarea>
                      </td>

                      <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                         <v-autocomplete
                             flat
                             solo
                             v-model="invoiceItemData.tax_number"
                             :items="taxItemDataObj"
                             item-value="id"
                             color="pink"
                             label=""
                             hide-selected
                             multiple
                             clearable
                             dense
                             hide-details
                             append-outer-icon="mdi-plus"
                             @click:append-outer="addTaxModel"
                         ></v-autocomplete>
                       </td>

                      <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                        <v-text-field
                            @keypress="onlyNumberValidator($event)"
                            v-model="invoiceItemData.quantity"
                            label=""
                            flat
                            solo
                            dense
                            hide-details
                            required
                            v-on:blur="
                            computeValues(
                              invoiceItemData.quantity,
                              index,
                              'quantity'
                            )
                          "
                        ></v-text-field>
                      </td>
                      <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                        <v-text-field
                            @keypress="onlyNumberValidator($event)"
                            v-model="invoiceItemData.buying_price"
                            label=""
                            flat
                            solo
                            dense
                            hide-details
                            required
                            v-on:blur="
                            computeValues(
                              invoiceItemData.buying_price,
                              index,
                              'buying_price'
                            )
                          "
                        ></v-text-field>
                      </td>
                      <td class="pa-0">
                        <v-text-field
                            style="width: 100%;"
                            @keypress="onlyNumberValidator($event)"
                            v-model="invoiceItemData.selling_price"
                            label=""
                            flat
                            solo
                            dense
                            hide-details
                            required
                            v-on:blur="
                            computeValues(
                              invoiceItemData.selling_price,
                              index,
                              'selling_price'
                            )
                          "
                        ></v-text-field>
                      </td>
                      <td class="pa-0 text-right">
                        <v-icon
                            class="mr-3"
                            @click="removesInvoiceItem(index)"
                            style="font-size: 20px;"
                        >mdi-close</v-icon
                        >
                      </td>
                    </tr>
                           <tr>
                        <td
                            colspan="8"
                            style="cursor: pointer;"
                        >
                          <div>

                             <v-btn

                                 @click="addInvoiceItems"
                                 x-small
                                 dark
                                 color="indigo"
                             >
                                Add another item
                              </v-btn>

                                 <v-btn

                                     @click="showOtherExpenses"
                                     x-small
                                     class="float-right"
                                     outlined
                                     color="indigo"
                                 >
                                   {{ !showAdditionalItems ? "Show" : "Hide" }}   Other Expenses
                              </v-btn>
                          </div>
                            </td>
                      </tr>
                  </tbody>
                </template>
              </v-simple-table>


        <p v-if="showAdditionalItems" class="ml-5 mt-8">Other Expenses</p>


         <v-simple-table v-if="showAdditionalItems" class="table" dense style="width: 100%; border-top: 1px solid #eeebeb; border-bottom: 1px solid #eeebeb;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Category</th>
                      <th class="text-left">Notes</th>
                      <th class="text-left" colspan="2">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="(expenseItemData, key) in form.expense_items"
                        v-bind:key="key"
                    >
                      <td class="pa-0" style="border-right: 1px solid #e0e0e0; width: 24%;">
                        <v-text-field
                            v-model="expenseItemData.name"
                            flat
                            solo
                            dense
                            hide-details
                        ></v-text-field>
                      </td>

                      <td class="pa-0" style="border-right: 1px solid #e0e0e0; width: 20%;">
                        <v-autocomplete
                            v-model="expenseItemData.account_number"
                            :items="accountDataItem"
                            item-value="id"
                            color="pink"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="accountModel"
                            dense
                            flat
                            solo
                            hide-details
                        ></v-autocomplete>
                      </td>


                      <td class="pa-0" style="border-right: 1px solid #e0e0e0; width: 20%;">
                        <v-text-field
                            v-model="expenseItemData.notes"
                            flat
                            solo
                            rows="1"
                            dense
                            no-resize
                            hide-details
                        ></v-text-field>
                      </td>

                      <td class="pa-0" style="width: 20%;">
                        <v-text-field
                            v-model="expenseItemData.amount"
                            flat
                            solo
                            dense
                            hide-details
                            @keypress="onlyNumberValidator($event)"
                            v-on:blur="
                            computeExpense(expenseItemData.amount, key)
                          "
                        ></v-text-field>
                      </td>

                      <td class="pa-0" width="1%">
                        <v-icon
                            @click="removesExpenseItem(key)"
                            style="font-size: 20px;"
                        >mdi-close</v-icon
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                          class="pa-0"
                          colspan="6"
                          @click="addExpenseItems"
                          style="cursor: pointer;"
                      >
                        <span class="text--disabled">Add another item</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>


        <v-col cols="12">

           <v-btn
               class="mt-4 float-right"
               :loading="btnBillSpinner"
               color="#0000ff"
               dark
               type="submit"
           >
            Edit
          </v-btn>

        </v-col>
      </v-row>
    </form>
   <!--      adds items-->
    <v-dialog v-model="add_item_dialog" width="65%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Item</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_item_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-item ref="items_components"></add-item>
        </div>
      </v-card>
    </v-dialog>

   <!--supplier dialog-->
    <v-dialog v-model="addSupplierDialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Supplier</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addSupplierDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
           <new-profile ref="add_supplier_info"></new-profile>
        </div>
      </v-card>
    </v-dialog>

   <!--discounts dialog-->
    <v-dialog v-model="addDiscountDialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Discount</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addDiscountDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-discount ref="add_discount_items"></add-discount>
        </div>
      </v-card>
    </v-dialog>

   <!--    adds Storage accounts -->

    <v-dialog v-model="storage_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="storage_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <storage-account ref="storageAccountComponent"></storage-account>
        </div>
      </v-card>
    </v-dialog>

   <!--                    payment methods-->

    <v-dialog v-model="payment_methods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Payment Methods</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payment_methods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <payment-methods ref="paymentMethodsComponents"></payment-methods>
        </div>
      </v-card>
    </v-dialog>

   <!--    adding account dialog-->

    <v-dialog v-model="account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <add-account
              :account_type_list="['EXP','COS']"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>


   <!--tax dialog-->
    <v-dialog v-model="addTaxDialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Tax</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addTaxDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-tax ref="new_tax_component"></new-tax>
        </div>
      </v-card>
    </v-dialog>

  </span>
</template>

<script>
import NewTax from "@/modules/dashboard/Settings/tax/NewTax";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";
import StorageAccount from "@/modules/dashboard/accounting/commons/StorageAccount";
import AddAccount from "@/modules/dashboard/accounting/commons/AddAccount";
import PaymentMethods from "@/modules/dashboard/accounting/commons/PaymentMethods";
import AddDiscount from "@/modules/dashboard/accounting/commons/AddDiscount";
import AddItem from "@/modules/dashboard/accounting/commons/AddItem";
import Constants from "@/global/configs.json";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EditBill",
  props: {
    editInfo: String
  },
  components: {
    NewTax,
    NewProfile,
    StorageAccount,
    AddAccount,
    PaymentMethods,
    AddDiscount,
    AddItem,

  },
  data() {
    return {
      invoiceItemDataObj: [],
      add_item_dialog: false,
      btnBillSpinner: false,
      addSupplierDialog: false,
      supplierDataList: [],
      formBillErrorMessage: null,
      bill_number: null,
      addDiscountDialog: false,
      discountDataObj: [],
      taxItemDataObj: [],
      incomeAccountDataList: [],
      absoluteDiscount: false,
      discountListUnfiltered: [],
      taxListUnfiltered: [],
      addTaxDialog: false,
      form: {
        tax_type:"Inclusive",
        number: null,
        ref_no: null,
        invoice_date: new Date().toISOString().substr(0, 10),
        due_date: new Date().toISOString().substr(0, 10),
        notes: null,
        profile_number: null,
        invoice_type: Constants.SUPPLIER,
        discount: null,
        absolute_discount: null,
        stock_number: null,
        bill_of_items: [
          {
            amount:null,
            tax_number:null,
            item_number: null,
            quantity: null,
            notes: null,
            absolute_discount: null,
            buying_price: null,
            selling_price: null,
            account_number:null,
            code:null,
            sellingPriceWarning:null,
            items:[]
          }
        ],
        expense_items: []
      },
      storage_account_dialog: false,
      payment_methods_dialog: false,
      account_dialog: false,
      accountDataItem: [],
      // storageAccountDataList: [],
      // paymentMethodDataList: [],
      unfilteredBillList: [],
      taxTypeItems:[
        {text: "Inclusive", id: "Inclusive"},
        {text: "Exclusive", id: "Exclusive"},
        {text: "Exempt", id: "Exempt"},
      ],
      showAdditionalItems:false
    };
  },
  mounted() {
    this.fetchItemsList(this.form.invoice_type);
    this.getGeneratedBillNumber();
    this.fetchDiscountList();
    this.fetchProfiles();

    //  items for expense
    /* let data = {
       company_number: this.$company.number,
       type: "EXP"
     };*/

    // this.doGetAccountData(data);
    this.doGetStorageAccountData();
    this.doGetPaymentMethodData();

    this.fetchTaxData();

    this.fetchAccountDataList()

    this.fetchAccountsAdditionalCosts()

    if(this.$route.query.prof){
      this.form.profile_number = this.$route.query.prof
    }

    /* if (this.editBillNumber) {
      this.billEditDetails();
    }*/
  },

  methods: {
    ...mapActions([
      "fetchItemsList",
      "doEditBill",
      "doGetProfileDataList",
      "getGeneratedBillNumber",
      "fetchDiscountList",
      "doGetAccountData",
      "doGetStorageAccountData",
      "doGetPaymentMethodData",
      "doGetBillInfo",
      "fetchTaxData",
      "fetchAccountDataList",
      "fetchProfiles",
    ]),
    fetchAccountsAdditionalCosts(){

      this.doGetAccountData("EXP,COS");
    },
    editBill() {
      this.btnBillSpinner = true;
      this.formBillErrorMessage = null;
      let formData = {...this.form}
      this.doEditBill(formData);
    },
    filterStockItemsList(itemsList) {
      let itemJsn = {};
      let itemObj = [{ text: "Select Item", id: null }];
      for (let i = 0; i < itemsList.length; i++) {
        itemJsn = {
          text: itemsList[i].item_name,
          id: itemsList[i].number
        };
        itemObj.push(itemJsn);
      }

      this.invoiceItemDataObj = itemObj;

      this.form.bill_of_items.forEach(list =>{
        list.items = itemObj
      })



      this.unfilteredBillList = itemsList;
    },
    addItemData() {
      this.add_item_dialog = true;
    },
    addInvoiceItems() {
      let items = [];

      this.invoiceItemDataObj.forEach(obj => {
        let item_val = obj.id;

        let selected_items = this.form.bill_of_items.filter(
            t => t.item_number === item_val
        );

        if (selected_items.length === 0) {
          items.push(obj);
        }
      });


      if(items.length > 0) {
        let accountNo = ""
        this.incomeAccountDataList.forEach(accntItm => {
          if(accntItm.text === "Cost Of Goods"){
            accountNo = accntItm.id
          }
        })

        this.form.bill_of_items.push({
          item_number: null,
          quantity: null,
          amount: null,
          buying_price: null,
          selling_price: null,
          discount: null,
          sellingPriceWarning: null,
          account_number: accountNo,
          items: items
        });
      }else {
        this.errorToast("Error", "No item found!!")
      }

    },
    addExpenseItems() {
      this.form.expense_items.push({
        name: null,
        account_number: null,
        amount: null,
        notes: null,
      });
    },
    removesInvoiceItem(index) {
      this.form.bill_of_items.splice(index, 1);
    },
    removesExpenseItem(key) {
      this.form.expense_items.splice(key, 1);
    },
    addSupplierModel() {
      this.addSupplierDialog = true;
    },
    filterSupplierList(supplierList) {
      let dataObj = [{ text: "Select Supplier", id: null, disabled: true }];
      let dataJson = {};
      for (let i = 0; i < supplierList.length; i++) {
        dataJson = {
          text: supplierList[i].name,
          id: supplierList[i].number,
          disabled: false
        };
        dataObj.push(dataJson);
        if (this.$route.query.prof === supplierList[i].number) {
          this.form.profile_number = supplierList[i].number;
        }
      }

      this.supplierDataList = dataObj;
    },
    addDiscountsModel() {
      this.addDiscountDialog = true;
    },
    filterDiscountsData(dataList) {
      let dataObj = [{ text: "Select discount", id: [], disabled: true }];

      dataList.forEach(item=>{
            dataObj.push(
                {
                  text: item.name + " ( " + item.rate + "% )",
                  id: item.number
                }
            )
          }

      )


      return dataObj;
    },
    computeValues(value, index, vModal) {
      if (value) {
        value = value + "";
        value = value.toString().replace(/,/g, "");

        ///this removes any value that starts with a zero
        if (!isNaN(value)) {
          value = value - 0;
        }


        if (vModal === "quantity") {
          this.form.bill_of_items[index].quantity =
              eval(value) >= 0
                  ? eval(value)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : null;
        } else if (vModal === "buying_price") {
          this.form.bill_of_items[index].buying_price =
              eval(value) >= 0
                  ? eval(value)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : null;
        } else if (vModal === "selling_price") {
          this.form.bill_of_items[index].selling_price =
              eval(value) >= 0
                  ? eval(value)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : null;
        }
      }
    },
    computeExpense(value, index) {
      if (value) {
        value = value.toString().replace(/,/g, "");

        if (!isNaN(value)) {
          value = value * 1;
        }

        this.form.expense_items[index].amount = eval(value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    storageAccountModel() {
      this.storage_account_dialog = true;
    },
    paymentMethodModel() {
      this.payment_methods_dialog = true;
    },
    accountModel() {
      this.account_dialog = true;
    },

    addBillAutoCompute(value, index) {
      let billListInfo = this.unfilteredBillList;
      let quantity = 1;
      let price = 0;
      let tax_no = null;


      this.form.bill_of_items[index].buying_price = null;
      this.form.bill_of_items[index].selling_price = null;
      this.form.bill_of_items[index].quantity = null;
      this.form.bill_of_items[index].tax_number = null;

      for (let i = 0; i < billListInfo.length; i++) {
        if (value === billListInfo[i].number) {
          price = billListInfo[i].selling_price;
          tax_no = billListInfo[i].tax ? billListInfo[i].tax.tax_number : tax_no;
        }
      }

      this.form.bill_of_items[index].tax_number = tax_no

      this.form.bill_of_items[index].quantity = quantity
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.form.bill_of_items[index].buying_price = price
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.form.bill_of_items[index].selling_price = (price * quantity)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    taxItemFilter(dataList) {
      let arr = [{ text: "Select tax", id: "", disabled:true}];
      let obj = {};
      dataList.forEach( list => {
            obj = {
              text: list.name+" ("+list.rate+" %)",
              id: list.number,
            };
            arr.push(obj);
          }
      )
      return arr;
    },
    filterAccountDataLists: function (accountData) {
      let account = accountData;
      let accObj = [{text: "Select item", id: null, disabled: true}];
      let accountJson = {};



      let account_type = "";
      account.forEach((accountItem, index) => {

        let _account_type = "";

        if (account_type !==  accountItem.account_type) {
          account_type =      accountItem.account_type;
          _account_type =     account_type;
        } else {
          _account_type = "";
        }

        if(_account_type) {
          accountJson = {text: _account_type, id: index, disabled: true}
          accObj.push(accountJson);
          accountJson = {divider: !!_account_type}
          accObj.push(accountJson);
        }


        accountJson = {
          text: accountItem.full_name,
          id: accountItem.number,
          disabled: false
        }
        accObj.push(accountJson);
        if(accountItem.full_name === "Cost Of Goods" && this.form.bill_of_items.length > 0) {
          this.form.bill_of_items[0].account_number = accountItem.number
        }

      })


      return accObj;
    },
    showOtherExpenses(){
      this.showAdditionalItems =  !this.showAdditionalItems

      if(this.showAdditionalItems){
        this.form.expense_items = [
          {
            number: null,
            name: null,
            account_number: null,
            amount: null,
            notes: null,
          }
        ]
      }else {
        this.form.expense_items = []
      }
    },
    addTaxModel(){
      this.addTaxDialog = true;
    },
    filterBillInfo(data){
      if(data){
        this.formBillErrorMessage = ""
        this.form.expense_items = []
        this.form.bill_of_items = []
        this.invoice_number = data.counter
        this.form.number = data.number ? data.number : null
        this.form.ref_no = data.ref_no ? data.ref_no : null
        this.form.profile_number = data.profile_number ? data.profile_number : null
        this.form.invoice_date = data.invoice_date ? data.invoice_date : null
        this.form.due_date = data.due_date ? data.due_date : null
        this.form.notes = data.notes ? data.notes : null

        let arr = []
        let obj = {}
        if (data.stock_items.length > 0) {
          this.form.stock_number =  data.stock_items[0].stock_number
          data.stock_items.forEach(lst => {

            let arrObj = []
            if(lst.all_taxes && lst.all_taxes.length > 0){
               lst.all_taxes.forEach(list => {
                 if(list && list.number) {
                   arrObj.push(list.number)
                 }
               })
            }

            obj = {
              tax_number: arrObj,
              item_number: lst.item_number ? lst.item_number : null,
              quantity: lst.quantity ? lst.quantity.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
              absolute_discount: null,
              notes: lst.notes ? lst.notes : null,
              buying_price: lst.buying_price ? lst.buying_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
              selling_price: lst.selling_price ? lst.selling_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
              account_number:lst.account_number ? lst.account_number : null,
              code:lst.code ? lst.code : null,
              stock_item_number:lst.number ? lst.number : null,
              items:this.invoiceItemDataObj
            }
            arr.push(obj)
          })
          if (arr.length > 0) {
            this.form.bill_of_items = arr
          }
        }


        let arry = []
        let obj2 = {}
        if(data.invoice_expenses.length > 0){
          this.showAdditionalItems = true
          data.invoice_expenses.forEach(list => {
            obj2 =   {
              number: list.number ? list.number : null,
              name: list.name ? list.name : null,
              amount: list.amount ? list.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
              notes: list.notes ? list.notes : null,
              account_number: list.account_number ? list.account_number : null,
            }
            arry.push(obj2)
          })
          this.form.expense_items = arry
        }




      }
    }
  },
  computed: {
    ...mapGetters([
      "getStockItemsDataList",
      "getItemData",
      "getBillNextNumber",
      "getBillError",
      "getSupplierDataList",
      "getDiscountData",
      "getDiscountDataList",
      "getAccountDataSet",
      "getStorageAccountDataSet",
      "getPaymentMethodDataSet",
      "getNewStorageAccountData",
      "getNewPaymentMethods",
      "getChartOfAccountsData",
      "getTaxDataAll",
      "getNewBillData",
      "getAccountDataLists",
      "getProfileDataList",
      "getProfileResponseData",
      "getNewTaxData",
      "getNewServiceItemData",
      "getInventoryPartItem",
      "getInventoryAssemblyItem",
       "getBillDataInfo"
    ]),
    doSumTotal() {
      /*------------------------------------------------------------*/
      //this function will calculate the total amount
      let arr = this.form.bill_of_items;
      let sum = 0;
      for (let i = 0; i < arr.length; i++) {
        let quantity = arr[i].quantity
            ? (arr[i].quantity.toString().replace(/,/g, "") * 1)
            : arr[i].quantity;
        let unit_price = arr[i].unit_price
            ? (arr[i].unit_price.toString().replace(/,/g, "") * 1)
            : arr[i].unit_price;
        sum = quantity * unit_price + sum;
      }

      let subTotal = sum
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");



      //----------Taxation on item-------------------/
      let  totalTax = 0
      let  percentageTax = 0

      // let taxUnfiltered = this.taxListUnfiltered

      let taxSum = 0

      /*    taxUnfiltered.forEach(itemUnfiltered => {
            let taxUnfiltd = itemUnfiltered.number
            let taxRate = itemUnfiltered.tax_item.rate

            arr.forEach( taxFormItem =>{
              let taxSelectd = taxFormItem.tax_number
              if(taxUnfiltd === taxSelectd){
                 // alert(taxUnfiltd +"<==>"+taxSelectd+"<==>"+taxRate)
                taxSum = taxRate + taxSum
                  }
                }
            )


          })*/

      percentageTax =   taxSum
      totalTax      =   (taxSum / 100) * sum
      // alert(taxSum)

      //------------------Calculating discount on item---------------------------------------//

      /* let discountUnfilteredList = this.discountListUnfiltered;
        let discountCount = 0
       //unfiltered list of discounts
       discountUnfilteredList.forEach( unfitd => {
           let dicountUnfitdNo  = unfitd.number
           let dicountRate  = unfitd.rate
         ///form data array for discounts
           arr.forEach(discountArr=>{
             let discountData = discountArr.discount
             if(discountData) {
               discountData.forEach(discountNumber => {
                 if (dicountUnfitdNo === discountNumber) {
                   discountCount = dicountRate + discountCount
                 }
               })
             }
           } )


           })*/

      // let allDiscountOnItem = discountCount
      //discount count on discount



      /*----------General---Calculating discounts----------------------------*/


      let discountSum = 0;

      let formDiscounts = this.form.discount;
      let absoluteDiscount = this.form.absolute_discount;

      if (formDiscounts) {
        let discountList = this.discountListUnfiltered;

        for (let a = 0; a < discountList.length; a++) {
          let array_number = discountList[a].number;

          for (let s = 0; s < formDiscounts.length; s++) {
            if (array_number === formDiscounts[s]) {
              //this returns summation of discounts
              discountSum = discountList[a].rate + discountSum;
            }
          }
        }
      } else if (absoluteDiscount) {
        discountSum = (absoluteDiscount / sum) * 100;
      }

      let discountError = null;
      if (discountSum > 100) {
        discountError = "Danger!! discount has exceeded 100%";
      } else {
        discountError = "";
      }

      let computeDiscount =  (discountSum / 100) * (sum + totalTax);

      let totalDiscount =
          "- " +
          computeDiscount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let totalAmount = (sum - computeDiscount)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let totalAmountAfterTax = ((sum + totalTax) - computeDiscount)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let percentageDiscount = discountSum.toFixed(2);




      return {
        subTotal: subTotal,
        totalAmount: totalAmount,
        totalAmountAfterTax: totalAmountAfterTax,
        totalDiscount: totalDiscount,
        percentageDiscount: percentageDiscount,
        totalTax: totalTax,
        percentageTax: percentageTax,
        discountError: discountError
      };
    },
  },
  watch: {
    getStockItemsDataList(itemsList) {
      this.filterStockItemsList(itemsList);
    },
    getItemData(itemsData) {
      if (itemsData) {
        this.add_item_dialog = false;
        this.$refs["items_components"].form = {};
        this.fetchItemsList();
        this.successToast("Success", itemsData.message);
      }
    },

    getBillNextNumber(numberInfo) {
      this.bill_number = numberInfo;
    },
    getProfileResponseData(supplierData) {
      if (supplierData) {
        this.$refs["add_supplier_info"].customerSpinner = false;
        this.$refs["add_supplier_info"].form = {
          name: null,
          address: null,
          tin: null,
          phoneContact: [
            {
              contact: null
            }
          ],
          emailContact: [
            {
              contact: null
            }
          ]
        };
        this.addSupplierDialog = false;
        this.successToast("Success", supplierData.message);
        this.form.profile_number = supplierData.data.number;
        this.fetchProfiles();
      }
    },
    getBillError(data) {
      if(data) {
        this.formBillErrorMessage = data.data.message;
        this.btnBillSpinner = false;
        this.errorToast("Error", data.data.message)
      }
    },
    getProfileDataList(supplierDataList) {
      this.filterSupplierList(supplierDataList);
    },
    getDiscountData(discountData) {
      if (discountData) {
        this.$refs["add_discount_items"].form = {
          name: null,
          rate: null,
          discount_number: null
        };
        this.$refs["add_discount_items"].addDiscountLoader = false;
        this.addDiscountDialog = false;
        this.fetchDiscountList();
        this.successToast("Success", discountData.message);
      }
    },
    getDiscountDataList(discountData) {
      this.discountDataObj = this.filterDiscountsData(discountData);
      this.discountListUnfiltered = discountData;
    },
    absoluteDiscount() {
      this.form.absolute_discount = null;
      this.form.discount = null;
    },
    getAccountDataSet(accountData) {
      this.accountDataItem = this.accountFilter(accountData);
    },
    getNewPaymentMethods(paymentData) {
      if (paymentData) {
        this.$refs["paymentMethodsComponents"].form = {};
        this.$refs["paymentMethodsComponents"].addPaymentMethodLoader = false;
        this.payment_methods_dialog = false;
        this.form.payment_method = paymentData.data.number;
        this.successToast("Success", paymentData.message);
        this.doGetPaymentMethodData();
      }
    },
    getChartOfAccountsData(chartData) {
      if (chartData) {
        this.form.account_number = chartData.data.number;

        this.$refs["chart_of_account_components"].chartSpinner = false;
        this.$refs["chart_of_account_components"].form = {};
        this.account_dialog = false;

        this.successToast("Success", chartData.message);

        /*  let data = {
            company_number: this.$company.number,
            type: "EXP"
          };
          this.doGetAccountData(data);*/
        this.fetchAccountsAdditionalCosts()
      }
    },
    getTaxDataAll(taxData) {
      if (taxData) {
        this.taxItemDataObj = this.taxItemFilter(taxData);
        this.taxListUnfiltered = taxData
      }

    },
    getNewBillData(billData){
      if(billData){
        this.btnBillSpinner = false
        this.form = {
          number: null,
          ref_no: null,
          invoice_date: new Date().toISOString().substr(0, 10),
          due_date: new Date().toISOString().substr(0, 10),
          notes: null,
          profile_number: null,
          invoice_type: Constants.SUPPLIER,
          discount: null,
          absolute_discount: null,
          bill_of_items: [
            {
              amount:null,
              tax_number:null,
              item_number: null,
              quantity: null,
              notes: null,
              absolute_discount: null,
              buying_price: null,
              selling_price: null,
              account_number:null,
              code:null,
              sellingPriceWarning:null,
              items:this.invoiceItemDataObj
            }
          ],
          expense_items: [
            {
              number: null,
              name: null,
              account_number: null,
              amount: null,
              notes: null,
            }
          ]
        }
        this.successToast("Success", billData.message)
      }
    },
    getAccountDataLists(accountData) {
      this.incomeAccountDataList = this.filterAccountDataLists(accountData);
    },
    getNewTaxData(data){
      if(data){
        this.$refs["new_tax_component"].form = {
          name:null,
          rate:null,
          profile_number:null,
          is_compound:false,
          is_absolute:false
        };
        this.$refs["new_tax_component"].taxSpinner = false;
        this.addTaxDialog = false;
        this.fetchTaxData();
        this.successToast("Success", data.message);
      }
    },
    getNewServiceItemData(data){
      if(data){
        this.add_item_dialog = false
        this.fetchItemsList(this.form.invoice_type);
      }
    },
    getInventoryPartItem(data){
      if(data){
        this.add_item_dialog = false
        this.fetchItemsList(this.form.invoice_type);
      }
    },
    getInventoryAssemblyItem(data){
      if(data){
        this.add_item_dialog = false
        this.fetchItemsList(this.form.invoice_type);
      }
    },
    getBillDataInfo(data){
      if(data){
        this.filterBillInfo(data)
      }
    }
  }
}
</script>

<style scoped>

</style>