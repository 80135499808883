import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  rolesAndPermissionListData: null,
  innerUsersList: null,
  rolesAndPermissionListError: null
};

const getters = {
  getRolesAndPermissionListData: state => state.rolesAndPermissionListData,
  getInnerUsersList: state => state.innerUsersList,
  getRolesAndPermissionListError: state => state.rolesAndPermissionListError
};

const actions = {
  async getUserRolesList({ commit }) {
    await axios
      .get(IP + "company/roles?paginate=" + 0)
      .then(response => {
        commit("setRolesAndPermissionListData", response);
      })
      .catch(error => {
        commit("setRolesAndPermissionError", error);
      });
  },
  async fetchInnerUsers({ commit }, data) {
    await axios
      .get(
        IP + "company/staff?page=" + data.page + "&per_page=" + data.per_page
      )
      .then(response => {
        commit("setInnerUsersList", response);
      })
      .catch(error => {
        commit("setRolesAndPermissionError", error);
      });
  }
};

const mutations = {
  setRolesAndPermissionListData: (state, response) => {
    state.rolesAndPermissionListData = response.data;
  },
  setInnerUsersList: (state, response) => {
    state.innerUsersList = response.data;
  },
  setRolesAndPermissionError: (state, error) => {
    state.rolesAndPermissionListError = error.response
      ? error.response.data
      : "Error: Network Error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
