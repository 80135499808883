<template>
  <span>

        <v-row>
          <v-col cols="4">

               <v-alert dismissible type="error" v-if="formItemErrorMessage">
                  {{ formItemErrorMessage }}
                </v-alert>

             <v-autocomplete
                 :loading="itemsLoader"
                 outlined
                 :disabled="lockItemType"
                 v-model="itemType"
                 :items="itemTypeItemList"
                 item-value="id"
                 color="#1976d2"
                 label="Item Type"
                 dense
                 hide-selected
                 clearable
             ></v-autocomplete>
          </v-col>
        </v-row>

     <transition name="fade">
            <service-item
                v-if="itemType === 'SRV'"
                :itemType="itemType"
                v-on:itemsErrorRemoval="itemsErrorRemoval"
                ref="service_component"
                :serviceDataInfo="serviceDataInfo"
            >
            </service-item>


            <inventory-part-item
                v-if="itemType === 'IVP'"
                :itemType="itemType"
                ref="inventory_part_item_component"
                v-on:itemsErrorRemoval="itemsErrorRemoval"
                :inventoryPartDataInfo="inventoryPartDataInfo"
            >
            </inventory-part-item>

             <inventory-assembly-item
                 v-if="itemType === 'IVA'"
                 :itemType="itemType"
                 ref="inventory_assembly_item_component"
                 v-on:itemsErrorRemoval="itemsErrorRemoval"
                 :inventoryAssemblyDataInfo="inventoryAssemblyDataInfo"
             >
            </inventory-assembly-item>
     </transition>


  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import constants from "../../../../global/configs.json";
import ServiceItem from "@/modules/dashboard/accounting/Item/newItem/ServiceItem";
import InventoryPartItem from "@/modules/dashboard/accounting/Item/newItem/InventoryPartItem";
import InventoryAssemblyItem from "@/modules/dashboard/accounting/Item/newItem/InventoryAssemblyItem";

export default {
  name: "AddItem",
  components: {InventoryAssemblyItem, InventoryPartItem, ServiceItem},
  props: {
    itemTypeProp: String,
    itemsDataList: {},
    editItem: String,
    filterItems: {}
  },
  data() {
    return {
      showSubItemCategory: false,
      addItemLoader: false,
      addSuperItemDialog: false,
      itemTypeItemList: [],
      subItemDataList: [],
      unitDataList: [],
      itemType: null,
      add_item_unit_dialog: false,
      formItemErrorMessage: null,
      show_unit_of_measure: false,
      show_super_item_check_box: false,
      current_asset_dialog: false,
      currentAssetAccountDataList: [],
      show_current_asset_account: false,
      show_income_account: false,
      income_dialog: false,
      cost_of_goods_dialog: false,
      incomeAccountDataList: [],
      show_stock_item_elements: false,
      costOfGoodsAccountDataList: [],
      add_item_supplier_dialog: false,
      supplierDataObj: [],
      show_tax_item: false,
      show_item_form_components: false,
      addTaxDialog: false,
      disableItemType: false,
      taxItemDataObj: [],
      show_assembly_part_elements: false,
      inventoryPartDataObj: [],
      showTaxItems: false,
      dont_show_supplier: true,
      service_selling_price: false,
      bill_of_items: [],
      lockItemType: false,
      itemsLoader:false,
      serviceDataInfo: {},
      inventoryPartDataInfo: {},
      inventoryAssemblyDataInfo: {},
      filteredItemsList: []
    };
  },
  mounted() {
    this.getFilteredItems()
    this.doGetItemType(this.$company.number);
    this.doGetUnitDataList();
    this.fetchProfiles()
    this.changeItemsDataEdit();
    this.itemsLoader = true
  },
  methods: {
    ...mapActions([
      "saveItemData",
      "doGetItemType",
      "doGetSubItemData",
      "doGetUnitDataList",
      "doFetchAccountData",
      "doGetSupplierDataList",
      "getTaxItemAccountData",
      "fetchStockItems",
      "doGetItemsInfo",
      "fetchProfiles",
      "fetchStockedAssemblyItems"
    ]),
    saveItem(evt) {
      evt.preventDefault();
      this.formItemErrorMessage = null;
      if (this.itemType == null || this.itemType === "") {
        this.formItemErrorMessage = "Item type is required.";
        return false;
      }

      if (
          this.itemType === constants.SERVICE_CODE ||
          this.itemType === constants.INVENTORY_PART_CODE ||
          this.itemType === constants.INVENTORY_ASSEMBLY_CODE ||
          this.itemType === constants.NON_INVENTORY_PART_CODE
      ) {
        if (this.form.unit_number === null) {
          let confirm_unit = confirm(
              "Are you sure you want to continue without a unit of measure"
          );
          if (!confirm_unit) {
            return false;
          }
        }
      }

      this.addItemLoader = true;
      // alert(JSON.stringify(this.form))
      let obj = {};
      let itemFormData = this.form;
      itemFormData.itemType = this.itemType;
      itemFormData.bill_of_items = this.bill_of_items;

      obj = {
        data: itemFormData,
        endPoint: this.editItem ? this.editItem : "items/new-item"
      };

      this.saveItemData(obj);
    },
    filterItemTypes(itemType) {
      if(itemType) {
        let itemTypeObj = [];
        let itemTypeJson = {};
        this.itemsLoader = false


        itemType.forEach(list => {
          itemTypeJson = {
            text: list.name,
            id: list.ref_code
          };

          if (this.itemTypeProp && this.itemTypeProp === list.ref_code) {
            this.itemType = list.ref_code;
            this.disableItemType = true;
          }

          if (this.filteredItemsList.length > 0) {
            this.filteredItemsList.forEach(lst => {
              if (lst === list.ref_code) {
                itemTypeObj.push(itemTypeJson);
              }
            })

          } else {
            itemTypeObj.push(itemTypeJson);
          }
        })

        this.itemTypeItemList = itemTypeObj;
      }
    },
    filterSubItemData(subItemData) {
      let subItemObj = [];
      let subItemJson = {};
      for (let i = 0; i < subItemData.length; i++) {
        subItemJson = {
          text: subItemData[i].full_name,
          id: subItemData[i].number
        };
        subItemObj.push(subItemJson);
      }

      this.subItemDataList = subItemObj;

      if (subItemData.length > 0) {
        this.show_super_item_check_box = true;
      } else {
        this.show_super_item_check_box = false;
      }
    },
    addItemUnitData() {
      this.add_item_unit_dialog = true;
    },
    filterUnits(unitDataList) {
      let unitJsn = {};
      let unitObj = [
        {
          text: "Select unit",
          id: null
        }
      ];
      for (let i = 0; i < unitDataList.length; i++) {
        unitJsn = {
          text:
              unitDataList[i].name + " ( " + unitDataList[i].abbreviation + " )",
          id: unitDataList[i].number
        };
        unitObj.push(unitJsn);
      }

      this.unitDataList = unitObj;
    },
    addSuperItem() {
      this.addSuperItemDialog = true;
    },
    addAccountModel() {
      this.current_asset_dialog = true;
    },
    addIncomeAccountModel() {
      this.income_dialog = true;
    },
    fetchAccountData(accountType) {
      this.doFetchAccountData(accountType);
    },
    addCostOfGoodsAccountModel() {
      this.cost_of_goods_dialog = true;
    },
    addItemSupplierData() {
      this.add_item_supplier_dialog = true;
    },
    addTaxAccountModel() {
      this.addTaxDialog = true;
    },
    removeInventoryPartItem(index) {
      this.bill_of_items.splice(index, 1);
    },
    computeNumber(value, vModal, index = null) {
      ///this removes any value that starts with a zero
      value = value.toString().replace(/,/g, "");
      if (!isNaN(value)) {
        value = value * 1;
      }

      if (vModal === "buying_price") {
        this.form.buying_price =
            eval(value) >= 0 ? eval(value).toFixed(2) : null;
      } else if (vModal === "selling_price") {
        this.form.selling_price =
            eval(value) >= 0 ? eval(value).toFixed(2) : null;
      } else if (vModal === "quantity") {
        this.bill_of_items[index].number_of_items =
            eval(value) >= 0 ? eval(value).toFixed(2) : null;
      } else if (vModal === "item_quantity") {
        this.form.quantity = eval(value) >= 0 ? eval(value).toFixed(2) : null;
      } else if (vModal === "rate") {
        let result = eval(value);

        if (result > 100) {
          result = 100;
        } else if (result < 1) {
          result = null;
        }

        this.form.rate = result;
      }
    },
    changeItemsDataEdit() {
      if (this.itemsDataList) {
        this.formItemErrorMessage = ""
        this.doGetItemsInfo(this.itemsDataList.number);
      }

    },
    itemsErrorRemoval(data) {
      if (data) {
        this.formItemErrorMessage = ""
      }
    },
    getFilteredItems() {
      if (this.filterItems) {
        this.filteredItemsList = this.filterItems
      }
    }
  },
  computed: {
    ...mapGetters([
      "getItemData",
      "getItemDataErrorResponse",
      "getItemTypeData",
      "getSubItemData",
      "getUnitDataList",
      "getUnitData",
      "getChartOfAccountsData",
      "getCurrentAssetsAccountDataSet",
      "getIncomeAccountDataSet",
      "getCostOfGoodsAccountDataSet",
      "getProfileResponseData",
      "getTaxItemDataList",
      "getStockItemsDataSet",
      "getStockInfo",
      "getProfileDataList",
      "getInventoryPartItemError",
      "getInventoryAssemblyItemError",
      "getNewServiceItemData",
      "getItemListErrorResponse",
      "getUpdateErrorMessage",
      "getUpdateItemData"
    ])
  },
  watch: {
    filterItems() {
      this.getFilteredItems()
    },
    getItemDataErrorResponse(itemError) {
      if (itemError) {
        this.formItemErrorMessage = itemError.message;
        this.errorToast("Failed", itemError.message);
        this.$refs["service_component"].addItemLoader = false
      }
    },
    getInventoryPartItemError(itemError) {
      if (itemError) {
        this.formItemErrorMessage = itemError.message;
        this.errorToast("Failed", itemError.message);
        this.$refs["inventory_part_item_component"].addItemLoader = false
      }
    },
    getInventoryAssemblyItemError(itemError) {
      if (itemError) {
        this.formItemErrorMessage = itemError.message;
        this.errorToast("Failed", itemError.message);
        this.$refs["inventory_assembly_item_component"].addItemLoader = false
      }
    },
    getNewServiceItemData(data) {
      if (data) {
        this.formItemErrorMessage = ""
      }
    },
    getItemTypeData(itemType) {
      this.filterItemTypes(itemType);
    },
    /* itemType() {
       this.getSubItemList();
     },*/
    getSubItemData(subItemData) {
      this.filterSubItemData(subItemData);
    },
    getUnitDataList(unitDataList) {
      this.filterUnits(unitDataList);
    },
    getUnitData(unitData) {
      if (unitData) {
        this.form.unit_number = unitData.data.number;
        this.$refs["unit_components"].addUnitLoader = false;
        this.$refs["unit_components"].form = {
          name: null,
          abbreviation: null
        };
        this.add_item_unit_dialog = false;
        this.doGetUnitDataList();
        this.successToast("Success", unitData.message);
      }
    },
    getCurrentAssetsAccountDataSet(accountData) {
      this.currentAssetAccountDataList = this.accountFilter(accountData);
    },
    getIncomeAccountDataSet(accountData) {
      this.incomeAccountDataList = this.accountFilter(accountData);
    },
    getCostOfGoodsAccountDataSet(accountData) {
      this.costOfGoodsAccountDataList = this.accountFilter(accountData);
    },
    getChartOfAccountsData(chartData) {
      if (chartData) {
        this.$refs["chart_of_account_components"].chartSpinner = false;
        this.$refs["chart_of_account_components"].form = {
          name: null,
          code: null,
          description: null,
          sub_account: null,
          number: null
        };
        this.current_asset_dialog = false;
        this.income_dialog = false;
        this.cost_of_goods_dialog = false;
        this.successToast("Success", chartData.message);

        // alert(chartData.data.ref_code)
        if (chartData.data.ref_code === "CAST") {
          this.form.current_assets_account_number = chartData.data.number;
        } else if (chartData.data.ref_code === "INC") {
          this.form.income_account_number = chartData.data.number;
        } else if (chartData.data.ref_code === "COS") {
          this.form.cogs_account_number = chartData.data.number;
        }

        this.fetchAccountData("CAST");
        this.fetchAccountData("INC");
        this.fetchAccountData("COS");
      }
    },
    getProfileDataList(supplierData) {
      this.supplierDataObj = this.nameNumberFilter(supplierData);
    },
    getTaxItemDataList(taxData) {
      this.taxItemDataObj = this.nameNumberFilter(taxData);
    },
    itemTypeProp(type) {
      if (type == null) {
        return;
      }

      this.itemTypeProp = type;

      this.filterItemTypes(this.getItemTypeData);
    },
    getStockItemsDataSet(stockItemsData) {
      this.inventoryPartDataObj = this.stockItemsFilter(stockItemsData);
      this.addInventoryPartItem();
    },
    itemsDataList() {
      this.changeItemsDataEdit();
    },
    showTaxItems() {
      //alert(value)
      this.form.tax_item_numbers = null;
    },
    getStockInfo(itemsInfoData) {
      if (itemsInfoData) {
        this.itemType = itemsInfoData.ref_code;
        this.lockItemType = true

        if (itemsInfoData.type === "Service") {
          this.serviceDataInfo = itemsInfoData
        }
        if (itemsInfoData.type === "Inventory Item") {
          this.inventoryPartDataInfo = itemsInfoData
        }

        if (itemsInfoData.type === "Assembled Item") {
          this.inventoryAssemblyDataInfo = itemsInfoData
        }
      }
    },
    getItemListErrorResponse(data) {
      if (data) {
        this.formItemErrorMessage = data.message
      }
    },
    getUpdateErrorMessage(data) {
      if (data) {
        this.formItemErrorMessage = data.message
      }
    },
    getUpdateItemData(data) {
      if (data) {
        this.formItemErrorMessage = ""
      }
    }
  }
};
</script>

<style scoped>
tbody tr:hover {
  background-color: transparent !important;
}

.fade-enter-active, .fade-leave-active {
  transition: all .7s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}


</style>
