var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-auto mt-8"},[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSearch($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"search":_vm.search,"loading":_vm.tableLoader,"loading-text":"Loading... please wait","page":_vm.page,"items-per-page":_vm.items_per_page,"server-items-length":_vm.server_items_length,"options":_vm.pagination,"no-data-text":"No Depreciation Item found.","footer-props":{
                    'itemsPerPageOptions': _vm.items_per_page_options,
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text':'Depreciation per page'
                  }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
                  var pagination = ref.pagination;
                  var options = ref.options;
                  var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"Depreciation per page","items-per-page-options":_vm.items_per_page_options,"showFirstLastPage":true,"firstIcon":"mdi-arrow-collapse-left","lastIcon":"mdi-arrow-collapse-right","prevIcon":"mdi-chevron-left","nextIcon":"mdi-chevron-right"},on:{"update:options":updateOptions}})]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getStatusColor(item.status),"small":"","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [(item.status !== 'Fully paid')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"25"},on:{"click":function($event){return _vm.makePayment(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-cash ")])]}}],null,true)},[_c('span',[_vm._v("Pay Liability")])]):_vm._e(),(!item.locked)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.editLiability(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Liability")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteLiabilities(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Liability")])])],1):_vm._e()]}}])}),_c('v-dialog',{attrs:{"width":"70%"},model:{value:(_vm.editTransactionsDialog),callback:function ($$v) {_vm.editTransactionsDialog=$$v},expression:"editTransactionsDialog"}},[_c('v-card',[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"95%","padding-bottom":"20px"}},[_c('v-row',[_c('v-card-title',[_vm._v("Edit Liability")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.editTransactionsDialog = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('new-current-liability',{ref:"addExpenseItems",attrs:{"account_type":_vm.account_type}})],1)])],1),_c('v-dialog',{attrs:{"width":"40%"},model:{value:(_vm.payLiabilityDialog),callback:function ($$v) {_vm.payLiabilityDialog=$$v},expression:"payLiabilityDialog"}},[_c('v-card',[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"96%","padding-bottom":"20px"}},[_c('v-row',[_c('v-card-title',[_vm._v("Pay Liability")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.payLiabilityDialog = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('pay-liability',{ref:"liabilityPaymentComponents",attrs:{"invoiceInfo":_vm.liabilityDataInfo,"account_type":_vm.account_type,"receiptType":"Bill"}})],1)])],1),_c('ConfirmDialog',{ref:"deleteInvoicePaymentTransaction"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }