<template>
  <span>
    <v-alert type="error" v-if="stockErrorMessage">
      {{ stockErrorMessage }}
    </v-alert>


    <form ref="form" @submit.prevent="saveStock">


       <v-row>
            <v-col cols="6">

               <v-row>
                   <v-col cols="6">
                   <v-autocomplete
                       hide-details
                       outlined
                       v-model="form.storage_account_number"
                       :items="storageAccountDataList"
                       item-value="id"
                       color="pink"
                       label="Bank/Cash Account *"
                       append-outer-icon="mdi-plus"
                       @click:append-outer="storageAccountModel"
                       dense
                   ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                    <v-select
                        outlined
                        hide-details
                        v-model="form.payment_method_number"
                        :items="paymentMethodDataList"
                        item-value="id"
                        color="pink"
                        label="Payment Method"
                        append-outer-icon="mdi-plus"
                        @click:append-outer="paymentMethodModel"
                        dense
                    ></v-select>
                </v-col>
              </v-row>

              <v-row>

                <v-col cols="6">
                   <v-text-field
                       v-model="form.reference"
                       label="Reference No"
                       maxlength="17"
                       outlined
                       dense
                       hide-details
                   ></v-text-field>
                </v-col>

                   <v-col cols="6">
                   <v-text-field
                       v-model="form.invoice_date"
                       label="Date [ dd / mm / yyy ]"
                       outlined
                       dense
                       type="date"
                       required
                       hide-details
                   ></v-text-field>
                      <small style="color: red;" v-if="yearLengthValidator(form.invoice_date)">
                Invalid Date
              </small>
                </v-col>

              </v-row>


               <v-row>
                <v-col cols="6">
                    <v-autocomplete
                        outlined
                        clearable
                        v-model="form.profile_number"
                        :items="supplierDataObj"
                        item-value="id"
                        color="pink"
                        label="Supplier"
                        append-outer-icon="mdi-plus"
                        @click:append-outer="addSupplierData"
                        @change="newSupplierData(form.profile_number)"
                        required
                        dense
                        hide-details
                    ></v-autocomplete>
                </v-col>
               </v-row>




            </v-col>

             <v-col cols="6">
          <v-textarea
              v-model="form.notes"
              label="Memo"
              outlined
              rows="4"
              dense
          ></v-textarea>
        </v-col>

          </v-row>


      <v-row>

        <v-col>
          <p>Items on this stock</p>

          <v-divider />
          <v-row>
            <v-col cols="12">
              <v-simple-table dense >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Item</th>
                      <th class="text-left">Memo</th>
                      <th style="width: 15%;" class="text-left">Unit</th>
                      <th style="width: 10%;" class="text-left">Tax</th>
                      <th style="width: 10%;" class="text-left">Quantity</th>
                      <th class="text-left">Unit Price</th>
                      <th class="text-left">Amount</th>
                      <th class="text-left" colspan="2">Selling Price Per Unit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(stockItemsData, index) in form.bill_of_items"
                      :key="index"
                    >
                      <td style="border-right: 1px solid #e0e0e0; padding: 0">
                        <v-autocomplete
                          flat
                          solo
                          v-model="stockItemsData.item_number"
                          :items="stockItemsData.items"
                          item-value="id"
                          color="pink"
                          label=""
                          append-outer-icon="mdi-plus"
                          @click:append-outer="addItemData"
                          required
                          dense
                          hide-details
                          @change="
                            addStockAutoCompute(
                              stockItemsData.item_number,
                              index
                            )
                          "
                        ></v-autocomplete>
                      </td>

                      <td style="border-right: 1px solid #e0e0e0; padding: 0">
                        <v-textarea
                            dense
                            no-resize
                            flat
                            solo
                            v-model="stockItemsData.notes"
                            rows="1"
                            hide-details
                        ></v-textarea>
                      </td>
                           <td style="border-right: 1px solid #e0e0e0; padding: 0">
                        <v-autocomplete
                            hide-details
                            flat
                            solo
                            v-model="stockItemsData.unit_number"
                            :items="unitDataObj"
                            item-value="id"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="addUnitData"
                            required
                            clearable
                            dense
                        ></v-autocomplete>
                      </td>
                      <td style="border-right: 1px solid #e0e0e0; padding: 0">
                         <v-autocomplete
                             flat
                             solo
                             v-model="stockItemsData.tax_number"
                             :items="taxItemDataObj"
                             item-value="id"
                             color="pink"
                             label=""
                             hide-selected
                             dense
                             clearable
                             hide-details
                             append-outer-icon="mdi-plus"
                             @click:append-outer="addTaxModel"
                         ></v-autocomplete>
                       </td>
                      <td style="border-right: 1px solid #e0e0e0; padding: 0">
                        <v-text-field
                          @keypress="onlyNumberValidator($event)"
                          v-model="stockItemsData.quantity"
                          label=""
                          flat
                          solo
                          dense
                          hide-details
                          required
                          v-on:blur="
                            computeValues(
                              stockItemsData.quantity,
                              index,
                              'quantity'
                            )
                          "
                        ></v-text-field>
                      </td>

                      <td style="border-right: 1px solid #e0e0e0; padding: 0;">
                        <v-text-field
                          @keypress="onlyNumberValidator($event)"
                          v-model="stockItemsData.buying_price"
                          label=""
                          flat
                          solo
                          dense
                          required
                          hide-details
                          v-on:blur="
                            computeValues(
                              stockItemsData.buying_price,
                              index,
                              'buying_price'
                            )
                          "
                        ></v-text-field>
                      </td>
                      <td style="border-right: 1px solid #e0e0e0; padding: 0;">
                        <v-text-field
                            readonly
                          @keypress="onlyNumberValidator($event)"
                          v-model="stockItemsData.amount"
                          label=""
                          flat
                          solo
                          dense
                          required
                          hide-details
                        ></v-text-field>
                      </td>
                      <td style="padding: 0;">
                        <v-text-field
                          @keypress="onlyNumberValidator($event)"
                          v-model="stockItemsData.selling_price"
                          label=""
                          flat
                          solo
                          dense
                          hide-details
                          required
                          v-on:blur="
                            computeValues(
                              stockItemsData.selling_price,
                              index,
                              'selling_price'
                            )
                          "
                        ></v-text-field>
                        <small style="color: red;" v-if="stockItemsData.sellingPriceWarning">{{stockItemsData.sellingPriceWarning}}</small>
                      </td>
                      <td width="1%">
                        <v-icon
                          @click="removeStockItem(index)"
                          v-if="index !== 0"
                          style="font-size: 20px;"
                          >mdi-close</v-icon
                        >
                      </td>
                    </tr>

                    <tr>
                  <td colspan="6"></td>
                  <td class="pa-0">
                  <div style="font-size: 15px;"><b>Total:&nbsp;{{totalAmount}}</b>  </div>
                  </td>
                  <td colspan="2"></td>
                </tr>

                     <tr>
                  <td
                      colspan="9"
                      style="cursor: pointer;"
                  >
                    <div>

                       <v-btn

                           @click="addStockItem"
                           x-small
                           dark
                           color="indigo"
                       >
                          Add another stock item
                        </v-btn>


                           <v-btn

                               @click="showOtherExpenses"
                               x-small
                               class="float-right"
                               outlined
                               color="indigo"
                           >
                             {{ !showAdditionalItems ? "Show" : "Hide" }}   Other Expenses
                        </v-btn>
                    </div>
                      </td>
                </tr>


                  </tbody>
                </template>
              </v-simple-table>



                <p v-if="showAdditionalItems" class="ml-5 mt-8">Additional Costs</p>


         <v-simple-table v-if="showAdditionalItems" class="table" dense style="width: 100%; border-top: 1px solid #eeebeb; border-bottom: 1px solid #eeebeb;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Category</th>
                      <th class="text-left">Memo</th>
                      <th class="text-left" colspan="2">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="(expenseItemData, key) in form.expense_items"
                        v-bind:key="key"
                    >
                      <td style="border-right: 1px solid #e0e0e0; width: 24%;">
                        <v-text-field
                            v-model="expenseItemData.name"
                            flat
                            solo
                            dense
                            hide-details
                        ></v-text-field>
                      </td>
                      <td style="border-right: 1px solid #e0e0e0; width: 20%;">
                        <v-autocomplete
                            v-model="expenseItemData.account_number"
                            :items="accountDataItem"
                            item-value="id"
                            color="pink"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="accountModel"
                            dense
                            flat
                            solo
                            hide-details
                        ></v-autocomplete>
                      </td>

                      <td style="border-right: 1px solid #e0e0e0; width: 20%;">
                        <v-text-field
                            v-model="expenseItemData.notes"
                            flat
                            solo
                            rows="1"
                            dense
                            no-resize
                            hide-details
                        ></v-text-field>
                      </td>

                      <td style="width: 20%;">
                        <v-text-field
                            v-model="expenseItemData.amount"
                            flat
                            solo
                            dense
                            hide-details
                            @keypress="onlyNumberValidator($event)"
                            v-on:blur="
                            computeExpense(expenseItemData.amount, key)
                          "
                        ></v-text-field>
                      </td>

                      <td width="1%">
                        <v-icon
                            @click="removesExpenseItem(key)"
                            style="font-size: 20px;"
                        >mdi-close</v-icon
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                          colspan="6"
                      >


                         <v-btn

                             @click="addExpenseItems"
                             x-small
                             dark
                             color="indigo"
                         >
                          Add another item
                        </v-btn>

                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>



              <v-divider></v-divider>
            </v-col>
          </v-row>



          <div class="text-right">
            <v-btn
              class="companyButton mt-4"
              :loading="btnStockSpinner"
              color="#0000ff"
              dark
              type="submit"
            >
              Save
            </v-btn>
          </div>



<p v-if="form.bill_of_items[0].item_number">Cost sheet</p>
             <v-simple-table dense v-if="form.bill_of_items[0].item_number" >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Item</th>
<!--                       <th class="text-left">Unit</th>-->
                       <th class="text-left">Quantity</th>
                      <th class="text-left">
                        Actual Cost
                        <v-tooltip color="#000000" right>
                          <template v-slot:activator="{ on, attrs }">

                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="ma-2"
                                outlined
                                x-small
                                fab
                                color="indigo"
                            >
                            <v-icon size="23">mdi-information-variant</v-icon>
                          </v-btn>
                          </template>
                          <span>
                            Formula:  (((BuyingPrice / TotalBuyingPrice) * TotalExpense) + (BuyingPrice))
                          </span>
                        </v-tooltip>


                      </th>
                      <th class="text-left" colspan="2">Selling Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="(stockItemsData, index) in form.bill_of_items"
                        :key="index"
                    >
                      <td style="border-right: 1px solid #e0e0e0;">
                        {{returnItemName(stockItemsData.item_number)}}
                      </td>
                       <td style="border-right: 1px solid #e0e0e0;">
                        {{stockItemsData.quantity}}
                      </td>
<!--                      <td style="border-right: 1px solid #e0e0e0;">
                        {{returnUnitName(stockItemsData.unit_number)}}
                      </td>-->
                      <td style="border-right: 1px solid #e0e0e0;">
                        {{computeActualBuyingPrice(stockItemsData.buying_price, stockItemsData.quantity, stockItemsData.selling_price, index)}}
                      </td>
                      <td>
                        {{computeSellingPrice(stockItemsData.selling_price)}}
                      </td>
                    </tr>
                  </tbody>
                </template>
             </v-simple-table>
          <v-divider></v-divider>



        </v-col>
      </v-row>
    </form>

    <!--      dialog to add items-->

    <!--      adds items-->
    <v-dialog v-model="add_item_dialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Item</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_item_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-item :filterItems="['IVP','IVA']" ref="items_components"></add-item>
        </div>
      </v-card>
    </v-dialog>

    <!--    adds unit-->
    <v-dialog v-model="add_unit_dialog" width="30%">
      <v-card>
        <div style="width: 95%; padding-bottom: 10px;" class="mx-auto">
          <v-row>
            <v-card-title> Add Unit</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_unit_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-unit ref="unit_components"></add-unit>
        </div>
      </v-card>
    </v-dialog>

    <!--    suppliers dialog-->

    <v-dialog v-model="add_supplier_dialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Supplier</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_supplier_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
           <new-profile ref="add_customer_info"></new-profile>
        </div>
      </v-card>
    </v-dialog>

    <!--      end of dialog-->

    <!--    dialog to add current assets-->

    <v-dialog v-model="current_asset_dialog" width="35%">
      <v-card>
        <v-row class="mx-auto">
          <v-col class="mb-5">
            <p class="mb-8" style="font-size: 20px;">
              Add Account
            </p>
            <add-account
              account_type="CAST"
              ref="stock_item_components"
            ></add-account>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!--    dialog to add income account-->
    <v-dialog v-model="income_dialog" width="35%">
      <v-card>
        <v-row class="mx-auto">
          <v-col class="mb-5">
            <p class="mb-8" style="font-size: 20px;">
              Add Account
            </p>
            <add-account
              account_type="INC"
              ref="stock_item_components"
            ></add-account>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!--    dialog to add cost of goods account-->
    <v-dialog v-model="cost_of_goods_dialog" width="35%">
      <v-card>
        <v-row class="mx-auto">
          <v-col class="mb-5">
            <p class="mb-8" style="font-size: 20px;">
              Add Account
            </p>
            <add-account
              account_type="COS"
              ref="stock_item_components"
            ></add-account>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!--tax item dialog-->
    <v-dialog v-model="addAdditionalExpenseDialog" width="65%">
      <v-card>
        <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Expense</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addAdditionalExpenseDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <add-transaction
            account_type="EXP"
            ref="addExpenseItems"
          ></add-transaction>
        </div>
      </v-card>
    </v-dialog>




    <!--discounts dialog-->
    <v-dialog v-model="addDiscountDialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Discount</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addDiscountDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-discount ref="add_discount_items"></add-discount>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <add-account
              :account_type_list="['EXP','COS']"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

    <!--tax dialog-->
    <v-dialog v-model="addTaxDialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Tax</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addTaxDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-tax ref="new_tax_component"></new-tax>
        </div>
      </v-card>
    </v-dialog>

    <!--    adds Storage accounts -->

    <v-dialog v-model="storage_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="storage_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <storage-account ref="storageAccountComponent"></storage-account>
        </div>
      </v-card>
    </v-dialog>


    <!--                    payment methods-->

    <v-dialog v-model="payment_methods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Payment Methods</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payment_methods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <payment-methods ref="paymentMethodsComponents"></payment-methods>
        </div>
      </v-card>
    </v-dialog>

  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddAccount from "./AddAccount";
import AddUnit from "./AddUnit";
import AddItem from "./AddItem";
import AddTransaction from "./AddTransaction";
import AddDiscount from "@/modules/dashboard/accounting/commons/AddDiscount";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";
import NewTax from "@/modules/dashboard/Settings/tax/NewTax";
import StorageAccount from "@/modules/dashboard/accounting/commons/StorageAccount";
import PaymentMethods from "@/modules/dashboard/accounting/commons/PaymentMethods";

export default {
  name: "AddStock",
  props:{
    payment_type: String
  },
  components: {
    PaymentMethods,
    StorageAccount,
    NewTax,
    NewProfile,
    AddDiscount,
    AddItem,
    AddUnit,
    AddAccount,
    AddTransaction
  },
  data() {
    return {
      payment_methods_dialog: false,
      absoluteDiscount: false,
      stockErrorMessage: null,
      btnStockSpinner: null,
      add_item_dialog: false,
      add_unit_dialog: false,
      addTaxDialog: false,
      add_supplier_dialog: false,
      ItemDataObj: [],
      unitDataObj: [],
      supplierDataObj: [],
      taxItemDataObj: [],
      cost_of_goods_dialog: false,
      income_dialog: false,
      current_asset_dialog: false,
      addAdditionalExpenseDialog: false,
      additionalCostDataObj: [],
      discountDataObj: [],
      addDiscountDialog: false,
      paymentTypeData:null,
      discountListUnfiltered: [],
      showAdditionalItems:false,
      accountDataItem: [],
      account_dialog: false,
      storageAccountDataList: [],
      storage_account_dialog: false,
      paymentMethodDataList: [],
      form: {
        discounts: [],
        absolute_discounts: null,
        storage_account_number: null,
        payment_method_number: null,
        item_number: null,
        reference: null,
        unit_number: null,
        profile_number: null,
        notes: null,
        invoice_date: new Date().toISOString().substr(0, 10),
        payment_type: null,
        bill_of_items: [
          {
            amount:null,
            item_number: "",
            buying_price: null,
            selling_price: null,
            unit_number: null,
            quantity: null,
            notes: null,
            code: null,
            tax_number:null,
            sellingPriceWarning:null,
            items:[]
          }
        ],
        // expense_items: [],
        expense_items: []
      },
      unfilteredItems:[],
      totalAmount:null
    };
  },
  mounted() {
    this.doGetStockableItems();
    this.doGetUnitDataList();
    this.fetchProfiles();
    this.doGetStorageAccountData();
    this.doGetPaymentMethodData();
    //fetch chart of accounts
    this.fetchAccountData("CAST");
    this.fetchAccountData("INC");
    this.fetchAccountData("COS");
    /// fetch expense items
    let data = {
      company_number: this.$company.number,
      type: "EXP"
    };

    this.fetchTransactions(data);


    this.fetchAccountsAdditionalCosts()

    this.fetchTaxData();

    this.fetchDiscountList();

    if(this.payment_type){
      this.checkPaymentType()
    }
  },
  methods: {
    ...mapActions([
      "doGetStockableItems",
      "doGetUnitDataList",
      "fetchProfiles",
      "doSaveStock",
      "doFetchAccountData",
      "fetchTransactions",
      "fetchDiscountList",
      "doGetAccountData",
      "fetchTaxData",
      "doGetStorageAccountData",
      "doGetPaymentMethodData"
    ]),
    fetchAccountsAdditionalCosts(){
      this.doGetAccountData("EXP,COS");
    },
    accountModel() {
      this.account_dialog = true;
    },
    saveStock() {
      this.btnStockSpinner = true;
      this.stockErrorMessage = ""
      let formData  = this.form
          formData.payment_type = this.paymentTypeData
      this.doSaveStock(formData);
    },
    addItemData() {
      this.add_item_dialog = true;
    },

    addUnitData() {
      this.add_unit_dialog = true;
    },
    addTaxModel(){
      this.addTaxDialog = true;
    },
    filterUnits(unitDataList) {
      let unitJsn = {};
      let unitObj = [
        {
          text: "Select unit",
          id: null,
          disabled: true
        }
      ];
      for (let i = 0; i < unitDataList.length; i++) {
        unitJsn = {
          text:
            unitDataList[i].name + " ( " + unitDataList[i].abbreviation + " )",
          id: unitDataList[i].number,
          disabled: false
        };
        unitObj.push(unitJsn);
      }

      this.unitDataObj = unitObj;
    },
    addNewProfile(data) {
      alert(data)
    },
    newSupplierData(data) {
      if(data ==="new-profile"){
        this.form.profile_number = ""
        this.add_supplier_dialog = true;
      }
    },
    addSupplierData() {
        this.add_supplier_dialog = true;
    },
    addStockItem() {

      let items = [];

      this.ItemDataObj.forEach(obj => {
        let item_val = obj.id;

        let selected_items = this.form.bill_of_items.filter(
            t => t.item_number === item_val
        );

        if (selected_items.length === 0) {
          items.push(obj);
        }
      });

      if(items.length > 0) {
      this.form.bill_of_items.push({
        amount: null,
        item_number: "",
        buying_price: null,
        selling_price: null,
        unit_number: null,
        quantity: null,
        notes: null,
        sellingPriceWarning: null,
        items: items
      });
      }else {
        this.errorToast("Error", "No item found!!")
      }

    },
    fetchAccountData(accountType) {
      this.doFetchAccountData(accountType);
    },
    addAccountModel() {
      this.current_asset_dialog = true;
    },
    addIncomeAccountModel() {
      this.income_dialog = true;
    },
    addCostOfGoodsAccountModel() {
      this.cost_of_goods_dialog = true;
    },
    removeStockItem(index) {
      this.form.bill_of_items.splice(index, 1);
    },
    addAdditionalExpenseModel() {
      this.addAdditionalExpenseDialog = true;
    },
    computeValues(value, index, vModal) {
      if (value) {
        ///this removes any value that starts with a zero
        value = value.toString().replace(/,/g, "");
        if (!isNaN(value)) {
          value = value * 1;
        }
        if (vModal === "quantity") {
          this.form.bill_of_items[index].quantity =
            eval(value) >= 0 ? eval(value).toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
        } else if (vModal === "buying_price") {
          this.form.bill_of_items[index].buying_price =
            eval(value) >= 0 ? eval(value).toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
        } else if (vModal === "selling_price") {
          this.form.bill_of_items[index].selling_price =
            eval(value) >= 0 ? eval(value).toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
        }
      }
    },
    addDiscountsModel() {
      this.addDiscountDialog = true;
    },
    checkPaymentType(){
      this.paymentTypeData = this.payment_type
    },
    filterDiscountsData(dataList) {
      let dataObj = [{ text: "Select discount", id: [], disabled: true }];

      dataList.forEach(item=>{
            dataObj.push(
                {
                  text: item.name + " ( " + item.rate + "% )",
                  id: item.number
                }
            )
          }

      )


      return dataObj;
    },
    addStockAutoCompute(value, index){

      let stockItemInfo = this.unfilteredItems
      let quantity = 1;
      let buyingPrice = null
      let sellingPrice = null
      let unit_number = null
      let amount = null

      stockItemInfo.forEach(itemsNumber =>{
            if(value === itemsNumber.number){
              buyingPrice = itemsNumber.buying_price
              sellingPrice = itemsNumber.selling_price
              unit_number = itemsNumber.unit ? itemsNumber.unit.number : null
              amount = (quantity * buyingPrice)
            }
      })


        this.form.bill_of_items[index].unit_number =  unit_number
        this.form.bill_of_items[index].quantity =  quantity.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        this.form.bill_of_items[index].buying_price = buyingPrice ?
                                                      buyingPrice
                                                          .toFixed(2)
                                                          .toString()
                                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0

        this.form.bill_of_items[index].selling_price = sellingPrice ?
                                                      sellingPrice
                                                          .toFixed(2)
                                                          .toString()
                                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0

      this.form.bill_of_items[index].amount = amount ?
                                                      amount
                                                      .toFixed(2)
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0




    },
    showOtherExpenses(){
      this.showAdditionalItems =  !this.showAdditionalItems
      if(this.showAdditionalItems){
        this.form.expense_items = [
          {
            number: null,
            name: null,
            amount: null,
            notes: null,
            account_number: null
          }
        ]
      }else {
        this.form.expense_items = []
      }
    },
    addExpenseItems() {
      this.form.expense_items.push({
        name: null,
        amount: null,
        notes: null,
        account_number: null
      });
    },
    storageAccountModel() {
      this.storage_account_dialog = true;
    },
    removesExpenseItem(key) {
      this.form.expense_items.splice(key, 1);
    },
    computeExpense(value, index) {
      if (value) {
        value = value + "";
        value = value.toString().replace(/,/g, "");

        if (!isNaN(value)) {
          value = value * 1;
        }

        this.form.expense_items[index].amount = eval(value)
            .toFixed(2)
            .toString()
            // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    filterCustomerList(customerList) {
      let dataObj = [
        { text: "Add Profile", id: "new-profile"},
        {text: "Select profiles", id: null, disabled: true}
      ];
      let dataJson = {};
      customerList.forEach(list =>{
        dataJson = {
          text: list.name,
          id: list.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })


      this.supplierDataObj = dataObj;
    },
    taxItemFilter(dataList) {
      let arr = [
        /*{ text: "Add Profile", id: "new-profile"},*/
        { text: "Select tax", id: "", disabled:true}
        ];
      let obj = {};
      dataList.forEach( list => {
            obj = {
              text: list.name+" ("+list.rate+" %)",
              id: list.number,
            };
            arr.push(obj);
          }
      )
      return arr;
    },
    newStockFilter(dataList) {
      let dataObj = [{ text: "Select item", id: "", disabled: true }];
      let dataJson = {};
      dataList.forEach(list => {
        dataJson = {
          text: list.name ? list.name : "",
          id: list.number ? list.number : "",
          disabled: false,
          buying_price:list.selling_price ? list.selling_price : "",
          selling_price:list.selling_price ? list.selling_price : "",
          tax: list.tax.length > 0 ? list.tax : [],
        };
        dataObj.push(dataJson);
      })

      return dataObj;
    },
    paymentMethodModel() {
      this.payment_methods_dialog = true;
    },
    returnUnitName(unitNumber){
      if(unitNumber){
        const item = this.unitDataObj.find(item => item.id === unitNumber);
        return item ? item.text : "";
      }
  },
    computeActualBuyingPrice(buyingPrice, quantity,  sellingPrice, index){

      buyingPrice  = buyingPrice.toString().replace(/,/g, "")
      quantity  = quantity.toString().replace(/,/g, "")
      sellingPrice  = sellingPrice.toString().replace(/,/g, "")
      buyingPrice = (quantity * buyingPrice)
      sellingPrice = (quantity * sellingPrice)

      //items cost
      let itemsList = this.form.bill_of_items[0].item_number ? this.form.bill_of_items : []
      let totalBuyingPrice = 0
      if(itemsList.length > 0){
        itemsList.forEach(lst => {
          totalBuyingPrice += (lst.quantity.toString().replace(/,/g, "") * 1) * (lst.buying_price.toString().replace(/,/g, "") * 1)
        })
      }

      //expense amount
      let expenseItems = this.form.expense_items.length ? this.form.expense_items : []
      let totalExpense = 0
      if(expenseItems.length > 0){
        expenseItems.forEach(list =>{
          totalExpense +=  (list.amount.toString().replace(/,/g, "") * 1)
        })
      }
      let actualCost = (((buyingPrice / totalBuyingPrice) * totalExpense) + (buyingPrice))


      this.form.bill_of_items[index].sellingPriceWarning =  (sellingPrice <= actualCost) ? "Price is less than or equal to actual Cost":null

      return (actualCost / quantity).toFixed(2).toString()
                 .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    },
    computeSellingPrice(data){
     return  data
    },
    returnItemName(itemNumber){
      if(itemNumber){
        const item = this.ItemDataObj.find(item => item.id === itemNumber);
        return item ? item.text : "";

      }
    },
    filterBillOfItemItemsData(data){
      if(data){
        let amountTotal = 0
        let dataInfo = this.form.bill_of_items
        if(dataInfo.length > 0){
          dataInfo.forEach(list => {
            let quantity = list.quantity.toString().replace(/,/g, "")
            let buying_price = list.buying_price.toString().replace(/,/g, "")
            let amountInfo = (quantity * buying_price)
            amountTotal += amountInfo
            list.amount = amountInfo.toFixed(2).toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.totalAmount = amountTotal.toFixed(2).toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          })
        }
      }
    }

  },
  computed: {
    ...mapGetters([
      "getItemData",
      "getStockableItems",
      "getUnitData",
      "getUnitDataList",
      "getProfileResponseData",
      "getProfileDataList",
      "getNewStockDataList",
      "getCurrentAssetsAccountDataSet",
      "getIncomeAccountDataSet",
      "getCostOfGoodsAccountDataSet",
      "getChartOfAccountsData",
      "getTransactionData",
      "getTransactionsDataSet",
      "getNewBillData",
      "getDiscountDataList",
      "getItemListErrorResponse",
      "getAccountDataSet",
      "getDiscountData",
      "getNewServiceItemData",
      "getInventoryPartItem",
      "getInventoryAssemblyItem",
      "getTaxDataAll",
      "getNewTaxData",
      "getStorageAccountDataSet",
      "getNewStorageAccountData",
      "getPaymentMethodDataSet",
    ])
  },
  watch: {
    getStorageAccountDataSet(storageAccountData) {
      this.storageAccountDataList = this.nameNumberFilter(storageAccountData);
    },

    getItemData(itemData) {
      if (itemData) {
        this.$refs["items_components"].addItemLoader = false;
        this.add_item_dialog = false;
        this.doGetStockableItems();
      }
    },
    getStockableItems(itemDataList) {
      this.unfilteredItems = itemDataList
      this.ItemDataObj = this.newStockFilter(itemDataList);
      this.form.bill_of_items[0].items = this.newStockFilter(itemDataList);
    },
    getUnitData(unitData) {
      if (unitData) {
        //this.addUnitLoader = false
        this.$refs["unit_components"].addUnitLoader = false;
        this.add_unit_dialog = false;
        this.doGetUnitDataList();
        //alert(JSON.stringify(unitData));
      }
    },
    getUnitDataList(unitDataList) {
      this.filterUnits(unitDataList);
    },
    getProfileResponseData(customerData) {
      if (customerData) {
        this.$refs["add_customer_info"].customerSpinner = false;
        this.$refs["add_customer_info"].form = {
          name: null,
          trading_name: null,
          address: null,
          number: null,
          tin: null,
          phoneContact: [
            {
              contact: null,
              id: null,
              type: "phone"
            }
          ],
          emailContact: [
            {
              contact: null,
              id: null,
              type: "email"
            }
          ],
          contactPersons: [
            {
              name: null,
              phone_contacts: [
                {
                  phone: null
                }
              ],
              email_contacts: [
                {
                  email: null
                }
              ],
            }
          ]
        };
        this.add_supplier_dialog = false;
        this.successToast("Success", customerData.message);
        this.form.profile_number = customerData.data.number;
        this.fetchProfiles();
      }
    },
    getProfileDataList(supplierDataList) {
      this.filterCustomerList(supplierDataList)
    },
    getChartOfAccountsData(chartData) {
      if (chartData) {
        this.$refs["chart_of_account_components"].chartSpinner = false;
        this.$refs["chart_of_account_components"].form = {
                                              name: null,
                                              code: null,
                                              description: null,
                                              sub_account: null,
                                              number: null
                                            };
        this.account_dialog = false;
        this.fetchAccountsAdditionalCosts()
        this.successToast("Success", chartData.message);
      }
    },
    getTransactionData(transactionData) {
      this.$notify({
        group: "foo",
        type: "success",
        title: "Success",
        text: JSON.stringify(transactionData.message)
      });

      this.$refs["addExpenseItems"].transactionLoader = false;
      this.$refs["addExpenseItems"].form = {};

      this.addAdditionalExpenseDialog = false;

      let data = {
        company_number: this.$company.number,
        type: "EXP"
      };

      this.fetchTransactions(data);
    },
    getTransactionsDataSet(additionalCostData) {
      this.additionalCostDataObj = this.nameNumberFilter(additionalCostData);
    },
    getNewBillData(billData) {
      if (billData) {
        this.$refs["bill_components"].btnBillSpinner = false;
        this.$refs["bill_components"].form = {
          reference: null,
          invoice_date: new Date().toISOString().substr(0, 10),
          due_date: new Date().toISOString().substr(0, 10),
          notes: null,
          profile_number: null,
          discounts: null,
          absolute_discounts: null,
          invoice_type: "supplier",
          items: [
            {
              item_number: null,
              quantity: null,
              item_discounts: null,
              unit_price: null
            }
          ],
          expense: {
            date: null,
            reference: null,
            storage_account_number: null,
            payment_method: null
          },
          expense_items: [
            {
              name: null,
              amount: null,
              notes: null,
              account_number: null
            }
          ]
        };
        this.successToast("Success", billData.message);

        let options = {
          profile_number: this.profile_number,
          page: this.page,
          per_page: this.items_per_page
        };

        this.doGetBillListData(options);

        //generate the next bill number
        this.getGeneratedBillNumber();
      }
    },
    payment_type(){
      this.checkPaymentType()
    },
    getDiscountDataList(discountData) {
      if(discountData){
         this.discountDataObj = this.filterDiscountsData(discountData);
         this.discountListUnfiltered = discountData;
      }

    },
    getItemListErrorResponse(error){
      if(error){
        this.btnStockSpinner = false
        this.stockErrorMessage = error.message
        this.errorToast("Error", error.message)
      }
    },
    getAccountDataSet(accountData) {
      this.accountDataItem = this.accountFilter(accountData);
    },
    getDiscountData(discountData) {
      if (discountData) {
        this.$refs["add_discount_items"].form = {
                                                name: null,
                                                rate: null,
                                                discount_number: null
                                              };
        this.$refs["add_discount_items"].addDiscountLoader = false;
        this.addDiscountDialog = false;
        this.fetchDiscountList();
        this.successToast("Success", discountData.message);
      }
    },
    getNewServiceItemData(data){
      if(data){
        this.add_item_dialog = false
        this.successToast("Success", data.message)
        this.doGetStockableItems();
      }
    },
    getInventoryPartItem(data){
      if(data){
        this.add_item_dialog = false
        this.successToast("Success", data.message)
        this.doGetStockableItems();
      }
    },
    getInventoryAssemblyItem(data){
      if(data){
        this.add_item_dialog = false
        this.successToast("Success", data.message)
        this.doGetStockableItems();
      }
    },
    getTaxDataAll(taxData) {
      if (taxData) {
        this.taxItemDataObj = this.taxItemFilter(taxData);
        this.taxListUnfiltered = taxData
      }

    },
    getNewTaxData(data){
      if(data){
        this.$refs["new_tax_component"].form = {
          name:null,
          rate:null,
          profile_number:null,
          is_compound:false,
          is_absolute:false
        };
        this.$refs["new_tax_component"].taxSpinner = false;
        this.addTaxDialog = false;
        this.fetchTaxData();
        this.successToast("Success", data.message);
      }
    },
    getNewStorageAccountData(storageAccountData) {
      if (storageAccountData) {
        this.$refs["storageAccountComponent"].form = {};
        this.$refs["storageAccountComponent"].addStorageLoader = false;
        this.storage_account_dialog = false;
        this.form.storage_account_number = storageAccountData.data.number;

        this.successToast("Success", storageAccountData.message);
        this.doGetStorageAccountData(this.$company.number);
      }
    },
    getPaymentMethodDataSet(paymentData) {
      this.paymentMethodDataList = this.nameNumberFilter(paymentData);
    },
    getNewPaymentMethods(paymentData) {
      if (paymentData) {
        this.$refs["paymentMethodsComponents"].form = {
                                                      name: null,
                                                      notes: null
                                                    };
        this.$refs["paymentMethodsComponents"].addPaymentMethodLoader = false;
        this.payment_methods_dialog = false;
        this.form.payment_method_number = paymentData.data.number;
        this.successToast("Success", paymentData.message);
        this.doGetPaymentMethodData();
      }
    },
    form:{
      handler(data){
        if(data) {
          this.filterBillOfItemItemsData(data)
        }
      },
      deep:true
    },

  }
};
</script>

<style scoped>
tbody tr:hover {
  background-color: transparent !important;
}
</style>
