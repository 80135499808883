import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
    transferError: null,
    transferSuccess: null,
    transferList: null,
    newTransfer: null,
};

const getters = {
    getTransferList: state => state.transferList,
    getTransferDetails: state => state.newTransfer,
    getTransferError: state => state.transferError,
    getTransferSuccess: state => state.transferSuccess,

};

const actions = {
    async doTransfer({commit}, data) {
        await axios
            .post(
                IP + "transactions/transfer",
                data
            )
            .then(response => {
                commit("setTransferSuccess", response);
            })
            .catch(error => {
                commit("setTransactionErrorMessage", error);
            });
    },

    async fetchTransferList({commit}) {
        await axios
            .get(IP + "transactions/transfer/list")
            .then(response => {
                commit("setTransferList", response);
            }).catch(error => {
                commit("setTransferError", error);
            });
    },

    async fetchTransferDetails({commit}, data) {
        await axios
            .get(IP + "transactions/transfer/details?number=" + data.number)
            .then(response => {
                commit("setTransferDetails", response);
            }).catch(error => {
                commit("setTransferError", error);
            });
    }
};
const mutations = {
    setTransfers: (state, response) => {
        state.transferList = response.data;
    },
    setTransferDetails: (state, response) => {
        state.newTransfer = response.data;
    }
    ,
    setTransferError: (state, error) => {
        state.transferError = error;
    },
    setTransferSuccess: (state, success) => {
        state.transferSuccess = success;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
