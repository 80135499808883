<template>
<span>
    <v-alert type="error" v-if="expenseError">
      {{ expenseError }}
    </v-alert>

    <form ref="form" @submit.prevent="saveTransaction">
      <v-row>
        <v-col cols="6">

          <v-text-field
              v-model="form.name"
              label="Name"
              outlined
              dense
              hide-details
              class="mb-3"
              required
          ></v-text-field>

          <v-text-field
              v-model="form.amount"
              label="Amount *"
              outlined
              dense
              hide-details
              required
              @keypress="onlyNumberValidator($event)"
              v-on:blur="compute(form.amount)"
          ></v-text-field>

          <v-row>
            <v-col cols="6">
               <v-text-field
                   v-model="form.date"
                   label="Date [ dd / mm / yyy ]"
                   outlined
                   dense
                   type="date"
                   required
                   hide-details
               ></v-text-field>
               <small style="color: red;" v-if="yearLengthValidator(form.date)">
                Invalid Date
              </small>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="form.due_date"
                  label="Due Date [ dd / mm / yyy ]"
                  outlined
                  dense
                  type="date"
                  required
                  hide-details
              ></v-text-field>
               <small style="color: red;" v-if="yearLengthValidator(form.due_date)">
                Invalid Date
              </small>
            </v-col>
          </v-row>




          <v-textarea
              v-model="form.notes"
              label="Memo"
              outlined
              rows="3"
              dense
          ></v-textarea>


        </v-col>

        <v-col cols="6">



          <v-autocomplete
              hide-details
              class="mb-3"
              outlined
              v-model="form.account_number"
              :items="accountDataItem"
              item-value="id"
              color="pink"
              label="Liability Account"
              append-outer-icon="mdi-plus"
              @click:append-outer="accountModel"
              dense
          ></v-autocomplete>

              <v-row class="mx-auto">

              <v-checkbox
                  dense
                  v-model="form.owe_cash"
                  class="shrink mt-2 mb-5 mr-2 float-left"
                  label="Owe Cash"
                  id="sub_account_id"
                  @change="resetCashLiabilityFields(form.owe_cash)"
                  hide-details
              ></v-checkbox>

                 <v-checkbox
                     dense
                     v-model="form.owe_asset"
                     class="shrink mt-2 mb-5 float-left"
                     label="Owe Asset"
                     id="sub_account_id"
                     @change="resetAssetLiabilityFields(form.owe_asset)"
                     hide-details
                 ></v-checkbox>

            </v-row>


           <v-row v-if="form.owe_cash">
            <v-col cols="6">
               <v-autocomplete
                   outlined
                   v-model="form.storage_account_number"
                   :items="storageAccountDataList"
                   item-value="id"
                   color="pink"
                   label="Bank/Cash Account *"
                   append-outer-icon="mdi-plus"
                   @click:append-outer="storageAccountModel"
                   dense
                   class="mb-3"
                   hide-details
               ></v-autocomplete>

            </v-col>
             <v-col cols="6">
                  <v-autocomplete
                      outlined
                      v-model="form.payment_method_number"
                      :items="paymentMethodDataList"
                      item-value="id"
                      color="pink"
                      label="Payment Method"
                      append-outer-icon="mdi-plus"
                      @click:append-outer="paymentMethodModel"
                      dense
                      hide-details
                  ></v-autocomplete>

             </v-col>
          </v-row>





           <v-autocomplete
               v-if="form.owe_asset"
               outlined
               v-model="form.asset_account"
               :items="fixedAssetAccountDataList"
               item-value="id"
               color="pink"
               label="Fixed Asset Account"
               append-outer-icon="mdi-plus"
               @click:append-outer="incomeAccountModel('FAST')"
               dense
               hide-details
           ></v-autocomplete>


          <v-row>
            <v-col cols="6">
                <v-autocomplete
                    outlined
                    v-model="form.sale_user_number"
                    :items="staffDataObj"
                    item-value="id"
                    color="pink"
                    :label="staffLabel"
                    append-outer-icon="mdi-plus"
                    @click:append-outer="addNewStaffProfileData"
                    dense
                    clearable
                    hide-details
                ></v-autocomplete>
            </v-col>
            <v-col cols="6">
               <v-autocomplete
                   outlined
                   clearable
                   v-model="form.profile_number"
                   :items="supplierDataList"
                   item-value="id"
                   color="pink"
                   :label="profileLabel"
                   append-outer-icon="mdi-plus"
                   @click:append-outer="addSupplierModel"
                   required
                   dense
                   hide-details
               ></v-autocomplete>
            </v-col>
          </v-row>


          <v-text-field
              v-model="form.reference"
              label="Reference"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="text-right">
        <v-btn
            class="companyButton"
            :loading="transactionLoader"
            color="#0000ff"
            dark
            type="submit"
        >
          {{ transactionEditData ? "Edit" : "save" }}
        </v-btn>
      </div>
    </form>

  <!--    adds Storage accounts -->

    <v-dialog v-model="storage_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="storage_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <storage-account ref="storageAccountComponent"></storage-account>
        </div>
      </v-card>
    </v-dialog>

  <!--                    payment methods-->

    <v-dialog v-model="payment_methods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Payment Methods</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payment_methods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <payment-methods ref="paymentMethodsComponents"></payment-methods>
        </div>
      </v-card>
    </v-dialog>

  <!--    adding account dialog-->

    <v-dialog v-model="account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              :account_type="account_type"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>


  <v-dialog v-model="income_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="income_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              :account_type="dataAccountType"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

  <!--supplier dialog-->
    <v-dialog v-model="addSupplierDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Profile</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addSupplierDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
           <new-profile  ref="add_customer_info"></new-profile>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="new_staff_dialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Staff</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="new_staff_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-user ref="users_components"></new-user>
        </div>
      </v-card>
    </v-dialog>

  <!--                    end dialog-->
  </span>
</template>

<script>
import StorageAccount from "@/modules/dashboard/accounting/commons/StorageAccount";
import PaymentMethods from "@/modules/dashboard/accounting/commons/PaymentMethods";
import AddAccount from "@/modules/dashboard/accounting/commons/AddAccount";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";
import NewUser from "@/modules/dashboard/Settings/Users/NewUser";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "NewLongTermLiability",
  components: {NewUser, NewProfile, AddAccount, PaymentMethods, StorageAccount},
  props: {
    account_type: String,
    transactionEditData: {}
  },
  data() {
    return {
      expenseError: false,
      storage_account_dialog: false,
      payment_methods_dialog: false,
      new_staff_dialog: false,
      transactionLoader: false,
      accountDataItem: [],
      incomeAccountDataItem: [],
      fixedAssetAccountDataList: [],
      storageAccountDataList: [],
      paymentMethodDataList: [],
      addStorageLoader: false,
      addPaymentMethodLoader: false,
      account_dialog: false,
      income_account_dialog: false,
      dataAccountType: null,
      supplierDataObj: [],
      staffLabel: null,
      profileLabel: null,
      form: {
        name: null,
        amount: null,
        notes: null,
        date: new Date().toISOString().substr(0, 10),
        due_date: new Date().toISOString().substr(0, 10),
        storage_account_number: null,
        account_number: null,
        asset_account: null,
        owe_cash: false,
        owe_asset: false,
        payment_method_number: null,
        profile_number: null,
        reference: null,
        sale_user_number: null
      },
      payment_methods_form: {
        name: null,
        notes: null
      },
      payment_method_items: [
        {text: "Select Payment Method", value: null},
        {text: "Cash", value: "cash"},
        {text: "Mobile Money", value: "mobile_money"},
        {text: "Cheque", value: "cheque"},
        {text: "Credit Cards", value: "credit_cards"},
        {text: "Electronic bank transfer", value: "electronic_bank_transfer"}
      ],
      addSupplierDialog: false,
      supplierDataList: [],
      staffDataObj: []
    };
  },
  mounted() {
    this.doGetAccountData(this.account_type);
    this.fetchAccountData("FAST");
    this.doGetStorageAccountData();
    this.doGetPaymentMethodData();

    if (this.transactionEditData) {
      this.transactionLoader = false;
      this.getTransactionInfo();
    }


    this.staffLabel = this.account_type === "INC" ? "Received by" : this.account_type === "EXP" ? "Spent by" : this.account_type === "CLBT" ? "Procured by" : ""
    this.profileLabel = this.account_type === "INC" ? "Received From" : this.account_type === "EXP" ? "Taken by" : this.account_type === "CLBT" ? "Procured by" : ""

    this.fetchProfiles();
    this.fetchStaffProfiles();
  },
  methods: {
    ...mapActions([
      "doGetAccountData",
      "doGetStorageAccountData",
      "saveNewPaymentMethod",
      "doGetPaymentMethodData",
      "doFetchTransactionInfo",
      "fetchProfiles",
      "fetchStaffProfiles",
      "doUpdateLiability",
      "doSaveLiability",
       "doFetchAccountData"
    ]),
    fetchAccountData(accountType) {
      this.doFetchAccountData(accountType);
    },
    saveTransaction() {
      this.expenseError = null;
      this.transactionLoader = true;
      let formData = this.form;
      formData.ref_code = this.account_type;
      formData.amount = this.form.amount.toString().replace(/,/g, "")
      if (this.transactionEditData) {
        formData.number = this.transactionEditData.number;
        this.doUpdateLiability(formData);
      }else {
        this.doSaveLiability(formData);
      }

    },
    storageAccountModel() {
      this.storage_account_dialog = true;
    },
    paymentMethodModel() {
      this.payment_methods_dialog = true;
    },
    addNewStaffProfileData() {
      this.new_staff_dialog = true;
    },
    accountModel() {
      this.account_dialog = true;
    },
    incomeAccountModel(data) {
      if(data){
        this.dataAccountType = data
        this.income_account_dialog = true;
      }
    },
    resetAssetLiabilityFields(value) {
      if(value){
        this.form.owe_cash = false
        this.resetInfo()
      }
    },
    resetCashLiabilityFields(value) {
      if(value){
        this.form.owe_asset = false
        this.resetInfo()
      }
    },
    resetInfo(){
      // asset account
      this.form.asset_account = null
    //  cash

      this.form.storage_account_number = null
      this.form.payment_method_number = null
    },
    compute(value) {
      value = value.toString().replace(/,/g, "")
      if (!isNaN(value)) {
        value = value - 0;
      }
      this.form.amount = eval(value) >= 0 ? eval(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
    },
    getTransactionInfo() {
      this.doFetchTransactionInfo(this.transactionEditData.number);
    },
    addSupplierModel() {
      this.addSupplierDialog = true;
    },
    filterProfilesList(profileList) {
      let dataObj = [{text: "Select profile", id: null, disabled: true}];
      let dataJson = {};
      profileList.forEach(list => {
        dataJson = {
          text: list.name,
          id: list.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })
      this.supplierDataList = dataObj;
    }

  },
  computed: {
    ...mapGetters([
      "getAccountDataSet",
      "getStorageAccountDataSet",
      "getNewStorageAccountData",
      "getNewPaymentMethods",
      "getPaymentMethodDataSet",
      "getChartOfAccountsData",
      "getTransactionErrorMessage",
      "getTransactionInfoData",
      "getProfileDataList",
      "getProfileResponseData",
      "getStaffProfileDataList",
      "getIncomeAccountDataInfo",
       "getFixedAssetAccountDataSet"
    ])
  },
  watch: {
    getFixedAssetAccountDataSet(accountData){
      this.fixedAssetAccountDataList = this.accountFilter(accountData);
    },
    getProfileDataList(profileDataList) {
      this.filterProfilesList(profileDataList);
    },
    getStaffProfileDataList(staffDataList) {
      this.staffDataObj = this.filterStaffProfilesList(staffDataList);
    },
    getIncomeAccountDataInfo(accountData) {
      this.incomeAccountDataItem = this.accountFilter(accountData);
    },
    getAccountDataSet(accountData) {
      this.accountDataItem = this.accountFilter(accountData);
    },
    getStorageAccountDataSet(storageAccountData) {
      this.storageAccountDataList = this.nameNumberFilter(storageAccountData);
    },
    getPaymentMethodDataSet(paymentData) {
      this.paymentMethodDataList = this.nameNumberFilter(paymentData);
    },
    getNewStorageAccountData(storageAccountData) {
      if (storageAccountData) {
        this.$refs["storageAccountComponent"].form = {};
        this.$refs["storageAccountComponent"].addStorageLoader = false;
        this.storage_account_dialog = false;
        this.form.storage_account_number = storageAccountData.data.number;

        this.successToast("Success", storageAccountData.message);
        this.doGetStorageAccountData(this.$company.number);
      }
    },
    getNewPaymentMethods(paymentData) {
      if (paymentData) {
        this.$refs["paymentMethodsComponents"].form = {
          name: null,
          notes: null
        };
        this.$refs["paymentMethodsComponents"].addPaymentMethodLoader = false;
        this.payment_methods_dialog = false;
        this.form.payment_method_number = paymentData.data.number;
        this.successToast("Success", paymentData.message);
        this.doGetPaymentMethodData();
      }
    },
    getChartOfAccountsData(chartData) {
      if (chartData) {

        let accountData = chartData.data
        if(accountData.ref_code === "INC"){
          this.form.account_number = accountData.number;
        } else if(accountData.ref_code === "FAST"){
          this.form.asset_account = accountData.number;
        }

        this.$refs["chart_of_account_components"].chartSpinner = false;
        this.$refs["chart_of_account_components"].form = {
                                                        name: null,
                                                        code: null,
                                                        description: null,
                                                        sub_account: null,
                                                        number: null
                                                      };
        this.account_dialog = false;

        this.income_account_dialog = false;

        this.successToast("Success", chartData.message);
          if(this.account_type) {
            this.doGetAccountData(this.account_type);
          }
        this.doGetIncomeAccountData("INC");
        this.fetchAccountData("FAST");
      }
    },
    getTransactionErrorMessage(transactionError) {
      if (transactionError) {
        this.transactionLoader = false;
        this.expenseError = transactionError.message;
        this.$refs.paymentMethodsComponents.addPaymentMethodLoader = false
      }
    },
    transactionEditData() {
      this.getTransactionInfo();
    },
    getTransactionInfoData(transactionData) {
      if (transactionData) {
        this.form.name = transactionData.name ? transactionData.name : "";
        this.form.amount = transactionData.amount ? transactionData.amount : "";
        this.form.notes = transactionData.notes ? transactionData.notes : "";
        this.form.date = transactionData.date ? transactionData.date : "";
        this.form.account_number = transactionData.account_number ? transactionData.account_number : "";
        this.form.storage_account_number = transactionData.storage_account_number ? transactionData.storage_account_number : "";
        this.form.payment_method_number = transactionData.payment_method_number ? transactionData.payment_method_number : "";
        this.form.reference = transactionData.reference ? transactionData.reference : "";
        this.form.profile_number = transactionData.profile_number ? transactionData.profile_number : "";
        this.form.sale_user_number = transactionData.sale_user_number ? transactionData.sale_user_number : "";
      }
    },
    getProfileResponseData(supplierData) {
      if (supplierData) {
        this.$refs["add_customer_info"].customerSpinner = false;
        this.$refs["add_customer_info"].form = {
          name: null,
          trading_name: null,
          address: null,
          number: null,
          tin: null,
          phoneContact: [
            {
              contact: null,
              id: null,
              type: "phone"
            }
          ],
          emailContact: [
            {
              contact: null,
              id: null,
              type: "email"
            }
          ],
          contactPersons: [
            {
              name: null,
              phone_contacts: [
                {
                  phone: null
                }
              ],
              email_contacts: [
                {
                  email: null
                }
              ],
            }
          ]
        };
        this.addSupplierDialog = false;
        this.successToast("Success", supplierData.message);
        this.form.profile_number = supplierData.data.number;
        this.fetchProfiles();


      }
    }
  }
}
</script>

<style scoped>

</style>