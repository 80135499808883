import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  itemListData: null,
  stockInfo: null,
  deleteItemsData: null,
  itemListErrorResponse: null,
  deleteItemsDataError: null
};

const getters = {
  getItemListData: state => state.itemListData,
  getStockInfo: state => state.stockInfo,
  getDeleteItemsData: state => state.deleteItemsData,
  getItemListErrorResponse: state => state.itemListErrorResponse,
  getDeleteItemsDataError: state => state.deleteItemsDataError
};

const actions = {
  async doGetItemsListData({ commit }, data) {
    let search          = data ? data.search ? data.search : "" : ""
    await axios
      .get(
        IP + "items/item-list?page=" + data.page +
          "&per_page=" + data.per_page+
          "&search="+search+
          "&types="+data.types
      )
      .then(response => {
        commit("setItemListData", response);
      })
      .catch(error => {
        commit("setItemErrorMessage", error);
      });
  },
  async doGetItemsInfo({ commit }, data) {
    await axios
      .get(IP + "items/details/" + data)
      .then(response => {
        commit("setStockInfo", response);
      })
      .catch(error => {
        commit("setItemErrorMessage", error);
      });
  },
  async deleteItemInfo({ commit }, data) {
    await axios
        .post(IP + "items/delete/"+data)
        .then(response => {
          commit("setDeleteItemsData", response);
        })
        .catch(error => {
          commit("setDeleteItemsDataError", error);
        });
  },
  async searchItem({ commit }, data) {
    let itemData = data.item ? data.item : ""
    await axios
      .get(
        IP +
          "items/search/?item=" +
          itemData +
          "&page=" +
          data.page +
          "&per_page=" +
          data.per_page
      )
      .then(response => {
        commit("setItemListData", response);
      })
      .catch(error => {
        commit("setItemErrorMessage", error);
      });
  }
};

const mutations = {
  setItemListData: (state, response) => {
    state.itemListData = response.data;
  },
  setStockInfo: (state, response) => {
    state.stockInfo = response.data;
  },
  setDeleteItemsData: (state, response) => {
    state.deleteItemsData = response.data;
  },
  setItemErrorMessage: (state, error) => {
    state.itemListErrorResponse = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  },
  setDeleteItemsDataError: (state, error) => {
    state.deleteItemsDataError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
