var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mt-10"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"ml-4",attrs:{"items":_vm.statusItems,"item-value":"id","color":"pink","dense":"","hint":"Status","persistent-hint":""},on:{"change":_vm.filterData},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"ml-4",attrs:{"items":_vm.customerDataList,"item-value":"id","color":"pink","dense":"","hint":"Customer","persistent-hint":"","clearable":""},on:{"change":_vm.filterData},model:{value:(_vm.profile_number),callback:function ($$v) {_vm.profile_number=$$v},expression:"profile_number"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"dense":"","readonly":"","hint":"Start Date [ YYY-MM-DD ]","persistent-hint":"","clearable":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"dense":"","readonly":"","hint":"End Date [ YYY-MM-DD ]","persistent-hint":"","clearable":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}}),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"3"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.invoiceHeaders,"items":_vm.invoiceListDataInfo,"search":_vm.search,"loading":_vm.tableInvoiceLoader,"loading-text":"Loading... please wait","page":_vm.page,"items-per-page":_vm.items_per_page,"server-items-length":_vm.server_items_length,"options":_vm.paginationInvoice},on:{"update:options":function($event){_vm.paginationInvoice=$event}},scopedSlots:_vm._u([{key:"item.counter",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"refNumber",attrs:{"to":{
              name: 'invoice-details',
              params: { type: item.counter },
              query: { id: item.number }
            }}},[_vm._v(" "+_vm._s(item.counter)+" ")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getColor(item.status),"small":"","outlined":""}},[_vm._v(" "+_vm._s(item.status)),_c('br')])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(item.status !== 'Fully paid' && item.status !== 'cancelled')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.makePayment(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-cash ")])]}}],null,true)},[_c('span',[_vm._v("Pay Invoice")])]):_vm._e(),(item.status !== 'cancelled')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.cancelInvoice(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")])]}}],null,true)},[_c('span',[_vm._v("Cancel Invoice")])]):_vm._e(),(_vm.activeIndex === item.number)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"width":"1","size":"16","color":"#242f7e","indeterminate":""}}):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"width":"40%"},model:{value:(_vm.payInvoiceDialog),callback:function ($$v) {_vm.payInvoiceDialog=$$v},expression:"payInvoiceDialog"}},[_c('v-card',[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"96%","padding-bottom":"20px"}},[_c('v-row',[_c('v-card-title',[_vm._v("Pay Invoice")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.payInvoiceDialog = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('pay-invoice-bill',{ref:"paymentComponents",attrs:{"invoiceInfo":_vm.invoiceDataInfo,"receiptType":"Invoice"}})],1)])],1),_c('v-dialog',{attrs:{"width":"90%"},model:{value:(_vm.editInvoiceDialog),callback:function ($$v) {_vm.editInvoiceDialog=$$v},expression:"editInvoiceDialog"}},[_c('v-card',[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"96%","padding-bottom":"20px"}},[_c('v-row',[_c('v-card-title',[_vm._v("Edit Invoice")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.editInvoiceDialog = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('edit-invoice',{ref:"edit_invoice_items",attrs:{"editInfo":'editInvoice'}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }