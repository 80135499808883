<template>
  <span>
    <bank-statement cashFlow="Cash Flow"></bank-statement>
  </span>
</template>

<script>
import BankStatement from "@/modules/dashboard/accounting/BankStatement/BankStatement";
export default {
  name: "CashFlow",
  components: { BankStatement }
};
</script>

<style scoped></style>
