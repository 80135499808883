<template>
<span>
    <v-toolbar dense flat>
      <v-toolbar-title>Payments</v-toolbar-title>
    </v-toolbar>

    <v-tabs>
      <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        <v-icon left>mdi-apps</v-icon>
         All Payouts
      </v-tab>

      <v-tab>
        <v-icon left>mdi-pencil</v-icon>
       New Payment
      </v-tab>

      <v-tab-item>
        <v-divider></v-divider>
            <customer-payout-table></customer-payout-table>
      </v-tab-item>

      <v-tab-item>
        <v-divider></v-divider>

      <v-card flat>
                <v-row>
                  <v-col cols="6" class="mt-15 ml-6">
                   <new-customer-payments></new-customer-payments>
                  </v-col>
                </v-row>
              </v-card>
      </v-tab-item>
    </v-tabs>
  </span>
</template>

<script>
import CustomerPayoutTable from "@/modules/dashboard/accounting/CustomerPayments/CustomerPayoutTable";
import NewCustomerPayments from "@/modules/dashboard/accounting/CustomerPayments/NewCustomerPayments";
export default {
  name: "CustomerDetailsPayouts",
  components: {NewCustomerPayments, CustomerPayoutTable}
}
</script>

<style scoped>

</style>