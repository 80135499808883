<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Payments </v-toolbar-title>
          </v-toolbar>

          <v-tabs v-model="activeTab">
            <v-tab href="#payments">
              <v-icon left>mdi-account</v-icon>
              All Payments
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-payments">
              <v-icon left>mdi-lock</v-icon>
              New Payment
            </v-tab>

            <v-tab-item id="payments">
              <v-divider></v-divider>
             <customer-payout-table></customer-payout-table>

            </v-tab-item>

            <v-tab-item id="new-payments">
              <v-divider></v-divider>
              <v-card flat>
                <v-row>
                  <v-col cols="5" class="mt-15 ml-6">
                    <new-customer-payments></new-customer-payments>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from "../../../../global/router";
import NewCustomerPayments from "@/modules/dashboard/accounting/CustomerPayments/NewCustomerPayments";
import CustomerPayoutTable from "@/modules/dashboard/accounting/CustomerPayments/CustomerPayoutTable";

export default {
  name: "CustomerPayments",
  components: {CustomerPayoutTable, NewCustomerPayments },
  data() {
    return {
      activeTab: "payments",
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },

  watch: {
    activeTab(data) {
      router.push({ name: "customer-payments", query: { type: data } });
    }
  }
};
</script>

<style scoped>
.refNumber {
  text-decoration: none;
}

.refNumber:hover {
  color: green;
  text-decoration: underline;
}
</style>
