import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  profitAndLossData: null,
  profitAndLossErrorResponse: null
};

const getters = {
  getProfitAndLossData: state => state.profitAndLossData,
  getProfitAndLossErrorResponse: state => state.profitAndLossErrorResponse
};

const actions = {
  async doGetProfitAndLoss({ commit }, data) {
    let start_date = data.start_date ? data.start_date : "";
    let end_date = data.end_date ? data.end_date : "";
    await axios
      .get(
        IP +
          "reports/profit-and-loss?start_date=" +
          start_date +
          "&end_date=" +
          end_date
      )
      .then(response => {
        commit("setProfitAndLossData", response);
      })
      .catch(error => {
        commit("setProfitAndLossErrorMessage", error);
      });
  }
};

const mutations = {
  setProfitAndLossData: (state, response) => {
    state.profitAndLossData = response.data;
  },
  setProfitAndLossErrorMessage: (state, error) => {
    state.profitAndLossErrorResponse = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
