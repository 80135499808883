<template>
  <span>
       <v-toolbar dense flat>
        <v-toolbar-title>Invoice Settings</v-toolbar-title>
      </v-toolbar>
    <v-divider></v-divider>
  <v-card flat class="mx-auto">
    <v-card-text>




      <v-alert type="error" v-if="responseMessage">
        {{ responseMessage }}
      </v-alert>


      <form ref="form" @submit.prevent="saveInvoiceData">


        <v-row class="mx-auto">
         <v-col cols="12" sm="12" md="8" lg="8">

           <v-row>
             <v-col cols="12" sm="12" md="12" lg="6">
                 <v-text-field
                         class="uppercase-input"
                         @keypress="onlyAlphabetValidator($event)"
                         v-model="form.invoice_prefix"
                         @input="forceUppercaseInvoice(form.invoice_prefix)"
                         label="Invoice Prefix *"
                         outlined
                         dense
                         required
                         maxlength="4"
                         hide-details
                 ></v-text-field>
             </v-col>
             <v-col cols="12" sm="12" md="12" lg="6">
                <v-text-field
                        class="uppercase-input"
                        style="text-transform: uppercase;"
                        @keypress="onlyAlphabetValidator($event)"
                        v-model="form.bill_prefix"
                        @input="forceUppercaseBill(form.bill_prefix)"
                        label="Bill Prefix *"
                        outlined
                        dense
                        required
                        maxlength="4"
                        hide-details
                ></v-text-field>
             </v-col>
           </v-row>

   <v-text-field
       @keypress="onlyNumberValidator($event)"
       v-model="form.desired_length"
       label="Preferred invoice number length *"
       outlined
       dense
       required
       maxlength="4"
       hide-details
   ></v-text-field>



        <div class="mb-3 mt-3">
          <p>Upload Invoice Image</p>
          <v-row class="mx-auto">
             <croppa
                     style="border: 1px solid #838383;"
                     class="mr-9"
                     v-model="myCroppa"
                     :width="150"
                     :height="150"
                     :canvas-color="'default'"
                     :placeholder="'Upload Invoice logo'"
                     :accept="'image/*'"
                     :file-size-limit="0"
                     :remove-button-color="'#2196f3'"
                     :prevent-white-space="true"
                     @new-image-drawn="uploadCroppedImage"
                     @move="uploadCroppedImage"
                     @zoom="uploadCroppedImage"
                     @image-remove="uploadCroppedImage"
             ></croppa>
<span>
    <v-img
            style="border: 1px solid #838383;"
            width="150"
            :src="form.logo"
            :lazy-src="form.logo"
            aspect-ratio="1"
            class="grey lighten-2"
    >
      </v-img>
</span>

          </v-row>


            <!--        <img v-if="form.logo" style="width: 150px; height: 150px; border: 1px solid #838383;" :src="form.logo" >-->
</div>




         <v-textarea
                 v-model="form.default_terms"
                 label="Default Terms"
                 outlined
                 rows="3"
                 dense
         ></v-textarea>

        <v-textarea
                v-model="form.footer"
                label="Invoice Footer"
                outlined
                rows="2"
                dense
        ></v-textarea>


         </v-col>

         <v-col cols="3">
           <p>
             Invoice Image
           </p>



        <v-img
                style="border: 1px solid #838383;"
                width="150"
                :src="image_path"
                :lazy-src="image_path"
                aspect-ratio="1"
                class="grey lighten-2"
        >
      </v-img>

         </v-col>
       </v-row>




        <div class="text-right">
          <v-btn
                  class="companyButton"
                  color="#0000ff"
                  :loading="btnSpinner"
                  dark
                  type="submit"
          >
            Save Changes
          </v-btn>
        </div>


      </form>

    </v-card-text>
  </v-card>
  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "InvoiceSettings",
    data() {
        return {
            myCroppa: {},
            responseMessage: null,
            btnSpinner: false,
            form: {
                logo: null,
                default_terms: null,
                invoice_prefix: null,
                bill_prefix: null,
                desired_length: 6,
                footer: null
            },
            number: null,
            image_path: null,
            logo: null,
        }
    },
    mounted() {
        this.fetchInvoiceSettings()
    },
    methods: {
        ...mapActions(
            [
                "doSaveInvoiceData",
                "fetchInvoiceSettings"
            ]
        ),
        forceUppercaseInvoice(data) {
            this.form.invoice_prefix = data.toUpperCase();
        },
        forceUppercaseBill(data) {
            this.form.bill_prefix = data.toUpperCase();
        },
        saveInvoiceData() {
            this.btnSpinner = true
            const formData = new FormData();
            if (this.form.logo) {
                formData.set('logo', this.convertBase64ToMultipart(this.form.logo), 'image.jpeg');
            }
            if (this.form.default_terms) {
                formData.set("default_terms", this.form.default_terms);
            }

            if (this.form.footer) {
                formData.set("footer", this.form.footer);
            }


            formData.set("bill_prefix", this.form.bill_prefix.toUpperCase());
            formData.set("invoice_prefix", this.form.invoice_prefix.toUpperCase());
            if (this.number) {
                formData.set("number", this.number);
            }

            this.doSaveInvoiceData(formData)
        },
        uploadCroppedImage() {
            let url = this.myCroppa.generateDataUrl()
            if (!url) {
                this.form.logo = ""
                return
            }

            this.form.logo = url
        },
    },
    computed: {
        ...mapGetters(
            [
                "getInvoiceSettings",
                "getInvoiceSettingsError",
                "getInvoiceSettingsDetails"
            ]
        )
    },
    watch: {
        getInvoiceSettings(settingsData) {
            if (settingsData) {
                this.btnSpinner = false
                //  alert(JSON.stringify(settingsData))
                this.successToast("Success", settingsData.message)
                this.fetchInvoiceSettings()
            }
        },
        getInvoiceSettingsError(error) {
            if (error) {
                this.btnSpinner = false
                this.errorToast("Error", error.message)
            }
        },
        getInvoiceSettingsDetails(details) {
            if (details) {
                let invoDetails = details
                this.number = invoDetails.number
                this.form.desired_length = invoDetails.desired_length
                this.form.default_terms = invoDetails.default_terms
                this.form.footer = invoDetails.footer
                this.form.invoice_prefix = invoDetails.invoice_prefix
                this.form.bill_prefix = invoDetails.bill_prefix
                this.image_path = invoDetails.image_path
                this.logo = invoDetails.logo
            }
        }
    }
}
</script>

<style scoped>
.uppercase-input {
    text-transform: uppercase;
}
</style>