/*import axios from "axios";
import configs from "../../global/configs.json"

const IP = configs.SERVER_IP;*/

const state = {};

const getters = {};

const actions = {};

const mutations = {};

export default /**/ {
  state,
  getters,
  actions,
  mutations
};
