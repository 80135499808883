<template>
  <span>
    <v-toolbar dense flat>
      <v-toolbar-title>Supplier Bills</v-toolbar-title>
    </v-toolbar>

    <v-tabs>
      <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        <v-icon left>mdi-apps</v-icon>
        Bills
      </v-tab>

      <v-tab>
        <v-icon left>mdi-pencil</v-icon>
        New Bill
      </v-tab>

      <v-tab-item>
        <v-divider></v-divider>

        <bill-table></bill-table>
      </v-tab-item>

      <v-tab-item>
        <v-divider></v-divider>

        <v-card flat>
          <div class="mt-4 mx-auto" style="width: 98%; padding-bottom: 15px;">
            <new-bill ref="bill_components"></new-bill>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </span>
</template>

<script>
import NewBill from "@/modules/dashboard/accounting/commons/NewBill";
import BillTable from "@/modules/dashboard/accounting/Supplier/BillTable";
export default {
  name: "SupplierBill",
  components: { BillTable, NewBill }
};
</script>

<style scoped></style>
