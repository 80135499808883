import axios from "axios";
import configs from "../../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  activityLogsData: null,
  activityLogsDataError: null
};

const getters = {
  getActivityLogsData: state => state.activityLogsData,
  getActivityLogsDataError: state => state.activityLogsDataError
};

const actions = {
  async fetchActivityLogs({ commit }) {
    await axios
      .get(IP + "company/activity-logs")
      .then(response => {
        commit("setActivityLogsData", response);
      })
      .catch(error => {
        commit("setActivityLogsError", error);
      });
  }
};

const mutations = {
  setActivityLogsData: (state, response) => {
    state.activityLogsData = response.data;
  },
  setActivityLogsError: (state, error) => {
    state.activityLogsDataError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
