import Vue from "vue";
import Router from "vue-router";

import Dashboard from "../modules/dashboard/Dashboard";
import Login from "../modules/account/auth/Login";
import Register from "../modules/account/auth/Register";
import Accounting from "../modules/dashboard/accounting/Accounting";
import Expense from "../modules/dashboard/accounting/Expense/Expense";
import Deposit from "../modules/dashboard/accounting/Deposit";
import OnboardingCompany from "../modules/home/OnboardingCompany.vue";
import Company from "../modules/dashboard/accounting/Company.vue";
import ChartOfAccounts from "../modules/dashboard/accounting/ChartOfAccounts";
import Income from "../modules/dashboard/accounting/Income/Income";
import Stock from "../modules/dashboard/accounting/Stock/Stock";
import Item from "../modules/dashboard/accounting/Item/Item";
import Equity from "../modules/dashboard/accounting/Equity/Equity";
import Profiles from "../modules/dashboard/accounting/profiles/Profiles";
import CurrentAssets from "../modules/dashboard/accounting/CurrentAssets/CurrentAssets";
import FixedAssets from "../modules/dashboard/accounting/FixedAssets/FixedAssets";
import CurrentLiability from "../modules/dashboard/accounting/CurrentLiability/CurrentLiability";
import LongTermLiability from "../modules/dashboard/accounting/LongTermLiability/LongTermLiability";
import Sales from "../modules/dashboard/accounting/Sales/Sales";
import Invoice from "../modules/dashboard/accounting/Invoice/Invoice";
import Supplier from "../modules/dashboard/accounting/Supplier/Supplier";
import Bills from "../modules/dashboard/accounting/Bills/Bills";
import InvoiceDetails from "../modules/dashboard/accounting/Invoice/InvoiceDetails";
import BillDetails from "../modules/dashboard/accounting/Bills/BillDetails";
import CustomerPayments from "../modules/dashboard/accounting/CustomerPayments/CustomerPayments";
import SupplierPayouts from "../modules/dashboard/accounting/SupplierPayouts/SupplierPayouts";
import ProfitAndLoss from "../modules/dashboard/accounting/ProfitAndLoss/ProfitAndLoss";
import BalanceSheet from "../modules/dashboard/accounting/BalanceSheet/BalanceSheet";
import RequestResetPassword from "../modules/account/auth/RequestResetPassword";
import SetPassword from "../modules/account/auth/SetPassword";
import BankStatement from "../modules/dashboard/accounting/BankStatement/BankStatement";
import StorageAccountList from "../modules/dashboard/accounting/StorageAccounts/StorageAccountList";
import Error404 from "../modules/dashboard/Error404";
import Settings from "@/modules/dashboard/Settings/Settings";
import UserProfile from "@/modules/dashboard/accounting/UserProfile/UserProfile";
import abilities from "./casl/abilities";
import Transfer from "@/modules/dashboard/accounting/transfer/Transfer";
import Error403 from "../modules/account/auth/custom/Error403";
import CashFlow from "@/modules/dashboard/accounting/CashFlow/CashFlow";
import Depreciation from "@/modules/dashboard/accounting/Depreciation/Depreciation";
import DepreciationTransactionInfo
  from "@/modules/dashboard/accounting/DepreciationTransaction/DepreciationTransactionInfo";
import ProfileDetails from "@/modules/dashboard/accounting/CustomeDetails/ProfileDetails";
import NewJournal from "@/modules/dashboard/accounting/AddJournals/NewJournal";
import FixedAssetsDetails from "@/modules/dashboard/accounting/FixedAssets/FixedAssetsDetails";
import ActivateAccount from "@/modules/account/auth/ActivateAccount";
import HomePage from "@/modules/home/HomePage.vue";
import BillingChart from "@/modules/home/BillingChart.vue";
import StatementOfCashFLows from "@/modules/dashboard/accounting/CashFlow/StatementOfCashFLows";
import CostOfGoods from "@/modules/dashboard/accounting/costOfGoods/CostOfGoods";
import TaxPayments from "@/modules/dashboard/accounting/taxPayments/TaxPayments";
import AllTransactions from "@/modules/dashboard/accounting/AllTransactions/AllTransactions";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {name: "features", path: "/features", component: () => import("../modules/home/Features.vue")},
    {
      path: "/",
      component: HomePage,
      name:"auth",
      redirect:{ name: 'login' },
      children: [
        {
          path: "login",
          component: Login,
          name: "login",
          meta: {
            title: "Login | Base"
          }
        },
        {
          path: "register",
          component: Register,
          name: "register",
          meta: {
            title: "Register | Base"
          }
        },
        {
          path: "request-reset-password",
          component: RequestResetPassword,
          name: "request-reset-password",
          meta: {
            title: "Request Reset Password | Base"
          }
        },
        {
          path: "set-password/:id",
          component: SetPassword,
          name: "set-password",
          meta: {
            title: "Set Password With Code | Base"
          }
        },
        {
          path: "activate/:id",
          component: ActivateAccount,
          name: "activate",
          meta: {
            title: "Set Password With Code | Base"
          }
        } ,
      ]
    },
    {
      path:"/billing-payment",
      component:BillingChart,
      name:"billing"
    },
    {
      path: "/onboard",
      component: OnboardingCompany,
      name: "onboard_company",
      meta: {
        requiresAuth: true,
        resource: "profile",
        breadCrumb: "Profile"
      }
    },
    {
      path: "/base",
      component: Dashboard,
      name: "user_dashboard",
      meta: {
        requiresAuth: true,
        breadCrumb: "Home"
      },
      redirect:{ name: 'home' },
      children: [
        {
          path: "home",
          component: Accounting,
          name: "home",
          meta: {
            title: "Accounting | Base",
            resource: "chart",
            breadCrumb: "Reports"
          }
        },
        {
          path: "company",
          component: Company,
          name: "company",
          meta: {
            title: "Company | Base",
            breadCrumb: "Company profile"
          }
        },
        {
          path: "chart-of-accounts",
          component: ChartOfAccounts,
          name: "chart-of-accounts",
          meta: {
            title: "Chart Of Accounts | Base",
            resource: "chart",
            breadCrumb: "Chart of accounts"
          }
        },
        {
          path: "expense",
          component: Expense,
          name: "expenses",
          meta: {
            title: "Expense | Base",
            resource: "expenses",
            breadCrumb: "Expenses"
          }
        },
        {
          path: "journal",
          component: NewJournal,
          name: "journal",
          meta: {
            title: "New Journal | Base",
            resource: "expenses",
            breadCrumb: "Expenses"
          }
        },
        {
          path: "fixed-assets",
          component: FixedAssets,
          name: "fixed_assets",
          meta: {
            title: "Fixed Assets | Base",
            resource: "assets",
            breadCrumb: "Fixed assets"
          }
        },
        {
          path: "fixed-assets-details/:type",
          component: FixedAssetsDetails,
          name: "fixed-assets-details",
          meta: {
            title: "Fixed Asset Details | Base",
            resource: "assets",
            breadCrumb: "Fixed assets details"
          }
        },
        {
          path: "current-assets",
          component: CurrentAssets,
          name: "current_assets",
          meta: {
            title: "Current Assets | Base",
            resource: "assets",
            breadCrumb: "Current assets"
          }
        },
        {
          path: "all-transactions",
          component: AllTransactions,
          name: "all-transactions",
          meta: {
            title: "All Transactions | Base",
            resource: "transactions",
            breadCrumb: "All Transactions"
          }
        },
        {
          path: "long-term-liability",
          component: LongTermLiability,
          name: "long_term_liability",
          meta: {
            title: "Long Term Liability | Base",
            resource: "liabilities",
            breadCrumb: "Long term Liabilities"
          }
        },
        {
          path: "current-liability",
          component: CurrentLiability,
          name: "current_liability",
          meta: {
            title: "Current Liability | Base",
            resource: "liabilities",
            breadCrumb: "Current liabilities"
          }
        },
        {
          path: "income",
          component: Income,
          name: "income",
          meta: {
            title: "Income | Base",
            resource: "income",
            breadCrumb: "Income"
          }
        },
        {
          path: "cost-of-goods",
          component: CostOfGoods,
          name: "cost-of-goods",
          meta: {
            title: "Cost of Goods | Base",
            resource: "cost-of-goods",
            breadCrumb: "Income"
          }
        },
        {
          path: "deposit",
          component: Deposit,
          meta: {
            title: "Deposit | Base",
            breadCrumb: "Deposit"
          }
        },
        {
          path: "transfer",
          name: "transfer",
          component: Transfer,
          meta: {
            title: "Transfer funds | Base",
            breadCrumb: "Deposit"
          }
        },
        {
          path: "tax-payments",
          name: "tax-payments",
          component: TaxPayments,
          meta: {
            title: "Tax Payments | Base",
            breadCrumb: "Tax Payments"
          }
        },
        {
          path: "stock",
          component: Stock,
          name: "stock",
          meta: {
            title: "Stock | Base",
            resource: "stock",
            breadCrumb: "Stock"
          }
        },
        {
          path: "item-list",
          component: Item,
          name: "item-list",
          meta: {
            title: "Item | Base",
            resource: "items",
            breadCrumb: "Item list"
          }
        },
        {
          path: "equity",
          component: Equity,
          name: "equity",
          meta: {
            title: "Equity | Base",
            resource: "equity",
            breadCrumb: "Equity"
          }
        },
        {
          path: "profiles",
          component: Profiles,
          name: "profiles",
          meta: {
            title: "profiles / Supplier | Base",
            resource: "profiles",
            breadCrumb: "Profiles"
          }
        },
        {
          path: "supplier",
          component: Supplier,
          name: "supplier",
          meta: {
            title: "Supplier | Base",
            resource: "suppliers",
            breadCrumb: "Supplier"
          }
        },
        {
          path: "sales",
          component: Sales,
          name: "sales",
          meta: {
            title: "Sales | Base",
            resource: "sales",
            breadCrumb: "Sales"
          }
        },
        {
          path: "invoice",
          component: Invoice,
          name: "invoice",
          meta: {
            title: "Invoice | Base",
            resource: "invoices",
            breadCrumb: "Invoices"
          }
        },
        {
          path: "invoice/:type",
          component: InvoiceDetails,
          name: "invoice-details",
          meta: {
            title: "Invoice details | Base",
            resource: "invoices",
            breadCrumb: "Invoices"
          }
        },
        {
          path: "bill",
          component: Bills,
          name: "bill",
          meta: {
            title: "Bill | Base",
            resource: "bills",
            breadCrumb: "Bills"
          }
        },
        {
          path: "bill/:type",
          component: BillDetails,
          name: "bill-details",
          meta: {
            title: "Bill Details | Base",
            resource: "bills",
            breadCrumb: "Bill details"
          }
        },
        {
          path: "profile-info/:id",
          component: ProfileDetails,
          name: "profile-details",
          meta: {
            title: "profiles Details | Base",
            resource: "customers",
            breadCrumb: "Customers"
          }
        },
     /*   {
          path: "supplier/:id",
          component: SupplierDetails,
          name: "supplier-details",
          meta: {
            title: "Supplier Details | Base",
            resource: "suppliers",
            breadCrumb: "Suppliers"
          }
        },*/

        {
          path: "customer-payments",
          component: CustomerPayments,
          name: "customer-payments",
          meta: {
            title: "profiles Payments | Base",
            resource: "payments",
            breadCrumb: "profiles payments"
          }
        },
        {
          path: "supplier-payouts",
          component: SupplierPayouts,
          name: "supplier-payouts",
          meta: {
            title: "Supplier Payouts | Base",
            resource: "payments",
            breadCrumb: "Supplier payouts"
          }
        },
        {
          path: "profit-and-loss",
          component: ProfitAndLoss,
          name: "profit-and-loss",
          meta: {
            title: "Profit And Loss | Base",
            resource: "profitAndLoss",
            breadCrumb: "P&L report"
          }
        },
        {
          path: "balance-sheet",
          component: BalanceSheet,
          name: "balance-sheet",
          meta: {
            title: "Balance Sheet | Base",
            resource: "balanceSheet",
            breadCrumb: "Balance sheet"
          }
        },
        {
          path: "bank-statement",
          component: StorageAccountList,
          name: "storage-accounts",
          meta: {
            title: "Storage Accounts | Base",
            resource: "bankAccounts",
            breadCrumb: "Bank & Cash accounts"
          }
        },
        {
          path: "bank-statement/:id",
          component: BankStatement,
          name: "bank-statement",
          meta: {
            title: "Bank Statement | Base",
            resource: "bankAccounts",
            breadCrumb: "Bank & Cash accounts"
          }
        },
      /*  {
          path: "unit-of-measure",
          component: UnitOfMeasure,
          name: "unit-of-measure",
          meta: {
            title: "Unit Of Measure | Base",
            resource: "settings",
            action: "manage",
            breadCrumb: "Units of measure"
          }
        },*/
        {
          path: "cash-flow",
          component: CashFlow,
          name: "cash-flow",
          meta: {
            title: "Cash Flow | Base",
            resource: "bankAccounts",
            breadCrumb: "Cash Flow"
          }
        },
        {
          path: "statement-of-cash-flows",
          component: StatementOfCashFLows,
          name: "statement-of-cash-flows",
          meta: {
            title: "Statement of Cash Flow | Base",
            resource: "bankAccounts",
            breadCrumb: "Cash Flow"
          }
        },
        {
          path: "settings",
          component: Settings,
          name: "settings",
          meta: {
            title: "Settings | Base",
            resource: "settings",
            action: "manage",
            breadCrumb: "Settings"
          }
        },
      /*  {
          path: "activity-logs",
          component: ActivityLogs,
          name: "activity-logs",
          meta: {
            title: "Activity Logs | Base",
            resource: "settings",
            action: "manage",
            breadCrumb: "Activity logs"
          }
        },*/
        {
          path: "settings/:id",
          component: Settings,
          name: "settings-ui",
          meta: {
            title: "Settings | Base",
            resource: "settings",
            action: "manage",
            breadCrumb: "Settings"
          }
        },
        {
          path: "user-profile",
          component: UserProfile,
          name: "user-profile",
          meta: {
            title: "User Profile | Base",
            resource: "profile",
            breadCrumb: "User profile"
          }
        },
        {
          path: "depreciation",
          component: Depreciation,
            name: "depreciation",
          meta: {
            title: "Depreciation | Base",
            resource: "depreciation",
            breadCrumb: "depreciation"
          }
        },
        {
          path: "depreciation-transaction",
          component: DepreciationTransactionInfo,
            name: "depreciation-transaction",
          meta: {
            title: "Depreciation Transaction | Base",
            resource: "depreciation",
            breadCrumb: "depreciation"
          }
        }
      ]
    },
    {
      path: "/forbidden",
      name: "forbidden",
      component: Error403,
      meta: {
        title: "Unauthorized",
        requiresAuth: true,
        resource: "forbidden"
      }
    },
    {
      path: "/error-404",
      component: Error404,
      name: "error-404",
      meta: {
        title: "Error-404 | Base"
      }
    },
    {
      path: "*",
      redirect: { name: "error-404" }
    }
  ]
});

router.beforeEach((to, from, next) => {
  let user = localStorage.getItem("userProfile");
  let company = localStorage.getItem("company");

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (user == null) {
      localStorage.clear();
      next({ name: "login" });
    } else {
      const canNavigate = to.matched.some(route => {
        return abilities.can(route.meta.action || "view", route.meta.resource);
      });

      if (!canNavigate) {
        abilities.update([{ action: "view", subject: "forbidden" }]);
        return next({
          name: "forbidden"
        });
        //return next(getHomeRouteForLoggedInUser(user.type));
      }
      next();
    }
  } else {
    if (localStorage.getItem("userProfile") == null) {
      next();
    } else {
      if (company == null) {
        next({ name: "onboard_company" });
      } else {
        next({ name: "chart-of-accounts" });
      }
    }
  }
});

const DEFAULT_TITLE = "Dashboard | Base ";
router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
