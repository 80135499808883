<template>
<span>
   <form ref="form" @submit.prevent="saveItem">
  <v-row>
    <v-col cols="6">

      <v-text-field
          v-model="form.name"
          label="Name"
          outlined
          dense
          required
      ></v-text-field>

  <v-text-field
      v-model="form.code"
      label="Code"
      outlined
      dense
  ></v-text-field>



     <v-autocomplete
         outlined
         v-model="form.unit_number"
         :items="unitDataList"
         item-value="id"
         label="Unit of Measure"
         append-outer-icon="mdi-plus"
         @click:append-outer="addItemUnitData"
         dense
         clearable
     ></v-autocomplete>





 <v-autocomplete
     outlined
     v-model="form.current_assets_account_number"
     :items="currentAssetAccountDataList"
     item-value="id"
     color="pink"
     label="Current Assets Account *"
     append-outer-icon="mdi-plus"
     @click:append-outer="addAccountModel('CAST')"
     required
     clearable
     dense
 ></v-autocomplete>


          <v-autocomplete
              outlined
              v-model="form.income_account_number"
              :items="incomeAccountDataList"
              item-value="id"
              color="pink"
              label="Income Account"
              append-outer-icon="mdi-plus"
              @click:append-outer="addAccountModel('INC')"
              required
              dense
              clearable
          ></v-autocomplete>


       <v-autocomplete
           outlined
           v-model="form.cogs_account_number"
           :items="costOfGoodsAccountDataList"
           item-value="id"
           color="pink"
           label="Cost of Goods Account *"
           append-outer-icon="mdi-plus"
           @click:append-outer="addAccountModel('COS')"
           required
           dense
           clearable
           hide-details
       ></v-autocomplete>


        <v-row>
            <v-col cols="4">
              <v-checkbox
                  dense
                  v-model="showTaxItems"
                  class="shrink mr-0 mt-0 float-left"
                  hide-details
                  label="is item taxable?"
                  id="sub_account_id"
              ></v-checkbox>
            </v-col>
            <v-col  v-if="showTaxItems" cols="8">
              <v-autocomplete
                  v-if="showTaxItems"
                  outlined
                  v-model="form.tax_item_numbers"
                  :items="taxItemDataObj"
                  item-value="id"
                  color="pink"
                  label="Tax Item"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="addTaxAccountModel"
                  multiple
                  hide-details
                  hide-selected
                  clearable
                  dense
              ></v-autocomplete>
            </v-col>
          </v-row>


    </v-col>
    <v-col cols="6">
      <v-row v-if="!inventoryPartDataInfo.number">
        <v-col>
               <v-checkbox
                   v-model="showOpeningStock"
                   class="shrink mr-0 mt-0 float-left"
                   hide-details
                   dense
                   label="Add opening stock"
                   id="sub_account_id"
               ></v-checkbox>
        </v-col>
      </v-row>

        <fieldset
            v-if="showOpeningStock && !inventoryPartDataInfo.number"
            style="border: 1px solid #e5e1e1; color: #bfb5b5"
            class="mb-4"
        >
            <legend style="padding: 0px 7px 0px 7px; margin: auto;">
              Opening Stock
            </legend>
            <div style="padding: 0px 6px 0px 6px;">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      v-model="openingStock.quantity"
                      label="Quantity"
                      v-on:blur="computeNumber(openingStock.quantity, 'item_quantity')"
                      @keypress="onlyNumberValidator($event)"
                      outlined
                      dense
                      hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                     <v-text-field
                         v-model="openingStock.date_of_purchase"
                         label="Date as of  [ dd / mm / yyy ]"
                         outlined
                         dense
                         hide-details
                         type="date"
                     ></v-text-field>
                   <small style="color: red;" v-if="yearLengthValidator(openingStock.date_of_purchase)">
                Invalid Date
              </small>
                </v-col>
              </v-row>



<v-row>
  <v-col cols="6">
 <v-autocomplete
     outlined
     v-model="openingStock.profile_number"
     :items="supplierDataObj"
     item-value="id"
     color="pink"
     :label="receivedOnCreditCheck ? 'Creditor' : 'Supplier'"
     append-outer-icon="mdi-plus"
     @click:append-outer="addItemSupplierData"
     dense
     clearable
     hide-details
 ></v-autocomplete>
  </v-col>
  <v-col cols="6">
     <v-text-field
         v-model="openingStock.reference"
         label="Reference"
         outlined
         dense
         hide-details
     ></v-text-field>
  </v-col>
</v-row>








            </div>
          </fieldset>


      <v-row>
        <v-col cols="6">
                 <v-text-field
                     v-model="form.buying_price"
                     label="Buying Price"
                     outlined
                     v-on:blur="computeNumber(form.buying_price, 'buying_price')"
                     @keypress="onlyNumberValidator($event)"
                     dense
                     hide-details
                 ></v-text-field>
        </v-col>
        <v-col cols="6">
           <v-text-field
               v-model="form.selling_price"
               label="Selling Price"
               outlined
               v-on:blur="computeNumber(form.selling_price, 'selling_price')"
               @keypress="onlyNumberValidator($event)"
               dense
               hide-details
           ></v-text-field>
        </v-col>
      </v-row>


        <v-row v-if="validateBuyingPrice && showOpeningStock" class="mt-2 mx-auto">
              <v-checkbox
                  v-model="paidCashCheck"
                  class="shrink mr-8 mt-0 float-left"

                  dense
                  label="As Equity"
                  id="sub_account_id"
              ></v-checkbox>

              <v-checkbox
                  v-model="receivedOnCreditCheck"
                  class="shrink mr-0 mt-0 float-left"

                  dense
                  label="Received on Credit"
                  id="sub_account_id"
              ></v-checkbox>


        </v-row>


    <v-autocomplete
        v-if="receivedOnCreditCheck"
        outlined
        v-model="form.current_liability_account_number"
        :items="currentLiabilityAccountDataList"
        item-value="id"
        color="pink"
        label="Current Liability Account *"
        append-outer-icon="mdi-plus"
        @click:append-outer="addAccountModel('CLBT')"
        dense
    ></v-autocomplete>

     <v-autocomplete
         v-if="paidCashCheck"
         outlined
         v-model="form.equity_account_number"
         :items="equityAccountDataSet"
         item-value="id"
         color="pink"
         label="Equity Account *"
         append-outer-icon="mdi-plus"
         @click:append-outer="addAccountModel('EQT')"
         dense
     ></v-autocomplete>





       <v-row>
            <v-col cols="3">
              <v-checkbox
                  v-model="showSubItemCategory"
                  class="shrink mr-0 mt-0 float-left"
                  hide-details
                  dense
                  label="Sub Item of"
                  id="sub_account_id"
              ></v-checkbox>
            </v-col>
            <v-col v-if="showSubItemCategory" cols="9">
              <v-select
                  clearable
                  outlined
                  v-if="showSubItemCategory"
                  v-model="form.super_item"
                  :items="subItemDataList"
                  item-value="id"
                  color="pink"
                  label="Select Super Item"
                  dense
                  hide-details
              ></v-select>
            </v-col>
          </v-row>


      <v-textarea
          v-model="form.notes"
          label="Item Description"
          outlined
          rows="2"
          dense
      ></v-textarea>

    </v-col>
  </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <v-btn
            class="companyButton"
            :loading="addItemLoader"
            color="#0000ff"
            dark
            type="submit"
        >
          {{ inventoryPartDataInfo.number ? "Edit Item" : "Save Item" }}
        </v-btn>
      </v-row>
   </form>


     <v-dialog v-model="add_item_unit_dialog" width="30%">
      <v-card>
        <div style="width: 95%; padding-bottom: 10px;" class="mx-auto">
          <v-row>
            <v-card-title> Add Unit</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_item_unit_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-unit ref="unit_components"></add-unit>
        </div>
      </v-card>
    </v-dialog>

  <!--                    payment methods-->

    <v-dialog v-model="payment_methods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Payment Methods</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payment_methods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <payment-methods ref="paymentMethodsComponents"></payment-methods>
        </div>
      </v-card>
    </v-dialog>

  <!--    dialog to add current assets-->
    <v-dialog v-model="current_asset_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="current_asset_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              :account_type="accountType"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

  <!--    dialog to add income account-->
    <v-dialog v-model="income_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="income_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              account_type="INC"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

  <!--    dialog to add cost of goods account-->
    <v-dialog v-model="cost_of_goods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="cost_of_goods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <add-account
              account_type="COS"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>


  <!--    adds Storage accounts -->

    <v-dialog v-model="storage_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="storage_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <storage-account ref="storageAccountComponent"></storage-account>
        </div>
      </v-card>
    </v-dialog>




  <!--tax item dialog-->
    <v-dialog v-model="addTaxDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Tax</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addTaxDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-tax ref="new_tax_component"></new-tax>
        </div>
      </v-card>
    </v-dialog>


  <!--    add item suppliers dialog-->
    <v-dialog v-model="add_item_supplier_dialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Supplier</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_item_supplier_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-profile ref="profile_component"></new-profile>
        </div>
      </v-card>
    </v-dialog>

</span>
</template>

<script>
import AddAccount from "@/modules/dashboard/accounting/commons/AddAccount";
import AddUnit from "@/modules/dashboard/accounting/commons/AddUnit";
import {mapActions, mapGetters} from "vuex";
import NewTax from "@/modules/dashboard/Settings/tax/NewTax";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";
import StorageAccount from "@/modules/dashboard/accounting/commons/StorageAccount";
import PaymentMethods from "@/modules/dashboard/accounting/commons/PaymentMethods";

export default {
  name: "InventoryPartItem",
  components: {PaymentMethods, StorageAccount, NewProfile, NewTax, AddAccount, AddUnit},
  props:{
    itemType:String,
    inventoryPartDataInfo:{}
  },
  data(){
    return{
      addItemLoader:false,
      item_number:null,
      super_item:null,
      storageAccountDataList: [],
      currentLiabilityAccountDataList: [],
      paymentMethodDataList: [],
      storage_account_dialog: false,
      payment_methods_dialog: false,
      form:{
        name:null,
        code:null,
        unit_number:null,
        current_assets_account_number:null,
        equity_account_number:null,
        current_liability_account_number:null,
        income_account_number:null,
        cogs_account_number:null,
        notes:null,
        super_item:null,
        payment_method_number:null,
        storage_account_number:null,
        selling_price:0,
        tax_item_numbers:[],
        profile_number:null,
        openingStock:null,
        buying_price:0,
      },
      openingStock:{
        quantity:null,
        date_of_purchase:new Date().toISOString().substr(0, 10),
        reference:null,
      },
      showOpeningStock:false,
      taxItemDataObj: [],
      supplierDataObj: [],
      showSubItemCategory: false,
      paidCashCheck: false,
      receivedOnCreditCheck: false,
      showTaxItems: false,
      unitDataList:[],
      currentAssetAccountDataList:[],
      incomeAccountDataList:[],
      costOfGoodsAccountDataList:[],
      equityAccountDataSet:[],
      add_item_unit_dialog: false,
      current_asset_dialog: false,
      accountType: null,
      income_dialog: false,
      cost_of_goods_dialog: false,
      subItemDataList: [],
      addSuperItemDialog: false,
      addTaxDialog: false,
      add_item_supplier_dialog: false,
    }
  },
  mounted() {
    this.filterSubItemList()
    this.doGetStorageAccountData();
    this.doGetPaymentMethodData();
    this.doGetUnitDataList();
    this.fetchAccountData("CAST");
    this.fetchAccountData("INC");
    this.fetchAccountData("COS");
    this.fetchAccountData("CLBT");
    this.fetchAccountData("EQT");
    this.fetchTaxData();
    this.fetchProfiles();
    this.checkInventoryDataInfo()


  },
  methods:{
    ...mapActions(
        [
          "saveInventoryPartItem",
          "doGetUnitDataList",
          "doFetchAccountData",
          "doGetSubItemData",
          "fetchTaxData",
          "fetchProfiles",
          "editServiceItem",
          "doGetStorageAccountData",
          "doGetPaymentMethodData"
        ]
    ),
    saveItem(){
      this.$emit("itemsErrorRemoval", "data");

      let formData = this.form
      formData.itemType = this.itemType;
      formData.buying_price = this.form.buying_price ? this.form.buying_price.toString().replace(/,/g, "") : 0
      formData.selling_price = this.form.selling_price ? this.form.selling_price.toString().replace(/,/g, "") : 0
      if(this.form.openingStock && this.form.openingStock.quantity) {
        formData.openingStock.quantity =  this.form.openingStock.quantity.toString().replace(/,/g, "")
      }
      if (!this.form.unit_number) {
        let confirm_unit = confirm(
            "Are you sure you want to continue without a unit of measure"
        );
        if (!confirm_unit) {
          return
        }
      }

      this.addItemLoader = true
      if (this.item_number) {
        formData.number = this.item_number;
        this.editServiceItem(formData)
      } else {
        this.saveInventoryPartItem(formData)
      }



    },
    addItemUnitData() {
      this.add_item_unit_dialog = true;
    },
    addAccountModel(data) {
      this.accountType = data
      this.current_asset_dialog = true;
    },
    addIncomeAccountModel() {
      this.income_dialog = true;
    },
    addCostOfGoodsAccountModel() {
      this.cost_of_goods_dialog = true;
    },
    filterUnits(unitDataList) {
      let unitJsn = {};
      let unitObj = [
        {
          text: "Select unit",
          id: "",
          disabled:true
        }
      ];
      for (let i = 0; i < unitDataList.length; i++) {
        unitJsn = {
          text:
              unitDataList[i].name + " ( " + unitDataList[i].abbreviation + " )",
          id: unitDataList[i].number
        };
        unitObj.push(unitJsn);
      }

      this.unitDataList = unitObj;
    },
    computeNumber(value, vModal, index = null) {
      ///this removes any value that starts with a zero
      value = value.toString().replace(/,/g, "");
      if (!isNaN(value)) {
        value = value * 1;
      }

      if (vModal === "buying_price") {
        this.form.buying_price =
            (eval(value) * 1) >= 0 ? eval(value).toFixed(2).toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      } else if (vModal === "selling_price") {
        this.form.selling_price =
            (eval(value) * 1) >= 0 ? eval(value).toFixed(2).toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      } else if (vModal === "quantity") {
        this.bill_of_items[index].number_of_items =
            (eval(value) * 1) >= 0 ? eval(value).toFixed(2).toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      } else if (vModal === "item_quantity") {
        this.openingStock.quantity
            = (eval(value) * 1) >= 0 ? eval(value).toFixed(2).toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      } else if (vModal === "rate") {
        let result = (eval(value) * 1);

        if (result > 100) {
          result = 100;
        } else if (result < 1) {
          result = null;
        }

        this.form.rate = result;
      }
    },
    fetchAccountData(accountType) {
      this.doFetchAccountData(accountType);
    },
    filterSubItemData(subItemData) {
      let subItemObj = [];
      let subItemJson = {};
      for (let i = 0; i < subItemData.length; i++) {
        subItemJson = {
          text: subItemData[i].full_name,
          id: subItemData[i].number
        };
        subItemObj.push(subItemJson);
      }

      this.subItemDataList = subItemObj;


      if(subItemObj.length > 0) {
        const checkItem = obj => obj.id === this.super_item
        this.showSubItemCategory = subItemObj.some(checkItem)
      }
    },
    addSuperItem() {
      this.addSuperItemDialog = true;
    },
    filterSubItemList(){
      if(this.itemType) {
        this.doGetSubItemData(this.itemType);
      }
    },
    addTaxAccountModel() {
      this.addTaxDialog = true;
    },
    filterTaxData(taxData) {
      if(taxData){
        let arr = [{ text: "Select tax", id: "", disabled: true }];
        let obj = {};
        taxData.forEach(list => {
          let rate = list.rate ? "("+list.rate+"%)" : ""
          obj = {
            text: list.name+" "+rate,
            id: list.number,
          };
          arr.push(obj);
        })
        this.taxItemDataObj = arr;
      }


    },
    addItemSupplierData() {
      this.add_item_supplier_dialog = true;
    },
    filterProfileList(customerList) {
      let dataObj = [{text: "Select profile", id: null, disabled: true}];
      let dataJson = {};
      for (let i = 0; i < customerList.length; i++) {
        dataJson = {
          text: customerList[i].name,
          id: customerList[i].number,
          disabled: false
        };
        dataObj.push(dataJson);
      }

      this.supplierDataObj = dataObj;
    },
    checkInventoryDataInfo(){
      if (this.inventoryPartDataInfo) {
        let dataInfo = this.inventoryPartDataInfo
        this.form.name = dataInfo.name ? dataInfo.name : null
        this.form.code = dataInfo.code ? dataInfo.code : null
        this.form.unit_number = dataInfo.unit ? dataInfo.unit.number : null
        this.form.current_assets_account_number = dataInfo.current_assets_account_number ? dataInfo.current_assets_account_number : null
        this.form.income_account_number = dataInfo.income_account_number ? dataInfo.income_account_number : null
        this.form.cogs_account_number = dataInfo.cogs_account_number ? dataInfo.cogs_account_number : null
        this.form.notes = dataInfo.notes ? dataInfo.notes : null
        this.form.selling_price = dataInfo.selling_price ? dataInfo.selling_price.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
        this.form.buying_price = dataInfo.buying_price ? dataInfo.buying_price.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
        this.super_item = dataInfo.super_item

        //check if super item has been selected
        if(this.subItemDataList.length > 0) {
          const checkItem = obj => obj.id === dataInfo.super_item
          this.showSubItemCategory = this.subItemDataList.some(checkItem)
        }

        this.form.super_item = dataInfo.super_item ? dataInfo.super_item : null
        this.item_number = dataInfo.number ? dataInfo.number : null
        this.showTaxItems = (dataInfo.tax && dataInfo.tax.length > 0)

        if(dataInfo.tax && dataInfo.tax.length > 0){
          let arr = []
          dataInfo.tax.forEach(list =>{
            arr.push(list.number)
          })
          this.form.tax_item_numbers = arr
        }

      }
    },
    storageAccountModel() {
      this.storage_account_dialog = true;
    },
    paymentMethodModel() {
      this.payment_methods_dialog = true;
    },
  },
  computed:{
    ...mapGetters(
        [
          "getInventoryPartItem",
          "getUnitDataList",
          "getUnitData",
          "getCostOfGoodsAccountDataSet",
          "getIncomeAccountDataSet",
          "getCurrentAssetsAccountDataSet",
          "getChartOfAccountsData",
          "getSubItemData",
          "getTaxDataAll",
          "getNewTaxData",
          "getProfileResponseData",
          "getProfileDataList",
          "getUpdateErrorMessage",
          "getUpdateItemData",
          "getStorageAccountDataSet",
          "getNewStorageAccountData",
          "getNewPaymentMethods",
          "getPaymentMethodDataSet",
          "getCurrentLiabilityAccountDataSet",
          "getEquityAccountDataSet"
        ]
    ),
    validateBuyingPrice(){
      let check = false
      if(this.form.buying_price && ((this.form.buying_price.toString().replace(/,/g, "") * 1) > 0)){
        check = true
      }
      return check
    }
  },
  watch:{
    getPaymentMethodDataSet(paymentData) {
      if(paymentData){
        this.paymentMethodDataList = this.nameNumberFilter(paymentData);
      }

    },
    getStorageAccountDataSet(storageAccountData) {
      if(storageAccountData){
        this.storageAccountDataList = this.nameNumberFilter(storageAccountData);
      }
    },
    getCurrentLiabilityAccountDataSet(currentLiabilityAccountData) {
      this.currentLiabilityAccountDataList = this.nameNumberFilter(currentLiabilityAccountData);
    },
    getNewPaymentMethods(paymentData) {
      if (paymentData) {
        this.$refs["paymentMethodsComponents"].form = {
          name: null,
          notes: null
        };
        this.$refs["paymentMethodsComponents"].addPaymentMethodLoader = false;
        this.payment_methods_dialog = false;
        this.form.payment_method_number = paymentData.data.number;
        this.successToast("Success", paymentData.message);
        this.doGetPaymentMethodData();
      }
    },
    getNewStorageAccountData(storageAccountData) {
      if (storageAccountData) {
        this.$refs["storageAccountComponent"].form = {
          name: null,
          notes: null,
          supper_account: null,
          number: null
        };
        this.$refs["storageAccountComponent"].addStorageLoader = false;
        this.storage_account_dialog = false;
        this.form.storage_account_number = storageAccountData.data.number;

        this.successToast("Success", storageAccountData.message);
        this.doGetStorageAccountData();
      }
    },
    itemType() {
      this.filterSubItemList()
    },
    getInventoryPartItem(data){
      if(data){
        this.form = {
          name:null,
          code:null,
          unit_number:null,
          current_assets_account_number:null,
          equity_account_number:null,
          current_liability_account_number:null,
          income_account_number:null,
          cogs_account_number:null,
          notes:null,
          super_item:null,
          payment_method_number:null,
          storage_account_number:null,
          selling_price:0,
          tax_item_numbers:[],
          profile_number:null,
          openingStock:null,
          buying_price:0,
        }
        this.showTaxItems = false
        this.openingStock = {
          quantity:null,
              date_of_purchase:new Date().toISOString().substr(0, 10),
              reference:null,
        }
        this.paidCashCheck = false
        this.receivedOnCreditCheck = false
        this.addItemLoader = false
        this.showOpeningStock = false

        this.successToast("Success", data.message)
        this.filterSubItemList()
        this.$root.$emit("items_error_removal", "delete");
      }
    },
    getUnitDataList(unitDataList) {
      if(unitDataList) {
        this.filterUnits(unitDataList);
      }
    },
    getUnitData(unitData) {
      if (unitData) {
        this.form.unit_number = unitData.data.number;
        this.$refs["unit_components"].addUnitLoader = false;
        this.$refs["unit_components"].form = {
          name: null,
          abbreviation: null
        };
        this.add_item_unit_dialog = false;
        this.doGetUnitDataList();
        this.successToast("Success", unitData.message);
      }
    },
    getCurrentAssetsAccountDataSet(accountData) {
      this.currentAssetAccountDataList = this.accountFilter(accountData);
    },
    getIncomeAccountDataSet(accountData) {
      this.incomeAccountDataList = this.accountFilter(accountData);
    },
    getCostOfGoodsAccountDataSet(accountData) {
      this.costOfGoodsAccountDataList = this.accountFilter(accountData);
    },
    getEquityAccountDataSet(accountData) {
      this.equityAccountDataSet = this.accountFilter(accountData);
    },
    getChartOfAccountsData(chartData) {
      if (chartData) {
        this.$refs["chart_of_account_components"].chartSpinner = false;
        this.$refs["chart_of_account_components"].form = {
          name: null,
          code: null,
          description: null,
          sub_account: null,
          number: null
        };
        this.current_asset_dialog = false;
        this.income_dialog = false;
        this.cost_of_goods_dialog = false;
        this.successToast("Success", chartData.message);

        // alert(chartData.data.ref_code)
        if (chartData.data.ref_code === "CAST") {
          this.form.current_assets_account_number = chartData.data.number;
        } else if (chartData.data.ref_code === "INC") {
          this.form.income_account_number = chartData.data.number;
        } else if (chartData.data.ref_code === "COS") {
          this.form.cogs_account_number = chartData.data.number;
        }else if (chartData.data.ref_code === "CLBT") {
          this.form.current_liability_account_number = chartData.data.number;
        }else if (chartData.data.ref_code === "EQT") {
          this.form.equity_account_number = chartData.data.number;
        }

        this.fetchAccountData("CAST");
        this.fetchAccountData("INC");
        this.fetchAccountData("COS");
        this.fetchAccountData("CLBT");
        this.fetchAccountData("EQT");
      }
    },
    getSubItemData(subItemData) {
      this.filterSubItemData(subItemData);
    },
    getTaxDataAll(data) {
      if (data) {
        this.filterTaxData(data)
      }
    },
    getNewTaxData(data) {
      if (data) {
        this.form.tax_item_numbers = []
        this.form.tax_item_numbers = [data.data.number]
        this.$refs["new_tax_component"].taxSpinner = false
        this.$refs["new_tax_component"].form = {
          name:null,
          rate:null,
          profile_number:null,
          is_compound:false,
          is_absolute:false
        }
        this.addTaxDialog = false
        this.successToast("Success", data.message)
        this.fetchTaxData();
      }
    },
    getProfileResponseData(data) {
      if (data) {
        this.add_item_supplier_dialog = false
        this.openingStock.profile_number = data.data.number;
        this.$refs["profile_component"].customerSpinner = false;
        this.$refs["profile_component"].form = {
                                          name: null,
                                          address: null,
                                          number: null,
                                          tin: null,
                                          phoneContact: [
                                            {
                                              contact: null,
                                              id: null,
                                              type: null
                                            }
                                          ],
                                          emailContact: [
                                            {
                                              contact: null,
                                              id: null,
                                              type: null
                                            }
                                          ]
                                        };
        this.successToast("Success", data.message);
        this.fetchProfiles()
      }
    },
    getProfileDataList(data) {
      if(data) {
        this.filterProfileList(data);
      }
    },
    showOpeningStock(){
      if(this.showOpeningStock){
        this.form.openingStock = this.openingStock
      }else{
        this.form.openingStock = null
      }
    },
    inventoryPartDataInfo(data){
      if(data){
        this.checkInventoryDataInfo()
      }
    },
    getUpdateErrorMessage(data){
      if (data) {
        this.addItemLoader = false
      }
    },
    getUpdateItemData(data) {
      if (data) {
        this.addItemLoader = false
      }
    },
    showSubItemCategory(){
      if(!this.showSubItemCategory){
        this.form.super_item = ""
      }
    },
    paidCashOnCreditValidation(){
      if(this.paidCashOnCreditValidation){
        this.form.payment_method_number = null
        this.form.storage_account_number = null
      }
    },
    receivedOnCreditCheck(){
      if(this.receivedOnCreditCheck){
        this.paidCashCheck = false
        this.form.equity_account_number = null
      }
    },
    paidCashCheck(){
      if(this.paidCashCheck){
        this.receivedOnCreditCheck = false
        this.form.current_liability_account_number = null

      }
    }
  }
}
</script>

<style scoped>

</style>