<template>
  <div>
    <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>
    <form ref="form" @submit.prevent="saveStorageAccount">
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.name"
            label="Name"
            outlined
            dense
            required
          ></v-text-field>

          <v-row>
            <v-col cols="4">
              <v-checkbox
                v-model="showSubCategory"
                class="shrink mr-0 mt-0 float-left"
                hide-details
                label="Sub account of"
                id="sub_account_id"
              ></v-checkbox>
            </v-col>

            <v-col cols="8">
              <v-select
                outlined
                v-if="showSubCategory"
                v-model="form.supper_account"
                :items="newSubAccountData"
                item-value="id"
                color="pink"
                label="Super Account"
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-textarea
            v-model="form.notes"
            label="Memo"
            outlined
            rows="2"
            dense
          ></v-textarea>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="companyButton"
          :loading="addStorageLoader"
          color="#0000ff"
          dark
          type="submit"
        >
          {{
            editStorageAccountObj
              ? "Edit Bank/Cash Account"
              : "Save Bank/Cash Account"
          }}
        </v-btn>
      </v-card-actions>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "StorageAccount",
  props: {
    editStorageAccountObj: {}
  },
  data() {
    return {
      addStorageLoader: false,
      showSubCategory: false,
      form: {
        name: null,
        notes: null,
        supper_account: null,
        number: null
      },
      newSubAccountData: [],
      errorMessage:null,
    };
  },
  mounted() {
    this.fetchAllStorage();
    if (this.editStorageAccountObj) {
      this.storageEditor();
    }
  },
  methods: {
    ...mapActions(["saveNewStorageAccount", "fetchAllStorage"]),
    saveStorageAccount() {
      this.addStorageLoader = true;
      this.errorMessage = ""
      this.saveNewStorageAccount(this.form);
    },
    storageEditor() {
      this.form.name = this.editStorageAccountObj.name;
      this.form.notes = this.editStorageAccountObj.notes;
      this.form.number = this.editStorageAccountObj.number;
    },
    subAccountJsonData(subAccountData) {
      let account = subAccountData.data;
      let accountObj = [{ text: "Select Account", id: null }];
      let accountJson = {};
      for (let i = 0; i < account.length; i++) {
        accountJson = {
          text: account[i].name,
          id: account[i].number
        };
        accountObj.push(accountJson);
      }

      this.newSubAccountData = accountObj;
    }
  },
  computed: {
    ...mapGetters(
        [
            "getAllStorageAccount",
            "getTransactionErrorMessage"
        ]
    )
  },
  watch: {
    editStorageAccountObj() {
      this.storageEditor();
    },
    getAllStorageAccount(storageAccount) {
      if (storageAccount) {
        this.subAccountJsonData(storageAccount);
      }
    },
    showSubCategory(value) {
      if (!value) {
        this.form.supper_account = null;
      }
    },
    getTransactionErrorMessage(data){
      if(data){
        this.errorMessage = data.message
        this.errorToast("Error", data.message)
        this.addStorageLoader = false
      }
    }
  }
};
</script>

<style scoped></style>
