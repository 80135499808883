<template>
  <span>
    <v-row>
      <v-col>
<!--        <v-card>-->
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Unit of Measure</v-toolbar-title>
          </v-toolbar>

<!--          <v-tabs v-model="activeTab">-->
          <v-tabs>
            <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
            <v-tab href="#unit">
              <v-icon left>mdi-account</v-icon>
              Unit of Measure
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-unit">
              <v-icon left>mdi-lock</v-icon>
              New Unit
            </v-tab>

            <v-tab-item id="unit">
              <v-divider></v-divider>
              <v-card flat>
                <v-row class="mx-auto mt-8">
                  <v-col cols="8">
<!--                    <download-excel
                      style="color:#777777; width: 30%; margin-left: 2%; cursor: pointer;"
                      class="btn btn-default mt-3"
                      :data="unitTableData"
                      :fields="json_fields"
                      worksheet="Expenses"
                      name="stock.xls"
                      >Download Excel
                      <v-icon color="#777777">
                        mdi-cloud-download
                      </v-icon>
                    </download-excel>-->
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      class="mr-3"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      dense
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-data-table
                  :headers="headers"
                  :items="unitTableData"
                  :search="search"
                  :loading="tableUnitLoader"
                  loading-text="Loading... please wait"
                  :page="page"
                  :items-per-page="items_per_page"
                  :server-items-length="server_items_length"
                  :options.sync="paginationUnit"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editUnit(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteUnit(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item id="new-unit">
              <v-divider></v-divider>

              <v-card flat>
                <v-row>
                  <v-col cols="5" class="mt-15 ml-6">
                    <add-unit ref="unit_of_measure"></add-unit>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
<!--        </v-card>-->
      </v-col>
    </v-row>

    <v-dialog v-model="editUnitDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Unit of Measure</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editUnitDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-unit ref="unit_of_measure" :editUnitObj="editUnitObj"></add-unit>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import AddUnit from "../../commons/AddUnit";
import { mapActions, mapGetters } from "vuex";
import router from "@/global/router";

export default {
  name: "UnitOfMeasure",
  components: { AddUnit },
  data() {
    return {
      activeTab: "unit",
      search: "",
      tableUnitLoader: false,
      paginationUnit: {},
      page: 1,
      items_per_page: 10,
      server_items_length: 0,
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Name", value: "name" },
        { text: "Abbreviation", value: "abbreviation" },
        { text: "Action", value: "actions", sortable: false, width: "7%" }
      ],
      json_fields: {
        Name: "name",
        Rate: "abbreviation"
      },
      unitTableData: [],
      editUnitDialog: false,
      editUnitObj: {}
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  computed: {
    ...mapGetters([
      "getUnitData",
      "unitDataInfo",
      "getDeleteUnit",
      "getUnitErrorResponse"
    ])
  },
  methods: {
    ...mapActions(["doGetUnitList", "doDeleteUnit"]),
    editUnit(item) {
      // alert(JSON.stringify(item))
      this.editUnitObj = item;
      this.editUnitDialog = true;
    },
    deleteUnit(item) {
      let confirm_delete = confirm(
        "Are you sure, you want to delete this unit!!"
      );
      if (!confirm_delete) {
        return false;
      }
      this.doDeleteUnit(item.number);
    },
    filterUnits(raw_data) {
      let unitData = raw_data.data;
      this.server_items_length = raw_data.total;
      this.tableUnitLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let unitJsn = {};
      let unitObj = [];

      for (let i = 0; i < unitData.length; i++) {
        unitJsn = {
          key: i + 1,
          name: unitData[i].name,
          abbreviation: unitData[i].abbreviation,
          number: unitData[i].number
        };
        unitObj.push(unitJsn);
      }

      this.unitTableData = unitObj;
    }
  },
  watch: {
    getUnitData(unitData) {
      if (unitData) {
        this.successToast("Success", unitData.message);
        this.$refs["unit_of_measure"].form = {
          name: null,
          abbreviation: null
        };
        this.$refs["unit_of_measure"].addUnitLoader = false;

        this.editUnitDialog = false;

        let options = {
          page: this.page,
          per_page: this.items_per_page
        };
        this.doGetUnitList(options);
      }
    },
    paginationUnit(obj) {
      this.tableUnitLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage
      };
      this.doGetUnitList(options);
    },
    unitDataInfo(unitInfo) {
      this.filterUnits(unitInfo);
    },
    getDeleteUnit(deleteUnit) {
      if (deleteUnit) {
        let options = {
          page: this.page,
          per_page: this.items_per_page
        };
        this.doGetUnitList(options);
        this.successToast("Success", deleteUnit.message);
      }
    },
    getUnitErrorResponse(errorResp) {
      if (errorResp) {
        this.errorToast("Error", errorResp.message);
      }
    },
    activeTab(data) {
      router.push({ name: "unit-of-measure", query: { type: data } });
    }
  }
};
</script>

<style scoped></style>
