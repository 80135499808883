import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  supplierData: null,
  deleteSupplierData: null,
  supplierError: null
};

const getters = {
  getSupplierDataInfo: state => state.supplierData,
  getDeleteSupplierData: state => state.deleteSupplierData,
  getSupplierError: state => state.supplierError
};

const actions = {
  async doGetSuppliers({ commit }, data) {
    await axios
      .get(
        IP +
          "supplier/all-paginated" +
          "?page=" +
          data.page +
          "&per_page=" +
          data.per_page
      )
      .then(response => {
        commit("setSupplierData", response);
      })
      .catch(error => {
        commit("setSupplierErrorMessage", error);
      });
  },
  async doDeleteSupplier({ commit }, data) {
    await axios
      .delete(IP + "supplier/delete/" + data)
      .then(response => {
        commit("setDeleteSupplier", response);
      })
      .catch(error => {
        commit("setSupplierErrorMessage", error);
      });
  }
};

const mutations = {
  setSupplierData: (state, response) => {
    state.supplierData = response.data;
  },
  setDeleteSupplier: (state, response) => {
    state.deleteSupplierData = response.data;
  },
  setSupplierErrorMessage: (state, error) => {
    state.supplierError = error.response
      ? error.response.data.message
      : "Error: Network Error"; //error.response.data.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
