<template>
  <v-container fluid>
    <v-row>
      <v-col>

        <v-row>
          <v-col>
            <v-card>
              <v-progress-linear
                  v-if="progressLoader"
                  color="#1f7087"
                  indeterminate
                  rounded
                  height="2"
              ></v-progress-linear>

              <v-card-subtitle style="font-size: 18px;">Statistics</v-card-subtitle>

              <v-row  class="mx-auto">


                <v-col style="margin:0;
                    padding:0;
                    overflow-x:hidden; white-space: nowrap;" cols="12" v-for="(statList, index) in analyticsDataSet" :key="index"  :xs="12" :sm="6" :md="3" :lg="2" :xl="2">
                                  <v-card-title  class="d-inline-flex pa-2">
                                    <v-avatar  size="47" color="#eeecfe">
                                      <v-icon
                                          size="27"
                                          :color="statList.color"
                                      >
                                       {{statList.icon}}
                                      </v-icon>
                                    </v-avatar>
                                    <span>

                          <v-card-title style="color: #726c84;">



                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                   {{abbreviateNumber(statList.total)}}
                                </div>
                              </template>
                              <span>{{statList.total}}</span>
                            </v-tooltip>


                          </v-card-title>
                          <v-card-subtitle>
                         <router-link class="text-decoration-none" style="color: #726c84;" :to="{name: statList.name}" >  {{statList.title}}  </router-link>
                          </v-card-subtitle>

                </span>
                  </v-card-title>
                </v-col>

              </v-row>



            </v-card>
          </v-col>
        </v-row>



<!--        <v-row dense>




          <v-col col lg="12" md="12" sm="12">
            <v-card>
              <v-row
                style="width: 96%;"
                class="mx-auto"
                justify="space-between"
              >
                <v-card-title>Dashboard</v-card-title>

                <div style="width: 20%;" class="mt-2">
                  <v-overflow-btn
                    style="border-style: hidden;"
                    outlined
                    background-color="#fbfdff"
                    dense
                    class="my-2 filterIcon"
                    :items="filterItems"
                    v-model="filterItemsDashboard"
                    label="Filter"
                    prepend-inner-icon="mdi mdi-calendar-text"
                  ></v-overflow-btn>
                </div>
              </v-row>

              <v-divider></v-divider>

              <line-graph></line-graph>
            </v-card>
          </v-col>

          <v-col col lg="6" md="6" sm="12">
            <v-card>
              <bar-graph></bar-graph>
            </v-card>
          </v-col>
          <v-col col lg="6" md="6" sm="12">
            <v-card>
              <doughnut-graph></doughnut-graph>
            </v-card>
          </v-col>
        </v-row>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
// import BarGraph from "./commons/Graphs/BarGraph";
// import LineGraph from "./commons/Graphs/LineGraph";
// import DoughnutGraph from "./commons/Graphs/DoughnutGraph";

export default {
  name: "Accounting",
  components: {
    // DoughnutGraph,
    // LineGraph,
    // BarGraph
  },
  data() {
    return {
      progressLoader:false,
      user_names: null,
      company_number: "",
      user: this.$userProfile,
      company: this.$company,
      filterItemsDashboard: null,
      analyticsDataSet:[],
      filterItems: [
        "Last 7 days",
        "Last 30 days",
        "Last 3 months",
        "Last 6 months",
        "Last Year",
        "Lifetime"
      ],
      /*items: [
        {
          color: "#1F7087",
          icon: "mdi mdi-chart-line",
          title: "Invoices",
          artist: "profiles Invoices"
        },
        {
          color: "#952175",
          icon: "mdi mdi-cash-usd",
          title: "Bills",
          artist: "Supplier bills"
        },
        {
          color: "#385F73",
          icon: "mdi mdi-cart-outline",
          title: "Daily Sales",
          artist: "Daily sales"
        },
        {
          color: "#73383d",
          icon: "mdi mdi-chart-timeline",
          title: "Stock",
          artist: "In Stock"
        }
      ]*/
    };
  },
  mounted() {
    // this.company_number = this.$company.number;
    // this.user_names = this.$userProfile;
    // this.company = this.company;
    this.fetchStatisticalData()
    this.progressLoader = true
  },
  beforeMount() {
    this.company_number = this.$company.number;
    this.user_names = this.$userProfile;
  },
  methods: {
    ...mapActions(
        [
            "doGetCompanyProfile",
            "fetchStatisticalData"
        ]
    ),
    filterAnalyticsData(analyticsData){

      this.progressLoader = false

      let invoicesTotal  = analyticsData.invoices.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      let unpaidInvoicesTotal  = analyticsData.unpaid_invoices.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      let totalPayments  = analyticsData.total_payments_received.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      let billsTotal  = analyticsData.bills.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      let unpaidBillsTotal  = analyticsData.unpaid_bills.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      let payOuts  = analyticsData.total_payouts_made.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")


      this.analyticsDataSet = [
                              {
                                color: "#0072a7",
                                icon: "mdi-script",
                                title: "Invoices",
                                total: invoicesTotal,
                                name: "invoice",

                              },
                              {
                                color: "#28c76f",
                                icon: "mdi-note-outline",
                                title: "Unpaid Invoices",
                                total: unpaidInvoicesTotal,
                                name: "invoice",

                              },
                                {
                                color: "#73383d",
                                icon: "mdi-trending-up",
                                title: "Total Payments",
                                total: totalPayments,
                                name: "customer-payments",

                              },
                              {
                                color: "#0072a7",
                                icon: "mdi-receipt",
                                title: "Bills",
                                total: billsTotal,
                                name: "bill",
                              },
                              {
                                color: "#28c76f",
                                icon: "mdi-panorama-vertical",
                                title: "Unpaid Bills",
                                total: unpaidBillsTotal,
                                name: "bill",
                              },
                              {
                                color: "#73383d",
                                icon: "mdi-currency-usd",
                                title: "Payouts",
                                total: payOuts,
                                name: "supplier-payouts",
                              },

                ]

    }
  },
  computed: {
    ...mapGetters(
        [
            "getCompanyData",
            "getAnalyticsData"
        ]
    )
  },
  watch: {
    filterItemsDashboard(data) {
      alert(data);
    },
    getAnalyticsData(analyticsData){
      if(analyticsData){
        this.filterAnalyticsData(analyticsData)
      }
    }
  }
};
</script>

<style scoped></style>
