<template>
  <span>
    <form ref="form" @submit.prevent="newRole">
      <v-row class="mx-auto">
        <v-col cols="6">
          <v-alert type="error" v-if="errorMessage">
            {{ errorMessage }}
          </v-alert>
          <v-text-field
            outlined
            v-model="form.name"
            label="Role Name"
            required
            type="text"
            dense
          ></v-text-field>

          <v-textarea
            outlined
            v-model="form.description"
            rows="3"
            name="input-7-4"
            label="Description"
            value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
          ></v-textarea>
        </v-col>

        <v-col cols="12">
          <v-simple-table dense style="border-bottom: solid 1px #e0e0e0;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Groups [ Access Area ]
                  </th>
                  <th class="text-left">
                    Permissions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(permissionGroup, index) in groupPermissions"
                  :key="index"
                >
                  <td style="width: 20%; border-right: solid 1px #e0e0e0;">
                    <v-btn
                      style="width: 90%; text-align: left"
                      class="ma-2"
                      outlined
                      color="indigo"
                    >
                      {{ index }}
                    </v-btn>
                  </td>
                  <td>
                    <v-row
                      class="mx-auto"
                      style="padding-bottom: 8px; padding-top: 8px;"
                    >
                      <span
                        v-for="(permissions, key) in permissionGroup"
                        :key="key"
                      >
                        <v-checkbox
                          v-model="form.permissions"
                          :label="permissions"
                          color="#242f7e"
                          :value="key"
                          hide-details
                          class="shrink mr-8"
                        ></v-checkbox>
                      </span>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col cols="12">
          <div class="mx-auto text-right">
            <v-spacer></v-spacer>
            <v-btn
              class="mt-4"
              :loading="spinner"
              color="#0000ff"
              dark
              type="submit"
            >
              {{ roleData ? "Edit" : "Save" }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </form>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewRole",
  props: {
    roleData: {}
  },
  data() {
    return {
      errorMessage: null,
      form: {
        role_number: null,
        name: null,
        description: null,
        permissions: []
      },
      groupPermissions: {},
      spinner: false,
      role_uid: null
    };
  },
  mounted() {
    this.errorMessage = "";
    if (this.roleData) {
      this.checkRole();
    } else {
      this.getRolesList();
    }
  },
  methods: {
    ...mapActions([
      "getRolesList",
      "doSaveUserRolesAndPermissions",
      "fetchRoleInfo",
      "updateRoleAndPermission"
    ]),
    newRole() {
      this.spinner = true;
      this.errorMessage = null;

      if (this.form.role_number) {
        this.updateRoleAndPermission(this.form);
      } else {
        this.doSaveUserRolesAndPermissions(this.form);
      }
    },
    checkRole() {
      this.fetchRoleInfo(this.roleData.number);
    },
    filterRoleEdit(roleDataEdit) {
      let role_data = roleDataEdit;
      this.form.name = role_data.name;
      this.form.description = role_data.description;
      this.form.role_number = role_data.number;

      let role = role_data.role_permissions;
      this.form.permissions = [];
      for (let i = 0; i < role.length; i++) {
        if (role[i].value === "Yes") {
          this.form.permissions.push(role[i].permission);
        }
      }

      this.getRolesList();
    }
  },
  computed: {
    ...mapGetters([
      "getRolesAndPermission",
      "getRolesAndPermissionError",
      "getEditRolesAndPermission"
    ])
  },
  watch: {
    getRolesAndPermission(userRoles) {
      if (userRoles) {
        this.groupPermissions = userRoles;
      }
    },
    getRolesAndPermissionError(errorRole) {
      if (errorRole) {
        this.spinner = false;
        this.errorMessage = errorRole.message;
        this.errorToast("Error", errorRole.message);
      }
    },
    roleData() {
      this.checkRole();
    },
    getEditRolesAndPermission(rolesInfo) {
      if (rolesInfo) {
        this.filterRoleEdit(rolesInfo);
      }
    }
  }
};
</script>

<style scoped></style>
