<template>
  <div>


    <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>

    <form ref="form" @submit.prevent="saveLicence">


      <v-autocomplete
          class="mt-2"
          outlined
          clearable
          v-model="form.company_number"
          :items="companyList"
          item-value="id"
          color="pink"
          label="Company"
          required
          dense
      ></v-autocomplete>

      <v-text-field
          v-model="form.expiry_date"
          label="Date [ dd / mm / yyy ]"
          outlined
          dense
          type="date"
          required
          hide-details
      ></v-text-field>
      <small style="color: red;" v-if="yearLengthValidator(form.expiry_date)">
        Invalid Date
      </small>


      <div class="text-right mt-5">
        <v-btn
            class="companyButton"
            :loading="buttonSpinner"
            color="#0000ff"
            dark
            type="submit"
        >
         Save
        </v-btn>

      </div>
    </form>


  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewLicense",
  data(){
    return{
      errorMessage:null,
      buttonSpinner:false,
      companyList:[],
      form:{
        company_number:null,
        expiry_date:null,
      }
    }
  },
  mounted() {
    this.fetchCompanyList()
  },
  methods:{
    ...mapActions(
        [
            "saveNewLicense",
            "fetchCompanyList"
        ]
    ),
    saveLicence(){
      this.buttonSpinner = true
      this.saveNewLicense(this.form)
    },
    filterCompanyList(customerList) {
      let dataObj = [
        {text: "Select Company", id: null, disabled: true}
      ];
      let dataJson = {};
      customerList.forEach(list =>{
        dataJson = {
          text: list.company_name,
          id: list.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })


      this.companyList = dataObj;
    },
  },
  computed:{
...mapGetters(
    [
        "getLicenseError",
        "getAllCompany"
    ]
)
  },
  watch:{
    getLicenseError(data){
      if(data){
        this.buttonSpinner = false
        this.errorToast("Error", data.message)
      }
    },
    getAllCompany(data){
      if(data){
        this.filterCompanyList(data)
      }
    }
  }
}
</script>

<style scoped>

</style>