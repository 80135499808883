import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  invoiceSettings: null,
  invoiceSettingsDetails: null,
  invoiceSettingsError: null
};

const getters = {
  getInvoiceSettings: state => state.invoiceSettings,
  getInvoiceSettingsDetails: state => state.invoiceSettingsDetails,
  getInvoiceSettingsError: state => state.invoiceSettingsError
};

const actions = {
  async doSaveInvoiceData({ commit }, data) {
    await axios
      .post(IP + "invoice/settings/save", data)
      .then(response => {
        commit("setInvoiceSettings", response);
      })
      .catch(error => {
        commit("setInvoiceSettingsError", error);
      });
  },
  async fetchInvoiceSettings({ commit }) {
    await axios
      .get(IP + "invoice/settings/view")
      .then(response => {
        commit("setInvoiceSettingsDetails", response);
      })
      .catch(error => {
        commit("setInvoiceSettingsError", error);
      });
  },
};

const mutations = {
  setInvoiceSettings: (state, response) => {
    state.invoiceSettings = response.data;
  },
  setInvoiceSettingsDetails: (state, response) => {
    state.invoiceSettingsDetails = response.data;
  },

  setInvoiceSettingsError: (state, error) => {
    state.invoiceSettingsError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
