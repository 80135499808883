<template>
  <div style="width: 97%;" class="mx-auto">
    <br />
    <!--    profiles Id: {{ $route.params.id }}-->

    <v-row>
      <v-col col lg="3" md="3" sm="12">
        <v-card class="mx-auto" tile>
          <v-list dense>
            <v-subheader>Customer</v-subheader>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                @click.prevent="setActiveTabs(item.text)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col col lg="9" md="9" sm="12">

        <v-card style="padding-bottom: 10px;" v-if="showCustomerDetails">
          <customer-info :customerProfileData="customerProfileData"></customer-info>
        </v-card>

        <v-card v-if="showCustomerInvoices">
          <customer-invoice></customer-invoice>
        </v-card>


        <v-card v-if="showCustomerBills">
          <supplier-bill></supplier-bill>
        </v-card>

        <v-card v-if="showCustomerPayments">
          <customer-details-payouts></customer-details-payouts>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomerInvoice from "@/modules/dashboard/accounting/profiles/CustomerInvoice";
import CustomerDetailsPayouts from "@/modules/dashboard/accounting/CustomerPayments/CustomerDetailsPayouts";
import CustomerInfo from "@/modules/dashboard/accounting/profiles/CustomerInfo";
import SupplierBill from "@/modules/dashboard/accounting/Supplier/SupplierBill";
export default {
  name: "ProfileDetails",
  components: {SupplierBill, CustomerInfo, CustomerDetailsPayouts, CustomerInvoice },
  data() {
    return {
      client_id: null,
      selectedItem: 0,
      customerProfileData: {},
      customerPaymentsList: [],
      items: [
        { text: "Details", icon: "mdi-flag" },
        { text: "Invoices", icon: "mdi-clock" },
        { text: "Bills", icon: "mdi-cash-multiple" },
        { text: "Payments", icon: "mdi-account" }
      ],
      showCustomerDetails: true,
      showCustomerInvoices: false,
      showCustomerPayments: false,
      showCustomerBills: false
    };
  },
  mounted() {
    this.customerProfile(this.$route.query.prof);
  },
  methods: {
    ...mapActions(["customerProfile"]),
    setActiveTabs(items) {
      if (items === "Details") {
        this.showCustomerDetails = true;
        this.showCustomerInvoices = false;
        this.showCustomerPayments = false;
        this.showCustomerBills = false
      } else if (items === "Invoices") {
        this.showCustomerDetails = false;
        this.showCustomerInvoices = true;
        this.showCustomerPayments = false;
        this.showCustomerBills = false
      } else if (items === "Payments") {
        this.showCustomerDetails = false;
        this.showCustomerInvoices = false;
        this.showCustomerPayments = true;
        this.showCustomerBills = false
      } else if (items === "Bills"){
        this.showCustomerBills = true
        this.showCustomerDetails = false;
        this.showCustomerInvoices = false;
        this.showCustomerPayments = false;
      }
    }
  },
  computed: {
    ...mapGetters(
        [
            "getCustomerProfileData",
            "getCustomerDetailsErrorMessage"
        ]
    )
  },
  watch: {
    getCustomerProfileData(customerProfileInfo) {
      this.customerProfileData = customerProfileInfo;
    },
    getCustomerDetailsErrorMessage(error){
      if(error){
        this.errorToast("Error", error.message)
      }
    }
  }
};
</script>

<style scoped></style>
