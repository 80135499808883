<template>
<span>

   <form ref="form" @submit.prevent="saveItem">
  <v-row>
    <v-col cols="6">

      <v-text-field
          v-model="form.name"
          dense
          label="Name"
          outlined
          hide-details
          required
      ></v-text-field>

          <v-row class="mt-2">
            <v-col cols="6">
  <v-text-field
      v-model="form.code"
      dense
      label="Code"
      outlined
      hide-details
  ></v-text-field>
            </v-col>


<v-col cols="6">
     <v-autocomplete
         v-model="form.unit_number"
         :items="unitDataList"
         append-outer-icon="mdi-plus"
         clearable
         dense
         item-value="id"
         label="Unit of Measure"
         outlined
         hide-details
         @click:append-outer="addItemUnitData"
     ></v-autocomplete>
</v-col>
          </v-row>


       <v-row>
            <v-col cols="6">
              <v-checkbox
                  id="sub_account_id"
                  v-model="showItemForSell"
                  class="shrink mr-0 mt-0 float-left"
                  dense
                  hide-details
                  label="Do you sell this service?"
              ></v-checkbox>
            </v-col>
         <v-col cols="6">

              <v-checkbox
                  id="sub_account_id"
                  v-model="showItemForPurchase"
                  class="shrink mr-0 mt-0 float-left"
                  dense
                  hide-details
                  label="I purchase this service"
              ></v-checkbox>
            </v-col>
       </v-row>


         <fieldset
             v-if="showItemForSell"
             style="border: 1px solid #e5e1e1; color: #bfb5b5"
             class="mb-4"
         >
            <legend style="padding: 0px 7px 0px 7px; margin: auto;">
             Sell item
            </legend>

      <v-row class="mx-auto">
        <v-col cols="6">
            <v-text-field
                v-model="form.selling_price"
                dense
                hide-details
                label="Selling Price"
                outlined
                @keypress="onlyNumberValidator($event)"
                v-on:blur="computeNumber(form.selling_price, 'selling_price')"
            ></v-text-field>
        </v-col>
        <v-col cols="6">
           <v-autocomplete
               v-model="form.income_account_number"
               :items="incomeAccountDataList"
               append-outer-icon="mdi-plus"
               clearable
               color="pink"
               dense
               hide-details
               item-value="id"
               label="Income Account"
               outlined
               @click:append-outer="addIncomeAccountModel"
           ></v-autocomplete>
        </v-col>
      </v-row>

         </fieldset>




        <fieldset
            v-if="showItemForPurchase"
            style="border: 1px solid #e5e1e1; color: #bfb5b5"
            class="mb-4"
        >
            <legend style="padding: 0px 7px 0px 7px; margin: auto;">
              Purchase item
            </legend>

      <v-row class="mx-auto">
        <v-col cols="6">
            <v-text-field
                v-model="form.buying_price"
                dense
                hide-details
                label="Buying Price"
                outlined
                @keypress="onlyNumberValidator($event)"
                v-on:blur="computeNumber(form.buying_price, 'buying_price')"
            ></v-text-field>
        </v-col>
        <v-col cols="6">
           <v-autocomplete
               v-model="form.cogs_account_number"
               :items="eXPCOSAAccountDataList"
               append-outer-icon="mdi-plus"
               clearable
               color="pink"
               dense
               hide-details
               item-value="id"
               label="Account"
               outlined
               @click:append-outer="addCOSExpAccountModel"
           ></v-autocomplete>
        </v-col>
      </v-row>

         </fieldset>


    </v-col>
    <v-col cols="6">


       <v-row>
            <v-col cols="6">
              <v-checkbox
                  id="sub_account_id"
                  v-model="showSubItemCategory"
                  class="shrink mr-0 mt-0 float-left"
                  dense
                  hide-details
                  label="Sub Item of"
              ></v-checkbox>
            </v-col>
            <v-col v-if="showSubItemCategory" cols="12">
              <v-select
                  clearable
                  v-if="showSubItemCategory"
                  v-model="form.super_item"
                  :items="subItemDataList"
                  color="pink"
                  dense
                  hide-details
                  item-value="id"
                  label="Select Super Item"
                  outlined
              ></v-select>
            </v-col>
          </v-row>

            <v-textarea
                v-model="form.notes"
                dense
                label="Description"
                outlined
                rows="3"
            ></v-textarea>

    </v-col>
  </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <v-btn
            :loading="addItemLoader"
            class="companyButton"
            color="#0000ff"
            dark
            type="submit"
        >
          {{ serviceDataInfo.number ? "Update Item" : "Save Item" }}
        </v-btn>
      </v-row>
   </form>


     <v-dialog v-model="add_item_unit_dialog" width="30%">
      <v-card>
        <div class="mx-auto" style="width: 95%; padding-bottom: 10px;">
          <v-row>
            <v-card-title> Add Unit</v-card-title>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="add_item_unit_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-unit ref="unit_components"></add-unit>
        </div>
      </v-card>
    </v-dialog>


  <!--    dialog to add current assets-->
    <v-dialog v-model="current_asset_dialog" width="35%">
      <v-card>
        <div class="mx-auto" style="width: 95%; padding-bottom: 20px;">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="current_asset_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              ref="chart_of_account_components"
              account_type="CAST"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

  <!--    dialog to add cost of goods and expense account-->
    <v-dialog v-model="cos_exp_dialog" width="35%">
      <v-card>
        <div class="mx-auto" style="width: 95%; padding-bottom: 20px;">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="cos_exp_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              ref="chart_of_account_components"
              :account_type_list="['EXP','COS']"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

  <!--    dialog to add income account-->
    <v-dialog v-model="income_dialog" width="35%">
      <v-card>
        <div class="mx-auto" style="width: 95%; padding-bottom: 20px;">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="income_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              ref="chart_of_account_components"
              account_type="INC"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

  <!--    dialog to add cost of goods account-->
    <v-dialog v-model="cost_of_goods_dialog" width="35%">
      <v-card>
        <div class="mx-auto" style="width: 95%; padding-bottom: 20px;">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="cost_of_goods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              ref="chart_of_account_components"
              account_type="COS"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>




</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddUnit from "@/modules/dashboard/accounting/commons/AddUnit";
import AddAccount from "@/modules/dashboard/accounting/commons/AddAccount";

export default {
  name: "ServiceItem",
  components: { AddAccount, AddUnit},
  props: {
    itemType: String,
    editItem: String,
    serviceDataInfo: {}
  },
  data() {
    return {
      addItemLoader: false,
      service_item_number: null,
      form: {
        name: null,
        code: null,
        unit_number: "",
        // current_assets_account_number: "",
        income_account_number: "",
        cogs_account_number: "",
        selling_price: 0,
        buying_price: 0,
        notes: null,
        super_item: null
      },
      showSubItemCategory: false,
      showItemForSell: false,
      showItemForPurchase: false,
      unitDataList: [],
      currentAssetAccountDataList: [],
      incomeAccountDataList: [],
      costOfGoodsAccountDataList: [],
      eXPCOSAAccountDataList: [],
      add_item_unit_dialog: false,
      current_asset_dialog: false,
      income_dialog: false,
      cos_exp_dialog: false,
      cost_of_goods_dialog: false,
      subItemDataList: [],
      addSuperItemDialog: false,
    }
  },
  mounted() {
    this.doGetUnitDataList();
    this.fetchAccountData("CAST");
    this.fetchAccountData("INC");
    this.fetchAccountData("COS");
    this.fetchEXPCOSAccountData('EXP,COS');
    this.filterSubItemList()
    this.getFilteredServiceInfo()
  },
  methods: {
    ...mapActions(
        [
          "saveServiceItem",
          "doGetUnitDataList",
          "doFetchAccountData",
          "doGetSubItemData",
          "editServiceItem",
          "fetchEXPCOSAccountData"
        ]
    ),
    saveItem() {
      this.$emit("itemsErrorRemoval", "data");

      let formData = this.form
      formData.itemType = this.itemType;
      formData.selling_price = parseFloat(formData.selling_price.toString().replace(/,/g, ""))
      formData.buying_price = parseFloat(formData.buying_price.toString().replace(/,/g, ""))

      if (!this.form.unit_number) {
        let confirm_unit = confirm(
            "Are you sure you want to continue without a unit of measure"
        );
        if (!confirm_unit) {
          return
        }
      }
      this.addItemLoader = true
      if (this.service_item_number) {
        formData.number = this.service_item_number;
        this.editServiceItem(formData)
      } else {
        this.saveServiceItem(formData)
      }


    },
    addItemUnitData() {
      this.add_item_unit_dialog = true;
    },
    addAccountModel() {
      this.current_asset_dialog = true;
    },
    addIncomeAccountModel() {
      this.income_dialog = true;
    },
    addCOSExpAccountModel() {
      this.cos_exp_dialog = true;
    },
    addCostOfGoodsAccountModel() {
      this.cost_of_goods_dialog = true;
    },
    filterUnits(unitDataList) {
      let unitJsn = {};
      let unitObj = [
        {
          text: "Select unit",
          id: "",
          disabled: true
        }
      ];
      for (let i = 0; i < unitDataList.length; i++) {
        unitJsn = {
          text:
              unitDataList[i].name + " ( " + unitDataList[i].abbreviation + " )",
          id: unitDataList[i].number
        };
        unitObj.push(unitJsn);
      }

      this.unitDataList = unitObj;
    },
    computeNumber(value, type) {
      ///this removes any value that starts with a zero
      value = value.toString().replace(/,/g, "");
      value = parseFloat(value)


      if(type === 'selling_price') {
        this.form.selling_price =
            value ? this.transformNumbersToAccountingStandard(eval(value)).toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      }else{
        this.form.buying_price =
            value  ? this.transformNumbersToAccountingStandard(eval(value)).toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      }

    },
    fetchAccountData(accountType) {
      this.doFetchAccountData(accountType);
    },
    filterSubItemData(subItemData) {
      let subItemObj = [];
      let subItemJson = {};
      for (let i = 0; i < subItemData.length; i++) {
        subItemJson = {
          text: subItemData[i].full_name,
          id: subItemData[i].number
        };
        subItemObj.push(subItemJson);
      }

      this.subItemDataList = subItemObj;

      /*  if (subItemData.length > 0) {
          this.show_super_item_check_box = true;
        } else {
          this.show_super_item_check_box = false;
        }*/
    },
    addSuperItem() {
      this.addSuperItemDialog = true;
    },
    filterSubItemList() {
      if (this.itemType) {
        this.doGetSubItemData(this.itemType);
      }
    },
    getFilteredServiceInfo() {
      if (this.serviceDataInfo) {
        let dataInfo = this.serviceDataInfo
        this.form.name = dataInfo.name ? dataInfo.name : ""
        this.form.code = dataInfo.code ? dataInfo.code : ""
        this.form.unit_number = dataInfo.unit ? dataInfo.unit.number : ""
        // this.form.current_assets_account_number = dataInfo.current_assets_account_number ? dataInfo.current_assets_account_number : ""
        this.form.income_account_number = dataInfo.income_account_number ? dataInfo.income_account_number : ""
        this.form.cogs_account_number = dataInfo.cogs_account_number ? dataInfo.cogs_account_number : ""
        this.form.selling_price = dataInfo.selling_price ? dataInfo.selling_price.toFixed(2) : 0
        this.form.buying_price = dataInfo.buying_price ? dataInfo.buying_price.toFixed(2) : 0
        this.form.notes = dataInfo.notes ? dataInfo.notes : ""
        //check if super item has been selected
        const checkItem = obj => obj.id === dataInfo.super_item
        this.showSubItemCategory = this.subItemDataList.some(checkItem)
        this.form.super_item = dataInfo.super_item ? dataInfo.super_item : ""
        this.service_item_number = dataInfo.number ? dataInfo.number : ""

        if(dataInfo.selling_price || dataInfo.income_account_number){
          this.showItemForSell = true
        }else {
          this.showItemForSell = false
        }

        if(dataInfo.buying_price || dataInfo.cogs_account_number){
          this.showItemForPurchase = true
        }else {
          this.showItemForPurchase = false
        }


      }
    }
  },
  computed: {
    ...mapGetters(
        [
          "getNewServiceItemData",
          "getUnitDataList",
          "getUnitData",
          "getCostOfGoodsAccountDataSet",
          "getIncomeAccountDataSet",
          "getCurrentAssetsAccountDataSet",
          "getChartOfAccountsData",
          "getSubItemData",
          "getUpdateItemData",
          "getUpdateErrorMessage",
          "getEXPCOSAccountDataInfo"

        ]
    )
  },
  watch: {
    getEXPCOSAccountDataInfo(data){
      if(data){
        this.eXPCOSAAccountDataList = this.accountFilter(data);
      }
    },
    serviceDataInfo() {
      this.getFilteredServiceInfo()
    },
    itemType() {
      this.filterSubItemList()
    },
    getNewServiceItemData(data) {
      if (data) {
        this.form = {
          name: null,
          code: null,
          unit_number: "",
          income_account_number: "",
          cogs_account_number: "",
          selling_price: 0,
          buying_price: 0,
          notes: null,
          super_item: null
        }
        this.addItemLoader = false
        this.showItemForSell = false
        this.showItemForPurchase = false
        this.successToast("Success", data.message)
        this.addSuperItemDialog = false
        // this.$root.$emit("items_error_removal", "delete");
        this.filterSubItemList()
      }
    },
    getUnitDataList(unitDataList) {
      if (unitDataList) {
        this.filterUnits(unitDataList);
      }
    },
    getUnitData(unitData) {
      if (unitData) {
        this.form.unit_number = unitData.data.number;
        this.$refs["unit_components"].addUnitLoader = false;
        this.$refs["unit_components"].form = {
          name: null,
          abbreviation: null
        };
        this.add_item_unit_dialog = false;
        this.doGetUnitDataList();
        this.successToast("Success", unitData.message);
      }
    },
    getCurrentAssetsAccountDataSet(accountData) {
      this.currentAssetAccountDataList = this.accountFilter(accountData);
    },
    getIncomeAccountDataSet(accountData) {
      this.incomeAccountDataList = this.accountFilter(accountData);
    },
    getCostOfGoodsAccountDataSet(accountData) {
      this.costOfGoodsAccountDataList = this.accountFilter(accountData);
    },
    getChartOfAccountsData(chartData) {
      if (chartData) {
        this.$refs["chart_of_account_components"].chartSpinner = false;
        this.$refs["chart_of_account_components"].form = {
          name: null,
          code: null,
          description: null,
          sub_account: null,
          number: null
        };
        this.current_asset_dialog = false;
        this.income_dialog = false;
        this.cost_of_goods_dialog = false;
        this.cos_exp_dialog = false;
        this.successToast("Success", chartData.message);

        // alert(JSON.stringify(chartData.data))
        if (chartData.data.ref_code === "CAST") {
          this.form.current_assets_account_number = chartData.data.number;
        } else if (chartData.data.ref_code === "INC") {
          this.form.income_account_number = chartData.data.number;
        } else if (chartData.data.ref_code === "COS" ||  chartData.data.ref_code === "EXP") {
          this.form.cogs_account_number = chartData.data.number;
        }

        this.fetchAccountData("CAST");
        this.fetchAccountData("INC");
        this.fetchAccountData("COS");
        this.fetchEXPCOSAccountData("EXP,COS");
      }
    },
    getSubItemData(subItemData) {
      this.filterSubItemData(subItemData);
    },
    getUpdateItemData(data) {
      if (data) {
        this.addItemLoader = false
      }
    },
    getUpdateErrorMessage(data){
      if (data) {
        this.addItemLoader = false
      }
    },
    showSubItemCategory(){
      if(!this.showSubItemCategory){
        this.form.super_item = null
      }
    },
    showItemForSell(){
      if(!this.showItemForSell){
        this.form.selling_price = 0
        this.form.income_account_number = null
      }
    },
    showItemForPurchase(){
      if(!this.showItemForPurchase){
        this.form.buying_price = 0
        this.form.cogs_account_number = null
      }
    }
  }
}
</script>

<style scoped>

</style>