<template>
  <span>
    <form ref="form" @submit="saveSupplier">
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.name"
            label="Supplier Name"
            outlined
            dense
            prepend-inner-icon="mdi-account"
            required
          ></v-text-field>

          <v-text-field
            v-model="form.address"
            label="Address"
            outlined
            prepend-inner-icon="mdi-map-marker"
            dense
          ></v-text-field>

          <v-text-field
            v-model="form.tin"
            label="TIN"
            outlined
            prepend-inner-icon="mdi-numeric-9-plus-box-outline"
            dense
            hide-details
          ></v-text-field>

          <v-row class="mx-auto">
            <v-checkbox
              v-model="enableAddContacts"
              class="shrink mt-2 mb-5 float-left"
              hide-details
              label="Add contacts"
              id="sub_account_id"
            ></v-checkbox>
          </v-row>

          <v-divider v-if="enableAddContacts"></v-divider>

          <v-simple-table dense light v-if="enableAddContacts">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(phoneData, index) in form.phoneContact"
                  :key="index"
                >
                  <td>
                    <v-text-field
                      flat
                      solo
                      :placeholder="'+25670000000'+index"
                      v-model="phoneData.contact"
                      prepend-inner-icon="mdi-phone"
                      type="tel"
                      dense
                      hide-details
                      :append-outer-icon="index === 0 ? '' : 'mdi-close'"
                      @click:append-outer="removePhone(index)"
                    ></v-text-field>
                  </td>
                </tr>

                <tr>
                  <td colspan="6" @click="addPhone" style="cursor: pointer;">
                    <span class="text--disabled">Add another Phone</span>
                  </td>
                </tr>

                <tr
                  v-for="(emailData, index) in form.emailContact"
                  :key="index"
                >
                  <td>
                    <v-text-field
                      flat
                      solo
                      :placeholder="'email'+(index+1)+'@address.com'"
                      v-model="emailData.contact"
                      prepend-inner-icon="mdi-email-outline"
                      type="email"
                      dense
                      :append-outer-icon="index === 0 ? '' : 'mdi-close'"
                      @click:append-outer="removeEmail(index)"
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>

                <tr>
                  <td colspan="6" @click="addEmail" style="cursor: pointer;">
                    <span class="text--disabled">Add another Email</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-divider v-if="enableAddContacts"></v-divider>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="companyButton"
          :loading="addSupplierLoader"
          color="#0000ff"
          dark
          type="submit"
        >
          {{ editSupplierObj ? "Edit Supplier" : "Save Supplier" }}
        </v-btn>
      </v-card-actions>
    </form>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AddSupplier",
  props: {
    editSupplierObj: {}
  },
  data() {
    return {
      addSupplierLoader: false,
      enableAddContacts: false,
      form: {
        name: null,
        address: null,
        tin: null,
        number: null,
        phoneContact: [
          {
            contact: null,
            id: null,
            type: null
          }
        ],
        emailContact: [
          {
            contact: null,
            id: null,
            type: null
          }
        ]
      }
    };
  },
  mounted() {
    if (this.editSupplierObj) {
      this.customerProfile(this.editSupplierObj.number);
    }
  },
  methods: {
    ...mapActions(["doSaveProfile", "customerProfile"]),
    saveSupplier(evt) {
      evt.preventDefault();
      //this.addSupplierLoader = true;
      this.doSaveProfile(this.form);
    },
    addPhone() {
      this.form.phoneContact.push({
        contact: null,
        id: null,
        type: "phone"
      });
    },
    removePhone(index) {
      this.form.phoneContact.splice(index, 1);
    },
    addEmail() {
      this.form.emailContact.push({
        contact: null,
        id: null,
        type: "email"
      });
    },
    removeEmail(index) {
      this.form.emailContact.splice(index, 1);
    }
  },
  computed: {
    ...mapGetters(["getProfileResponseData", "getCustomerProfileData"])
  },
  watch: {
    editSupplierObj() {
      this.customerProfile(this.editSupplierObj.number);
    },
    getProfileResponseData(userProfileData) {
      if (userProfileData) {
        this.form.name = userProfileData.name;
        this.form.address = userProfileData.address;
        this.form.tin = userProfileData.tin;
        this.form.number = userProfileData.number;

        this.enableAddContacts = userProfileData.profile_contacts.length !== 0;
        let contactInfo = [];

        contactInfo = userProfileData.profile_contacts;
        this.form.phoneContact = [];
        this.form.emailContact = [];
        for (let i = 0; i < contactInfo.length; i++) {
          if (contactInfo[i].type === "phone") {
            this.form.phoneContact.push({
              contact: contactInfo[i].contact,
              id: contactInfo[i].id,
              type: contactInfo[i].type
            });
          } else if (contactInfo[i].type === "email") {
            this.form.emailContact.push({
              contact: contactInfo[i].contact,
              id: contactInfo[i].id,
              type: contactInfo[i].type
            });
          }
        }
      }
    }
  }
};
</script>

<style scoped></style>
