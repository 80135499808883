<template>
  <v-row class="footer">
    <v-col>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="1" class="logo-icon mt-5" :style="logoImageStyle"></v-col>
            <v-col cols="11">
              <span style="font-weight: bold; font-size: 18px">Base Software</span>
              <h5>Product by HamSoft (UG) Ltd</h5>
              <h5>Copy Right&#169; 2023. All right reserved </h5>
              <span style="font-size: 14px;">Plot 84A Kawala, Block C</span><br/>
              <span style="font-size: 14px;">P.O Box 12345, Kampala Uganda</span><br/>
              <span style="font-size: 14px;">inquiries@base.ug</span><br/>
              <span style="font-size: 14px;">+256 70000000</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col>
              <span style="font-weight: bold;">Solution</span>
              <ul>
                <li>Small</li>
                <li>Medium</li>
                <li>Large</li>
              </ul>
            </v-col>
            <v-col>
              <span style="font-weight: bold;">Features</span>
              <ul>
                <li>Chart of Accounts</li>
                <li>Transactions</li>
                <li>Balance Sheet</li>
                <li>Cash flow</li>
                <li>Profit and Loss</li>
              </ul>
            </v-col>
            <v-col>
              <span style="font-weight: bold;">Resources</span>
              <ul>
                <li>News letter</li>
                <li>Partners</li>
                <li>Team</li>
                <li>About Hamsoft</li>
                <li>Contact</li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="10" style="font-size: 14px;" class="text-center">
          <span class="text-decoration-underline footer-text">Terms and Conditions</span>
          <span class="mx-2">|</span>
          <span class="text-decoration-underline footer-text">Privacy Policy</span>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      logoImageStyle: {
        backgroundImage: "url(" + require("@/assets/logo_white.png") + ")",
      }
    };
  }
};
</script>

<style scoped>
.footer{
  background: #065FDB;
  padding-left: 5px;
  padding-right: 5px;
  border-top: solid 3px #065FDB;
  color: white;
}

.logo-icon{
  height:40px;
}

ul{
  list-style-type: none;
}

li{
  cursor: pointer;
  text-decoration: none;
}

li:hover{
  font-weight: bold;
}

.footer-text{
  cursor: pointer;
}

.footer-text:hover{
  color: #a7bfe3;
}
</style>