<template>
    <v-container fluid>
        <v-card :loading="cardLoader">
            <v-row class="mx-0">
                <v-col id="invoicePDFId" cols="12">



                    <v-row dense class="mx-0">
<span>
   <v-img
       style="border: 1px solid #838383;"
       width="40"
       :src="invoiceLogo"
       :lazy-src="invoiceLogo"
       aspect-ratio="1"
       class="grey lighten-2 mr-2 mb-1"
   >
                      </v-img>
</span>




                        <p class="mt-3">Invoice #{{ invoiceDataInfo.counter }}</p>

                        <v-spacer></v-spacer>
                        <span>
<!--              <v-btn
                      style="color: #ffffff;"
                      tile
                      color="#1976d2"
                      small
                      class="mr-1"
              >
                <v-icon left>
                  mdi-content-save
                </v-icon>
                Save
              </v-btn>

              <v-btn
                      style="color: #ffffff;"
                      tile
                      color="#1976d2"
                      small
                      class="mr-1"
                      @click="printInvoicePdf()"
              >
                 <v-icon left>
                mdi-printer
                 </v-icon>

                Print
              </v-btn>-->

               <v-btn
                       style="color: #ffffff;"
                       tile
                       color="#1976d2"
                       small
                       class="mr-1"
                       @click="downloadInvoicePdf()"
               >
                <i class="fa fa-file-pdf-o mr-1" style="font-size: 15px;" aria-hidden="true"></i>
                Download
              </v-btn>

              <v-btn
                      v-if="invoiceDataInfo.status !== 'Fully paid'"
                      @click="payInvoice()"
                      style="color: #ffffff;"
                      tile
                      color="#1976d2"
                      small
              >
                <v-icon left>
                  mdi-cash
                </v-icon>
                 Pay
              </v-btn>
            </span>
                    </v-row>

                    <v-divider></v-divider>

                    <v-row>
                        <v-col cols="4">
                            <v-card-subtitle>
                                <p>From:</p>
                                <b>Name:</b> {{ invoiceDataInfo.company ? invoiceDataInfo.company.company_name : "" }} <br/>
                                Legal name: {{ invoiceDataInfo.company ? invoiceDataInfo.company.legal_name : "" }} <br/>
                                Tin: {{ invoiceDataInfo.company ? invoiceDataInfo.company.company_tin : "" }} <br/>
                                Address:
                                {{
                                (invoiceDataInfo.company ? invoiceDataInfo.company.company_address : "")
                                }}
                                <br/>
                                Email:
                                <a v-if="invoiceDataInfo.company" :href="'mailto:' + invoiceDataInfo.company.company_email">
                                    {{ invoiceDataInfo.company.company_email }}</a
                                >
                                <br/>
                                Phone:
                                <a v-if="invoiceDataInfo.company" :href="'tel:' + invoiceDataInfo.company.company_phone">{{
                                        invoiceDataInfo.company.company_phone
                                    }}</a>
                                <br/>
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="4">
                            <v-card-subtitle>
                                <p>To:</p>
                                Name: <b>{{ invoiceDataInfo.customer.name }}</b
                            ><br/>
                                Address: {{ invoiceDataInfo.customer.address }}<br/>
                                Tin: {{ invoiceDataInfo.customer.tin }}<br/>
                                Email:
                                <a
                                        v-for="(contact, index) in invoiceDataInfo.customer
                    .profile_contacts"
                                        :href="'mailto:' + contact.contact"
                                        :key="index"
                                >
                  <span v-if="contact.type === 'email'">
                    {{ contact.contact }},
                  </span>
                                </a>
                                <br/>
                                Phone:
                                <a
                                        v-for="(contact, index) in invoiceDataInfo.customer
                    .profile_contacts"
                                        :href="'tel:' + contact.contact"
                                        :key="'A' + index"
                                >
                  <span v-if="contact.type === 'phone'">
                    {{ contact.contact }},
                  </span>
                                </a>
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="4">
                            <v-card-subtitle>
                                <p>Details:</p>
                                Invoice Number <b>#{{ invoiceDataInfo.counter }}</b> <br/>
                                Ref No: {{ invoiceDataInfo.ref_no }}<br/>
                                Invoice Date: {{ invoiceDataInfo.invoice_date }}<br/>
                                Due Date: {{ invoiceDataInfo.due_date }}<br/>
                                Status:
                                <v-chip class="ma-2" :color="statusColor" small outlined>
                                    {{ invoiceDataInfo.status }}<br/>
                                </v-chip>
                            </v-card-subtitle>
                        </v-col>
                    </v-row>


                  <v-row>
                    <p class="ml-5">Invoice Items</p>
                    <v-spacer></v-spacer>
                    <p class="mr-5">Amounts are tax exclusive</p>

                  </v-row>

                    <v-simple-table style="width: 100%;" dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left" width="5%">
                                    #
                                </th>
                                <th class="text-left">
                                    Name
                                </th>
                              <th class="text-left">
                                Discount (s)
                              </th>

                                <th class="text-left">
                                    Tax
                                </th>
                              <th class="text-left">
                                Tax Amount
                              </th>
                                <th class="text-left">
                                    Quantity
                                </th>
                                <th class="text-left">
                                    Unit Cost (UGX)
                                </th>

                                <th class="text-left">
                                    Total (UGX)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    v-for="(item, index) in invoiceDataInfo.bill_of_items"
                                    :key="index"
                            >
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.item_name ? item.item_name : "" }}</td>
                              <td>{{
                                  item.total_discount ?
                                      item.total_discount
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      : ""
                                }}
                              </td>
                                <td>
                                  <span v-for="(taxList, i) in item.taxes" :key="i">
                                    {{taxList.name + ": ("+(taxList.pivot.is_absolute ?  taxList.pivot.rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : taxList.pivot.rate+"%")+"), "}}
                                  </span>

<!--                                  <span v-for="(taxList, i) in item.tax" :key="i">
                                  {{ taxList.name ? taxList.name + " (" + ((!taxList.is_absolute) ? taxList.rate+"%" : taxList.rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))  + ")" : "" }}
                                      <br/>
                                  </span>-->
                                </td>
                              <td>
                                {{item.total_item_taxes ? item.total_item_taxes.toFixed(2).toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}}
                              </td>
                                <td>
                                    {{
                                        item.quantity ?
                                                item.quantity
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " (" + item.unit_name + ")" : ""
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.unit_price ?
                                                item.unit_price
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                                    }}
                                </td>

                                <td>
                                    {{
                                        item.total_amount ?
                                                item.total_amount
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="6"></td>
                                <td style="border-bottom: #e0e0e0 solid 1px;">
                                    <b>Cost of Items</b>
                                </td>
                                <td style="border-bottom: #e0e0e0 solid 1px;">
                                    <b>
                                        {{costOfItems}}
                                    </b>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <p class="ml-5 mt-5">Other Expenses</p>

                    <v-simple-table dense style="width: 100%;">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left" width="5%">
                                    #
                                </th>
                                <th class="text-left" width="20%">
                                    Date
                                </th>
                                <th class="text-left">
                                    Name
                                </th>
                                <th class="text-left" width="30%">
                                    Memo
                                </th>
                                <th class="text-left">
                                    Amount (UGX)
                                </th>

                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    v-for="(item, index) in invoiceDataInfo.invoice_expenses"
                                    :key="index"
                            >
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.created_at }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.notes }}</td>
                                <td>
                                    {{
                                        item.amount
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <td style="border-bottom: #e0e0e0 solid 1px;">
                                    <b>Additional Costs</b>

                                </td>
                                <td style="border-bottom: #e0e0e0 solid 1px;">
                                    <b>
                                        {{additionalCosts}}
                                    </b>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>


                    <v-row>
                        <v-col cols="8">
                            <v-card-subtitle>
                                {{ invoiceDataInfo.notes }}
                            </v-card-subtitle>

                            <v-card-subtitle>
                                {{ default_terms }}
                            </v-card-subtitle>


                        </v-col>
                        <v-col cols="4">
                            <v-card-subtitle>
                                <v-row>
                                    <p><b>Sub-total</b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>{{subTotal}}</b>
                                    </p>
                                </v-row>

                                <v-row>
                                    <p><b>Discount</b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>
                                            {{discount}}</b
                                        >
                                    </p>
                                </v-row>

                                <v-row>
                                    <p><b>Additional Costs</b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>
                                            {{additionalCosts}}</b
                                        >
                                    </p>
                                </v-row>

                                <v-row
                                        v-for="(discount, index) in invoiceDataInfo.discounts"
                                        :key="index"
                                >
                                    <p>
                                        <b>Discount ( {{ discount.rate }}% )</b>
                                    </p>

                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>
                                            -
                                            {{
                                                invoiceDataInfo.total_amount.totalDiscount
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            }}
                                        </b>
                                    </p>
                                </v-row>
                              <v-divider></v-divider>
                              <v-row v-if="invoiceDataInfo.all_taxes && invoiceDataInfo.all_taxes.length > 0">
                                <p>Taxes</p>
                              </v-row>
                          <v-divider></v-divider>
                                <v-row
                                    v-for="(taxes, index) in invoiceDataInfo.all_taxes"
                                    :key="index+'h'"
                                >
                                    <p>{{taxes.name}}</p>
                                    <v-spacer></v-spacer>
                                    <p>

                                            {{ transformNumbersToAccountingStandard(taxes.amount)}}

                                    </p>
                                </v-row>
                              <v-divider></v-divider>
                              <v-row>
                                    <p><b>Total Tax Collected </b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>
                                            {{taxCollected}}
                                        </b>
                                    </p>
                                </v-row>
                              <v-divider></v-divider>
                                <v-row>
                                    <p><b>Total</b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>{{
                                                invoiceDataInfo.total_amount.afterDiscount
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            }}</b>
                                    </p>
                                </v-row>
                                <v-row>
                                    <p><b>Paid amount</b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>
                                            {{paidAmount}}
                                        </b>
                                    </p>
                                </v-row>
                              <v-divider></v-divider>
                                <v-row class="red--text">
                                    <p><b>Due Amount</b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>{{
                                                invoiceDataInfo.total_amount.due
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            }}</b>
                                    </p>
                                </v-row>
                              <v-divider></v-divider>
                            </v-card-subtitle>
                        </v-col>
                    </v-row>


                    <v-row class="mx-auto">
                        {{ invoiceFooter }}
                        <v-spacer></v-spacer>
                        <v-btn
                                v-if="invoiceDataInfo.status !== 'Fully paid'"
                                style="color: #ffffff;"
                                tile
                                color="#45a164"
                                small
                                class="mr-1"
                                @click="payInvoice()"
                        >
                            <v-icon left>
                                mdi-cash
                            </v-icon>
                            Proceed to Payment
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-4">
            <v-row class="mx-0">
                <v-col cols="12">
                    <p>Payment Details</p>
                    <v-divider></v-divider>

                    <v-data-table
                            :headers="paymentHeaders"
                            :items="invoicePaymentDataInfo"
                            :search="search"
                            :loading="tablePaymentLoader"
                            loading-text="Loading... please wait"
                            item-key="name"
                            class="elevation-1"
                            :page="page"
                            :items-per-page="items_per_page"
                            :server-items-length="server_items_length"
                            :options.sync="paymentPagination"
                    >
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="editPayment(item)">
                                mdi-pencil
                            </v-icon>

                            <v-icon small @click="confirmDeletePayment(item.transaction_number)">
                                mdi-delete
                            </v-icon>

                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>

        <!--pay invoice dialog-->

        <v-dialog v-model="payInvoiceDialog" width="40%">
            <v-card>
                <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
                    <v-row>
                        <v-card-title>Pay Invoice</v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="payInvoiceDialog = false">
                            <v-icon color="red">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-row>
                    <pay-invoice-bill
                            ref="paymentInvoiceComponent"
                            :invoiceInfo="invoiceDataInfo"
                            receiptType="Invoice"
                    ></pay-invoice-bill>
                </div>
            </v-card>
        </v-dialog>

        <ConfirmDialog ref="deleteInvoicePaymentTransaction"></ConfirmDialog>

        <v-dialog v-model="editInvoicePaymentDialog" width="40%">
            <v-card>
                <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
                    <v-row>
                        <v-card-title>Edit Payment Details</v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="editInvoicePaymentDialog = false">
                            <v-icon color="red">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-row>
                    <pay-invoice-bill
                            ref="editPaymentComponents"
                            receiptType="Invoice"
                    ></pay-invoice-bill>
                </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PayInvoiceBill from "../commons/PayInvoiceBill";
import configs from "@/global/configs.json";
import ConfirmDialog from "@/modules/commons/ConfirmDialog";

export default {
    name: "InvoiceDetails",
    components: {PayInvoiceBill, ConfirmDialog},
    data() {
        return {
            invoiceDataInfo: {
                company: {},
                customer: {},
                total_amount: {
                    beforeDiscount: {},
                    afterDiscount: {},
                    due: {}
                }
            },
            invoiceLogo:null,
            subTotal:null,
            discount:null,
            additionalCosts:null,
            taxCollected:null,
            costOfItems:null,
            paidAmount:null,
            payInvoiceDialog: false,
            invoicePaymentDataInfo: [],
            search: null,
            tablePaymentLoader: false,
            items_per_page: 10,
            page: 1,
            server_items_length: 0,
            statusColor: null,
            paymentHeaders: [
                {
                    width: "5%",
                    text: "#",
                    align: "start",
                    value: "key"
                },
                {text: "Invoice Number", value: "invoice_number"},
                {text: "Amount", value: "amount"},
                {text: "Notes", value: "notes"},
                {text: "Payment Method", value: "payment_method_name"},
                {text: "Bank/Cash Account", value: "bank" },
                {text: "Payment Date", value: "payment_date"},
                {text: "Action", value: "actions"}
            ],
            paymentPagination: {},
            editInvoicePaymentDialog: false,
            cardLoader: false,
            invoiceFooter: null,
            default_terms: null,
            downloadLink:
                configs.SERVER_IP + "out-pdf/invoice?invoice-no=",
        };
    },
    mounted() {
        this.cardLoader = true;
        this.doGetInvoiceInfo(this.$route.query.id);
    },
    methods: {
        ...mapActions([
            "doGetInvoiceInfo",
            "doGetInvoicePayment",
            "getInvoiceBillBalance",
            "deleteInvoicePayment",
            "fetchInvoicePaymentDetails"
        ]),
        printInvoicePdf() {
            let invoicePdf = window.open(
                "",
                "PRINT",
                "height=650,width=900,top=100,left=150"
            );

            invoicePdf.document.write(`<html><head><title>Invoice</title>`);
            invoicePdf.document.write("</head><body >");
            invoicePdf.document.write(
                document.getElementById("invoicePDFId").innerHTML
            );
            invoicePdf.document.write("</body></html>");

            invoicePdf.document.close(); // necessary for IE >= 10
            invoicePdf.focus(); // necessary for IE >= 10*/

            invoicePdf.print();
            invoicePdf.close();

            return true;
        },
        downloadInvoicePdf() {
            this.downloadSpinner = false;

            let fileUrl = this.downloadLink + this.invoiceDataInfo.number;

            const pdfObj = document.createElement("a");
            pdfObj.href = fileUrl;
            pdfObj.click();
        },
        payInvoice() {
          if(this.invoiceDataInfo) {
            this.payInvoiceDialog = true;
            this.getInvoiceBillBalance(this.invoiceDataInfo.number);
          }
        },
        editPayment(item) {
            if (item) {
                this.editInvoicePaymentDialog = true
                this.fetchInvoicePaymentDetails(item.number)
            }
        },
        filterPaymentListData(raw_data) {
            let paymentDataList = raw_data.data;
            this.server_items_length = raw_data.total;
            this.tablePaymentLoader = false;

            let current_page = raw_data.current_page;
            this.page = current_page;
            let per_page = raw_data.per_page;
            this.items_per_page = per_page * 1;

            let paymentJsn = {};
            let paymentObj = [];


          paymentDataList.forEach((lst,i) => {
            paymentJsn = {
              key: (current_page - 1) * per_page + i + 1,
              invoice_number: lst.invoice_counter,
              amount: lst.amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              notes: lst.notes,
              payment_method_name: lst.payment_method_name,
              payment_date: lst.payment_date,
              number: lst.number,
              transaction_number: lst.transaction_number,
              bank: lst.bank ? lst.bank : null
            };
            paymentObj.push(paymentJsn);
          })


            this.invoicePaymentDataInfo = paymentObj;
        },
        async confirmDeletePayment(transaction_number) {

            let title = `Are you sure you want to delete this payment ?  Doing this is not reversible`

            const agreed = await this.$refs.deleteInvoicePaymentTransaction.confirm(
                title,
                {
                    color: "red",
                    width: "480",
                }
            );

            if (!agreed) {
                return
            }
            await this.deleteInvoicePayment({transaction_number});

        },
        fetchPaymentDetailsFilter() {
            let options = {
                invoice_number: this.$route.query.id,
                page: this.page,
                per_page: this.items_per_page
            };
            this.doGetInvoicePayment(options);
        },
    },
    computed: {
        ...mapGetters([
            "getInvoiceDataInfo",
            "getInvoicePaymentData",
            "getInvoicePaymentDataInfo",
            "getInvoicePaymentDetailsEditDetails",
            "getInvoicePaymentDelete",
            "getInvoicePaymentDeleteError"
        ])
    },
    watch: {
        getInvoiceDataInfo(invoiceData) {
          if(invoiceData){
            this.invoiceDataInfo = invoiceData;

           this.invoiceLogo = invoiceData.invoice_setting ? invoiceData.invoice_setting.image_path : null

            this.subTotal = invoiceData.total_amount ? invoiceData.total_amount.taxFree
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

            this.discount = invoiceData.total_amount ? invoiceData.total_amount.totalDiscount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

            this.additionalCosts =  invoiceData.total_amount ? invoiceData.total_amount.totalExpenses
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

            this.taxCollected =  invoiceData.total_amount ? invoiceData.total_amount.taxCollected
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

            this.paidAmount =  invoiceData.total_amount ? invoiceData.total_amount.paidAmount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

            this.costOfItems =  invoiceData.total_amount ? invoiceData.total_amount.itemsAmount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

            //changes color status
            this.cardLoader = false;
            this.statusColor = this.getStatusColor(invoiceData.status);
            this.invoiceFooter = invoiceData.invoice_setting.footer
            this.default_terms = invoiceData.invoice_setting.default_terms
          }

        },
        getInvoicePaymentData(invoiceData) {
            if (invoiceData.message) {
                this.$refs.paymentInvoiceComponent.form = {
                    invoice_number: this.invoiceDataInfo.number,
                    amount: null,
                    payment_method_number: null,
                    storage_account_number: null,
                    payment_date: new Date().toISOString().substr(0, 10),
                    notes: null
                };
                this.$refs.paymentInvoiceComponent.invoiceLoader = false;
                this.payInvoiceDialog = false;
                this.successToast("Success", invoiceData.message);

                //do fetch invoice details
                this.doGetInvoiceInfo(this.$route.query.id);

                //fetch invoice payments
                this.fetchPaymentDetailsFilter()

                //  we need to populate the balance on the invoice
                this.getInvoiceBillBalance(this.invoiceDataInfo.number);
            }
        },
        getInvoicePaymentDataInfo(paymentData) {
            this.filterPaymentListData(paymentData);
        },
        paymentPagination(obj) {
            this.tablePaymentLoader = true;

            let options = {
                invoice_number: this.$route.query.id,
                page: obj.page,
                per_page: obj.itemsPerPage
            };
            this.doGetInvoicePayment(options);
        },
        getInvoicePaymentDetailsEditDetails(data) {
            if (data) {
                this.successToast("Success", data.message)
                this.$refs.editPaymentComponents.invoiceLoader = false
                this.editInvoicePaymentDialog = false
                this.doGetInvoiceInfo(this.$route.query.id);
                this.fetchPaymentDetailsFilter()
            }
        },
        getInvoicePaymentDelete(data) {
            this.successToast("Success", data.message)
            this.doGetInvoiceInfo(this.$route.query.id);
            this.fetchPaymentDetailsFilter()

        },
        getInvoicePaymentDeleteError(error){
            this.errorToast("Error", error.message)
        }
    }
};
</script>

<style scoped></style>
