import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  supplierData: null,
  supplierErrorResponse: null
};

const getters = {
  getSupplierData: state => state.supplierData,
  getSupplierErrorResponse: state => state.supplierErrorResponse
};

const actions = {
  async doAddSupplier({ commit }, data) {
    await axios
      .post(
        data.number
          ? IP + "supplier/update/" + data.number
          : IP + "supplier/new-supplier",
        data
      )
      .then(response => {
        commit("setNewSupplierData", response.data);
      })
      .catch(error => {
        commit("setSupplierErrorMessage", error);
      });
  }
};

const mutations = {
  setNewSupplierData: (state, response) => {
    state.supplierData = response;
  },
  setSupplierErrorMessage: (state, error) => {
    state.supplierErrorResponse = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
