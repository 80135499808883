<template>
  <span>
    <v-row>
      <v-col>
<!--        <v-card>-->
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Discount </v-toolbar-title>
          </v-toolbar>

<!--          <v-tabs v-model="activeTab">-->
          <v-tabs>
            <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
            <v-tab href="#discounts">
              <v-icon left>mdi-account</v-icon>
              Discount
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-discounts">
              <v-icon left>mdi-lock</v-icon>
              New Discount
            </v-tab>

            <v-tab-item id="discounts">
              <v-divider></v-divider>
              <v-card flat>
                <v-row class="mx-auto mt-8">
                  <v-col cols="8">
<!--                    <download-excel
                      style="color:#777777; width: 30%; margin-left: 2%; cursor: pointer;"
                      class="btn btn-default mt-3"
                      :data="discountTableData"
                      :fields="json_fields"
                      worksheet="Expenses"
                      name="stock.xls"
                      >Download Excel
                      <v-icon color="#777777">
                        mdi-cloud-download
                      </v-icon>
                    </download-excel>-->
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      class="mr-3"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      dense
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-data-table
                  :headers="headers"
                  :items="discountTableData"
                  :search="search"
                  :loading="tableDiscountLoader"
                  loading-text="Loading... please wait"
                  :page="page"
                  :items-per-page="items_per_page"
                  :server-items-length="server_items_length"
                  :options.sync="paginationDiscounts"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editDiscount(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteDiscount(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item id="new-discounts">
              <v-divider></v-divider>

              <v-card flat>
                <v-row>
                  <v-col cols="5" class="mt-15 ml-6">
                    <add-discount ref="discount_components"></add-discount>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
<!--        </v-card>-->
      </v-col>
    </v-row>

    <v-dialog v-model="editDiscountDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Discount</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editDiscountDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-discount
            ref="discount_components"
            :editDiscountObj="editDiscountObj"
          ></add-discount>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddDiscount from "../commons/AddDiscount";
import router from "@/global/router";
export default {
  name: "Discount",
  components: { AddDiscount },
  data() {
    return {
      activeTab: "discounts",
      discountTableData: [],
      search: "",
      tableDiscountLoader: false,
      paginationDiscounts: {},
      page: 1,
      items_per_page: 10,
      server_items_length: 0,
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Name", value: "name" },
        { text: "Rate ( % )", value: "rate" },
        { text: "Action", value: "actions", sortable: false, width: "10%" }
      ],
      json_fields: {
        Name: "name",
        Rate: "rate"
      },
      editDiscountDialog: false,
      editDiscountObj: {}
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  methods: {
    ...mapActions(["doGetDiscountList", "doDeleteDiscount"]),

    editDiscount(item) {
      this.editDiscountObj = item;
      this.editDiscountDialog = true;
    },
    deleteDiscount(item) {
      let confirm_delete = confirm(
        "Are you sure, you want to delete this discount!!"
      );
      if (!confirm_delete) {
        return false;
      }
      this.doDeleteDiscount(item.number);
    },
    filterDiscountList(raw_data) {
      let discountData = raw_data.data;
      this.server_items_length = raw_data.total;
      this.tableDiscountLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let discountJsn = {};
      let discountObj = [];


      discountData.forEach((list, i) => {
        discountJsn = {
          key: i + 1,
          name: list.name,
          rate: list.rate,
          number:list.number
        };
        discountObj.push(discountJsn);
      })


      this.discountTableData = discountObj;
    }
  },
  computed: {
    ...mapGetters(["getDiscountData", "getDiscount", "getDeleteDiscount"])
  },
  watch: {
    getDiscountData(discountData) {
      if (discountData) {
        this.successToast("Success", discountData.message);
        this.$refs["discount_components"].addDiscountLoader = false;
        this.$refs["discount_components"].form = {
          name: null,
          rate: null
        };
        this.editDiscountDialog = false;
        let options = {
          page: this.page,
          per_page: this.items_per_page
        };
        this.doGetDiscountList(options);
      }
    },
    getDiscount(discountData) {
      this.filterDiscountList(discountData);
    },
    paginationDiscounts(obj) {
      this.tableDiscountLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage
      };
      this.doGetDiscountList(options);
    },
    getDeleteDiscount(deleteMsg) {
      if (deleteMsg) {
        this.successToast("Success", deleteMsg.message);
        let options = {
          page: this.page,
          per_page: this.items_per_page
        };
        this.doGetDiscountList(options);
      }
    },
    activeTab(data) {
      router.push({ name: "discounts", query: { type: data } });
    }
  }
};
</script>

<style scoped></style>
