<template>
<span>
    <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>

    <form ref="form" @submit.prevent="saveFixedAssetTransaction">
      <v-row>
        <v-col cols="6">
          <v-text-field
              v-model="form.name"
              label="Name"
              outlined
              dense
              required
          ></v-text-field>

          <v-text-field
              class="mb-2"
              v-model="form.amount"
              label="Amount *"
              outlined
              dense
              required
              hide-details
              @keypress="onlyNumberValidator($event)"
              v-on:blur="compute(form.amount)"
          ></v-text-field>

          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="form.date"
                  label="Date [ dd / mm / yyy ]"
                  outlined
                  dense
                  type="date"
                  required
                  hide-details
                  class="mb-3"
              ></v-text-field>
               <small style="color: red;" v-if="yearLengthValidator(form.date)">
                Invalid Date
              </small>
            </v-col>
            <v-col cols="6">
               <v-text-field
                   v-model="form.dateAcquired"
                   label="Date Aquired [ dd / mm / yyy ]"
                   outlined
                   dense
                   type="date"
                   required
                   hide-details
                   class="mb-3"
               ></v-text-field>
               <small style="color: red;" v-if="yearLengthValidator(form.dateAcquired)">
                Invalid Date
              </small>
            </v-col>
          </v-row>




          <v-textarea
              v-model="form.description"
              label="Description"
              outlined
              rows="3"
              dense
          ></v-textarea>


        </v-col>

        <v-col cols="6">
          <v-autocomplete
              class="mb-2"
              outlined
              v-model="form.account_number"
              :items="accountDataItem"
              item-value="id"
              color="pink"
              label="Category *"
              append-outer-icon="mdi-plus"
              @click:append-outer="accountModel"
              dense
              hide-details
          ></v-autocomplete>


           <small style="color: red;" v-if="checkMessage">{{checkMessage}}</small>
           <v-row class="mx-auto" style="padding: 0;">
              <v-checkbox
                  style="padding: 0;"
                  v-model="received_cash"
                  class="shrink mt-2 mb-5 mr-3 float-left p-0"
                  label="Paid Cash"
                  id="sub_account_id"
                  @change="resetCashFields('cash')"
                  hide-details
              ></v-checkbox>

               <v-checkbox
                   style="padding: 0;"
                   v-model="asLiability"
                   class="shrink mt-2 mb-5 mr-3 float-left p-0"
                   label="As a Liability"
                   id="sub_account_id"
                   @change="resetCashFields('liability')"
                   hide-details
               ></v-checkbox>

               <v-checkbox
                   style="padding: 0;"
                   v-model="asEquity"
                   class="shrink mt-2 mb-5 mr-3 float-left p-0"
                   label="As Equity"
                   id="sub_account_id"
                   @change="resetCashFields('equity')"
                   hide-details
               ></v-checkbox>
            </v-row>



          <v-row v-if="received_cash">
            <v-col cols="6">
                <v-autocomplete
                    outlined
                    v-model="form.storage_account_number"
                    :items="storageAccountDataList"
                    item-value="id"
                    color="pink"
                    label="Bank/Cash Account *"
                    append-outer-icon="mdi-plus"
                    @click:append-outer="storageAccountModel"
                    dense
                    clearable
                    hide-details
                ></v-autocomplete>
            </v-col>
            <v-col cols="6">
               <v-autocomplete
                   outlined
                   v-model="form.payment_method_number"
                   :items="paymentMethodDataList"
                   item-value="id"
                   color="pink"
                   label="Payment Method"
                   append-outer-icon="mdi-plus"
                   @click:append-outer="paymentMethodModel"
                   dense
                   clearable
                   hide-details
               ></v-autocomplete>
            </v-col>
          </v-row>

         <v-autocomplete
             v-if="asEquity"
             outlined
             v-model="form.equity_account_number"
             :items="equityAccountDataSet"
             item-value="id"
             color="pink"
             label="Equity Account *"
             append-outer-icon="mdi-plus"
             @click:append-outer="addAccountModel('EQT')"
             dense
         ></v-autocomplete>

           <v-autocomplete
               v-if="asLiability"
               outlined
               v-model="form.liability_account_number"
               :items="currentLiabilityAccountDataList"
               item-value="id"
               color="pink"
               :label="(account_type ==='CAST') ? 'Current Liability Account *' : 'Long Term Liability Account *'"
               append-outer-icon="mdi-plus"
               @click:append-outer="addAccountModel((account_type ==='CAST') ? 'CLBT' : 'LTLB')"
               dense
           ></v-autocomplete>




          <v-row>
            <v-col cols="6">
                <v-autocomplete
                    outlined
                    v-model="form.sale_user_number"
                    :items="staffDataObj"
                    item-value="id"
                    color="pink"
                    :label="staffLabel"
                    append-outer-icon="mdi-plus"
                    @click:append-outer="addNewStaffProfileData"
                    dense
                    clearable
                    hide-details
                ></v-autocomplete>
            </v-col>
            <v-col cols="6">
               <v-autocomplete
                   outlined
                   clearable
                   v-model="form.profile_number"
                   :items="supplierDataList"
                   item-value="id"
                   color="pink"
                   :label="profileLabel"
                   append-outer-icon="mdi-plus"
                   @click:append-outer="addSupplierModel"
                   dense
                   hide-details
               ></v-autocomplete>
            </v-col>
          </v-row>







          <v-text-field
              v-model="form.reference"
              label="Reference"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="text-right">
        <v-btn
            class="companyButton"
            :loading="transactionLoader"
            color="#0000ff"
            dark
            type="submit"
        >
          {{ form.number ? "Edit" : "save" }}
        </v-btn>
      </div>
    </form>

  <!--    adds Storage accounts -->

    <v-dialog v-model="storage_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="storage_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <storage-account ref="storageAccountComponent"></storage-account>
        </div>
      </v-card>
    </v-dialog>

  <!--                    payment methods-->

    <v-dialog v-model="payment_methods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Payment Methods</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payment_methods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <payment-methods ref="paymentMethodsComponents"></payment-methods>
        </div>
      </v-card>
    </v-dialog>

  <!--    adding account dialog-->

    <v-dialog v-model="account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              :account_type="account_type"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

  <!--supplier dialog-->
    <v-dialog v-model="addSupplierDialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Profile</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addSupplierDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
           <new-profile  ref="add_customer_info"></new-profile>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="new_staff_dialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Staff</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="new_staff_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-user ref="users_components"></new-user>
        </div>
      </v-card>
    </v-dialog>

  <!--                    end dialog-->

  <!--    dialog to add current assets-->
    <v-dialog v-model="account_transaction_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="account_transaction_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
              :account_type="accountType"
              ref="chart_of_account_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>

  </span>
</template>

<script>
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";
import NewUser from "@/modules/dashboard/Settings/Users/NewUser";
import StorageAccount from "@/modules/dashboard/accounting/commons/StorageAccount";
import PaymentMethods from "@/modules/dashboard/accounting/commons/PaymentMethods";
import AddAccount from "@/modules/dashboard/accounting/commons/AddAccount";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewFixedAsset",
  components: {NewProfile, NewUser, StorageAccount, PaymentMethods, AddAccount},
  props: {
    account_type: String,
    transactionEditData: {}
  },
  data() {
    return {
      checkMessage: "",
      errorMessage: "",
      storage_account_dialog: false,
      payment_methods_dialog: false,
      new_staff_dialog: false,
      transactionLoader: false,
      accountDataItem: [],
      storageAccountDataList: [],
      paymentMethodDataList: [],
      addStorageLoader: false,
      addPaymentMethodLoader: false,
      account_dialog: false,
      supplierDataObj: [],
      staffLabel: null,
      profileLabel: null,
      received_cash:false,
      asLiability:false,
      asEquity:false,
      equityAccountDataSet:[],
      currentLiabilityAccountDataList:[],
      form: {
        name: null,
        number: null,
        amount: null,
        dateAcquired: null,
        description: null,
        date: new Date().toISOString().substr(0, 10),
        storage_account_number: null,
        equity_account_number:null,
        liability_account_number:null,
        account_number: null,
        payment_method_number: null,
        profile_number: null,
        reference: null,
        sale_user_number: null
      },
      payment_methods_form: {
        name: null,
        notes: null
      },
      payment_method_items: [
        {text: "Select Payment Method", value: null},
        {text: "Cash", value: "cash"},
        {text: "Mobile Money", value: "mobile_money"},
        {text: "Cheque", value: "cheque"},
        {text: "Credit Cards", value: "credit_cards"},
        {text: "Electronic bank transfer", value: "electronic_bank_transfer"}
      ],
      addSupplierDialog: false,
      supplierDataList: [],
      accountType: null,
      staffDataObj: [],
      account_transaction_dialog: false,
    };
  },
  mounted() {

    this.doGetAccountData(this.account_type);
    this.doGetStorageAccountData();
    this.doGetPaymentMethodData();
    this.fetchAccountData("EQT");
    this.fetchAccountData( (this.account_type ==='CAST') ? 'CLBT' : 'LTLB' );




    this.staffLabel = this.account_type === "INC" ? "Received by" : this.account_type === "EXP" ? "Spent by" : this.account_type === "CLBT" ? "Procured by" : ""
    this.profileLabel = this.account_type === "INC" ? "Received From" : this.account_type === "EXP" ? "Taken by" : this.account_type === "CLBT" ? "Procured by" : ""

    this.fetchProfiles();
    this.fetchStaffProfiles();
  },
  methods: {
    ...mapActions([
      "doGetAccountData",
      "doGetStorageAccountData",
      "doSaveFixedTransaction",
      "saveNewPaymentMethod",
      "doGetPaymentMethodData",
      "fetchProfiles",
      "fetchStaffProfiles",
      "doEditFixedTransaction",
       "doFetchAccountData"
    ]),
    fetchAccountData(accountType) {
      this.doFetchAccountData(accountType);
    },
    saveFixedAssetTransaction() {

      if(!this.received_cash && ! this.asLiability && !this.asEquity){
        this.checkMessage = "Please check if you are getting this item on cash or as a liability or equity!"
        return;
      }

      if(this.received_cash && (!this.form.storage_account_number || !this.form.payment_method_number)){
        this.errorMessage = "Bank/Cash account and Payment method is required!"
        return
      }


      this.checkMessage = null;
      this.errorMessage = null;
      this.transactionLoader = true;
      let formData = this.form;
      formData.amount = this.form.amount.toString().replace(/,/g, "")
      formData.ref_code = this.account_type;
      if(this.form.number){
        this.doEditFixedTransaction(formData);
      }else {
        this.doSaveFixedTransaction(formData);
      }

    },
    storageAccountModel() {
      this.storage_account_dialog = true;
    },
    paymentMethodModel() {
      this.payment_methods_dialog = true;
    },
    addNewStaffProfileData() {
      this.new_staff_dialog = true;
    },
    accountModel() {
      this.account_dialog = true;
    },
    compute(value) {
      value = value.toString().replace(/,/g, "")
      if (!isNaN(value)) {
        value = value - 0;
      }
      this.form.amount = eval(value) >= 0 ? eval(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
    },
    addSupplierModel() {
      this.addSupplierDialog = true;
    },
    addAccountModel(data) {
      this.accountType = data
      this.account_transaction_dialog = true;
    },
    filterProfilesList(profileList) {
      let dataObj = [{text: "Select profile", id: null, disabled: true}];
      let dataJson = {};
      profileList.forEach(list => {
        dataJson = {
          text: list.name,
          id: list.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })
      this.supplierDataList = dataObj;
    },
    resetCashFields(value) {
      if(value){

       if(value === 'cash'){
         this.asLiability = false
         this.asEquity = false
       }

       if(value === 'liability'){
         this.received_cash = false
         this.asEquity = false
       }

       if(value === 'equity'){
         this.received_cash = false
         this.asLiability = false
       }

        this.form.liability_account_number = null
        this.form.equity_account_number = null
        this.form.storage_account_number = null
        this.form.payment_method_number = null
      }
    },

  },
  computed: {
    ...mapGetters([
      "getAccountDataSet",
      "getStorageAccountDataSet",
      "getNewStorageAccountData",
      "getNewPaymentMethods",
      "getPaymentMethodDataSet",
      "getChartOfAccountsData",
      "getTransactionErrorMessage",
      "getAssetsDataInfo",
      "getProfileDataList",
      "getProfileResponseData",
      "getStaffProfileDataList",
      "getFixedTransactionErrorMessage",
      "getEquityAccountDataSet",
      "getCurrentLiabilityAccountDataSet",
      "getLongTermLiabilityAccountDataSet"
    ])
  },
  watch: {
    getCurrentLiabilityAccountDataSet(currentLiabilityAccountData) {
      this.currentLiabilityAccountDataList = this.nameNumberFilter(currentLiabilityAccountData);
    },
    getLongTermLiabilityAccountDataSet(currentLiabilityAccountData) {
      this.currentLiabilityAccountDataList = this.nameNumberFilter(currentLiabilityAccountData);
    },
    getEquityAccountDataSet(accountData) {
      this.equityAccountDataSet = this.accountFilter(accountData);
    },
    getProfileDataList(profileDataList) {
      this.filterProfilesList(profileDataList);
    },
    getStaffProfileDataList(staffDataList) {
      this.staffDataObj = this.filterStaffProfilesList(staffDataList);
    },
    getAccountDataSet(accountData) {
      this.accountDataItem = this.accountFilter(accountData);
    },
    getStorageAccountDataSet(storageAccountData) {
      this.storageAccountDataList = this.nameNumberFilter(storageAccountData);
    },
    getPaymentMethodDataSet(paymentData) {
      this.paymentMethodDataList = this.nameNumberFilter(paymentData);
    },
    getNewStorageAccountData(storageAccountData) {
      if (storageAccountData) {
        this.$refs["storageAccountComponent"].form = {};
        this.$refs["storageAccountComponent"].addStorageLoader = false;
        this.storage_account_dialog = false;
        this.form.storage_account_number = storageAccountData.data.number;

        this.successToast("Success", storageAccountData.message);
        this.doGetStorageAccountData(this.$company.number);
      }
    },
    getNewPaymentMethods(paymentData) {
      if (paymentData) {
        this.$refs["paymentMethodsComponents"].form = {
          name: null,
          notes: null
        };
        this.$refs["paymentMethodsComponents"].addPaymentMethodLoader = false;
        this.payment_methods_dialog = false;
        this.form.payment_method_number = paymentData.data.number;
        this.successToast("Success", paymentData.message);
        this.doGetPaymentMethodData();
      }
    },
    getTransactionErrorMessage(transactionError) {
      if (transactionError) {
        this.transactionLoader = false;
        this.errorMessage = transactionError.message;
        this.$refs.paymentMethodsComponents.addPaymentMethodLoader = false
      }
    },
    transactionEditData() {
      this.getTransactionInfo();
    },
    getAssetsDataInfo(transactionData) {
      if (transactionData) {
        this.form.name = transactionData.name ? transactionData.name : "";
        this.form.number = transactionData.number ? transactionData.number : "";
        this.form.amount = transactionData.amount ?
            transactionData.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
        this.form.description = transactionData.description ? transactionData.description : "";
        this.form.dateAcquired = transactionData.dateAcquired ? transactionData.dateAcquired : "";
        this.form.date = transactionData.transaction ? transactionData.transaction.date : "";
        this.form.account_number = transactionData ? transactionData.account_number : ""
        this.form.storage_account_number = transactionData.transaction ? transactionData.transaction.storage_account_number : "";
        this.form.payment_method_number = transactionData.transaction ? transactionData.transaction.payment_method_number : "";
        this.form.reference = transactionData.transaction ? transactionData.transaction.reference : "";
        this.form.profile_number = transactionData.transaction ? transactionData.transaction.profile_number : "";
        this.form.sale_user_number = transactionData.transaction ? transactionData.transaction.sale_user_number : "";
        if(this.form.storage_account_number && this.form.payment_method_number){
          this.received_cash = true
        }
        if(transactionData.liability_account_number){
          this.asLiability = true
          this.form.liability_account_number = transactionData.liability_account_number ? transactionData.liability_account_number : null
        }
        if(transactionData.equity_account_number){
          this.asEquity = true
          this.form.equity_account_number = transactionData.equity_account_number ? transactionData.equity_account_number : null
        }

      }
    },
    getProfileResponseData(supplierData) {
      if (supplierData) {
        this.$refs["add_customer_info"].customerSpinner = false;
        this.$refs["add_customer_info"].form = {
          name: null,
          trading_name: null,
          address: null,
          number: null,
          tin: null,
          phoneContact: [
            {
              contact: null,
              id: null,
              type: "phone"
            }
          ],
          emailContact: [
            {
              contact: null,
              id: null,
              type: "email"
            }
          ],
          contactPersons: [
            {
              name: null,
              phone_contacts: [
                {
                  phone: null
                }
              ],
              email_contacts: [
                {
                  email: null
                }
              ],
            }
          ]
        };
        this.addSupplierDialog = false;
        this.successToast("Success", supplierData.message);
        this.form.profile_number = supplierData.data.number;
        this.fetchProfiles();


      }
    },
    getFixedTransactionErrorMessage(data){
      if(data){
        this.errorToast("Error", data.message)
        this.errorMessage = data.message
        this.transactionLoader = false
      }
    },
    getChartOfAccountsData(chartData) {
      if (chartData) {
        this.$refs["chart_of_account_components"].chartSpinner = false;
        this.$refs["chart_of_account_components"].form = {
          name: null,
          code: null,
          description: null,
          sub_account: null,
          number: null
        };

        this.account_dialog = false;
        this.account_transaction_dialog = false;
        this.successToast("Success", chartData.message);

        // alert(chartData.data.ref_code)
      /*  if (chartData.data.ref_code === "CAST") {
          this.form.current_assets_account_number = chartData.data.number;
        } else if (chartData.data.ref_code === "INC") {
          this.form.income_account_number = chartData.data.number;
        } else if (chartData.data.ref_code === "COS") {
          this.form.cogs_account_number = chartData.data.number;
        }else */if (chartData.data.ref_code === "CLBT" || chartData.data.ref_code === 'LTLB') {
          this.form.liability_account_number = chartData.data.number;
        }else if (chartData.data.ref_code === "EQT") {
          this.form.equity_account_number = chartData.data.number;
        }else {
          this.form.account_number = chartData.data.number;
        }

        // this.fetchAccountData("CAST");
        // this.fetchAccountData("INC");
        // this.fetchAccountData("COS");
        this.fetchAccountData((this.account_type ==='CAST') ? 'CLBT' : 'LTLB' );
        this.doGetAccountData(this.account_type);
        this.fetchAccountData("EQT");
      }
    },
  }
}
</script>

<style scoped>

</style>