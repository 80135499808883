<template>
<span>

</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FixedAssetsDetails",
  data(){
    return{

    }
  },
  mounted() {
    this.fetchFixedAssetInfo(this.$route.query.id);
  },
  methods:{
    ...mapActions(
        [
            "fetchFixedAssetInfo"
        ]
    )
  },
  computed:{
    ...mapGetters(
        [
            "getAssetsDataInfo"
        ]
    )
  },
  watch:{
    getAssetsDataInfo(data){
      if(data){
        //alert(JSON.stringify(data))
      }
    }
  }
}
</script>

<style scoped>

</style>