import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  itemData: null,
  newServiceItemData: null,
  inventoryPartItem: null,
  inventoryAssemblyItem: null,
  itemDataErrorResponse: null,
  itemTypeData: null,
  subItemData: null,
  taxItemDataList: null,
  stockItemsDataSet: null,
  itemsSetupDataSet: null,
  updateItemData: null,
  inventoryPartItemError: null,
  updateErrorMessage: null,
  inventoryAssemblyItemError: null
};

const getters = {
  getItemData: state => state.itemData,
  getItemTypeData: state => state.itemTypeData,
  getSubItemData: state => state.subItemData,
  getItemDataErrorResponse: state => state.itemDataErrorResponse,
  getTaxItemDataList: state => state.taxItemDataList,
  getNewServiceItemData: state => state.newServiceItemData,
  getInventoryPartItem: state => state.inventoryPartItem,
  getStockItemsDataSet: state => state.stockItemsDataSet,
  getInventoryAssemblyItem: state => state.inventoryAssemblyItem,
  getInventoryPartItemError: state => state.inventoryPartItemError,
  getUpdateItemData: state => state.updateItemData,
  getInventoryAssemblyItemError: state => state.inventoryAssemblyItemError,
  getItemsSetupDataSet: state => state.itemsSetupDataSet,
  getUpdateErrorMessage: state => state.updateErrorMessage
};

const actions = {
  async saveItemData({ commit }, data) {
    await axios
      .post(IP + data.endPoint, data.data)
      .then(response => {
        commit("setNewItemData", response.data);
      })
      .catch(error => {
        commit("setItemDataErrorMessage", error);
      });
  },
  async saveServiceItem({ commit }, data) {
    await axios
      .post(IP + "items/new-item", data)
      .then(response => {
        commit("setNewServiceItemData", response.data);
      })
      .catch(error => {
        commit("setItemDataErrorMessage", error);
      });
  },
  async editServiceItem({ commit }, data) {
    if(data.bill_of_items) {
      data.bill_of_items.forEach((list, i) => {
        data.bill_of_items[i].quantity = list.quantity ? (list.quantity.toString().replace(/,/g, "") * 1) : list.quantity;
        data.bill_of_items[i].cost = list.cost ? (list.cost.toString().replace(/,/g, "") * 1) : list.cost;
      })
    }
    await axios
      .post(IP + "items/update", data)
      .then(response => {
        commit("setUpdateItemData", response.data);
      })
      .catch(error => {
        commit("setUpdateErrorMessage", error);
      });
  },
  async saveInventoryPartItem({ commit }, data) {
    await axios
      .post(IP + "items/new-item", data)
      .then(response => {
        commit("setInventoryPartItem", response.data);
      })
      .catch(error => {
        commit("setInventoryPartItemError", error);
      });
  },
  async saveInventoryAssemblyItem({ commit }, data) {
    if(data.bill_of_items) {
      data.bill_of_items.forEach((list, i) => {
        data.bill_of_items[i].quantity = list.quantity ? (list.quantity.toString().replace(/,/g, "") * 1) : list.quantity;
        data.bill_of_items[i].cost = list.cost ? (list.cost.toString().replace(/,/g, "") * 1) : list.cost;
      })
    }
    await axios
      .post(IP + "items/new-item", data)
      .then(response => {
        commit("setInventoryAssemblyItem", response.data);
      })
      .catch(error => {
        commit("setInventoryAssemblyItemError", error);
      });
  },
  async doGetItemType({ commit }) {
    await axios
      .get(IP + "items/types")
      .then(response => {
        commit("setItemTypeData", response);
      })
      .catch(error => {
        commit("setItemDataErrorMessage", error);
      });
  },
  async doGetSubItemData({ commit }, data) {
    await axios
      .get(IP + "items/sub-items/" + data)
      .then(response => {
        commit("setSubItemData", response);
      })
      .catch(error => {
        commit("setItemDataErrorMessage", error);
      });
  },
  async fetchStockItems({ commit }) {
    await axios
      .get(IP + "items/stocked")
      .then(response => {
        commit("setStockItemsDataSet", response);
      })
      .catch(error => {
        commit("setItemDataErrorMessage", error);
      });
  },
  async fetchItemsSetup({ commit }) {
    await axios
      .get(IP + "items/for-assembly")
      .then(response => {
        commit("setItemsSetupDataSet", response);
      })
      .catch(error => {
        commit("setItemDataErrorMessage", error);
      });
  },
  async getTaxItemAccountData({ commit }) {
    await axios
      .get(IP + "company/tax-items")
      .then(response => {
        commit("setTaxItemsDataList", response);
      })
      .catch(error => {
        commit("setStockErrorMessage", error);
      });
  }
};

const mutations = {
  setNewItemData: (state, response) => {
    state.itemData = response;
  },
  setNewServiceItemData: (state, response) => {
    state.newServiceItemData = response;
  },
  setUpdateItemData: (state, response) => {
    state.updateItemData = response;
  },
  setInventoryPartItem: (state, response) => {
    state.inventoryPartItem = response;
  },
  setInventoryAssemblyItem: (state, response) => {
    state.inventoryAssemblyItem = response;
  },
  setItemTypeData: (state, response) => {
    state.itemTypeData = response.data;
  },
  setSubItemData: (state, response) => {
    state.subItemData = response.data;
  },
  setTaxItemsDataList: (state, response) => {
    state.taxItemDataList = response.data;
  },
  setStockItemsDataSet: (state, response) => {
    state.stockItemsDataSet = response.data;
  },
  setItemsSetupDataSet: (state, response) => {
    state.itemsSetupDataSet = response.data;
  },
  setItemDataErrorMessage: (state, error) => {
    state.itemDataErrorResponse = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  },
  setInventoryPartItemError: (state, error) => {
    state.inventoryPartItemError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  },
  setInventoryAssemblyItemError: (state, error) => {
    state.inventoryAssemblyItemError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  },
  setUpdateErrorMessage: (state, error) => {
    state.updateErrorMessage = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
