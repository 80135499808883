<template>
  <v-container fluid>
    <v-row>
      <v-col>
        Deposit
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Accounting"
};
</script>

<style scoped></style>
