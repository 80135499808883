<template>
<span>
    <form ref="form" @submit.prevent="saveTransaction">
      <v-row class="mx-auto">
        <v-col>
          <v-alert type="error" v-if="errorMessage">
            {{ errorMessage }}
          </v-alert>



           <v-autocomplete
               v-model="form.asset_depreciation_number"
               :items="depreciationItemsList"
               item-value="id"
               label="Depreciated Items"
               outlined
               dense
           ></v-autocomplete>

          <v-text-field
              v-model="form.amount"
              label="Amount"
              outlined
              dense
              required
              @keypress="onlyNumberValidator($event)"
              v-on:blur="compute(form.amount)"
          ></v-text-field>


           <v-menu
               :close-on-content-click="true"
               transition="scale-transition"
               offset-y
               min-width="290px"
           >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  class="mr-1"
                  v-model="form.date"
                  dense
                  readonly
                  v-bind="attrs"
                  label="Date"
                  v-on="on"
                  hint="[ YYY-MM-DD ]"
                  persistent-hint
                  outlined
                  clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.date" no-title></v-date-picker>
          </v-menu>


        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :loading="buttonLoader"
            color="#0000ff"
            block
            dark
            type="submit"
        >
     Save
        </v-btn>
      </v-card-actions>
    </form>
  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DepreciationTransactions",
  props:{
    itemsTransDataList:{}
  },
  data(){
    return{
      errorMessage:null,
      form:{
        amount:null,
        date:null,
        asset_depreciation_number:null,
      },
      number:null,
      buttonLoader:false,
      depreciationItemsList:[],
    }
  },
  mounted() {
   this.doGetDeprecatedItemsData()
    if(this.itemsTransDataList){
       this.checkTransaction()
    }
  },
  methods:{
    ...mapActions(
        [
            "addDepreciationTransactionData",
            "doGetDeprecatedItemsData",
            "fetchTransactionsUpdateInfo",
            "doUpdateTransactionsInfo"
        ]
    ),
    compute(value) {
      value = value + "";
      value = value.toString().replace(/,/g, "");

      if (!isNaN(value)) {
        value = value - 0;
      }
      this.form.amount =
          eval(value) > 0
              ? eval(value)
              : null;
    },
    saveTransaction(){
      this.buttonLoader = true
      let form = this.form
      if(this.itemsTransDataList){
          let data = {
            number:this.number,
            data:form
          }
          this.doUpdateTransactionsInfo(data)
      }else {
        this.addDepreciationTransactionData(form)
      }

    },
    filterDepreciatedList(dataList) {
      let dataObj = [{text: "Choose Item", id: ""}];
      let dataJson = {};
      dataList.forEach(list => {
        dataJson = {
          text: list.account_name,
          id: list.number
        };
        dataObj.push(dataJson);
      })
      return dataObj;
    },
    checkTransaction(){
      this.fetchTransactionsUpdateInfo(this.itemsTransDataList.number)
    }

  },
computed:{
  ...mapGetters(
      [
        "getAllDepreciationData",
          "getDetailsTransactionDepreciationData"
      ]
  )
} ,
  watch:{
    getAllDepreciationData(data){
        if(data){
          this.depreciationItemsList = this.filterDepreciatedList(data)
        }
    },
    itemsTransDataList(){
      this.checkTransaction()
    },
    getDetailsTransactionDepreciationData(details){
      if(details){
        this.form.date = details.date
        this.form.amount = details.amount
        this.form.asset_depreciation_number = details.asset_depreciation_number
        this.number = details.number
      }
    }

  }
}
</script>

<style scoped>

</style>