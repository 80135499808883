<template>
  <div>
    <v-alert type="error" v-if="formMakeSaleErrorMessage">
      {{ formMakeSaleErrorMessage }}
    </v-alert>

    <form ref="form" @submit.prevent="saveSale">




          <v-row>
            <v-col cols="4">
           <v-text-field
                   v-model="form.ref_no"
                   label="Reference No"
                   maxlength="17"
                   outlined
                   dense
           ></v-text-field>
                  <v-text-field
                          v-model="form.invoice_date"
                          label="Date [ dd / mm / yyy ]"
                          outlined
                          dense
                          type="date"
                          required
                          hide-details
                  ></v-text-field>
              <small style="color: red;" v-if="yearLengthValidator(form.invoice_date)">
                Invalid Date
              </small>
            </v-col>

           <v-col cols="4">
          <v-autocomplete
                  outlined
                  hide-details
                  v-model="form.profile_number"
                  :items="customerDataObj"
                  item-value="id"
                  color="pink"
                  label="Customer"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="addCustomer"
                  required
                  dense
          ></v-autocomplete>



   <v-row>
     <v-col cols="4">
        <v-checkbox
                v-model="absoluteDiscount"
                class="shrink mr-0 mt-0 float-left"
                hide-details
                label="is discount absolute?"
                id="sub_account_id"
        ></v-checkbox>
     </v-col>
     <v-col cols="8">


        <v-text-field
                v-if="absoluteDiscount"
                v-model="form.absolute_discount"
                label="Discount Amount"
                outlined
                hide-details
                dense
        ></v-text-field>

          <v-autocomplete
                  outlined
                  v-if="!absoluteDiscount"
                  v-model="form.discount"
                  :items="discountDataObj"
                  item-value="id"
                  color="pink"
                  label="Discount (s)"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="addDiscountsModel"
                  clearable
                  hide-selected
                  dense
          ></v-autocomplete>

     </v-col>




   </v-row>


</v-col>

               <v-col cols="4">
         <v-select
                 outlined
                 v-model="form.storage_account_number"
                 :items="storageAccountDataList"
                 item-value="id"
                 color="pink"
                 label="Bank Account *"
                 append-outer-icon="mdi-plus"
                 @click:append-outer="storageAccountModel"
                 dense
         ></v-select>

          <v-select
                  outlined
                  v-model="form.payment_method_number"
                  :items="paymentMethodDataList"
                  item-value="id"
                  color="pink"
                  label="Payment Method"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="paymentMethodModel"
                  dense
          ></v-select>

       </v-col>

          </v-row>


<v-row>


          <v-row class="mx-auto">
              <v-col cols="9">
                <p class="ml-5">Items</p>
              </v-col>
              <v-col cols="3">
                  <v-select
                      v-model="form.tax_type"
                      :items="taxTypeItems"
                      item-value="id"
                      color="pink"
                      dense
                      hint="Tax Type"
                      persistent-hint
                  ></v-select>
              </v-col>
          </v-row>


        <v-col cols="12">
          <v-divider/>


          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                      <th style="width: 20%;" class="text-left">Item</th>
                      <th style="width: 20%;" class="text-left">Discount (s)</th>
                      <th style="width: 20%;" class="text-left">Tax</th>
                      <th style="width: 10%;" class="text-left">Quantity</th>
                      <th class="text-left">Price</th>
                      <th class="text-left">Amount</th>
                      <th class="text-left" colspan="2">Tax</th>
                    </tr>
              </thead>
              <tbody>
                <tr
                        v-for="(stockItemData, index) in form.bill_of_items"
                        v-bind:key="index"
                >
                  <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                    <v-autocomplete
                            flat
                            solo
                            v-model="stockItemData.item_number"
                            :items="stockItemData.items"
                            color="pink"
                            label=""
                            item-value="id"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="addItemData"
                            @change="addStockItemInfo(stockItemData.item_number, index)"
                            required
                            dense
                            hide-details
                    ></v-autocomplete>
                  </td>
                  <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                        <v-autocomplete
                                flat
                                solo
                                v-model="stockItemData.discount"
                                :items="discountDataObj"
                                item-value="id"
                                color="pink"
                                label=""
                                hide-selected
                                dense
                                append-outer-icon="mdi-plus"
                                @click:append-outer="addDiscountsModel"
                                hide-details
                                clearable
                        ></v-autocomplete>
                      </td>
                   <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                         <v-autocomplete
                                 flat
                                 solo
                                 v-model="stockItemData.tax_number"
                                 :items="taxItemDataObj"
                                 item-value="id"
                                 color="pink"
                                 label=""
                                 hide-selected
                                 dense
                                 multiple
                                 clearable
                                 append-outer-icon="mdi-plus"
                                 @click:append-outer="addTaxModel"
                                 hide-details
                         ></v-autocomplete>
                       </td>
                  <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                    <v-text-field
                            @keypress="onlyNumberValidator($event)"
                            v-model="stockItemData.quantity"
                            label=""
                            flat
                            solo
                            dense
                            required
                            hide-details
                            v-on:blur="
                        compute(stockItemData.quantity, index, 'quantity')
                      "
                    ></v-text-field>
                  </td>
                  <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                    <v-text-field
                            @keypress="onlyNumberValidator($event)"
                            v-model="stockItemData.unit_price"
                            label=""
                            flat
                            solo
                            dense
                            required
                            hide-details
                            v-on:blur="
                        compute(stockItemData.unit_price, index, 'unit_price')
                      "
                    ></v-text-field>
                  </td>
                  <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                    <v-text-field
                            readonly
                            :ref="'stockAmount' + index"
                            v-model="stockItemData.total_amount"
                            dense
                            label=""
                            flat
                            solo
                            required
                            hide-details
                            @keypress="onlyNumberValidator($event)"
                            v-on:blur="
                        compute(
                          stockItemData.total_amount,
                          index,
                          'total_amount'
                        )
                      "
                    ></v-text-field>
                  </td>
                  <td class="pa-0">
                        <v-text-field
                            readonly
                            v-model="stockItemData.taxAmount"
                            flat
                            solo
                            dense
                            hide-details
                            required
                        ></v-text-field>
                      </td>
                  <td class="pa-0">
                    <v-icon
                            @click="removeStockItem(index)"
                            v-if="form.bill_of_items.length > 1"
                            style="font-size: 20px;"
                    >mdi-close</v-icon
                    >
                  </td>
                </tr>

                <tr>
                  <td
                      class="pa-0"
                          colspan="7"
                          style="cursor: pointer;"
                  >
                    <div>

                       <v-btn

                           @click="addStockItems"
                           x-small
                           dark
                           color="indigo"
                       >
                            Add another item
                        </v-btn>


                           <v-btn

                                   @click="showOtherBillExpenses"
                                   x-small
                                   class="float-right"
                                   outlined
                                   color="indigo"
                           >
                             {{ !showAdditionalItems ? "Show" : "Hide" }}  Other Charges
                        </v-btn>
                    </div>
                      </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
 <v-divider></v-divider>


           <p v-if="showAdditionalItems" class="ml-5 mt-5">Other Charges</p>

           <v-simple-table v-if="showAdditionalItems" class="table" dense
                           style="width: 100%; border-top: 1px solid #eeebeb; border-bottom: 1px solid #eeebeb;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Notes</th>
                      <th class="text-left" colspan="2">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                            v-for="(expenseItemData, key) in form.expense_items"
                            v-bind:key="key"
                    >
                      <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                        <v-text-field
                                v-model="expenseItemData.name"
                                flat
                                solo
                                dense
                                hide-details
                        ></v-text-field>
                      </td>
                      <td class="pa-0" style="border-right: 1px solid #e0e0e0;">
                        <v-text-field
                                v-model="expenseItemData.notes"
                                flat
                                solo
                                rows="1"
                                dense
                                no-resize
                                hide-details
                        ></v-text-field>
                      </td>

                      <td class="pa-0">
                        <v-text-field
                                v-model="expenseItemData.amount"
                                flat
                                solo
                                dense
                                hide-details
                                @keypress="onlyNumberValidator($event)"
                                v-on:blur="
                            computeExpense(expenseItemData.amount, key)
                          "
                        ></v-text-field>
                      </td>

                      <td class="pa-0">
                        <v-icon
                                @click="removesExpenseItem(key)"
                                style="font-size: 20px;"
                        >mdi-close</v-icon
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                          class="pa-0"
                              colspan="6"
                              style="cursor: pointer;"
                      >
                         <v-btn

                             @click="addExpenseItems"
                             x-small
                             dark
                             color="indigo"
                         >
                            Add another item
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>



          <v-divider></v-divider>
        </v-col>
      </v-row>


      <v-row>
                <v-col cols="8"></v-col>
                <v-col cols="4">
                  <v-card-text>
                    <v-row class="mx-auto">
                      <p style="color: red;" v-if="doSumTotal.discountError">
                        {{ doSumTotal.discountError }}
                      </p>
                    </v-row>



                     <v-divider></v-divider>

                    <v-row class="mx-auto mt-3">
                      <p>Subtotal</p>
                      <v-spacer></v-spacer>
                      <p>{{ doSumTotal.subTotal }}</p>
                    </v-row>

                    <v-divider></v-divider>

                     <v-row class="mx-auto mt-3">
                      <p>Total Expense</p>
                      <v-spacer></v-spacer>
                      <p>{{ totalExpenseAmount }}</p>
                    </v-row>

                    <v-divider></v-divider>

                    <v-row class="mx-auto mt-3">
                      <p>Discount ( {{ doSumTotal.percentageDiscount }} % )</p>
                      <v-spacer></v-spacer>
                      <p>{{ doSumTotal.totalDiscount }}</p>
                    </v-row>

                     <v-row class="mx-auto mt-3">
                      <p>Tax ( {{ doSumTotal.percentageTax }} % )</p>
                      <v-spacer></v-spacer>
                      <p>{{ doSumTotal.totalTax }}</p>
                    </v-row>

                    <v-divider></v-divider>

                     <v-row class="mx-auto mt-4" style="font-size: 17px;">
                      <p><b>Total</b></p>
                      <v-spacer></v-spacer>
                      <p>
                        <b>{{ doSumTotal.totalAmountAfterTax }}</b>
                      </p>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>


      <div class="text-right">
        <v-btn
                class="companyButton mt-4"
                :loading="salesSpinner"
                color="#0000ff"
                dark
                type="submit"
        >
          Make Sale
        </v-btn>
      </div>
    </form>

      <!--      adds items-->
    <v-dialog v-model="add_item_dialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Item</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_item_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-item ref="items_components"></add-item>
        </div>
      </v-card>
    </v-dialog>

      <!--      adds customer-->
    <v-dialog v-model="add_customer_dialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Customer</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="add_customer_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-profile ref="add_customer_info"></new-profile>
        </div>
      </v-card>
    </v-dialog>


      <!--discounts dialog-->
    <v-dialog v-model="addDiscountDialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Discount</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addDiscountDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-discount ref="add_discount_items"></add-discount>
        </div>
      </v-card>
    </v-dialog>

      <!--tax dialog-->
    <v-dialog v-model="addTaxDialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Tax</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addTaxDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-tax ref="new_tax_component"></new-tax>
        </div>
      </v-card>
    </v-dialog>
      <!--                    payment methods-->

    <v-dialog v-model="payment_methods_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Payment Methods</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payment_methods_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <payment-methods ref="paymentMethodsComponents"></payment-methods>
        </div>
      </v-card>
    </v-dialog>

      <!--    adds Storage accounts -->

    <v-dialog v-model="storage_account_dialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="storage_account_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <storage-account ref="storageAccountComponent"></storage-account>
        </div>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddItem from "./AddItem";
import AddDiscount from "@/modules/dashboard/accounting/commons/AddDiscount";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";
import NewTax from "@/modules/dashboard/Settings/tax/NewTax";
import StorageAccount from "@/modules/dashboard/accounting/commons/StorageAccount.vue";
import PaymentMethods from "@/modules/dashboard/accounting/commons/PaymentMethods.vue";

export default {
    name: "MakeSale",
    props: {
        sale_type: String
    },
    components: {NewTax, NewProfile, AddDiscount, AddItem, StorageAccount, PaymentMethods},
    data() {
        return {
            absoluteDiscount: false,
            formMakeSaleErrorMessage: null,
            salesSpinner: false,
            unit_amount: null,
            stockItemDataObj: [],
            unfilteredInvoiceList: [],
            add_item_dialog: false,
            add_customer_dialog: false,
            customerDataObj: [],
            stockItemData: [],
            discountDataObj: [],
            addDiscountDialog: false,
            addTaxDialog: false,
            taxItemDataObj: [],
            taxListUnfiltered: [],
            discountListUnfiltered: [],
            salesTypeData: null,
            showAdditionalItems: false,
            form: {
                tax_type:"Inclusive",
                ref_no: null,
                due_date: new Date().toISOString().substr(0, 10),
                profile_number: null,
                sale_type: null,
                notes: null,
                discount: null,
                absolute_discount: null,
                invoice_date: new Date().toISOString().substr(0, 10),
                bill_of_items: [
                    {
                        tax_number: null,
                        discount: null,
                        absolute_discount: null,
                        total_amount: null,
                        unit_price: null,
                        item_number: "",
                        quantity: null,
                        taxAmount: 0,
                        amount: null,
                        items:[]
                    }
                ],
                expense_items: [],
                payment_method_number: null,
                storage_account_number: null
            },
            payment_methods_dialog: false,
            storage_account_dialog: false,
            storageAccountDataList: [],
            paymentMethodDataList: [],
            taxTypeItems: [
              {text: "Inclusive", id: "Inclusive"},
              {text: "Exclusive", id: "Exclusive"},
              // {text: "Exempt", id: "Exempt"},
            ],


        };
    },
    mounted() {
        this.doGetItemDataList();
        this.fetchProfiles();
        this.fetchDiscountList();
        this.doGetPaymentMethodData();
        this.doGetStorageAccountData();


        if (this.sale_type) {
            this.checkSalesType()
        }

        this.fetchTaxData();
    },
    methods: {
        ...mapActions(
            [
                "doMakeSale",
                "doGetItemDataList",
                "fetchTaxData",
                "fetchDiscountList",
                "fetchProfiles",
                "doGetPaymentMethodData",
                "doGetStorageAccountData",
            ]
        ),
        saveSale() {
            this.salesSpinner = true;
            this.formMakeSaleErrorMessage = null;
            let formData = this.form
            formData.sale_type = this.salesTypeData
            this.doMakeSale(formData);
        },
        addItemData() {
            this.add_item_dialog = true;
        },
        addStockItems() {

          let items = [];

          this.stockItemDataObj.forEach(obj => {
            let item_val = obj.id;

            let selected_items = this.form.bill_of_items.filter(
                t => t.item_number === item_val
            );

            if (selected_items.length === 0) {
              items.push(obj);
            }
          });


          if(items.length > 0) {
            this.form.bill_of_items.push({
                tax_number: "",
                discount: null,
                absolute_discount: null,
                total_amount: null,
                unit_price: null,
                item_number: "",
                quantity: null,
                taxAmount: 0,
                amount: null,
                items: items
            });
          }else {
            this.errorToast("Error", "No item found!!")
          }
        },
        removeStockItem(index) {
            this.form.bill_of_items.splice(index, 1);
        },
        addCustomer() {
            this.add_customer_dialog = true;
        },
        filterStockItemsList(dataList) {
            let arr = [{text: "Select item", id: "", disabled: true}];
            let dataJson = {};
            dataList.forEach(list => {
              let price = (list.current_price ? " [" +list.current_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ "]" : "")
                dataJson = {
                    text:
                        (list.item_name ? list.item_name : "") + price,
                    id: list.number ? list.number : "",
                    buying_price: list.buying_price ? list.buying_price : "",
                    selling_price: list.current_price ? list.current_price : ""
                };
                arr.push(dataJson);
            })


            this.stockItemDataObj = arr;
            this.form.bill_of_items[0].items = arr
            this.unfilteredInvoiceList = dataList;
        },
        compute(value, index, vModal) {
            if (value) {
                value = value + "";
                value = value.toString().replace(/,/g, "");
                ///this removes any value that starts with a zero
                if (!isNaN(value)) {
                    value = value - 0;
                }

                let quantity = (this.form.bill_of_items[index].quantity.toString().replace(/,/g, "") * 1)
                let unit_price = (this.form.bill_of_items[index].unit_price.toString().replace(/,/g, "") * 1)
                let total_amount = (this.form.bill_of_items[index].total_amount.toString().replace(/,/g, "") * 1)

                if (vModal === "total_amount") {
                    this.form.bill_of_items[index].total_amount =
                        eval(value) >= 0 ? eval(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;


                    if (
                        quantity > 0 &&
                        unit_price < 1
                    ) {
                        this.form.bill_of_items[index].unit_price = (
                            total_amount /
                            quantity
                        ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }

                    if (
                        unit_price > 0 &&
                        quantity < 1
                    ) {
                        this.form.bill_of_items[index].quantity = (
                            total_amount /
                            unit_price
                        ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }

                    if (
                        quantity > 0 &&
                        unit_price > 0
                    ) {
                        this.form.bill_of_items[index].total_amount = (
                            quantity *
                            unit_price
                        ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                } else if (vModal === "quantity") {
                    this.form.bill_of_items[index].quantity =
                        eval(value) >= 0 ? eval(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;

                    if (unit_price > 0) {
                        this.form.bill_of_items[index].total_amount = (
                            quantity *
                            unit_price
                        ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }

                    if (
                        unit_price < 1 &&
                        total_amount > 0
                    ) {
                        this.form.bill_of_items[index].unit_price = (
                            total_amount /
                            quantity
                        ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                } else if (vModal === "unit_price") {
                    this.form.bill_of_items[index].unit_price =
                        eval(value) >= 0 ? eval(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;

                    if (quantity > 0) {
                        this.form.bill_of_items[index].total_amount = (
                            quantity *
                            unit_price
                        ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }

                    if (
                        quantity < 1 &&
                        total_amount > 0
                    ) {
                        this.form.bill_of_items[index].quantity = (
                            total_amount /
                            unit_price
                        ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                }
            }
        },
        addStockItemInfo(value, index) {


          let invoiceListInfo = this.unfilteredInvoiceList;
          let quantity = 1;
          let price = 0;
          let tax_no = null;



          this.form.bill_of_items[index].unit_price = null;
          this.form.bill_of_items[index].total_amount = null;
          this.form.bill_of_items[index].quantity = null;
          this.form.bill_of_items[index].tax_number = "";

          invoiceListInfo.forEach(lst => {
            if (value === lst.number) {
              price = lst.selling_price;
              tax_no = lst.tax ? lst.tax.tax_number : tax_no;
            }
          })


          this.form.bill_of_items[index].tax_number = tax_no

          this.form.bill_of_items[index].quantity = quantity
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.form.bill_of_items[index].unit_price = price
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.form.bill_of_items[index].total_amount = (price * quantity)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        },
        addDiscountsModel() {
            this.addDiscountDialog = true;
        },
        addTaxModel() {
            this.addTaxDialog = true;
        },
        taxItemFilter(dataList) {
            let arr = [{text: "Select Tax", id: "", disabled: true}];
            let obj = {};
            dataList.forEach(list => {
              let tax = (!list.is_absolute) ? list.rate+"%" : list.rate.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    obj = {
                      text: list.name + " (" + tax  + ")",
                      id: list.number,
                      rate:list.rate,
                      is_compound:list.is_compound,
                      is_absolute:list.is_absolute,
                    };
                    arr.push(obj);
                }
            )
            return arr;
        },
        filterDiscountsData(dataList) {
            let dataObj = [{text: "Select discount", id: "", disabled: true}];
            dataList.forEach(item => {
                dataObj.push(
                    {
                        text: item.name + " (" + item.rate + "%)",
                        id: item.number
                    }
                );
            })

            return dataObj;
        },

        checkSalesType() {
            this.salesTypeData = this.sale_type
        },
        showOtherBillExpenses() {
            this.showAdditionalItems = !this.showAdditionalItems
            if (this.showAdditionalItems) {
                this.form.expense_items = [
                    {
                        number: null,
                        name: null,
                        amount: null,
                        notes: null,
                    }
                ]
            } else {
                this.form.expense_items = []
            }
        },
        removesExpenseItem(key) {
            this.form.expense_items.splice(key, 1);
        },
        computeExpense(value, index) {
            if (value) {
                value = value + "";
                value = value.toString().replace(/,/g, "");

                if (!isNaN(value)) {
                    value = value - 0;
                }

                this.form.expense_items[index].amount = eval(value)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        addExpenseItems() {
            this.form.expense_items.push({
                name: null,
                amount: null,
                notes: null,
            });
        },
        paymentMethodModel() {
            this.payment_methods_dialog = true;
        },
        storageAccountModel() {
            this.storage_account_dialog = true;
        },
      calculateTaxAmount(){
        if(this.form.bill_of_items.length > 0) {
          this.form.bill_of_items.forEach(lst =>{
            if(lst.tax_number && lst.tax_number.length > 0){
              lst.taxAmount = this.taxPercentageCalculate(lst.tax_number, lst.total_amount)
            }
          })
        }
      },
      taxPercentageCalculate(data, itemCost){
        itemCost = itemCost ?  (itemCost.toString().replace(/,/g, "") * 1) : 0
        let totalTax = 0
        let taxRate = 0
        let taxAbsolute = 0
        if(this.taxItemDataObj.length > 0){
          let taxObject = this.taxItemDataObj

          const isCompound = data.some(id => {
            const obj = taxObject.find(o => o.id === id);
            return obj && obj.is_compound === true;
          });


          if(!isCompound){
            data.forEach(list => {
              taxObject.forEach(lst => {
                if(list === lst.id){
                  if(lst.is_absolute === false){
                    taxRate += lst.rate
                  }else {
                    taxAbsolute += lst.rate
                  }

                }
              })
            })


            if(this.form.tax_type === "Inclusive"){
              totalTax = itemCost -  (itemCost / (1 + (taxRate / 100))) + taxAbsolute
            }else if(this.form.tax_type === "Exclusive"){
              totalTax = (itemCost *  (taxRate / 100)) + taxAbsolute
            }
          }else {
            //  this condition applies when there is compound tax
            // get list with compound
            //this applies for non-absolute tax

            let sumCompoundTaxes = 0
            let sumNonCompoundTaxes = 0
            const compoundTax = taxObject.filter(obj => data.includes(obj.id) && obj.is_compound === true && obj.is_absolute === false);
            compoundTax.forEach(lst => { sumCompoundTaxes += lst.rate })

            // get list with noncompound
            const nonCompoundTax = taxObject.filter(obj => data.includes(obj.id) && obj.is_compound === false && obj.is_absolute === false);
            nonCompoundTax.forEach(lst => { sumNonCompoundTaxes += lst.rate })


            //this applies for absolute tax
            let sumCompoundTaxesAbsolute = 0
            let sumNonCompoundTaxesAbsolute = 0
            const compoundTaxAbsolute = taxObject.filter(obj => data.includes(obj.id) && obj.is_compound === true && obj.is_absolute === true);
            compoundTaxAbsolute.forEach(lst => { sumCompoundTaxesAbsolute += lst.rate })

            // get list with noncompound
            const nonCompoundTaxAbsolute = taxObject.filter(obj => data.includes(obj.id) && obj.is_compound === false && obj.is_absolute === true);
            nonCompoundTaxAbsolute.forEach(lst => { sumNonCompoundTaxesAbsolute += lst.rate })


            // (sumCompoundTaxesAbsolute + sumNonCompoundTaxesAbsolute)

            let taxAmountCompound = 0
            let taxAmountNonCompound = 0

            if(this.form.tax_type === "Inclusive"){

              taxAmountCompound = itemCost - (itemCost / (1 + (sumCompoundTaxes / 100)))
              itemCost =  itemCost - (taxAmountCompound + sumCompoundTaxesAbsolute)
              totalTax = taxAmountCompound  +  sumCompoundTaxesAbsolute


              if(sumNonCompoundTaxes > 0 || sumNonCompoundTaxesAbsolute > 0){

                if(sumNonCompoundTaxes > 0){
                  taxAmountNonCompound = itemCost - (itemCost / (1 + (sumNonCompoundTaxes / 100)))
                }

                totalTax = (taxAmountCompound + taxAmountNonCompound) + sumCompoundTaxesAbsolute + sumNonCompoundTaxesAbsolute

              }

            } else {

              taxAmountNonCompound = itemCost * (sumNonCompoundTaxes / 100)
              itemCost = itemCost + taxAmountNonCompound + sumNonCompoundTaxesAbsolute
              totalTax = taxAmountNonCompound + sumNonCompoundTaxesAbsolute

              if(sumCompoundTaxes > 0 || sumCompoundTaxesAbsolute > 0){


                if(sumCompoundTaxes > 0){
                  taxAmountCompound = itemCost * (sumCompoundTaxes / 100)
                }

                totalTax = taxAmountCompound + taxAmountNonCompound + sumNonCompoundTaxesAbsolute + sumCompoundTaxesAbsolute

              }

            }






          }


        }

        return totalTax.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },

    },
    computed: {
        ...mapGetters([
            "getStockItemsErrorResponse",
            // "getNewSaleData",
            "getProfileResponseData",
            "getProfileDataList",
            "getItemDataList",
            "getTaxDataAll",
            "getDiscountDataList",
            "getDiscountData",
            "getNewTaxData",
            "getNewServiceItemData",
            "getInventoryPartItem",
            "getInventoryAssemblyItem",
            "getPaymentMethodDataSet",
            "getStorageAccountDataSet"
        ]),
      doSumTotal() {
        /*------------------------------------------------------------*/
        //this function will calculate the total amount
        let arr = this.form.bill_of_items;
        let sum = 0;
        let totalTax = 0

        for (let i = 0; i < arr.length; i++) {
          let quantity = arr[i].quantity
              ? (arr[i].quantity.toString().replace(/,/g, "") * 1)
              : arr[i].quantity;
          let unit_price = arr[i].unit_price
              ? (arr[i].unit_price.toString().replace(/,/g, "") * 1)
              : arr[i].unit_price;
          sum = quantity * unit_price + sum;
          totalTax += (arr[i].taxAmount.toString().replace(/,/g, "") *1)
        }

        /*     if(this.form.tax_type === "Exclusive"){

             }*/


        let subTotal = sum
        // .toFixed(2)
        // .toString()
        // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");


        //----------Taxation-- and  discount calculation-------------------/
        let totalAmountAfterTax = 0





        let percentageTax = 0

        let discountSum = 0;

        let formDiscounts = this.form.discount;
        let absoluteDiscount = this.form.absolute_discount;

        if (formDiscounts) {
          let discountList = this.discountListUnfiltered;

          for (let a = 0; a < discountList.length; a++) {
            let array_number = discountList[a].number;

            for (let s = 0; s < formDiscounts.length; s++) {
              if (array_number === formDiscounts[s]) {
                //this returns summation of discounts
                discountSum = discountList[a].rate + discountSum;
              }
            }
          }
        } else if (absoluteDiscount) {
          discountSum = (absoluteDiscount / sum) * 100;
        }

        let discountError = null;
        if (discountSum > 100) {
          discountError = "Danger!! discount has exceeded 100%";
        } else {
          discountError = "";
        }

        let computeDiscount = (discountSum / 100) * (sum + totalTax);
        // let computeDiscount = (discountSum / 100) * sum;

        let totalDiscount =
            "- " +
            computeDiscount
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        let totalAmount = (sum - computeDiscount)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");


        if(this.form.tax_type === "Exclusive") {
          totalAmountAfterTax = ((sum + totalTax) - computeDiscount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }else {
          totalAmountAfterTax = (sum  - computeDiscount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }


        totalTax = totalTax.toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");


        let percentageDiscount = discountSum.toFixed(2);

        // calculating subtotal
        if(this.form.tax_type === "Inclusive"){
          subTotal = sum  - (totalTax.toString().replace(/,/g, "") * 1)
        }else  if(this.form.tax_type === "Exclusive"){
          subTotal = sum
        }

        subTotal = subTotal
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");


        return {
          subTotal: subTotal,
          totalAmount: totalAmount,
          totalDiscount: totalDiscount,
          percentageDiscount: percentageDiscount,
          discountError: discountError,
          totalAmountAfterTax: totalAmountAfterTax,
          totalTax: totalTax,
          percentageTax: percentageTax,
        };
      },
      totalExpenseAmount() {
        let arr = this.form.expense_items;

        //summation of expense
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
          let amount = arr[i].amount
              ? (arr[i].amount.toString().replace(/,/g, "") * 1)
              : arr[i].amount;
          sum = amount - 0 + sum;
        }

        return sum
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    watch: {
        getStockItemsErrorResponse(error) {
            this.salesSpinner = false;
            this.formMakeSaleErrorMessage = error.message;
            this.errorToast("Error", error.message);
        },
        getProfileResponseData(customerData) {
            if (customerData) {
                this.$refs["add_customer_info"].customerSpinner = false;
                this.$refs["add_customer_info"].form = {
                  name: null,
                  trading_name: null,
                  address: null,
                  number: null,
                  tin: null,
                  phoneContact: [
                    {
                      contact: null,
                      id: null,
                      type: "phone"
                    }
                  ],
                  emailContact: [
                    {
                      contact: null,
                      id: null,
                      type: "email"
                    }
                  ],
                  contactPersons: [
                    {
                      name: null,
                      phone_contacts: [
                        {
                          phone: null
                        }
                      ],
                      email_contacts: [
                        {
                          email: null
                        }
                      ],
                    }
                  ]
                };
                this.add_customer_dialog = false;
                this.successToast("Success", customerData.message);
                this.form.profile_number = customerData.data.number;
                this.fetchProfiles();
            }
        },
        getProfileDataList(customerDataInfo) {
            if (customerDataInfo) {
                this.customerDataObj = this.nameNumberFilter(customerDataInfo);
            }
        },
        getItemDataList(itemDataList) {
            this.filterStockItemsList(itemDataList);
        },
        getTaxDataAll(taxData) {
            if (taxData) {
                this.taxItemDataObj = this.taxItemFilter(taxData);
                this.taxListUnfiltered = taxData
            }

        },
        getDiscountDataList(discountData) {
            this.discountDataObj = this.filterDiscountsData(discountData);
            this.discountListUnfiltered = discountData;
        },
        getDiscountData(discountData) {
            if (discountData) {
                this.$refs["add_discount_items"].form = {
                    name: null,
                    rate: null,
                    discount_number: null
                };
                this.$refs["add_discount_items"].addDiscountLoader = false;
                this.addDiscountDialog = false;
                this.fetchDiscountList();
                this.successToast("Success", discountData.message);
            }
        },
        sale_type() {
            this.checkSalesType()
        },
        getNewTaxData(data) {
            if (data) {
                this.$refs["new_tax_component"].form = {
                  name:null,
                  rate:null,
                  profile_number:null,
                  is_compound:false,
                  is_absolute:false
                };
                this.$refs["new_tax_component"].taxSpinner = false;
                this.addTaxDialog = false;
                this.fetchTaxData();
                this.successToast("Success", data.message);
            }
        },
        getNewServiceItemData(data) {
            if (data) {
                this.add_item_dialog = false
                this.successToast("Success", data.message)
                this.doGetItemDataList();
            }
        },
        getInventoryPartItem(data) {
            if (data) {
                this.add_item_dialog = false
                this.successToast("Success", data.message)
                this.doGetItemDataList();
            }
        },
        getInventoryAssemblyItem(data) {
            if (data) {
                this.add_item_dialog = false
                this.successToast("Success", data.message)
                this.doGetItemDataList();
            }
        },
        getPaymentMethodDataSet(paymentData) {
            this.paymentMethodDataList = this.nameNumberFilter(paymentData);
        },
        getStorageAccountDataSet(storageAccountData) {
            this.storageAccountDataList = this.nameNumberFilter(storageAccountData);
        },
      form:{
        handler(data){
          if(data) {
            this.calculateTaxAmount()
          }
        },
        deep:true
      },
    },

};
</script>

<style scoped>
tbody tr:hover {
    background-color: transparent !important;
}
</style>
