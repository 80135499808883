<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>New Journal </v-toolbar-title>
          </v-toolbar>


          <v-simple-table class="mt-10" dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th style="width: 20%;" class="text-left">Description</th>
                <th style="width: 20%;" class="text-left">Account</th>
                <th style="width: 20%;" class="text-left">Tax Rate</th>
                <th style="width: 17%;" class="text-left">Debit</th>
                <th class="text-left" colspan="2">Credit</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(journalItemData, index) in form.journal_items"
                  v-bind:key="index"
              >
                <td style="border-right: 1px solid #e0e0e0;">
                  <v-text-field
                      v-model="journalItemData.description"
                      flat
                      solo
                      rows="1"
                      dense
                      no-resize
                      hide-details
                  ></v-text-field>
                </td>
                <td style="border-right: 1px solid #e0e0e0;">
                  <v-autocomplete
                      flat
                      solo
                      v-model="journalItemData.account_number"
                      :items="accountDataList"
                      item-value="id"
                      color="pink"
                      label=""
                      hide-selected
                      dense
                      hide-details
                  ></v-autocomplete>
                </td>
              <td style="border-right: 1px solid #e0e0e0;">
                  <v-autocomplete
                      flat
                      solo
                      v-model="journalItemData.tax_number"
                      :items="taxItemDataObj"
                      item-value="id"
                      color="pink"
                      label=""
                      hide-selected
                      dense
                      clearable
                      append-outer-icon="mdi-plus"
                      @click:append-outer="addTaxModel"
                      hide-details
                  ></v-autocomplete>
                </td>

                <td style="border-right: 1px solid #e0e0e0;">
                  <v-text-field
                      v-model="journalItemData.debit"
                      flat
                      solo
                      dense
                      hide-details
                      @keypress="onlyNumberValidator($event)"
                      v-on:blur="
                            computeAmount(journalItemData.debit, index, 'debit')
                          "
                  ></v-text-field>
                </td>
                <td style="border-right: 1px solid #e0e0e0;">
                  <v-text-field
                      v-model="journalItemData.credit"
                      flat
                      solo
                      dense
                      hide-details
                      @keypress="onlyNumberValidator($event)"
                      v-on:blur="
                            computeAmount(journalItemData.credit, index, 'credit')
                          "
                  ></v-text-field>
                </td>
           <td>
             <v-icon
                 @click="removeJournalItem(index)"
                 v-if="index !== 0"
                 style="font-size: 20px;"
             >mdi-close</v-icon
             >
           </td>
              </tr>

              <tr>
                <td
                    colspan="6"
                    @click="addJournalItems"
                    style="cursor: pointer;"
                >
                  <span class="text--disabled">Add another item</span>
                </td>
                <td class="text-right" colspan="2">



                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>


        </v-card>
      </v-col>
    </v-row>

    <!--tax dialog-->
    <v-dialog v-model="addTaxDialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Tax</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addTaxDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-tax ref="new_tax_component"></new-tax>
        </div>
      </v-card>
    </v-dialog>

  </v-container>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewTax from "@/modules/dashboard/Settings/tax/NewTax";

export default {
  name: "NewJournal",
  components: {NewTax},
  data(){
    return{
      accountDataList: [],
      taxItemDataObj: [],
      addTaxDialog: false,
      form:{
        journal_items:[
          {
            description:null,
            account_number:null,
            tax_number:null,
            debit:null,
            credit:null
          }
        ]
      }
    }
  },
  mounted() {
    this.fetchAccountDataList()
    this.fetchTaxData();
  },
  methods:{
    ...mapActions(
        [
          "fetchAccountDataList",
            "fetchTaxData"
        ]
    ),
    filterAccountDataLists: function (accountData) {
      let account = accountData;
      let accObj = [{text: "Select item", id: null, disabled: true}];
      let accountJson = {};



      let account_type = "";
      account.forEach((accountItem, index) => {

        let _account_type = "";

        if (account_type !==  accountItem.account_type) {
          account_type =      accountItem.account_type;
          _account_type =     account_type;
        } else {
          _account_type = "";
        }

        if(_account_type) {
          accountJson = {text: _account_type, id: index, disabled: true}
          accObj.push(accountJson);
          accountJson = {divider: !!_account_type}
          accObj.push(accountJson);
        }


        accountJson = {
          text: accountItem.full_name,
          id: accountItem.number,
          disabled: false
        }
        accObj.push(accountJson);
      })


      return accObj;
    },
    taxItemFilter(dataList) {
      let arr = [{ text: "Select tax", id: "", disabled:true}];
      let obj = {};
      dataList.forEach( list => {
            obj = {
              text: list.name+" ("+list.rate+" %)",
              id: list.number,
            };
            arr.push(obj);
          }
      )
      return arr;
    },
    addTaxModel(){
      this.addTaxDialog = true;
    },
    computeAmount(value, index, type) {
      if (value) {

        value = value.toString().replace(/,/g, "");

        if (!isNaN(value)) {
          value = value * 1;
        }

        if(type === 'credit') {
          this.form.journal_items[index].credit = eval(value)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        if(type === 'debit') {
          this.form.journal_items[index].debit = eval(value)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }


      }
    },
    addJournalItems(){
      this.form.journal_items.push(
          {
            description:null,
            account_number:null,
            tax_number:null,
            debit:null,
            credit:null
          }
      );
    },
    removeJournalItem(index){
      this.form.journal_items.splice(index, 1);
    }
  },
  computed:{
    ...mapGetters(
        [
            "getAccountDataLists",
            "getTaxDataAll",
            "getNewTaxData"
        ]
    )
  },
  watch:{
    getAccountDataLists(accountData) {
      this.accountDataList = this.filterAccountDataLists(accountData);
    },
    getTaxDataAll(taxData) {
      if (taxData) {
        this.taxItemDataObj = this.taxItemFilter(taxData);
        // this.taxListUnfiltered = taxData
      }

    },
    getNewTaxData(data){
      if(data){
        this.$refs["new_tax_component"].form = {
          name:null,
          rate:null,
          profile_number:null,
          is_compound:false,
          is_absolute:false
        };
        this.$refs["new_tax_component"].taxSpinner = false;
        this.addTaxDialog = false;
        this.fetchTaxData();
        this.successToast("Success", data.message);
      }
    },
  }
}
</script>

<style scoped>

</style>