<template>
<span>

  <v-toolbar dense flat>

   {{ customerProfileData.name.charAt(0).toUpperCase() + customerProfileData.name.slice(1) }}

     <v-spacer></v-spacer>
          <span>
              <v-btn
                  class="ma-2 text-decoration-none"
                  outlined
                  small
                  color="#242f7e"
                  @click="editProfile"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Edit Profile
              </v-btn>
            </span>
  </v-toolbar>

  <v-divider></v-divider>

 <div style="width: 98%;" class="mx-auto mt-5">

            <v-row>

               <v-col cols="4">
              <v-card-subtitle>
                <p><b>Supplier Info:</b></p>
                Name: <b>{{ customerProfileData.name.charAt(0).toUpperCase() + customerProfileData.name.slice(1) }}</b
              ><br/>
                Address: {{ customerProfileData.address }}<br/>
                Tin: {{ customerProfileData.tin }}<br/>


              </v-card-subtitle>
            </v-col>

              <v-col cols="4">
              <v-card-subtitle>
                <p><b>Contact Details:</b></p>

                Email:
                <a
                    v-for="(mails, index) in customerProfileData.emails"
                    :href="'mailto:' + mails"
                    :key="index"
                >
                    {{ mails }},

                </a>
                <br/>
                Phone:
                <a
                    v-for="(phones, index) in customerProfileData.phones"
                    :href="'tel:' +phones"
                    :key="'A' + index"
                >
                    {{ phones }},
                </a>
                </v-card-subtitle>

              </v-col>


              <v-col cols="4">
                  <p><b>Received Amount:</b></p>
                  <p>UGX: {{(customerProfileData.reports.total_payments_made.toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</p>


                 <v-progress-circular
                     :rotate="360"
                     :size="170"
                     :width="13"
                     :value="customerProfileData.reports.percentage"
                     color="#1976d2"
                 >
                       {{customerProfileData.reports.percentage}}%<br />
                        Paid
                </v-progress-circular>
              </v-col>



            </v-row>



   <span style="font-size: 17px;" class="ml-5 mb-5"><b>Contact Person Info:</b></span>



   <v-row dense class="mx-auto">
     <v-col
         v-for="(lst, idx) in customerProfileData.contact_persons"
         :key="idx"
         cols="3">

         <v-card-subtitle>
         <p><b>Name:</b>  {{lst.name}}</p>

                   <span>Email Contact:
             <a
                 v-for="(mails, index) in lst.email_contacts"
                 :href="'mailto:' + mails.email"
                 :key="index"
             >
                    {{ mails.email }},

                </a>
           </span>
  <br />
         <span>Phone Contact:

             <a
                 v-for="(phones, index) in lst.phone_contacts"
                 :href="'tel:' +phones.phone"
                 :key="'A' + index"
             >
                    {{ phones.phone }},
                </a>
           </span>

      </v-card-subtitle>


     </v-col>
   </v-row>



    <v-divider></v-divider>

            <v-row class="mx-auto mt-3">

            <v-btn
                outlined
                color="indigo"
            >
      <v-icon>mdi-flag</v-icon>
            <span class="mr-2"><b>Amount:</b></span>
              USh {{(customerProfileData.reports.invoices.toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
            </v-btn>

              <v-spacer></v-spacer>

                <v-btn
                    outlined
                    color="indigo"
                >
      <v-icon>mdi-flag</v-icon>
            <span class="mr-2"><b>Outstanding:</b></span>
            USh {{(customerProfileData.reports.unpaid_invoices.toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
            </v-btn>


            </v-row>

          </div>


   <v-dialog v-model="editCustomerDialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Profile</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editCustomerDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-profile
              ref="profile_component"
              :editProfilesObj="editProfilesObj"
          ></new-profile>
        </div>
      </v-card>
    </v-dialog>

</span>
</template>

<script>
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "CustomerInfo",
  components: {NewProfile},
  props: {
    customerProfileData: {}
  },
  data(){
    return{
      editCustomerDialog: false,
      editProfilesObj:null,
    }
  },
  methods:{
    ...mapActions(
        [
            "customerProfile"
        ]
    ),
    editProfile() {
      this.editProfilesObj = {number:this.$route.query.prof};
      this.editCustomerDialog = true;
    },
  },
  computed:{
    ...mapGetters(
        [
            "getProfileResponseData"
        ]
    )
  },
  watch:{
    getProfileResponseData(data){
      if(data){
        this.editCustomerDialog = false;
        this.$refs["profile_component"].customerSpinner = false;
        this.customerProfile(this.$route.query.prof);
      }
    }
  }
}
</script>

<style scoped>

</style>