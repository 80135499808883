<template>
  <span>
    <v-card-text class="text--disabled"
      ><b> How account types affect your reports </b></v-card-text
    >
    <v-row>
      <v-col col lg="6" md="6" sm="12">
        <v-card tile outlined>
          <v-card-subtitle>Profit & Loss</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <b>Income</b> <br />
            <span class="ml-9">Revenue </span><br />
            <span class="ml-9">Sales </span><br />

            <b>Less Cost of Sales</b><br />
            <span class="ml-9">Direct Costs</span>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-subtitle>GROSS PROFIT</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <b>Plus Other Income</b> <br />
            <span class="ml-9">Other Income</span> <br />

            <b>Less Expenses</b> <br />
            <span class="ml-9">Expenses</span> <br />
            <span class="ml-9">Depreciation</span> <br />
            <span class="ml-9">Overheads</span> <br />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-subtitle> NET PROFIT </v-card-subtitle>
          <v-divider></v-divider>
        </v-card>
      </v-col>
      <v-col col lg="6" md="6" sm="12">
        <v-card tile outlined>
          <v-card-subtitle>Balance Sheet</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <b> Current Assets</b><br />
            <span class="ml-9"> Current Assets</span> <br />
            <span class="ml-9"> Inventory </span> <br />
            <span class="ml-9"> Prepayments </span> <br />

            <b> Plus Bank</b> <br />
            <span class="ml-9"> Bank Accounts </span> <br />

            <b> Plus Fixed Assets</b> <br />
            <span class="ml-9"> Fixed Assets </span> <br />

            <b> Plus Non-current Assets</b> <br />
            <span class="ml-9"> Non-current Assets </span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-subtitle> TOTAL ASSETS </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <b> Less Current Liabilities</b> <br />
            <span class="ml-9"> Current Liabilities </span><br />

            <b> Less Non-current Liabilities </b> <br />
            <span class="ml-9"> Liabilities </span> <br />
            <span class="ml-9"> Non-current Liabilities </span> <br />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-subtitle> NET ASSETS </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <b> Equity </b> <br />
            <span class="ml-9"> Equity</span> <br />

            <b>Plus Net Profit</b>
          </v-card-text>
          <v-divider>
            <v-card-subtitle> TOTAL EQUITY </v-card-subtitle>
          </v-divider>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
export default {
  name: "ChartOfAccountInfo"
};
</script>

<style scoped></style>
