<template>
  <span>
    <v-card flat>
      <v-toolbar dense flat>
<!--        <v-app-bar-nav-icon></v-app-bar-nav-icon>-->
        <v-toolbar-title>Roles And Permissions </v-toolbar-title>
      </v-toolbar>

      <v-tabs>
        <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
        <v-tab>
          <v-icon left>mdi-account</v-icon>
          Roles
        </v-tab>

        <v-tab v-if="checkLicenseStatus()">
          <v-icon left>mdi-lock</v-icon>
          New Role
        </v-tab>

        <v-tab-item>
          <v-divider></v-divider>
          <v-card flat>
            <v-row class="mx-auto mt-8">
              <v-col cols="8"> </v-col>

              <v-col cols="4">
                <v-text-field
                  class="mr-3"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="rolesTableData"
              :search="search"
              :loading="tableRolesLoader"
              loading-text="Loading... please wait"
              :page="page"
              :items-per-page="items_per_page"
              :server-items-length="server_items_length"
              :options.sync="paginationRoles"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="mr-2"
                      @click="editRolePermission(item)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit Role</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-divider></v-divider>

          <v-card flat>
            <v-row>
              <v-col cols="12" class="mt-10">
                <new-role ref="role_components"></new-role>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <v-dialog v-model="edit_role_dialog" width="70%">
      <v-card>
        <div style="width: 93%; padding-bottom: 10px;" class="mx-auto">
          <v-row>
            <v-card-title> Edit Role</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="edit_role_dialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <new-role :roleData="roleData" ref="role_component"></new-role>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import NewRole from "@/modules/dashboard/Settings/RolesAndPermissions/NewRole";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "RolesAndPermissions",
  components: { NewRole },
  data() {
    return {
      rolesTableData: [],
      search: "",
      tableRolesLoader: false,
      paginationRoles: {},
      page: 1,
      items_per_page: 10,
      server_items_length: 0,
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Role Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Action", value: "actions", sortable: false, width: "10%" }
      ],
      edit_role_dialog: false,
      roleData: {}
    };
  },
  mounted() {},
  methods: {
    ...mapActions(["getUserRolesAndPermissionsList"]),
    filterRolesList(raw_data) {
      let rolesDataList = raw_data.data;
      this.server_items_length = raw_data.total;
      this.tableRolesLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let rolesJsn = {};
      let rolesObj = [];

      for (let i = 0; i < rolesDataList.length; i++) {
        rolesJsn = {
          key: (current_page - 1) * per_page + i + 1,
          name: rolesDataList[i].name,
          description: rolesDataList[i].description,
          number: rolesDataList[i].number
        };
        rolesObj.push(rolesJsn);
      }
      this.rolesTableData = rolesObj;
    },
    editRolePermission(item) {
      this.roleData = item;
      this.edit_role_dialog = true;
    },
    checkRoles() {
      let options = {
        page: this.page,
        per_page: this.items_per_page
      };
      this.getUserRolesAndPermissionsList(options);
    }
  },
  computed: {
    ...mapGetters([
      "getNewRolesAndPermission",
      "getRolesAndPermissionList",
      "getUpdateRolesAndPermission"
    ])
  },
  watch: {
    getNewRolesAndPermission(rolesResponse) {
      if (rolesResponse) {
        this.$refs["role_components"].form = {
          name: null,
          description: null,
          permissions: []
        };
        this.$refs["role_components"].spinner = false;
        this.successToast("Success", rolesResponse.message);

        this.checkRoles();
      }
    },
    getRolesAndPermissionList(rolesList) {
      if (rolesList) {
        this.filterRolesList(rolesList);
      }
    },
    paginationRoles(obj) {
      this.tableRolesLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage
      };
      this.getUserRolesAndPermissionsList(options);
    },
    getUpdateRolesAndPermission(updateData) {
      if (updateData) {
        this.$refs["role_component"].spinner = false;
        this.$refs["role_component"].form = {
          role_number: null,
          name: null,
          description: null,
          permissions: []
        };
        this.checkRoles();
        this.successToast("Success", updateData.message);
        this.edit_role_dialog = false;
      }
    }
  }
};
</script>

<style scoped></style>
