<template>
  <span>
    <v-card flat>
      <v-row class="mt-10">
         <v-col cols="3">
            <v-select
                class="ml-4"
                v-model="status"
                :items="statusItems"
                item-value="id"
                color="pink"
                dense
                hint="Status"
                persistent-hint
                v-on:change="filterData"
            ></v-select>
         </v-col>

         <v-col cols="3">
            <v-select
                class="ml-4"
                v-model="profile_number"
                :items="customerDataList"
                item-value="id"
                color="pink"
                dense
                hint="Customer"
                persistent-hint
                clearable
                v-on:change="filterData"
            ></v-select>
         </v-col>

         <v-col cols="2">
          <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  class="mr-1"
                  v-model="start_date"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hint="Start Date [ YYY-MM-DD ]"
                  persistent-hint
                  clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="start_date" no-title></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2">
          <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  class="mr-1"
                  v-model="end_date"
                  dense
                  readonly
                  hint="End Date [ YYY-MM-DD ]"
                  persistent-hint
                  v-bind="attrs"
                  clearable
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="end_date" no-title></v-date-picker>
          </v-menu>
        </v-col>
           </v-row>

      <v-row>
        <v-col cols="9"></v-col>
        <v-col cols="3" class="ml-auto">
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                dense
                outlined
                hide-details
                v-on:keyup.enter="filterData"
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>

      <v-data-table
          :headers="invoiceHeaders"
          :items="invoiceListDataInfo"
          :search="search"
          :loading="tableInvoiceLoader"
          loading-text="Loading... please wait"
          :page="page"
          :items-per-page="items_per_page"
          :server-items-length="server_items_length"
          :options.sync="paginationInvoice"
      >
        <!--                  add a link on the an invoice ref code-->
        <template #item.counter="{ item }">
          <router-link
              class="refNumber"
              :to="{
              name: 'invoice-details',
              params: { type: item.counter },
              query: { id: item.number }
            }"
          >
            {{ item.counter }}
          </router-link>
        </template>

        <!--                status-->

        <template #item.status="{ item }">
          <v-chip class="ma-2" :color="getColor(item.status)" small outlined>
            {{ item.status }}<br/>
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip bottom v-if="item.status !== 'Fully paid' && item.status !== 'cancelled'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click="makePayment(item)"
              >
                mdi-cash
              </v-icon>
            </template>
            <span>Pay Invoice</span>
          </v-tooltip>



<!--           <v-tooltip v-if="item.status !== 'cancelled'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="17" v-bind="attrs" v-on="on" @click="editInvoice(item)">
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit Invoice</span>
          </v-tooltip>-->



          <v-tooltip v-if="item.status !== 'cancelled'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click="cancelInvoice(item)">
                mdi-close
              </v-icon>
            </template>
            <span>Cancel Invoice</span>
          </v-tooltip>


          <v-progress-circular
              class="ml-2"
              v-if="activeIndex === item.number"
              width="1"
              size="16"
              color="#242f7e"
              indeterminate
          ></v-progress-circular>


        </template>
      </v-data-table>
    </v-card>

    <!--pay invoice dialog-->

    <v-dialog v-model="payInvoiceDialog" width="40%">
      <v-card>
        <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Pay Invoice</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payInvoiceDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <pay-invoice-bill
              ref="paymentComponents"
              :invoiceInfo="invoiceDataInfo"
              receiptType="Invoice"
          ></pay-invoice-bill>
        </div>
      </v-card>
    </v-dialog>


    <!--edit invoice dialog-->
    <v-dialog v-model="editInvoiceDialog" width="90%">
      <v-card>
        <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Invoice</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editInvoiceDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <edit-invoice :editInfo="'editInvoice'" ref="edit_invoice_items"></edit-invoice>
        </div>
      </v-card>
    </v-dialog>


  </span>
</template>

<script>
import PayInvoiceBill from "@/modules/dashboard/accounting/commons/PayInvoiceBill";
import {mapActions, mapGetters} from "vuex";
import router from "@/global/router";
import EditInvoice from "@/modules/dashboard/accounting/Invoice/EditInvoice";

export default {
  name: "InvoiceTable",
  components: {EditInvoice, PayInvoiceBill},
  data() {
    return {
      activeTab: "invoice",
      invoiceListDataInfo: [],
      tableInvoiceLoader: false,
      items_per_page: 10,
      page: 1,
      server_items_length: 0,
      paginationInvoice: {},
      profile_number: null,
      invoice_number: null,
      search: "",
      invoiceHeaders: [
        {
          width: "5%",
          text: "#",
          align: "start",
          value: "key"
        },
        {text: "No", value: "counter"},
        {text: "Reference", value: "reference"},
        {text: "profiles Name", value: "customer_name"},
        {text: "Invoice Date", value: "invoice_date"},
        {text: "Due Date", value: "due_date"},
        {text: "Amount", value: "amount", align: "right"},
        {text: "Due Amount", value: "due_amount", align: "right"},
        {text: "Status", value: "status", align: "right"},
        {text: "Action", value: "actions"}
      ],
      // editInvoiceDialog: false,
      editInvoiceNumber: null,
      showInvoice: true,
      showNewInvoice: false,
      payInvoiceDialog: false,
      editInvoiceDialog: false,
      invoiceDataInfo: {},
      start_date: "",
      end_date: "",
      status: "",
      statusItems: this.getInvoiceBillStatuses(),
      customerDataList: [],
      activeIndex: undefined,
    };
  },
  mounted() {
    this.profile_number = this.$route.query.prof ? this.$route.query.prof : null;
    this.fetchProfiles();
  },
  methods: {
    ...mapActions(
        [
          "doGetInvoiceListData",
          "getGeneratedInvoiceNumber",
          "doCancelInvoiceBill",
          "fetchProfiles",
          "doGetInvoiceInfo",
          "getInvoiceBillBalance"
        ]),
    filterInvoiceList(raw_data) {
      let invoiceListData = raw_data.data;
      this.server_items_length = raw_data.total;
      this.tableInvoiceLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let invoiceListJsn = {};
      let invoiceListObj = [];

      invoiceListData.forEach((list, i) => {
        invoiceListJsn = {
          key: (current_page - 1) * per_page + i + 1,
          customer_name: list.customer ? list.customer.name : "",
          invoice_date: list.invoice_date ? list.invoice_date : null,
          due_date: list.due_date ? list.due_date : null,
          number: list.number ? list.number : null,
          counter: list.counter ? list.counter : null,
          status: list.status ? list.status : null,
          reference: list.ref_no ? list.ref_no : null,
          due_amount: list.total_amount ? list.total_amount.due
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null,
          amount: list.total_amount ? list.total_amount.afterDiscount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null
        };
        invoiceListObj.push(invoiceListJsn);
      })

      this.invoiceListDataInfo = invoiceListObj;
    },
    makePayment(item) {
      this.invoiceDataInfo = item;
      this.payInvoiceDialog = true;
      this.getInvoiceBillBalance(item.number);
    },
    editInvoice(item) {
      if (item) {
        this.doGetInvoiceInfo(item.number);
        this.editInvoiceDialog = true
      }
    },
    cancelInvoice(item) {
      let confirm_delete = confirm(
          "Are you sure you want to cancel this Invoice?"
      );
      if (!confirm_delete) {
        return false;
      }
      this.activeIndex = item.number
      this.doCancelInvoiceBill(item.number);
    },
    getColor(statusColor) {
      return this.getStatusColor(statusColor);
    },
    filterData() {
      this.tableBillLoader = true
      let options = {
        page: 1,
        per_page: this.items_per_page,
        profile_number: this.profile_number,
        start_date: this.start_date,
        end_date: this.end_date,
        search: this.search,
        status: this.status
      };
      this.doGetInvoiceListData(options);

    },
    filterCustomerList(customerList) {
      let dataObj = [{text: "Select profiles", id: null, disabled: true}];
      let dataJson = {};
      customerList.forEach(list => {
        dataJson = {
          text: list.name,
          id: list.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })


      this.customerDataList = dataObj;

    },
  },
  computed: {
    ...mapGetters([
      "getInvoiceListData",
      "getNewInvoiceData",
      "getNextInvoiceNumber",
      "getInvoicePaymentData",
      "getProfileDataList",
      "getCancelInvoiceBill",
      "getCustomerDetailsErrorMessage",
      "getEditInvoiceData"
    ])
  },
  watch: {
    getInvoiceListData(invoiceList) {
      this.filterInvoiceList(invoiceList);
    },

    paginationInvoice(obj) {
      this.tableInvoiceLoader = true;
      let page = obj.page;

      let options = {
        page: page,
        per_page: obj.itemsPerPage,
        profile_number: this.profile_number,
        start_date: this.start_date,
        end_date: this.end_date,
        search: this.search,
        status: this.status
      };
      this.doGetInvoiceListData(options);
    },

    getNewInvoiceData(invoiceData) {
      if (invoiceData) {
        this.$refs["invoice_items"].btnInvoiceSpinner = false;
        this.$refs["invoice_items"].form = {
          ref_no: null,
          invoice_date: new Date().toISOString().substr(0, 10),
          due_date: new Date().toISOString().substr(0, 10),
          notes: null,
          profile_number: null,
          discounts: null,
          absolute_discounts: null,
          invoice_type: "customer",
          items: [
            {
              item_number: null,
              quantity: null,
              item_discounts: null,
              unit_price: null
            }
          ],
          expense: {
            date: null,
            account_number: null,
            reference: null,
            payment_method: null
          },
          expense_items: [
            {
              name: null,
              amount: null,
              notes: null,
              account_number: null
            }
          ]
        };
        this.successToast("Success", invoiceData.message);

        this.filterData()

        //  show new invoice number
        this.getGeneratedInvoiceNumber();
      }
    },
    activeTab(data) {
      router.push({name: "invoice", query: {type: data}});
    },
    getInvoicePaymentData(invoiceData) {
      if (invoiceData.message) {
        this.$refs["paymentComponents"].form = {
          invoice_number: this.invoiceDataInfo.number,
          amount: null,
          payment_method_number: null,
          storage_account_number: null,
          payment_date: new Date().toISOString().substr(0, 10),
          notes: null
        };
        this.$refs["paymentComponents"].invoiceLoader = false;
        this.payInvoiceDialog = false;

        this.successToast("Success", invoiceData.message);

        let options = {
          profile_number: this.profile_number,
          page: this.page,
          per_page: this.items_per_page
        };

        this.doGetInvoiceListData(options);
      }
    },
    start_date() {
      this.filterData();
    },
    end_date() {
      this.filterData();
    },
    getProfileDataList(customerInfo) {
      this.filterCustomerList(customerInfo);
    },
    getCancelInvoiceBill(cancelInvoice) {
      if (cancelInvoice) {
        this.activeIndex = undefined
        this.successToast("Success", cancelInvoice.message)
        this.filterData()
      }
    },
    getCustomerDetailsErrorMessage(error) {
      if (error) {
        this.activeIndex = undefined
        this.errorToast("Error", error.message)
      }
    },
    getEditInvoiceData(data){
      if(data){
        this.editInvoiceDialog = false
        this.successToast("Success", data.message)
        this.$refs.edit_invoice_items.btnInvoiceSpinner = false
        this.filterData()
      }
    }
  }
};
</script>

<style scoped>
.refNumber {
  text-decoration: none;
}

.refNumber:hover {
  color: green;
  text-decoration: underline;
}
</style>
