<template>
<span>
     <v-card flat>

                <v-row class="mx-auto mt-8">
                  <v-col cols="8">
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                        class="mr-3"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        v-on:keyup.enter="doSearch"
                        dense
                        outlined
                        hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-data-table
                    :headers="itemHeaders"
                    :items="itemListDataTable"
                    :search="search"
                    :loading="tableItemLoader"
                    loading-text="Loading... please wait"
                    item-key="name"
                    class="elevation-1"
                    :page="page"
                    :items-per-page="items_per_page"
                    :server-items-length="server_items_length"
                    :options.sync="pagination"
                    :footer-props="{
                    itemsPerPageOptions: items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Items per page'
                  }"
                >
                  <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                        :pagination="pagination"
                        :options="options"
                        @update:options="updateOptions"
                        items-per-page-text="Items per page"
                        :items-per-page-options="items_per_page_options"
                        :showFirstLastPage="true"
                        firstIcon="mdi-arrow-collapse-left"
                        lastIcon="mdi-arrow-collapse-right"
                        prevIcon="mdi-chevron-left"
                        nextIcon="mdi-chevron-right"
                    />
                  </template>

                  <template v-slot:item.item_type="{ item }">
                    <b>{{item.item_type}}</b>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>



   <v-dialog v-model="itemsDialog" width="100%">
      <v-card>
        <div style="width: 97%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Item</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="itemsDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-item
              ref="edit_item_components"
              :itemsDataList="itemsDataList"
          ></add-item>
        </div>
      </v-card>
    </v-dialog>




</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddItem from "@/modules/dashboard/accounting/commons/AddItem";

export default {
  name: "InventoryItemList",
  components: {AddItem},
  data(){
    return{
      itemListDataTable: [],
      search: null,
      tableItemLoader: false,
      items_per_page: 20,
      page: 1,
      server_items_length: 0,
      itemsDialog: false,
      itemHeaders: [
        {
          width: "5%",
          text: "#",
          align: "start",
          value: "key"
        },
        { text: "Item Type", value: "item_type" },
        { text: "Name", value: "name" },
        { text: "Code", value: "code" },
        { text: "notes", width: "20%", value: "notes" },
        { text: "Current in stock", width: "20%", value: "remaining_stock" },
        { text: "Stock Value", width: "20%", value: "stock_value" },
        { text: "Selling price", width: "20%", value: "selling_price" },
        { text: "Action", value: "actions", width: "10%", }
      ],
      pagination: {},
      items_per_page_options: [20, 50, 100, 150],
      itemsDataList: {},
    }
  },
  methods:{
    ...mapActions(
        [
          "doGetItemsListData",
          "deleteItemInfo"
        ]
    ),
    editItem(item) {
      this.itemsDataList = item;
      this.itemsDialog = true;
    },
    deleteItem(item) {
      let confirm_delete = confirm(
          "Are you sure you want to delete this item."
      );
      if (!confirm_delete) {
        return false;
      }
      this.deleteItemInfo(item.number)
    },
    doSearch() {
      this.tableItemLoader = true;
      let options = {
        item: this.search ? this.search : "",
        page: this.page,
        per_page: this.items_per_page,
        types: 'IVP,IVA',
        search: this.search
      };
      // this.searchItem(options);
      this.doGetItemsListData(options)
    },
    filterItemListData(raw_data) {
      //data

      let itemListData = raw_data.data;
      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;
      this.server_items_length = raw_data.total;
      this.tableItemLoader = false;

      let itemListJsn = {};
      let itemListObj = [];

      let type = "";

      itemListData.forEach((list, i) => {
        let _type = "";

        if (type !== list.type) {
          type = list.type;
          _type = type;
        } else {
          _type = "";
        }

        itemListJsn = {
          key: (current_page - 1) * per_page + i + 1,
          item_type: _type,
          name: list.full_name ? list.full_name : "",
          code: list.code ? list.code : "",
          notes: list.notes ? list.notes : "",
          number: list.number ? list.number : "",
          selling_price: this.transformNumbersToAccountingStandard(list.selling_price),
          stock_value: list.stock_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          remaining_stock: list.remaining_stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        };
        itemListObj.push(itemListJsn);
      })

      this.itemListDataTable = itemListObj;
    },
  },
  computed:{
    ...mapGetters(
        [
          "getItemListData",
          "getDeleteItemsData",
          "getUpdateItemData"
        ]
    )
  },
  watch:{
    getItemListData(itemListData) {
      this.filterItemListData(itemListData);
    },
    pagination(obj) {
      this.tableItemLoader = true;
      let page = obj.page;

      let options = {
        company_number: this.$company.number,
        page: page,
        per_page: obj.itemsPerPage,
        types: 'IVP,IVA',
        search: this.search
      };
      this.doGetItemsListData(options);
    },
    getDeleteItemsData(data){
      if(data){
        this.successToast("Success", data.message)
        this.doSearch()
      }
    },
    getUpdateItemData(data){
      if(data){
        this.itemsDialog = false
        this.doSearch()
      }
    }
  }
}
</script>

<style scoped>

</style>