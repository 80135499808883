<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>User Profile </v-toolbar-title>
          </v-toolbar>

          <v-list dense>
            <v-list-item-group color="primary">
              <v-divider></v-divider>
              <v-list-item style="border-bottom: 1px solid #e0e0e0">
                <span>
                  <v-icon>mdi-account</v-icon>
                  <span class="ml-4">{{ userName }}</span>
                </span>
              </v-list-item>

              <v-list-item style="border-bottom: 1px solid #e0e0e0">
                <span>
                  <v-icon v-if="userDataProfile.gender === 'Male'"
                    >mdi-gender-male</v-icon
                  >
                  <v-icon v-else>mdi-gender-female</v-icon>
                  <span class="ml-4">{{ userDataProfile.gender }}</span>
                </span>
              </v-list-item>

              <v-list-item style="border-bottom: 1px solid #e0e0e0">
                <span>
                  <v-icon>mdi-email-outline</v-icon>
                  <a
                    class="text-decoration-none ml-4"
                    v-bind:href="'mailto:' + userDataProfile.email"
                    >{{ userDataProfile.email }}</a
                  >
                </span>
              </v-list-item>

              <v-list-item style="border-bottom: 1px solid #e0e0e0">
                <span>
                  <v-icon>mdi-phone</v-icon>
                  <a
                    class="text-decoration-none ml-4"
                    v-bind:href="'tel:' + userDataProfile.phone"
                    >{{ userDataProfile.phone }}</a
                  >
                </span>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserProfile",
  data() {
    return {
      userDataProfile: [],
      userName: null
    };
  },
  mounted() {
    this.fetchUserProfileData();
  },
  methods: {
    ...mapActions(["fetchUserProfileData"])
  },
  computed: {
    ...mapGetters(["getUserProfileInfo"])
  },
  watch: {
    getUserProfileInfo(userProfile) {
      if (userProfile) {
        this.userDataProfile = userProfile;
        this.userName = userProfile.first_name + " " + userProfile.second_name;
      }
    }
  }
};
</script>

<style scoped></style>
