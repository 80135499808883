import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  taxPaymentDetails: null,
  taxPaymentError: null,
};

const getters = {
  getTaxPaymentDetails: state => state.taxPaymentDetails,
  getTaxPaymentError: state => state.taxPaymentError,
};

const actions = {
/*  async saveChartOfAccounts({ commit }, data) {
    await axios
      .post(
        data.number
          ? IP + "charts-of-accounts/update"
          : IP + "charts-of-accounts/save_chart",
        data
      )
      .then(response => {
        commit("setChartOfAccountsData", response);
      })
      .catch(error => {
        commit("setChartOfAccountsErrorMessage", error);
      });
  },*/
  async taxPaymentDataList({ commit }, data) {
    let search = data?data.search ? data.search : '':""
    await axios
        .get(IP + "taxes/payments?" +
            "page="+data.page+
            "&per_page="+data.per_page+
            "&search="+search
        )
        .then(response => {
          commit("setTaxPaymentDetails", response);
        })
        .catch(error => {
          commit("setTaxPaymentError", error);
        });
  },




};

const mutations = {
  setTaxPaymentDetails: (state, response) => {
    state.taxPaymentDetails = response.data;
  },
  setTaxPaymentError: (state, error) => {
    state.taxPaymentError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
