<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-toolbar dense flat>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
          <v-toolbar-title> Invoices </v-toolbar-title>

          <v-spacer></v-spacer>
          <span>

              <v-btn
                  class="ma-2"
                  outlined
                  color="#242f7e"
                  small
                  @click="fileExport"
                  :loading="exportSpinner"
              >
                <v-icon left>
                  mdi-download
                </v-icon>
                Export
              </v-btn>
            </span>
        </v-toolbar>

        <v-tabs v-model="activeTab">
          <v-tab href="#invoice">
            <v-icon left>mdi-account</v-icon>
            Invoices
          </v-tab>

          <v-tab v-if="checkLicenseStatus()" href="#new-invoice">
            <v-icon left>mdi-lock</v-icon>
            New Invoice
          </v-tab>

          <v-tab-item id="invoice">
            <v-divider></v-divider>
            <invoice-table></invoice-table>
          </v-tab-item>

          <v-tab-item  id="new-invoice">
            <v-divider></v-divider>
            <v-card flat>
              <div
                class="mt-4 mx-auto"
                style="width: 98%; padding-bottom: 15px;"
              >
                <new-invoice ref="invoice_items"></new-invoice>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <v-dialog v-model="uploadDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Import Invoices</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="uploadDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <upload-file
              ref="upload_component"
              fileType="profiles"
          ></upload-file>
        </div>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import NewInvoice from "../commons/NewInvoice";
import router from "@/global/router";
import InvoiceTable from "@/modules/dashboard/accounting/profiles/InvoiceTable";
import configs from "@/global/configs.json";
import UploadFile from "@/modules/dashboard/accounting/UploadFile/UploadFile";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Invoice",
  components: {UploadFile, InvoiceTable, NewInvoice },
  data() {
    return {
      activeTab: "invoice",
      downloadSpinner: false,
      exportSpinner: false,
      uploadDialog:false,
      downloadLink:
          configs.SERVER_IP + "template?type=invoices&file_token=",
      exportLink:
          configs.SERVER_IP + "export?type=invoices&file_token=",
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  methods:{
    ...mapActions(
        [
            "downloadTemplateFile",
            "exportFile"
        ]
    ),
    fileUploader() {
      this.uploadDialog = true;
    },
    downloadTemplate() {
      this.downloadSpinner = true;
      this.downloadTemplateFile("invoices");
    },
    fileExport() {
      this.exportSpinner = true;
      this.exportFile("invoices");
    },
  },
  computed:{
    ...mapGetters(
        [
            "getTemplateDownloadData",
            "getExportFileData"
        ]
    )
  },
  watch: {
    activeTab(data) {
      router.push({ name: "invoice", query: { type: data } });
    },
    getTemplateDownloadData(templateData) {
      if (templateData) {
        this.downloadSpinner = false;

        let fileUrl = this.downloadLink + templateData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },
    getExportFileData(exportData) {
      if (exportData) {
        this.exportSpinner = false;
        let fileUrl = this.exportLink + exportData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },
  }
};
</script>
