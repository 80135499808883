import axios from "axios";
import configs from "../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  userProfileDataInfo: null,
  registerStatusResponse: false,
  registerErrorMessage: null
};

const getters = {
  getRegisterUserProfileData: state => state.userProfileDataInfo,
  getRegisterStatusResponse: state => state.registerStatusResponse,
  getRegisterErrorMessage: state => state.registerErrorMessage
};

const actions = {
  async registerUser({ commit }, data) {
    await axios
      .post(IP + "auth/register", data)
      .then(response => {
        commit("setRegisterUserProfileData", response);
      })
      .catch(error => {
        commit("setRegisterMessage", error.response);
      });
  }
};

const mutations = {
  setRegisterUserProfileData: (state, response) => {
    state.registerStatusResponse = true;
    state.userProfileDataInfo = response.data;
  },
  setRegisterMessage: (state, error) => {
    state.registerStatusResponse = false;
    state.registerErrorMessage = error;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
