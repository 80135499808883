import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  bankStatementData: null,
  storageDetails: null,
  bankStatementErrorResponse: null
};

const getters = {
  getBankStatementData: state => state.bankStatementData,
  getStorageDetails: state => state.storageDetails,
  getBankStatementErrorResponse: state => state.bankStatementErrorResponse
};

const actions = {
  async doGeBankStatement({ commit }, data) {
    let start_date = data.start_date ? data.start_date : "";
    let end_date = data.end_date ? data.end_date : "";
    let storage_number = data.storage_number ? data.storage_number : "";
    let data_filter = data.data_filter ? data.data_filter : "";
    await axios
      .get(
        IP +
          "transactions/cash-flow" +
          "?page=" +data.page +
          "&per_page="+data.per_page +
          "&account="+storage_number +
          "&start_date="+start_date +
          "&end_date=" +end_date+
          "&data_filter=" +data_filter
      )
      .then(response => {
        commit("setBankStatement", response);
      })
      .catch(error => {
        commit("setBankStatementErrorMessage", error);
      });
  },
  async doGetStorageAccount({ commit }, data) {
    await axios
      .get(IP + "bank-accounts/details/" + data)
      .then(response => {
        commit("setStorageDetails", response);
      })
      .catch(error => {
        commit("setBankStatementErrorMessage", error);
      });
  }
};

const mutations = {
  setBankStatement: (state, response) => {
    state.bankStatementData = response.data;
  },
  setStorageDetails: (state, response) => {
    state.storageDetails = response.data;
  },
  setBankStatementErrorMessage: (state, error) => {
    state.bankStatementErrorResponse = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
