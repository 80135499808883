<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      <HomeHeader/>
      <v-row>
        <v-col class="d-none d-md-block" md="12" lg="8">
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="10" class="font-fam">
              <v-row class="section">
                <v-col cols="12">
                  <span class="base">BASE</span>
                  <h1>Basic Accounting for Simplified Enterprise</h1>
                  <p class="font-fam-2" style="font-size: 18px; text-align: justify; text-justify: inter-word;">
                    Start your journey with base today, with curated features by industry experts to allow you manage your enterprise regardless of your size.
                    Control your accounts, monitor your transactions and get to see your Balance Sheet, Cash flow and Profit & Loss updated in real time.
                    <br/>
                    This is a smart spend.</p>

                  <span class="button">Schedule a demo</span>

                </v-col>

              </v-row>
              <v-row class="section">
                <v-col cols="12">
                  <h1>A solution that is designed for your specific size</h1>
                  <v-row>
                    <v-col  cols="4">
                      <v-card>
                        <v-img class="img" src="https://www.usnews.com/object/image/00000183-7f28-da3a-afcb-ffff637b0000/gettyimages-1282829300.jpg?update-time=1664285735669&size=responsive970"></v-img>
                        <p class="md-text mx-2">Small business</p>
                        <v-row>
                          <v-col class="mx-2 columnHeight">
                            <p style="color: #065FDB; font-weight: bold;">For freelancers and micro-enterprises</p>
                            <p>
                              Are you a small company that wants to automate expense reports management for yourself or your team?
                            </p>
                            <p>
                              <b>Base</b> has designed a solution tailored for the needs of sole proprietors and micro-enterprises, enabling you to devote your time to your core business.
                            </p>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="4">
                      <v-card>
                        <v-img class="img" src="https://hbr.org/resources/images/article_assets/2022/08/Hero-Image.png"></v-img>
                        <p class="md-text mx-2">Growing Company</p>
                        <v-row>
                          <v-col class="mx-2 columnHeight">
                            <p style="color: #065FDB; font-weight: bold;">For startups, SMEs, and mid-caps</p>
                            <p>
                              Are you a fast-growing company that needs to optimize expense management?
                            </p>
                            <p>
                              Optimize your process using <b>Base</b>: automatic data entry, simplified expense validation, integration with your ecosystem and fingertip control.
                            </p>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="4">
                      <v-card>
                        <v-img class="img" src="https://committingsociologydotcom.files.wordpress.com/2015/06/corporate-buildings-900.jpg"></v-img>
                        <p class="md-text mx-2">Enterprise</p>
                        <v-row>
                          <v-col class="mx-2 columnHeight">
                            <p style="color: #065FDB; font-weight: bold;">For large companies</p>
                            <p>
                              Are you a large company with complex, rigorous processes?
                            </p>
                            <p>
                              <b>Base</b> simplifies them without compromising on accuracy: a high-tech solution with unparalleled functional depth, advanced configurations, and custom support.
                            </p>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
              <v-row class="section">
                <v-col>
                  <h1>Features provided</h1>
                  <v-row>
                    <v-col>
                      <h2>Chart of Accounts</h2>
                      <p>
                        This comes preset for you and it encompasses the major classification of accounts by industry standards.
                        We allow it to be customizable but also have the ability to generate sub-class accounts under each of the top categories.
                        This Chart varies depending on what you as a business deal in and therefore avoids having accounts you will not need for your
                        day to day work. All your book keeping is done in any of these accounts and reports are generated to determine the health
                        of your business.
                      </p>

                      <h2>Balance Sheet</h2>
                      <p>
                        This is generated for you in real-time as transactions are recorded on a day to day basis. It is the quikest health check
                        but also provides a breakdown of what actually was computed to determine the current relationship between the assets and liabilities
                        of the business.
                      </p>

                      <h2>Cash Flow Report</h2>
                      <p>
                        This statement is provides aggregate data regarding all cash inflows that a business receives from its ongoing operations and external
                        investment sources. It also includes all cash outflows that pay for business activities and investments during a given period.
                      </p>

                      <h2>Profit & Loss Analysis</h2>
                      <p>
                        Profit and loss (P&L) statement refers to a financial statement that summarizes the revenues, costs, and expenses incurred during a specified
                        period, usually a quarter or fiscal year. These records provide information about a company’s ability or inability to generate profit by increasing
                        revenue, reducing costs, or both. P&L statements are often presented on a cash or accrual basis. Company managers and investors use P&L statements to
                        analyze the financial health of a company.
                      </p>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
        </v-col>
        <v-col sm="12" md="12" lg="4" class="side-bg">
          <v-row>
            <v-col cols="12">
              <v-main>
                <router-view></router-view>
              </v-main>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <Footer/>
    </v-col>
  </v-row>
</template>

<script>
import HomeHeader from "@/modules/home/HomeHeader.vue";
import Footer from "@/modules/home/Footer.vue";

export default {
  name: "HomePage",
  components: { Footer, HomeHeader },
  data() {
    return {
      laptop: {
        backgroundImage: "url(" + require("@/assets/laptop.jpg") + ")",
      },
    };
  }
};
</script>

<style scoped>

.base{
  font-weight: bold;
  font-size: 45px;
}

.title{
  font-size: 20px;
  font-weight: bold;
  color: #1a1ada;
}

.side-bg{
  background-color: #f7f7f8;
  display: flex;
}

.font-fam{
  font-family: 'Helvetica', Arial;
}

.font-fam-2{
  font-family: 'Helvetica Neue'
}

.button{
  background: #065FDB;
  padding: 10px 15px;
  border-radius: 3px;
  color:white;
  font-weight: bold;
  cursor: pointer;
}

.button:hover{
  background: #1a1ada;
}

.section{
  margin-top: 30px;
}

.md-text{
  font-size: 18px;
  padding-top: 5px;
  font-weight: bold;
}

.img{
  height: 220px;
}

.columnHeight{
  height: 340px;
}

</style>
