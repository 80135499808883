<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <!--    :class="item.label === this.$route.params.id ? 'activeLink' : ''"-->

        <v-row>
          <v-col col lg="3" md="3" sm="12">
            <v-card class="mx-auto" tile>
              <v-list dense>
                <v-subheader>Settings</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item
                    class="text-decoration-none"
                    v-for="(item, i) in items"
                    :key="i"
                    :to="{ name: 'settings-ui', params: { id: item.name } }"
                    :value="item.active"
                    :class="item.name === tabSelector ? 'activeLink' : ''"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col col lg="9" md="9" sm="12">
            <v-card v-if="tabSelector === 'company-details'">
             <company-profile></company-profile>
            </v-card>
            <v-card v-if="tabSelector === 'roles-and-permissions'">
              <RolesAndPermissions></RolesAndPermissions>
            </v-card>
            <v-card v-if="tabSelector === 'user'">
              <users></users>
            </v-card>
            <v-card v-if="tabSelector === 'notifications'">
              <notifications></notifications>
            </v-card>
            <v-card v-if="tabSelector === 'invoice-settings'">
              <invoice-settings></invoice-settings>
            </v-card>
            <v-card v-if="tabSelector === 'tax'">
              <tax></tax>
            </v-card>
            <v-card v-if="tabSelector === 'units-of-measure'">
              <unit-of-measure></unit-of-measure>
            </v-card>
            <v-card v-if="tabSelector === 'discounts'">
              <discount></discount>
            </v-card>
            <v-card v-if="tabSelector === 'activity-logs'">
              <activity-logs></activity-logs>
            </v-card>
            <v-card v-if="tabSelector === 'license'">
           <license></license>
            </v-card>
<!--            <v-card v-if="tabSelector === 'currencies'">
              <currencies-info></currencies-info>
            </v-card>-->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RolesAndPermissions from "@/modules/dashboard/Settings/RolesAndPermissions/RolesAndPermissions";
import Users from "@/modules/dashboard/Settings/Users/Users";
import Notifications from "@/modules/dashboard/Settings/Notifications/Notifications";
import CompanyProfile from "@/modules/dashboard/Settings/CompanyProfile/CompanyProfile";
import InvoiceSettings from "@/modules/dashboard/Settings/InvoiceSettings/InvoiceSettings";
import Tax from "@/modules/dashboard/Settings/tax/Tax";
import UnitOfMeasure from "@/modules/dashboard/accounting/Settings/UnitOfMeasure/UnitOfMeasure";
import Discount from "@/modules/dashboard/accounting/Discount/Discount";
import ActivityLogs from "@/modules/dashboard/accounting/Settings/ActivityLogs/ActivityLogs";
import License from "@/modules/dashboard/Settings/license/License";



export default {
  name: "Settings",
  components: {
    License,
    ActivityLogs,
    Discount, UnitOfMeasure, InvoiceSettings, CompanyProfile, Notifications, Users, RolesAndPermissions ,Tax},
  data() {
    return {
      items: [
        {
          text: "Company Details",
          icon: "mdi-checkbox-blank-circle-outline",
          name: "company-details"
        },
        {
          text: "Roles and Permissions",
          icon: "mdi-checkbox-blank-circle-outline",
          name: "roles-and-permissions"
        },
        {
          text: "Users",
          icon: "mdi-checkbox-blank-circle-outline",
          name: "user"
        },
        {
          text: "Invoice settings",
          icon: "mdi-checkbox-blank-circle-outline",
          name: "invoice-settings"
        },
      /*  {
          text: "Currencies",
          icon: "mdi-checkbox-blank-circle-outline",
          name: "currencies"
        },*/
        {
          text: "Notifications",
          icon: "mdi-checkbox-blank-circle-outline",
          name: "notifications"
        },
        {
          text: "Tax settings",
          icon: "mdi-checkbox-blank-circle-outline",
          name: "tax"
        },
        {
          text: "Units of Measure",
          icon: "mdi-checkbox-blank-circle-outline",
          name: "units-of-measure"
        },
        {
          text: "Discounts",
          icon: "mdi-checkbox-blank-circle-outline",
          name: "discounts"
        },
        {
          text: "Activity Logs",
          icon: "mdi-checkbox-blank-circle-outline",
          name: "activity-logs"
        },
      ],
      tabSelector: this.$route.params.id
        ? this.$route.params.id
        : "company-details"
    };
  },
  mounted() {
    if(this.$userProfile.type==='Admin'){
      let data =   [{
        text: "license",
        icon: "mdi-checkbox-blank-circle-outline",
        name: "license"
      }]
      this.items = [...this.items, ...data]
    }
  }
};
</script>

<style scoped>
.activeLink {
  background-color: #e3eefa;
  color: #1976d2;
}
</style>
