<template>
<span>

  <v-card flat>
                <v-row class="mx-auto mt-8">
                  <v-col cols="8">
                     <v-btn
                         class="ma-2 text-decoration-none"
                         color="#065fdb"
                         dark
                         @click="newAdjustment"
                     >
                        <v-icon left>
                          mdi-import
                        </v-icon>
                        New Adjustment
                      </v-btn>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                        class="mr-3"
                        v-model="search"
                        v-on:keyup.enter="doSearch"
                        append-icon="mdi-magnify"
                        label="Search"
                        dense
                        outlined
                        hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-data-table
                    :headers="headers"
                    :items="salesItemDataObj"
                    :search="search"
                    :loading="tableLoader"
                    loading-text="Loading... please wait"
                    item-key="name"
                    class="elevation-1"
                    :page="page"
                    :items-per-page="items_per_page"
                    :server-items-length="server_items_length"
                    :options.sync="paginationMovement"
                >
                  <template v-slot:item.actions="{ item }">
<!--                    <v-icon small class="mr-2" @click="editAdjustment(item)">
                      mdi-pencil
                    </v-icon>-->
                    <v-icon v-if="item.reference" small @click="deleteAdjustment(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>

      <v-dialog v-model="adjustmentDialog" width="70%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Adjust Stock</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="adjustmentDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
           <adjust-stock-data ref="adjustmentComponent"></adjust-stock-data>
        </div>
      </v-card>
    </v-dialog>
 <ConfirmDialog ref="deleteInvoicePaymentTransaction"></ConfirmDialog>
</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AdjustStockData from "@/modules/dashboard/accounting/Stock/AdjustStockData";
import ConfirmDialog from "@/modules/commons/ConfirmDialog";

export default {
  name: "StockAdjustmentInfo",
  components: {ConfirmDialog, AdjustStockData},
  data(){
    return{
      stockTableData: [],
      search: "",
      tableLoader: false,
      salesItemDataObj: [],
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Date", value: "date" },
        { text: "Item", value: "item_name" },
        { text: "Adjustment Type", value: "adjustment_type" },
        { text: "Quantity", value: "quantity" },
        { text: "Unit Cost", value: "buying_price" },
        { text: "Amount", value: "amount" },
        { text: "Action", value: "actions", sortable: false, width: "7%" }
      ],
      json_fields: {},
      page: 1,
      items_per_page: 20,
      server_items_length: 0,
      paginationMovement: {},
      adjustmentDialog: false
    }
  },
  methods:{
    ...mapActions(
        [
          "doGetStockAdjustmentList",
          "deleteStockAdjustment",
          "doGetStockableItems"
        ]
    ),
    filterStockAdjustment(adjustData) {
      let objData = adjustData.data;

      let current_page = adjustData.current_page;
      this.page = current_page;
      let per_page = adjustData.per_page;
      this.items_per_page = per_page * 1;
      this.server_items_length = adjustData.total;

      this.tableLoader = false;

      let jsonObj = {};
      let arr = [];

      let reference = "";
      objData.forEach((list, i) =>{

        let _reference = "";

        if (reference !== list.reference) {
          reference = list.reference;
          _reference = reference;
        } else {
          _reference = "";
        }

        jsonObj = {
          key: (current_page - 1) * per_page + i + 1,
          date: list.date ? list.date : "",
          number: list.number,
          reference: _reference,
          item_name: list.item ? list.item.name : "",
          adjustment_type: list.adjustment_type,
          amount: (list.quantity * list.buying_price).toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          quantity: list.quantity
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          buying_price: list.buying_price
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),


        };
        arr.push(jsonObj);

      })

      this.salesItemDataObj = arr;
    },
    newAdjustment(){
      this.adjustmentDialog = true
    },
    doSearch() {
      this.tableLoader = true;
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search ? this.search : ""
      };
      this.doGetStockAdjustmentList(options);

    },
    editAdjustment(){

    },
   async deleteAdjustment(item){

      let title = `Are you sure you want to delete this stock adjustment ?  Doing this is not reversible`

      const agreed = await this.$refs.deleteInvoicePaymentTransaction.confirm(
          title,
          {
            color: "red",
            width: "480",
          }
      );

      if (!agreed) {
        return
      }
      await this.deleteStockAdjustment(item.reference);
    }
  },
  computed:{
    ...mapGetters(
        [
          "getAdjustStockListData",
          "getAdjustStockData",
          "getAdjustmentDelete",
          "getAdjustmentError"
        ]
    )
  },
  watch:{
    paginationMovement(obj) {
      this.tableLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        search: this.search ? this.search : ""
      };

      this.doGetStockAdjustmentList(options);
    },
    getAdjustStockListData(data){
      if(data){
        this.filterStockAdjustment(data);
      }
    },
    getAdjustmentDelete(data){
      if(data){
        this.successToast("Success", data.message)
        this.doSearch()
      }
    },
    getAdjustStockData(data){
      if(data){
        this.adjustmentDialog = false
        this.$refs.adjustmentComponent.buttonLoader = false
        this.$refs.adjustmentComponent.form = {
                                              quantity: null,
                                              item_number: null,
                                              buying_price: null,
                                              adjustment_type: null,
                                              storage_account_number: null,
                                              payment_method_number: null,
                                              notes: null,
                                            }
        this.successToast("Success", data.message)
        this.doSearch()
        this.doGetStockableItems();
      }
    },
    getAdjustmentError(data){
      if(data){
        this.errorToast("Error", data.data.message)
      }
    }

  }
}
</script>

<style scoped>

</style>