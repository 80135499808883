import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  balanceSheetData: null,
  bSTransactionsInfo: null,
  balanceSheetErrorResponse: null
};

const getters = {
  getBalanceSheetData: state => state.balanceSheetData,
  getBSTransactionsInfo: state => state.bSTransactionsInfo,
  getBalanceSheetErrorResponse: state => state.balanceSheetErrorResponse
};

const actions = {
  async doGetBalanceSheet({ commit }, data) {
    let start_date = data.start_date ? data.start_date : "";
    let end_date = data.end_date ? data.end_date : "";
    await axios
      .get(
        IP +
          "reports/balance-sheet?start_date=" +
          start_date +
          "&end_date=" +
          end_date
      )
      .then(response => {
        commit("setBalanceSheetData", response);
      })
      .catch(error => {
        commit("setBalanceSheetErrorMessage", error);
      });
  },
  async fetchBalanceSheetTransactions({ commit }, data) {
    await axios
        .get(IP + "transactions/under-account/" + data)
        .then(response => {
          commit("setBSTransactionsInfo", response);
        })
        .catch(error => {
          commit("setBalanceSheetErrorMessage", error);
        });
  },


};

const mutations = {
  setBalanceSheetData: (state, response) => {
    state.balanceSheetData = response.data;
  },
  setBSTransactionsInfo: (state, response) => {
    state.bSTransactionsInfo = response.data;
  },
  setBalanceSheetErrorMessage: (state, error) => {
    state.balanceSheetErrorResponse = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
