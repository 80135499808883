<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Bank Accounts </v-toolbar-title>
          </v-toolbar>

          <v-tabs v-model="activeTab">
            <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
            <v-tab href="#storage">
              <v-icon left>mdi-account</v-icon>
              All Bank Accounts
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-storage">
              <v-icon left>mdi-lock</v-icon>
              New Account
            </v-tab>

            <v-tab-item id="storage">
              <v-divider></v-divider>
              <v-card flat>
                <v-row class="mx-auto mt-8">
                  <v-col cols="8">
<!--                    <download-excel
                      style="color:#777777; width: 30%; margin-left: 2%; cursor: pointer;"
                      class="btn btn-default mt-3"
                      :data="storageAccountTableData"
                      :fields="json_fields"
                      worksheet="Expenses"
                      name="stock.xls"
                      >Download Excel
                      <v-icon color="#777777">
                        mdi-cloud-download
                      </v-icon>
                    </download-excel>-->
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      class="mr-3"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      dense
                      outlined
                      hide-details
                      v-on:keyup.enter="filterData"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-data-table
                  :headers="storage_account_headers"
                  :items="storageAccountTableData"
                  :search="search"
                  :loading="storageAccountStockLoader"
                  loading-text="Loading... please wait"
                  item-key="name"
                  class="elevation-1"
                  :page="page"
                  :items-per-page="items_per_page"
                  :server-items-length="server_items_length"
                  :options.sync="paginationStorageAccounts"
                >
                  <!--                  add a link on the customer name-->
                  <template #item.name="{ item }">
                    <router-link
                      class="storageAccountName"
                      :to="{
                        name: 'bank-statement',
                        params: { id: item.name },
                        query: { id: item.number }
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editStorageAccount(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteStorageAccount(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item id="new-storage">
              <v-divider></v-divider>

              <v-card flat>
                <v-row>
                  <v-col cols="5" class="mt-15 ml-6">
                    <p style="font-size: 20px;">Add Bank/Cash Account</p>
                    <storage-account
                      ref="storage_accounts_components"
                    ></storage-account>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="editStorageAccountDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Bank/Cash Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editStorageAccountDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <storage-account
            ref="storage_accounts_components"
            :editStorageAccountObj="editStorageAccountObj"
          ></storage-account>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import StorageAccount from "../commons/StorageAccount";
import { mapActions, mapGetters } from "vuex";
import router from "@/global/router";
export default {
  name: "StorageAccountList",
  components: { StorageAccount },
  data() {
    return {
      activeTab: "storage",
      search: null,
      storage_account_headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Name", value: "name" },
        { text: "Memo", value: "notes" },
        { text: "Balance", value: "balance" },
        { text: "Action", value: "actions", sortable: false, width: "7%" }
      ],
      page: 1,
      items_per_page: 10,
      server_items_length: 0,
      paginationStorageAccounts: {},
      storageAccountStockLoader: false,
      storageAccountTableData: [],
      json_fields: {
        Name: "name",
        Notes: "notes"
      },
      editStorageAccountDialog: false,
      editStorageAccountObj: {}
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  methods: {
    ...mapActions(["getStorageAccountData", "doDeleteStorageAccount"]),
    filterStorageAccount(raw_data) {
      let storageAccountDataList = raw_data.data;
      this.server_items_length = raw_data.total;
      this.storageAccountStockLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let storageAccountJsn = {};
      let storageAccountObj = [];

      for (let i = 0; i < storageAccountDataList.length; i++) {
        storageAccountJsn = {
          key: (current_page - 1) * per_page + i + 1,
          name: storageAccountDataList[i].full_name,
          notes: storageAccountDataList[i].notes,
          balance: this.transformNumbersToAccountingStandard(storageAccountDataList[i].balance),
          number: storageAccountDataList[i].number
        };
        storageAccountObj.push(storageAccountJsn);
      }
      this.storageAccountTableData = storageAccountObj;
    },
    editStorageAccount(item) {
      this.editStorageAccountObj = item;
      this.editStorageAccountDialog = true;
    },
    deleteStorageAccount(item) {
      let confirm_dele = confirm(
        "Are you sure you want to delete this customer?"
      );
      if (!confirm_dele) {
        return false;
      }
      this.doDeleteStorageAccount(item.number);
    },
    filterData() {

      this.storageAccountStockLoader = true;
      let options = {
        page: this.page,
        per_page: this.items_per_page,
        search: this.search,
      };

      this.getStorageAccountData(options);

    }
  },
  computed: {
    ...mapGetters([
      "getStorageAccountList",
      "getNewStorageAccountData",
      "getDeleteStorageAccount"
    ])
  },
  watch: {
    paginationStorageAccounts(obj) {
      this.storageAccountStockLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage
      };

      this.getStorageAccountData(options);
    },
    getStorageAccountList(storageAccountData) {
      this.filterStorageAccount(storageAccountData);
    },
    getNewStorageAccountData(storageInfo) {
      if (storageInfo) {
        this.$refs["storage_accounts_components"].addStorageLoader = false;
        this.$refs["storage_accounts_components"].form = {
          name: null,
          notes: null
        };
        this.successToast("Success", storageInfo.message);
        this.editStorageAccountDialog = false;

        let options = {
          page: this.page,
          per_page: this.items_per_page
        };

        this.getStorageAccountData(options);
      }
    },
    getDeleteStorageAccount(deleteData) {
      if (deleteData) {
        this.successToast("Success", deleteData.message);
        let options = {
          page: this.page,
          per_page: this.items_per_page
        };

        this.getStorageAccountData(options);
      }
    },
    activeTab(data) {
      router.push({ name: "storage-accounts", query: { type: data } });
    }
  }
};
</script>

<style scoped>
.storageAccountName {
  text-decoration: none;
}

.storageAccountName:hover {
  color: green;
  text-decoration: underline;
}
</style>
