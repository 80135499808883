var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-app-bar-nav-icon'),_c('v-toolbar-title',[_vm._v("Supplier ")])],1),_c('v-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{attrs:{"href":"#supplier"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v(" Supplier List ")],1),_c('v-tab',{attrs:{"href":"#new-supplier"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-lock")]),_vm._v(" New Supplier ")],1),_c('v-tab-item',{attrs:{"id":"supplier"}},[_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mx-auto mt-8"},[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.supplierTableData,"search":_vm.search,"loading":_vm.tableSupplierLoader,"loading-text":"Loading... please wait","page":_vm.page,"items-per-page":_vm.items_per_page,"server-items-length":_vm.server_items_length,"options":_vm.paginationSupplier},on:{"update:options":function($event){_vm.paginationSupplier=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"supplierName",attrs:{"to":{
                        name: 'supplier-details',
                        params: { id: item.name_url },
                        query: { sup: item.number }
                      }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.contacts",fn:function(ref){
                      var item = ref.item;
return [(item.contacts.length > 0)?_c('span',[_vm._v(" Email: ")]):_vm._e(),_vm._l((item.contacts),function(contact,index){return _c('a',{key:index,staticClass:"supplierName",attrs:{"href":'mailto:' + contact.contact}},[(contact.type === 'email')?_c('span',[_vm._v(_vm._s(contact.contact)+", ")]):_vm._e()])}),_c('br'),(item.contacts.length > 0)?_c('span',[_vm._v(" Phone: ")]):_vm._e(),_vm._l((item.contacts),function(contact,index){return _c('a',{key:'A' + index,staticClass:"supplierName",attrs:{"href":'tel:' + contact.contact}},[(contact.type === 'phone')?_c('span',[_vm._v(_vm._s(contact.contact)+", ")]):_vm._e()])})]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editSupplier(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteSupplier(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-tab-item',{attrs:{"id":"new-supplier"}},[_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-15 ml-6",attrs:{"cols":"5"}},[_c('p',{staticStyle:{"font-size":"20px"}},[_vm._v("Add Supplier")]),_c('add-supplier',{ref:"supplierComponents"})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"outlined":"","color":"success","bottom":"","left":"","absolute":"","shaped":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.supplierSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.supplierSnackbar),callback:function ($$v) {_vm.supplierSnackbar=$$v},expression:"supplierSnackbar"}},[_vm._v(" "+_vm._s(_vm.supplierErrorMessage)+" ")]),_c('v-dialog',{attrs:{"width":"40%"},model:{value:(_vm.editSupplierDialog),callback:function ($$v) {_vm.editSupplierDialog=$$v},expression:"editSupplierDialog"}},[_c('v-card',[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"95%","padding-bottom":"20px"}},[_c('v-row',[_c('v-card-title',[_vm._v("Edit Customer Info")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.editSupplierDialog = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('add-supplier',{ref:"supplierComponents",attrs:{"editSupplierObj":_vm.editSupplierObj}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }