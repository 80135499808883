<template>
    <v-dialog v-model="dialog" :max-width="width" persistent>
        <v-card>
            <v-card-title class="body-1">
                {{ title }}
            </v-card-title>
            <hr/>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="disagree"> Cancel</v-btn>
                <v-btn dark @click="agree" :color="color"> Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
/**
 * confirm action dialog usage
 * add component to view with a ref
 * <confirm-dialog ref="dialog" />
 * then call async method confirm eg. with async await
 *  const answer = await this.$refs.dialog.confirm(title, options)
 */
export default {
    props: {},
    data() {
        return {
            dialog: false,
            title: "Please confirm your action",
            color: "primary",
            width: 290,
            resolve: null,
            reject: null,
        };
    },
    computed: {},
    methods: {
        confirm(title, {color, width}) {
            this.dialog = true;
            this.title = title;
            this.color = color || "primary";
            this.width = width || 290;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        disagree() {
            this.resolve(false);
            this.dialog = false;
        },
    },
};
</script>
