var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"tile":""}},[_c('v-row',{staticClass:"mx-auto"},[_c('v-col',[_c('v-row',{staticClass:"mx-auto"},[_c('div',[_c('v-card-title',[_vm._v(_vm._s(_vm.cashFlow ? _vm.cashFlow : "Bank Statement"))]),_c('v-card-text',[_vm._v("Company: "+_vm._s(_vm.company_name))])],1),_c('v-spacer'),_c('span',[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"#242f7e","small":""},on:{"click":_vm.fileExport}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-pdf-box ")]),_vm._v(" Export PDF ")],1)],1)],1)],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"dense":"","readonly":"","placeholder":new Date().toISOString().substr(0, 10),"hint":"Start Date [ YYY-MM-DD ]","persistent-hint":"","clearable":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"dense":"","readonly":"","hint":"End Date [ YYY-MM-DD ]","persistent-hint":"","clearable":"","placeholder":new Date().toISOString().substr(0, 10)},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":"","origin":"center center","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #9e9e9e","height":"40px","color":"#777777"},attrs:{"light":"","tile":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-4"},[_vm._v(" mdi mdi-filter ")]),_vm._v(" Filter "),_c('v-icon',[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-card',[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.filterList),function(item,i){return _c('v-span',{key:i},[_c('v-list-item',{on:{"click":function($event){return _vm.filters(item.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1),((i + 1) % 3 === 0)?_c('v-divider'):_vm._e()],1)}),1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.bankStatementInfo,"search":_vm.search,"loading":_vm.tableBankStatementLoader,"loading-text":"Loading... please wait","item-key":"name","page":_vm.page,"items-per-page":_vm.items_per_page,"server-items-length":_vm.server_items_length,"options":_vm.paginationBankStatement,"footer-props":{
                  'itemsPerPageOptions': _vm.items_per_page_options,
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text':'Entries Per Page'
                }},on:{"update:options":function($event){_vm.paginationBankStatement=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
                var pagination = ref.pagination;
                var options = ref.options;
                var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"Entries Per Page","items-per-page-options":_vm.items_per_page_options,"showFirstLastPage":true,"firstIcon":"mdi-arrow-collapse-left","lastIcon":"mdi-arrow-collapse-right","prevIcon":"mdi-chevron-left","nextIcon":"mdi-chevron-right"},on:{"update:options":updateOptions}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }