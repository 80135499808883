<template>
  <div>




    <v-row class="mx-auto mt-8">
      <v-col cols="8">

      </v-col>

      <v-col cols="4">
        <v-text-field
          class="mr-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          v-on:keyup.enter="doSearch"
          dense
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>


    <v-data-table
        :headers="tableHeader"
        :items="generalTableData"
        :search="search"
        :loading="tableBoxLoader"
        loading-text="Loading... please wait"
        item-key="name"
        class="elevation-1"
        :page="page"
        :items-per-page="items_per_page"
        :footer-props="{
                      'itemsPerPageOptions': items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text':'Items per page'
                    }"
        :server-items-length="server_items_length"
        :options.sync="pagination"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <!--                     "Boxes "-->
        <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text= "Items per page"
            :items-per-page-options="items_per_page_options"
            :showFirstLastPage="true"
            firstIcon="mdi-arrow-collapse-left"
            lastIcon="mdi-arrow-collapse-right"
            prevIcon="mdi-chevron-left"
            nextIcon="mdi-chevron-right"
        />
      </template>

      <template v-slot:item.actions="{ item }">

        <v-tooltip bottom v-if="item.receipt_no">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                v-on="on"
                v-bind="attrs"
                class="mr-2"
                @click="editExpense(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          Edit Transaction
        </v-tooltip>


        <v-tooltip bottom v-if="item.receipt_no">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                v-on="on"
                v-bind="attrs"
                class="mr-2"
                @click="deleteExpense(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          Delete Transaction
        </v-tooltip>


        <v-tooltip bottom v-if="item.receipt_no">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-on="on"
                v-bind="attrs"
                class="mr-2"
                @click="downloadReceiptPdf(item)"
            >
              mdi-cash
            </v-icon>
          </template>
          Download Receipt
        </v-tooltip>

      </template>

    </v-data-table>



    <v-dialog v-model="editTransactionsDialog" width="70%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Transaction</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editTransactionsDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-transaction
            :account_type="account_type"
            :transactionEditData="transactionEditData"
            ref="addExpenseItems"
          ></add-transaction>
        </div>
      </v-card>
    </v-dialog>
    <ConfirmDialog ref="deleteInvoicePaymentTransaction"></ConfirmDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddTransaction from "@/modules/dashboard/accounting/commons/AddTransaction";
import ConfirmDialog from "@/modules/commons/ConfirmDialog";
import configs from "@/global/configs.json";
export default {
  name: "ListTransactions",
  components: {ConfirmDialog, AddTransaction },
  props: {
    account_type: String
  },
  data() {
    return {
      search: "",
      tableHeader: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount" },
        { text: "notes", value: "notes" },
        { text: "Date", value: "date" },
        { text: "Reference", value: "reference" },
        { text: "payment Method", value: "payment_method" },
        { text: "Account", value: "account" },
        { text: "Staff", value: "staff" },
        { text: "Action", value: "actions", width: "10%", sortable: false }
      ],
      generalTableData: [],
      tableBoxLoader:false,
      pagination: {},
      page: 1,
      items_per_page_options: [20, 50, 100, 500],
      items_per_page: 20,
      server_items_length: 0,
      expense_permission_items: [
        { text: "Select Permission", value: null },
        { text: "Everyone", value: "everyone" },
        { text: "view", value: "view" },
        { text: "edit", value: "edit" }
      ],
      expense: [
        {
          name: "Balance brought down from March, 2019",
          date: "01-04-2019",
          account_name: "Equity Bank (Ugx)",
          amount: "USh 39,843.99",
          status: "Paid",
          attachment: "word.docx",
          action: "Edit"
        },
        {
          name: "Monthly data",
          date: "01-06-2020",
          account_name: "Equity Bank (Ugx)",
          amount: "USh 30,000.00",
          status: "Paid",
          attachment: "",
          action: "Edit"
        }
      ],
      json_fields: {
        Date: "date",
        Name: "name",
        Amount: "amount",
        notes: "notes"
      },
      editTransactionsDialog: false,
      transactionEditData: {},
      downloadLink: configs.SERVER_IP + "out-pdf/receipt?receipt-no=",
    };
  },
  mounted() {

  },
  methods: {
    ...mapActions(
        [
            "fetchTransactions",
            "deleteTransactionItems"
        ]
    ),
    doSearch() {
      this.tableBoxLoader = true;
      let options = {
        page: 1,
        per_page: this.items_per_page,
        type: this.account_type,
        search: this.search ? this.search : ""
      };
      this.fetchTransactions(options);

    },
    editExpense(items) {
      this.editTransactionsDialog = true;
      this.transactionEditData = items;
    },
    async deleteExpense(item) {
      if(item) {
        let title = `Are you sure you want to delete this transaction ?  Doing this is not reversible`

        const agreed = await this.$refs.deleteInvoicePaymentTransaction.confirm(
            title,
            {
              color: "red",
              width: "480",
            }
        );

        if (!agreed) {
          return
        }

        await this.deleteTransactionItems(item.number);
      }
    },
    downloadReceiptPdf(data) {
      let receiptNumber = data.receipt_no
      let fileUrl = this.downloadLink + receiptNumber;

      const pdfObj = document.createElement("a");
      pdfObj.href = fileUrl;
      pdfObj.click();
    },
    filterTransaction(rowData) {

      let transactionData = rowData.data;
      this.server_items_length = rowData.total;

      let current_page = rowData.current_page;
      this.page = current_page;
      let per_page = rowData.per_page;
      this.items_per_page = per_page * 1;


      this.tableBoxLoader = false;

      this.tableStockLoader = false;
      let transactionJsn = {};
      let transactionObj = [];

      transactionData.forEach((lst,i) => {
        transactionJsn = {
          key: i + 1,
          name: lst.name,
          amount: lst.amount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          notes: lst.notes,
          date: lst.date,
          reference: lst.reference,
          payment_method: lst.payment_method_name,
          number: lst.number,
          locked: lst.locked,
          receipt_no: lst.receipt_no,
          staff: (lst.user ? lst.user.first_name ? lst.user.first_name : "" :"") +" "+ (lst.user ? lst.user.second_name ? lst.user.second_name : "" :""),
          account: lst.account ? lst.account.name ? lst.account.name : "" : ""
        };
        transactionObj.push(transactionJsn);
      })


      this.generalTableData = transactionObj;
    }
  },
  computed: {
    ...mapGetters([
      "getStatusExpenseResponse",
      "getTransactionErrorMessage",
      "getTransactionsDataSet",
      "getTransactionData",
      "getDeleteTransactionInfo",
      "getTransactionError"
    ])
  },
  watch: {
    pagination(obj) {
      this.tableBoxLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        type: this.account_type,
        search: this.search ? this.search : ""
      };
      this.fetchTransactions(options);
    },
    getTransactionErrorMessage(expenseError) {
      console.log(expenseError);
    },
    getTransactionsDataSet(transactionData) {
      this.filterTransaction(transactionData);
    },
    getTransactionData(transactionData) {
      if (transactionData) {
        this.editTransactionsDialog = false;
        this.$refs["addExpenseItems"].transactionLoader = false;
        this.$refs["addExpenseItems"].form = {
          name: null,
          amount: null,
          notes: null,
          date: new Date().toISOString().substr(0, 10),
          storage_account_number: null,
          account_number: null,
          payment_method: null,
          reference: null
        };
        this.doSearch()
      }
    },
    getDeleteTransactionInfo(data){
      if(data){
        this.doSearch()
        this.successToast("Success", data.message)
      }
    },
    getTransactionError(data){
      if(data){
        this.errorToast("Error", data.message)
      }
    }
  }
};
</script>

<style scoped></style>
