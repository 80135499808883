<template>
  <div>
    <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>
    <form ref="form" @submit.prevent="uploadFile">
      <v-file-input
        class="mt-8"
        outlined
        v-model="form.attachment"
        dense
        show-size
        truncate-length="20"
        prepend-icon=""
        type="file"
        label="file"
        prepend-inner-icon="mdi-paperclip"
        required
      ></v-file-input>

      <div class="text-right">
        <v-spacer></v-spacer>
        <v-btn
          class="mt-4"
          :loading="spinner"
          color="#242f7e"
          dark
          type="submit"
        >
          <v-icon left>
            mdi-upload
          </v-icon>
          Upload
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UploadFile",
  props: {
    fileType: String
  },
  data() {
    return {
      errorMessage: null,
      spinner: null,
      form: {
        attachment: null
      }
    };
  },
  methods: {
    ...mapActions(["doUploadFile"]),
    uploadFile() {
      this.errorMessage = "";
      this.spinner = true;
      const formData = new FormData();
      formData.append("file", this.form.attachment);
      formData.append("type", this.fileType);
      this.doUploadFile(formData);
    }
  },
  computed: {
    ...mapGetters(
        [
            "getUploadError"
        ]
    )
  },
  watch: {
    getUploadError(uploadError) {
      if (uploadError) {
        this.spinner = false;
        this.errorMessage = uploadError.message;
        this.errorToast(uploadError.message);
      }
    }
  }
};
</script>

<style scoped></style>
