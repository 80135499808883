import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  stock: null,
  stockAssemblyItemsData: null,
  newStockAssembly: null,
  newStockDataInfo: null,
  selectedAssemblyItemsData: null,
  stockError: null
};

const getters = {
  getStock: state => state.stock,
  getStockAssemblyItemsData: state => state.stockAssemblyItemsData,
  getSelectedAssemblyItemsData: state => state.selectedAssemblyItemsData,
  getNewStockAssembly: state => state.newStockAssembly,
  getNewStockDataInfo: state => state.newStockDataInfo,
  getStockError: state => state.stockError
};

const actions = {
  async doGetStockItemsList({ commit }, data) {
    await axios
      .get(
        IP + "pos/stock" +
                  "?page=" + data.page +
                  "&per_page=" + data.per_page+
                  "&search=" + data.search
      )
      .then(response => {
        commit("setStockData", response.data);
      })
      .catch(error => {
        commit("setStockError", error);
      });
  },
  async fetchAssemblyItemsList({ commit }) {
    await axios
      .get(
        IP + "items/assembly-items")
      .then(response => {
        commit("setStockAssemblyItemsData", response.data);
      })
      .catch(error => {
        commit("setStockError", error);
      });
  },
  async saveStockedAssemblyItemData({ commit }, data) {
    await axios
      .post(
        IP + "pos/new-stock-assembly", data)
      .then(response => {
        commit("setNewStockAssembly", response.data);
      })
      .catch(error => {
        commit("setStockError", error);
      });
  },
  async fetchStockEditInfo({ commit }, data) {
    await axios
      .get(
        IP + "pos/stock-item-details/"+data)
      .then(response => {
        commit("setNewStockDataInfo", response.data);
      })
      .catch(error => {
        commit("setStockDataInfoError", error);
      });
  },
  async fetchStockedAssemblyItems({ commit }, data) {
    await axios
      .get(
        IP + "items/assembly-item-parts/"+data.number+"?quantity="+data.quantity)
      .then(response => {
        commit("setSelectedAssemblyItemsData", response.data);
      })
      .catch(error => {
        commit("setStockError", error);
      });
  }
};

const mutations = {
  setStockData: (state, response) => {
    state.stock = response;
  },
  setStockAssemblyItemsData: (state, response) => {
    state.stockAssemblyItemsData = response;
  },
  setNewStockAssembly: (state, response) => {
    state.newStockAssembly = response;
  },
  setNewStockDataInfo: (state, response) => {
    state.newStockDataInfo = response;
  },
  setSelectedAssemblyItemsData: (state, response) => {
    state.selectedAssemblyItemsData = response;
  },
  setStockError: (state, error) => {
    state.stockError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
