<template>
  <v-card flat style="width: 70%; margin-top: 13%;" class="mx-auto">
    <v-card-text>
      <v-card-title class="justify-center"><b>BASE</b></v-card-title>

      <h3 class="text-center">Let's get you signed in</h3>

      <v-card-subtitle class="text-center">
        Enter your email and we'll find your account.
      </v-card-subtitle>

      <v-form ref="form" @submit.prevent="doReset" lazy-validation>
        <v-alert :type="responseType" v-if="formLoginErrorMessage">
          {{ formLoginErrorMessage }}
        </v-alert>
        <v-card-subtitle>
          <v-text-field
            outlined
            v-model="form.email"
            :rules="[rules.email_required, rules.email_pattern]"
            label="Email"
            required
            prepend-inner-icon="mdi-email-outline"
            type="email"
            dense
            single-line
          ></v-text-field>

          <div style="text-align: center;">
            <router-link
              :to="{ name: 'set-password' }"
              class="text-decoration-none"
            >
              Already have reset code
            </router-link>
          </div>

          <v-btn
            class="mx-auto requestPasswordButton"
            block
            large
            :loading="resetSpinner"
            color="#0000ff"
            dark
            type="submit"
          >
            Continue
          </v-btn>

          <v-row class="mx-auto">
            <p>
              <router-link :to="{ name: 'login' }" class="text-decoration-none">
                <b>Login</b>
              </router-link>
            </p>

            <v-spacer> </v-spacer>

            <p>
              Do not have an account?
              <router-link
                :to="{ name: 'register' }"
                class="text-decoration-none"
              >
                Get an account
              </router-link>
            </p>
          </v-row>
        </v-card-subtitle>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "../../../global/router";
export default {
  name: "RequestResetPassword",
  data() {
    return {
      show1: false,
      formLoginErrorMessage: null,
      responseType: null,
      form: {
        email: null
      },
      resetSpinner: false,
      rules: {
        email_required: value => !!value || "Email is Required.",
        email_pattern: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Please enter valid email.";
        }
      }
    };
  },
  methods: {
    ...mapActions([
      "requestResetPassword",
      "getUserProfile",
      "doGetCompanyProfile"
    ]),
    doReset() {
      this.formLoginErrorMessage = "";

      if (this.form.email == null || this.form.email === "") {
        this.formLoginErrorMessage = "Email is Required";
        this.responseType = "error";
        return false;
      }

      this.resetSpinner = true;

      this.requestResetPassword(this.form);
    }
  },
  computed: {
    ...mapGetters([
      "getUserProfileData",
      "getRequestPassword",
      "getCompanyData",
      "getLoginMessage"
    ])
  },
  watch: {
    getLoginMessage(error) {
      this.formLoginErrorMessage = error.message;
      this.responseType = "error";
      this.resetSpinner = false;
    },

    getCompanyData(company) {
      if (company != null) {
        router.push("/dashboard");
        location.reload();
      }
    },
    getRequestPassword(requestPassData) {
      this.formLoginErrorMessage = requestPassData.message;
      this.responseType = "success";
      this.resetSpinner = false;
    }
  }
};
</script>

<style scoped>
.requestPasswordButton {
  margin-top: 5%;
  margin-bottom: 7%;
  height: 10px;
  font-size: 16px;
  font-weight: bold;
}
</style>
