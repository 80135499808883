<template>
  <v-card flat>

    <v-row class="mx-auto mt-8">
      <v-col cols="8">
      </v-col>

      <v-col cols="4">
        <v-text-field
            class="mr-3"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            v-on:keyup.enter="doSearch"
            dense
            outlined
            hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
        :headers="itemHeaders"
        :items="itemListDataTable"
        :search="search"
        :loading="tableItemLoader"
        loading-text="Loading... please wait"
        item-key="name"
        class="elevation-1"
        :page="page"
        :items-per-page="items_per_page"
        :server-items-length="server_items_length"
        :options.sync="pagination"
        :footer-props="{
                    itemsPerPageOptions: items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Items per page'
                  }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="Items per page"
            :items-per-page-options="items_per_page_options"
            :showFirstLastPage="true"
            firstIcon="mdi-arrow-collapse-left"
            lastIcon="mdi-arrow-collapse-right"
            prevIcon="mdi-chevron-left"
            nextIcon="mdi-chevron-right"
        />
      </template>

      <template v-slot:item.item_type="{ item }">
        <b>{{item.item_type}}</b>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
            v-if="item.status !=='inactive'"
            class="ma-2 text-decoration-none"
            outlined
            small
            color="red"
            :loading="deactivateSpinner === item.number"
            @click="deactivateLicence(item)"
        >
          Invalidate
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "LicenseList",
  data(){
    return{
      itemListDataTable: [],
      search: null,
      tableItemLoader: false,
      items_per_page: 20,
      page: 1,
      server_items_length: 0,
      itemsDialog: false,
      deactivateSpinner:false,
      itemHeaders: [
        {
          width: "5%",
          text: "#",
          align: "start",
          value: "key"
        },
        { text: "Expiry Date", value: "expiry_date" },
        { text: "Code", value: "code" },
        { text: "Status", value: "status" },
        { text: "Owner", value: "owner" },
        { text: "Action", value: "actions", width: "10%", }
      ],
      pagination: {},
      items_per_page_options: [20, 50, 100, 150],
      itemsDataList: {},
    }
  },
  methods:{
    ...mapActions(
        [
            "doLicenseData",
            "doDeactivateLicense"
        ]
    ),
    doSearch() {
      this.tableItemLoader = true;
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search
      };
      this.doLicenseData(options)
    },
    editItem(){

    },
    deleteItem(){

    },
    filterLicenseListData(raw_data) {
      //data

      let itemListData = raw_data.data;
      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;
      this.server_items_length = raw_data.total;
      this.tableItemLoader = false;

      let itemListJsn = {};
      let itemListObj = [];


      itemListData.forEach((list, i) => {
        itemListJsn = {
          key: (current_page - 1) * per_page + i + 1,
          number:list.number ? list.number : "",
          code:list.code ? list.code : "",
          expiry_date:list.expiry_date ? list.expiry_date : "",
          status:list.status ? list.status : "",
          owner:list.company ? list.company.company_name : "",
        };
        itemListObj.push(itemListJsn);
      })

      this.itemListDataTable = itemListObj;
    },
    deactivateLicence(item){
     if(item){
       this.deactivateSpinner = item.number
       this.doDeactivateLicense({code: item.code})
     }
    }

  },
  computed:{
...mapGetters(
    [
        "getLicenseListData",
        "getDeactivateLicense",
        "getLicenseError"
    ]
)
  },
  watch:{
    pagination(obj) {
      this.tableItemLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        search: this.search
      };
      this.doLicenseData(options);
    },
    getLicenseListData(data){
      if(data){
        this.filterLicenseListData(data);
      }
    },
    getDeactivateLicense(data){
      if(data){
        this.deactivateSpinner = false
        this.doSearch()
        this.successToast("Success", data.message)
      }
    },
    getLicenseError(data){
      if(data){
        this.deactivateSpinner = false
        this.errorToast("Error", data.message)
      }
    }
  }
}
</script>

<style scoped>

</style>