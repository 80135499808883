var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Users")])],1),_c('v-tabs',[_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v(" Users ")],1),(_vm.checkLicenseStatus())?_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-lock")]),_vm._v(" New User ")],1):_vm._e(),_c('v-tab-item',[_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mx-auto mt-8"},[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.innerUsersTableData,"search":_vm.search,"loading":_vm.tableInnerUsersLoader,"loading-text":"Loading... please wait","page":_vm.page,"items-per-page":_vm.items_per_page,"server-items-length":_vm.server_items_length,"options":_vm.paginationInnerUsers},on:{"update:options":function($event){_vm.paginationInnerUsers=$event}},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
var item = ref.item;
return _vm._l((item.roles),function(roles,index){return _c('span',{key:index},[_c('v-row',[_c('span',[_vm._v(" "+_vm._s(roles.name)+" "),(roles.pivot.active === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(roles.name)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.revertRoll(roles, item.number)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-block-helper ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Revert Role")])]):_vm._e(),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"indigo darken-3","outlined":"","small":""}},[_vm._v(" "+_vm._s(roles.pivot.active === 1 ? "Active" : "Inactive")+" ")])],1)])],1)})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDiscount(item)}}},[_vm._v(" mdi-pencil ")])]}}])})],1)],1),_c('v-tab-item',[_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto mt-10",attrs:{"cols":"8"}},[_c('new-user',{ref:"users_components"})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }