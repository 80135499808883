<template>
  <span>
    <v-alert type="error" v-if="formChartErrorMessage">
      {{ formChartErrorMessage }}
    </v-alert>
    <form ref="form" @submit.prevent="saveChartOfAccount">
      <v-autocomplete
        outlined
        v-model="accountType"
        :items="accountItemList"
        item-value="id"
        color="pink"
        label="Account Type"
        :disabled="disableItemType"
        dense
      ></v-autocomplete>

      <v-text-field
        v-model="form.name"
        label="Account Name"
        outlined
        dense
        required
      ></v-text-field>

      <v-text-field
        v-model="form.code"
        label="Account Code"
        outlined
        dense
      ></v-text-field>

      <v-row>
        <v-col cols="4">
          <v-checkbox
            v-if="toggleSubItems"
            v-model="showSubCategory"
            class="shrink mr-0 mt-0 float-left"
            hide-details
            label="Sub account of"
            id="sub_account_id"
          ></v-checkbox>
        </v-col>

        <v-col cols="8">
          <v-select
            outlined
            v-if="showSubCategory"
            v-model="form.super_account"
            :items="newSubAccountData"
            item-value="id"
            color="pink"
            label="Super Account"
            dense
          ></v-select>
        </v-col>
      </v-row>

      <v-textarea
        v-model="form.description"
        label="Description"
        outlined
        rows="2"
        dense
      ></v-textarea>

      <div class="text-right">
        <v-btn
          block
          class="companyButton mt-4"
          :loading="chartSpinner"
          color="#0000ff"
          dark
          type="submit"
        >
          {{ editedChartOfAccountInfo ? "Update" : "Save" }}
        </v-btn>
      </div>
    </form>

    <!--Account dialog-->
    <v-dialog v-model="addSuperAccountDialog" width="35%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Account</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addSuperAccountDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <add-account
            :account_type="accountType"
            ref="item_components"
          ></add-account>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddAccount",
  props: {
    account_type: String,
    editedChartOfAccountInfo: {},
    account_type_list:{}
  },
  data() {
    return {
      formChartErrorMessage: null,
      accountItemList: [],
      chartSpinner: false,
      showSubCategory: false,
      newSubAccountData: null,
      disableItemType: false,
      addSuperAccountDialog: false,
      accountFiltered: [],
      form: {
        name: null,
        code: null,
        description: null,
        super_account: null,
        number: null
      },
      accountType: this.account_type,
      toggleSubItems: false
    };
  },
  mounted() {
    this.doGetAccountItems();

    if (this.editedChartOfAccountInfo) {
      this.getCharOfAccountsEditData(this.editedChartOfAccountInfo.number);
    }
    this.checkAccountListing()
  },
  computed: {
    ...mapGetters([
      "getAccountItems",
      "getChartOfAccountsData",
      "getChartOfAccountsErrorMessage",
      "getSubAccount",
      "getEditChartOfAccountData"
    ])
  },
  methods: {
    ...mapActions([
      "saveChartOfAccounts",
      "doGetAccountItems",
      "fetchSubAccountData",
      "getCharOfAccountsEditData"
    ]),
    getAccountItemList(accountData) {
      let account = accountData;
      let accObj = [];
      let accountJson = {};

      account.forEach(list => {
        accountJson = {
          text: list.account_type,
          id: list.ref_code
        };

        if (this.account_type === list.ref_code) {
          this.disableItemType = true;
          this.accountType = list.ref_code;
          this.toggleSubItems = true;
        }

        if(this.accountFiltered.length > 0){
          this.accountFiltered.forEach(lst => {
            if(lst === list.ref_code){
              accObj.push(accountJson);
            }
          })
        }else {
          accObj.push(accountJson);
        }


      })


      this.accountItemList = accObj;
    },
    saveChartOfAccount() {
      this.formChartErrorMessage = "";

      if (this.accountType == null || this.accountType === "") {
        this.formChartErrorMessage = "Choose account type ";
        return false;
      }

      if (this.form.name == null || this.form.name === "") {
        this.formChartErrorMessage = "Account name is missing";
        return false;
      }

      this.chartSpinner = true;

      let accountData = this.form;
      accountData.ref_code = this.accountType;


      this.saveChartOfAccounts(accountData);
    },
    fetchSubAccountList() {
      this.fetchSubAccountData({type:this.accountType,number:this.form.number ? this.form.number : ""});
    },
    subAccountJsonData(subAccountData) {
      let account = subAccountData;
      let accountObj = [];
      let accountJson = {};
      for (let i = 0; i < account.length; i++) {
        accountJson = {
          text: account[i].full_name,
          id: account[i].number
        };
        accountObj.push(accountJson);
      }

      this.newSubAccountData = accountObj;
    },
    addSuperAccount() {
      this.addSuperAccountDialog = true;
    },
    checkAccountListing(){
      if(this.account_type_list){
       this.accountFiltered =  this.account_type_list
      }
    }
  },
  watch: {
    account_type_list(){
      this.checkAccountListing()
    },
    getAccountItems(accountData) {
      this.getAccountItemList(accountData);
    },

    getChartOfAccountsErrorMessage(error) {
      if (error) {
        this.chartSpinner = false;
        this.errorToast("Error", error.message);
      }
    },
    accountType() {
      this.toggleSubItems = true;
      // this.showSubCategory = false;
      this.fetchSubAccountList();
    },
    getSubAccount(subAccountData) {
      this.subAccountJsonData(subAccountData);
    },
    showSubCategory(isShow) {
      if (isShow) {
        //this.form.sub_account = null;
        this.fetchSubAccountList();
      }
    },
    account_type(value) {
      this.accountType = value;
    },
    editedChartOfAccountInfo() {
      this.getCharOfAccountsEditData(this.editedChartOfAccountInfo.number);
    },
    getEditChartOfAccountData(chartOfAccountToEdit) {
      if (chartOfAccountToEdit) {
        this.accountType = chartOfAccountToEdit.ref_code;
        this.form.name = chartOfAccountToEdit.name;
        this.form.code = chartOfAccountToEdit.code;
        this.form.description = chartOfAccountToEdit.description;
        this.form.number = chartOfAccountToEdit.number;

        this.toggleSubItems = true;
        this.showSubCategory = chartOfAccountToEdit.editable_super_account;
        if(chartOfAccountToEdit.editable_super_account) {
          this.form.super_account = chartOfAccountToEdit.super_account;
        }
      }
    },
  }
};
</script>

<style scoped></style>
