<template>
<span>
   <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Depreciation</v-toolbar-title>
          </v-toolbar>

          <v-tabs v-model="activeTab">
            <v-tab href="#depreciation">
              <v-icon left>mdi-account</v-icon>
              Depreciation List
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-depreciation">
              <v-icon left>mdi-lock</v-icon>
             Add Depreciation Item
            </v-tab>

            <v-tab-item id="depreciation">
              <v-divider></v-divider>

              <depreciation-table ref="depreciation_content"></depreciation-table>

            </v-tab-item>

            <v-tab-item id="new-depreciation">
              <v-divider></v-divider>

              <v-row class="mx-auto">
                <v-col cols="6">
                     <new-depreciation ref="new_depreciation_content"></new-depreciation>
                </v-col>
              </v-row>


            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>



  </v-container>
</span>
</template>

<script>
import router from "@/global/router";
import DepreciationTable from "@/modules/dashboard/accounting/Depreciation/DepreciationTable";
import {mapGetters} from "vuex";
import NewDepreciation from "@/modules/dashboard/accounting/Depreciation/NewDepreciation";

export default {
  name: "Depreciation",
  components: {NewDepreciation, DepreciationTable},
  data(){
    return{
      activeTab: "depreciation",
    }
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  computed:{
    ...mapGetters(
        [
            "getDepreciationError",
            "getNewDepreciationData",
            "getDeleteDepreciationData",
            "getEditDepreciationData",
        ]
    )
  },
  watch:{
    activeTab(data) {
      router.push({ name: "depreciation", query: { type: data } });
    },
    getDepreciationError(error){
      if(error){
        this.$refs["new_depreciation_content"].buttonLoader = false
        this.$refs["depreciation_content"].tableLoader = false
        this.errorToast("Error", error.message)
      }
    },
    getNewDepreciationData(data){
      if(data){
        this.$refs["depreciation_content"].filterDepreciationInfo()
        this.$refs["new_depreciation_content"].buttonLoader = false
        this.$refs["new_depreciation_content"].form = {
          account_number:null,
          max_number_of_years:null,
          rate:null,
        }
         this.successToast("Success", data.message)
      }
    },
    getDeleteDepreciationData(del){
    if(del){
      this.successToast("Success", del.message)
      this.$refs["depreciation_content"].activeIndex = undefined
      this.$refs["depreciation_content"].filterDepreciationInfo()
    }
  },
    getEditDepreciationData(editData){
      if(editData){
        editData = editData.data
        this.successToast("Success", editData.message)
        this.$refs["depreciation_content"].depreciationDialog = false
        this.$refs["depreciation_content"].filterDepreciationInfo()
        this.$refs["depreciation_content"].stopButtonLoader()
      }
    },

  }
}
</script>

<style scoped>

</style>