import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  rolesAndPermission: null,
  newRolesAndPermission: null,
  rolesAndPermissionList: null,
  editRolesAndPermission: null,
  updateRolesAndPermission: null,
  revertRolesAndPermissionList: null,
  rolesAndPermissionError: null
};

const getters = {
  getRolesAndPermission: state => state.rolesAndPermission,
  getNewRolesAndPermission: state => state.newRolesAndPermission,
  getRolesAndPermissionList: state => state.rolesAndPermissionList,
  getEditRolesAndPermission: state => state.editRolesAndPermission,
  getUpdateRolesAndPermission: state => state.updateRolesAndPermission,
  getRevertRolesAndPermissionList: state => state.revertRolesAndPermissionList,
  getRolesAndPermissionError: state => state.rolesAndPermissionError
};

const actions = {
  async getRolesList({ commit }) {
    await axios
      .get(IP + "company/roles/permissions")
      .then(response => {
        commit("setRolesAndPermission", response);
      })
      .catch(error => {
        commit("setRolesAndPermissionRoles", error);
      });
  },
  async doSaveUserRolesAndPermissions({ commit }, data) {
    await axios
      .post(IP + "company/roles/new", data)
      .then(response => {
        commit("setNewRoleData", response);
      })
      .catch(error => {
        commit("setRolesAndPermissionRoles", error);
      });
  },
  async fetchRoleInfo({ commit }, data) {
    await axios
      .get(IP + "company/roles/details/" + data)
      .then(response => {
        commit("setEditRoleData", response);
      })
      .catch(error => {
        commit("setRolesAndPermissionRoles", error);
      });
  },
  async updateRoleAndPermission({ commit }, data) {
    await axios
      .post(IP + "company/roles/update/", data)
      .then(response => {
        commit("setUpdateRoleData", response);
      })
      .catch(error => {
        commit("setRolesAndPermissionRoles", error);
      });
  },
  async getUserRolesAndPermissionsList({ commit }, data) {
    await axios
      .get(
        IP + "company/roles?page=" + data.page + "&per_page=" + data.per_page
      )
      .then(response => {
        commit("setRolesAndPermissionList", response);
      })
      .catch(error => {
        commit("setRolesAndPermissionRoles", error);
      });
  },
  async doRevertRole({ commit }, data) {
    await axios
      .post(
        IP +
          "company/roles/remove-role?role_number=" +
          data.role_number +
          "&user_number=" +
          data.user_number
      )
      .then(response => {
        commit("setRevokeRole", response);
      })
      .catch(error => {
        commit("setRolesAndPermissionRoles", error);
      });
  }
};

const mutations = {
  setRolesAndPermission: (state, response) => {
    state.rolesAndPermission = response.data;
  },
  setNewRoleData: (state, response) => {
    state.newRolesAndPermission = response.data;
  },
  setEditRoleData: (state, response) => {
    state.editRolesAndPermission = response.data;
  },
  setUpdateRoleData: (state, response) => {
    state.updateRolesAndPermission = response.data;
  },
  setRolesAndPermissionList: (state, response) => {
    state.rolesAndPermissionList = response.data;
  },
  setRevokeRole: (state, response) => {
    state.revertRolesAndPermissionList = response.data;
  },
  setRolesAndPermissionRoles: (state, error) => {
    state.rolesAndPermissionError = error.response
      ? error.response.data
      : "Error: Network Error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
