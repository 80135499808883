<template>
  <span>
    <form ref="form" @submit.prevent="payInvoice">
      <v-row class="mx-auto">
        <v-col>
          <v-alert type="error" v-if="customerPaymentError">
            {{ customerPaymentError }}
          </v-alert>

          <v-autocomplete
            outlined
            v-model="form.profile_number"
            :items="customerDataList"
            item-value="id"
            color="#1976d2"
            label="Customer / supplier"
            dense
            clearable
            append-outer-icon="mdi-plus"
            @click:append-outer="addCustomerModel"
            @change="filterCustomerInvoice"
          ></v-autocomplete>

          <v-autocomplete
            outlined
            v-model="invoiceNumber"
            :items="invoiceDataList"
            item-value="id"
            color="#1976d2"
            label="Invoices"
            dense
            hide-details
            clearable
            :loading="invoiceLoader"
            @change="getInvoicesInfo"
          ></v-autocomplete>
          <small class="ml-2" style="color: red;" v-if="invoiceErrorResp">{{
            invoiceErrorResp
          }}</small>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          style="background-color: #0000ff; color: #ffffff"
          :disabled="disabledButton"
          block
          type="submit"
        >
          Pay Invoice
        </v-btn>
      </v-card-actions>
    </form>

    <!--customer dialog-->
    <v-dialog v-model="addCustomerDialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 13px;" class="mx-auto">
          <v-row>
            <v-card-title>Add Customer</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addCustomerDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
           <new-profile ref="add_customer_info"></new-profile>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog v-model="payInvoiceDialog" width="40%">
      <v-card>
        <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Pay Invoice</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payInvoiceDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <pay-invoice-bill
            ref="paymentComponents"
            :invoiceInfo="invoiceDataDetails"
            receiptType="Invoice"
          ></pay-invoice-bill>
        </div>
      </v-card>
    </v-dialog>



  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PayInvoiceBill from "@/modules/dashboard/accounting/commons/PayInvoiceBill";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";
export default {
  name: "NewCustomerPayments",
  components: {NewProfile, PayInvoiceBill },
  data() {
    return {
      customerPaymentError: null,
      addCustomerDialog: false,
      customerDataList: [],
      invoiceDataList: [],
      invoiceLoader: false,
      form: {
        profile_number: null
      },
      payInvoiceDialog: false,
      invoiceErrorResp: null,
      invoiceNumber: null,
      fullInvoiceDataList: {},
      invoiceDataDetails: {},
      disabledButton: true
    };
  },
  mounted() {
    this.fetchProfiles();
    if(this.$route.query.prof){
      this.form.profile_number = this.$route.query.prof
      this.filterCustomerInvoice()
    }
  },
  methods: {
    ...mapActions(
        [
      "fetchProfiles",
      "fetchCustomerSupplierBillInvoices",
      "getInvoiceBillBalance"
      ]
    ),
    addCustomerModel() {
      this.addCustomerDialog = true;
    },
    filterCustomerList(customerList) {

      let dataObj = [{text: "Select profiles", id: null, disabled: true}];
      let dataJson = {};
      customerList.forEach(list =>{
        dataJson = {
          text: list.name,
          id: list.number,
          disabled: false
        };
        dataObj.push(dataJson);
      })

      this.customerDataList = dataObj;
    },
    filterCustomerInvoice() {
      this.invoiceLoader = true;
      this.invoiceErrorResp = null;
      let data = {
        path: "invoice/due/" + this.form.profile_number
      };
      this.fetchCustomerSupplierBillInvoices(data);
    },
    doFilterBillList(invoiceDataInfo) {
      this.invoiceLoader = false;
      this.fullInvoiceDataList = invoiceDataInfo;

      if (invoiceDataInfo.length === 0) {
        this.disabledButton = true;
        this.invoiceErrorResp = "No invoices";
      }

      let dataObj = [{ text: "Select Invoice", id: null, disabled: true }];
      let dataJson = {};
      for (let i = 0; i < invoiceDataInfo.length; i++) {
        dataJson = {
          text:
            invoiceDataInfo[i].counter +
            " [ " +
            invoiceDataInfo[i].total_amount.due
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " ]",
          id: invoiceDataInfo[i].number,
          disabled: false
        };
        dataObj.push(dataJson);
      }
      this.invoiceDataList = dataObj;
    },
    payInvoice() {
      this.payInvoiceDialog = true;
      this.getInvoiceBillBalance(this.invoiceDataDetails.number);
    },
    getInvoicesInfo() {
      let number = this.invoiceNumber;
      let invoiceObj = this.fullInvoiceDataList;

      let obj = {};
      for (let i = 0; i < invoiceObj.length; i++) {
        if (invoiceObj[i].number === number) {
          obj = {
            number: invoiceObj[i].number,
            counter: invoiceObj[i].counter
          };
        }
      }

      this.disabledButton = false;

      this.invoiceDataDetails = obj;
    }
  },
  computed: {
    ...mapGetters([
      "getProfileDataList",
      "getProfileResponseData",
      "getBillInvoiceDataInfoData",
      "getBillError",
      "getInvoicePaymentData"
    ])
  },
  watch: {
    getProfileDataList(customerInfo) {
      this.filterCustomerList(customerInfo);
    },
    getProfileResponseData(profile) {
      if (profile) {
        this.$refs["add_customer_info"].customerSpinner = false;
        this.$refs["add_customer_info"].form = {
          name: null,
          trading_name: null,
          address: null,
          number: null,
          tin: null,
          phoneContact: [
            {
              contact: null,
              id: null,
              type: "phone"
            }
          ],
          emailContact: [
            {
              contact: null,
              id: null,
              type: "email"
            }
          ],
          contactPersons: [
            {
              name: null,
              phone_contacts: [
                {
                  phone: null
                }
              ],
              email_contacts: [
                {
                  email: null
                }
              ],
            }
          ]
        };
        this.addCustomerDialog = false;
        this.successToast("Success", profile.message);
        this.form.profile_number = profile.data.number;
        this.fetchProfiles();
      }
    },
    getBillInvoiceDataInfoData(invoiceDataInfo) {
      if (invoiceDataInfo) {
        this.doFilterBillList(invoiceDataInfo);
      }
    },
    getBillError(data) {
      if(data) {
        this.errorToast("Error", data.data.message)
      }
    },
    getInvoicePaymentData(invoiceData) {
      if (invoiceData) {
        this.$refs["paymentComponents"].form = {
          invoice_number: this.invoiceDataDetails.number,
          amount: null,
          payment_method_number: null,
          storage_account_number: null,
          payment_date: new Date().toISOString().substr(0, 10),
          notes: null
        };
        this.$refs["paymentComponents"].invoiceLoader = false;
        this.payInvoiceDialog = false;

        this.successToast("Success", invoiceData.message);

        //  here we need to clear the  form for you to select again
        this.form.profile_number = null;
        this.invoiceDataList = [];
      }
    }
  }
};
</script>

<style scoped></style>
