<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>

            <v-toolbar-title class="mr-4">Profiles</v-toolbar-title>  [<small>Customers and Suppliers</small>]

            <v-spacer></v-spacer>
            <span>
              <v-btn
                  class="ma-2 text-decoration-none"
                  outlined
                  small
                  color="#1b5e20"
                  :loading="downloadSpinner"
                  @click="downloadTemplate"
              >
                <v-icon left>
                  mdi-import
                </v-icon>
                Template
              </v-btn>

              <v-btn
                  class="ma-2"
                  outlined
                  color="#242f7e"
                  small
                  @click="fileUploader"
              >
                <v-icon left>
                  mdi-upload
                </v-icon>
                Import
              </v-btn>

              <v-btn
                  class="ma-2"
                  outlined
                  color="#242f7e"
                  small
                  @click="fileExport"
                  :loading="exportSpinner"
              >
                <v-icon left>
                  mdi-download
                </v-icon>
                Export
              </v-btn>
            </span>

          </v-toolbar>

          <v-divider></v-divider>

          <v-tabs v-model="activeTab">
            <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
            <v-tab href="#profile">
              <v-icon small left>mdi-account</v-icon>
              <small><b>Profiles</b></small>
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-profile">
              <v-icon small left>mdi-lock</v-icon>
              <small><b>New Profile</b></small>
            </v-tab>

            <v-tab-item id="profile">
              <v-divider></v-divider>
              <v-card flat>
                <v-row class="mx-auto mt-8">
                  <v-col cols="3">
                    <v-select
                        clearable
                        class="ml-4"
                        v-model="profileType"
                        :items="profileItems"
                        item-value="value"
                        color="pink"
                        dense
                        outlined
                        label="Profile type"
                        persistent-hint
                        v-on:change="filterList"
                    ></v-select>
                  </v-col>
                  <v-col cols="5">

                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="mr-3"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      dense
                      outlined
                      hide-details
                      v-on:keyup.enter="filterList"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-data-table
                  :headers="headers"
                  :items="customerTableData"
                  :search="search"
                  :loading="tableCustomerLoader"
                  loading-text="Loading... please wait"
                  :page="page"
                  :items-per-page="items_per_page"
                  :server-items-length="server_items_length"
                  :options.sync="paginationCustomer"
                >
                  <!--                  add a link on the customer name-->
                  <template #item.name="{ item }">
                    <router-link
                      class="customerName"
                      :to="{
                        name: 'profile-details',
                        params: { id: item.name_url },
                        query: { prof: item.number }
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </template>
                  <template #item.contacts="{ item }">
                    <span v-if="item.contacts.length > 0">Email: </span>
                    <a
                      v-for="(contact, index) in item.contacts"
                      v-bind:key="index"
                      :href="'mailto:' + contact.contact"
                      class="customerName"
                      ><span v-if="contact.type === 'email'"
                        >{{ contact.contact }},
                      </span></a
                    ><br />
                    <span v-if="item.contacts.length > 0">Phone: </span>
                    <a
                      v-for="(contact, index) in item.contacts"
                      v-bind:key="'A' + index"
                      :href="'tel:' + contact.contact"
                      class="customerName"
                      ><span v-if="contact.type === 'phone'"
                        >{{ contact.contact }},
                      </span></a
                    >
                  </template>

                  <!--                  slot for table actions-->
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editCustomer(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteCustomer(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item id="new-profile">
              <v-divider></v-divider>

              <v-card flat>
                <v-row>
                  <v-col cols="8" class="mt-4 ml-10">
                    <p style="font-size: 20px;">Add Profile</p>
                    <new-profile ref="profile_component"></new-profile>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="editCustomerDialog" width="60%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Profile</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editCustomerDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-profile
            ref="profile_component"
            :editProfilesObj="editProfilesObj"
          ></new-profile>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog v-model="uploadDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Import Profiles</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="uploadDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <upload-file
              ref="upload_component"
              fileType="profiles"
          ></upload-file>
        </div>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "@/global/router";
import configs from "@/global/configs.json";
import UploadFile from "@/modules/dashboard/accounting/UploadFile/UploadFile";
import NewProfile from "@/modules/dashboard/accounting/profiles/NewProfile";
export default {
  name: "Profiles",
  components: {NewProfile, UploadFile },
  data() {
    return {
      activeTab: "profile",
      search: "",
      tableCustomerLoader: false,
      customerTableData: [],
      page: 1,
      items_per_page: 10,
      server_items_length: 0,
      paginationCustomer: {},
      profileType:"",
      profileItems: [
        { text: "Select Type", value: "" },
        { text: "Customer", value: "customer" },
        { text: "Supplier", value: "supplier" }
      ],
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Name", value: "name" },
        { text: "Trading Name", value: "trading_name" },
        { text: "Contacts", value: "contacts", width: "30%" },
        { text: "Address", value: "address" },
        { text: "Tin", value: "tin" },
        { text: "Due Amount", value: "paid_amount" },
        { text: "Paid Amount", value: "invoice" },
        { text: "Action", value: "actions", width:"7%", sortable: false }
      ],
      json_fields: {
        Name: "name",
        Contacts: "contacts",
        Address: "address",
        Tin: "tin"
      },
      editCustomerDialog: false,
      editProfilesObj: {},
      downloadSpinner: false,
      exportSpinner: false,
      uploadDialog:false,
      downloadLink:
          configs.SERVER_IP + "template?type=profiles&file_token=",
      exportLink:
          configs.SERVER_IP + "export?type=profiles&file_token=",
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  methods: {
    ...mapActions(
        [
            "doGetProfileDataList",
          "doDeleteCustomer",
          "exportFile",
          "downloadTemplateFile"
        ]
    ),
    filterCustomerData(raw_data) {
      let customerDataList = raw_data.data;
      this.server_items_length = raw_data.total;
      this.tableCustomerLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let customerJsn = {};
      let customerObj = [];

      customerDataList.forEach((list, i) => {
        customerJsn = {
          key: (current_page - 1) * per_page + i + 1,
          number: list.number ? list.number : "",
          name: list.name ? list.name : "",
          name_url: list.name ? (list.name).replace(" ", "-") : "",
          contacts: list.profile_contacts ? list.profile_contacts : "",
          address: list.address ? list.address : "",
          tin: list.tin ? list.tin : "",
          paid_amount:list.paid_amount ? list.paid_amount : "",
          invoice:list.invoice ? list.invoice : "",
          trading_name:list.trading_name ? list.trading_name : "",
        };
        customerObj.push(customerJsn);
      })
      this.customerTableData = customerObj;
    },
    editCustomer(item) {
      this.editProfilesObj = item;
      this.editCustomerDialog = true;
    },
    deleteCustomer(item) {
      let confirm_delete = confirm(
        "Are you sure you want to delete this customer?"
      );
      if (!confirm_delete) {
        return false;
      }
      this.doDeleteCustomer(item.number);
    },
    filterList(){
      this.tableCustomerLoader = true;
      let options = {
        page: 1,
        per_page: this.items_per_page,
        profileType:this.profileType,
        search:this.search
      };
      this.doGetProfileDataList(options);
    },
    fileUploader() {
      this.uploadDialog = true;
    },
    downloadTemplate() {
      this.downloadSpinner = true;
      this.downloadTemplateFile("profiles");
    },
    fileExport() {
      this.exportSpinner = true;
      this.exportFile("profiles");
    },
  },
  computed: {
    ...mapGetters([
      "getProfileResponseData",
      "getProfileDataList",
      "getDeleteCustomerData",
      "getCustomerErrorMessage",
        "getTemplateDownloadData",
        "getExportFileData",
        "getUploadData"

    ])
  },
  watch: {
    paginationCustomer(obj) {
      this.tableCustomerLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        profileType:this.profileType,
        search:this.search
      };
      this.doGetProfileDataList(options);
    },
    getUploadData(data){
      if(data){
        this.$refs["upload_component"].form = {attachment: null}
        this.$refs["upload_component"].spinner = false
        this.uploadDialog = false
        this.successToast("Success", data.message)
        this.filterList()
      }
    },
    getProfileResponseData(customerData) {
      if (customerData) {
        this.$refs["profile_component"].customerSpinner = false;
        this.$refs["profile_component"].form = {
                                            name: null,
                                            trading_name: null,
                                            address: null,
                                            number: null,
                                            tin: null,
                                            phoneContact: [
                                              {
                                                contact: null,
                                                id: null,
                                                type: "phone"
                                              }
                                            ],
                                            emailContact: [
                                              {
                                                contact: null,
                                                id: null,
                                                type: "email"
                                              }
                                            ],
                                            contactPersons: [
                                              {
                                                name: null,
                                                phone_contacts: [
                                                  {
                                                    phone: null
                                                  }
                                                ],
                                                email_contacts: [
                                                  {
                                                    email: null
                                                  }
                                                ],
                                              }
                                            ]
                                          };

        this.successToast("Success", customerData.message);
        this.editCustomerDialog = false;
        this.filterList()
      }
    },
    getProfileDataList(customerDataList) {
      this.filterCustomerData(customerDataList);
    },
    getDeleteCustomerData(customerDeleteInfo) {
      if (customerDeleteInfo) {
        this.successToast("Success", customerDeleteInfo.message);
        this.filterList()
      }
    },
    getCustomerErrorMessage(deleteError) {
      if (deleteError) {
        this.errorToast("Error", deleteError.message);
      }
    },
    activeTab(data) {
      router.push({ name: "profiles", query: { type: data } });
    },
    getTemplateDownloadData(templateData) {
      if (templateData) {
        this.downloadSpinner = false;

        let fileUrl = this.downloadLink + templateData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },
    getExportFileData(exportData) {
      if (exportData) {
        this.exportSpinner = false;
        let fileUrl = this.exportLink + exportData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },
  }
};
</script>

<style scoped>
.customerName {
  text-decoration: none;
}

.customerName:hover {
  color: green;
  text-decoration: underline;
}
</style>
