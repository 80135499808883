<template>
<span>
   <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Depreciation Transactions</v-toolbar-title>
          </v-toolbar>

          <v-tabs v-model="activeTab">
            <v-tab href="#depreciation-transaction">
              <v-icon left>mdi-account</v-icon>
              Depreciation  Transactions
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-depreciation-transaction">
              <v-icon left>mdi-lock</v-icon>
             Add Depreciation Transaction
            </v-tab>

            <v-tab-item id="depreciation-transaction">
              <v-divider></v-divider>

              <depreciation-transaction-table ref="transaction_table_content"></depreciation-transaction-table>

            </v-tab-item>

            <v-tab-item id="new-depreciation-transaction">
              <v-divider></v-divider>

              <v-row class="mx-auto">
                <v-col cols="6" class="mt-10">
                  <depreciation-transactions ref="depreciation_transactions_content"></depreciation-transactions>
                </v-col>
              </v-row>


            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>



  </v-container>
</span>
</template>

<script>
import router from "@/global/router";
import DepreciationTransactions from "@/modules/dashboard/accounting/Depreciation/DepreciationTransactions";
import DepreciationTransactionTable
  from "@/modules/dashboard/accounting/DepreciationTransaction/DepreciationTransactionTable";
import {mapGetters} from "vuex";

export default {
  name: "DepreciationTransactionInfo",
  components: {DepreciationTransactionTable, DepreciationTransactions},
  data(){
    return{
      activeTab: "depreciation",
    }
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  computed:{
    ...mapGetters(
        [
            "getNewTransactionDepreciationData",
            "getDeleteTransactionDepreciationData",
            "getUpdateTransactionDepreciationData"
        ]
    )
  },
  watch: {
    activeTab(data) {
      router.push({name: "depreciation-transaction", query: {type: data}});
    },
    getNewTransactionDepreciationData(newTransaction){
      if(newTransaction){
        this.$refs["depreciation_transactions_content"].buttonLoader = false
        // this.$refs["transaction_table_content"].filterDepreciationTransactionInfo()
        this.$refs["depreciation_transactions_content"].form = {
                                                            amount:null,
                                                            date:null,
                                                            asset_depreciation_number:null,
                                                          }

        this.successToast("Success", newTransaction.message)
      }
    },
    getDeleteTransactionDepreciationData(deleteData){
        if(deleteData){
          this.$refs["transaction_table_content"].activeIndex = undefined
          this.$refs["transaction_table_content"].filterDepreciationTransactionInfo()
            this.successToast("Success", deleteData.message)
        }
    },
    getUpdateTransactionDepreciationData(update){
        if(update){
          this.$refs["transaction_table_content"].filterDepreciationTransactionInfo()
          this.$refs["transaction_table_content"].depreciationTransactionDialog = false
          this.$refs["transaction_table_content"].inactivateLoader()
          this.successToast("Success", update.message)
        }
    }
  }

}
</script>

<style scoped>

</style>