import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
    newInvoiceData: null,
    editInvoiceData: null,
    invoiceDataInfo: null,
    adjustStockData: null,
    adjustStockListData: null,
    invoicePaymentData: null,
    taxPaymentData: null,
    invoicePaymentDetailsEditDetails: null,
    liabilityPaymentData: null,
    liabilityPaymentUpdateData: null,
    invoicePaymentDataInfo: null,
    invoiceBillBalance: null,
    invoicePaymentDetails: null,
    invoiceError: null,
    paymentDelete: null,
    paymentDeleteError: null,
    adjustmentDelete: null,
    adjustmentError: null
};

const getters = {
    getNewInvoiceData: state => state.newInvoiceData,
    getInvoiceDataInfo: state => state.invoiceDataInfo,
    getInvoicePaymentData: state => state.invoicePaymentData,
    getInvoicePaymentDataInfo: state => state.invoicePaymentDataInfo,
    getInvoiceBillBalanceInfo: state => state.invoiceBillBalance,
    getEditInvoiceData: state => state.editInvoiceData,
    getLiabilityPaymentData: state => state.liabilityPaymentData,
    getLiabilityPaymentUpdateData: state => state.liabilityPaymentUpdateData,
    getInvoicePaymentDetails: state => state.invoicePaymentDetails,
    getInvoicePaymentDetailsEditDetails: state => state.invoicePaymentDetailsEditDetails,
    getInvoiceError: state => state.invoiceError,
    getInvoicePaymentDelete: state => state.paymentDelete,
    getInvoicePaymentDeleteError: state => state.paymentDeleteError,
    getTaxPaymentData: state => state.taxPaymentData,
    getAdjustStockListData: state => state.adjustStockListData,
    getAdjustStockData: state => state.adjustStockData,
    getAdjustmentError: state => state.adjustmentError,
    getAdjustmentDelete: state => state.adjustmentDelete,
};

const actions = {
    async doSaveInvoice({commit, dispatch}, data) {
        //this removes commas when invoice is submitted
        data.bill_of_items.forEach((list, i) => {
            data.bill_of_items[i].quantity = (list.quantity.toString().replace(/,/g, "") * 1);
            data.bill_of_items[i].unit_price = (list.unit_price.toString().replace(/,/g, "") * 1);
            data.bill_of_items[i].amount = (list.amount.toString().replace(/,/g, "") * 1);
        })

        //removes commas on expense
        data.expense_items.forEach((list, i) => {
            data.expense_items[i].amount = list.amount
                ? (list.amount.toString().replace(/,/g, "") * 1)
                : "";
        })

        await axios
            .post(IP + "invoice/create", data)
            .then(response => {
                commit("setNewInvoiceData", response);
                dispatch("doGetInvoiceListData", {profile_number: response.data.data.profile_number});
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    },
    async doEditInvoice({commit}, data) {
        //this removes commas when invoice is submitted
        data.bill_of_items.forEach((list, i) => {
            data.bill_of_items[i].quantity = (list.quantity.toString().replace(/,/g, "") * 1);
            data.bill_of_items[i].unit_price = (list.unit_price.toString().replace(/,/g, "") * 1);
            data.bill_of_items[i].amount = (list.amount.toString().replace(/,/g, "") * 1);
        })

        //removes commas on expense
        data.expense_items.forEach((list, i) => {
            data.expense_items[i].amount = list.amount
                ? (list.amount.toString().replace(/,/g, "") * 1)
                : "";
        })

        await axios
            .post(IP + "invoice/update/" + data.number, data)
            .then(response => {
                commit("setEditInvoiceData", response);
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    },
    async doGetInvoiceInfo({commit}, data) {
        await axios
            .get(IP + "invoice/details/" + data)
            .then(response => {
                commit("setInvoiceDataInfo", response);
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    },
    async doAdjustStock({commit}, data) {
        await axios
            .post(IP + "company/stock-adjustment", data)
            .then(response => {
                commit("setAdjustStockData", response);
            })
            .catch(error => {
                commit("setAdjustmentError", error);
            });
    },
    async deleteStockAdjustment({commit}, data) {
        await axios
            .post(IP + "company/cancel-adjustments?reference="+data)
            .then(response => {
                commit("setAdjustmentDelete", response);
            })
            .catch(error => {
                commit("setAdjustmentError", error);
            });
    },
    async doGetStockAdjustmentList({commit}, data) {
        let search = data?data.search ? data.search: "":""
        await axios
            .get(
                IP + "company/stock-adjustments?page=" +
                                                        data.page +
                                                        "&per_page=" +
                                                        data.per_page +
                                                        "&search=" + search
            )
            .then(response => {
                commit("setAdjustStockListData", response.data);
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    },
    async doPayInvoice({commit}, data) {
        commit("setInvoicePaymentData", {data: {}});
        await axios
            .post(IP + "invoice/make-payment", data)
            .then(response => {
                commit("setInvoicePaymentData", response);
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    },
    async doPayTaxes({commit}, data) {
        await axios
            .post(IP + "taxes/remit", data)
            .then(response => {
                commit("setTaxPaymentData", response);
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    },
    async doEditInvoicePaymentDetails({commit}, data) {
        await axios
            .post(IP + "invoice/update-payment", data)
            .then(response => {
                commit("setInvoicePaymentDetailsEditDetails", response);
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    },
    async doPayLiability({commit}, data) {
        await axios
            .post(IP + "liability-payments/new", data)
            .then(response => {
                commit("setLiabilityPaymentData", response);
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    },
    async doEditPayLiability({commit}, data) {
        await axios
            .post(IP + "liability-payments/update", data)
            .then(response => {
                commit("setLiabilityPaymentUpdateData", response);
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    },
    async doGetInvoicePayment({commit}, data) {
        await axios
            .get(
                IP +
                "payments/on-invoice/" +
                data.invoice_number +
                "?page=" +
                data.page +
                "&per_page=" +
                data.per_page
            )
            .then(response => {
                commit("setInvoicePaymentDataInfo", response);
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    },
    async getInvoiceBillBalance({commit}, data) {
        commit("setInvoiceBillBalance", {data: 0});
        await axios
            .get(IP + "payments/get-due-amount/" + data)
            .then(response => {
                commit("setInvoiceBillBalance", response);
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    },

    async deleteInvoicePayment({commit}, data) {
        commit("setInvoiceDelete", {data: 0});
        await axios
            .post(IP + "invoice/delete-payment", data)
            .then(response => {
                commit("setInvoiceDelete", response);
            })
            .catch(error => {
                commit("setInvoiceDeleteError", error);
            });
    },
    async fetchInvoicePaymentDetails({commit}, data) {
        await axios
            .get(IP + "invoice/invoice-payment-details/" + data)
            .then(response => {
                commit("setInvoicePaymentDetails", response);
            })
            .catch(error => {
                commit("setInvoiceMessage", error);
            });
    }
};

const mutations = {
    setNewInvoiceData: (state, response) => {
        state.newInvoiceData = response.data;
    },
    setEditInvoiceData: (state, response) => {
        state.editInvoiceData = response.data;
    },
    setInvoiceDataInfo: (state, response) => {
        state.invoiceDataInfo = response.data;
    },
    setAdjustStockData: (state, response) => {
        state.adjustStockData = response.data;
    },
    setAdjustmentDelete: (state, response) => {
        state.adjustmentDelete = response.data;
    },
    setAdjustStockListData: (state, response) => {
        state.adjustStockListData = response;
    },
    setInvoicePaymentData: (state, response) => {
        state.invoicePaymentData = response.data;
    },
    setTaxPaymentData: (state, response) => {
        state.taxPaymentData = response.data;
    },
    setInvoicePaymentDetailsEditDetails: (state, response) => {
        state.invoicePaymentDetailsEditDetails = response.data;
    },
    setLiabilityPaymentData: (state, response) => {
        state.liabilityPaymentData = response.data;
    },
    setLiabilityPaymentUpdateData: (state, response) => {
        state.liabilityPaymentUpdateData = response.data;
    },
    setInvoicePaymentDataInfo: (state, response) => {
        state.invoicePaymentDataInfo = response.data;
    },
    setInvoiceBillBalance: (state, response) => {
        state.invoiceBillBalance = response.data;
    },
    setInvoicePaymentDetails: (state, response) => {
        state.invoicePaymentDetails = response.data;
    },
    setInvoiceMessage: (state, error) => {
        state.invoiceError = error.response
            ? error.response.data.message
            : "Error: Network Error";
    },
    setInvoiceDelete: (state, response) => {
        state.paymentDelete = response.data;
    },
    setInvoiceDeleteError: (state, error) => {
        state.paymentDeleteError = error.response
    },
    setAdjustmentError: (state, error) => {
        state.adjustmentError = error.response
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
