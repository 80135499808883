<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-toolbar dense flat>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
          <v-toolbar-title> Bills </v-toolbar-title>
        </v-toolbar>

        <v-tabs v-model="activeTab" v-if="this.$can('view', 'bills')">
          <!-- <v-tabs-slider color="yellow"></v-tabs-slider>-->
          <v-tab href="#bill">
            <v-icon left>mdi-account</v-icon>
            Bills
          </v-tab>

          <v-tab v-if="this.$can('create', 'bills') && checkLicenseStatus()" href="#new-bill">
            <v-icon left>mdi-lock</v-icon>
            New Bill
          </v-tab>

          <v-tab-item id="bill" v-if="this.$can('view', 'bills')">
            <v-divider></v-divider>
            <bill-table></bill-table>
          </v-tab-item>

          <v-tab-item id="new-bill" v-if="this.$can('create', 'bills')">
            <v-divider></v-divider>
            <v-card flat>
              <div
                class="mt-4 mx-auto"
                style="width: 98%; padding-bottom: 15px;"
              >
                <new-bill ref="bill_components"></new-bill>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewBill from "../commons/NewBill";
import router from "@/global/router";
import BillTable from "@/modules/dashboard/accounting/Supplier/BillTable";
export default {
  name: "Bills",
  components: { BillTable, NewBill },
  data() {
    return {
      activeTab: "bill"
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },

  watch: {
    activeTab(data) {
      router.push({ name: "bill", query: { type: data } });
    }
  }
};
</script>
