<template>
<div>

          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>License</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-tabs>
            <v-tab href="#stock">
<!--              <v-icon left>mdi-account</v-icon>-->
              Licenses
            </v-tab>

            <v-tab href="#new-stock">
<!--              <v-icon left>mdi-lock</v-icon>-->
              New License
            </v-tab>


            <v-tab-item id="stock">
              <v-divider></v-divider>
              <v-card flat>

                <license-list></license-list>

              </v-card>
            </v-tab-item>

            <v-tab-item id="new-stock">
              <v-divider></v-divider>

              <v-card flat>
                <div
                    class="mt-8 mx-auto"
                    style="width: 98%; padding-bottom: 15px;"
                >

                   <v-row class="mx-auto">

                   <v-col class="mx-auto" cols="6">

                     <new-license ref="license_component"></new-license>

                   </v-col>
                   </v-row>

                </div>
              </v-card>
            </v-tab-item>

          </v-tabs>






  </div>
</template>

<script>
import NewLicense from "@/modules/dashboard/Settings/license/NewLicense";
import {mapGetters} from "vuex";
import LicenseList from "@/modules/dashboard/Settings/license/LicenseList";
export default {
  name: "License",
  components: {LicenseList, NewLicense},
  data(){
    return{

    }
  },
  computed:{
    ...mapGetters(
        [
            "getNewLicense"
        ]
    )
  },
  watch:{
    getNewLicense(data){
      if(data){
        this.$refs.license_component.buttonSpinner = false
        this.$refs.license_component.form = {
                                              owner:null,
                                              expiry_date:null,
                                            }
        this.successToast("Success", data.message)
      }
    }
  }
}
</script>

<style scoped>

</style>