<template>
<span>

  <v-row>
     <v-col cols="2">
              <v-select
                  outlined
                  v-model="payment_type"
                  :items="paymentTypeObj"
                  item-value="id"
                  color="pink"
                  label="Stock by"
                  required
                  hide-details
                  dense
              ></v-select>
            </v-col>

     <v-col cols="2">
              <v-select
                  v-if="payment_type==='Purchase'"
                  outlined
                  v-model="options_type"
                  :items="optionsTypeObj"
                  item-value="id"
                  color="pink"
                  label="Option"
                  required
                  hide-details
                  dense
              ></v-select>
            </v-col>
  </v-row>


  <add-stock v-if="options_type === 'Cash'" :payment_type="payment_type" ref="stock_components"></add-stock>

  <new-bill v-if="options_type === 'Credit'" ref="bill_components"></new-bill>

  <assembly v-if="payment_type === 'Assembly'" ref="assembly_components"></assembly>

</span>
</template>

<script>
import AddStock from "@/modules/dashboard/accounting/commons/AddStock";
import {mapGetters} from "vuex";
import NewBill from "@/modules/dashboard/accounting/commons/NewBill";
import Assembly from "@/modules/dashboard/accounting/Stock/Assembly";
export default {
  name: "StockOptions",
  components: {Assembly, NewBill, AddStock},
  data(){
    return{
      payment_type:"",
      options_type:"",
      optionsTypeObj: [
        {
          text: "Select item",
          id: ""
        },
        {
          text: "Cash",
          id: "Cash"
        },
        {
          text: "Credit",
          id: "Credit"
        },
     /*   {
          text: "Assemble",
          id: "Assembly"
        }*/
      ],
      paymentTypeObj: [
        {
          text: "Select item",
          id: ""
        },
        {
          text: "Purchase",
          id: "Purchase"
        },
        {
          text: "Assemble",
          id: "Assembly"
        }
      ],

    }
  },
  computed: {
    ...mapGetters([
      "getNewStockDataList",
    ])
  },
  watch:{
      getNewStockDataList(newDataStock) {
        if (newDataStock) {
          this.$refs["stock_components"].btnStockSpinner = false;
          this.$refs["stock_components"].form = {
            discounts: [],
            absolute_discounts: null,
            storage_account_number: null,
            payment_method_number: null,
            item_number: null,
            reference: null,
            unit_number: null,
            profile_number: null,
            notes: null,
            invoice_date: new Date().toISOString().substr(0, 10),
            payment_type: null,
            bill_of_items: [
              {
                item_number: "",
                buying_price: null,
                selling_price: null,
                unit_number: null,
                quantity: null,
                notes: null,
                code: null,
                tax_number:null
              }
            ],
            // expense_items: [],
            expense_items: []
          };
          this.successToast("Success", newDataStock.message);

        }
      },
    payment_type(data){
        if(data){
         if(data === 'Assembly'){
           this.options_type = null
         }
        }
    }
  }
}
</script>

<style scoped>

</style>