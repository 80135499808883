<template>
<div>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :loading="loading"
          loading-text="Loading... please wait"
          item-key="name"
          class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editExpense(item)">
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-row>

</div>
</template>

<script>
import {mapActions,mapGetters} from "vuex";

export default {
  name: "AllTransfers",
  data(){
    return {
      loading:false,
      items:[
        {

        }
      ],
      search:"",
      headers:[
        {
          text: "From",
          align: "left",
          sortable: false,
          value: "from"
        },
        {
          text: "To",
          align: "left",
          sortable: false,
          value: "to"
        },
        {
          text: "Amount",
          align: "left",
          sortable: false,
          value: "amount"
        },
        {
          text: "Date",
          align: "left",
          sortable: false,
          value: "date"
        },
        {
          text: "Actions",
          align: "left",
          sortable: false,
          value: "actions"
        }
      ]

    }
  },
  methods:{
    ...mapActions(["fetchTransferList"]),
   /* transferDetails(itemId){

    }*/
  },
  computed:{
    ...mapGetters([
      "getTransferDetails"
    ]),
  },

    watch:{
        getTransferDetails(data){
            alert(data)
        }
    }
}
</script>

<style scoped>

</style>