<template>
    <v-container fluid>
        <v-card :loading="cardLoader">
            <v-row class="mx-0">
                <v-col id="billPDFId" cols="12">
                    <v-row class="mx-0">
                        <p>Bill #{{ billDataInfo.counter }}</p>

                        <v-spacer></v-spacer>

                        <span>
<!--              <v-btn
                      style="color: #ffffff;"
                      tile
                      color="#1976d2"
                      small
                      class="mr-1"
              >
                <v-icon left>
                  mdi-content-save
                </v-icon>
                Save
              </v-btn>

              <v-btn
                      style="color: #ffffff;"
                      tile
                      color="#1976d2"
                      small
                      class="mr-1"
                      @click="printBillPdf()"
              >
                <v-icon left>
                  mdi-printer
                </v-icon>
                Print
              </v-btn>-->

                 <v-btn
                         style="color: #ffffff;"
                         tile
                         color="#1976d2"
                         small
                         class="mr-1"
                         @click="downloadInvoicePdf()"
                 >
                <i class="fa fa-file-pdf-o mr-1" style="font-size: 15px;" aria-hidden="true"></i>
                Download
              </v-btn>

              <v-btn
                      v-if="billDataInfo.status !== 'Fully paid'"
                      @click="payBill()"
                      style="color: #ffffff;"
                      tile
                      color="#1976d2"
                      small
              >
                <v-icon left>
                  mdi-cash
                </v-icon>
                Pay Bill
              </v-btn>
            </span>
                    </v-row>

                    <v-divider></v-divider>

                    <v-row>
                        <v-col cols="4">
                            <v-card-subtitle>
                                <p>From:</p>
                                Name: <b>{{ billDataInfo.customer.name }}</b
                            ><br/>
                                Address: {{ billDataInfo.customer.address }}<br/>
                                Tin: {{ billDataInfo.customer.tin }}<br/>

                                Email:
                              <span v-if="billDataInfo.customer">
                                <a
                                        v-for="(contact, index) in billDataInfo.customer
                    .profile_contacts"
                                        :href="'mailto:' + contact.contact"
                                        :key="index"
                                >
                  <span v-if="contact.type === 'email'">
                    {{ contact.contact }},
                  </span>
                                </a>
                              </span>
                                <br/>
                                Phone:
                              <span v-if="billDataInfo.customer">
                                     <a
                                         v-for="(contact, index) in billDataInfo.customer.profile_contacts"
                                                           :href="'tel:' + contact.contact"
                                                           :key="'A' + index"
                                                       >
                                    <span v-if="contact.type === 'phone'">
                                      {{ contact.contact }},
                                    </span>
                                </a>
                              </span>

                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="4">
                            <v-card-subtitle>
                                <p>To:</p>
                                Name: <b>{{ billDataInfo.company ? billDataInfo.company.company_name : "" }}</b> <br/>
                                Legal name: {{ billDataInfo.company ? billDataInfo.company.legal_name : "" }} <br/>
                                Address:
                                {{
                                (billDataInfo.company ? billDataInfo.company.company_address : "")
                                }}
                                <br/>
                                Email:
                                <a v-if="billDataInfo.company" :href="'mailto:' + billDataInfo.company.company_email">
                                    {{ billDataInfo.company.company_email }}</a
                                >
                                <br/>
                                Phone:
                                <a v-if="billDataInfo.company" :href="'tel:' + billDataInfo.company.company_phone">{{
                                        billDataInfo.company.company_phone
                                    }}</a>
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="4">
                            <v-card-subtitle>
                                <p>Details:</p>
                                Bill Number <b>#{{ billDataInfo.counter }}</b> <br/>
                                Ref No: {{ billDataInfo.ref_no }}<br/>
                                Bill Date: {{ billDataInfo.invoice_date }}<br/>
                                Due Date: {{ billDataInfo.due_date }}<br/>
                                Status:
                                <v-chip class="ma-2" :color="statusColor" small outlined>
                                    {{ billDataInfo.status }}<br/>
                                </v-chip>
                            </v-card-subtitle>
                        </v-col>
                    </v-row>


                    <p class="ml-5">Bill Items</p>
                    <v-simple-table style="width: 100%;" dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left" width="5%">
                                    #
                                </th>
                                <th class="text-left">
                                    Name
                                </th>

                                <th class="text-left">
                                    Memo
                                </th>
                                <th class="text-left">
                                    Quantity
                                </th>
                                <th class="text-left">
                                    Buying Price (UGX)
                                </th>
                                <th class="text-left">
                                    Amount (UGX)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    v-for="(item, index) in billDataInfo.stock_items"
                                    :key="index"
                            >
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.item_name ? item.item_name : "" }}</td>

                                <td>{{ item.notes ? item.notes : "" }}</td>
                                <td>
                                    {{
                                        item.quantity ? item.quantity
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " (" + item.unit_name + ")" : ""
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.buying_price
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.total_amount ?
                                                item.total_amount
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4"></td>
                                <td style="border-bottom: #e0e0e0 solid 1px;">
                                    <b>Cost of Items</b>
                                </td>
                                <td style="border-bottom: #e0e0e0 solid 1px;">
                                    <b>
                                        {{costOfItems}}
                                    </b>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>


                    <p class="ml-5 mt-5">Other Expenses</p>

                    <v-simple-table style="width: 100%;" dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left" width="5%">
                                    #
                                </th>
                                <th class="text-left">
                                    Date
                                </th>
                                <th class="text-left">
                                    Name
                                </th>
                                <th class="text-left">
                                    Memo
                                </th>
                                <th class="text-left">
                                    Amount (UGX)
                                </th>


                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    v-for="(item, index) in billDataInfo.invoice_expenses"
                                    :key="index"
                            >
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.created_at }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.notes }}</td>
                                <td>
                                    {{
                                        item.amount
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                </td>

                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <td style="border-bottom: #e0e0e0 solid 1px;">
                                    <b>Additional Costs</b>

                                </td>
                                <td style="border-bottom: #e0e0e0 solid 1px;">
                                    <b>
                                        {{additionalCosts}}
                                    </b>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>


                    <v-row>
                        <v-col cols="8">
                            <v-card-subtitle>
                                {{ billDataInfo.notes }}
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="4">
                            <v-card-subtitle>
                                <v-row>
                                    <p><b>Sub-total</b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>
                                            {{subTotal}}
                                        </b>
                                    </p>
                                </v-row>


                                <v-row>
                                    <p><b>Additional Costs
                                    </b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>
                                            {{additionalCosts}}
                                        </b>
                                    </p>
                                </v-row>

                                <v-row>
                                    <p><b>Total</b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>
                                            {{
                                                billDataInfo.total_amount.afterDiscount
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            }}
                                        </b>
                                    </p>
                                </v-row>
                                <v-row>
                                    <p><b>Paid amount</b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>
                                            {{paidAmount}}
                                        </b>
                                    </p>
                                </v-row>
                                <v-row class="red--text">
                                    <p><b>Due Amount</b></p>
                                    <v-spacer></v-spacer>
                                    <p>
                                        <b>
                                            {{
                                                billDataInfo.total_amount.due
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            }}
                                        </b>
                                    </p>
                                </v-row>
                            </v-card-subtitle>
                        </v-col>
                    </v-row>


                    <v-row class="mx-auto">
                        <v-spacer></v-spacer>
                        <v-btn
                                v-if="billDataInfo.status !== 'Fully paid'"
                                style="color: #ffffff;"
                                tile
                                color="#45a164"
                                small
                                class="mr-1"
                                @click="payBill()"
                        >
                            <v-icon left>
                                mdi-cash
                            </v-icon>
                            Proceed to Payment
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-4">
            <v-row class="mx-0">
                <v-col cols="12">
                    <p>Payment Details</p>
                    <v-divider></v-divider>

                    <v-data-table
                            :headers="paymentHeaders"
                            :items="billPaymentDataInfo"
                            :search="search"
                            :loading="tablePaymentLoader"
                            loading-text="Loading... please wait"
                            item-key="name"
                            class="elevation-1"
                            :page="page"
                            :items-per-page="items_per_page"
                            :server-items-length="server_items_length"
                            :options.sync="paymentPagination"
                    >
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="editPayment(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon small @click="confirmDeletePayment(item.transaction_number)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>

        <!--      adds bill-->
        <v-dialog v-model="payBillDialog" width="40%">
            <v-card>
                <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
                    <v-row>
                        <v-card-title>Pay Bill</v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="payBillDialog = false">
                            <v-icon color="red">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-row>
                    <pay-invoice-bill
                            ref="paymentComponents"
                            :invoiceInfo="billDataInfo"
                            receiptType="Bill"
                    ></pay-invoice-bill>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editBillPaymentDialog" width="40%">
            <v-card>
                <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
                    <v-row>
                        <v-card-title>Edit Payment Details</v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="editBillPaymentDialog = false">
                            <v-icon color="red">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-row>
                    <pay-invoice-bill
                            ref="editPaymentComponents"
                            receiptType="Invoice"
                    ></pay-invoice-bill>
                </div>
            </v-card>
        </v-dialog>
        <ConfirmDialog ref="deleteInvoicePaymentTransaction"></ConfirmDialog>

    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PayInvoiceBill from "../commons/PayInvoiceBill";
import ConfirmDialog from "@/modules/commons/ConfirmDialog";
import configs from "@/global/configs.json";

export default {
    name: "BillDetails",
    components: {PayInvoiceBill, ConfirmDialog},
    data() {
        return {
            billDataInfo: {
                customer: {},
                company: {},
                total_amount: {
                    beforeDiscount: {},
                    afterDiscount: {},
                    due: {}
                }
            },
            subTotal:null,
            costOfItems:null,
            paidAmount:null,
            additionalCosts:null,
            payBillDialog: null,
            search: null,
            tablePaymentLoader: false,
            items_per_page: 10,
            page: 1,
            server_items_length: 0,
            statusColor: null,
            editBillPaymentDialog: false,
            paymentHeaders: [
                {
                    width: "5%",
                    text: "#",
                    align: "start",
                    value: "key"
                },
                {text: "Bill Number", value: "invoice_number"},
                {text: "Amount", value: "amount"},
                {text: "Notes", value: "notes"},
                {text: "Payment Method", value: "payment_method_name"},
                {text: "Bank/Cash Account", value: "bank" },
                {text: "Payment Date", value: "payment_date"},
                {text: "Action", value: "actions"}
            ],
            paymentPagination: {},
            billPaymentDataInfo: [],
            cardLoader: false,
            downloadLink:
                configs.SERVER_IP + "out-pdf/bill?bill-no=",
        };
    },
    beforeMount() {
        this.cardLoader = true;
    },
    mounted() {
        this.doGetBillInfo(this.$route.query.id);
    },
    methods: {
        ...mapActions([
            "doGetBillInfo",
            "doGetBillPayment",
            "getInvoiceBillBalance",
            "fetchInvoicePaymentDetails",
            "deleteInvoicePayment"
        ]),
        printBillPdf() {
            let invoicePdf = window.open(
                "",
                "PRINT",
                "height=650,width=900,top=100,left=150"
            );

            invoicePdf.document.write(`<html><head><title>Bill</title>`);
            invoicePdf.document.write("</head><body >");
            invoicePdf.document.write(document.getElementById("billPDFId").innerHTML);
            invoicePdf.document.write("</body></html>");

            invoicePdf.document.close(); // necessary for IE >= 10
            invoicePdf.focus(); // necessary for IE >= 10*/

            invoicePdf.print();
            invoicePdf.close();

            return true;
        },
        downloadInvoicePdf() {
            this.downloadSpinner = false;

            let fileUrl = this.downloadLink + this.billDataInfo.number;

            const pdfObj = document.createElement("a");
            pdfObj.href = fileUrl;
            pdfObj.click();
        },
        payBill() {
            this.payBillDialog = true;
            this.getInvoiceBillBalance(this.billDataInfo.number);
        },
        editPayment(item) {
            if (item) {
                this.editBillPaymentDialog = true
                this.fetchInvoicePaymentDetails(item.number)
            }
        },
        deletePayment(item) {
            let confirm_delete = confirm(
                "Are you sure you want to delete this item."
            );
            if (!confirm_delete) {
                return false;
            }
            alert(JSON.stringify(item));
        },
        filterPaymentListData(raw_data) {
            let paymentDataList = raw_data.data;
            this.server_items_length = raw_data.total;
            this.tablePaymentLoader = false;

            let current_page = raw_data.current_page;
            this.page = current_page;
            let per_page = raw_data.per_page;
            this.items_per_page = per_page * 1;

            let paymentJsn = {};
            let paymentObj = [];

            paymentDataList.forEach((lst, i) => {
                paymentJsn = {
                    key: (current_page - 1) * per_page + i + 1,
                    invoice_number: lst.invoice_counter ? lst.invoice_counter : "",
                    number: lst.number ? lst.number : "",
                    amount: lst.amount ? lst.amount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
                    notes: lst.notes ? lst.notes : null,
                    payment_method_name: lst.payment_method_name ? lst.payment_method_name : "",
                    payment_date: lst.payment_date ? lst.payment_date : "",
                    transaction_number: lst.transaction_number ? lst.transaction_number : "",
                    bank: lst.bank ? lst.bank : null
                };
                paymentObj.push(paymentJsn);
            })

            this.billPaymentDataInfo = paymentObj;
        },
        fetchPaymentDetailsFilter() {
            let options = {
                bill_number: this.$route.query.id,
                page: this.page,
                per_page: this.items_per_page
            };
            this.doGetBillPayment(options);
        },
        async confirmDeletePayment(transaction_number) {
            let title = `Are you sure you want to delete this payment ?  Doing this is not reversible`

            const agreed = await this.$refs.deleteInvoicePaymentTransaction.confirm(
                title,
                {
                    color: "red",
                    width: "480",
                }
            );

            if (!agreed) {
                return
            }
            await this.deleteInvoicePayment({transaction_number});

        },
    },
    computed: {
        ...mapGetters([
            "getBillDataInfo",
            "getBillPaymentsData",
            "getInvoicePaymentData",
            "getInvoicePaymentDetailsEditDetails",
            "getInvoicePaymentDelete",
            "getInvoicePaymentDeleteError"
        ])
    },
    watch: {
        getBillDataInfo(billData) {
            this.billDataInfo = billData;

            this.subTotal =  billData.total_amount ? billData.total_amount.taxFree
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

            this.additionalCosts =  billData.total_amount ? billData.total_amount.totalExpenses
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

            this.paidAmount =  billData.total_amount ? billData.total_amount.paidAmount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

            this.costOfItems =  billData.total_amount ? billData.total_amount.itemsAmount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

            //changes color status
            this.cardLoader = false;

            this.statusColor = this.getStatusColor(billData.status);
        },
        getInvoicePaymentData(billData) {
            if (billData.message) {
                this.$refs.paymentComponents.form = {
                    invoice_number: this.billDataInfo.number,
                    amount: null,
                    payment_method_number: null,
                    storage_account_number: null,
                    payment_date: new Date().toISOString().substr(0, 10),
                    notes: null
                };
                this.$refs.paymentComponents.invoiceLoader = false;
                this.payBillDialog = false;
                this.successToast("Success", billData.message);

                //fetch bill details
                this.doGetBillInfo(this.$route.query.id);

                //fetch payment details
                this.fetchPaymentDetailsFilter()


                //  we need to populate the balance on the bill
                this.getInvoiceBillBalance(this.billDataInfo.number);
            }
        },
        paymentPagination(obj) {
            this.tablePaymentLoader = true;

            let options = {
                bill_number: this.$route.query.id,
                page: obj.page,
                per_page: obj.itemsPerPage
            };
            this.doGetBillPayment(options);
        },
        getBillPaymentsData(billPaymentsData) {
            this.filterPaymentListData(billPaymentsData);
        },
        getInvoicePaymentDetailsEditDetails(data) {
            if (data) {
                this.successToast("Success", data.message)
                this.$refs.editPaymentComponents.invoiceLoader = false
                this.editBillPaymentDialog = false
                this.doGetBillInfo(this.$route.query.id);
                this.fetchPaymentDetailsFilter()
            }
        },
        getInvoicePaymentDelete(data) {
            this.successToast("Success", data.message)
            this.doGetBillInfo(this.$route.query.id);
            this.fetchPaymentDetailsFilter()

        },
        getInvoicePaymentDeleteError(error) {
            this.errorToast("Error", error.message)
        }
    }
};
</script>

<style scoped></style>
