<template>

  <div>
    <v-row class="mx-auto mt-8">
      <v-col cols="8">

      </v-col>

      <v-col cols="4">
        <v-text-field
            class="mr-3"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            v-on:keyup.enter="doSearch"
            dense
            outlined
            hide-details
        ></v-text-field>
      </v-col>
    </v-row>


    <v-data-table
        :headers="headers"
        :items="tableData"
        :search="search"
        :loading="tableLoader"
        loading-text="Loading... please wait"
        :page="page"
        :items-per-page="items_per_page"
        :server-items-length="server_items_length"
        :options.sync="pagination"
        no-data-text="No Depreciation Item found."
        :footer-props="{
                      'itemsPerPageOptions': items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text':'Depreciation per page'
                    }">
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="Depreciation per page"
            :items-per-page-options="items_per_page_options"
            :showFirstLastPage="true"
            firstIcon="mdi-arrow-collapse-left"
            lastIcon="mdi-arrow-collapse-right"
            prevIcon="mdi-chevron-left"
            nextIcon="mdi-chevron-right"
        />
      </template>

      <template v-slot:item.actions="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                size="20"
                v-bind="attrs"
                v-on="on"
                @click="editLiabilityPayment(item)">
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit Liability</span>
        </v-tooltip>


        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                size="20"
                v-bind="attrs"
                v-on="on"
                @click="deleteLiabilityPayment(item)">
              mdi-delete
            </v-icon>
          </template>
          <span>Delete Liability</span>
        </v-tooltip>


      </template>
    </v-data-table>




    <v-dialog v-model="editTransactionsPaymentDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Payment</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editTransactionsPaymentDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
           <pay-liability
               ref="liabilityPaymentComponents"
               :invoiceInfo="liabilityPaymentDataInfo"
               :account_type="account_type"
           ></pay-liability>
        </div>
      </v-card>
    </v-dialog>


  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PayLiability from "@/modules/dashboard/accounting/commons/PayLiability";

export default {
  name: "LiabilityPaymentTransactions",
  components: {PayLiability},
  props: {
    account_type: String
  },
  data() {
    return {
      tableData: [],
      search: "",
      tableLoader: false,
      pagination: {},
      page: 1,
      items_per_page_options: [20, 50, 100, 500],
      items_per_page: 20,
      server_items_length: 0,

      expense_permission_items: [
        { text: "Select Permission", value: null },
        { text: "Everyone", value: "everyone" },
        { text: "view", value: "view" },
        { text: "edit", value: "edit" }
      ],
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount" },
        { text: "Date", value: "date" },
        { text: "Bank/Cash Account", value: "storage_account" },
        { text: "Action", value: "actions", sortable: false }
      ],
      expense: [
        {
          name: "Balance brought down from March, 2019",
          date: "01-04-2019",
          account_name: "Equity Bank (Ugx)",
          amount: "USh 39,843.99",
          status: "Paid",
          attachment: "word.docx",
          action: "Edit"
        },
        {
          name: "Monthly data",
          date: "01-06-2020",
          account_name: "Equity Bank (Ugx)",
          amount: "USh 30,000.00",
          status: "Paid",
          attachment: "",
          action: "Edit"
        }
      ],
      json_fields: {
        Date: "date",
        Name: "name",
        Amount: "amount",
        Notes: "notes"
      },
      editTransactionsPaymentDialog: false,
      liabilityPaymentDataInfo:{}
    };
  },
  mounted() {
    this.tableTransactionLoader = true;

  },
  methods: {
    ...mapActions(
        [
          "fetchLiabilitiesPayments",
          "doFetchLiabilityPaymentInfo",
          "doDeleteLiabilityPayment"
        ]
    ),

    doSearch() {
      this.tableLoader = true
      let options = {
        page: this.page,
        per_page: this.items_per_page,
        search: this.search,
        type: this.account_type,
      }
      this.fetchLiabilitiesPayments(options);
    },
    editLiabilityPayment(items) {
      this.editTransactionsPaymentDialog = true;
      this.liabilityPaymentDataInfo = items
      this.doFetchLiabilityPaymentInfo(items.number);
    },
    deleteLiabilityPayment(items) {
      this.doDeleteLiabilityPayment(items.number)
    },
    filterTransaction(raw_data) {
      if(raw_data){

        let liabilityPaymentData = raw_data.data;
        this.server_items_length = raw_data.total;
        this.tableLoader = false;

        let current_page = raw_data.current_page;
        this.page = current_page;
        let per_page = raw_data.per_page;
        this.items_per_page = per_page * 1;

        this.tableTransactionLoader = false;
        this.tableStockLoader = false;
        let transactionJsn = {};
        let transactionObj = [];

        liabilityPaymentData.forEach((lst, i) => {
          transactionJsn = {
            key: (current_page - 1) * per_page + i + 1,
            name: lst.name ? lst.name : "",
            amount: lst.amount ? lst.amount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
            date: lst.date ? lst.date : "",
            storage_account: lst.bank ? lst.bank : "",
            number: lst.number ? lst.number : "",
            liability_number: lst.liability_number ? lst.liability_number : "",
          };
          transactionObj.push(transactionJsn);
        })

        this.tableData = transactionObj;
      }

    }
  },
  computed: {
    ...mapGetters([
      "getStatusExpenseResponse",
      "getTransactionErrorMessage",
      "getLiabilityPaymentsDataSet",
      "getTransactionData",
      "getUpdateLiabilityData",
      "getLiabilityPaymentData",
      "getLiabilityPaymentUpdateData",
      "getLiabilityPaymentDeleteInfo"
    ])
  },
  watch: {
    pagination(obj) {
      this.tableLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        search: this.search,
        type: this.account_type,
      };
      this.fetchLiabilitiesPayments(options);
    },
    getTransactionErrorMessage(expenseError) {
      console.log(expenseError);
    },
    getLiabilityPaymentsDataSet(transactionData) {
      this.filterTransaction(transactionData);
    },
    getTransactionData(transactionData) {
      if (transactionData) {
        this.editTransactionsPaymentDialog = false;
        this.$refs["addExpenseItems"].transactionLoader = false;
        this.$refs["addExpenseItems"].form = {
          name: null,
          amount: null,
          notes: null,
          date: new Date().toISOString().substr(0, 10),
          storage_account_number: null,
          account_number: null,
          payment_method: null,
          reference: null
        };

        let data = {
          company_number: this.$company.number,
          type: this.account_type
        };
        this.fetchTransactions(data);
      }
    },
    getUpdateLiabilityData(data){
      if(data){
        this.editTransactionsPaymentDialog = false
        this.successToast("Success", data.message)
        this.$refs.addExpenseItems.transactionLoader = false
        this.doSearch()
      }
    },
    getLiabilityPaymentData(data){
      if(data){
        this.payLiabilityDialog = false
        this.$refs.liabilityPaymentComponents.invoiceLoader = false
        this.$refs.liabilityPaymentComponents.form = {
          liability_number: null,
          amount: null,
          account_number: null,
          payment_method_number: null,
          storage_account_number: null,
          payment_date: new Date().toISOString().substr(0, 10),
          notes: null,
          sale_user_number: null
        }
        this.doSearch()
      }
    },
    getLiabilityPaymentUpdateData(data){
      if(data){
        this.successToast("Success", data.message)
        this.editTransactionsPaymentDialog = false
        this.$refs.liabilityPaymentComponents.invoiceLoader = false
        this.doSearch()
      }
    },
    getLiabilityPaymentDeleteInfo(data){
      if(data){
        this.successToast("Success", data.message)
        this.doSearch()
      }
    }
  }
}
</script>

<style scoped>

</style>