<template>
  <span>
    <form ref="form" @submit.prevent="saveNotificationSetting">
      <v-row class="mx-auto">
        <v-col>
          <v-alert type="error" v-if="notificationErrorMessage">
            {{ notificationErrorMessage }}
          </v-alert>

          <v-card-title> {{ notificationInfo.text }}</v-card-title>

          <!--          <span v-for="(item, index) in conf" :key="index">-->
          <v-textarea
            label="Mail Template"
            outlined
            v-model="form.mail_template"
            rows="3"
            name="input-7-4"
            hide-details
          ></v-textarea>
          <!--      </span>-->

          <v-card-title> Placeholder</v-card-title>
          <span
            class="mr-3"
            style="color: #757575; font-size: 14px;"
            v-for="(item, i) in notificationInfo.placeholders"
            :key="i"
            >{{ item }}</span
          >
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="notificationLoader"
          color="#0000ff"
          block
          dark
          type="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </form>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewNotificationSetting",
  props: {
    notificationObj: {}
  },
  data() {
    return {
      notificationErrorMessage: null,
      form: {
        mail_template: null,
        notification_uid: null
      },
      notificationInfo: {},
      notificationLoader: false,
      conf: []
    };
  },
  mounted() {
    if (this.notificationObj) {
      this.changeNotification();
    }
  },
  methods: {
    ...mapActions(["saveNotificationSettings"]),
    changeNotification() {
      this.notificationInfo = this.notificationObj;
      this.form.mail_template = this.notificationObj.configs.mail_template;
      this.form.notification_uid = this.notificationObj.number;
    },
    saveNotificationSetting() {
      // alert(JSON.stringify(this.form))
      this.notificationLoader = true;
      this.saveNotificationSettings(this.form);
    }
  },
  computed: {
    ...mapGetters(["getNotificationChangeError"])
  },
  watch: {
    notificationObj() {
      this.changeNotification();
    },
    getNotificationChangeError(errorData) {
      if (errorData) {
        this.notificationLoader = false;
        this.errorToast("Error", errorData.message);
      }
    }
  }
};
</script>

<style scoped></style>
