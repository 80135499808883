import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  notificationSettings: null,
  notificationChange: null,
  notificationConfig: null,
  notificationError: null,
  newTaxError: null,
  notificationChangeError: null
};

const getters = {
  getNotificationSettings: state => state.notificationSettings,
  getNotificationChange: state => state.notificationChange,
  getNotificationError: state => state.notificationError,
  getNotificationConfig: state => state.notificationConfig,
  getNewTaxError: state => state.newTaxError,
  getNotificationChangeError: state => state.notificationChangeError
};

const actions = {
  async fetchNotificationSettings({ commit }) {
    await axios
      .get(IP + "settings/notifications")
      .then(response => {
        commit("setNotificationSettings", response);
      })
      .catch(error => {
        commit("setNotificationsError", error);
      });
  },
  async notificationSettingsActivate({ commit }, data) {
    await axios
      .post(IP + "settings/notifications/change", data)
      .then(response => {
        commit("setNotificationSettings", response);
      })
      .catch(error => {
        commit("setNotificationsError", error);
      });
  },
  async toggleNotification({ commit }, data) {
    await axios
      .post(IP + "settings/notifications/change", data)
      .then(response => {
        commit("setNotificationChange", response);
      })
      .catch(error => {
        commit("setNotificationsChangeError", error);
      });
  },
  async saveNotificationSettings({ commit }, data) {
    await axios
      .post(IP + "company/settings/notifications/configs", data)
      .then(response => {
        commit("setNotificationConfigs", response);
      })
      .catch(error => {
        commit("setNotificationsChangeError", error);
      });
  }
};

const mutations = {
  setNotificationSettings: (state, response) => {
    state.notificationSettings = response.data;
  },
  setNotificationChange: (state, response) => {
    state.notificationChange = response.data;
  },
  setNotificationConfigs: (state, response) => {
    state.notificationConfig = response.data;
  },
  setNotificationsError: (state, error) => {
    state.notificationError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  },
  setNewTaxError: (state, error) => {
    state.newTaxError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  },
  setNotificationsChangeError: (state, error) => {
    state.notificationChangeError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
