import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  itemDataList: null,
  stockErrorResponse: null,
  editStockErrorMessage: null,
  supplierDataList: null,
  stockableItems: null,
  newStockDataList: null,
  editStockData: null
};

const getters = {
  getItemDataList: state => state.itemDataList,
  getStockableItems: state => state.stockableItems,
  getSupplierDataList: state => state.supplierDataList,
  getNewStockDataList: state => state.newStockDataList,
  getStockErrorResponse: state => state.stockErrorResponse,
  getEditStockData: state => state.editStockData,
  getEditStockErrorMessage: state => state.editStockErrorMessage
};

const actions = {
  async doGetStockableItems({ commit }) {
    await axios
      .get(IP + "pos/stock-able-items")
      .then(response => {
        commit("setStockableItems", response);
      })
      .catch(error => {
        commit("setStockErrorMessage", error);
      });
  },
  async doGetItemDataList({ commit }) {
    await axios
      .get(IP + "pos/stock-items/in-stock")
      .then(response => {
        commit("setItemDataList", response);
      })
      .catch(error => {
        commit("setStockErrorMessage", error);
      });
  },

  async doGetSupplierDataList({ commit }) {
    await axios
      .get(IP + "supplier/all")
      .then(response => {
        commit("setSupplierDataList", response);
      })
      .catch(error => {
        commit("setStockErrorMessage", error);
      });
  },
  async doSaveStock({ commit }, data) {

    if(data.bill_of_items) {
        data.bill_of_items.forEach((list, i) => {
        data.bill_of_items[i].quantity = list.quantity ? (list.quantity.toString().replace(/,/g, "") * 1) : list.quantity;
        data.bill_of_items[i].buying_price = list.buying_price ? (list.buying_price.toString().replace(/,/g, "") * 1) : list.buying_price;
        data.bill_of_items[i].selling_price = list.selling_price ? (list.selling_price.toString().replace(/,/g, "") * 1) : list.selling_price;
        data.bill_of_items[i].unit_price = list.selling_price ? (list.selling_price.toString().replace(/,/g, "") * 1) : list.selling_price;
      })
    }

    await axios
      .post(IP + "company/new-stock", data)
      .then(response => {
        commit("setNewStockData", response.data);
      })
      .catch(error => {
        commit("setItemErrorMessage", error);
      });
  },
  async saveEditStock({ commit }, data) {
    await axios
      .post(IP + "pos/update-stock-line", data)
      .then(response => {
        commit("setEditStockData", response.data);
      })
      .catch(error => {
        commit("setEditStockErrorMessage", error);
      });
  },
};

const mutations = {
  setItemDataList: (state, response) => {
    state.itemDataList = response.data;
  },
  setStockableItems: (state, response) => {
    state.stockableItems = response.data;
  },
  setSupplierDataList: (state, response) => {
    state.supplierDataList = response.data;
  },
  setNewStockData: (state, response) => {
    state.newStockDataList = response.data;
  },
  setEditStockData: (state, response) => {
    state.editStockData = response;
  },
  setStockErrorMessage: (state, error) => {
    state.stockErrorResponse = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  },
  setEditStockErrorMessage: (state, error) => {
    state.editStockErrorMessage = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.message;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
