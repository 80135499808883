import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  userProfileData: null,
  userProfileDataError: null
};

const getters = {
  getUserProfileInfo: state => state.userProfileData,
  getUserProfileDataError: state => state.userProfileDataError
};

const actions = {
  async fetchUserProfileData({ commit }) {
    await axios
      .get(IP + "auth/profile")
      .then(response => {
        commit("setUserProfileData", response);
      })
      .catch(error => {
        commit("setUserProfileDataError", error);
      });
  }
};

const mutations = {
  setUserProfileData: (state, response) => {
    state.userProfileData = response.data;
  },
  setUserProfileDataError: (state, error) => {
    state.userProfileDataError = error.response
      ? error.response.data
      : "Error: Network Error"; //error.response.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
