<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Fixed Assets </v-toolbar-title>
            <v-spacer></v-spacer>
            <span>
              <v-btn
                class="ma-2 text-decoration-none"
                outlined
                small
                color="#1b5e20"
                :loading="downloadSpinner"
                @click="downloadTemplate"
              >
                <v-icon left>
                  mdi-import
                </v-icon>
                Template
              </v-btn>

              <v-btn
                class="ma-2"
                outlined
                color="#242f7e"
                small
                @click="fileUploader"
              >
                <v-icon left>
                  mdi-upload
                </v-icon>
                Import
              </v-btn>

              <v-btn
                class="ma-2"
                outlined
                color="#242f7e"
                small
                @click="fileExport"
                :loading="exportSpinner"
              >
                <v-icon left>
                  mdi-download
                </v-icon>
                Export
              </v-btn>
            </span>
          </v-toolbar>

          <v-tabs v-model="activeTab">
            <!--            <v-tabs-slider color="yellow"></v-tabs-slider>-->
            <v-tab href="#fixed-assets">
              <v-icon left>mdi-account</v-icon>
              Fixed Assets
            </v-tab>

            <v-tab v-if="checkLicenseStatus()" href="#new-fixed-assets">
              <v-icon left>mdi-lock</v-icon>
              New Fixed Assets
            </v-tab>

            <v-tab-item id="fixed-assets">
              <v-divider></v-divider>
              <v-card flat>
                <fixed-asset-list
                    account_type="FAST"
                    ref="Assets"
                ></fixed-asset-list>
              </v-card>
            </v-tab-item>

            <v-tab-item id="new-fixed-assets">
              <v-divider></v-divider>

              <v-card flat>
                <v-row>
                  <v-col cols="11" class="mt-8 mx-auto">
                    <new-fixed-asset
                        account_type="FAST"
                        ref="addFixedAssetsItems"
                    ></new-fixed-asset>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="uploadDialog" width="40%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Import Fixed Assets</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="uploadDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <upload-file
            ref="upload_component"
            fileType="transactions"
          ></upload-file>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UploadFile from "@/modules/dashboard/accounting/UploadFile/UploadFile";
import configs from "@/global/configs.json";
import router from "@/global/router";
import FixedAssetList from "@/modules/dashboard/accounting/FixedAssets/FixedAssetList";
import NewFixedAsset from "@/modules/dashboard/accounting/FixedAssets/NewFixedAsset";
export default {
  name: "Accounting",
  components: {NewFixedAsset, FixedAssetList, UploadFile },
  data() {
    return {
      activeTab: "fixed-assets",
      uploadDialog: false,
      downloadLink:
        configs.SERVER_IP + "template?type=transactions&file_token=",
      exportLink: configs.SERVER_IP + "export?type=transactions&file_token=",
      downloadSpinner: false,
      exportSpinner: false
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
  },
  methods: {
    ...mapActions(["fetchTransactions", "downloadTemplateFile", "exportFile"]),
    fileUploader() {
      this.uploadDialog = true;
    },
    downloadTemplate() {
      this.downloadSpinner = true;
      this.downloadTemplateFile("transactions");
    },
    fileExport() {
      this.exportSpinner = true;
      this.exportFile("transactions");
    }
  },
  computed: {
    ...mapGetters([
      "getFixedTransactionDataResponse",
      "getStatusExpenseResponse",
      "getTransactionErrorMessage",
      "getTransactionsDataSet",
      "getTemplateDownloadData",
      "getExportFileData"
    ])
  },
  watch: {
    getFixedTransactionDataResponse(transactionData) {
      if (transactionData) {
        this.successToast("Success", transactionData.message);

        this.$refs["addFixedAssetsItems"].transactionLoader = false;
        this.$refs["addFixedAssetsItems"].form = {
          name: null,
          amount: null,
          dateAcquired: null,
          description: null,
          date: new Date().toISOString().substr(0, 10),
          storage_account_number: null,
          account_number: null,
          payment_method_number: null,
          profile_number: null,
          reference: null,
          sale_user_number: null
        };
      }
    },
    getTemplateDownloadData(templateData) {
      if (templateData) {
        this.downloadSpinner = false;

        let fileUrl = this.downloadLink + templateData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },
    getExportFileData(exportData) {
      if (exportData) {
        this.exportSpinner = false;
        let fileUrl = this.exportLink + exportData;

        const pdfObj = document.createElement("a");
        pdfObj.href = fileUrl;
        pdfObj.click();
      }
    },
    activeTab(data) {
      router.push({ name: "fixed_assets", query: { type: data } });
    }
  }
};
</script>

<style scoped></style>
