<template>
  <v-row>
    <v-col>
      <HomeHeader/>
      <v-row class="mt-5 py-5">
        <v-col style="text-align: center; margin-top: 30px;">
          <h1>Billing & Payments</h1>
          <router-link :to="{name:'home'}" style="padding: 5px 15px; color: #065FDB; border-radius: 3px; font-weight: bold; cursor:pointer;" class="mt-5">Back home</router-link>
        </v-col>
      </v-row>
      <v-row class="mx-auto">
        <v-col cols="3"></v-col>
        <v-col cols="2">
          <v-card>
            <v-row>
              <v-col class="text-center">
                <h5 class="tier">Starter Pack</h5>
                <h4 class="money-tier">$150</h4>
                <span>p/mo</span>
                <v-row>
                  <v-col cols="12">
                    <v-table>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Feature
                          </th>
                          <th class="text-left">
                            Active
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Item 1</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Item 2</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Item 3</td>
                          <td>No</td>
                        </tr>
                        <tr>
                          <td>Item 4</td>
                          <td>No</td>
                        </tr>
                        <tr>
                          <td>Item 5</td>
                          <td>No</td>
                        </tr>
                        <tr>
                          <td>Item 6</td>
                          <td>No</td>
                        </tr>
                        <tr>
                          <td>Item 6</td>
                          <td>No</td>
                        </tr>
                        <tr>
                          <td>Item 6</td>
                          <td>No</td>
                        </tr>
                        <tr>
                          <td>Item 6</td>
                          <td>No</td>
                        </tr>
                        <tr>
                          <td>Item 6</td>
                          <td>No</td>
                        </tr>
                        <tr>
                          <td>Item 6</td>
                          <td>No</td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span style="padding: 5px 15px; color: white; background: green; border-radius: 3px; font-weight: bold;">7 day free trial</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-row>
              <v-col class="text-center">
                <h5 class="tier">Standard</h5>
                <h4 class="money-tier">$450</h4>
                <span>p/mo</span>
                <v-row>
                  <v-col cols="12">
                    <v-table>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Feature
                        </th>
                        <th class="text-left">
                          Active
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>Item 1</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 2</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 3</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 4</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 5</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>No</td>
                      </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span style="padding: 5px 15px; color: white; background: green; border-radius: 3px; font-weight: bold;">Experts Recommendation</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-row>
              <v-col class="text-center">
                <h5 class="tier">Premium</h5>
                <h4 class="money-tier">$850</h4>
                <span>p/mo</span>
                <v-row>
                  <v-col cols="12">
                    <v-table>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Feature
                        </th>
                        <th class="text-left">
                          Active
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>Item 1</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 2</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 3</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 4</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 5</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Item 6</td>
                        <td>Yes</td>
                      </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span style="padding: 5px 15px; color: white; background: green; border-radius: 3px; font-weight: bold;">All Features. All Access</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import HomeHeader from "@/modules/home/HomeHeader.vue";

export default {
  name: "BillingChart",
  components: { HomeHeader }
};
</script>

<style scoped>

.tier{
  font-weight: bold;
  font-size: 25px;
}

.money-tier{
  color: #065FDB;
  font-size: 45px;
}

tr td{
  padding: 5px;
}

th{
  padding: 5px;
  font-weight: bold;
}

</style>