<template>
<div>

  <v-row>
    <v-col cols="9"></v-col>
    <v-col cols="3" class="ml-auto">
      <v-card-title>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            dense
            outlined
            hide-details
            v-on:keyup.enter="filterData"
        ></v-text-field>
      </v-card-title>
    </v-col>
  </v-row>


  <v-data-table
      :headers="tableHeader"
      :items="generalTableData"
      :search="search"
      :loading="tableUserLoader"
      loading-text="Loading... please wait"
      item-key="name"
      class="elevation-1"
      :page="page"
      :items-per-page="items_per_page"
      :footer-props="{
                      'itemsPerPageOptions': items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text':'Payments per page'
                    }"
      :server-items-length="server_items_length"
      :options.sync="pagination"


  >

    <template v-slot:top="{ pagination, options, updateOptions }">
      <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="Payments per page"
          :items-per-page-options="items_per_page_options"
          :showFirstLastPage="true"
          firstIcon="mdi-arrow-collapse-left"
          lastIcon="mdi-arrow-collapse-right"
          prevIcon="mdi-chevron-left"
          nextIcon="mdi-chevron-right"
      />
    </template>



  </v-data-table>


</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TaxPaymentTable",
  data(){
    return{
      search:null,
      tableUserLoader:false,
      generalTableData:[],
      tableHeader: [
        {
          text: "#",
          align: "start",
          width: "2%",
          value: "key"
        },
        { text: "Date", value: "date" },
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount" },
        { text: "Memo", value: "notes" },
        { text: "Reference", value: "reference" },
        { text: "Payment Method", value: "payment_method_name" },
        { text: "Bank", value: "bank" },
        // { text: "Action", value: "actions" ,width: "10%"}
      ],
      page: 1,
      items_per_page_options: [20, 50, 100, 500],
      items_per_page: 20,
      server_items_length: 0,
      pagination: {},
    }
  },
  methods:{
    ...mapActions(
        [
            "taxPaymentDataList"
        ]
    ),
    filterData(){
      this.tableUserLoader = true
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search,
      };
      this.taxPaymentDataList(options);
    },
    filterTaxPaymentList(raw_data) {
      let taxPaymentListData = raw_data.data;
      this.server_items_length = raw_data.total;
      this.tableUserLoader = false;

      let current_page = raw_data.current_page;
      this.page = current_page;
      let per_page = raw_data.per_page;
      this.items_per_page = per_page * 1;

      let obj = {};
      let err = [];

      taxPaymentListData.forEach((list, i) => {
        obj = {
          key: (current_page - 1) * per_page + i + 1,
          name: list.name,
          amount: list.amount,
          notes: list.notes,
          date: list.date,
          reference: list.reference,
          payment_method_name: list.payment_method_name,
          bank: list.bank,
        };
        err.push(obj);
      })

      this.generalTableData = err;
    },
  },
  computed:{
    ...mapGetters(
        [
            "getTaxPaymentDetails",
            "getTaxPaymentData"
        ]
    )
  },
  watch:{
    pagination(obj) {
      this.tableUserLoader = true
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        search:this.search,
      };
      this.taxPaymentDataList(options)
    },
    getTaxPaymentDetails(data){
      if(data){
        this.filterTaxPaymentList(data)
      }
    },
    getTaxPaymentData(data){
      if(data){
         this.filterData()
      }
    },
  }
}
</script>

<style scoped>

</style>