<template>
  <div>
    <v-row class="mx-auto mt-8">
      <v-col cols="8">

      </v-col>

      <v-col cols="4">
        <v-text-field
            class="mr-3"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            v-on:keyup.enter="doSearch"
            dense
            outlined
            hide-details
        ></v-text-field>
      </v-col>
    </v-row>


    <v-data-table
        :headers="headers"
        :items="tableData"
        :search="search"
        :loading="tableLoader"
        loading-text="Loading... please wait"
        :page="page"
        :items-per-page="items_per_page"
        :server-items-length="server_items_length"
        :options.sync="pagination"
        no-data-text="No Depreciation Item found."
        :footer-props="{
                      'itemsPerPageOptions': items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text':'Depreciation per page'
                    }">
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="Depreciation per page"
            :items-per-page-options="items_per_page_options"
            :showFirstLastPage="true"
            firstIcon="mdi-arrow-collapse-left"
            lastIcon="mdi-arrow-collapse-right"
            prevIcon="mdi-chevron-left"
            nextIcon="mdi-chevron-right"
        />
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip class="ma-2" :color="getStatusColor(item.status)" small outlined>
          {{ item.status }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">

        <v-tooltip bottom v-if="item.status !== 'Fully paid'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                size="25"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="makePayment(item)"
            >
              mdi-cash
            </v-icon>
          </template>
          <span>Pay Liability</span>
        </v-tooltip>

        <div v-if="!item.locked">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  size="20"
                  v-bind="attrs"
                  v-on="on"
                  @click="editLiability(item)">
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit Liability</span>
          </v-tooltip>


          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  size="20"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteLiabilities(item)">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete Liability</span>
          </v-tooltip>
        </div>



      </template>
    </v-data-table>




    <v-dialog v-model="editTransactionsDialog" width="70%">
      <v-card>
        <div style="width: 95%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Edit Liability</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editTransactionsDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
          <new-current-liability
              :account_type="account_type"
              ref="addExpenseItems"
          ></new-current-liability>
        </div>
      </v-card>
    </v-dialog>


    <!--      adds bill-->
    <v-dialog v-model="payLiabilityDialog" width="40%">
      <v-card>
        <div style="width: 96%; padding-bottom: 20px;" class="mx-auto">
          <v-row>
            <v-card-title>Pay Liability</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="payLiabilityDialog = false">
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>

          <pay-liability
              ref="liabilityPaymentComponents"
              :invoiceInfo="liabilityDataInfo"
              :account_type="account_type"
              receiptType="Bill"
          ></pay-liability>
        </div>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="deleteInvoicePaymentTransaction"></ConfirmDialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewCurrentLiability from "@/modules/dashboard/accounting/CurrentLiability/NewCurrentLiability";
import PayLiability from "@/modules/dashboard/accounting/commons/PayLiability";
import ConfirmDialog from "@/modules/commons/ConfirmDialog";

export default {
  name: "LiabilitiesInfo",
  components: {ConfirmDialog, PayLiability, NewCurrentLiability},
  props: {
    account_type: String
  },
  data() {
    return {
      tableData: [],
      search: "",
      tableLoader: false,
      pagination: {},
      page: 1,
      items_per_page_options: [20, 50, 100, 500],
      items_per_page: 20,
      server_items_length: 0,

      expense_permission_items: [
        { text: "Select Permission", value: null },
        { text: "Everyone", value: "everyone" },
        { text: "view", value: "view" },
        { text: "edit", value: "edit" }
      ],
      headers: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount" },
        { text: "Balance", value: "balance" },
        { text: "Memo", value: "notes" },
        { text: "Date", value: "date" },
        { text: "Reference", value: "reference" },
        { text: "Payment Method", value: "payment_method" },
        { text: "Status", value: "status" },
        { text: "Action", value: "actions", sortable: false }
      ],
      expense: [
        {
          name: "Balance brought down from March, 2019",
          date: "01-04-2019",
          account_name: "Equity Bank (Ugx)",
          amount: "USh 39,843.99",
          status: "Paid",
          attachment: "word.docx",
          action: "Edit"
        },
        {
          name: "Monthly data",
          date: "01-06-2020",
          account_name: "Equity Bank (Ugx)",
          amount: "USh 30,000.00",
          status: "Paid",
          attachment: "",
          action: "Edit"
        }
      ],
      json_fields: {
        Date: "date",
        Name: "name",
        Amount: "amount",
        notes: "notes"
      },
      editTransactionsDialog: false,
      payLiabilityDialog:false,
      liabilityDataInfo:{}
    };
  },
  mounted() {
    this.tableTransactionLoader = true;

  },
  methods: {
    ...mapActions(
        [
          "fetchLiabilities",
          "doFetchLiabilityInfo",
           "fetchLiabilityBalance",
           "doDeleteLiabilityInfo"
        ]
    ),

    doSearch() {
      this.tableLoader = true
      let options = {
        page: this.page,
        per_page: this.items_per_page,
        search: this.search,
        type: this.account_type,
      }
      this.fetchLiabilities(options);
    },
    makePayment(item){
      if(item){
        this.payLiabilityDialog = true
        this.liabilityDataInfo = item
        this.fetchLiabilityBalance(item.liability_number)

      }
    },
    editLiability(items) {
      this.editTransactionsDialog = true;
      this.doFetchLiabilityInfo(items.number);
    },
    async deleteLiabilities(item) {
      if(item) {
        let title = `Are you sure you want to delete this liability ?  Doing this is not reversible`

        const agreed = await this.$refs.deleteInvoicePaymentTransaction.confirm(
            title,
            {
              color: "red",
              width: "480",
            }
        );

        if (!agreed) {
          return
        }

        await this.doDeleteLiabilityInfo(item.liability_number);
      }
    },
    filterTransaction(raw_data) {
      if(raw_data){

        let liabilityData = raw_data.data;
        this.server_items_length = raw_data.total;
        this.tableLoader = false;

        let current_page = raw_data.current_page;
        this.page = current_page;
        let per_page = raw_data.per_page;
        this.items_per_page = per_page * 1;
        
            this.tableTransactionLoader = false;
            this.tableStockLoader = false;
            let transactionJsn = {};
            let transactionObj = [];

        liabilityData.forEach((lst, i) => {
          transactionJsn = {
            key: (current_page - 1) * per_page + i + 1,
            name: lst.name ? lst.name : "",
            locked: lst.locked ? lst.locked : "",
            amount: lst.amount ? lst.amount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
            notes: lst.notes ? lst.notes : "",
            date: lst.date ? lst.date : "",
            reference: lst.reference ? lst.reference : "",
            payment_method: lst.payment_method_name ? lst.payment_method_name : "",
            number: lst.number ? lst.number : "",
            liability_number: lst.liability ? lst.liability.number : "",
            status: lst.liability ? lst.liability.status : "",
            balance: lst.liability ? lst.liability.amount_paid_and_due.due.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
          };
          transactionObj.push(transactionJsn);
        })
            this.tableData = transactionObj;
      }

    }
  },
  computed: {
    ...mapGetters([
      "getStatusExpenseResponse",
      "getTransactionErrorMessage",
      "getLiabilityDataSet",
      "getTransactionData",
       "getUpdateLiabilityData",
        "getLiabilityPaymentData",
        "getLiabilityDeleteData"
    ])
  },
  watch: {
    pagination(obj) {
      this.tableLoader = true;
      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        search: this.search,
        type: this.account_type,
      };
      this.fetchLiabilities(options);
    },
    getTransactionErrorMessage(expenseError) {
     if(expenseError){
       this.errorToast("Error", expenseError.message)
     }
    },
    getLiabilityDataSet(transactionData) {
      this.filterTransaction(transactionData);
    },
    getTransactionData(transactionData) {
      if (transactionData) {
        this.editTransactionsDialog = false;
        this.$refs["addExpenseItems"].transactionLoader = false;
        this.$refs["addExpenseItems"].form = {
          name: null,
          amount: null,
          notes: null,
          date: new Date().toISOString().substr(0, 10),
          storage_account_number: null,
          account_number: null,
          payment_method: null,
          reference: null
        };

        let data = {
          company_number: this.$company.number,
          type: this.account_type
        };
        this.fetchTransactions(data);
      }
    },
    getUpdateLiabilityData(data){
      if(data){
        this.editTransactionsDialog = false
        this.successToast("Success", data.message)
        this.$refs.addExpenseItems.transactionLoader = false
        this.doSearch()
      }
    },
    getLiabilityDeleteData(data){
      if(data){
        this.successToast("Success", data.message)
        this.doSearch()
      }
    },
    getLiabilityPaymentData(data){
      if(data){
          this.payLiabilityDialog = false
          this.$refs.liabilityPaymentComponents.invoiceLoader = false
          this.$refs.liabilityPaymentComponents.form = {
                                                        liability_number: null,
                                                        amount: null,
                                                        account_number: null,
                                                        payment_method_number: null,
                                                        storage_account_number: null,
                                                        payment_date: new Date().toISOString().substr(0, 10),
                                                        notes: null,
                                                        sale_user_number: null
                                                      }
          this.doSearch()
      }
    }
  }

}
</script>

<style scoped>

</style>