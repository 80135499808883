<template>
  <span>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td style="width: 30%;">Date:</td>
            <td>{{ logInfo.date }}</td>
          </tr>
          <tr>
            <td style="width: 30%;">Log Name:</td>
            <td>{{ logInfo.name }}</td>
          </tr>
          <tr>
            <td style="width: 30%;">Description:</td>
            <td>{{ logInfo.description }}</td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: center; font-weight: bold;">
              Attributes
            </td>
          </tr>

          <tr v-for="(items, index) in logInfo.attributes" :key="index">
            <td style="width: 30%;">{{index}}</td>
            <td>{{ items }}</td>
          </tr>
          <tr></tr>
        </tbody>
      </template>
    </v-simple-table>
  </span>
</template>

<script>
export default {
  name: "ActivityLogsInfo",
  props: {
    logInfo: {}
  }
};
</script>

<style scoped></style>
