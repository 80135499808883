<template>
  <div>


    <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>

    <form ref="form" @submit.prevent="activateLicense">


      <v-text-field
          v-model="form.code"
          label="XXXXXXXX"
          outlined
          dense
          required
      ></v-text-field>





      <div class="text-right">
        <v-btn
            class="companyButton"
            :loading="btnSpinner"
            color="#0000ff"
            dark
            type="submit"
        >
          Activate
        </v-btn>

      </div>
    </form>





  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ActivateLicense",
  data(){
    return{
      errorMessage:"",
      form:{
        code:null,
      },
      btnSpinner:false
    }
  },
  methods:{
    ...mapActions(
        [
            "doActivateLicense"
        ]
    ),
    activateLicense(){
      this.btnSpinner = true
     this.doActivateLicense(this.form)
    }
  },
  computed:{
    ...mapGetters(
        [
            "getLicenseError"
        ]
    )
  },
  watch:{
    getLicenseError(data){
      if(data){
        this.btnSpinner = false
        this.errorToast("Error", data.message)
      }
    }
  }
}
</script>

<style scoped>

</style>