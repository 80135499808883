import Vuex from "vuex";
import Vue from "vue";
import dashboard from "../modules/dashboard/vuex-store/dashboard_store";
import login_store from "../modules/account/auth/vuex-store/login_store";
import register_store from "../modules/account/auth/vuex-store/register_store";
import accounting_store from "../modules/dashboard/accounting/vuex-store/accounting_store";
import company_store from "../modules/dashboard/accounting/vuex-store/company_store";
import chart_of_accounts_store from "../modules/dashboard/accounting/vuex-store/chart_of_accounts_store";
import expense_store from "../modules/dashboard/accounting/Expense/vuex-store/expense_store";
import add_item_store from "../modules/dashboard/accounting/commons/vuex-store/add_item_store";
import add_stock_store from "../modules/dashboard/accounting/commons/vuex-store/add_stock_store";
import add_unit_store from "../modules/dashboard/accounting/commons/vuex-store/add_unit_store";
import add_supplier_store from "../modules/dashboard/accounting/commons/vuex-store/add_supplier_store";
import stock_store from "../modules/dashboard/accounting/Stock/vuex-store/stock_store";
import item_store from "../modules/dashboard/accounting/Item/vuex-store/item_store";
import customer_store from "../modules/dashboard/accounting/profiles/vuex-store/profiles_store";
import current_assets from "../modules/dashboard/accounting/CurrentAssets/vuex-store/current_assets";
import make_sale_store from "../modules/dashboard/accounting/commons/vuex-store/make_sale_store";
import sales_store from "../modules/dashboard/accounting/Sales/vuex-store/sales_store";
import add_discount_store from "../modules/dashboard/accounting/commons/vuex-store/add_discount_store";
import customer_details_store from "../modules/dashboard/accounting/CustomeDetails/vuex-store/customer_details_store";
import discount_store from "../modules/dashboard/accounting/Discount/vuex-store/discount_store";
import invoice_store from "../modules/dashboard/accounting/Invoice/vuex-store/invoice_store";
import supplier_store from "../modules/dashboard/accounting/Supplier/vuex-store/supplier_store";
import bills_store from "../modules/dashboard/accounting/Bills/vuex-store/bills_store";
import supplier_payouts_store from "../modules/dashboard/accounting/SupplierPayouts/vuex-store/supplier_payouts_store";
import profit_and_loss_store from "../modules/dashboard/accounting/ProfitAndLoss/vuex-store/profit_and_loss_store";
import balance_sheet_store from "../modules/dashboard/accounting/BalanceSheet/vuex-store/balance_sheet_store";
import bank_statement_store from "../modules/dashboard/accounting/BankStatement/vuex-store/bank_statement_store";
import storage_account_list_store from "../modules/dashboard/accounting/StorageAccounts/vuex-store/storage_account_list_store";
import roles_and_permissions_store from "@/modules/dashboard/Settings/RolesAndPermissions/vuex-store/roles_and_permissions_store";
import user_profile_store from "@/modules/dashboard/accounting/UserProfile/vuex-store/user_profile_store";
import internal_user_store from "@/modules/dashboard/Settings/Users/vuex-store/internal_user_store";
import activity_logs_store from "@/modules/dashboard/accounting/Settings/ActivityLogs/vuex-store/activity_logs_store";
import upload_store from "@/modules/dashboard/accounting/UploadFile/vuex-store/upload_store";
import notifications_store from "@/modules/dashboard/Settings/Notifications/vuex-store/notifications_store";
import stat_store from "@/modules/dashboard/accounting/vuex-store/stat_store";
import invoice_settings_store from "@/modules/dashboard/Settings/InvoiceSettings/vuex-store/invoice_settings_store";
import depreciation_store from "@/modules/dashboard/accounting/Depreciation/vuex-store/depreciation_store";
import depreciation_transaction_store
  from "@/modules/dashboard/accounting/DepreciationTransaction/vuex-store/depreciation_transaction_store";
import tax_store from "@/modules/dashboard/Settings/tax/vuex-store/tax_store";
import transfer_store from "@/modules/dashboard/accounting/transfer/vuex-store/transfer_store";
import fixed_assets_store from "@/modules/dashboard/accounting/FixedAssets/vuex-store/fixed_assets_store";
import tax_payment_store from "@/modules/dashboard/accounting/taxPayments/vuex-store/tax_payment_store";
import license_store from "@/modules/dashboard/Settings/license/vuex-store/license_store";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dashboard,
    login_store,
    register_store,
    accounting_store,
    company_store,
    chart_of_accounts_store,
    expense_store,
    add_item_store,
    add_stock_store,
    add_unit_store,
    add_supplier_store,
    stock_store,
    item_store,
    customer_store,
    current_assets,
    make_sale_store,
    sales_store,
    add_discount_store,
    customer_details_store,
    discount_store,
    invoice_store,
    supplier_store,
    bills_store,
    supplier_payouts_store,
    profit_and_loss_store,
    balance_sheet_store,
    bank_statement_store,
    storage_account_list_store,
    roles_and_permissions_store,
    user_profile_store,
    internal_user_store,
    activity_logs_store,
    upload_store,
    notifications_store,
    stat_store,
    invoice_settings_store,
    depreciation_store,
    depreciation_transaction_store,
    tax_store,transfer_store,
    fixed_assets_store,
    tax_payment_store,
    license_store,
  }
});
