var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mx-auto mt-8"},[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","persistent-hint":"","hint":"Press enter to search"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterDepreciationTransactionInfo($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"search":_vm.search,"loading":_vm.tableLoader,"loading-text":"Loading... please wait","page":_vm.page,"items-per-page":_vm.items_per_page,"server-items-length":_vm.server_items_length,"options":_vm.pagination,"no-data-text":"No Transaction Item found.","footer-props":{
                      'itemsPerPageOptions': _vm.items_per_page_options,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text':'Transaction per page'
                    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
                    var pagination = ref.pagination;
                    var options = ref.options;
                    var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"Transaction per page","items-per-page-options":_vm.items_per_page_options,"showFirstLastPage":true,"firstIcon":"mdi-arrow-collapse-left","lastIcon":"mdi-arrow-collapse-right","prevIcon":"mdi-chevron-left","nextIcon":"mdi-chevron-right"},on:{"update:options":updateOptions}})]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDepreciationTransaction(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteDepreciationTransaction(item)}}},[_vm._v(" mdi-delete ")]),(_vm.activeIndex === item.number)?_c('v-progress-circular',{attrs:{"width":"1","size":"16","color":"#242f7e","indeterminate":""}}):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"width":"40%"},model:{value:(_vm.depreciationTransactionDialog),callback:function ($$v) {_vm.depreciationTransactionDialog=$$v},expression:"depreciationTransactionDialog"}},[_c('v-card',[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"95%","padding-bottom":"20px"}},[_c('v-row',[_c('v-card-title',[_vm._v("Edit Depreciation Transaction")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.depreciationTransactionDialog = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('depreciation-transactions',{ref:"depreciation_transactions_content",attrs:{"itemsTransDataList":_vm.itemsTransDataList}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }