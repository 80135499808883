import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  depreciationData: null,
  fixedAssetsDataList: null,
  newDepreciationData: null,
  deleteDepreciationData: null,
  singleDepreciationData: null,
  editDepreciationData: null,
  depreciationError: null
};

const getters = {
  getDepreciationData: state => state.depreciationData,
  getFixedAssetsDataList: state => state.fixedAssetsDataList,
  getNewDepreciationData: state => state.newDepreciationData,
  getDeleteDepreciationData: state => state.deleteDepreciationData,
  getSingleDepreciationData: state => state.singleDepreciationData,
  getEditDepreciationData: state => state.editDepreciationData,
  getDepreciationError: state => state.depreciationError
};

const actions = {
  async fetchDepreciationData({ commit }, data) {
    await axios
      .get(
        IP +
          "depreciation/assets?page=" +
                                data.page +
                                "&per_page=" +
                                data.per_page
      )
      .then(response => {
        commit("setDepreciationData", response.data);
      })
      .catch(error => {
        commit("setDepreciationError", error);
      });
  },
  async fetchAllFixedAssets({ commit }, data) {
    await axios
      .get(
        IP +
        "company/get-account-data?type=" +
        data.type
    )
      .then(response => {
        commit("setFixedAssetsDataList", response);
      })
      .catch(error => {
        commit("setDepreciationError", error);
      });
  },
  async saveDepreciationData({ commit }, data) {
    await axios
        .post(IP + "depreciation/new", data)
        .then(response => {
          commit("setNewDepreciationData", response);
        })
        .catch(error => {
          commit("setDepreciationError", error);
        });
  },
  async deleteDepreciationData({ commit }, data) {
    await axios
        .post(IP + "depreciation/delete/"+data)
        .then(response => {
          commit("setDeleteDepreciationData", response);
        })
        .catch(error => {
          commit("setDepreciationError", error);
        });
  },
  async getSingeDepreciation({ commit }, data) {
    await axios
        .get(IP + "depreciation/details/"+data)
        .then(response => {
          commit("setSingleDepreciationData", response);
        })
        .catch(error => {
          commit("setDepreciationError", error);
        });
  },async editDepreciationData({ commit }, data) {
    await axios
        .post(IP + "depreciation/update/"+data.number, data.data)
        .then(response => {
          commit("setEditDepreciationData", response);
        })
        .catch(error => {
          commit("setDepreciationError", error);
        });
  },



};

const mutations = {
  setDepreciationData: (state, response) => {
    state.depreciationData = response;
  },
  setFixedAssetsDataList: (state, response) => {
    state.fixedAssetsDataList = response;
  },
  setNewDepreciationData: (state, response) => {
    state.newDepreciationData = response;
  },
  setDeleteDepreciationData: (state, response) => {
    state.deleteDepreciationData = response;
  },
  setSingleDepreciationData: (state, response) => {
    state.singleDepreciationData = response;
  },
  setEditDepreciationData: (state, response) => {
    state.editDepreciationData = response;
  },
  setDepreciationError: (state, error) => {
    state.depreciationError = error.response
      ? error.response.data
      : "Error: Network Error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
