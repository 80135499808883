import axios from "axios";
import configs from "../../../../../global/configs.json";

const IP = configs.SERVER_IP;

const state = {
  billNextNumber: null,
  newBillData: null,
  updateBillData: null,
  billListData: null,
  billDataInfo: null,
  billPaymentsData: null,
  billInvoiceDataInfoData: null,
  billError: null
};

const getters = {
  getBillNextNumber: state => state.billNextNumber,
  getNewBillData: state => state.newBillData,
  getBillListData: state => state.billListData,
  getBillDataInfo: state => state.billDataInfo,
  getBillPaymentsData: state => state.billPaymentsData,
  getBillInvoiceDataInfoData: state => state.billInvoiceDataInfoData,
  getUpdateBillData: state => state.updateBillData,
  getBillError: state => state.billError
};

const actions = {
  async getGeneratedBillNumber({ commit }) {
    commit("setNextBillNumber", { data: null });
    await axios
      .get(IP + "bills/next/bill")
      .then(response => {
        commit("setNextBillNumber", response);
      })
      .catch(error => {
        commit("setBillErrorMessage", error);
      });
  },
  async doSaveBill({ commit, dispatch }, data) {
    //this removes commas when invoice is submitted
    data.bill_of_items.forEach((list, k) => {
      data.bill_of_items[k].quantity = (list.quantity.toString().replace(/,/g, "") * 1);
      data.bill_of_items[k].buying_price = (list.buying_price.toString().replace(/,/g, "") * 1);
      data.bill_of_items[k].selling_price = (list.selling_price.toString().replace(/,/g, "") * 1);
      data.bill_of_items[k].unit_price = (list.selling_price.toString().replace(/,/g, "") * 1);
    })


    //removes commas on expense
    data.expense_items.forEach((list, u) => {
      data.expense_items[u].amount = data.expense_items[u].amount
          ? (data.expense_items[u].amount.toString().replace(/,/g, "") * 1)
          : "";
    })
    for (let u = 0; u < data.expense_items.length; u++) {
      data.expense_items[u].amount = data.expense_items[u].amount
        ? (data.expense_items[u].amount.toString().replace(/,/g, "") * 1)
        : "";
    }

    await axios
      .post(data.number ? IP + "bills/update/"+data.number : IP + "bills/create", data)
      .then(response => {
        commit("setNewBillData", response);
        dispatch("doGetBillListData", {profile_number:response.data.data.profile_number});
      })
      .catch(error => {
        commit("setBillErrorMessage", error);
      });
  },
  async doEditBill({commit}, data) {
    //this removes commas when invoice is submitted
    if(data.bill_of_items.length > 0) {
      data.bill_of_items.forEach(list => {
        list.quantity = (list.quantity.toString().replace(/,/g, "") * 1);
        list.buying_price = (list.buying_price.toString().replace(/,/g, "") * 1);
        list.selling_price = (list.selling_price.toString().replace(/,/g, "") * 1);
        list.unit_price = (list.selling_price.toString().replace(/,/g, "") * 1);
      })
    }


    //removes commas on expense
    if(data.expense_items.length > 0) {
      data.expense_items.forEach((list, u) => {
        data.expense_items[u].amount = data.expense_items[u].amount
            ? (data.expense_items[u].amount.toString().replace(/,/g, "") * 1)
            : "";
      })
    }


    await axios
        .post( IP + "bills/update/"+data.number, data)
        .then(response => {
          commit("setUpdateBillData", response);
        })
        .catch(error => {
          commit("setBillErrorMessage", error);
        });
  },
  async doGetBillListData({ commit }, data) {
        let profile_number  = data ? data.profile_number ? data.profile_number : "" : ""
        let start_date      = data ? data.start_date ? data.start_date : "" : ""
        let end_date        = data ? data.end_date ? data.end_date : "" : ""
        let search          = data ? data.search ? data.search : "" : ""
        let status          = data ? data.status ? data.status : "" : ""
        let page          = data ? data.page ? data.page : "1" : "1"
        let per_page          = data ? data.per_page ? data.per_page : "10" : "10"
    await axios
      .get(
          IP +
              "bills/all" +
              "?page="+page +
              "&per_page="+per_page+
              "&profile_number="+profile_number+
              "&start_date="+start_date+
              "&end_date="+end_date+
              "&search="+search+
              "&status="+status
      )
      .then(response => {
        commit("setBillListData", response);
      })
      .catch(error => {
        commit("setBillErrorMessage", error);
      });
  },
  async doGetBillInfo({ commit }, data) {
    await axios
      .get(IP + "bills/details/" + data)
      .then(response => {
        commit("setBillDataInfo", response);
      })
      .catch(error => {
        commit("setBillErrorMessage", error);
      });
  },
  async doGetBillPayment({ commit }, data) {
    await axios
      .get(
        IP +
          "payouts/on-bill/" +
          data.bill_number +
          "?page=" +
          data.page +
          "&per_page=" +
          data.per_page
      )
      .then(response => {
        commit("setBillPaymentDataInfo", response);
      })
      .catch(error => {
        commit("setBillErrorMessage", error);
      });
  },
  async fetchCustomerSupplierBillInvoices({ commit }, data) {
    let dataPath = data.path;
    await axios
      .get(IP + dataPath)
      .then(response => {
        commit("setBillInvoiceDataInfo", response);
      })
      .catch(error => {
        commit("setBillErrorMessage", error);
      });
  }
};

const mutations = {
  setNextBillNumber: (state, response) => {
    state.billNextNumber = response.data;
  },
  setNewBillData: (state, response) => {
    state.newBillData = response.data;
  },
  setUpdateBillData: (state, response) => {
    state.updateBillData = response.data;
  },
  setBillDataInfo: (state, response) => {
    state.billDataInfo = response.data;
  },
  setBillListData: (state, response) => {
    state.billListData = response.data;
  },
  setBillPaymentDataInfo: (state, response) => {
    state.billPaymentsData = response.data;
  },
  setBillInvoiceDataInfo: (state, response) => {
    state.billInvoiceDataInfoData = response.data;
  },
  setBillErrorMessage: (state, error) => {
    state.billError = error.response
      ? error.response
      : "Error: Network Error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
